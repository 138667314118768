import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterUnique',
    pure: false
  })
  export class FilterUniquePipe implements PipeTransform {
    transform(value: any, key: string): any {
      // Remove the duplicate elements
      return value.filter((e, i) => value.findIndex(a => a[key] === e[key]) === i);
    }
  }