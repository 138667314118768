import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DragSnap, DraggableEvent } from 'src/app/directives/draggable.directive';
import { Asset, AssetVO } from 'src/app/models/asset.model';
import { ITask, Task } from 'src/app/models/task.model';
import { AssetComponent } from '../../prototypes/assets/asset/asset.component';
import { Observable } from 'rxjs';
import { MarkerVO } from 'src/app/models/marker.model';

@Component({
  selector: 'app-creative-viewer',
  templateUrl: './creative-viewer.component.html',
  styleUrls: ['./creative-viewer.component.scss']
})
export class CreativeViewerComponent implements OnInit {

  @Input() assets:AssetVO[];
  @Input() selectedAsset:AssetVO;
  @Input() tasks:Task[];
  //@Input() selectedTask:Task;
  @Input() layout:string;
  @Input() zoom:number = 1;
  @Input() galleryIndex:number;
  @Output() galleryIndexChange = new EventEmitter<number>();
  @Input() editMode:boolean;
  @Input() transparent:boolean;
  @Input() color:string;
  @Input() showMarkup:boolean;
  @Input() isProduction:boolean;
  @Input() saving:boolean;
  @Input() markerVOs$:Observable<MarkerVO[]>;

  @ViewChildren(AssetComponent) assetElementList!: QueryList<AssetComponent>;
  @ViewChild('assetsContainer') assetsContainer:ElementRef<HTMLDivElement>;
  @Output() selected:EventEmitter<AssetVO> = new EventEmitter<AssetVO>();
  @Output() markerMouseEvent:EventEmitter<{task:ITask, event:string}> = new EventEmitter<{task:ITask, event:string}>();
  @Output() layoutChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() scrolled:EventEmitter<boolean> = new EventEmitter<boolean>();
  snapping:DragSnap;

  constructor(private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    
  }
  getScrollOffset()
  {
    const x = this.assetsContainer.nativeElement.scrollLeft || 0;
    const y = this.assetsContainer.nativeElement.scrollTop || 0;
    return {x, y};
  }
  ngAfterViewInit() {
		//console.log("assetList", this.assetElementList);
		this.assetElementList.changes.subscribe((r) => {
		  	//console.log("assetListChange", r);
      		// has to be after a delay or will throw angular error about stage change after evaluation :(
			setTimeout(() => {
				this.layoutChange.emit();
			}, 1);
		});

    this.assetsContainer.nativeElement.addEventListener('scroll', (e) => {
				this.scrolled.emit();
		});

    // watch for layout changes and notify
    let observer = new MutationObserver(mutations => {
      //console.log("assets MUTATION", this.assetsContainer.nativeElement.classList);
      this.layoutChange.emit();
      /*
      mutations.forEach((mutation) => {
        if(mutation.attributeName == "xxx"){}
      });
      */
      });
      var config = { attributes: true, childList: false, characterData: false };		  
      observer.observe(this.assetsContainer.nativeElement, config);
	}
  triggerResize()
  {
    this.assetElementList.forEach(asset => {
      asset.onResize();
    })
  }
  dragUpdate(e:DraggableEvent)
  {
	if(e.type == DraggableEvent.DISABLED){
		if(this.isProduction){
			//this.snackBar.open("TO DRAG ASSET: ensure view mode is set to 'custom' and the 'assets' tab is selected. Assets can only be moved during BUILD or AMENDING phases.", null,{duration: 5000})
		}
	}
    if(e.type == DraggableEvent.START)
    {
      this.snackBar.open("Hold CTRL to snap 10px grid. Hold SHIFT to snap to other assets.", null);//, {duration: 2000}
    } else if(e.type == DraggableEvent.STOP)
    {
      this.snackBar.dismiss();
    }
  }
  getAssetComponents():QueryList<AssetComponent>
  {
    return this.assetElementList;
  }
  onAssetSelected(assetVO : AssetVO, event:MouseEvent = null)
  {
    console.log("SELECTED", assetVO?.asset.name);
    
    if(event)
    {
      // stop the event bubbling so it is not triggered higher up
      //event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
		if(event && event.button == 1){
			// return on middle click
			return;
		}
    this.selected.emit(assetVO);
  }

  getAssetStyle(assetVO, i)
  {
    let asset = assetVO.asset;
    
    if(this.layout != "gallery")
    {
      return {
        'width.px':asset.width || '100%',
        'height.px':asset.height || '100%',
        'left.px':asset.x || 0,
        'top.px':asset.y || 0
      };
    }else{
      return {
        //'display':(!this.selectedAsset && i != (this.assets.length - this.galleryIndex)) || (this.selectedAsset && this.selectedAsset != asset) ? 'none' : ''
        //'display': this.showAssetIfGalleryMode(assetVO) ? '' : 'none'
      }
    }
  }

  showAssetIfGalleryMode(assetVO:AssetVO)
  {
    if(this.selectedAsset)
    {
       return this.selectedAsset == assetVO;
    } else {
      return this.assets.findIndex(assVO => assVO == assetVO) == this.galleryIndex;
    }
  }

  /*
  onMarkerClicked(task:Task)
  {

  }*/
  galleryGoto(index)
  {
    // update gallery index and wrap
    this.galleryIndex = index;
		this.galleryIndex = ((this.galleryIndex % this.assets.length) + this.assets.length) % this.assets.length;
    this.galleryIndexChange.emit(this.galleryIndex);
		// notify
    if(this.selectedAsset)
		{
			let asset = this.assets[this.galleryIndex];
      this.selected.emit(asset);
		}
  }
  galleryGotoAsset(asset:Asset | string)
  {
    let index = -1;
    if(typeof asset == 'string')
    {
      index = this.assets.findIndex(ass => ass.asset.uuid == asset);
    }else{
      index = this.assets.findIndex(ass => ass.asset.uuid == asset.uuid);
    }
    if(index != -1)
    {
      this.galleryGoto(index);
    }
  }
  galleryMove(dir){
    // if an asset is selected update the gallery index to match it's index
		if(this.selectedAsset)
		{
			this.galleryIndex = this.assets.indexOf(this.selectedAsset);
		}
		this.galleryGoto(this.galleryIndex + dir);
	}
  assetTrackBy(index:number, assetVO:AssetVO)
  {
    return assetVO.id;
  }
  onSnappingUpdate(snappingEvent:DragSnap)
  {
    this.snapping = snappingEvent;
    if(this.snapping.snapping)
    {
      const offset = this.getScrollOffset();
      if(typeof this.snapping.x == "number")  this.snapping.x -= offset.x; 
      if(typeof this.snapping.y == "number")  this.snapping.y -= offset.y; 
    }
  }
}
