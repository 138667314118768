<mat-tab-group #tabs animationDuration="0ms" [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event)">
	<mat-tab label="Account Workgroups">
		<div class="workgroups">
			<mat-card class="card">
				<mat-card-header class="shadow-underline">
					<!--<div mat-card-avatar class="example-header-image"></div>-->

					<mat-card-title>
						<div>Workgroups<span *ngIf="workgroups" style="color: rgba(0, 0, 0, 0.54); font-size: 0.5em; margin-left: 0.5em;">({{workgroups.length}})</span></div>	
					</mat-card-title>
					<div style="margin-bottom: 0.6em; font-size: 1.25em;"><app-help [key]="'workgroups'"></app-help></div>
				

					<mat-card-subtitle style="align-self: center">
							</mat-card-subtitle>
					
				</mat-card-header>
				<mat-card-content>
					<div class="list-header" *ngIf="workgroups?.length > 0">
						<mat-form-field [ngStyle]="{'visibility': showSearch ? 'visible' : 'hidden'}" class="search">   <!-- floatLabel="never"-->
							<mat-label>search</mat-label>
							<input matInput type="text" #searchInput [(ngModel)]="search">
							<button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
								<mat-icon>close</mat-icon>
							</button>
						</mat-form-field>
						<button mat-icon-button (click)="toggleSearch();"><mat-icon>search</mat-icon></button>
					</div>
					<div *ngIf="workgroups?.length > 0" class="list">
						<app-genericlist *ngIf="(appUserService.appUserSubject | async)?.super || (appUserService.appUserSubject | async)?.super || (this.accountService.accountAdmin$ | async); else notAdmin"
						[items]="workgroups"
						[sort]="sort"
						[sortReverse]="sortReverse"
						[search]="search"
						[searchExclude]="['uuid', 'account_uuid', 'created_at', 'updated_at']"
						(clickItem)="selected($event)"
						(editItem)="edit($event)"
						(deleteItem)="delete($event)">
						</app-genericlist>
						<ng-template #notAdmin>
							<app-genericlist [items]="workgroups"
							[sort]="sort"
							[sortReverse]="sortReverse"
							[search]="search"
							[searchExclude]="['uuid', 'account_uuid', 'created_at', 'updated_at']"
							(clickItem)="selected($event)">
							</app-genericlist>
						</ng-template>
					</div>
					<div *ngIf="workgroups?.length == 0" style="margin-top: 2em;">
						<span class="instruction">No client workgroups have been added to this account.</span>
					</div>
				</mat-card-content>
				<mat-card-actions *ngIf="!(this.accountService.workgroupsLoading$ | async) && (appUserService.appUserSubject | async)?.super || (this.accountService.accountAdmin$ | async)">
					<button [disabled]="addingWorkgroup" (click)="addWorkgroup()" [matTooltip]="strings.getTooltip('btn_add_workgroup')" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
					<div>
						<div style="margin: 0 1.5em;" [matMenuTriggerFor]="sortmenu">
							<span>sort by:&nbsp;</span>
							<span style="font-weight: bold;">{{getSortLabel()}}</span>			
							<span>
								<mat-icon style="margin-left: -0.1em; margin-top: 0.1em;">arrow_drop_down</mat-icon>
							</span>
			
							<mat-menu #sortmenu="matMenu">
								<button *ngFor="let option of sortOptions" mat-menu-item [disabled]="sort==option.sort && sortReverse==option.sortReverse" (click)="selectSort(option)">{{option.label}}</button>
							</mat-menu>
						</div>
					</div>
				</mat-card-actions>
			</mat-card> 
		</div>        
    </mat-tab>
    <mat-tab label="Account Team" *ngIf="appUserService.appSuperOrAccountAdmin$ | async">
		<div class="admin-content" >
			
			<p>Users added here are then available to be assigned to this account's Workgroups and the Projects within.</p>
			
			<button *ngIf="account" style="max-width: fit-content;" mat-flat-button color="primary" (click)="addUser()">Invite user to Account: '{{account.name}}'</button>
			<mat-divider style="margin: 1em 0 0 0;"></mat-divider>
			<div style="display: flex; overflow: hidden; height: 100%;">
				<mat-card class="card">
					<div class="title split" style="margin-bottom: 0;">
						<span>Account users:</span>
						<div *ngIf="users && users.length > 0" class="sortActions" style="flex-grow: 1; margin-left: 2em;">
							
								<mat-form-field [ngStyle]="{'visibility': showSearch_team ? 'visible' : 'hidden'}" class="search">   <!-- floatLabel="never"-->
									<mat-label>search</mat-label>
									<input matInput type="text" #searchInput_team [(ngModel)]="search_team">
									<button *ngIf="search_team" matSuffix mat-icon-button aria-label="Clear" (click)="search_team=''">
										<mat-icon>close</mat-icon>
									</button>
								</mat-form-field>
								<button mat-icon-button (click)="toggleSearch_team();"><mat-icon>search</mat-icon></button>
							

							<!--<div style="margin: 0; cursor:pointer;" [matMenuTriggerFor]="sortmenu">
								<span>sort by:&nbsp;</span>
								<span style="font-weight: bold;">{{getSortLabel(true)}}</span>			
								<span>
									<mat-icon style="margin-left: -0.1em; margin-top: 0.1em;">arrow_drop_down</mat-icon>
								</span>
				
								<mat-menu #sortmenu="matMenu">
									<button *ngFor="let option of sortOptions" mat-menu-item [disabled]="sort_team == option.sort && sortReverse_team == option.sortReverse" (click)="selectSort(option,true)">{{option.label}}</button>
								</mat-menu>
							</div> -->
						</div>

					</div>
					<ng-container *ngIf="users && users.length > 0; noUsers">
						<app-genericlist 
						[items]="users" 
						[itemIcon]="getUserIcon" 
						[sort]="sort_team" 
						[sortReverse]="sortReverse_team" 
						(clickItem)="clickUser($event)" 
						(infoItem)="clickUser($event)"
						[search]="search_team"
						[searchExclude]="['uuid', 'account_uuid', 'created_at', 'updated_at']">
						</app-genericlist>
					</ng-container>
					<ng-template #noUsers>
						<div class="note">Retrieving user list...</div>
					</ng-template>
					<mat-card-actions *ngIf="users && users.length > 0" style="justify-content: right !important;">
						<!-- <div class="sortActions"> -->
							<div style="margin: 0; cursor:pointer;" [matMenuTriggerFor]="sortmenu">
								<span>sort by:&nbsp;</span>
								<span style="font-weight: bold;">{{getSortLabel(true)}}</span>			
								<span>
									<mat-icon style="margin-left: -0.1em; margin-top: 0.1em;">arrow_drop_down</mat-icon>
								</span>
				
								<mat-menu #sortmenu="matMenu">
									<button *ngFor="let option of sortOptions" mat-menu-item [disabled]="sort_team == option.sort && sortReverse_team == option.sortReverse" (click)="selectSort(option,true)">{{option.label}}</button>
								</mat-menu>
							</div>
						<!-- </div> -->
					</mat-card-actions> 

				</mat-card>
				<mat-card class="card"  style="height:100%">
					
					<div *ngIf="user; else noUser" style="height:100%">
						<div class="title split" style="margin-top: 0.6em;">
							<span>Selected user:</span><span><button mat-icon-button (click)="user = null;"><mat-icon>close</mat-icon></button></span>
						</div>
						<p>{{user.name}} - {{isUserAdmin(user) ? '(Admin)' : '(Non-admin)'}}</p>
						<p *ngIf="!user.active" class="instruction">User has not yet accepted account invite.</p>
						<div class="split" style="margin-bottom: 1em;">
							<button mat-button color="warn" [disabled]="!isAccountAdmin()" (click)="removeUser(user)">Remove User</button>
							<div>
								<button *ngIf="!user.active" mat-stroked-button color="primary" [disabled]="submittingInvite" (click)="reinviteUser(user)" style="margin-right: 0.5em;">Re-send invite</button>
								<button mat-flat-button color="primary" (click)="editUserAdmin(user)">Edit User</button>
							</div>
						</div>
						<mat-divider style="position: relative !important;"></mat-divider>
						<div  style="overflow:auto; height: calc(100% - 110px);">
						<div class="title split">Assigned to:</div>
							<ng-template #noUserData>
								<div class="note">Retrieving user data...</div>
							</ng-template>
							<ng-template #noUserWorkgroups>
								<div class="note">This user has not been assigned to any workgroups.</div>
							</ng-template>
							<div *ngIf="userInfo; else noUserData">
								<mat-accordion *ngIf="userInfo.length > 0; else noUserWorkgroups" multi>
									<mat-expansion-panel *ngFor="let workgroup of userInfo" [disabled]="!workgroup.projects.length">
									  <mat-expansion-panel-header>
										<mat-panel-title>
										  {{workgroup.name}}
										</mat-panel-title>
										<mat-panel-description>
										  Number of projects: {{workgroup.projects.length}}
										  <!--<mat-icon>account_circle</mat-icon>-->
										</mat-panel-description>
									  </mat-expansion-panel-header>					  
									  <mat-list role="list">
										<mat-list-item *ngFor="let project of workgroup.projects">
											{{project.name}}<!-- - {{project.scoped_permissions}} - {{project.tasks}}-->
										</mat-list-item>
									  </mat-list>
									</mat-expansion-panel>
								</mat-accordion>
								<div class="note">To enable a user to add/edit workgroups and projects - set their access level to 'Admin':
									<ol>
										<li>1. Click 'Edit User'</li>
										<li>2. Turn ON the 'Is admin' checkbox</li>
										<li>3. click ...UPDATE</li>
									</ol>
								</div>
							</div>		
						</div>
					</div>	
					<ng-template #noUser>
						<div class="note" style="margin-top:1.2em;">Select a user to see their details.</div>
					</ng-template>			
				</mat-card>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>

