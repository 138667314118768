import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryItem } from '../models/deliveryItem.model';

import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class DeliveryItemService extends OVBaseService<DeliveryItem, string> {
	constructor(protected http: HttpClient) {
		super(http, 'delivery_item');
	}
}
