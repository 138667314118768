
<div *ngIf="!needsPassword" [ngStyle]="{'max-width': (deliveryPackage?.type == 'delivery') ? '630px' : 'unset'}" 
style="
display: flex;
flex-direction: column;
height: 100%;">
	<div class="packageName">
		<span *ngIf="!deliveryPackage">...loading package</span>
		<ng-container *ngIf="deliveryPackage">
			<div >{{(deliveryPackage.type=="delivery" ? 'Delivery' : 'Share') + ' package:'}}
				<span>{{deliveryPackage.name}}</span>
			</div>
			<div *ngIf="deliveryPackage">
				Project: <span>{{deliveryPackage.project.workgroup.name}} - {{deliveryPackage.project.name}}</span>
			</div>
			<div *ngIf="deliveryPackage.type=='delivery'" style="padding-top: 1em;">Download folder priority: <span>{{folderOptionLabel}}</span></div>
			<!--<button  class="btn-copy" (click)="copTextToClipboard(deliveryPackage.name,'package name')" [matTooltip]="'Copy package name'" mat-icon-button><mat-icon>content_copy</mat-icon></button>-->
		</ng-container>
		
	</div>

	<!-- <div *ngIf="!needsPassword && deliveryPackage" class="packageContainer"> -->

	<!--===== DELIVERY =====-->
	<!-- <div *ngIf="!needsPassword && deliveryPackage && deliveryPackage.type == 'delivery'" class="delivery dualCol2"> -->
		<!-- NOTES -->
		<div *ngIf="!needsPassword && deliveryPackage && deliveryPackage.type == 'delivery' && deliveryPackage.notes" class="section deliveryNote">
			<div class="subHeader">Note</div>
			<span class="note">{{deliveryPackage.notes}}</span>
		</div>
	
		<!-- DOWNLOAD BTN-->
		<div *ngIf="!needsPassword && deliveryPackage && deliveryPackage.type == 'delivery' && downloadStatus" xclass="section downloadBtnRow">
			<!--<div xclass="col"> -->
				<button style="vertical-align: baseline; margin: 0 2em 1em 0;" [disabled]="downloadStatus.disabled" mat-raised-button color="primary" (click)="download()">{{downloadStatus.label}}</button>
				<!-- <button *ngIf="deliveryPackage.partialAllowed" [disabled]="isPartiaDownloadDisabled()" mat-raised-button color="primary" (click)="download()">Download all APPROVED items</button> -->
			
				<div style="display: inline-flex;
				align-items: center;
				vertical-align: middle;
				"
				[ngClass]="downloadStatus.color"><mat-icon>{{downloadStatus.icon}}</mat-icon><span class="instruction" [ngClass]="downloadStatus.color">{{downloadStatus.msg}}</span></div>
			<!-- </div> -->	
		</div>

		<!-- <div *ngIf="!needsPassword && deliveryPackage && deliveryPackage.type == 'delivery'" xclass="deliveryLeft col"> -->

			
		
			<!-- CONTENTS-->
			<!-- <div *ngIf="deliveryPackage.delivery_items?.length > 0" > -->
				<fieldset *ngIf="!needsPassword && deliveryPackage && deliveryPackage.type == 'delivery' && deliveryPackage.delivery_items?.length > 0" class="packageFieldset">
					<legend>Package contents</legend>
					<div class="packageContent">
						<div class="workflowGroup" style="margin-bottom: 2em;">Totals: {{deliveryPackage.delivery_items.length}} formats containing {{totalAssetCount}} deliverable assets</div>
						<div class="workflowGroup" *ngFor="let workflow of sortedItems">
							<div *ngIf="sortedItems.length > 1" style="margin-bottom: 1em;">Workflow:  <span class="workflowRow">{{workflow.name}}</span></div>
							<!-- <ng-container *ngIf="deliveryPackage.useFormatPath == false"> -->
								<!-- channel priority -->
								<div class="channelGroup" *ngFor="let channel of workflow.channels">
									<div class="channelRow" >{{channel.channel.name}}</div>
									<div class="creativeGroup" *ngFor="let creative of channel.creatives">
										<div class="creativeRow" [ngClass]="{'inProgress':creative.state!='approved'}">
											<div class="creativeName">{{creative.format.group}} - {{creative.format.name}}</div>
											<div class="assets">
												<!-- <span *ngIf="creative.state=='approved'">{{creative.deliverableCount}} asset(s)</span>
												<span *ngIf="creative.state!='approved'">-</span> -->
												<span *ngIf="creative.deliverableCount > 0">{{creative.deliverableCount}} asset(s)</span>
												<span *ngIf="creative.deliverableCount == 0" class="instruction">No deliverable assets</span>
											</div>
											<div class="status" [ngClass]="{'done':creative.state=='approved'}">{{creative.state=="approved" ? 'approved' : creative.state=="build" ? 'not started':'in-progress'}}</div>
											<button class="singleDownloadBtn small" *ngIf="this.deliveryPackage.actioned && this.deliveryPackage.partialAllowed" [disabled]="creative.deliverableCount == 0 ||isCreativeDownloadDisabled(creative)" (click)="downloadCreative(creative)" mat-stroked-button color="primary">Download</button>
										</div>
									</div>
								</div>
							<!-- </ng-container> -->	

								<!-- format priority 
								 group-format
									channel
										creative (no. assets, approved, download button)
								-->
								<!-- 
								<div class="channelGroup" *ngFor="let format of workflow.formats">
									<div class="channelRow" >{{format.format.group}} - {{format.format.name}}</div>
									<div class="creativeGroup" *ngFor="let creative of format.creatives">
										<div class="creativeRow" [ngClass]="{'inProgress':creative.state!='approved'}">
											<div class="creativeName">{{creative.channel.name}}</div>
											<div class="assets">
												<span *ngIf="creative.state=='approved'">{{creative.assetCount}} asset(s)</span>
												<span *ngIf="creative.state!='approved'">-</span>
											</div>
											<div class="status" [ngClass]="{'done':creative.state=='approved'}">{{creative.state=="approved" ? 'approved' : creative.state=="build" ? 'not started':'in-progress'}}</div>
											<button class="singleDownloadBtn small" *ngIf="this.deliveryPackage.actioned && this.deliveryPackage.partialAllowed" [disabled]="isCreativeDownloadDisabled(creative)" (click)="downloadCreative(creative)" mat-stroked-button color="primary">Download</button>
										</div>
									</div>
								</div>
							-->
							
						</div>
					</div>
				</fieldset>
			<!-- </div>	-->
		<!-- </div> -->
		<!--<div class="deliveryContacts col">
			<div class="subHeader">Contacts:</div>
			<div class="contactRow">
				<span>{{admin_name}}</span>
				<a href="mailto:{{admin_email}}" target="_blank">{{admin_email}}</a>						
			</div>
		</div>-->
		
		<!--
		<mat-accordion>
			<mat-expansion-panel *ngFor="let item of deliveryPackage.delivery_items">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{item.creative.channel.name + " | " + item.creative.name}}
					</mat-panel-title>
				<mat-panel-description>
					({{item.creative.assets.length}})
				</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-list role="list" dense>
					<mat-list-item role="listitem" *ngFor="let asset of item.creative.assets">{{asset.name}}</mat-list-item>
				</mat-list>
			</mat-expansion-panel>
		</mat-accordion>
		-->
	<!-- </div> -->

	
	<!--===== SHARE =====-->
	<div class="share" *ngIf="!needsPassword && deliveryPackage && deliveryPackage.type == 'share'">
	
		<app-warning-message *ngIf="!deliveryPackage.actioned" [message]="'This package has not yet been released by the Project Manager.'"></app-warning-message>	
		<app-warning-message *ngIf="deliveryPackage.actioned && !sortedItems.length" [message]="'There are no items to view in this package'"></app-warning-message>	
		
		<!--left -->
		<div *ngIf="deliveryPackage.actioned && sortedItems.length" class="share-left">
			<mat-selection-list class="shareMenu" #assetList [multiple]="false" (selectionChange)="onItemSelected($event)">
				<div class="workflowGroup" *ngFor="let workflow of sortedItems">
					<div class="workflowRow">{{workflow.name}} </div>
					<div class="channelGroup" *ngFor="let channel of workflow.channels">
						<div class="channelRow">{{channel.channel.name}}</div>
						<div class="creativeGroup" *ngFor="let creative of channel.creatives">
							
							<mat-list-option class="creativeRow" [value]="creative">
								<div class="creativeName">{{creative.format.group}} - {{creative.format.name}}</div>
							</mat-list-option>
							
							<!--<div class="creativeRow">
								<div class="creativeName">{{creative.format.group}} - {{creative.format.name}}</div>
							</div>-->
						</div>
					</div>
				</div>
			</mat-selection-list>
			<!-- asset list -->
				<!--
					<div class="creatives">
						<mat-selection-list #assetList [multiple]="false" (selectionChange)="onItemSelected($event)">
							<mat-list-option *ngFor="let item of deliveryPackage.delivery_items" [value]="item">
								{{item.creative.channel.name }} || {{ item.creative.format.name}}
							</mat-list-option>
					</mat-selection-list>
				</div>
				-->
		</div>

		<!--right-->
		<div *ngIf="deliveryPackage.actioned && sortedItems.length && !selectedItem" class="share-right"> <!-- -->
			<app-warning-message [useIcon]="false" [message]="'Select an item to view from the menu on the left.'"></app-warning-message>
			<!--<mat-icon class="red">warning</mat-icon>
			<span class="instruction"> 
				<span *ngIf="!deliveryPackage.actioned">This package has not yet been released by the Project Manager.</span>
				<span *ngIf="deliveryPackage.actioned"> 
					<span>{{sortedItems.length ? 'Select an item to view from the menu on the left.' : 'There are no items in this package.'}} </span>
				</span>
			</span> -->
		</div>
		
		<div *ngIf="deliveryPackage.actioned && sortedItems.length && selectedItem" class="share-right">
			<!--right-top -->
			<div class="share-right-top">
				<div class="shareCreativeBar">{{selectedItem.workflow_name}} - {{selectedItem.channel.name}} - {{selectedItem.format.group}} - <span>{{selectedItem.format.name}}</span></div>
				<!--<div class="shareLayoutBar">[layout icon]</div>-->
			</div>
			<!--right-bottom--><!--VIEW-->
			<div class="share-right-bottom">
				<!-- <div class="creative" style="width:3000px; height:2000px; background-color: pink;">-->
					<!-- <div *ngIf="selectedItem"> -->
						<!--<div class="layout">
							<div>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='custom', selectedLayout:layout=='custom'}" matTooltip="Custom position" (click)="layout='custom'"><mat-icon>dashboard</mat-icon></button>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='flow', selectedLayout:layout=='flow'}" matTooltip="Flow" (click)="layout='flow'"><mat-icon>view_compact</mat-icon></button>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='vertical left', selectedLayout:layout=='vertical left'}" matTooltip="Vertical Left" (click)="layout='vertical left'"><mat-icon>align_horizontal_left</mat-icon></button>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='vertical', selectedLayout:layout=='vertical'}" matTooltip="Vertical Center" (click)="layout='vertical'"><mat-icon>align_horizontal_center</mat-icon></button>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='horizontal', selectedLayout:layout=='horizontal'}" matTooltip="Horizontal" (click)="layout='horizontal'"><mat-icon>horizontal_distribute</mat-icon></button>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='vertical-auto', selectedLayout:layout=='vertical-auto'}" matTooltip="Vertical" (click)="layout='vertical-auto'"><mat-icon>vertical_distribute</mat-icon></button>
								<button mat-icon-button [ngClass]="{unselectedLayout:layout!='gallery', selectedLayout:layout=='gallery'}" matTooltip="Gallery view" (click)="layout='gallery'"><mat-icon>view_carousel</mat-icon></button>
							</div>
						</div>-->
						<!-- <div> -->
							<app-creative-viewer *ngIf="selectedItem"  [ngClass]="{'checker':bg_transparent}" [ngStyle]="bg_transparent ? {} : {'background': bg_color}"
							[assets]="assets"
							[layout]="layout"
							[galleryIndex]="galleryIndex"
							[transparent]="bg_transparent"
                        	[color]="bg_color" 
							></app-creative-viewer>
						<!-- </div> -->
						
						<!--
							
							<div class="assetsStage">
								<div class="assets" [ngClass]="layout">
									<ng-container *ngFor="let asset of assets; index as i">
										<app-asset 
										[asset]="asset"
										[assets]="assets"
										[index]="i"
										class="asset custom"
										[ngStyle]="{'display':layout=='gallery' && ((!selectedAsset && index != galleryIndex) || (selectedAsset && selectedAsset != asset)) ? 'none' : '', 'width.px':asset.width || '100%', 'height.px':asset.height || '100%', 'left.px':asset.x || 0, 'top.px':asset.y || 0}"
										></app-asset>
										</ng-container>
									</div>
									<div class="gallery-controls" *ngIf="layout=='gallery'">
										<div class="gallery-prev">
											<button [disabled]="assets.length <= 1" (click)="galleryMove(-1)" mat-icon-button><mat-icon>arrow_back_ios</mat-icon></button>
										</div>
										<div class="gallery-next">
											<button [disabled]="assets.length <= 1" (click)="galleryMove(1)" mat-icon-button><mat-icon>arrow_forward_ios</mat-icon></button>
										</div>
									</div>
								</div>
							-->
					<!-- </div> -->
				<!--</div>-->
			</div>	
		</div>
	</div>		
<!-- </div> -->
</div>
<div class="pwContainer" *ngIf="needsPassword" >
	<div class="passw">
		<mat-form-field appearance="fill">
			<mat-label for="password">Package passcode</mat-label>
			<input matInput [(ngModel)]="password">
		</mat-form-field>
		<div>
			<button (click)="loadData()" mat-raised-button color="primary">Submit</button>
		</div>
	</div>
	
</div>


