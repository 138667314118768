import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, OperatorFunction, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { Credentials } from './../auth/credentials';
//import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { Globals } from '../global';


declare var jquery:any;
declare var $ :any;
declare var window :any;

let API_URL = Globals.BASE_API_URL;


// good info on angular subject types
// https://stackoverflow.com/questions/43118769/subject-vs-behaviorsubject-vs-replaysubject-in-angular
@Injectable({
	providedIn: 'root'
})
export class ApiService {


	filterEmpty<T>(): OperatorFunction<T | null | undefined, T> {
		return filter<T>(value => value !== undefined && value !== null);
	}


	private route:string;
	constructor(private http: HttpClient, private router: Router) {
		// could filter? https://stackoverflow.com/questions/33520043/how-to-detect-a-route-change-in-angular
		router.events.forEach((event) => {
			if(event instanceof NavigationEnd) {
				let routerUrl = event.urlAfterRedirects;
				if (routerUrl && typeof routerUrl === 'string') {
					routerUrl = routerUrl.split("#")[0];	// TODO a better way to strip any hashes
					if(this.route == routerUrl) return; // dont call if already done
					this.route = routerUrl;
 
					let parts = routerUrl.slice(1).split('/');
					if(parts.length == 2)
					{
						if(parts[0] == 'workgroup')
							this.loadCrumbs('workgroup', parts[1]);
						else if(parts[0] == 'project')
							this.loadCrumbs('project', parts[1]);
						else if(parts[0] == 'creative')
							this.loadCrumbs('creative', parts[1]);
						//else if(parts[0] == 'delivery') ////why need crumbs for delivery?!!!!! recipient likely not a logged in user
							//this.loadCrumbs('delivery', parts[1]);
					}else{
						this.loadCrumbs('')
					}
				}
				
			}
			// NavigationStart
			// NavigationEnd
			// NavigationCancel
			// NavigationError
			// RoutesRecognized
		  });
		  return;
		router.events.subscribe((router: any) => {
			let routerUrl = router.urlAfterRedirects;
			//console.log("ROUTE changed", routerUrl);
			if (routerUrl && typeof routerUrl === 'string') {
				routerUrl = routerUrl.split("#")[0];	// TODO a better way to strip any hashes
				if(this.route == routerUrl) return; // dont call if already done
				this.route = routerUrl;

				let parts = routerUrl.slice(1).split('/');
				if(parts.length == 3 && parts[1] == 'creative')
				{
					this.loadCrumbs('creative', parts[2]);
				}else if(parts.length == 3 && parts[1] == 'project')
				{
					this.loadCrumbs('project', parts[2]);
				}
				else if(parts.length == 2 && parts[0] == 'workgroup')
				{
					this.loadCrumbs('workgroup', parts[1]);
				}else{
					this.loadCrumbs('')
				}
			}
		});
	}

	
	public TOKENN:BehaviorSubject<string> = new BehaviorSubject<string>(null);

	token:any = '';
	refresh: any = '';
	userObj:any = false;
	oauthObj:any = false;
	creds:any = {};
	roles:any;
	
	// GENERIC APPLICATION FUNCTIONS
	
	public gotoPage(page:any, reload:any=false, extras:NavigationExtras = null){
		if(reload){
			$('body').addClass('reloading');
			extras ? this.router.navigate(['/'+page], extras) : this.router.navigate(['/'+page]);
			setTimeout(() => {
				window.location.reload();
			}, 100);
		}else{
			extras ? this.router.navigate(['/'+page], extras) : this.router.navigate(['/'+page]);
		}
	}
	
	public getFormData(object) {
		const formData = new FormData();
		Object.keys(object).forEach(key => formData.append(key, object[key]));
		return formData;
	}
	
	public setToken(token:any, refresh:any){
		refresh = token; //temp @todo remove this
		if(typeof token == 'undefined' || typeof refresh == 'undefined' || !token || !refresh){
			sessionStorage.removeItem('tmptoken');
			sessionStorage.removeItem('tmprefresh');
			return;
		}
		sessionStorage.setItem('tmptoken', token);
		sessionStorage.setItem('tmprefresh', refresh);
		this.token = token;
		this.TOKENN.next(this.token);
		this.refresh = refresh;
	}
	
	public getToken(){
		if(sessionStorage.getItem('tmptoken')) this.token = sessionStorage.getItem('tmptoken');
		if(sessionStorage.getItem('tmprefresh')) this.refresh = sessionStorage.getItem('tmprefresh');
		if(this.token == '' || !this.token) return false;
		this.TOKENN.next(this.token);
		return {token: this.token, refresh: this.refresh};
	}
	
	private _currentUserSource = new BehaviorSubject<any>(0);
	currentuser = this._currentUserSource.asObservable();
	
	public getCurrentUser(){
		let _user_str:any = sessionStorage.getItem('current_user');
		//console.log("GET CURRENT UER", _user_str);
		let _user:any = JSON.parse(_user_str);
		if(_user){
			_user['meta'] = this.getCurrentUserMeta();
			_user['roles'] = this.getCurrentUserRoles();
			_user['accounts'] = this.getCurrentUserAccounts();
			this._currentUserSource.next(_user);
			return _user;
		}
		return false;
	}
	public setCurrentUser(user_str:string){
		let _user:any = JSON.parse(user_str);
		//console.log("SET CURRENT USER", _user);
		if(_user){
			this.updateCurrentUser(_user, _user["meta"], _user["accounts"], _user["roles"], true);
			this._currentUserSource.next(_user);
			return _user;
		}
		return false;
	}
	
	public getCurrentUserMeta(){
		if(sessionStorage.getItem('current_user_meta')){
			return JSON.parse(sessionStorage.getItem('current_user_meta'));
		}
		return false;
	}
	
	public getCurrentUserRoles(){
		if(sessionStorage.getItem('current_user_roles')){
			return JSON.parse(sessionStorage.getItem('current_user_roles'));
		}
		return false;
	}
	
	public getCurrentUserAccounts(){
		if(sessionStorage.getItem('current_user_accounts')){
			return JSON.parse(sessionStorage.getItem('current_user_accounts'));
		}
		return false;
	}
	
	public updateCurrentUser(_user:any, _meta:any, _accounts:any, _roles:any, force:boolean = false){
		if(sessionStorage.getItem('current_user')){
			let stored_current_user:any = JSON.parse(sessionStorage.getItem('current_user'));
			if(stored_current_user.uuid == _user.uuid){
				sessionStorage.setItem('current_user', JSON.stringify(_user));
				sessionStorage.setItem('current_user_meta', JSON.stringify(_meta));
				sessionStorage.setItem('current_user_accounts', JSON.stringify(_accounts));
				sessionStorage.setItem('current_user_roles', JSON.stringify(_roles));
			}
		}else if(force)
		{
			sessionStorage.setItem('current_user', JSON.stringify(_user));
			sessionStorage.setItem('current_user_meta', JSON.stringify(_meta));
			sessionStorage.setItem('current_user_accounts', JSON.stringify(_accounts));
			sessionStorage.setItem('current_user_roles', JSON.stringify(_roles));
		}
	}
	
	private _crumbs = new Subject<any>();
	crumbs$ = this._crumbs.asObservable();
	public loadCrumbs(type:string, id:string = null)	// TODO type the type
	{
		if(type == '')
		{
			this._crumbs.next([]);
		}else{
			this.http.get(API_URL+'crumbs', {params:{type, id}}).subscribe(res => {
				this._crumbs.next(res["data"]);
			},
			err => {
				this._crumbs.next(err);
			});
		}
	}
	public userOnline()
	{
		this.http.put(API_URL+'userstatus/online', {}).subscribe(
			res => {
				console.log(res);
			},
			err => {
				console.log(err);
			}
		);
	}
	public userOffline()
	{
		this.http.put(API_URL+'userstatus/offline', {}).subscribe(
			res => {
				console.log(res);
			},
			err => {
				console.log(err);
			}
		);
	}
	
	private _getStatusSource = new BehaviorSubject<any>(0);
	getstatus = this._getStatusSource.asObservable();
	public getStatus() {
		this.http.get(API_URL+'status').subscribe(
			res => this._getStatusSource.next(res),
			err => this._getStatusSource.next(err)
		);
	}

	// AUTH FUNCTIONS	
	private _loginSource = new Subject<any>();
	loguserinwithcreds = this._loginSource.asObservable();
	
	public logIn(credentials: Credentials) {
		
		const oauth_creds = {
			'grant_type': 'password',
			'email': credentials.name,
			'password': credentials.pass,
			'remember_me': credentials.remember_me ? true : false,
		}
		
		//const oauthFormData = this.getFormData(oauth_creds);
		//console.log('oauthFormData', oauthFormData);
		
		this.http.post(API_URL+'auth/login', oauth_creds).subscribe(
			res => {
				this.oauthObj = res;
				if(res["status"] != 401)
				{
					this.setToken(res['access_token'], res['refresh_token']);
				}
				this._loginSource.next(res);
				//this.getAppUser();
			},
			err => {
				//this._loginSource.error(err);	// <- this finalises (closes) the subjet breaking the login on next call
				this._loginSource.next(err);
			}
		);
	}
	private _logoutSubject = new Subject<boolean>();
	logoutObservable = this._logoutSubject.asObservable();
	public logOut()
	{
		/*
		document.cookie = '';
		sessionStorage.clear();
		setTimeout(() => {
			window.location.reload();
		}, 500);
		*/
		this.http.get(API_URL+'auth/logout').subscribe(
			res => {
				//console.log("logout success");
				
				document.cookie = '';
				sessionStorage.clear();
				this._logoutSubject.next(true);
				//this.router.navigate(['login']);
				/*
				setTimeout(() => {
					//window.location.reload();
				}, 500);*/
			},
			err => {
				//console.log("logout failed");
				this._logoutSubject.next(false);
				//this.router.navigate(['login']);			
			}
		);
	}
	
	private _activateAccountSource = new BehaviorSubject<any>(0);
	activateaccount = this._activateAccountSource.asObservable();
	public activateAccount(_data:any) {
		this.http.post(API_URL+'auth/signup/activate/'+_data.token, _data).subscribe(
			res => {
				this._activateAccountSource.next(res);
			},
			err => {
				this._activateAccountSource.next(err);
			}
		);
	}
	
	private _signupSource = new BehaviorSubject<any>(0);
	signup = this._signupSource.asObservable();
	public signUp(_data:any) {
		this.http.post(API_URL+'auth/signup', _data).subscribe(
			res => {
				this._signupSource.next(res);
			},
			err => {
				this._signupSource.next(err);
			}
		);
	}

	private _validateSignupSubject = new BehaviorSubject<any>(0);
	validateSignupObservable = this._validateSignupSubject.asObservable();
	public validateSignup(token:any) {
		this.http.get(API_URL+'auth/signup/validate/'+token).subscribe(
			res => {
				this._validateSignupSubject.next(res);
			},
			err => {
				this._validateSignupSubject.next(err);
			}
		);
	}
	
	private _validateresetpasswordSource = new BehaviorSubject<any>(0);
	validateresetpassword = this._validateresetpasswordSource.asObservable();
	public validateResetPassword(token:any) {
		this.http.get(API_URL+'password/validate/'+token).subscribe(
			res => {
				this._validateresetpasswordSource.next(res);
			},
			err => {
				this._validateresetpasswordSource.next(err);
			}
		);
	}
	
	private _requestpasswordSource = new ReplaySubject<any>(1);
	requestpassword = this._requestpasswordSource.asObservable();
	public requestPassword(_data:any) {
		this.http.post(API_URL+'password/request', _data).subscribe(
			res => this._requestpasswordSource.next(res),
			err => this._requestpasswordSource.next(err)
		);
	}
	
	private _resetpasswordSource = new BehaviorSubject<any>(0);
	resetpassword = this._resetpasswordSource.asObservable();
	public resetPassword(_data:any) {
		this.http.post(API_URL+'password/reset', _data).subscribe(
			res => this._resetpasswordSource.next(res),
			err => this._resetpasswordSource.next(err)
		);
	}
	
	private _reInviteSource = new ReplaySubject<any>(1);
	reinvite = this._reInviteSource.asObservable();
	public reInvite(_data:any) {
		this.http.post(API_URL+'auth/reinvite',_data).subscribe(
			res => this._reInviteSource.next(res),
			err => this._reInviteSource.next(err)
		);
	}

	// USERS
	private _usersetactiveSource = new BehaviorSubject<any>(0);
	setuserisactive = this._usersetactiveSource.asObservable();
	public setUserIsActive(_data:any, caller:any=false) {
		this.http.post(API_URL+'user/'+_data.action, _data).subscribe(
			res => {
				this._usersetactiveSource.next({data:res, caller:caller});
			},
			err => {
				this._usersetactiveSource.next(err);
			}
		);
	}
	
	private _uploadprofileimageSource = new BehaviorSubject<any>(0);
	uploadprofileimage = this._uploadprofileimageSource.asObservable();
	public uploadProfileImage(_data:any, caller:any=false) {
		
		let fd = new FormData();
		fd.append('file', _data.file, _data.file.name);

		this.http.post(API_URL+'user/'+_data.uuid+'/profile_image', fd).subscribe(
			res => {
				this._uploadprofileimageSource.next(res);
			},
			err => {
				this._uploadprofileimageSource.next(err);
			}
		);
	}
	
	private _userbyidSource = new BehaviorSubject<any>(0);
	getuserbyid = this._userbyidSource.asObservable();
	public getUserById(uuid:any, caller:any=false) {
		this.http.post(API_URL+'user/profile', {uuid:uuid}).subscribe(
			res => {
				this._userbyidSource.next({data:res, caller:caller});
			},
			err => {
				this._userbyidSource.next(err);
			}
		);
	}
	
	private _updateuserSource = new BehaviorSubject<any>(0);
	updateuser = this._updateuserSource.asObservable();
	public updateUser(_data:any, caller:any=false) {
		this.http.post(API_URL+'user/update', _data).subscribe(
			res => {
				this._updateuserSource.next(res);
			},
			err => {
				this._updateuserSource.next(err);
			}
		);
	}
	
	private _useraccessbyidSource = new BehaviorSubject<any>(0);
	getuseraccessbyid = this._useraccessbyidSource.asObservable();
	public getUserAccessById(id:any, caller:any=false) {
		this.http.post(API_URL+'access/get', {user_id:id}).subscribe(
			res => {
				this._useraccessbyidSource.next(res);
			},
			err => {
				this._useraccessbyidSource.next(err);
			}
		);
	}
	
	private _currentuseraccessSource = new BehaviorSubject<any>(0);
	getcurrentuseraccess = this._currentuseraccessSource.asObservable();
	public getCurrentUserAccess(id:any, caller:any=false) {
		this.http.post(API_URL+'access/get', {user_id:id}).subscribe(
			res => {
				this._currentuseraccessSource.next(res);
			},
			err => {
				this._currentuseraccessSource.next(err);
			}
		);
	}
	
	
	private _deleteuserSource = new BehaviorSubject<any>(0);
	deleteuser = this._deleteuserSource.asObservable();
	public deleteUser(_data:any, caller:any=false) {
		this.http.post(API_URL+'user/delete', _data).subscribe(
			res => {
				this._deleteuserSource.next(res);
			},
			err => {
				this._deleteuserSource.next(err);
			}
		);
	}
	
	
	private _userSource = new BehaviorSubject<any>(0);
	getuser = this._userSource.asObservable();
	public getUser(caller:any=false) {
		this.http.get(API_URL+'user/profile').subscribe(
			res => {
				this._userSource.next(res);
			},
			err => {
				this._userSource.next(err);
			}
		);
	}
	
	private _allusersSource = new BehaviorSubject<any>(0);
	getallusers = this._allusersSource.asObservable();
	public getAllUsers(_data:any = false, caller:any=false) {
		if(_data){
			//console.log('api/user/list', _data);
			this.http.post(API_URL+'user/list', _data).subscribe(
				res => {
					res['caller'] = caller;
					this._allusersSource.next(res);
				},
				err => {
					this._allusersSource.next(err);
				}
			);
		}else{
			this.http.get(API_URL+'user/list').subscribe(
				res => {
					res['caller'] = caller;
					this._allusersSource.next(res);
				},
				err => {
					this._allusersSource.next(err);
				}
			);
		}
		
	}
	
	private _usersforaccountSource = new BehaviorSubject<any>(0);
	getusersforaccount = this._usersforaccountSource.asObservable();
	public getUsersForAccount(_data:any, caller:any=false) {
		//console.log('getUsersForAccount', _data);
		this.http.post(API_URL+'user/account', _data).subscribe(
			res => {
				res['caller'] = caller;
				this._usersforaccountSource.next(res);
			},
			err => {
				this._usersforaccountSource.next(err);
			}
		);
	}
	
	private _usersforworkgroupSource = new BehaviorSubject<any>(0);
	getusersforworkgroup = this._usersforworkgroupSource.asObservable();
	public getUsersForWorkgroup(_data:any, caller:any=false) {
		this.http.post(API_URL+'user/workgroup', _data).subscribe(
			res => {
				res['caller'] = caller;
				this._usersforworkgroupSource.next(res);
			},
			err => {
				this._usersforworkgroupSource.next(err);
			}
		);
	}
	
	private _usersforprojectSource = new BehaviorSubject<any>(0);
	getusersforproject = this._usersforprojectSource.asObservable();
	public getUsersForProject(_data:any, caller:any=false) {
		this.http.post(API_URL+'user/project', _data).subscribe(
			res => {
				this._usersforprojectSource.next(res);
			},
			err => {
				this._usersforprojectSource.next(err);
			}
		);
	}
	
	// ACCOUNTS
	
	private _allaccountsSource = new BehaviorSubject<any>(0);
	getallaccounts = this._allaccountsSource.asObservable();
	public getAllAccounts(caller:any=false) {
		this.http.get(API_URL+'account/list').subscribe(
			res => {
				this._allaccountsSource.next(res);
			},
			err => {
				this._allaccountsSource.next(err);
			}
		);
	}
	
	private _addaccountSource = new BehaviorSubject<any>(0);
	addaccount = this._addaccountSource.asObservable();
	public addAccount(_data:any, caller:any=false) {
		this.http.post(API_URL+'account/add', _data).subscribe(
			res => {
				this._addaccountSource.next(res);
			},
			err => {
				this._addaccountSource.next(err);
			}
		);
	}
	
	private _getaccountconfigSource = new BehaviorSubject<any>(0);
	getaccountconfig = this._getaccountconfigSource.asObservable();
	public getAccountConfig(_data:any, caller:any=false) {
		
		let cached_config_str:any = sessionStorage.getItem('account_config_'+_data.account_id);
		if(cached_config_str && cached_config_str !== 'undefined'){
			this._getaccountconfigSource.next(JSON.parse(cached_config_str));
		}
		
		this.http.post(API_URL+'account/config', _data).subscribe(
			res => {
				sessionStorage.setItem('account_config_'+_data.account_id, JSON.stringify(res['data']));
				this._getaccountconfigSource.next(res['data']);
			},
			err => {
				this._getaccountconfigSource.next(err);
			}
		);
	}
	
	// WORKGROUPS
	
	private _allworkgroupsSource = new BehaviorSubject<any>(0);
	getallworkgroups = this._allworkgroupsSource.asObservable();
	public getAllWorkgroups(caller:any=false) {
		this.http.get(API_URL+'workgroup/list').subscribe(
			res => {
				this._allworkgroupsSource.next(res);
			},
			err => {
				this._allworkgroupsSource.next(err);
			}
		);
	}
	
	private _getworkgroupsforaccountSource = new BehaviorSubject<any>(0);
	getworkgroupsforaccount = this._getworkgroupsforaccountSource.asObservable();
	public getWorkgroupsForAccount(_data:any, caller:any=false) {
		this.http.post(API_URL+'workgroup/list', _data).subscribe(
			res => {
				this._getworkgroupsforaccountSource.next(res);
			},
			err => {
				this._getworkgroupsforaccountSource.next(err);
			}
		);
	}
	
	private _addworkgroupSource = new BehaviorSubject<any>(0);
	addworkgroup = this._addworkgroupSource.asObservable();
	public addWorkgroup(_data:any, caller:any=false) {
		this.http.post(API_URL+'workgroup/add', _data).subscribe(
			res => {
				this._addworkgroupSource.next(res);
			},
			err => {
				this._addworkgroupSource.next(err);
			}
		);
	}
	
	private _deleteworkgroupSource = new BehaviorSubject<any>(0);
	deleteworkgroup = this._deleteworkgroupSource.asObservable();
	public deleteWorkgroup(_data:any, caller:any=false) {
		this.http.post(API_URL+'workgroup/remove', _data).subscribe(
			res => {
				this._deleteworkgroupSource.next(res);
			},
			err => {
				this._deleteworkgroupSource.next(err);
			}
		);
	}
	
	private _updateworkgroupSource = new BehaviorSubject<any>(0);
	updateworkgroup = this._updateworkgroupSource.asObservable();
	public updateWorkgroup(_data:any, caller:any=false) {
		this.http.post(API_URL+'workgroup/update', _data).subscribe(
			res => {
				this._updateworkgroupSource.next(res);
			},
			err => {
				this._updateworkgroupSource.next(err);
			}
		);
	}
	
	// FOLDERS
	
	private _allfoldersSource = new BehaviorSubject<any>(0);
	getallfolders = this._allfoldersSource.asObservable();
	public getAllFolders(caller:any=false) {
		this.http.get(API_URL+'folder/list').subscribe(
			res => {
				this._allfoldersSource.next(res);
			},
			err => {
				this._allfoldersSource.next(err);
			}
		);
	}
	
	private _addfolderSource = new BehaviorSubject<any>(0);
	addfolder = this._addfolderSource.asObservable();
	public addFolder(_data:any, caller:any=false) {
		this.http.post(API_URL+'folder/add', _data).subscribe(
			res => {
				this._addfolderSource.next(res);
			},
			err => {
				this._addfolderSource.next(err);
			}
		);
	}
	
	private _deletefolderSource = new BehaviorSubject<any>(0);
	deletefolder = this._deletefolderSource.asObservable();
	public deleteFolder(_data:any, caller:any=false) {
		this.http.post(API_URL+'folder/remove', _data).subscribe(
			res => {
				this._deletefolderSource.next(res);
			},
			err => {
				this._deletefolderSource.next(err);
			}
		);
	}
	
	private _updatefolderSource = new BehaviorSubject<any>(0);
	updatefolder = this._updatefolderSource.asObservable();
	public updateFolder(_data:any, caller:any=false) {
		this.http.post(API_URL+'folder/update', _data).subscribe(
			res => {
				this._updatefolderSource.next(res);
			},
			err => {
				this._updatefolderSource.next(err);
			}
		);
	}
	
	// PROJECTS
	
	private _allprojectsSource = new BehaviorSubject<any>(0);
	getallprojects = this._allprojectsSource.asObservable();
	public getAllProjects(caller:any=false) {
		this.http.get(API_URL+'project/list').subscribe(
			res => {
				this._allprojectsSource.next(res);
			},
			err => {
				this._allprojectsSource.next(err);
			}
		);
	}
	
	private _getprojectbyidSource = new BehaviorSubject<any>(0);
	getprojectbyid = this._getprojectbyidSource.asObservable();
	public getProjectById(_data:any, caller:any=false) {
		this.http.post(API_URL+'project/get', _data).subscribe(
			res => {
				res['caller'] = caller;
				this._getprojectbyidSource.next(res);
			},
			err => {
				this._getprojectbyidSource.next(err);
			}
		);
	}
	
	private _addprojectSource = new BehaviorSubject<any>(0);
	addproject = this._addprojectSource.asObservable();
	public addProject(_data:any, caller:any=false) {
		this.http.post(API_URL+'project/add', _data).subscribe(
			res => {
				this._addprojectSource.next(res);
			},
			err => {
				this._addprojectSource.next(err);
			}
		);
	}
	
	private _deleteprojectSource = new BehaviorSubject<any>(0);
	deleteproject = this._deleteprojectSource.asObservable();
	public deleteProject(_data:any, caller:any=false) {
		this.http.post(API_URL+'project/remove', _data).subscribe(
			res => {
				this._deleteprojectSource.next(res);
			},
			err => {
				this._deleteprojectSource.next(err);
			}
		);
	}
	
	private _updateprojectSource = new BehaviorSubject<any>(0);
	updateproject = this._updateprojectSource.asObservable();
	public updateProject(_data:any, caller:any=false) {
		this.http.post(API_URL+'project/update', _data).subscribe(
			res => {
				this._updateprojectSource.next(res);
			},
			err => {
				this._updateprojectSource.next(err);
			}
		);
	}
	
	// SEGMENTS
	
	private _getsegmentsforprojectSource = new BehaviorSubject<any>(0);
	getsegmentsforproject = this._getsegmentsforprojectSource.asObservable();
	public getSegmentsForProject(_data:any, caller:any=false) {
		this.http.get(API_URL+'segments/project').subscribe(
			res => {
				this._getsegmentsforprojectSource.next(res);
			},
			err => {
				this._getsegmentsforprojectSource.next(err);
			}
		);
	}
	
	private _listsegmentlabelSource = new BehaviorSubject<any>(0);
	listsegmentlabel = this._listsegmentlabelSource.asObservable();
	public listSegmentLabel(_data:any, caller:any=false) {
		_data.action = 'list';
		this.http.post(API_URL+'segment/label', _data).subscribe(
			res => {
				this._listsegmentlabelSource.next(res);
			},
			err => {
				this._listsegmentlabelSource.next(err);
			}
		);
	}
	
	private _addsegmentlabelSource = new BehaviorSubject<any>(0);
	addsegmentlabel = this._addsegmentlabelSource.asObservable();
	public addSegmentLabel(_data:any, caller:any=false) {
		_data.action = 'add';
		this.http.post(API_URL+'segment/label', _data).subscribe(
			res => {
				this._addsegmentlabelSource.next(res);
			},
			err => {
				this._addsegmentlabelSource.next(err);
			}
		);
	}
	
	private _removesegmentlabelSource = new BehaviorSubject<any>(0);
	removesegmentlabel = this._removesegmentlabelSource.asObservable();
	public removeSegmentLabel(_data:any, caller:any=false) {
		_data.action = 'remove';
		this.http.post(API_URL+'segment/label', _data).subscribe(
			res => {
				this._removesegmentlabelSource.next(res);
			},
			err => {
				this._removesegmentlabelSource.next(err);
			}
		);
	}
	
	private _addsegmentSource = new BehaviorSubject<any>(0);
	addsegment = this._addsegmentSource.asObservable();
	public addSegment(_data:any, caller:any=false) {
		this.http.post(API_URL+'segment/add', _data).subscribe(
			res => {
				this._addsegmentSource.next(res);
			},
			err => {
				this._addsegmentSource.next(err);
			}
		);
	}
	
	private _removesegmentSource = new BehaviorSubject<any>(0);
	removesegment = this._removesegmentSource.asObservable();
	public removeSegment(_data:any, caller:any=false) {
		this.http.post(API_URL+'segment/remove', _data).subscribe(
			res => {
				this._removesegmentSource.next(res);
			},
			err => {
				this._removesegmentSource.next(err);
			}
		);
	}
	
	// CREATIVES
	
	private _getcreativesforsegmentSource = new BehaviorSubject<any>(0);
	getcreativesforsegment = this._getcreativesforsegmentSource.asObservable();
	public getCreativesForSegment(_data:any, caller:any=false) {
		this.http.get(API_URL+'creatives/segment').subscribe(
			res => {
				this._getcreativesforsegmentSource.next(res);
			},
			err => {
				this._getcreativesforsegmentSource.next(err);
			}
		);
	}
	
	private _getcreativeSource = new BehaviorSubject<any>(0);
	getcreative = this._getcreativeSource.asObservable();
	public getCreative(_data:any, caller:any=false) {
		this.http.post(API_URL+'creative/get', _data).subscribe(
			res => {
				this._getcreativeSource.next(res);
			},
			err => {
				this._getcreativeSource.next(err);
			}
		);
	}
	
	private _addcreativeSource = new BehaviorSubject<any>(0);
	addcreative = this._addcreativeSource.asObservable();
	public addCreative(_data:any, caller:any=false) {
		this.http.post(API_URL+'creative/add', _data).subscribe(
			res => {
				this._addcreativeSource.next(res);
			},
			err => {
				this._addcreativeSource.next(err);
			}
		);
	}
	
	private _removecreativeSource = new BehaviorSubject<any>(0);
	removecreative = this._removecreativeSource.asObservable();
	public removeCreative(_data:any, caller:any=false) {
		this.http.post(API_URL+'creative/remove', _data).subscribe(
			res => {
				this._removecreativeSource.next(res);
			},
			err => {
				this._removecreativeSource.next(err);
			}
		);
	}
	
	// ASSETS
	
	private _getassetsforcreativeSource = new BehaviorSubject<any>(0);
	getassetsforcreative = this._getassetsforcreativeSource.asObservable();
	public getAssetsForCreative(_data:any, caller:any=false) {
		this.http.post(API_URL+'assets/creative', _data).subscribe(
			res => {
				this._getassetsforcreativeSource.next(res);
			},
			err => {
				this._getassetsforcreativeSource.next(err);
			}
		);
	}
	
	private _addassetSource = new BehaviorSubject<any>(0);
	addasset = this._addassetSource.asObservable();
	public addAsset(_data:any, caller:any=false) {
		this.http.post(API_URL+'asset/add', _data).subscribe(
			res => {
				this._addassetSource.next(res);
			},
			err => {
				this._addassetSource.next(err);
			}
		);
	}
	
	private _removeassetSource = new BehaviorSubject<any>(0);
	removeasset = this._removeassetSource.asObservable();
	public removeAsset(_data:any, caller:any=false) {
		this.http.post(API_URL+'asset/remove', _data).subscribe(
			res => {
				this._removeassetSource.next(res);
			},
			err => {
				this._removeassetSource.next(err);
			}
		);
	}
	
	private _getassetmetaSource = new BehaviorSubject<any>(0);
	getassetmeta = this._getassetmetaSource.asObservable();
	public getAssetMeta(uuid:any, caller:any=false) {
		this.http.get(API_URL+'asset/'+uuid+'/meta').subscribe(
			res => {
				this._getassetmetaSource.next(res);
			},
			err => {
				this._getassetmetaSource.next(err);
			}
		);
	}
	
	private _updateassetSource = new BehaviorSubject<any>(0);
	updateasset = this._updateassetSource.asObservable();
	public updateAsset(_data:any, caller:any=false) {
		this.http.post(API_URL+'asset/'+_data.uuid+'/update', _data).subscribe(
			res => {
				this._updateassetSource.next(res);
			},
			err => {
				this._updateassetSource.next(err);
			}
		);
	}
	
	private _uploadassetSource = new BehaviorSubject<any>(0);
	uploadasset = this._uploadassetSource.asObservable();
	public uploadAsset(_data:any, caller:any=false) {
		
		let fd = new FormData();
		fd.append('file', _data.file, _data.file.name);

		this.http.post(API_URL+'asset/'+_data.uuid+'/upload', fd).subscribe(
			res => {
				this._uploadassetSource.next(res);
			},
			err => {
				this._uploadassetSource.next(err);
			}
		);
	}

	// TREE
	private _getTreeForAccountSource = new BehaviorSubject<any>(0);
	gettreeforaccount = this._getTreeForAccountSource.asObservable();	
	public getTreeForAccount(account_id:any, workgroup_id:any = false) {
		let _data:any = {account_uuid: account_id};
		if(workgroup_id){
			_data.workgroup_uuid = workgroup_id;
		}
		
		//console.log('getTreeForAccount', _data);
		
		this.http.post(API_URL+'tree/get', _data).subscribe(
			res => {
				this._getTreeForAccountSource.next(res);
			},
			err => {
				this._getTreeForAccountSource.next(err);
			}
		);
	};
	
	private _getTreeForUserSource = new BehaviorSubject<any>(0);
	gettreeforuser = this._getTreeForUserSource.asObservable();	
	public getTreeForUser(uuid:any) {
		this.http.get(API_URL+'tree/user/'+uuid).subscribe(
			res => {
				this._getTreeForUserSource.next(res);
			},
			err => {
				this._getTreeForUserSource.next(err);
			}
		);
	};
	
	
	// project
	private _projectSource = new BehaviorSubject<any>(0);
	_project = this._projectSource.asObservable();	
	public project(id: string) {
		this.http.get(API_URL+'project/'+id).subscribe(
			res => {
				this._projectSource.next(res);
			},
			err => {
				this._projectSource.next(err);
			}
		);
	};
	
	
	// assign multiple users to a container
	private _assignmultipleusersSource = new BehaviorSubject<any>(0);
	assignmultipleusers = this._assignmultipleusersSource.asObservable();	
	public assignMultipleUsers(_data:any) {
		this.http.post(API_URL+'access/grant_multiple', _data).subscribe(
			res => {
				this._assignmultipleusersSource.next(res);
			},
			err => {
				this._assignmultipleusersSource.next(err);
			}
		);
	};

	private _saveSource = new BehaviorSubject<any>(0);
	_save = this._saveSource.asObservable();	
	public save(creative_uuid:string, assets:any[])
	{
		let formData = new FormData();
		/*
		for (let i = 0; i < assets.length; i++) {
			const asset:any= assets[i];
			for (var prop in asset) {
                if (asset.hasOwnProperty(prop))
                {
					console.log("appending", `assets[${i}][${prop}]`);
                    formData.append(`assets[${i}][${prop}]`, asset[prop]);
                    if(prop === "file")
                    {
                        formData.append(`assets[${i}][filename]`, asset.file.name);
                        formData.append(`assets[${i}][filetype]`, asset.file.type);
						formData.append('file', asset[prop], asset[prop].name);
                    }
                }
            }
		}*/
		formData.append('file', assets[0].file, assets[0].file.name);
		//console.log("saving fd", formData);
		this.http.post(API_URL + `api/assets2/${creative_uuid}`, formData).subscribe(
			res => {
				//console.log("save result", res);
				this._saveSource.next(res);
			},
			err => {
				//console.log("save result err", err);
				this._saveSource.next(err);
			}
		);
	}
}
