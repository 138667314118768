import { Component, OnInit, OnDestroy, Inject, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { StringsService } from './../../services/strings.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { map, tap, takeUntil, take, startWith} from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, public strings: StringsService) { }
	
	current_user:any = {};
	user:any = {};
	user_meta:any = {};
	user_accounts:any = [];
	user_roles:any = [];
	heading:any = '';
	photo_url:any = false;
	getUserDelay:any = false;
	my_profile:any = true;
	user_access:any = false;
	
	
	visible = true;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	workgroupCtrl = new FormControl();
	workgroups:any = [];
	allworkgroups: any = [];
	
	@ViewChild('workgroupInput') workgroupInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	
	options:any = [];
	filteredOptions: Observable<any>;
	
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		
		this.current_user = this.api.getCurrentUser();
		this.api.currentuser.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.current_user = res;
			//console.log('this.current_user', this.current_user);
		});
		
		this.filteredOptions = this.workgroupCtrl.valueChanges.pipe(
	        startWith(''),
	        map(value => this._filter(value))
		);
		
		this.subscription = this.route.params.subscribe(params => {
			if(params['uuid']){
				this.my_profile = false;
				this.heading = '';
				this.user = {};
				this.user_meta = {};
				this.user.uuid = params['uuid'];
				clearTimeout(this.getUserDelay);
				$('body').addClass('saving').addClass('loading');
				this.getUserDelay = setTimeout(() => {
					this.subscription = this.api.getuserbyid.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						if(!res || res === 0 || res.caller !== this) return;
						this.user = res.data.data.user;
						this.user_meta = res.data.data.meta;
						this.user_accounts = res.data.data.accounts;
						this.user_roles = res.data.data.roles;
						this.setupUserProfile(false);
					});
					this.api.getUserById(this.user.uuid, this);
				}, 400);
			}else{
				this.my_profile = true;
				this.user = this.api.getCurrentUser();
				this.user_meta = this.api.getCurrentUserMeta();
				this.user_accounts = this.api.getCurrentUserAccounts();
				this.setupUserProfile(true);
			}
			this.subscription = this.api.getuseraccessbyid.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				this.setupUserAccess(res);
			});
		});
	}
	
	private _filter(value: any) {
		if(value && typeof value.toLowerCase !== 'undefined'){
			const filterValue:any = value.toLowerCase().replace(' ', '');
			let option:any = this.options[0];
			if(typeof option == 'undefined'){
				return false;
			}
			return this.options.filter(
				option => option.name.toLowerCase().includes(filterValue)
			);
		}
	}
	
	////// WORKGROUPS CHIPS //////
	
	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		this.workgroups.push(value);
		if (input) {
			input.value = '';
		}
		this.workgroupCtrl.setValue(null);	
	}

	remove(workgroup_id: any): void {
		for(var i in this.workgroups){
			if(this.workgroups[i].uuid == workgroup_id){
				this.workgroups.splice(i, 1);
			}
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.workgroups.push(event.option.value);
		this.workgroupInput.nativeElement.value = '';
		this.workgroupCtrl.setValue(null);
	}
	
	/////////////////////////
	
	setupUserProfile(me:any){
		if(!this.user) return;
		//this.heading = me ? this.strings.getHeading('my_profile') : this.user.name;
		
		if(typeof this.user['company'] == 'undefined'){
			this.user['company'] = 'Example Company';
		}
		
		if(typeof this.user_meta !== 'undefined' && typeof this.user_meta.profile_image !== 'undefined'){
			this.photo_url = this.user_meta.profile_image;
			setTimeout(() => {
				$('[data-profile-url]').each((index, element) => {
					$(element).css('background-image', 'url('+$(element).attr('data-profile-url')+')');
				});
			}, 150);
		}
		this.heading = this.user.name;
		this.api.getUserAccessById(this.user.uuid);
		$('body').removeClass('saving').removeClass('loading');
	}
	
	setupUserAccess(res:any){
		
		this.workgroups = res.containers.workgroups;
		
		//@todo - change this to "get workgroups for account... and/or show the accounts if multiple"
		this.subscription = this.api.getworkgroupsforaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.allworkgroups = res.data;
			this.options = res.data;
		});
		for(let i in this.user_accounts){
			this.api.getWorkgroupsForAccount({account_id:this.user_accounts[i].uuid});
		}
	}
	
	saveUserProfile(){
		
		let name_val:any = $('#name').val();
		let email_val:any = $('#email').val();
		let job_val:any = $('#job').val();
		
		let _user:any = {
			uuid: this.user.uuid,
			name: (typeof name_val !== 'undefined') ? name_val : this.user.name,
			email: (typeof email_val !== 'undefined') ? email_val : this.user.email,
			job_description: (typeof job_val !== 'undefined') ? job_val : this.user.job_description,
		};
		
		this.subscription = this.api.updateuser.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			$('body').removeClass('saving').removeClass('loading');
			for(let i in _user){
				this.user[i] = _user[i];
			}
			this.api.updateCurrentUser(this.user, this.user_meta, this.user_accounts, this.user_roles);
			this.api.getUserAccessById(this.user.uuid);
		});
		$('body').addClass('saving').addClass('loading');
		this.api.updateUser({user:_user, access: {workgroups:this.workgroups}});
	}
	
	activateUser(){

		// @todo: add confirm dialog?

		let _user:any = {
			uuid: this.user.uuid
		};
		this.subscription = this.api.setuserisactive.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			$('body').removeClass('saving').removeClass('loading');
			window.location.reload();
		});
		$('body').addClass('saving').addClass('loading');
		this.api.setUserIsActive({user:_user, action:'activate'});
	}
	deactivateUser(){
		
		// @todo: add confirm dialog?
		
		let _user:any = {
			uuid: this.user.uuid
		};
		this.subscription = this.api.setuserisactive.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			$('body').removeClass('saving').removeClass('loading');
			window.location.reload();
		});
		$('body').addClass('saving').addClass('loading');
		this.api.setUserIsActive({user:_user, action:'deactivate'});
	}
	
	ngOnDestroy() {
		this.user = null;
		this.user_meta = null;
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}