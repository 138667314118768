<span *ngFor="let crumb of crumbs; let last = last; let first = first">
	<span *ngIf="!last && !first">
		<i class="material-icons">chevron_right</i>
	</span>
	<a class="c-breadcrumbs__crumb" href="{{crumb.url}}" *ngIf="!last">
		{{crumb.label}}
	</a>
	<div *ngIf="last">
		<a class="c-breadcrumbs__crumb" href="{{crumb.url}}">{{crumb.label}}</a>
	</div>
</span>
