
<div class="caption">
	<div class="ui">
		<div>
			<mat-button-toggle-group [(ngModel)]="mode" #group="matButtonToggleGroup">	<!-- [hideSingleSelectionIndicator]="true"-->
				<mat-button-toggle value="source" matTooltip="Source edit">
					<mat-icon>code_blocks</mat-icon>
				</mat-button-toggle>
				<mat-button-toggle value="preview" matTooltip="Preview">
					<mat-icon>preview</mat-icon>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div>
			<ng-container *ngIf="false && mode == 'source'">
				<button mat-icon-button color="primary" (click)="align('left')" matTooltip="Align left"><mat-icon>format_align_left</mat-icon></button>
				<button mat-icon-button color="primary" (click)="align('center')" matTooltip="Align center"><mat-icon>format_align_center</mat-icon></button>
				<button mat-icon-button color="primary" (click)="align('right')" matTooltip="Align right"><mat-icon>format_align_right</mat-icon></button>
				<input type="color" matTooltip="Font colour">
				<!--<input type="color" matTooltip="Background colour">-->
			</ng-container>
		</div>
	</div>
	<div class="content">
			<pre
				#editor
				[attr.contenteditable]="mode == 'source'"
				(input)="onChange($event)"
				[ngClass]="assetVO.asset.metadata.align"
				[ngStyle]="{color:assetVO.asset.metadata.color || '#000', fontSize:assetVO.asset.metadata.size || '16px'}"
			>
			</pre>
		<ng-container *ngIf="false && mode == 'preview'">
			<div class="caption2" [ngClass]="assetVO.asset.metadata.align" [ngStyle]="{color:assetVO.asset.metadata.color || '#000', fontSize:assetVO.asset.metadata.size || '16px'}">
				{{text}}
			</div>
		</ng-container>
	</div>
</div>

