import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreativeService } from 'src/app/api/creative.service';
import { ProjectService } from 'src/app/api/project.service';
import { Creative } from 'src/app/models/creative.model';

export interface CopyCreativeDialogData {
	creative: Creative;
}


@Component({
  selector: 'app-copy-creative-dialog',
  templateUrl: './copy-creative-dialog.component.html',
  styleUrls: ['./copy-creative-dialog.component.scss']
})
export class CopyCreativeDialogComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<boolean>();

  public form: FormGroup;
  public availableChannels:any[];
  public channels:any[];  // a lookup for id by name  
  public copyToChannelCount: number = 0;
  public copyAssets:boolean = false;
  public copyLabel:boolean = false;
  public copySpec:boolean = false;

  constructor(public dialogRef: MatDialogRef<CopyCreativeDialogComponent>,
              public creativeService:CreativeService,
              public projectService:ProjectService,
              public formBuilder:FormBuilder,
              public snackBar:MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: CopyCreativeDialogData) { }
  

  ngOnInit(): void {
    this.form = new FormGroup({});
    if(this.data.creative)
    {
      //console.log("copy", this.data.creative);
      this.channels = [];
      // show the available channels to copy this creative to
      this.creativeService.getAvailableChannels(this.data.creative.uuid).pipe(takeUntil(this.destroyed)).subscribe(response => {
        this.availableChannels = response.data;
        
        for (let i = 0; i < this.availableChannels.length; i++) {
          const channel:any = this.availableChannels[i];
          let control =  new FormControl({value:!channel.available, disabled:!channel.available});
          this.availableChannels[i].control = control;
          this.form.addControl(channel.channel.name, control);
          this.channels[channel.channel.name] = channel.channel.uuid;
          if(channel.available) this.copyToChannelCount ++;
        }
        this.form.setValidators(this.requireCheckboxesToBeCheckedValidator());
        this.form.valueChanges.subscribe((value) => {
          // loop throug all value values and check if at least one is true, if so enable copy button
          //console.log("change", value, this.form.value)
          //console.log("valid", this.form.valid);
        });
  
        /*
        this.form = this.formBuilder.group({
          checkbox1: [''],
          checkbox2: [''],
          checkbox3: [''],
        });
      
        this.form.setErrors({required: true});
        this.form.valueChanges.subscribe((newValue) => {
          if (newValue.checkbox1 === true || newValue.checkbox2 === true || newValue.checkbox3 === true) {
            this.form.setErrors(null);
          } else {
            this.form.setErrors({required: true});
          }
        });*/

      });
      /*
      this._snackBar.open("Setting label", null, {duration: 4000});
      this.creativeService.setLabel(this.creative_uuid, color).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
        this._snackBar.open("label set", null, {duration: 2000});
        //this.mergeResponse(response)}
      });*/
    }
  }
  copy()
  {
    let copies:any[] = [];
    for (const key in this.form.value) {
      if(this.form.value[key] == true)
      {
        copies.push({id:this.channels[key], copyAssets:this.copyAssets, copyLabel:this.copyLabel, copySpec:this.copySpec});
      }
    } 
    // todo
    this.snackBar.open("copying creative", null, {duration: 4000});
		this.creativeService.copy(this.data.creative.uuid, copies).pipe(takeUntil(this.destroyed)).subscribe(response => {
			this.snackBar.open("creative copied", null, {duration: 2000});
      this.close();
		});
  }

  close(){
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }
  private requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate (formGroup: FormGroup) {
      let checked = 0;
  
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];  
        if (!control.disabled && control.value === true) {
          checked++;
        }
      });
  
      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      } 
      return null;
    };
  }
}
