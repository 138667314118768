import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { UserService } from 'src/app/api/user.service';
import { WorkgroupService } from 'src/app/api/workgroup.service';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { GenericDialogComponent, GenericDialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { RemoveWorkgroupUserComponent } from 'src/app/dialogs/remove-workgroup-user/remove-workgroup-user.component';
import { IUser, User } from 'src/app/models/user.model';
import { IWorkgroup } from 'src/app/models/workgroup.model';
import { AccountService } from 'src/app/services/accounts.service';
import { AppUser, AppUserService } from 'src/app/services/app-user.service';
import { PusherService } from 'src/app/services/pusher.service';
import { Md5 } from 'ts-md5';
import { ProjectsComponent } from '../projects/projects.component';
import { IMessage } from 'src/app/models/message';


@Component({
  selector: 'app-workgroup',
  templateUrl: './workgroup.component.html',
  styleUrls: ['./workgroup.component.scss']
})
export class WorkgroupComponent extends BaseComponent implements OnInit {
	private _instanceID:number = 0;
	private _unsubscribe = new Subject<boolean>();

	public uuid:string;
	public workgroup:IWorkgroup;
	public adminOrSuper:boolean = false;

	public search: string;
	public availableUsers:IUser[];	// all available users
	public nonActiveUsers:IUser[];	// 
	public users:IUser[];			// users associated with this project

	@ViewChild("projects") projects:ProjectsComponent;
	public appUser:AppUser;

	constructor(
		private route:ActivatedRoute,
		private workgroupService:WorkgroupService,
		public api:ApiService,
		public appUserService:AppUserService,
		private accountService:AccountService,
		private userService:UserService,
		private dialog:MatDialog,
		private snackBar:MatSnackBar,
		private pusherService:PusherService,
    ) {
		super();
	}

  ngOnInit(): void {
	this._instanceID++;
    this.sub = this.route.params.subscribe(params => {
		let uuid = params["uuid"];
		if(uuid == this.uuid) return;
		if(this.uuid)
		{
			this.cleanup();
		}
		this.uuid = uuid;
		this.loadData()
		this.listen();		
	});

	this.sub = this.appUserService.appSuperOrAccountAdmin$.subscribe(superOrAdmin => {
		this.adminOrSuper = superOrAdmin;
		this.loadAvailableUsers();
	});
	/*
	this.appUserService.appUserSubject.pipe(takeUntil(this._unsubscribe)).subscribe(appUser => {
		this.appUser = appUser;
		this.loadData();
	});*/

	this.sub = this.accountService.users$.subscribe(accountUsers => {
		this.availableUsers = accountUsers;
		this.updateNonActiveUsers();
	});

	this.api.signup.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
		if(!res || res === 0) return;
		if(res.error){
			console.log("error with signup...");
		}else if(res.status == 201){
			this.snackBar.open("user created", "", {duration:2000});
			this.loadUsers();	
		}
	});
	
  }
  cleanup()
  {
	// clear temp subscriptions
	this.unsub();
  }
  	ngOnDestroy(): void {
		this._instanceID--;
		//console.log("workgroup component destroyed");
		super.ngOnDestroy();
		this.pusherService.leaveWorkgroupChat();		
	}
	listen()
	{
		// listen for changes to this workgroup, and if this workgroup changes, reload self
		// if projects change then reload projects (only really name change or access maybe)

		this.pusherService.init(this.api.token);
		this.pusherService.joinWorkgroupChat(this.uuid);
		
		this.sub = this.pusherService.workgroupChat.messages.pipe(debounceTime(500 + ((Math.random() * 500) | 0))).subscribe(
			(message:IMessage) => {
				//console.log("wg realtime message", this._instanceID, message);
				// ignore any messages I generate
				if(message.user == this.appUserService.appUser.uuid) return;
				if(message.type == "project")
				{
					if(message.metadata.action == "update"){
						this.projects?.reload();
					}
					//if(this.workgroup.find(project => project.id == message.id))
					//this.loadData();	// bogus.. kinda
				}
				if(message.type == "user")
				{
					if(message.metadata.id == this.appUserService.appUser.uuid)
					{
						//this.loadData();	// bogus.. kinda
						this.projects?.reload();
					}
				}
			});
		return;
		/*
		this.pusherService.joinWorkgroupChat(this.uuid).pipe(debounceTime(500 + ((Math.random() * 500) | 0)), takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				let message = response.message;
				if(message.type == "update" || message.id == this.appUserService.uuid())
				{
					this.loadData();	// bogus.. linda
					if(this.projects)this.projects.reload();
				}
			});*/
	}
  	loadWorkgroup(uuid:string)
	{
		this.tempSub = this.workgroupService.findOne(uuid).subscribe(res => {
			this.workgroup = res.data[0];
			this.loadAvailableUsers();
		});
	}
	loadData(){
		//console.log("WORKGROUP: LOAD DATA", this.uuid);
		this.loadWorkgroup(this.uuid);
		this.loadUsers();
	}
  	loadAvailableUsers()
	{
		if(!this.workgroup) return;
		if(this.adminOrSuper)
		{
			this.tempSub = this.accountService.loadUsers(this.workgroup.account_uuid);
		}else{
			this.availableUsers = [];
		}
		
		/*
		this.userService.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(response => {
		  this.availableUsers = response.data;
		  this.updateNonActiveUsers();
		});*/
	}
	loadUsers()
	{
		this.tempSub = this.workgroupService.getUsers(this.uuid).subscribe(res => {
			this.users = res.data;
			this.updateNonActiveUsers();
		});
	}
	updateNonActiveUsers()
	{
		if(!this.availableUsers || !this.users) return;
		this.nonActiveUsers = this.availableUsers.filter( user => {
			return !this.users.find(u => u.uuid == user.uuid);
		});
	}
  	isAvailableUserSelected(user:any)
	{
		if(!this.users) return false; 
		return this.users.find(u => u.uuid == user.uuid);
		//return this.users.filter(u => {this["uuid"] == u.uuid}, user).length ? true : false;
		return this.users.filter(this.userFilter, user).length ? true : false;
	}
	userFilter(currentUser, index)
	{
		return this["uuid"] == currentUser.uuid;
	}
	activate(user:User)
	{
		// grant user permission to access this workgroup
		this._addUserPermission(user);
	}
	deactivate(user:User)
	{
		this.getUserActiveProjects(user);

		//this._removeUserPermission(user);
	}
	getUserActiveProjects(user:User)
	{
		this.tempSub = this.workgroupService.getUserPermissions(this.uuid, user.uuid).subscribe(res => {
			//console.log("res", res);
			let projects = res.data['groups'].projects;
			if(!projects?.length)
			{
				this._removeUserPermission(user);
			}else{

				const dialogRef = this.dialog.open(GenericDialogComponent, {
					data: {
						title: `Cannot remove ${user.name} as they still have access to projects`,
						subtitle: `They have access to the following ${projects.length} project(s)`,
						body: '<table>' + projects.map(project => `<tr><td>${project.name}</td><td>&nbsp;&nbsp;</td><td><a href="/project/${project.uuid}" target="_BLANK">view</a></td>`).join("")  + '</table>',
						//body: projects.map(project => `${project.name}&nbsp;<a href="/projects/${project.uuid}" target="_BLANK">link</a>`).join("<br>"),
						positive: "Ok",
					}
				});
				dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
					if(result)
					{
						//console.log("new user", result);

					}
				});
				//this.snackBar.open(user.name+" cannot be removed. They are still assigned to an active project.", "", {duration:4000, panelClass:'snackBar-error'});
				//TODO per-project removeal not fully implemented... so active user cannot be removed
				/*
				const dialogRef = this.dialog.open(RemoveWorkgroupUserComponent, {
					data:{user:user, workgroup:this.workgroup, projects:res.data['groups'].projects}
				});
				dialogRef.afterClosed().subscribe(result => {
					//console.log("AFTER REMOV DIALOG CLOSE", result);
	
				});
				*/
			}

		}, (error) => {
			console.error("error removing user", error);
			
		});
	}
  	onAvailableUserSelected(e: MatSelectionListChange)
	{
		const option = e.options[0];
		const user = option.value;
		const selected = option.selected;
		if(selected) this._addUserPermission(user);
		else this._removeUserPermission(user);
	}
	onAvailableUserToggled(e:any, user:IUser)
	{
		if(e.checked) this._addUserPermission(user);
		else this._removeUserPermission(user);
	}
  _removeUserPermission(user)
	{
		this.snackBar.open("removing user", "", {duration:2000});
    	this.workgroupService.removeUserAccess(this.uuid, user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("user removed", "", {duration:2000});
			this.loadUsers();
		}, (error) => {
			console.error("error removing user", error);
			this.snackBar.open("error removing user", "", {duration:2000});
			this.loadUsers();
		});
	}
	_addUserPermission(user)
	{
		this.snackBar.open("adding user", "", {duration:2000});
		this.workgroupService.grantUserAccess(this.uuid, user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("user added", "", {duration:2000});
			this.loadUsers();
		}, (error) => {
			console.error("error adding user", error);
			this.snackBar.open("error adding user", "", {duration:2000});
			this.loadUsers();
		});
	}
  addUser()
  {
    // TODO remove bypass hack and ensure backend also adds access permission to this user for this workgroup
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
          title: "Create User",
          form:[
            {type:"text", name:"name", placeholder:"Name", value:"", validator:Validators.required},
            {type:"text", name:"job_description", placeholder:"Job Description", value:"", validator:Validators.required},
            {type:"text", name:"email", placeholder:"email", value:"", validator:[Validators.required, Validators.email]},
          ],
          negative: "Cancel",
          positive: "Save",
        }
			  });
			  dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
				  if(result)
				  {
					  //console.log("new user", result);
						let user = result.formGroup.value;
						user.password = user.password_confirmation = Md5.hashStr(user.email+Math.round((new Date()).getTime() / 1000));
						//user.bypass = true;
						
						this.snackBar.open("creating user...");
						this.api.signUp(user);
				  }
			  });
			}

}
