import { Component, OnInit } from '@angular/core';

/*
  https://bobrov.dev/blog/angular-smart-404-page/
  
*/

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
