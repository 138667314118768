import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';

// https://gist.github.com/tomastrajan/ee29cd8e180b14ce9bc120e2f7435db7
// (was in app component in wrapper div ->)  [ngClass]="{'dark-theme': themeService.isDarkTheme | async, 'light-theme': !(themeService.isDarkTheme | async)}"

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private _darkTheme = new BehaviorSubject<boolean>(false);
  isDarkTheme = this._darkTheme.asObservable();
  init()
  {
    this.setDarkTheme(false);
    return;
    // check if set in storage
    // if not then set from user preferences if possible
    const storedDarkMode = localStorage.getItem('darkmode');
    if(storedDarkMode === null)
    {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // dark mode
        this.setDarkTheme(true, false);
      }else{
        this.setDarkTheme(false, false);
      }
    } else {
      // set from local storage
      this.setDarkTheme(storedDarkMode === 'true' ? true : false, false);
    }

  }
  setDarkTheme(isDarkTheme: boolean, store:boolean = true): void {
    this._darkTheme.next(isDarkTheme);
    // store value
    if(store) localStorage.setItem('darkmode', isDarkTheme.toString());

        // remove existing class (needed if theme is being changed)
        document.body.classList.remove(isDarkTheme ? "light-theme" : "dark-theme");
        // add next theme class
        document.body.classList.add(isDarkTheme ? "dark-theme" : "light-theme");
  }
}
