import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { Globals } from '../global';
import { FormUtils } from '../utils/FormUtils';
import { Asset } from './../models/asset.model';
import { OVBaseService } from './OvBaseService';
import { Observable, interval } from 'rxjs';
import HTTPRequest from 'pusher-js/types/src/core/http/http_request';
import { clamp, mapRange } from '../utils/MathUtils';

// https://medium.com/@hitensharma1515/file-upload-component-with-resumable-functionality-using-angular-and-node-backend-included-9aba5f7f39bf
// https://api.video/blog/tutorials/uploading-large-files-with-javascript/

export enum UploadState
{
	READY = "ready",
	STARTED = "started",
	FAILED = "failed",
	COMPLETE = "complete",
	CANCELED = "canceled",
}

export interface IUpload
{
	file:File;
	path:string;
	state:UploadState;
	state$:Observable<UploadState>;
	link:string;
	data:any;
	response:any;

	progress$:Observable<number>;
	loaded$:Observable<number>;
	total$:Observable<number>;

	started:number;
	finished:number;

	start(): void;
	cancel():void
}
/*
export class MultipartUpload implements IUpload {
	
}
*/

export class UploadBase implements IUpload{

	protected _state: UploadState;
	state$: Observable<UploadState>;
	link: string;
	data: any;
	response: any;
	progress$: Observable<number>;
	loaded$: Observable<number>;
	total$: Observable<number>;

	subscription:Subscription;

	protected _loaded:number = 0;
	protected _total:number = 0;

	protected _stateSubject:BehaviorSubject<UploadState>;
	protected _progressSubject:BehaviorSubject<number>;
	protected _loadedSubject:BehaviorSubject<number>;
	protected _totalSubject:BehaviorSubject<number>;
 	started:number;
	finished:number;
	constructor(public path:string, public file:File, protected http:HttpClient, protected preCallback:(upload:IUpload) => Observable<boolean> = null)
	{
		this.path = Asset.uriEncoded(this.path);
		this.subscription = new Subscription();
		this._stateSubject = new BehaviorSubject<UploadState>(this._state);
		this.state$ = this._stateSubject.asObservable();

		this._progressSubject = new BehaviorSubject<number>(0);
		this.progress$ = this._progressSubject.asObservable();
	
		this._loadedSubject = new BehaviorSubject<number>(this._loaded);
		this.loaded$ = this._loadedSubject.asObservable();

		this._totalSubject = new BehaviorSubject<number>(this._total);
		this.total$ = this._totalSubject.asObservable();

		this.sub = this.state$.subscribe(state => {
			if(state == UploadState.CANCELED || state == UploadState.COMPLETE || state == UploadState.FAILED){
				this.finished = Date.now();
			}
		})
	}
	set sub(subscription:Subscription)
	{
		this.subscription.add(subscription);
	}
	set state(state:UploadState)
	{
		if(state == this._state) return;
		this._state = state;
		this._stateSubject.next(this._state);
	}
	get state():UploadState
	{
		return this._state;
	}
	cancel(): boolean {
		// TODO state check first
		if(this.state == UploadState.READY || this.state == UploadState.STARTED || this.state == UploadState.FAILED)
		{
			// set state to canceled unless cancel was called due to a fail (or we convert a fail into a cancel)
			if(this.state != UploadState.FAILED) this.state = UploadState.CANCELED;
			this.subscription.unsubscribe();
			return true;
		}else{
			return false;
		}
	}
	start(){
		this.started = Date.now();
		this.state = UploadState.STARTED
		let formData = new FormData();
		formData.append('file', this.file, this.file.name);
		this.sub = this.http.post(this.path, formData, {reportProgress: true, observe: 'events' }).subscribe(
			(event:HttpEvent<any>) => {
				
				switch (event.type) {
					case HttpEventType.UploadProgress:
						if(!event.total) return;
						this._loaded = event.loaded;
						this._total = event.total;
						this._progressSubject.next(this._loaded / this._total);
						break;
					case HttpEventType.Response:
						//console.log("complete", event);
						this.response = event.body;
						if(this.preCallback)
						{
							//console.log("complete", Date.now());
							this.preCallback(this).subscribe(success => {
								//console.log("precallback complete", Date.now(), success);
								if(success)
								{
									this.state = UploadState.COMPLETE;
								}else{
									this.state = UploadState.FAILED;
								}
							})
						}else{
							this.state = UploadState.COMPLETE;
						}
						break;
				}
			}, error => {
				// TODO retry this call
				this.state = UploadState.FAILED;
				console.error(error);
			});
	}
}
export class MultipartUpload extends UploadBase
{
	protected key:string;
	protected uploadId:string;
	protected parts = [];
	protected chunks:any[];
	constructor(public path:string, public file:File, protected http:HttpClient, protected preCallback:(upload:IUpload) => Observable<boolean> = null)
	{
		super(path, file, http, preCallback);
		


		//url += "?type=" + document.getElementById("provider").value;
		// 1. generate pseudo chunks
	
		// small file < 16MB
		// medium file < 512MB
		const MB = 1024 * 1024;
		const smallFile = 16 * MB; 
		const largeFile = 512 * MB; 
		const minChunkSize = (1 << 4) * MB; // 8
		const maxChunkSize = (1 << 6) * MB; // 64
		const chunkSizeOrd = Math.round(mapRange(clamp(file.size, smallFile, largeFile), smallFile, largeFile, 4, 6 ));
		const chunkSize = (1 << chunkSizeOrd) * MB; // 64 (1 << chunkSizeOrd) * MB;
		//console.log("worker chunkSize", chunkSize, chunkSizeOrd);
		const numChunks = Math.ceil(file.size / chunkSize);
		//console.log("worker numChunks", numChunks);
		this.chunks = [];
		for (let i = 0; i < numChunks; i++) {
			let start = i * chunkSize;
			let end = Math.min((i * chunkSize + chunkSize), file.size);
			let size = end - start;
			const chunk = {index:i, start, end, size, progress:0, state:0, retries:0}
			this.chunks.push(chunk);
		}
		//console.log("chunks", this.chunks);
	}
	// cancel overriden for multiplart uploads so we can call a clean up endpoing, could be better on a subscription of the cancel state change instead of an override
	cancel(): boolean {
		let cancelled = super.cancel();
		if(cancelled)
		{
			let action = 'mpu-cancel';
			const params = new HttpParams().appendAll({action, uploadId:this.uploadId});
			this.http.post(this.path, null, {params}).subscribe(
			(result:any) => {
				//console.log("multipart upload canceled");
			}, error => {
				console.error("multipart upload cancel error:", error);
			});
		}
		return cancelled;
	}
	start()
	{
		//console.log("STARTED", this.path);
		this.state = UploadState.STARTED;
		const params = new HttpParams().append('action', 'mpu-create');
		this.sub = this.http.post(this.path, null, {params}).subscribe(
			(result:any) => {
				let upload = result.upload;
				this.key = upload.key;
				this.uploadId = upload.uploadId;
				this.processNextChunk();
			}, error => {
				// TODO retry this call
				this.state = UploadState.FAILED;
				console.error(error);
			});
	}
	processNextChunk()
	{
			// find chunk
			const chunk = this.chunks.find(chunk => chunk.state == 0);
			if(!chunk) return;
			// update state to loading
			chunk.state = 1;
			//console.log("processNextChunk", chunk);

			let action = 'mpu-uploadpart';
			let partNumber = chunk.index + 1;
			const params = new HttpParams().appendAll({action, uploadId:this.uploadId, partNumber});

			//let put_url = route + `&action=mpu-uploadpart&uploadId=${uploadId}&partNumber=${partNumber}`
			//put_url += "&uploadId=" + uploadId;
			//put_url += "&partNumber=" + (index + 1);

			chunk.blob = this.file.slice(chunk.start, chunk.end);

			this.sub = this.http.put(this.path, chunk.blob, { params, reportProgress: true, observe: 'events' }).subscribe(
				(event:HttpEvent<any>) => {
					
					switch (event.type) {
						case HttpEventType.UploadProgress:
							if(!event.total) return;
							if(chunk.state == 1)
							{
								chunk.state = 2;
								this.processNextChunk();
							}
							chunk.progress = event.loaded / event.total;
							this.calculatePercentage();
							break;
						case HttpEventType.Response:
							//console.log("load event", chunk.index, event);
							this.parts.push(event.body.upload);
							//console.log("chunkUploaded", event.body)
							
							// update state to loading
							chunk.state = 3;
							this.calculatePercentage();

							// see if we are done (no active chunks)
							let anyActiveChunk = this.chunks.find(chunk => chunk.state != 3)
							if(!anyActiveChunk)
							{
								this.complete();
							}else {
								this.processNextChunk();
							}
							break;
					}	
				}, error => {
					console.warn("chunk upload error", chunk.index, error);
					chunk.state = 0;
					chunk.progress = 0;
					chunk.retries++;
					if(chunk.retries < 3)
					{
						this.processNextChunk();
					}else {
						// we dead abandon ship
						console.error("chunk failed", chunk.retries, "times");
						chunk.state = -1;
						this.calculatePercentage();
						
						// kill whole damn thing
						this.state = UploadState.FAILED;
						this.cancel();
					}
				}, () => {
					 //console.log("COMPLETE", this);
				}
			);
		}
		//processNextChunk();
		
	
		complete()
		{
			// sort parts to ensure order
			this.parts.sort((a,b) => a.partNumber - b.partNumber);
			const params = new HttpParams().append('action', 'mpu-complete');
			this.sub = this.http.post(this.path, {parts:this.parts, uploadId:this.uploadId, metadata:this.data.metadata}, {params}).subscribe(
				result => {
					this.response = result;
					if(this.preCallback)
					{
						//console.log("complete", Date.now());
						
						this.preCallback(this).subscribe(success => {
							//console.log("precallback complete", Date.now(), success);
							if(success)
							{
								this.state = UploadState.COMPLETE;
							}else{
								this.state = UploadState.FAILED;
							}
						})
					}else{
						this.state = UploadState.COMPLETE;
					}

				}, error => {
					// TODO retry this call
					this.state = UploadState.FAILED;
					console.error(error);
				});
			/*
			let complete_url = route + "&action=mpu-complete"
			//complete_url += "&uploadId=" + uploadId;
			const xhr = new XMLHttpRequest();
			xhr.open("POST", complete_url);
			xhr.addEventListener("load",(e)=>{
				if(xhr.status != 200)
				{
					console.warn("file complete failed", e, xhr);
					return;
				}
				let response = JSON.parse(xhr.response);
			   console.log("file upload complete", response);

			   upload.state = UploadState.COMPLETE
			   this.updateUploads();
			   this._completedSubject.next(upload);
			});
			// send all parts
			//xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
			
			var formData = new FormData();
			formData.append('uploadId', uploadId);
			formData.append('parts', JSON.stringify({parts}));
			xhr.send(formData);
			*/
		}
	calculatePercentage()
	{
		let loaded = 0;
		this.chunks.forEach(chunk => {
			switch (chunk.state) {
				case 3:
					loaded += chunk.size;
					break;
				case 2:
					loaded += (chunk.size * chunk.progress);
					break;
				default:
					break;
			}
		})
		//console.log("calculated progress",  progress, this.file.size , loaded);
		this._progressSubject.next(loaded / this.file.size);	//Math.round(100 * ( ))
	}
}
export class Upload
{
	public link:string;
	public data:any;
	public response:any;
	public request:any;
	public subscription:Subscription;
	public chunkSize:number = 32 * 1024 * 1024;//10mb
	public totalChunks:number;
	public chunksStarted = 0;
	public chunksUploaded:number = 0;
	constructor(
		public path:string,
		public file:File,
		public state:string = "uploading",
		public progress:number = 0,
	){
		this.totalChunks = Math.ceil(this.file.size / this.chunkSize);
	}
}

export class Upload2
{
	public static STATE_READY = "ready";
	public static STATE_STARTED = "started";
	public static STATE_FAILED = "failed";
	public static STATE_COMPLETE = "complete";
	public static STATE_CANCELED = "canceled";

	public response:any;

	public partCompleteSubject = new Subject<Boolean>();
	public completeSubject = new Subject<Boolean>();

	private chunkSize:number = 8 * 1024 * 1024; // 10mb chunks

	public link:string;
	public data:any;

	public parts:UploadPart[] = [];
	public subscription:Subscription = new Subscription();

	private _progress:number = 0;
	private _progressSubject = new BehaviorSubject<number>(this._progress);
	public progress$ = this._progressSubject.asObservable();

	private _loadedSubject = new BehaviorSubject<number>(0);
	public loaded$ = this._loadedSubject.asObservable();
	private _totalSubject = new BehaviorSubject<number>(0);
	public total$ = this._totalSubject.asObservable();

	constructor(public path:string,
		public file:File,
		public state:string = Upload2.STATE_READY,
	){
		// to chunk or not to chunk, default true for now
		let chunk:boolean = true;
		

		if(chunk)
		{
			// create all the chunk parts
			let numChunks = Math.ceil(this.file.size / this.chunkSize);
			if(numChunks < 0) throw new Error(`Invalid file/chunksize ${this.file.size}/${this.chunkSize}`);
			for (let i = 0; i < numChunks; i++) {

				// pre calculate size of each chunk
				let start = i * this.chunkSize;
				let end = Math.min((i * this.chunkSize + this.chunkSize), this.file.size);
				let size = end - start;

				let part = new UploadPart(i, numChunks);
				// this might work in my head but no idea why - to test for fun
				let size2 = (((i * this.chunkSize) % this.file.size) % this.chunkSize) || this.chunkSize;
				part.size = size;
				part.progress$.pipe().subscribe((progress) => this.calculateProgress())
				this.parts.push(part);
			}	
		

		}else{
			// single part
		}

	}
	upload()
	{
		// to get multiple uploads running at once we always start the first one automatically and use the start of it to trigger the next one if required
		// !!! SEND UP TO SERVICE ON PROGRESS AND SERVICE DICTATES
		this.uploadNextPart();
	}
	private calculateProgress()
	{
		let bytesLoaded = 0;
		let bytesTotal = 0;
		this.parts.forEach(part => {
			bytesTotal += part.size;
			bytesLoaded += part.size * part.progress;
		});
		this._loadedSubject.next(bytesLoaded);
		this._totalSubject.next(bytesTotal);
		this.progress = bytesLoaded / bytesTotal;
	}
	private uploadNextPart()
	{
		
	}
	public get progress()
	{
		return this._progress;
	}
	private set progress(progress:number)
	{
		this._progress = progress;
		this._progressSubject.next(this._progress);
	}
	/**
	 * 
	 * @param index 
	 * @returns either the whole file to upload or a blob
	 */
	public getFileOrBlob(index:number):{file:File|Blob,name:string}
	{
		// only 1 part required so just send the file (not bothering for now)
		/*
		if(this.parts.length == 1) return {
			file:this.file,
			name:this.file.name,
		}
		*/
		// length of string
		let partLength = this.parts.length.toString().length;
		// increase and pad index (so starts at 1)
		let indexString = (index + 1).toString();
		while (indexString.length < partLength) {
			indexString = "0" + indexString;
		}
		let start = index * this.chunkSize;
		let end = Math.min((index * this.chunkSize + this.chunkSize), this.file.size);
		let size = end - start;
		return {
			file:this.file.slice(start, end),
			name:this.file.name + `.chunk.${indexString}.${this.parts.length}.${size}`,
		};
	}
}
export class UploadPart
{
	public static STATE_READY = "ready";
	public static STATE_STARTED = "started";
	public static STATE_FAILED = "failed";
	public static STATE_COMPLETE = "complete";
	private _progress = 0;
	public progressTime:number;
	public progressSubject = new BehaviorSubject<number>(0);
	public progress$ = this.progressSubject.asObservable();
	public state = UploadPart.STATE_READY;
	public retries = 0;
	public size = 0;
	constructor(public partNumber:number, public totalParts:Number){

	}
	public set progress(progress:number)
	{
		this._progress = progress;
		this.progressSubject.next(this._progress);
	}
	public get progress()
	{
		return this._progress;
	}
	
}

/**
 * process
 * 
 * 1. add File to upload service
 * 2. upload service begins the upload right away, breaking into chucnks as required
 * 3. progress is reported
 * 
 * either uploads self manage everything or might be better if service does
 * 
 * upload service has uploads
 * 1 upload per file
 * upload service treats them as a quee
 */
let API_URL = Globals.BASE_API_URL;
let WORKER_URL = Globals.WORKER_URL;

@Injectable({
  providedIn: 'root'
})
export class UploadsService extends OVBaseService<Upload, string> 
{
	private _timerSubscription:Subscription;

	private _uploads:Upload[] = [];
	private _uploadsSubject =new BehaviorSubject<Upload[]>(this._uploads);
	public uploads = this._uploadsSubject.asObservable();
	private _uploads2:Upload2[] = [];
	private _uploads2Subject = new BehaviorSubject<Upload2[]>(this._uploads2);
	public uploads2$ = this._uploads2Subject.asObservable();
	private _uploads3:IUpload[] = [];
	private _uploads3Subject = new BehaviorSubject<IUpload[]>(this._uploads3);
	public uploads$ = this._uploads3Subject.asObservable();
	
	public globalProgressSubject = new BehaviorSubject<number>(0);
	public globalProgress$ = this.globalProgressSubject.asObservable();

	chunks: any[];
	uploadedChunks: number;

	private _activeUploads:number = 0;
	public set activeUploads(activeUploads:number)
	{
		let oldActiveUploads = this._activeUploads;
		if(oldActiveUploads == activeUploads) return;
		this._activeUploads = activeUploads;
		this._activeUploadsSubject.next(this._activeUploads);
		if(oldActiveUploads == 0 && this._activeUploads == 1)
		{
			// start timer
			this._timerSubscription = timer(0, 1000).subscribe(n => {
				this.speedCheck(n);				
			});
		}else if(oldActiveUploads == 1 && this._activeUploads == 0)
		{
			// stop timer
			this._timerSubscription.unsubscribe();
			this._speedSubject.next(0);
		}
	}
	public get activeUploads():number
	{
		return this._activeUploads;
	}
	private _activeUploadsSubject = new BehaviorSubject<number>(this._activeUploads);
  	public activeUploads$ = this._activeUploadsSubject.asObservable();

	public maxActiveUploads:number = 10;	// hard limit is from broswer but we can can customise it here to be less

	timercount:number
	lastSpeedCheck:number = 0;


	speedCheck(n:number)
	{
		let now = Date.now();
		let maxlookback = 5;	// average over last 5 seconds

		// first run just store
		if(n == 0)
		{
			this.lastSpeedCheck = now;
			return;
		}
		// calculate current loaded bytes
		

		let bytes = 0;
		let window = 5;// Math.min(n, maxlookback);	// how far back to look <- used to use n but it got reset to 1 sometimes when max active was low (i.e. 1)
		let windowMS = 1000 * window;
		for (let i = 0; i < this._bytes.length; i++) {
			// bytes, start, time
			const data = this._bytes[i];
			// remove if data not is older than 10 seconds
			if(data.end < (now - (1000 * 10)))
			{
				//this._bytes.splice(i--, 1);
				//continue;
			}
			if(data.start >= now - windowMS && data.end <= now){
				bytes += data.bytes;
			}			
		}
		this._speedSubject.next(bytes / window); // ( x / 1 -> maxlookback)		
		this.lastSpeedCheck = now;
	}
  getUploads() {
    // could be safer to have some read only version of these...
	  return this._uploads3;
  }

  private _completedSubject = new Subject<any>();
  public completed = this._completedSubject.asObservable();
  private _failedSubject = new Subject<any>();
  public failed = this._failedSubject.asObservable();
  private _cancelledSubject = new Subject<IUpload>();
  public cancelled = this._cancelledSubject.asObservable();
  // TODO could we combine failed and cancelled, or even all three?

  private _progressSubject = new Subject<{route:string, data:any, progress:number}>();
  public progress$ = this._progressSubject.asObservable();

  private _speedSubject = new BehaviorSubject<number>(0);
  public speed$ = this._speedSubject.asObservable();

  private _bytes:any[] = [];

  constructor(protected http: HttpClient) {
	super(http, 'upload');
		// check of old uploads every 5 seconds
		interval(5000).subscribe(() => this.clear());
   }
  
  ngOnInit(){

	}

 

  // TODO add upload as a proper class and reuse in the main list 
  // TODO seperate out progress events into own observable (maybe);
  upload(creative_uuid:string, uploads: any[])
  {
	//console.log("UPLOAD()");
    let path = API_URL;
    // if uploads have already been saved do we need the creative uuid? it should be in the asset - maybe
    //console.log("uploading uploads");
    for (let i = 0; i < uploads.length; i++) {
      const uploadIn:any = uploads[i];
      if(uploadIn.file)
      {
        //console.log("asset has file", uploadIn.file.name);
        let formData = new FormData();
        formData.append('creative_uuid', creative_uuid);
        formData.append('uuid', uploadIn.uuid);
		formData.append('file', uploadIn.file, uploadIn.file.name);

        const req = new HttpRequest('POST', `${path}upload`, formData, {
          reportProgress: true,
          responseType: 'json',
        });
        // TODO temp fix
        let upload :any = {}; //= new Upload(uploadIn)//{upload:uploadIn, progress:0, state:'uploading', response:null, subscription:null};
        //upload.link = `/prototypes/creative/${creative_uuid}`;
        let request = this.http.request(req);
        upload.request = request;
        let lastResponse:HttpEventType;
        upload.subscription = request.subscribe(
          (event: any) => {
            lastResponse = event.type;
            if (event.type === HttpEventType.UploadProgress) {
              let progress = Math.round(100 * event.loaded / event.total);
              upload.progress = progress;
              this._uploadsSubject.next(this._uploads);
            } else if (event instanceof HttpResponse) {
              const msg = 'Uploaded the file successfully: ' + uploadIn.file.name;
              //console.log("message", msg);
              upload.progress = 100;
              upload.state = "complete";
              upload.response = event.body.data[0];
              //this._uploadsSubject.next(this._uploads);
              this._completedSubject.next(upload);

              
              setTimeout(() =>
              {
                this._uploads.splice(this._uploads.indexOf(upload), 1);
                this._uploadsSubject.next(this._uploads);
              }, 5000);
            }
          },
          (err: any) => {
            const msg = 'Could not upload the file: ' +  uploadIn.file.name;
            upload.state = "failed";
            // TODO retry button?
            this._uploadsSubject.next(this._uploads);
            //console.log(msg);
          }, () => {
            //console.log("COMPLETE", lastResponse, upload, request);
            // https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr
            if (lastResponse === HttpEventType.Sent && upload.state != "failed") {
              // last response type was 0, and we haven't received an error
              console.log('aborted request');
            }
          });
          upload.subscription.add(()=>{
            //console.log("ADD", request, upload);
            if(upload.progress != 100) // or state != 'complete'
            {
              upload.state = "canceled";
            }
          });
        this._uploads.push(upload);
      }
    }    
    return this.uploads;
  }

  nextChunk(){
	let c = this.chunks[this.uploadedChunks]
	let currentChunk = c.chunkObj;
	//console.log("chunkfile:",currentChunk.data.chunkNum, currentChunk.file);

	//let chunkRoute = API_URL + `upload/creative/${currentChunk.data.creative}/asset/chunk`;
	let chunkRoute = c.parentUpload.path+"/chunk";
	// create request
	let chunkFormData = FormUtils.objectToFormData(currentChunk);
	chunkFormData.append('file',currentChunk.file);
	
    const request = new HttpRequest('POST', `${chunkRoute}`, chunkFormData, {
		reportProgress: true,
		responseType: 'json',
	  });
  
	  let chunk = new Upload(chunkRoute, currentChunk.file);
  
	  let lastResponse:HttpEventType; // keep track of last response
	  let requestEvent = this.http.request(request);
	  chunk.request = request;

	  let upload = c.parentUpload;
	  requestEvent.subscribe(
		(event: any) => {

		  if (event.type === HttpEventType.UploadProgress) {
			let numPrev = (currentChunk.data.chunkNum -1)
			let numCurr = (event.loaded / event.total);
			let numTotal = currentChunk.data.chunksTotal;
			let progress = Math.floor(100*((numPrev + numCurr)/numTotal));
			
			this._progressSubject.next({route:chunkRoute, data:currentChunk.data, progress});
			upload.progress = progress;
			//console.log("upload progress:",progress);
		  } else if (event instanceof HttpResponse) {
			//const msg = 'Uploaded the chunk successfully: chunk' + currentChunk.data.chunkNum;

			this.uploadedChunks ++;
			//this.cleanupUploadSubs(chunk);

			if(this.uploadedChunks < this.chunks.length){
				//console.log("calling nextChunk()");
				this.nextChunk();
			} else {
				//console.log("ALL CHUNKS UPLOADED");
				upload.progress = 100;
				upload.state = "complete";
				upload.response = event.body.data[0];
				this._completedSubject.next(upload);
				//this.cleanupUploadSubs(chunk);
			}
		  }
		},
		(err: any) => {
		  const msg = 'Could not upload the file: chunk' + currentChunk.data.chunkNum;
		  chunk.state = "failed";
		  // TODO retry button?
		  this._uploadsSubject.next(this._uploads);
		  //console.log(msg);
		}, () => {
		 // console.log("COMPLETE", lastResponse, upload, request);
		  // https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr
		  if (lastResponse === HttpEventType.Sent && chunk.state != "failed") {
			// last response type was 0, and we haven't received an error
			console.log('aborted request');
		  }
		});

/*
	  chunk.subscription = requestEvent.subscribe(
		(event: any) => {
		  lastResponse = event.type;
		  if (event.type === HttpEventType.UploadProgress) {
			let progress = Math.round(100 * event.loaded / event.total);
			chunk.progress = progress;
			console.log("chunk progress:",progress);
		  } else if (event instanceof HttpResponse) {
			//const msg = 'Uploaded the chunk successfully: chunk' + currentChunk.data.chunkNum;
			//console.log("message", msg);
			//chunk.progress = 100;
			//chunk.state = "complete";
			//chunk.response = event.body?.data[0];
			console.log("chunk sub event",event);
			this.uploadedChunks ++;
			this.cleanupUploadSubs(chunk);

			if(this.uploadedChunks < this.chunks.length){
				console.log("chunk sub: calling nextChunk()");
				this.nextChunk();
			} else {
				console.log("ALL CHUNKS UPLOADED");
				upload.progress = 100;
				upload.state = "complete";
				//TODO
				console.log("event:",event);
				upload.response = event.body.data[0];
				this._completedSubject.next(upload);
				this.cleanupUploadSubs(chunk);
			}
		  }
		},
		(err: any) => {
		  const msg = 'Could not upload the file: chunk' + currentChunk.data.chunkNum;
		  chunk.state = "failed";
		  // TODO retry button?
		  this._uploadsSubject.next(this._uploads);
		  //console.log(msg);
		}, () => {
		 // console.log("COMPLETE", lastResponse, upload, request);
		  // https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr
		  if (lastResponse === HttpEventType.Sent && chunk.state != "failed") {
			// last response type was 0, and we haven't received an error
			console.log('aborted request');
		  }
		}
	  );

	  */
  }

  nextChunk2(upload:Upload){
	const start = (upload.chunksStarted) * upload.chunkSize;
	const end = Math.min(start + upload.chunkSize, upload.file.size);
	const chunkFile = upload.file.slice(start, end);
	const chunkPath = upload.path+"/chunk";
	//console.log("chunkfile:",currentChunk.data.chunkNum, currentChunk.file);
	upload.chunksStarted ++;
	//console.log(upload.chunksStarted+" chunks started of "+upload.totalChunks);
	//let chunkRoute = API_URL + `upload/creative/${currentChunk.data.creative}/asset/chunk`;
	
	// create request
	//let chunkFormData = new FormData();
	// clone data
	let chunkData = upload.data ? JSON.parse(JSON.stringify(upload.data)) : {};
	chunkData.path = chunkPath;
	chunkData.chunkNum = upload.chunksStarted;
	chunkData.chunksTotal = upload.totalChunks;
	chunkData.origFilename = upload.file.name;
	let chunkFormData = FormUtils.objectToFormData({data:chunkData});
	chunkFormData.append('file',chunkFile);

	//TODO this could be a dedicated api route for all chunks, not a specific one per upload? 
    const request = new HttpRequest('POST', `${chunkPath}`, chunkFormData, {
		reportProgress: true,
		responseType: 'json',
	  });
  
  
	  let lastResponse:HttpEventType; // keep track of last response
	  let requestEvent = this.http.request(request);
	  upload.request = request;

	  //let upload = c.parentUpload;
	  upload.subscription = requestEvent.subscribe(
		(event: any) => {

		  if (event.type === HttpEventType.UploadProgress) {
			let numChunksUploaded = upload.chunksUploaded + (event.loaded / event.total);
			let progress = Math.floor(100*(numChunksUploaded/upload.totalChunks));
			upload.progress = progress;
			this._progressSubject.next({route:chunkPath, data:upload.data, progress});
			
			//console.log("upload progress:",progress);
		  } else if (event instanceof HttpResponse) {
			//const msg = 'Uploaded the chunk successfully: chunk' + currentChunk.data.chunkNum;

			upload.chunksUploaded ++;
			//console.log(upload.chunksUploaded+" chunks uploaded of "+upload.totalChunks);
			//this.cleanupUploadSubs(chunk);

			if(upload.chunksStarted < upload.totalChunks){
				//console.log("calling nextChunk()");
				this.nextChunk2(upload);
			} 
			if(upload.chunksUploaded == upload.totalChunks){
				//console.log("ALL CHUNKS UPLOADED");
				upload.progress = 100;
				upload.state = "complete";
				upload.response = event.body.data[0];

				//tell server to merge chunks
				this.completeUpload(upload).pipe().subscribe(res => {
					upload.response = res.data;
					this._completedSubject.next(upload);
				},
				(err: any) => {
					const msg = 'Could not comnplete the upload';
					upload.state = "failed";
					// TODO retry button?
					this._uploadsSubject.next(this._uploads);
					//console.log(msg);
				});

				//this.completeUpload(upload);
				//this._completedSubject.next(upload);

				//
				//this.cleanupUploadSubs(chunk);
			}
		  }
		},
		(err: any) => {
		  const msg = 'Could not upload the file: chunk' + (upload.chunksUploaded+1);
		  upload.state = "failed";
		  // TODO retry button?
		  this._uploadsSubject.next(this._uploads);
		  //console.log(msg);
		}, () => {
		 // console.log("COMPLETE", lastResponse, upload, request);
		  // https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr
		  if (lastResponse === HttpEventType.Sent && upload.state != "failed") {
			// last response type was 0, and we haven't received an error
			console.log('aborted request');
		  }
		});

		if(upload.chunksStarted == 1){
			upload.subscription.add(()=>{
				//console.log("ADD", request, upload);
				if(upload.progress != 100) // or state != 'complete'
				{
					upload.state = "canceled";
				}
			});
		}
  }

 
	completeUpload(upload)//:Observable<any>
	{
		//console.log("complete upload",upload);
		return this._post<any>(`${this.base}/creative/${upload.data.creative}/asset/${upload.data.asset}/complete`, {name: upload.file.name, chunksTotal: upload.totalChunks});
		//return [];
	}
  
  cleanupUploadSubs(uploadObj:Upload){
	uploadObj.subscription = null;
  }
	// worker-style
	// https://github.com/search?q=repo%3Atransloadit/uppy%20getChunkSize&type=code
	// https://github.com/transloadit/uppy/blob/main/packages/%40uppy/aws-s3-multipart/src/MultipartUploader.ts
	// https://github.com/transloadit/uppy/blob/4053abb588f54d8450d58c092b2a2963b74dcfe3/packages/%40uppy/aws-s3-multipart/src/MultipartUploader.js#L138
	addWorker(route:string, payload:{file:File, data?:any}, link:string = null, preCallback:(upload:IUpload) => Observable<boolean> = null)
	{
		// check
		if(!route || route == '') throw new Error(`Upload path invalid: ${route}`);
		if(!payload.file) throw new Error(`No upload file supplied`);

		const MB = 1024 * 1024;
		const multiPartMinSize = 8 * MB;

		let upload:IUpload;
		if(payload.file.size < multiPartMinSize)
		{
			route = WORKER_URL + route;
			upload = new UploadBase(route, payload.file, this.http, preCallback);
		}else{
			route += "?multipart=1"
			route = WORKER_URL + route;
			upload = new MultipartUpload(route, payload.file, this.http, preCallback);
		}

		upload.state = UploadState.READY;
		upload.data = payload.data;
		upload.link = link;

		
		
		
		// process next chunk until all but last complete
		//let index = 0;
		
		
		this._uploads3.push(upload);
		upload.progress$.subscribe(progress => {
			this._progressSubject.next({route, data:upload.data, progress})
		})
		upload.state$.subscribe((state:UploadState) => {
			//console.log("upload state change", state);
			if(state == UploadState.COMPLETE)
				this._completedSubject.next(upload);
			else if(state == UploadState.FAILED || state == UploadState.CANCELED)
			 	this._cancelledSubject.next(upload);
			this.updateUploads();
		})
		upload.start();
		this.updateUploads();
	}

  // add a file to be uploaded
  add(route:string, payload:{file:File, data?:any}, link:string = null)
  {
	//console.log("ADDING UPLOAD", route, payload);
    // check
    if(!route || route == '') throw new Error(`Upload path invalid: ${route}`);
    if(!payload.file) throw new Error(`No upload file supplied`);

	/*
    route = API_URL + route;  // TODO trim slashes on input?
	//console.log("UPLOADING",payload.file.size, payload.file.name, route);

	// create upload
    let upload = new Upload(route, payload.file);
	if(payload.data.file) payload.data.file = null;//remove any duplicated file (e..g from taskCaptureEvent)
	upload.data = payload.data;
    upload.link = link;*/

	//1 chunk at a time
	//this.nextChunk2(upload)
	
	//start 4 chunks at once for speed
	/*
	for(let i=0; i<Math.min(upload.totalChunks,4); i++){
		setTimeout(() => {this.nextChunk2(upload)},(200*1));
	}

	this._uploads.push(upload);*/

	// new way
	route = API_URL + route;//"upload/chunkTest";
	let upload2 = new Upload2(route, payload.file, "ready");
	if(payload.data.file) payload.data.file = null;//remove any duplicated file (e..g from taskCaptureEvent)
	upload2.data = payload.data;
    upload2.link = link;




	upload2.partCompleteSubject.subscribe(complete => {
		// console.log("part uploaded", "try next");
	} );
	// TODO unsubscribe on completee or fail?
	upload2.subscription.add(()=>{
		// not yet complete so safe to kill
		if(upload2.progress != 100)
		{
			upload2.state = Upload2.STATE_CANCELED;
		  this.updateUploads();
		  // @ts-ignore
		  this._cancelledSubject.next(upload2);
		}
	  });

	upload2.progress$.subscribe(progress => {
		this._progressSubject.next({route:route, data:upload2.data, progress});
		this.calculateGlobalProgress();
	})
	this._uploads2.push(upload2);
	this._uploads2Subject.next(this._uploads2);

	this.run();
	/*
	//WHOLE FILE AT ONCE
    // create formdata
    let formData = FormUtils.objectToFormData(payload);
    console.log("formData",formData);
    // create request
    const request = new HttpRequest('POST', `${route}`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    let lastResponse:HttpEventType; // keep track of last resonse
    let requestEvent = this.http.request(request);
    upload.request = request;
    upload.subscription = requestEvent.subscribe(
      (event: any) => {
        lastResponse = event.type;
        if (event.type === HttpEventType.UploadProgress) {
          let progress = Math.round(100 * event.loaded / event.total);
          upload.progress = progress;
          //console.log("calling next on upload");
          this._progressSubject.next({route, data:payload.data, progress});
          this._uploadsSubject.next(this._uploads);
        } else if (event instanceof HttpResponse) {
          const msg = 'Uploaded the file successfully: ' + payload.file.name;
          //console.log("message", msg);
          upload.progress = 100;
          upload.state = "complete";
          upload.response = event.body.data[0];
          this._completedSubject.next(upload);
          
          // remove old uplooads?
          
         // setTimeout(() =>
         // {
         //   this._uploads.splice(this._uploads.indexOf(upload), 1);
         //   this._uploadsSubject.next(this._uploads);
         // }, 5000);
         // 
        }
      },
      (err: any) => {
        const msg = 'Could not upload the file: ' +  payload.file.name;
        upload.state = "failed";
        // TODO retry button?
        this._uploadsSubject.next(this._uploads);
        //console.log(msg);
      }, () => {
       // console.log("COMPLETE", lastResponse, upload, request);
        // https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr
        if (lastResponse === HttpEventType.Sent && upload.state != "failed") {
          // last response type was 0, and we haven't received an error
          console.log('aborted request');
        }
      }
	);
*/
/* //moved to nextChunk2()...
	upload.subscription.add(()=>{
		//console.log("ADD", request, upload);
		if(upload.progress != 100) // or state != 'complete'
		{
			upload.state = "canceled";
		}
	});
*/
	// duplicated above commented code...
 //   this._uploads.push(upload);
	
  }

  run(debug:string = 'default')
  {
	//console.log("run",debug, this.activeUploads, this.maxActiveUploads,this.activeUploads >= this.maxActiveUploads);
	
	if(this.activeUploads >= this.maxActiveUploads)
	{
		//console.log("run skipped");		
		return;
	}

	// https://stackoverflow.com/questions/52171690/how-to-calculate-download-upload-internet-speed-in-angular

	// update any complete or failed uploads
	this._uploads2.filter(upload => upload.state == Upload2.STATE_STARTED).forEach(upload => {
		let completedParts = 0;
		let failedParts = 0;
		upload.parts.forEach(part => {
			if(part.state == UploadPart.STATE_COMPLETE)
				completedParts++;
			else if(part.state == UploadPart.STATE_FAILED)
				failedParts++;
		})
		if(failedParts > 0)
		{
			upload.state = Upload2.STATE_FAILED;
			this.updateUploads();
			this._failedSubject.next(upload);
			// TODO what do?
		}
		if(completedParts == upload.parts.length)
		{
			upload.state = Upload2.STATE_COMPLETE;
			//console.log("COMPLETE");
			
			this.updateUploads();
			this._completedSubject.next(upload);
		}
	})

	// find next target upload (one that not all parts are started)
	let upload = this._uploads2.find(upload => {
		if(upload.state != Upload2.STATE_READY && upload.state != Upload2.STATE_STARTED) return false;
		let unstartedPart = upload.parts.find(part => part.state === UploadPart.STATE_READY);
		if(unstartedPart) return true;
		return false;
	})
	// no uploads left
	if(!upload) return;

	// mark as started if not
	if(upload.state != Upload2.STATE_STARTED)
	{
		upload.state = Upload2.STATE_STARTED;
		this.updateUploads();
	}

	// grab the next part to start
	let nextPart:UploadPart = upload.parts.find(part => part.state === UploadPart.STATE_READY);
	// TODO throw errors
	if(!nextPart) return;
	
	nextPart.state = UploadPart.STATE_STARTED;

	// get the file/blob
	let formData = FormUtils.objectToFormData({data:upload.data});
	//console.log("form data", formData);	
	let file = upload.getFileOrBlob(nextPart.partNumber);
	nextPart.size = file.file.size;
	formData.append('file', file.file, file.name);
	formData.append('chunk', nextPart.partNumber.toString());
	formData.append('chunks', nextPart.totalParts.toString());
	formData.append('filename', file.name);
	formData.append('filesize', file.file.size.toString());
	

	const request = new HttpRequest('POST', `${upload.path}`, formData, {
		reportProgress: true,
		responseType: 'json',
	});

	let requestEvent = this.http.request(request);
	//upload.request = request;

	//console.log("making chunk request", nextPart.partNumber, nextPart.retries, nextPart.progress);
	
	this.activeUploads++;
	let uploadSubscription = requestEvent.subscribe(
		(event: HttpEvent<any>) => {
			//console.log("upload 2 part data", nextPart.partNumber, nextPart.totalParts);//  event);
			//console.log("upload 2 part event", event);
			switch (event.type) {
				case HttpEventType.Sent:
					nextPart.progress = 0;
					// sent fires too early to use or we queue up all the chunks right away!
					break;
				case HttpEventType.UploadProgress:
					let now = Date.now();
					let lastProgress = nextPart.progress;
					let progress = event.loaded / event.total;
					if(progress == 0)
					{
						//console.warn("OI", progress, event);
						
					}
					if(lastProgress == 0)
					{				
						this.run("progress " + nextPart.partNumber + " " + nextPart.progress);
						nextPart.progressTime = now;
					}
					let progressDelta = progress - lastProgress;
					let timeDelta = now - nextPart.progressTime;
					if(progressDelta && timeDelta)
					{
						this._bytes.push({bytes:progressDelta*nextPart.size, start:nextPart.progressTime, end:now});
					}
					nextPart.progressTime = now;
					nextPart.progress = progress;
					break;
				case HttpEventType.ResponseHeader:
					//console.log("HttpEventType.ResponseHeader", event.status);
					/*
					if(event.status == 404 || event.status == 500)
					{
						// reset part and increment retries count
						nextPart.started = false;
						nextPart.progress = 0;
						nextPart.retries++;
						if(nextPart.retries < 3)
						{
							this.run();
						}else{
							nextPart.failed = true;
							this.run();
							console.warn("upload failed");					
						}
					}else{
						// all done good
						nextPart.progress = 1;
					}*/
					break;
				case HttpEventType.DownloadProgress:
					const kbLoaded = Math.round(event.loaded / 1024);
					//console.log(`Download in progress! ${ kbLoaded }Kb loaded (${event.total})`);
					break;
				case HttpEventType.Response:
					//console.log("HttpEventType.Response");
					//const msg = 'Uploaded the chunk successfully: chunk' + currentChunk.data.chunkNum;
					//console.log("chunk complete", nextPart.partNumber +"/"+ nextPart.totalParts);
					//console.log("chunk complete eve", event);
					this.activeUploads--;
					
					if(event.status == 200)
					{
						nextPart.progress = 1;
						nextPart.state = UploadPart.STATE_COMPLETE;
						//console.log("chunk loaded", event.body.data);				
						if( event.body?.data?.length){
							upload.response = event.body.data[0];
						}						
						this.run("complete");
					} else {
						console.warn("unexpected status", event.status)
					}
					break;
			}
			},
			(err: HttpErrorResponse) => {
				const msg = 'Could not upload the file: chunk';
			
				console.error("upload error - about to retry", err.message);

				// reset part and increment retries count
				
				nextPart.progress = 0;
				nextPart.retries++;
				if(nextPart.retries < 3)
				{
					nextPart.state = UploadPart.STATE_READY;
					this.run("retry");
				}else{
					nextPart.state = UploadPart.STATE_FAILED;
					this.run("fail");
					console.warn("upload failed");					
				}
			}, () => {

			//console.log("COMPLETE");
			// https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr
			/*
			if (lastResponse === HttpEventType.Sent && upload.state != "failed") {
				// last response type was 0, and we haven't received an error
				console.log('aborted request');
			}*/
		});
		upload.subscription.add(uploadSubscription);

		/*
		if(upload.chunksStarted == 1){
			upload.subscription.add(()=>{
				//console.log("ADD", request, upload);
				if(upload.progress != 100) // or state != 'complete'
				{
					upload.state = "canceled";
				}
			});
		}*/
	}
	/**
	 * Notify that the uploads have changed somehow
	 */
	updateUploads()
	{
		//this._uploadsSubject.next(this._uploads);
		//this._uploads2Subject.next(this._uploads2);
		this._uploads3Subject.next(this._uploads3);
		//this.calculateGlobalProgress();
	}
  cancel(upload:IUpload)
  {
	if(upload.state != UploadState.READY && upload.state != UploadState.STARTED) return;
	upload.cancel();
	this.updateUploads();
	//this._cancelledSubject.next(upload);
    //(upload.subscription as Subscription).unsubscribe();
  }
  clear()
  {
	// find and clear old uploads
	let seconds = 10;
	let old = Date.now() - (1000 * seconds);
	let oldUploads = this._uploads3.filter((upload) => (upload.state == UploadState.CANCELED || upload.state == UploadState.COMPLETE || upload.state == UploadState.FAILED) && !isNaN(upload.finished) && upload.finished < old);
	oldUploads.forEach(oldUpload => {
		let index = this._uploads3.indexOf(oldUpload);
		if(index != -1) this._uploads3.splice(index, 1);
	});
	if(oldUploads.length) this.updateUploads();
  }
  retry(upload:Upload2)
  {
    if(upload.state != "failed") return;
    let index = this._uploads2.indexOf(upload);
    if(index != -1)
    {
      // TODO
    }
    throw new Error('Method not implemented.');
  }

  remove(upload: any) {

	let index = this._uploads3.indexOf(upload);
    if(index != -1)
    {
      this._uploads3.splice(index, 1);
      this.updateUploads();
    }
	// old way
	return;
	/*
    if  (upload.state != "canceled"
        && upload.state != "complete"
        && upload.state != "failed"
        ) return;
    let index = this._uploads2.indexOf(upload);
    if(index != -1)
    {
      this._uploads2.splice(index, 1);
      this.updateUploads();
    }
	*/

  }
  calculateGlobalProgress()
  {
	let activeUploads = this._uploads2.filter(upload => upload.state == Upload2.STATE_READY || upload.state == Upload2.STATE_STARTED);
	let progress = 0;
	activeUploads.forEach(upload => progress += upload.progress);
	progress /= activeUploads.length;
	this.globalProgressSubject.next(progress);

  }

}
