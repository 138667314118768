import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-edit-asset-dialog',
	templateUrl: './edit-asset-dialog.component.html',
	styleUrls: ['./edit-asset-dialog.component.scss']
})
export class EditAssetDialogComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	title:any = 'Assign Users';
	dismiss_button_label:any = 'Assign';
	all_users:any = false;
	saveDataTimer:any = false;
	container_id:any;
	container_type:any;
	selected_users:any = [];

	constructor(public dialogRef: MatDialogRef<EditAssetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api:ApiService, private router:Router) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
/*
		if(typeof this.data.title !== 'undefined'){
			this.title = this.data.title;
		}
		if(typeof this.data.dismiss_button_label !== 'undefined'){
			this.dismiss_button_label = this.data.dismiss_button_label;
		}
		if(typeof this.data.container_id !== 'undefined'){
			this.container_id = this.data.container_id;
		}
		if(typeof this.data.container_type !== 'undefined'){
			this.container_type = this.data.container_type;
		}
*/
	}
	
	updateIsDeliverable(event:any){
		this.data.asset.deliverable = (event.checked) ? 1 : 0;
	}
	
	updateIsVisible(event:any){
		this.data.asset.visible = (event.checked) ? 1 : 0;
	}
	
	onSelection(e:any, arr:any){
		this.selected_users = [];
		for(let i in arr){
			this.selected_users.push(arr[i].value);
		}
	}
	
	
	
	setAssetSaveDataDelayed(key:any, value:any){
		clearTimeout(this.saveDataTimer);
		this.saveDataTimer = setTimeout(() => {
			this.setAssetSaveData(key, value);
		}, 100);
	}
	
	public setAssetSaveData(key:any, value:any){
		this.data.asset[key] = value;
		$(window).trigger('update_assets');
	}
	
	saveAsset(_data:any){
		
		for(let i in _data){
			this.data.asset[i] = _data[i];
		}
		
		this.api.updateasset.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res == 0) return;
			this.dialogRef.close();
			//window.location.reload();
		});
		this.api.updateAsset(this.data.asset);
	}
	
	deleteAsset(){
		this.data.parent.openRemoveAssetDialog(this.data.asset, this);
	}
	
	public dismissAction(){
		this.dialogRef.close();
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}