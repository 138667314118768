<div *ngIf="false">
    <mat-form-field class="example-form-field">
        <mat-label>Search</mat-label>
        <input matInput type="text" [(ngModel)]="search" (ngModelChange)="generateCreatives()">
        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''; generateCreatives()">
          <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="this.newCreative()">New Creative</button>
</div>
<div class="container" #scroll (scroll)="onScroll($event)">
    <!--<div class="bangin" #container></div>-->
    <div *ngIf="!gridData" class="loading"><mat-spinner diameter="50"></mat-spinner></div>
    <div class="wrapper" (mouseenter)="mouseEnterInfo($event)">
        <ng-container #container (mouseenter)="mouseEnterInfo($event)"></ng-container>
    </div>
    <ng-container *ngIf="isMainGrid && gridData && gridData.length == 0">
        <div class="message">
            <p>There are currently no creatives in this project.</p>
            <p *ngIf="isProjectAdmin()">Use the blue '+'' button to make some channels, formats and creatives.</p>  
        </div>
    </ng-container>
    <table #table >
        <ng-container *ngIf="gridData?.length">
            <thead>
                <tr>
                    <th style="height: 41px;">
                        <!--<button *ngIf="isProjectAdmin() && isMainGrid" style="visibility: visible !important;" mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Add workflow, channel or format"><mat-icon>add_circle_outline</mat-icon></button>-->
                        <button *ngIf="isProjectAdmin() && isMainGrid && !selectionMode" class="newBtn small" style="visibility: visible !important;" mat-mini-fab color="primary" [matMenuTriggerFor]="menu" matTooltip="Add workflow, channel or format"><mat-icon>add</mat-icon></button>
						<mat-checkbox *ngIf="selectionMode" color="primary" [checked]="(selectionService.selection$ | async)?.project[project.uuid]" (change)="selectionChange('project', project.uuid)"></mat-checkbox>
                    </th>
                    <th *ngFor="let projectChannel of availableChannels; index as channelIndex" [ngClass]="{'hovered':channelIndex==hoverChannelIndex}">
                        <div class="channel-header">
							<div class="channel-name" [matTooltip]="projectChannel.name">{{projectChannel.name}}</div>
							<div class="channel-options" *ngIf="isProjectAdmin() && isMainGrid" > <!-- *ngIf="isProjectAdmin() && !this.selectedChannel" -->
								<button *ngIf="!selectionMode" class="options" mat-icon-button>
									<mat-icon [matTooltip]="'project channel options'" [matMenuTriggerFor]="menuChannel" [matMenuTriggerData]="{projectChannel}">more_vert</mat-icon>
								</button>
								<mat-checkbox *ngIf="selectionMode && projectChannelHasCreatives[projectChannel.uuid]" color="primary" [checked]="(selectionService.selection$ | async)?.projectChannel[projectChannel.uuid]" (change)="selectionChange('projectChannel', projectChannel.uuid)"></mat-checkbox>
							</div>
							<!--

								<button *ngIf="isProjectAdmin() && !this.selectedChannel" style="position:absolute;transform:translateY(-10px);" [matTooltip]="'Edit Channel'" (click)="editProjectChannel(projectChannel)" mat-icon-button><mat-icon class="small">edit</mat-icon></button>
							-->

							<!--<div style="display: flex; justify-content: space-between; align-items: center;">
								<span class="channel-add"><mat-icon>add_circle_outline</mat-icon></span>
								{{channel.name}}
								<span class="channel-add"><mat-icon>add_circle_outline</mat-icon></span>
							</div>
							-->
							<!-- mini grid mode -->
							<ng-container *ngIf="!isMainGrid"><!-- *ngIf="this.selectedChannel" -->
								<button mat-icon-button [matMenuTriggerFor]="menuChannels">
									<mat-icon>arrow_drop_down</mat-icon>
								</button>
								<mat-menu #menuChannels="matMenu" xPosition="before">
									<button mat-menu-item *ngFor="let channel of projectChannels" [ngClass]="{activeChannel:activeCreative.channel.project_channel.id == channel.id}" (click)="channelMenuClick(channel)" [disabled]="selectedChannel == channel.id"><!-- [ngClass]="{activeChannel:selectedChannel == channel.id}"-->
									<span>{{channel.name}}</span>
									</button>
								</mat-menu>
							</ng-container>
						</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let data of gridData; index as i">
                    <ng-container [ngSwitch]="data.type">
                         <tr *ngSwitchCase="'dummy'">
                            <td style="height:5px; border-top: 2px solid grey;" [attr.colspan]="channels.length+1"></td>
                         </tr>
                        <ng-container *ngSwitchCase="'workflow'">
                            <tr class="workflow-row sticky-workflow">
                                <th>
                                    <div class="split">
                                        <span [matTooltip]="'workflow: '+data.workflow.name"><strong>Workflow:</strong> {{data.workflow.name}}</span>
                                        <div *ngIf="isProjectAdmin() && isMainGrid" class="format-options">
                                            <button *ngIf="!selectionMode" class="options" mat-icon-button [matMenuTriggerFor]="menuWorkflow" [matMenuTriggerData]="{workflow:data.workflow}" [matTooltip]="'workflow options'"><mat-icon>more_vert</mat-icon> </button>
											<mat-checkbox *ngIf="selectionMode && !data.empty" color="primary" [checked]="(selectionService.selection$ | async)?.workflow[data.workflow.uuid]" (change)="selectionChange('workflow', data.workflow.uuid)"></mat-checkbox>
                                        </div>
                                    </div>
                                </th>
                                <!--<td [attr.colspan]="channels.length"></td>-->
                                <td class="group-cell" *ngFor="let projectChannel of availableChannels; index as i;">
                                    <div class="workflow-header" *ngIf="data.perChannelWorkflowInfo[i]" [ngClass]="{'header-warn-bg': isAdmin && data.perChannelWorkflowInfo[i].warning}">
                                        <div class="workflow-users" [ngClass]="{'workflow-warning': isAdmin && data.perChannelWorkflowInfo[i].warning}">

                                            <div>
                                                <button mat-icon-button [matTooltip]="'click to see: \nProduction users assigned to '+projectChannel.name" [matMenuTriggerFor]="menuWorkflowChannelUsers" [matMenuTriggerData]="{title:'Production', users:data.perChannelWorkflowInfo[i].production, channel:projectChannel.name}">
                                                    <mat-icon class="workflow-user-icon">manage_accounts</mat-icon>
                                                     <span>{{data.perChannelWorkflowInfo[i].production.length}}</span>
                                                </button>  
                                            </div>
                                            <div>
                                                <button mat-icon-button [matTooltip]="'click to see: \nApprovers assigned to '+projectChannel.name" [matMenuTriggerFor]="menuWorkflowChannelUsers" [matMenuTriggerData]="{title:'Approvers', users:data.perChannelWorkflowInfo[i].approval, channel:projectChannel.name}">
                                                    <mat-icon class="workflow-user-icon">how_to_reg</mat-icon>
                                                    <span>{{data.perChannelWorkflowInfo[i].approval.length}}</span>
												</button>
                                            </div>
                                    
                                            <mat-icon  *ngIf="isAdmin && data.perChannelWorkflowInfo[i].warning" class="header-warn-icon" xstyle="color:white; cursor:pointer" [matTooltip]="'WARNING:\n'+data.perChannelWorkflowInfo[i].warning + ((isMainGrid) ? '\n\n[click to assign users]' : '')" (click)="editWorkflow(data.workflow.id)">report</mat-icon>

                                        </div>
                                        <div *ngIf="isProjectAdmin() && isMainGrid" class="channel-options" [matTooltip]="'workfow channel options'">
                                            <!--<button mat-icon-button [matMenuTriggerFor]="menuWorkflowChannel" [matMenuTriggerData]="{workflow:data.workflow, projectChannel:projectChannel}">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>-->
											<button *ngIf="!selectionMode" mat-icon-button class="options">
												<mat-icon style="cursor:pointer" [matMenuTriggerFor]="menuWorkflowChannel" [matMenuTriggerData]="{workflow:data.workflow, workflowChannel:data.perChannelWorkflowInfo[i].workflowChannel}">more_vert</mat-icon>
											</button>
											<mat-checkbox *ngIf="selectionMode && workflowChannelCreativeCount[data.perChannelWorkflowInfo[i].workflowChannel.uuid] > 0" color="primary" [checked]="(selectionService.selection$ | async)?.workflowChannel[data.perChannelWorkflowInfo[i].workflowChannel.uuid]" (change)="selectionChange('workflowChannel', data.perChannelWorkflowInfo[i].workflowChannel.uuid)"></mat-checkbox>
                                        </div>
                                    </div>
                                    <!-- channel no-existing on this workflow -->
                                    <div *ngIf="isProjectAdmin() && !data.perChannelWorkflowInfo[i] && isMainGrid">
                                        <!-- <button [matTooltip]="'Activate \''+projectChannel.name+'\' channel on \''+data.workflow.name+'\' workflow'" mat-icon-button (click)="addChannelToWorkflow(data.workflow, projectChannel)"><mat-icon>insert_link</mat-icon></button>-->
                                        <button class="small" [matTooltip]="'Activate \''+projectChannel.name+'\' channel on \''+data.workflow.name+'\' workflow'" mat-stroked-button (click)="addChannelToWorkflow(data.workflow, projectChannel)">activate</button>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngSwitchCase="'group'">
                            <tr class="group-row">
                                <th class="group">
                                    <div class="split">
                                        <span [matTooltip]="data.format.group" style="width:100%">{{data.format.group}}</span>
                                        <div *ngIf="(isProjectAdmin()) && isMainGrid" class="format-options group"> <!-- || isProduction-->
                                            <button *ngIf="!selectionMode" class="options" mat-icon-button [matMenuTriggerFor]="menuFormatGroup" [matMenuTriggerData]="{format:data.format}" [matTooltip]="'format category options'">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
											<mat-checkbox *ngIf="selectionMode && !data.empty" color="primary" [checked]="(selectionService.selection$ | async)?.formatGroup[data.workflow.uuid+'_'+data.format.group]" (change)="selectionChange('formatGroup', data.workflow.uuid+'_'+data.format.group)"></mat-checkbox>
                                        </div>
                                    </div>
                                </th>
                                <td class="group-cell" *ngFor="let channel of channels;"></td>
                            </tr>
                        </ng-container>
                        <ng-container *ngSwitchCase="'format'">
                            <tr class="format-row">
                                <th>
                                    <div class="split ">
                                        <span [matTooltip]="data.format.name" [ngStyle]="{'width.px': isMainGrid ? (data.format.name.length*5)+50 : 175 }">{{data.format.name}}</span>
                                        <div *ngIf="isProjectAdmin() && isMainGrid" class="format-options">
                                            <button *ngIf="!selectionMode" class="options" mat-icon-button [matMenuTriggerFor]="menuFormat" [matMenuTriggerData]="{format:data.format, workflow:data.workflow}" [matTooltip]="'format options'">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
											<!-- <mat-icon *ngIf="!selectionMode" [matMenuTriggerFor]="menuFormat" [matMenuTriggerData]="{format:data.format, workflow:data.workflow}">more_vert</mat-icon> -->
											<mat-checkbox *ngIf="selectionMode && !data.empty" color="primary" [checked]="(selectionService.selection$ | async)?.format[data.format.uuid]" (change)="selectionChange('format', data.format.uuid)"></mat-checkbox>
                                        </div>
                                    </div>
                                </th>
                                <!-- (mouseenter)="onMouseEnterCell($event, creative, j)" (mouseleave)="onMouseLeaveCell($event, creative, j)" -->
                                <td *ngFor="let creative of data.creatives; index as j; trackBy:trackByUUID; "
                                    [ngClass]="{'active':creative, 'empty':!creative, 'activeState':creative && (creative.uuid && activeCreative?.uuid == creative.uuid)}" > <!--tooltip="Tooltip from text"  [cont]="container" [c]="containerRef"--> <!-- [container]="tooltipContainer"-->
                                    
										<ng-container> <!-- *ngIf="!selectionMode && !packageMode"-->
											<div *ngIf="creative && creative.uuid && !creative.deleting" class="grid-cell" xstyle="position: relative;">
												
												<div
													style="position: relative;"
													class="state"
													[ngClass]="getCreativeClass(creative, j, data.channels[j])"
													[class.selectMode] = "selectionMode || packageMode"
													[class.selectedPackage] = "packageMode && creative.package"
													[class.active]="packageMode && creative.package == 1"
                                                	[class.new]="packageMode && creative.package == 2"
                                                	[class.remove]="packageMode && creative.package == -1"
													
                                                    id="{{(creative.isNew) ? 'isNewSlot' : 'notnew'}}"

													(click)="creativeClicked(creative,$event)"> <!--cdkOverlayOrigin #trigger="cdkOverlayOrigin" (mouseenter)="isOpened=true" (mouseleave)="isOpened=false"-->
													<div *ngIf="creative.label && creative.label != 'null'" class="label-tri" [style]="{'borderTopColor':'#'+creative.label}"></div>
													<!-- state icon -->
													<mat-icon [class.statePipGap]="canShowPips(creative.state)" [ngClass]="{'red' : creative.state == 'warning' || creative.warning }">{{creative.warning ? 'warning' : getIcon(creative.state)}}</mat-icon><!-- [ngClass]="{'red' : creative.state == 'warning' || creative.warning }"  -->
													<!-- state label -->
													<span class="currentState" [class.statePipGap]="canShowPips(creative.state)" [ngClass]="{'red' : creative.state == 'warning' || creative.warning }">{{creative.state == 'warning' ? 'no reviewers' : creative.state}}</span><!--|i:{{i}}|j:{{j}}  -->
													<!-- <span *ngIf="creative.state == 'warning'" class="currentState"><mat-icon class="red">warning</mat-icon></span>-->
														<!--<span>{{creative.myTaskCount}}</span>/<span>{{creative.totalTaskCount}}</span>-->
														<!--<span class="badge"><sup>1</sup>&frasl;<sub>2</sub></span>-->
														<!--<span class="badge" [ngClass]="'state-'+creative.state"></span>-->
													<!-- meta info -->
													<!--
													<div *ngIf="creative.meta != 0" class="meta">
														<span>{{creative.meta}}</span>
													</div>-->

													<!-- creative flag-->
													<div *ngIf="creative.flag" class="flag">
														<mat-icon class="info icon-scale-0_5">{{getFlag(creative.flag)}}</mat-icon>
														<!--warning:issue / snooze:temo on hold / contact_support:need help or question-->
													</div>
													

													<!-- approval group name -->
                                                    <div class="groups" *ngIf="creative.groupInfo.groups && currentPipGroupLookup[creative.uuid] && !selectionMode && !packageMode">
                                                        <strong style="font-size: 0.8em; font-weight:bold">{{currentPipGroupLookup[creative.uuid].name}}</strong>
                                                    </div>
													<!-- group pips NOT USED --> 
													<div class="groups" *ngIf="false && creative.groupInfo.groups && canShowPips(creative.state) && !selectionMode && !packageMode">	
														<span *ngFor="let info of creative.groupInfo.groups; index as index" class="group_pip"
															[class.group_pip-done]="info.total > 0 && info.total == info.approved"
															[class.group_pip-current]="false && isCurrentPipGroup(index,creative)" 
															[class.group_pip-active]="activePipGroupLookup[creative.uuid][index]" 
															[class.group_pip-error]="info.total == 0"
															[matTooltip]="isCurrentPipGroup(index,creative) ? 'current approval group: '+info.name:''">
														</span> 
														<span class="meta">{{creative.meta}}</span>
														
														<!-- [class.group_pip-active]="(index == 0 && (info.total > 0 && info.users[0].state !='waiting' && info.total != info.approved)) || (index > 0 && creative.groupInfo.groups[index-1].total > 0 && creative.groupInfo.groups[index-1].total == creative.groupInfo.groups[index-1].approved)" -->
													</div>   
													
													<!-- select -->
													<div *ngIf="selectionMode" class="state-selectMode">
														<!-- <mat-checkbox color="primary" (change)="selectionChange($event)" [(ngModel)]="creative.selected"></mat-checkbox> -->
														<mat-checkbox color="primary" (change)="selectionChange('creative', creative.uuid)" [checked]="(selectionService.selection$ | async)?.creative[creative.uuid]"></mat-checkbox>
													</div>

													<!-- package -->
													<div *ngIf="false && packageMode" class="package">
														<ng-container  [ngSwitch]="creative.package">
															<div *ngSwitchCase="1">
																<mat-icon>remove</mat-icon>
																<!-- <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:-1})"><mat-icon>remove</mat-icon></button> -->
															</div>
															<div *ngSwitchCase="-1">
																<mat-icon>cancel</mat-icon>
																<!-- <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:1})"><mat-icon>cancel</mat-icon></button> -->
															</div>
															<div *ngSwitchCase="2">
																<mat-icon>cancel</mat-icon>
																<!-- <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:0})"><mat-icon>cancel</mat-icon></button> -->
															</div>
															<div *ngSwitchDefault>
																<mat-icon>add</mat-icon>
																<!-- <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:2})"><mat-icon>add</mat-icon></button> -->
															</div>
														</ng-container>	
													</div>

												</div>

												<!-- task badge -->
												<span *ngIf="false && !selectionMode && !packageMode && creative.meta.total>0" style="z-index:1000; position:'absolute'; bottom: 10px; right: 10px;" [matBadge]="creative.meta.total" matBadgeSize="small" matBadgeOverlap="true" matBadgeColor="warn"></span>
                                                
                                                <!-- top left -->
                                                 <div class="tl" *ngIf="!selectionMode">
													<!-- creative users -->
													<div *ngIf="creativeUsers?.[creative.uuid]" class="users">
                                                        <!--

                                                            <div *ngIf="creativeUsers[creative.uuid].length == 0">
                                                                {{creative.uuid}}                                                       }
                                                            </div>
                                                        -->
                                                            <span *ngFor="let user of creativeUsers[creative.uuid] | filterUnique:'uuid'" [matTooltip]="user.name">{{user.initials}}</span>
													</div>
                                                 </div>
                                                
                                                <div class="tr">
                									<!-- info icon rollover -->
													<div class="info" click-stop-propagation (click)="null" (mouseenter)="openInfoPanel(creative, $event)" (mouseleave)="this.closeInfoPanel()"><!--  -->
														<!--<div class="spinning-loader"></div>-->
														<!--<div class="stage">
															<div class="dot-flashing"></div>
														</div>-->
														<!--
														<div #elipses style="display: none;">
															<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
														</div>
														-->
														<mat-icon class="info-icon icon-scale-0_75">info_outline</mat-icon>     
														<div class="info-loading" #elipses style="display: none;">
															<div class="info-loading-inner"></div>
														</div>      
													</div>
                                                </div>

                                                <!-- bottom left-->
                                                <div class="bl">
                                                    <!-- delivery -->
                                                    <div *ngIf="creative.deliveryStatus && creative.deliveryStatus.className !='none'" class="delivery">
                                                        <div [matTooltip]="creative.deliveryStatus.description"
                                                            [ngClass]="creative.deliveryStatus.className">                                            
                                                            <mat-icon class="icon-scale-0_75">
                                                                {{creative.deliveryStatus.className.indexOf('noneActive') != -1 ? 'mail_outline' : 'mail'}}
                                                            </mat-icon>
                                                            <!--<div class="delivNum">{{this.creativeLookup[creative.uuid].deliveryPackages.length}}</div>-->
                                                        </div>
                                                    </div>	
                                                </div>
                                                <!-- bottom right-->
                                                <div class="br" *ngIf="!selectionMode">
                                                    <!-- unread discussion messages -->
                                                    <mat-icon *ngIf="discussionDates?.[creative.uuid]" class="icon-scale-0_75 discussion-icon" matTooltip="Unread discussion messages">forum</mat-icon>
                                                    <!-- warnings  -->
                                                    <mat-icon *ngIf="!packageMode && creative.warnings" class="warn icon-scale-0_75" [matTooltip]="getWarning(creative.warnings)" matTooltipClass="multiline-tooltip">priority_high</mat-icon>
                                                 </div>


												
                                                <!--

                                                    <ng-template #deliveryIconRef [ngTemplateOutlet]="deliveryIconRef" let-deliveryState [ngTemplateOutletContext]="{ $implicit: getDeliveryIconClass(creative) }">
                                                        <div *ngIf="deliveryState" class="delivery">
                                                            <div [matTooltip]="creative.deliveryStatus" [ngClass]="deliveryState"
															[class.delivered_blocked] = "creative.state != 'done' && deliveryState != 'delivered_none'">
															<mat-icon>mail</mat-icon>
														</div>	
													</div>
													</ng-template>
                                                
                                            	-->
											</div>

                                            <!--deleting-->
                                            <div *ngIf="creative && creative.uuid && creative.deleting" class="state deleting">
                                                <div>
                                                    <mat-icon >block</mat-icon>
                                                </div>
                                            </div>

											<!-- add -->
											<div *ngIf="creative && !creative.uuid && isProjectAdmin() && !selectionMode && !packageMode && isMainGrid" class="state add">
                                                <div class="pending" *ngIf="creatingLookup[data.format.uuid + '_'+ data.channels[j].uuid]">
                                                    <mat-icon>pending</mat-icon>
                                                </div>
                                                <div [matTooltip]="'Add '+data.format.name + ' ' + data.channels[j].name" class="adding" *ngIf="!creatingLookup[data.format.uuid + '_'+ data.channels[j].uuid]" (click)="addCreative(data.workflow, data.format, data.channels[j])">
                                                    <mat-icon>add_circle</mat-icon>
                                                </div>
											</div>

											<!--empty-->
											<div *ngIf="!creative"></div>
										</ng-container>	
										
                                    <!--
                                    <ng-container *ngIf="false && packageMode">
                                        <div *ngIf="creative && creative.uuid">
                                            <div class="state"
                                                [ngClass]="getCreativeClass(creative, j, data.channels[j])"
                                                [class.packageActive]="creative.package == 1"
                                                [class.packageNew]="creative.package == 2"
                                                [class.packageRemove]="creative.package == -1">
                                                <mat-icon [class.statePipGap]="canShowPips(creative.state)" [ngClass]="{'red' : creative.state == 'warning' || creative.warning }">{{creative.warning ? 'warning' : getIcon(creative.state)}}</mat-icon>
                                                <span class="currentState" [class.statePipGap]="canShowPips(creative.state)" [ngClass]="{'red' : creative.state == 'warning' || creative.warning }">{{creative.state == 'warning' ? 'approval' : creative.state}}</span>
                                                <div class="package">
                                                    <ng-container [ngSwitch]="creative.package">
                                                        <div *ngSwitchCase="1">
                                                            <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:-1})"><mat-icon>remove</mat-icon></button>
                                                        </div>
                                                        <div *ngSwitchCase="-1">
                                                            <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:1})"><mat-icon>cancel</mat-icon></button>
                                                         </div>
                                                         <div *ngSwitchCase="2">
                                                            <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:0})"><mat-icon>cancel</mat-icon></button>
                                                         </div>
                                                         <div *ngSwitchDefault>
                                                            <button mat-icon-button (click)="packageChanged.emit({uuid:creative.uuid, value:2})"><mat-icon>add</mat-icon></button>
                                                         </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </ng-container>
									-->
                                 </td>
                            </tr>
                        </ng-container>
                        <!--default case when there are no matches -->
                        <ng-container *ngSwitchDefault></ng-container>
                    </ng-container>                    
                </ng-container>
            </tbody>
        </ng-container>
        <ng-container *ngIf="!gridData">
            <thead>
                <tr><th>Loading...</th></tr>
            </thead>
        </ng-container>
                        
    </table>
    <div class="more"></div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="addChannel()">Add Channel</button>
        <button mat-menu-item [disabled]="workflows?.length == 0" [matMenuTriggerFor]="menuAddFormat">Add Format to...</button>
        <button mat-menu-item [disabled]="workflows?.length == 0" [matMenuTriggerFor]="menuAddPeople">Add People to...</button>
        <button mat-menu-item (click)="addWorkflow()">Add Workflow</button>
    </mat-menu>
    <mat-menu #menuAddFormat="matMenu">
        <div disabled="true" class="note" mat-menu-item>Select a workflow:</div>
        <mat-divider></mat-divider>
        <button *ngFor="let workflow of workflows" mat-menu-item (click)="addFormat(workflow)">{{workflow.name}}</button>
    </mat-menu>
    <mat-menu #menuAddPeople="matMenu">
        <div disabled="true" class="note" mat-menu-item>Select a workflow:</div>
        <mat-divider></mat-divider>
        <button *ngFor="let workflow of workflows" mat-menu-item (click)="editWorkflow(workflow.id)">{{workflow.name}}</button>
    </mat-menu>
    <mat-menu #menuChannel="matMenu">
        <ng-template matMenuContent let-projectChannel="projectChannel">
            <button mat-menu-item (click)="editProjectChannel(projectChannel)"><mat-icon class="medium">edit</mat-icon>Edit Channel</button>
            <button mat-menu-item (click)="deleteProjectChannel(projectChannel)" class="delete"><mat-icon class="medium">delete</mat-icon>Delete Channel</button>
        </ng-template>
    </mat-menu>
    <mat-menu #menuWorkflow="matMenu">
        <ng-template matMenuContent let-workflow="workflow">
            <button mat-menu-item [matTooltip]="'assign users to  \''+workflow.name+'\' workflow'" (click)="editWorkflow(workflow.id)">
                <mat-icon class="medium">group_add</mat-icon><span>Edit this workflow</span>
            </button>
            <button mat-menu-item [matTooltip]="'add format to \''+workflow.name+'\' workflow'" (click)="addFormat(workflow)">
                <mat-icon class="medium">add_circle_outline</mat-icon><span>Add format to this workflow</span>
            </button>
            <button mat-menu-item [matTooltip]="'Delete this workflow!'" (click)="deleteWorkflow(workflow.uuid)" class="delete">
                <mat-icon class="medium">delete</mat-icon><span>Delete this workflow</span>
            </button>
        </ng-template>
    </mat-menu>
    <mat-menu #menuWorkflowChannel="matMenu">
        <ng-template matMenuContent let-workflow="workflow" let-workflowChannel="workflowChannel">
            <button (click)="fillChannel(workflow, workflowChannel)" mat-menu-item >
                <mat-icon class="medium">add_circle_outline</mat-icon><span>Fill column with creative slots</span>
            </button>
            <button (click)="deleteWorkflowChannel(workflow, workflowChannel)" mat-menu-item class="delete">
                <mat-icon class="medium">link_off</mat-icon><span>De-activate workflow channel</span>
            </button>
        </ng-template>
    </mat-menu>
    <mat-menu #menuFormatGroup="matMenu">
        <ng-template matMenuContent let-format="format">
            <button (click)="addFormat(null, format)" mat-menu-item><mat-icon class="medium">add_circle_outline</mat-icon>Add format to this category</button>
            <button (click)="editGroupName(format)" mat-menu-item><mat-icon class="medium">edit</mat-icon>Edit category name</button>
            <button (click)="deleteGroup(format)" mat-menu-item class="delete"><mat-icon class="medium">delete</mat-icon>Delete category</button>
        </ng-template>
    </mat-menu>
    <mat-menu #menuFormat="matMenu">
        <ng-template matMenuContent let-format="format" let-workflow="workflow">
            <button (click)="editFormat(format)" mat-menu-item><mat-icon class="medium">edit</mat-icon>Edit format</button>
            <button (click)="fillFormat(workflow,format)" mat-menu-item><mat-icon class="medium">add_circle_outline</mat-icon>Fill row with creative slots</button>
            <button (click)="deleteFormat(format)" mat-menu-item class="delete"><mat-icon class="medium">delete</mat-icon>Delete format</button>
        </ng-template>
    </mat-menu>
	<mat-menu #menuWorkflowChannelUsers="matMenu" class="workflow-users-menu">
        <ng-template matMenuContent let-users="users" let-title="title" let-channel="channel">
			<div class="title">{{channel}}: {{title}}</div>
            <mat-divider></mat-divider>
			<ng-container *ngFor="let user of users">
				<button disabled mat-menu-item>{{user.name}}</button>
			</ng-container>
            <div class="instruction" *ngIf="!users || users.length == 0">No users assigned to role</div>
        </ng-template>
    </mat-menu>

<!--
    <button (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        {{isOpen ? "Close" : "Open"}}
      </button>
      -->
<!-- This template displays the overlay content and is connected to the button -->
<!--
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <ul class="example-list">
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
  </ul>
</ng-template>
-->
<!--
    <p [class.active]="isOpened" class="origin" >here we go</p>
    <div   cdkOverlayOrigin #CdkOverlayOrigin="cdkOverlayOrigin" >
        mouse over on me for 300ms to open overlay
    </div>
    <app-preview [CdkOverlayOrigin]="CdkOverlayOrigin"
(close)="isOpened = false"
(open)="isOpened = true">
my overlay data here....
</app-preview>
-->
    <!--
    <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpened"
  >
    <ul class="example-list">
      <li>Item 1</li>
      <li>Item 2</li>
      <li>Item 3</li>
    </ul>
  </ng-template>
  -->
    <!--
    <table>
        <thead>
            <tr>
                <th>Teams</th>
                <th *ngFor="let number of ['default', 'en', 'de', 'fr', 'it', 'es', 'nl', 'ru', 'sv', 'nl']">{{number}}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let group of groups">
                <tr><th class="group">{{group.name}}</th></tr>
                <tr *ngFor="let team of group.teams">
                    <th>{{team.name}}</th>
                    <td *ngFor="let score of team.scores">{{score}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    -->
</div>

<!--
<div class="container" style="display:none;">
    <div class="row head">
            <div class="cell">Teams</div>
            <div class="cell"*ngFor="let number of [1,2,3,4,5,6,7,8,9]">{{number}}</div>
            <div class="cell">Runs</div>
    </div>
    <div class="row" *ngFor="let team of teams">
        <div class="cell">{{team.name}}</div>
        <div class="cell" *ngFor="let score of team.scores">{{score}}</div>
    </div>
</div>-->
