import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreativeAction } from '../dialogs/remove-production/remove-production.component';
import { ApprovalGroup, IApprovalGroup } from '../models/ApprovalGroup.model';
import { IWorkflow } from '../models/workflow.model';
import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class WorkflowService extends OVBaseService<IWorkflow, string> {
	createCreative(workflow_uuid: string, channel:string, format:string):Observable<any>
	{
		return this._post(`${this.base}/${workflow_uuid}/creative`, {channel, format});
	}
	
	constructor(protected http: HttpClient) {
		super(http, 'workflow');
	}
	createFormat(id:string, name:string, group:string = null, autoaddslot:boolean):Observable<any>
	{
		return this._post(`${this.base}/${id}/format`, {name, group, autoaddslot});
	}
	fillFormat(id:string, format_uuid:string):Observable<any>
	{
		///{workflow}/format/{creativeFormat}/fill
		return this._post(`${this.base}/${id}/format/${format_uuid}/fill`);
	}
	fillChannel(id:string, channel_uuid:string):Observable<any>
	{
		///{workflow}/format/{creativeFormat}/fill
		return this._post(`${this.base}/${id}/channel/${channel_uuid}/fill`);
	}
	
	createChannel(id: string, project_channel_uuid:string, autoaddslot:boolean):Observable<any>
	{
		return this._post(`${this.base}/${id}/channel/${project_channel_uuid}`, {autoaddslot});
	}
	deleteChannel(id: string, channel_uuid:string) {
		return this._delete(`${this.base}/${id}/channel/${channel_uuid}`);
	}
	updateGroupName(uuid: string, oldName:string, newName: string) {
		return this._post(`${this.base}/${uuid}/groupName`, {oldName, group:newName}); // for validation to work in laravel, the request key needed to match the column name
	}
	deleteGroup(uuid: string, group:string) {
		return this._delete(`${this.base}/${uuid}/group/${group}`);
	}
	assignUserToGroup(id: string, user_uuid: string, group_uuid: string) {
		return this._post(`${this.base}/${id}/assignUserToGroup/${user_uuid}/${group_uuid}`);
	}
	updateUserGroup(id:string, user_uuid: string, group_uuid: string) {
		return this._post(`${this.base}/${id}/updateApproverGroup/${user_uuid}/${group_uuid}`);
	}
	grantApproverChannel(id: number, user_uuid: string, channel_id: string, permissions: number, group_id: any) {
		return this._post(`${this.base}/${id}/grantApproverChannel/${user_uuid}/${channel_id}/${permissions}`, {group_id});
	}
	grantUserChannel(id: number, user_uuid: string, channel_id: string, permissions: number) {
		return this._post(`${this.base}/${id}/grantUserChannel/${user_uuid}/${channel_id}/${permissions}`);
	}
	grantUserChannels(id: string, user_uuid: string, permissions: number, channel_ids:string[]) {// group:IApprovalGroup = null
		return this._post(`${this.base}/${id}/grantUserChannels/${user_uuid}`, {permissions, channel_ids} );//, group
	}
	getUsers(id: number):Observable<any>{
		return this._get(`${this.base}/${id}/users`);
	}
	getProductionInfo(id:string, user_uuid:string, channels:string[]):Observable<any>
	{
		return this._post<any>(`${this.base}/${id}/productionInfo/${user_uuid}`, {channels});
	}
	revokeUserProduction(id : string, user_id:string, channels:string[], actions:CreativeAction[]) {
		return this._post(`${this.base}/${id}/revokeUserProduction/${user_id}`, {channels, actions});
	}
	getApproverInfo(id:string, user_uuid:string, channels:string[]):Observable<any>
	{
		return this._post(`${this.base}/${id}/approverInfo/${user_uuid}`, {channels});
	}
	// andgroup will only work if all channels are sent too
	revokeUserApproval(id : string, user_id:string, channels:string[], actions:CreativeAction[], andGroup:boolean = false) {
		return this._post(`${this.base}/${id}/revokeUserApproval/${user_id}`, {channels, actions, andGroup});
	}
	approvalGroupCreate(id: string, group:ApprovalGroup):Observable<any>{
		return this._post(`${this.base}/${id}/approvalGroup`, group);
	}
	setValidator(id:string, user:any)
	{
		return this._post(`${this.base}/${id}/setValidator/${user.uuid}`);
	}
	removeValidator(id:string, user:any)
	{
		return this._post(`${this.base}/${id}/removeValidator/${user.uuid}`);
	}
}
