import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMarker } from '../models/marker.model';
import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class TaskMarkupService extends OVBaseService<IMarker, number> {
	constructor(protected http: HttpClient,private snackBar:MatSnackBar) {
		super(http, 'taskMarkup');
	}	
}