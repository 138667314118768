<div class="title-header">
	<mat-icon class="title-icon" *ngIf="data.titleIcon">{{data.titleIcon}}</mat-icon>
	<span mat-dialog-title *ngIf="data.title">{{ data.title }}</span>
</div>
<mat-dialog-content>
	<h3 class="subtitle" *ngIf="data.subtitle">{{ data.subtitle }}</h3>
	<!-- <p class="body" *ngIf="data.body">{{ data.body }}</p> -->
	<div class="body" *ngIf="data.body" [innerHtml]="data.body | safe:'html'"></div>
	<form #form="ngForm" (keydown.enter)="triggerSubmit()" *ngIf="data.form" [formGroup]="formGroup"
		(ngSubmit)="onSubmit()">
		<div *ngFor="let entry of data.form; index as i" #element [ngSwitch]="entry.type">
			<!--<mat-label>{{entry.label}}</mat-label>-->
			<ngContainer *ngSwitchCase="'text'">
				<mat-form-field>
					<!--placeholder="Enter Name" [formControl]="formControlName"  [(ngModel)]="this.data.model[entry.field]" -->
					<input type="{{ entry.type }}" [formControl]="entry.control"
						placeholder="{{ entry.placeholder || null }}" [value]="getValue(entry)"
						[matAutocomplete]="entry.autoComplete != undefined ? auto : null"
						[matAutocompleteDisabled]="entry.autoComplete == undefined" matInput autocompleteOff />
					<mat-error *ngIf="entry.control.hasError('required')">Value is <strong>required</strong></mat-error>
					<mat-autocomplete #auto="matAutocomplete">
						<mat-option *ngFor="let option of entry.autoCompleteObservable | async" [value]="option">
							{{ option }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</ngContainer>
			<ngContainer *ngSwitchCase="'textarea'">
				<mat-form-field>
					<!--placeholder="Enter Name" [formControl]="formControlName"  [(ngModel)]="this.data.model[entry.field]" -->
					<textarea type="{{ entry.type }}" [formControl]="entry.control"
						placeholder="{{ entry.placeholder || null }}" [value]="getValue(entry)"
						[matAutocomplete]="entry.autoComplete != undefined ? auto : null"
						[matAutocompleteDisabled]="entry.autoComplete == undefined" matInput autocompleteOff ></textarea>
					<mat-error *ngIf="entry.control.hasError('required')">Value is <strong>required</strong></mat-error>
					<mat-autocomplete #auto="matAutocomplete">
						<mat-option *ngFor="let option of entry.autoCompleteObservable | async" [value]="option">
							{{ option }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</ngContainer>

			<ngContainer *ngSwitchCase="'checkbox'">
				<mat-checkbox color="primary" [formControl]="entry.control" [value]="getValue(entry)">
					{{ entry.label }}
				</mat-checkbox>
			</ngContainer>
			<ngContainer *ngSwitchCase="'checkbox_list_item'">
				<mat-checkbox class="list-item" color="primary" [formControl]="entry.control" [value]="getValue(entry)">
					{{ entry.label }}
				</mat-checkbox>
			</ngContainer>
			<ngContainer *ngSwitchCase="'select'">
				<!--

                    <mat-select
                    [formControl]="entry.control"
                    [value]="getValue(entry)">
                    {{entry.label}}
                  </mat-select>
                -->
				<mat-form-field appearance="fill">
					<mat-label *ngIf="entry.label">{{ entry.label }}</mat-label>
					<mat-select [formControl]="entry.control">
						<mat-option *ngFor="let item of entry.data" [value]="item">
							{{ item.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</ngContainer>
			<ngContainer *ngSwitchCase="'label'">
				<div *ngIf="entry.label">{{ entry.label }}</div>
			</ngContainer>
			<ngContainer *ngSwitchCase="'divider'">
				<mat-divider></mat-divider>
			</ngContainer>
		</div>
		<!--
            <div [ngSwitch]="form_elem.type">
              <div *ngSwitchCase="'textBox'">
                <input type="text" formControlName="{{form_elem.label}}"/>
              </div>
              <div *ngSwitchCase="'number'">
                <input type="number" formControlName="{{form_elem.label}}"/>
              </div>
              <div *ngSwitchCase="'select'">
                <select formControlName="{{form_elem.label}}">
                  <option *ngFor="let opt of form_elem.options">
                    {{opt}}
                  </option>
                </select>
              </div>
            </div> 
            -->
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div *ngIf="data.paginate" class="pagination">{{data.paginate}}</div>
	<button class="small" *ngIf="data.negative" mat-button mat-dialog-close color="warn">
		{{ data.negative }}
	</button>
	<button class="small" *ngIf="data.positive" mat-raised-button [mat-dialog-close]="data" color="primary" [disabled]="!isValid()">
		{{ data.positive }}
	</button>
</mat-dialog-actions>