import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StringsService {

	constructor() { }
	
	tooltips:any = {
		'btn_home': 'Home',
		'btn_notifications': 'Notifications',
		'btn_settings': 'Settings',
		'btn_admin': 'Admin',
		'btn_logout': 'Log out of app',
		'btn_dashboard': 'My Dashboard',
		'btn_my_profile': 'My Profile',
		'btn_users': 'Manage Users',
		'btn_projects': 'Projects',
		'btn_my_projects': 'My Projects',
		'btn_delete': 'Delete',
		'btn_edit': 'Edit',
		'btn_profile': 'Profile',
		'btn_assign_users': 'Assign Users',
		'btn_add_workgroup': 'Add new workgroup',
		'btn_edit_workgroup': 'Edit workgroup',
		'btn_delete_workgroup': 'Delete workgroup',
		'btn_add_project': 'Add new project',
		'btn_edit_project': 'Edit project',
		'btn_delete_project': 'Delete project',
		'btn_add_channel': 'Add new channel',
		'btn_edit_channel': 'Edit channel',
		'btn_delete_channel': 'Delete channel',
		'btn_add_format': 'Add new format',
		'btn_edit_format': 'Edit format',
		'btn_delete_format': 'Delete format',
		'btn_add_group': 'Add new group',
		'btn_edit_group': 'Edit group',
		'btn_delete_group': 'Delete group',
	};
	
	headings:any = {
		'my_profile': 'My Profile',
		'add_account': 'Add Account',
		'add_workgroup': 'Add Workgroup',
		'add_folder': 'Add Folder',
		'add_project': 'Add Project',
		'edit_account': 'Edit Account',
		'edit_workgroup': 'Edit Workgroup',
		'edit_folder': 'Edit Folder',
		'edit_project': 'Edit Project',
	};
	
	errors:any = {
		'login': 'Invalid email or password',
	};
	
	public getTooltip(key:any){
		if(typeof this.tooltips[key] !== 'undefined'){
			return this.tooltips[key];
		}
		return '';
	}
	
	public getHeading(key:any){
		if(typeof this.headings[key] !== 'undefined'){
			return this.headings[key];
		}
		return '';
	}
	
	public getError(key:any){
		if(typeof this.errors[key] !== 'undefined'){
			return this.errors[key];
		}
		return '';
	}
}
