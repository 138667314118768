<form #dropzone class="dropzone" method="post" action="" enctype="multipart/form-data" [attr.data-width]="width" [attr.data-height]="height">
	<div class="image_scroller">
		<div class="image" *ngFor="let img of previewFiles" [attr.data-src]="img">
			<div class="remove"><mat-icon>remove_circle</mat-icon></div>
		</div>
	</div>
	<input id="input_upload_file" type="file" class="upload ng-hide" name="input_upload_file" />
	<div class="button_wrapper">
		<button (click)="browse()" class="blue" mat-raised-button>Browse...</button>
	</div>
</form>

<div #droptarget style="border: 1px dashed grey; border-radius: 1em; width: 100;"
	appDnd (fileDropped)="handleDrop($event)">
	<div>
		<mat-icon>upload</mat-icon>
		<div>Drag files here to creat multiple assets</div>
	</div>	
</div>