// https://dev.to/idrisrampurawala/creating-a-search-filter-in-angular-562d
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
	/**
	 * Transform
	 *
	 * @param {any[]} items
	 * @param {string} searchText
	 * @returns {any[]}
	 */
	transform(items: any[], search: string, include:string|string[] = null, exclude:string|string[] = null): any[] {
	if(!items)  return [];
	if(!search) return items;
	search = search.toLocaleLowerCase();
	return items.filter(item => {
		if(typeof item === 'string') return item.toLocaleLowerCase().includes(search);
		else if (typeof item === 'object') {
			for (const key in item) {
				if (Object.prototype.hasOwnProperty.call(item, key)) {
					if(exclude)
					{
						if(Array.isArray(exclude)){
							if(exclude.includes(key)) continue;
						} else if (exclude === key) {
							continue;
						}
					}
					if(include)
					{
						if(Array.isArray(include)){
							if(!include.includes(key)) continue;
						} else if (include !== key) {
							continue;
						}
					}
					const element = item[key];
					if(typeof element === 'string' && element.toLocaleLowerCase().includes(search)) return true;		
				}
			}
			return false;
		}
		else return false;
		});
	}
}