import { Injectable } from '@angular/core';
import { Asset, AssetType, AssetVO } from 'src/app/models/asset.model';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import * as JSZip from 'jszip';
import { AssetMetadataService } from 'src/app/services/assetMetadata.service';
import { FileUtils } from 'src/app/utils/FileUtils';

@Injectable({
	providedIn: 'root'
})
export class AssetsComponentService {

	private _assets:AssetVO[];
	private _selectedAsset:AssetVO|null;
	private _assetsSubject = new BehaviorSubject<AssetVO[]>(null);
	private _selectedAssetSubject = new BehaviorSubject<AssetVO>(null);

	private _creativeUUIDSubject = new BehaviorSubject<string>(null);
	private _creativeStateSubject = new BehaviorSubject<string>(null);

	private _isProductionUserSubject = new BehaviorSubject<boolean>(false);
	private _isAdminUserSubject = new BehaviorSubject<boolean>(false);
	private _editable = new BehaviorSubject<boolean>(false);

	private _draggable:boolean = true;
	private _draggableSubject = new BehaviorSubject<boolean>(this._draggable);
	public draggable$ = this._draggableSubject.asObservable();
	public set draggable(draggable:boolean)
	{
		this._draggable = draggable;
		this._draggableSubject.next(this._draggable);
	}

	private _filesDraggedSubject = new BehaviorSubject<any>(null);

	public filesDragged$ = this._filesDraggedSubject.asObservable();
	public assets$ = this._assetsSubject.asObservable();
	public selectedAssets$ = this._selectedAssetSubject.asObservable();


	public set assets(assets:AssetVO[])
	{
		this._assets = assets;
		this._assetsSubject.next(this._assets);
	}
	public get assets():AssetVO[]	// would have preferred this to be private
	{
		return this._assets;
	}
	public set selectedAsset(asset:AssetVO)
	{
		this._selectedAsset = asset ? this._assets?.find(assetVO => asset.asset.uuid ? asset.asset.uuid == assetVO.asset.uuid : asset.asset.id == assetVO.asset.id) : null;
		this._selectedAssetSubject.next(this._selectedAsset);
	}

	addAsset(assetVO: AssetVO) {
		this._assets.push(assetVO);
		this._assetsSubject.next(this._assets);
	}
	async handleFilesDragged(creative_uuid:string, files: File[], position: { x: any; y: any; }, targetAsset: AssetVO) {
		// store this for consumption
		//this._filesDraggedSubject.next({creative_uuid, files, position, targetAsset});
		//this.onFilesDragged()
		return;
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Filename(s) with illegal characters",
				subtitle: "test",
				body: `test`,
				positive: "Ok",
				negative: "nok",
			}
		});
		let result = await dialogRef.afterClosed().toPromise();
		if (result) {
			console.log("result", result)
		}else{
			return null;
		}
	}
	clearFilesDragged() {
		this._filesDraggedSubject.next(null);
	}
	constructor(
		private dialog:MatDialog,
		private dialogService:DialogService,
		protected assetMetadataService: AssetMetadataService,
	) {
		this.assetMetadataService.assetMeta.pipe().subscribe(assetVO => {
			let target = this._assets.find(asset => asset == assetVO);
			console.log("ON METEA", assetVO);
			if(target){
				//if(this.selectedAsset == target){
				//	this.maintainRatio = true;
				//	this.setAspectRatio();
				//}
				target.updateMetaView();
				this._assetsSubject.next(this._assets);
			}
		})
	}
	async onFilesDragged(creative_uuid:string, files:File[], position:{x:number,y:number} = null, targetAsset:AssetVO = null)
	{
		// state check
		//if(!(this.editable && this.production && this.isCreativeInProductionState()))
		{
		//	return;
		}

		// TODO enable folder upload support!!! https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree

		files = await this.checkFiles(files);
		if(!files || files.length == 0) return;
			/*
			dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
				if (result) {
					if(illegalFiles.length < files.length)
					{
						
					}
				}
			});
			return;
			*/
		
		// process each file first (check type + validity against spec maybe) then generate a preview if possible

		//console.log("on files dragged", files);
		let enableDropPos:boolean = event && this._assets?.length != 0;

		// remove unknown types
		files = files.filter((file) => this.getTypeFromFilename(file.name));

		if(targetAsset)
		{
			//
			let asset = this._assets.find(asset => asset == targetAsset);
			if(asset)
			{
				asset.file = files[0];
				this.processAsset(asset);
			}
			return;
		}

		// check for duplicate filenames
		let duplicates = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if(!this.isFilenameUnique(file))
			{
				duplicates.push(file);
				files.splice(i--, 1);
			}
		}

		// we have duplicate what shall we do, ignore or replace
		if(duplicates.length)
		{
			let form = [];
			for (let i = 0; i < duplicates.length; i++) {
				const file = duplicates[i];
				let item = { name: i, type: "checkbox", label: file.name, value: false };
				form.push(item);
			}
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
					title:"Resolve conflicing filenames",
					subtitle: "Check the box to replace the file or leave unchecked to skip it",
					negative: "Cancel",
					positive: "Continue",
					form
				}
			});
			dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
				if (result) {
					let values = result.formGroup.value; // {index:true/false}
					duplicates = duplicates.filter((file, index) => values[index]);
					if(duplicates.length)
					{
						// replace the duplicates
						duplicates.every(file => {
							let asset = this.findAssetByFilename(file.name);
							if(asset)
							{
								asset.file = file;
								this.processAsset(asset);
							}
						})
					}
					this.resolveNewFiles(files, enableDropPos, position);
				}
			});
			return;
		}
		this.resolveNewFiles(files, enableDropPos, position);

	}
	async checkFiles(files:File[])
	{
		// check for filename bug where long file names + long file paths cause a filename issue
		let issueFiles = files.filter((file) => file.name.indexOf("~") !== -1);
		if(issueFiles.length){
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
					title:"Bad filename detected",
					subtitle: "Please check filename lengths and/or upload from a shorter path",
					positive: "Continue",
				}
			});
			return null;
		}
		const illegalChars = ['%', '#'];	//, "'",
		let illegalFiles = files.flatMap((file) => {
			let illegalsFound = [];
			illegalChars.forEach(char => {
				if(file.name.indexOf(char) !== -1) illegalsFound.push(char);
			});
			if(illegalsFound.length) return [{file, illegalChars}];
			else return [];
		});
		if(illegalFiles.length){
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
					title:"Filename(s) with illegal characters",
					subtitle: "Please check all filenames, the following characters are not allowed ('"+illegalChars.join("','")+"')",
					body: `${illegalFiles.length} of ${files.length} files not allowed:\n`+illegalFiles.map(i => i.file.name).join("\n"),
					positive: illegalFiles.length < files.length ? "Continue with allowed files" : "Ok",
					negative: illegalFiles.length < files.length ? "Cancel all" : null,
				}
			});
			let result = await dialogRef.afterClosed().toPromise();
			if (result) {
				if(illegalFiles.length == files.length) return null;
				files = files.filter(file => !illegalFiles.find(illegalFile => illegalFile.file == file));
			}else{
				return null;
			}
		}
		return files;
	}
	findAssetByFilename(filename:string):AssetVO
	{
		return this._assets.find(assetVO => assetVO.file?.name == filename || FileUtils.baseName(assetVO.asset?.uri) == filename);
	}
	// TODO work out if replacing or just new
	resolveNewFiles(files:File[], enableDropPos:boolean, position:{x:number,y:number} = null)
	{
		let skippedFiles = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const type = this.getTypeFromFilename(file.name);
			if(!type) continue;	// TODO notify or handle this case

			if(!this.isFilenameUnique(file))
			{
				skippedFiles.push(file);
				continue;
			}

			const asset = new Asset();
			asset.id = this.getTempID();
			// position the asset based on drop point (only do this if there is one asset already)
			if(enableDropPos && position)
			{
				asset.x = position.x + (i * 20);
				asset.y = position.y + (i * 20);
			}else{
				asset.x = 0;
				asset.y = 0;
			}
			
			//const lastDot = file.name.lastIndexOf('.');
			//asset.name = lastDot == -1 ? file.name : file.name.substr(0, lastDot);
			asset.type = type;
			asset.order = this.getHighestOrder();
			asset.visible = true;
			asset.deliverable = true;
			
			let assetVO = new AssetVO(asset);
			assetVO.file = file;
			asset.order = asset.order;	// reference to original order (only used for new assets)
			this.setAssetNameToFilename(assetVO,file.name);

			let success = this.processAsset(assetVO);
			this._assets.push(assetVO);
		}
		this._assetsSubject.next(this._assets);
		if(this._assets.length){
			this.selectedAsset = this._assets[this._assets.length-1];
		}
		if(skippedFiles.length)
		{
			let skippedCount = skippedFiles.length;
			let addedCount = files.length - skippedCount;
			let message = skippedFiles.map(file => file.name).join("\n");
			this.dialogService.openAlert({title:"Duplicate file detected", message: addedCount + " files added, " + skippedCount + " files skipped." + "\nFiles that share a name with an existing asset are not allowed:\n" + message});
		}
		this._assetsSubject.next(this._assets);
	}
	processAsset(assetVO: AssetVO): boolean
	{
		if(!assetVO.file) return false;

		const file = assetVO.file;
		assetVO.fileMetadata = { size:file.size };
		if(assetVO.asset.type === AssetType.IMAGE && file.type.indexOf("image/") === 0)
		{
			const img = new Image();
			assetVO.preview = URL.createObjectURL(file);
			img.addEventListener("load", (e) => {
				// update asset dimensions if asset is new
				if(!assetVO.asset.uuid)
				{
					assetVO.asset.width = img.width;
					assetVO.asset.height = img.height;
				}
				assetVO.fileMetadata.width = img.width;
				assetVO.fileMetadata.height = img.height;
				// TODO image size? 
				//tempObj.width = img.width;
				//tempObj.height = img.height;
				//this.newFile = tempObj;
				//if(callback) callback();
				this._assetsSubject.next(this._assets);
				this.assetMetadataService.assetMetaSubject.next(assetVO);
			});
			img.src = assetVO.preview;
		} else if(assetVO.asset.type === AssetType.AUDIO && file.type.indexOf("audio/") === 0)
		{
			var preview = true;
			var getMetadata = true;
			if(preview) assetVO.preview = URL.createObjectURL(file);
			if(getMetadata)
			{
				/*
				var mime = file.type; // store mime for later
            	var fileReader = new FileReader(); // create a FileReader				
          		fileReader.onload = function(e) { // when file has read:	//fileReader.addEventListener("load", )
					var blob = new Blob([e.target.result], {type: mime}) // create a blob of buffer
					var url = (URL || webkitURL).createObjectURL(blob); // create o-URL of blob
					var media = document.createElement("audio"); // create audio element
					media.preload = "metadata"; // preload setting
					media.addEventListener("loadedmetadata", function() { // when enough data loads
						console.log("media meta loaded", media.duration, media); // show duration
						(URL || webkitURL).revokeObjectURL(url); // clean up
					});
					media.src = url; // start media load
				};
				var chunkSizeMB = 0.75;
				var chunk = file.slice(0, Math.min(chunkSizeMB * 1000000, file.size));
				fileReader.readAsArrayBuffer(chunk); // read file object
				*/
			}

		} else if(assetVO.asset.type === AssetType.VIDEO && file.type.indexOf("video/") === 0)
		{
			var preview = true;
			var getMetadata = true;
			//console.log("getting video meta...");
			
			if(preview) assetVO.preview = URL.createObjectURL(file);
			if(getMetadata)
			{
				// The dimensions can be worked out in the asset preview
			
				/*
				var mime = file.type; // store mime for later
            	var fileReader = new FileReader(); // create a FileReader				
          		fileReader.onload = function(e) { // when file has read:	//fileReader.addEventListener("load", )
					var blob = new Blob([e.target.result], {type: mime}) // create a blob of buffer
					var url = (URL || webkitURL).createObjectURL(blob); // create o-URL of blob
					var media = document.createElement("video"); // create video element
					media.preload = "metadata"; // preload setting
					console.log("video blob loaded adding new listeners");
					media.addEventListener("canplay", function(e)
					{
						console.log("CAN PLAY EVENT");
					});
					media.addEventListener("loadeddata", function(e)
					{
						console.log("LOADED DATA EVENT");
					});
					media.addEventListener("loadedmetadata", function(event) { // when enough data loads
						console.log("media meta loaded", this, media.duration, media.videoWidth, media.videoWidth); // show duration
						asset.width = media.videoWidth || asset.width;
						asset.height = media.videoHeight || asset.height;
						(URL || webkitURL).revokeObjectURL(url); // clean up
						assetVO.fileMetadata = {}; // TODO maybe we should always clear it?
						assetVO.fileMetadata.width = asset.width;
						assetVO.fileMetadata.height = asset.height;
					});
					media.src = url; // start media load
				};
				var chunkSizeMB = 0.75;
				var chunk = file.slice(0, Math.min(chunkSizeMB * 1000000, file.size));
				fileReader.readAsArrayBuffer(chunk); // read file object
				*/
			}
		} else if(assetVO.asset.type === AssetType.PDF && file.type == 'application/pdf') {
			//console.log("PDF meta load");
			
			assetVO.preview = URL.createObjectURL(file);
			pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
			const loadingTask = pdfjsLib.getDocument(assetVO.preview)
			loadingTask.promise.then(pdf => {
			  //console.log("pdf loaded", pdf);
			  assetVO.fileMetadata.pdf = {pages:pdf.numPages};
			  pdf.getPage(1).then(page =>{
				var view = page.view;
				var dpiFix = 96/72;
				assetVO.fileMetadata.width = view[2] * dpiFix;
				assetVO.fileMetadata.height = view[3] * dpiFix;
				// if dimensions are unset then set them based on the file metadata
				// update asset dimensions if asset is new
				if(!assetVO.asset.uuid)
				{
					assetVO.asset.width = assetVO.fileMetadata.width;
					assetVO.asset.height = assetVO.fileMetadata.height;
				}
				this.assetMetadataService.assetMetaSubject.next(assetVO);
			  });
			});
		} else if(assetVO.asset.type === AssetType.BANNER_HTML &&  this.zip_types.indexOf(file.type) != -1) {

			// TODO - investigate replacing file references with local blobs for preview purposes
			// i.e. https://stackoverflow.com/questions/27304992/how-to-extract-a-zip-file-and-render-the-contents-client-side
			// could use service worker (for auth issue also) (https://clurgo.com/en/service-worker-and-static-content-authorization/)
			this.parseBanner(assetVO);

		}else if(assetVO.asset.type === AssetType.FILE){
			//
		} else {
			//
		}
		//TO DO - move this to each async file loader above to rfesh the metadat when ready
		//this.getMetadataSource(assetVO);
		// trigger a change update somehow :)
		assetVO.updateMetaView();
		//this.assetsInChange.emit(this.assets.concat([]));

		return true;
	}
	async parseBanner(assetVO:AssetVO)
	{
		//console.log("parsing banner");
		
		const zip = await JSZip.loadAsync(assetVO.file).catch((err) => console.warn("error parsing zip", err));                                   // 1) read the Blob
		//console.log("zip", zip);
		if(!zip) return;
		if(!zip.files["index.html"])
		{
			this.dialogService.openAlert({
				title:"Incomplete banner",
				message: "'index.html' file not found in zip contents.\n Please replace with corrected zip. "
			});
			assetVO.file = null;
			assetVO.fileMetadata = null;
			assetVO.preview = null;
			assetVO.updateMetaView();
			return;
		}

		zip.forEach((relativePath, zipEntry) => {  // 2) print entries
			//console.log(relativePath, zipEntry)
			// parse the backup image
			if(relativePath === "backup.jpg" || relativePath === "backup.jpeg")
			{
				zip.file(relativePath)
					.async("base64") // could use a blob here (https://stackoverflow.com/questions/40028568/show-image-from-local-zip-with-jszip)
					.then(function(content) {
						assetVO.preview = "data:" + "image/jpeg" + ";base64," + content;
					});
			}
			// parse the index.html
			if(relativePath.indexOf(".html") != -1)
			{
				zip.file(relativePath)
				.async("text") // could use a blob here (https://stackoverflow.com/questions/40028568/show-image-from-local-zip-with-jszip)
				.then((content) => {
					// look for meta tags with content attribute
					//const re = /<meta.+content="(.+?)">/i; // ? makes it non-greedy

					// is flash2html?
					let re = /<meta name="generator" content="fbf_exporter:(\S+)">/i
					let found = content.match(re);
					if(found && found.length === 2)
					{
						assetVO.fileMetadata.banner = {generator:"fbf " + found[1]}; 
						re = /<meta.+content="width=(\d+?),height=(\d+?)">/i; 	// flash2html
						found = content.match(re);
						if(found && found.length === 3)								{
							assetVO.fileMetadata.width = found[1];	// group 1
							assetVO.fileMetadata.height = found[2];	// group 2
						}else{
							re = /{i:"root",w:(\d+),h:(\d+),/i; 	// flash2html (older variants)
							found = content.match(re);
							if(found && found.length === 3)								{
								assetVO.fileMetadata.width = found[1];	// group 1
								assetVO.fileMetadata.height = found[2];	// group 2
							}
						}
					} else {
						re = /<script type="text\/gwd-admetadata">(.+?)<\/script>/i;	// google web designer
						let found = content.match(re);
						if(found && found.length === 2)
						{
							let json = JSON.parse(found[1]);
							if(json && json.creativeProperties)
							{
								assetVO.fileMetadata.width = json.creativeProperties.minWidth;
								assetVO.fileMetadata.height = json.creativeProperties.minHeight;
							}
							assetVO.fileMetadata.banner = {generator:"gwd"}; 
						}else {
							re = /<script gwd-served-sizes="">\s*(.+?)\s*<\/script>/i;
							found = content.match(re);
							if(found && found.length === 2)
							{
								let json = JSON.parse(found[1]);
								if(json && json[0])
								{
									let parts = json[0].split("x");
									assetVO.fileMetadata.width = parseInt(parts[0]);
									assetVO.fileMetadata.height = parseInt(parts[1]);
								}
								assetVO.fileMetadata.banner = {generator:"gwd"}; 
							}else {
								//<meta name="ad\.size" content="width=(\d+)\W+height=(\d+)">
								re = /<meta name="ad\.size" content="\D+(\d+)\D+(\d+)">/i;
								found = content.match(re);
								if(found && found.length === 3)								{
									assetVO.fileMetadata.width = found[1];	// group 1
									assetVO.fileMetadata.height = found[2];	// group 2
									assetVO.fileMetadata.banner = {generator:"adobe-animate"};
								}
							}
						}
					} 
					// update asset dimensions if asset is new
					if(!assetVO.asset.uuid)
					{
						if(assetVO.fileMetadata?.width)
						{
							assetVO.asset.width = assetVO.fileMetadata.width;
						}
						if(assetVO.fileMetadata?.height)
						{
							assetVO.asset.height = assetVO.fileMetadata.height;
						}
					}
					this.assetMetadataService.assetMetaSubject.next(assetVO);
				});
			}
		});
	//console.log( "Error reading " + assetVO.file.name + ": " + e.message);
	}
	getHighestOrder(): number {
		let order = 0;
		for (var i = 0; i < this._assets.length; i++)
		{
			var asset = this._assets[i].asset;
			if(asset.order >= order) order = asset.order + 1;
		}
		return order;
	}
	setAssetNameToFilename(assetVO:AssetVO, filename:string){
		assetVO.asset.name = this.getFilenameNoDot(filename);
	}
	getFilenameNoDot(assetURI:string)
	{
		if(!assetURI) return;
		let filename = FileUtils.baseName(assetURI);
		//let filename = this.filenameFromPath(assetURI);
		let lastDot = filename.lastIndexOf('.');
		return lastDot == -1 ? filename : filename.substr(0, lastDot);
	}
	private isFilenameUnique(file:File, ignore:AssetVO = null):boolean
	{
		return this._assets?.find(assetVO => {
			if(ignore && assetVO == ignore) return false;
			return (assetVO.file?.name == file.name) || FileUtils.baseName(assetVO.asset?.uri) == file.name
		}) ? false : true;
	}

	// https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript/680982
	getExtensionFromFilename(filename: string) : string
	{
		return /\.([^.]+)$/.exec(filename)[1];
		return /(?:\.([^.]+))?$/.exec(filename)[1];
	}
	getTypeFromFilename(filename: string) : string
	{
		const extension = this.getExtensionFromFilename(filename).toLocaleLowerCase();
		if(this.audio_extensions.indexOf(extension) != -1) return AssetType.AUDIO;
		if(this.image_extensions.indexOf(extension) != -1) return AssetType.IMAGE;
		if(this.video_extensions.indexOf(extension) != -1) return AssetType.VIDEO;
		if(this.pdf_extensions.indexOf(extension) != -1) return AssetType.PDF;
		if(this.web_extensions.indexOf(extension) != -1) return AssetType.BANNER_HTML;
		if(this.threed_extensions.indexOf(extension) != -1) return AssetType.THREE_D;
		return AssetType.FILE;
	}

	private audio_extensions = ['aac', 'flac', 'm4a', 'mid', 'mp3', 'mpa', 'wav', 'wma'];
	private image_extensions  = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'psd', 'tga', 'tif', 'tiff', 'ai', 'eps', 'ps', 'svg', 'webp'];
	private video_extensions  = ['avi', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg','ogg','ogv','webm'];
	private pdf_extensions  = ['pdf'];
	private web_extensions  = ['zip', 'html'];
	private threed_extensions  = ['obj', 'gltf'];

	private zip_types = ['application/octet-stream', 'multipart/x-zip', 'application/zip', 'application/zip-compressed', 'application/x-zip-compressed'];

	tempUUID = -1;//new Date().getTime();
	getTempID():number{
		return this.tempUUID--;
	}
}