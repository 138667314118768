import { Component, OnInit, SimpleChanges } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
	/*template: `
<ng-container class="image" >	<!--[ngStyle]="getStyle()" -->
		<!--{{asset.name}}-->
</ng-container>
	`,
	styles: [
	]*/
})
export class ImageComponent extends BaseAssetComponent {

	tmpImg: HTMLImageElement;
	loaded:boolean = false;

	constructor() { 
		super();
	}

	ngOnInit(): void
	{
		this.preload();
	}
	private preload():void
	{
		// background loading logic
		if(this.tmpImg){
			this.tmpImg.onload = null; // remove the previous onload event, if registered
			this.tmpImg.onerror = null; // remove the previous onerror event, if registered
		}
		if(this.assetVO && !this.assetVO.preview)
		{
			this.loaded = false;
			this.tmpImg = new Image();
			this.tmpImg.onload = () => this.loaded = true;
			this.tmpImg.onerror = () => this.loaded = true;
			let path:string = this.getPath();
			if(path)
			{
				this.tmpImg.src = path;
			}else{
				this.loaded = true;
			}
		}else{
			this.loaded = true;
		}
	}
	ngOnChanges(changes: SimpleChanges) {
		
		if(!changes)return;
		if(changes.asset?.currentValue && changes.asset?.previousValue && changes.asset.currentValue.uuid != changes.asset.previousValue.uuid)
		{
			this.preload();
		}
	}
	getStyle(): any {
		if(!this.assetVO) return {};
		let styleObj = super.getStyle();

		
		//let path = this.getPath();
		//if(path) styleObj['backgroundImage'] = 'url(\'' + path + '\')';

		return styleObj;
		/*if(path) return {backgroundImage:'url(\'' + path + '\')'};
		else return {};
		*/
	}

	getImage(): any {
		if(!this.assetVO) return {};
		let path = this.getPath();
		let style:any = {};
		if(path){
			path = path.replace(/'/g, "\\'");	// support "'"" character which is valid in windows FML
			style.backgroundImage = `url('${path}')`;
			if(!this.galleryMode)
			{
				style['width.px'] = this.assetVO.asset.width * this.scale;
				style['height.px'] = this.assetVO.asset.height * this.scale;
			}
		}
		return style;
	}


}
