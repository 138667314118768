import { Component, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CreativeService } from 'src/app/api/creative.service';
import { AssetVO } from 'src/app/models/asset.model';
import { ICreative } from 'src/app/models/creative.model';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent extends BaseAssetComponent  implements OnInit, OnChanges {

  constructor(
    private _element:ElementRef,
    private creativeServive:CreativeService,
    )
	{
		super();
  }

  protected uuid:string;
  creative:ICreative;
  assets:AssetVO[];

  ngOnInit(): void {

  }
  ngOnChanges(changes:SimpleChanges)
  {
    //console.log("reference changes", changes);
    
  }
  ngDoCheck()
  {
    if(this.assetVO?.asset?.uri && this.assetVO?.asset?.uri !== this.uuid)
    {
      //ensure valid uuid
      var UUIDpattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if(!UUIDpattern.test(this.assetVO?.asset?.uri)) return;
      this.uuid = this.assetVO?.asset?.uri;
      this.loadAssets();
    }
  }
  loadAssets()
  {
    this.creativeServive.getAssets(this.uuid).pipe().subscribe(res => {
      let assets = res.data;
      this.assets = assets.map(asset => new AssetVO(asset));
      this.assets.sort((a, b) => a.asset.order - b.asset.order);

      // unsaved
      //console.log("loading assests", this.assetVO.asset.uuid);
      
      if(!this.assetVO.asset.uuid)
      {
        let minX = Number.MAX_VALUE;
        let maxX = -Number.MAX_VALUE;
        let minY = Number.MAX_VALUE;
        let maxY = -Number.MAX_VALUE;
  
        this.assets.forEach(assetVO => {
          if(assetVO.asset.x < minX) minX = assetVO.asset.x;
          if(assetVO.asset.x + assetVO.asset.width > maxX) maxX = assetVO.asset.x + assetVO.asset.width;
          if(assetVO.asset.y < minY) minY = assetVO.asset.y;
          if(assetVO.asset.y + assetVO.asset.height > maxY) maxY = assetVO.asset.y + assetVO.asset.height;
        });

        this.assetVO.asset.x = minX;
        this.assetVO.asset.y = minY;
        this.assetVO.asset.width = maxX - minX;
        this.assetVO.asset.height = maxY - minY;
      }

    });
    this.creativeServive.findOne(this.uuid).pipe().subscribe((res:any) => {
        this.creative = res.data[0];
        //console.log("creative", this.creative);
        
    });
  }
}
