import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Globals } from '../global';
import { AppUserService } from '../services/app-user.service';
import { AuthService } from './auth.service';


let API_URL = Globals.BASE_API_URL;

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private appUserService:AppUserService) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean  {
		if(this.appUserService.appUser)
		{
			return true;
		}else{
			let canActivateSubject = new Subject<boolean>();
			let canActivateObservable = canActivateSubject.asObservable();

			let canActivateObservableInner = this.appUserService.getAuth();
			canActivateObservableInner.pipe().subscribe(res => {
				if(res)
				{
					//let return_path:any = route.routeConfig.path;
					//console.log(route.routeConfig.path);
					canActivateSubject.next(true);
				}else {
					let return_path:any = route.routeConfig.path;
					if(return_path == 'logout'){
						this.router.navigate(['login']);
					}else{
						//console.log("redir", route);
						let redirect = this.getResolvedUrl(route);
						let fragment = route.fragment;
						this.router.navigate(['login'], { queryParams: { redirect}, fragment});
					}
					canActivateSubject.next(false);
				}
				//this.router.navigate(['login'], { queryParams: { redirect: this.getResolvedUrl(route) }});
			});
			return canActivateObservable;
	}
		/*
		return;
		let canActivateSubject = new Subject<boolean>();
		let obs = canActivateSubject.asObservable();
		let canActivateObservableInner = this.appUserService.getAuth();
		canActivateObservableInner.pipe().subscribe(res => {
			if(res)
			{
				//let return_path:any = route.routeConfig.path;
				//console.log(route.routeConfig.path);
				canActivateSubject.next(true);
			}else {
				let return_path:any = route.routeConfig.path;
				if(return_path == 'logout'){
					this.router.navigate(['login']);
				}else{
					this.router.navigate(['login'], { queryParams: { redirect: this.getResolvedUrl(route) }});
				}
				canActivateSubject.next(false);
			}
		})		
		return obs;
		*/
		/*
		if (!this.auth.setLoggedIn(null)) {
			this.router.navigate(['login']);
			let return_path:any = route.routeConfig.path;
			//console.log("can see", return_path, route);
			if(return_path == 'logout'){
				this.router.navigate(['login']);
			}else{
				this.router.navigate(['login'], { queryParams: { redirect: this.getResolvedUrl(route) }});
			}
			return false;
		}
		this.appUserService.getAppUser();
		return true;
		*/
	}
	// https://stackoverflow.com/questions/50250361/how-to-elegantly-get-full-url-from-the-activatedroutesnapshot
	getResolvedUrl(route: ActivatedRouteSnapshot): string {
		return route.pathFromRoot
			.map(v => v.url.map(segment => segment.toString()).join('/'))
			.join('/');
	}
	
	getConfiguredUrl(route: ActivatedRouteSnapshot): string {
		return '/' + route.pathFromRoot
			.filter(v => v.routeConfig)
			.map(v => v.routeConfig!.path)
			.join('/');
	}
}