
<mat-card class="card">
	<mat-card-header class="shadow-underline">
		<!--<div mat-card-avatar class="example-header-image"></div>-->

		<mat-card-title>Projects<span *ngIf="projects" style="opacity:0.5; font-size: 0.5em; margin-left: 0.5em;">({{projects.length}})</span></mat-card-title>
		<mat-card-subtitle *ngIf="workgroup" style="margin-left: 1.5em;">Workgroup: {{workgroup.name}}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div class="list-header" *ngIf="projects?.length > 0">		
			<mat-form-field [ngStyle]="{'visibility': showSearch ? 'visible' : 'hidden'}" class="search">
				<mat-label>search</mat-label>
				<input matInput type="text" #searchInput [(ngModel)]="search">
				<button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>
			<button mat-icon-button (click)="toggleSearch();"><mat-icon>search</mat-icon></button>
			<!-- <button style="margin-left: -0.5em;" mat-icon-button (click)="flipSort($event)"><mat-icon>{{sortReverse ? 'expand_more' : 'expand_less'}}</mat-icon></button>-->
		</div>

		<div *ngIf="projects?.length > 0" class="list">

			<app-genericlist *ngIf="(appUserService.accountAdmin$ | async); else notAdmin" [items]="projects | callback: filterProject" [itemMeta]="'creativeStats'" [sort]="sort" [sortReverse]="sortReverse" [search]="search" (clickItem)="selected($event)" (copyItem)="copy($event)" (editItem)="edit($event)" (deleteItem)="delete($event)"></app-genericlist>

			<ng-template #notAdmin>
				<app-genericlist [items]="projects | callback: filterProject" [sort]="sort" [sortReverse]="sortReverse" [search]="search" (clickItem)="selected($event)"></app-genericlist>
			</ng-template>
		</div>
		<div *ngIf="projects?.length == 0" style="margin-top: 2em;">
			<span class="instruction">No projects have been added to this workgroup.</span>
		</div>
		<!-- 	
		<p *ngIf="loading">Loading projects...</p>
		<p *ngIf="!loading && projects.length == 0">No projects...</p>
		-->
	</mat-card-content>
	<mat-card-actions *ngIf="!(this.workgroupService.projectsLoading$ | async)">
		<button *ngIf="(appUserService.appUserObservable$ | async)?.super || (appUserService.accountAdmin$ | async)" (click)="addProject()" [matTooltip]="strings.getTooltip('btn_add_project')" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
		<div>
			<div style="margin: 0 1.5em;" [matMenuTriggerFor]="sortmenu">
				<span>sort by:&nbsp;</span>
				<span style="font-weight: bold;">{{getSortLabel()}}</span>			
				<span>
					<mat-icon style="margin-left: -0.1em; margin-top: 0.1em;">arrow_drop_down</mat-icon>
				</span>

				<mat-menu #sortmenu="matMenu">
					<button *ngFor="let option of sortOptions" mat-menu-item [disabled]="sort==option.sort && sortReverse==option.sortReverse" (click)="selectSort(option)">{{option.label}}</button>
				</mat-menu>
			</div>
			<div>Show archived:<mat-slide-toggle style="transform: scale(0.75);" [(ngModel)]="showArchived" color="primary"></mat-slide-toggle></div>
		</div>
	</mat-card-actions>
</mat-card>


	