import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';

declare var jquery:any;
declare var $ :any;
declare var window :any;


@Component({
	selector: 'app-image-uploader',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	droppedFiles:any = false;
	previewFiles:any = [];
	
	@Input() parent:any;
	@Input() width:any;
	@Input() height:any;

	@Output() onFilesDragged:EventEmitter<File[]> = new EventEmitter<File[]>();	

	@ViewChild('dropzone') form : ElementRef<HTMLInputElement>;

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		
		$(window).on('resize_dropzone', (e) => {
			this.width = this.parent.asset_width || this.width;
			this.height = this.parent.asset_height || this.height;
			setTimeout(() => {
				//console.log(this.width, this.height);
				$('.dropzone').css('width', parseInt(this.width)+20);
				$('.dropzone').css('height', parseInt(this.height)+20);
				$('.dropzone').css('height', parseInt(this.height)+20);
				$('.dropzone .image_scroller').css('width', parseInt(this.width)+20);
				$('.dropzone .image_scroller').css('height', parseInt(this.height)+20);
				$('.dropzone .image_scroller').css('min-width', parseInt(this.width)+20);
				$('.dropzone .image_scroller').css('min-height', parseInt(this.height)+20);
				$('.dropzone .image_scroller').css('max-width', parseInt(this.width)+20);
				$('.dropzone .image_scroller').css('max-height', parseInt(this.height)+20);
				$('.dropzone .image').css('width', parseInt(this.width));
				$('.dropzone .image').css('height', parseInt(this.height));
			}, 250);
		});
		
		$('body').off('click', '.image').on('click', '.image', (e) => {
			let $this:any = $(e.currentTarget);
			let index:any = $this.index();
			this.droppedFiles.splice(index, 1);
			this.previewFiles.splice(index, 1);
			this.parent.setAssetSaveData('upload', false);
		});
		
		$('body').off('change', '.upload').on('change', '.upload', (e) => {
			
			this.addDroppedFiles(e.target.files);
			
			$(e.target.files).each((index, element) => {
			    var reader = new FileReader();
			    reader.readAsDataURL(element);
			    reader.onload = (readEvent) => {
			        this.previewFiles.push(readEvent.target.result);
			    }
			    setTimeout(() => {
			        $('[data-src]').each((index, element) => {
				        $(element).css('background-image', 'url('+$(element).attr('data-src')+')');
				        $(element).css('background-size', 'contain');
				        $(element).css('background-position', 'center center');
				        $(element).css('background-repeat', 'no-repeat');
			        });
		        }, 250);
			});
		});
		
		$(window).trigger('resize_dropzone');
		
		$(this.form).on('drag dragstart dragend dragover dragenter dragleave drop', (e) => {
			e.preventDefault();
			e.stopPropagation();
		}).on('dragover dragenter', () => {
			//console.log("drag over/enter");
			$(this.form).addClass('is-dragover');
		}).on('dragleave dragend drop', () => {
			//console.log("drag leave/end/drop");
			$(this.form).removeClass('is-dragover');
		}).on('drop', (e) => {
			//console.log("drop");
			this.addDroppedFiles(e.originalEvent.dataTransfer.files);
			$(e.originalEvent.dataTransfer.files).each((index, element) => {
				var reader = new FileReader();
				reader.readAsDataURL(element);
				reader.onload = (readEvent) => {
					this.previewFiles.push(readEvent.target.result);
					setTimeout(() => {
						$('[data-src]').each((index, element) => {
							$(element).css('background-image', 'url('+$(element).attr('data-src')+')');
						});
					}, 250);
				}
			});
		});
		
		
	}
	
//	handleInputChange(e) {
//		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
//		this.file = file;
//		var pattern = /image-*/;
//		var reader = new FileReader();
//		this.img_type = file.type;
//		if (!file.type.match(pattern)) {
//			alert('invalid format');
//			return;
//		}
//		reader.onload = this._handleReaderLoaded.bind(this);
//		reader.readAsDataURL(file);
//	}
	
	browse(){
		$('#input_upload_file').trigger('click');
	}
	
	addDroppedFiles(files_arr:any){
		if(!this.droppedFiles) this.droppedFiles = [];
		for(let i in files_arr){
			this.droppedFiles.push(files_arr[i]);
		}
		if(this.parent && this.parent.setUploadFiles)	this.parent.setUploadFiles(this.droppedFiles);
		this.onFilesDragged.emit(this.droppedFiles);
	}
	handleDrop(event)
	{
		//console.log("drop", event);
		return;
		event.stopPropagation();
		event.preventDefault();
		switch (event.type) {
			case "drop":
				
				break;
		
			default:
				break;
		}
		console.log()
		var dt = event.dataTransfer;
		var files = dt.files;
		console.log("files dropped", files);
	}
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}

}