import { Component, Renderer2, OnInit, OnDestroy } from '@angular/core';
//import { VERSION } from './../../../environments/version';
import { version, buildDate  } from './../../../environments/version';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	public v = version; 
	public build =  new Date(buildDate).toDateString() + ' ' + new Date(buildDate).toLocaleTimeString(); 
	//version:any = VERSION;
	
	show_app_version:any = true;
	show_api_version:any = false;
	api_version:any = false;
	
	constructor(private api:ApiService) { }
	
	toggleVersionView() {
		if(!this.api_version) return false;
		this.show_app_version = !this.show_app_version;
		this.show_api_version = !this.show_api_version;
	}
	
	ngOnInit(): void {
		this.api.getstatus.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.api_version = res.api;
		});
		this.api.getStatus();
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}

}
