<div class="container" *ngIf="pusherService.appChat">
    <div class="top">
        <div class="users">
            <mat-list>
                <ng-container *ngFor="let user of pusherService.appChat.users | async">
                    <mat-list-item>
                        {{user.name}}
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
            </mat-list>

        </div>
        <div class="chat">
            <ng-container *ngFor="let message of messages">
                <div class="message">
                    <div class="message-user">
                        <span>{{message.user.name}}</span>
                    </div>
                    <div class="message-body">
                        <p>
                            {{message.message}}
                        </p>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
        </div>
    </div>
    <div class="bottom">
        <div class="input">
            <mat-divider></mat-divider>
            <mat-form-field class="full-width">
              <input matInput placeholder="Start chatting..." name="text" [(ngModel)]="message" (input)="handleInput($event)">
              <mat-icon matSuffix (click)="sendMessage()">send</mat-icon>
            </mat-form-field>
        </div>
    </div>
</div>