<div style="position: relative;"> <!--[ngStyle]="{'visibility': autoFinalise ? 'hidden' : 'unset', 'position':'relative'}"-->

	<p *ngIf="showSummary || showSteppers" class="title">Removing {{data.user.name}} from project {{data.project.name}}</p>
	
	<!-- pre-stepper summary -->
	<ng-container *ngIf="showSummary && !showSteppers">
		<div class="container">
			<div>
				<p>{{data.user.name}}
					<span *ngIf="userIsAdmin"> is an Admin</span>
					<span *ngIf="rolesArr?.length > 0">{{userIsAdmin ? ' and' :''}} has {{(rolesArr.length == 1 && workflows.length == 1) ? (numApprovalRoles > 0) ? 'an' : 'a' : ''}}
						<span *ngFor="let role of rolesArr; index as i; last as isLast">{{rolesArr.length >2 ? ', ':' '}}{{(isLast && rolesArr.length > 1) ? 'and ':''}}{{role}} </span>
						<span>role{{(workflows.length>1 || rolesArr.length > 1) ?'s ':' '}}</span>
						<span>across {{workflows.length}} workflow{{(workflows.length > 1) ? 's':''}}</span>
					</span>
					<span> in this project.</span>
				</p>
				<p>Continue to the next screens to step through removing this user from each of their assigned workflow roles.</p>
			
			</div>
			<div style="display: flex; justify-content: space-between;">
				<button mat-stroked-button color="warn" (click)="close()">Cancel</button>
				<button mat-raised-button color="primary" (click)="startSteppers()">Continue</button>
			</div>
		</div>
	</ng-container>


	<!-- remove workflow user channel steppers -->
	<ng-container *ngIf="showSteppers && !showSummary" >
		<div class="close">
			<button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
		</div>
		<!-- workflow next/prev -->
		<div class="button-group">
			<div></div>
			<div class="buttons">
				<button class="small" *ngIf="workflows.length > 1" mat-stroked-button [disabled]="!canPreviousWorkflow()" (click)="previousWorkflow()">Previous Workflow</button>
				<button class="small" *ngIf="workflows.length > 1" [color]="primary" mat-raised-button [disabled]="!canNextWorkflow()" (click)="nextWorkflow()">Next Workflow</button>
				<button class="small" *ngIf="canFinalise()" [color]="green" mat-raised-button [disabled]="!canFinalise()" (click)="finalise()">Finalise</button>		
			</div>	
		</div>
		
		<!-- wf stepper component-->
		<div *ngIf="this.workflows && this.workflows[this.index]">
			<!-- debug info
			<div>
				<div *ngFor="let workflow of this.workflows; trackBy:uuid; index as i;">
					<span>{{workflow.name}}</span>
					<span *ngIf="!workflowActions[i]">☐</span>
					<span *ngIf="workflowActions[i]">☑</span>
					<span *ngIf="i == this.index">&lt;--</span>
				</div>
			</div>
			-->
			<app-remove-workflow-user [user]="this.user" [workflow]="this.workflows[this.index]" [numWorkflows]="workflows.length" [workflowIndex]="index" [actions]="this.workflowActions[this.index]" [admins]="this.admins" [removeAdminOnly]="removeAdminOnly" (complete)="workflowActionsComplete($event)"></app-remove-workflow-user>
			
		</div>
	
	</ng-container>
	<!-- 
	<div>
		<div *ngIf="data.project['perms']">
			<p>project permissions:</p>
			<p *ngFor="let perm of data.project['perms']">{{perm.names.join(', ')}}</p>
		</div>
		<p>User {{data.user.name}} is involved with {{data.project['workflows'].length}} workflows </p>
		
		<p>workflows</p>
		<div *ngFor="let workflow of data.project['workflows']">
			<p>workflow name: {{workflow.name}}</p>
			<div *ngIf="workflow.perms">
				<p>workflow permissions:</p>
				<p *ngFor="let perm of workflow.perms">{{perm.names.join(', ')}}</p>
			</div>
			<div *ngIf="workflow.channels?.length">
				<p>channels:</p>
				<div *ngFor="let channel of workflow.channels">
					<p>channel {{channel.name}} permissions:</p>
					<p *ngFor="let perm of channel.perms">{{perm.names.join(', ')}}</p>
				</div>        
			</div>
			<div>{{project.perms}}</div>
		</div>
	</div>
	-->
</div>


