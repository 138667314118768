import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';

import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LogoutComponent } from './views/logout/logout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { ManageUsersComponent } from './views/manage-users/manage-users.component';
import { ManageProjectsComponent } from './views/manage-projects/manage-projects.component';
import { ManageAccountsComponent } from './views/manage-accounts/manage-accounts.component';
import { ManageWorkgroupsComponent } from './views/manage-workgroups/manage-workgroups.component';
import { ManageFoldersComponent } from './views/manage-folders/manage-folders.component';
import { ProjectDetailComponent } from './views/project-detail/project-detail.component';
import { ProjectListComponent } from './views/project-list/project-list.component';
import { CreativeViewComponent } from './views/creative-view/creative-view.component';
import { AssetViewComponent } from './views/asset-view/asset-view.component';
import { AmendViewComponent } from './views/amend-request-view/amend-view.component';
// prototypes
import { PrototypesComponent } from './views/prototypes/prototypes/prototypes.component';
//import { AmendsComponent } from './views/prototypes/amends/amends.component';
import { TasksComponent } from './views/prototypes/tasks/tasks.component';
import { ProjectsComponent } from './views/prototypes/projects/projects.component';
import { ProjectComponent } from './views/prototypes/project/project.component';
import { CreativeComponent } from './views/prototypes/creative/creative.component';
import { AdminViewComponent } from './views/admin-view/admin-view.component';
import { AccountComponent } from './views/account/account.component';
import { WorkgroupComponent } from './views/prototypes/workgroup/workgroup.component';
import { PagenotfoundComponent } from './views/pagenotfound/pagenotfound.component';
import { AdminUsersComponent } from './views/admin-users/admin-users.component';
import { AdminAccountsComponent } from './views/admin-accounts/admin-accounts.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { DeliveryComponent } from './views/delivery/delivery.component';
import { SignupValidateComponent } from './views/signup-validate/signup-validate.component';
import { ChatComponent } from './views/chat/chat.component';
import { TranscoderComponent } from './views/transcoder/transcoder.component';
import { AdminWebsocketsComponent } from './views/admin-websockets/admin-websockets.component';
import { AdminJobsComponent } from './views/admin-jobs/admin-jobs.component';
import { AdminStatsComponent } from './views/admin-stats/admin-stats.component';



const routes: Routes = [
	/* AUTHENTICATION */
	{ path: 'signup/activate/:token' , component: SignupValidateComponent },
	{ path: 'login' , component: LoginComponent },
	{ path: 'forgot-password' , component: ForgotPasswordComponent },
	{ path: 'reset-password/:token' , component: ResetPasswordComponent },
	{ path: 'logout' , component: LogoutComponent, canActivate: [AuthGuardService], canDeactivate:[CanDeactivateGuard] },
	
	/* PROTOTYPES */
	{ path: 'prototypes' , 			component: PrototypesComponent, canActivate: [AuthGuardService]},
	//{ path: 'prototypes/amends' , 	component: AmendsComponent, canActivate: [AuthGuardService]},
	{ path: 'prototypes/tasks' , 	component: TasksComponent, canActivate: [AuthGuardService]},
	
	{ path: 'prototypes/projects',	 	component: ProjectsComponent, canActivate: [AuthGuardService]},
	{ path: 'project/:uuid', component: ProjectComponent, canActivate: [AuthGuardService], canDeactivate:[CanDeactivateGuard]},
	{ path: 'creative/:uuid', component: CreativeComponent, canActivate: [AuthGuardService], canDeactivate:[CanDeactivateGuard] },

	/* TESTING */
	{ path: 'amend-prototype' , component: AmendViewComponent, canActivate: [AuthGuardService]},

	/* ALL USERS */
	{ path: 'dashboard' , component: DashboardComponent, canActivate: [AuthGuardService] },
	{ path: 'user/profile' , component: UserProfileComponent, canActivate: [AuthGuardService] },
	
	/* ALL USERS */
	{ path: 'workgroup/:uuid' , component: WorkgroupComponent, canActivate: [AuthGuardService] },

	{ path: 'projects' , component: ProjectListComponent, canActivate: [AuthGuardService] },
	{ path: 'project/:uuid' , component: ProjectDetailComponent, canActivate: [AuthGuardService] },
	{ path: 'creative/:uuid' , component: CreativeViewComponent, canActivate: [AuthGuardService] },
	{ path: 'asset/:uuid' , component: AssetViewComponent, canActivate: [AuthGuardService] },
	
	/* ADMIN */
	{ path: 'admin', component: AdminViewComponent, canActivate: [AuthGuardService],
		children:[
			{ path: "users", component: AdminUsersComponent}, // , outlet: "admin"
			{ path: "accounts", component: AdminAccountsComponent},
			{ path: "websockets", component: AdminWebsocketsComponent},
			{ path: "jobs", component: AdminJobsComponent},
			{ path: "stats", component: AdminStatsComponent},
		]
	},
	{ path: 'users/manage' , component: ManageUsersComponent, canActivate: [AuthGuardService] },
	{ path: 'accounts/manage' , component: ManageAccountsComponent, canActivate: [AuthGuardService] },
	{ path: 'workgroups/manage' , component: ManageWorkgroupsComponent, canActivate: [AuthGuardService] },
	{ path: 'folders/manage' , component: ManageFoldersComponent, canActivate: [AuthGuardService] },
	{ path: 'projects/manage' , component: ManageProjectsComponent, canActivate: [AuthGuardService] },
	{ path: 'user/:uuid' , component: UserProfileComponent, canActivate: [AuthGuardService] },
	{ path: 'transcoder' , component: TranscoderComponent, canActivate: [AuthGuardService] },


	{ path: 'delivery/:uuid' , component: DeliveryComponent},
	{ path: 'share/:uuid' , component: DeliveryComponent},

	{ path: 'chat' , component: ChatComponent, canActivate: [AuthGuardService]},
	

	/* GENERIC */
	//{ path: '**', redirectTo: 'workgroups'},	//'prototypes/projects' was 'dashboard'
	{ path: '', component:AccountComponent, pathMatch: 'full', canActivate: [AuthGuardService] },	//'prototypes/projects' was 'dashboard'
	{ path: '**', pathMatch: 'full', component: PagenotfoundComponent},	//'prototypes/projects' was 'dashboard'
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
