import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-creative-properties',
	templateUrl: './creative-properties.component.html',
	styleUrls: ['./creative-properties.component.scss']
})
export class CreativePropertiesComponent implements OnInit {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	asset:any = {};
	assets:any = [];
	
	asset_type_id:any;
	asset_types:any;
	
	saveDataTimer:any = false;
	validateAssetMetaTimer:any = false;
	required_fields_filled:any = false;
	
	asset_save_data:any = {};
	
	public asset_width:any = 200;
	public asset_height:any = 100;
	
	@Input() parent:any;
	
	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService) { }
	
	public configure(asset:any, assets:any){
		this.asset = asset;
		this.assets = assets;
		//console.log('configure', asset, assets);
	}
	
	setAssetSaveDataDelayed(key:any, value:any){
		clearTimeout(this.saveDataTimer);
		this.saveDataTimer = setTimeout(() => {
			this.setAssetSaveData(key, value);
		}, 100);
	}
	
	public setAssetSaveData(key:any, value:any){
		this.asset_save_data[key] = value;
		if(key == 'width') this.asset_width = value;
		if(key == 'height') this.asset_height = value;
		$(window).trigger('resize_dropzone');
		this.validateAssetMeta();
	}
	
	validateAssetMeta(){
		this.required_fields_filled = true;
		
		this.asset_save_data['width'] = $('#_asset_w').val();
		this.asset_save_data['height'] = $('#_asset_h').val();
		
		//console.log('this.asset_save_data', this.asset_save_data);
		if(typeof this.asset_save_data['name'] == 'undefined' || !this.asset_save_data['name'] || this.asset_save_data['name'] == ''){
			this.required_fields_filled = false;
		}
		if(typeof this.asset_save_data['width'] == 'undefined' || !this.asset_save_data['width'] || this.asset_save_data['width'] == ''){
			this.required_fields_filled = false;
		}
		if(typeof this.asset_save_data['height'] == 'undefined' || !this.asset_save_data['height'] || this.asset_save_data['height'] == ''){
			this.required_fields_filled = false;
		}
		if(!this.asset_type_id){
			this.required_fields_filled = false;
		}else if(this.asset_type_id === 1 || this.asset_type_id === '1'){
			if(typeof this.asset_save_data['upload'] == 'undefined' || !this.asset_save_data['upload'] || this.asset_save_data['upload'] == ''){
				this.required_fields_filled = false;
			}
		}
	}
	
	ngOnInit(): void {
		
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}

}
