import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpResponse,
	HttpClient,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiService } from './../api/api.service';
import { timer, throwError, of, Observable } from 'rxjs';
import { retryWhen, tap, mergeMap } from 'rxjs/operators';
import { AppUserService } from '../services/app-user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globals } from '../global';
import { query } from '@angular/animations';

// https://www.digitalocean.com/community/tutorials/how-to-use-angular-interceptors-to-manage-http-requests-and-error-handling

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	ukFailoverDelay = 250;
	ukFailoverLastHit = 0
	ukBlacklist = ['broadcasting', 'upload/'];	// 'login' // NB: upload"/" is important as uploadComplete - we want to go to uk (I think)
	ukMethods = ["POST", "PUT", "DELETE", "PATCH"];
	retryDelay = 500;
	retryMaxAttempts = Globals.BASE_API_URL.indexOf("localhost") != -1 ? 2 : 0;

	constructor(
		public auth: AuthService,
		public api: ApiService,
		public appUserService:AppUserService,
		public snackBar:MatSnackBar,
		public http:HttpClient) {}
	
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		let TOKEN:any = sessionStorage.getItem('tmptoken');
		
		var noAuthEndpoint:any = (
			request.url.indexOf('somethingintheurlthatmeansnoauthrequired') > -1 || 
			request.url.indexOf('oauth/token') > -1
		) ? true : false;
		
		let content_type:any = 'application/json';
		
		var isImageUpload = (
			request.url.indexOf('/upload') > -1
			) ? true : false;
		// this is new to not set json header if a form is being sent as the request body
		isImageUpload = typeof FormData !== 'undefined' && request.body instanceof FormData;
		//let filename:any = sessionStorage.getItem('upload_file') ? sessionStorage.getItem('upload_file') : false;

		if(isImageUpload){
			//content_type = 'application/octet-stream';
			//content_type = 'application/x-www-form-urlencoded;charset=utf-8';
			//content_type = 'multipart/form-data';
		}

		let region = localStorage.getItem("region");
		//console.log("region",region);
		if(region)
		{
			let headers = {'Region': region};
			let apiVersion = localStorage.getItem("api-version");
			if(apiVersion) headers['api-version'] = apiVersion;
			request = request.clone({setHeaders: headers});
		}

		// reroute posts to uk
		let rerouted = false;
		// only override if not explicitly overriden elsewhere
		if(!request.headers.has('Region-Override'))
		{
			if(this.ukMethods.includes(request.method))
			{
				// blacklist check
				let blackListed = this.ukBlacklist.find(route => request.url.indexOf(route) != -1);
				if(!blackListed)
				{
					let headers = {'Region-Override': 'uk'};
					request = request.clone({setHeaders: headers});
					rerouted = true;
				}
			}else{
				if(Date.now() - this.ukFailoverLastHit < this.ukFailoverDelay)
				{
					let headers = {'Region-Override': 'uk'};
					request = request.clone({setHeaders: headers});
					rerouted = true;
				}
			}
		}

		
		let injectTokenHeader = request.url.indexOf("user/me") != -1;
		if(TOKEN && !noAuthEndpoint && !isImageUpload){
			let headers = {'Content-Type': content_type};
			if(injectTokenHeader) headers['Authorization'] = 'Bearer '+TOKEN;
			request = request.clone({setHeaders: headers});
		}else if(TOKEN && !noAuthEndpoint && isImageUpload){
			if(injectTokenHeader)
			{
				request = request.clone({setHeaders: {'Authorization': 'Bearer '+TOKEN}});
			}
		}
		// send credentials - required for comms with a sub domain api
		request = request.clone({
            withCredentials: true
        });
		return next.handle(request).pipe( 
			this.retryAfterDelay(),
			tap((res: HttpResponse<any>) => {
				// is res am httpResponse....
				if(res && res.body)
				{
					if(request.url.endsWith("user/me"))
					{
						// store region
						localStorage.setItem("region", res.body.region);
						localStorage.setItem("api-version", res.body.api);
					}
					const db = res.body.meta?.db;
					const queries = res.body.meta?.queries;
					// check for GETs that write!
					if(db && db.total_writes)
					{
						if(request.method == "GET")
						{
							console.warn("Api route GET request wrote to the database:: " + request.url);
							let writes = queries?.filter(query => query.sql.match(/^(insert|update|delete)\b/g));
							this.http.post(Globals.BASE_API_URL + "debug/get-write", {route:request.url, writes}, {headers:{'Silent': '1'}}).subscribe();
						}
					}
					// check for poorly indexed queries
					if(queries)
					{
						const warnings = [];
						queries.forEach(query => {
							const explained = query.explained;
							const warning = {sql:query.sql, url:request.url, warnings:[]};
							explained?.forEach(explain => {
								const {rows, filtered, key, possible_keys} = explain;
								if(rows > 100){
									warning.warnings.push({'type':'rows', 'table':explain.table, rows, filtered})
								}
								// maybe also only bother if rows > some value that makes a diff like 50 or 100?
								if(key == null && rows > 25){
									warning.warnings.push({'type':'keys', 'table':explain.table, rows, filtered});
								}					
							});
							if(warning.warnings.length) warnings.push(warning);
						});
						if(warnings.length)
						{
							console.warn("query warning", warnings);
							this.http.post(Globals.BASE_API_URL + "debug/query", {route:request.url, 'queries':warnings}, {headers:{'Silent': '1'}}).subscribe();
						}
					}

				}
				// if response contains query info - parse it here wan warn if a get did any db writes
				// could do timing stuff here
				if(rerouted)	this.ukFailoverLastHit = Date.now();
			}, (err: any) => {
			//console.log('err', err);
			if (err instanceof HttpErrorResponse) {

				if (err.status === 401)	// 401 Unauthorized :: the user is not a logged in user
				{
					// if there were logged in at one point they should have an active app user
					// if so we can ask them to log in again in situe
					// otherwise we can boot them to the login page

					//this.api.gotoPage('logout');
					sessionStorage.clear();
					let redirect = err.error.redirect;
					if(redirect)
					{
						//window.location.href = err.error.redirect;
						if(redirect.indexOf('#') != -1){
							let parts = redirect.split('#');
							redirect = parts[0];
							let fragment = parts[1];
							this.api.gotoPage(redirect, null, {fragment});
						}else{

							this.api.gotoPage(redirect);
						}

						//console.log("redirecting:", err.error.redirect)
					}else{
						//window.location.reload();
						//window.location.href = "/";
						// clear app user so the app knows they have been logged out (note: .appUSer = null doesnt actually log you out)
						if(this.appUserService.appUser)
						{
							//this.appUserService.appUser = null;
							this.api.gotoPage('login');
						};
					}
					return;
				} else if (err.status === 403)	// 403 Forbidden :: the user was not allowed to do what they tried
				{ 
					let snackMethods = ['POST', 'DELETE', 'PUT', 'PATCH'];
					if(snackMethods.includes(request.method))
						this.snackBar.open(err.error.message, "", {duration:4000, panelClass:'snackBar-error'});
				} else if (err.status === 422)	// 422 Unprocessable content :: laravel validation fail
				{ 
					let snackMethods = ['POST', 'DELETE', 'PUT', 'PATCH'];
					if(snackMethods.includes(request.method))
						this.snackBar.open(err.error.message, "", {duration:4000, panelClass:'snackBar-error'});
				} else if (err.status === 500)	// 500 server internal error
				{ 
					let snackMethods = ['POST', 'DELETE', 'PUT', 'PATCH'];
					if(snackMethods.includes(request.method))
						this.snackBar.open('server error!', "", {duration:4000, panelClass:'snackBar-error'});
					let error = err.error;
					if(error)
					{
						console.warn(error.message);
						let traceRow = error.trace?.find(row => row.file && row.file.indexOf("\\app\\") != -1);
						if(traceRow)
						{
							console.warn(traceRow);						
						}
					}
				}
			}
		}));
	}
	// https://gist.github.com/ninadvadujkar/bb1f2804f9a73b02234bb3813304805f
	retryAfterDelay(): any {
		return retryWhen(errors => {
		  return errors.pipe(
			mergeMap((err, count) => {
			  // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
			  if (count === this.retryMaxAttempts || err.status < 500) {
				return throwError(err);
			  }
			  return of(err).pipe(
				tap(error => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
				mergeMap(() => timer(this.retryDelay))
			  );
			})
		  );
		});
	  }
}