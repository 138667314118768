export class FormUtils {
    
    static objectToFormData(object:Object, rootName:string = '', ignoreList:string[] = null, formdata:FormData = null):FormData
    {
        /*
        const formData = new FormData();
		Object.keys(object).forEach(key => formData.append(key, object[key]));
		return formData;
        */

        /*
		let formData = new FormData();
		Object.keys(task).forEach(key => {
			if(key.toLowerCase().indexOf('file') == -1)
			{
				formData.append(key, task[key])
			}else{
				formData.append(key, <File>task[key], task[key]['name']);
			}
		});*/
		//https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data
		
		const toFormData = (f => f(f))(h => f => f(x => h(h)(f)(x)))(f => fd => pk => d => {
			if (d instanceof Object) {
			  Object.keys(d).forEach(k => {
				const v = d[k]
				if (pk) k = `${pk}[${k}]`
				if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
				  return f(fd)(k)(v)
				} else if(v != undefined) {
				  fd.append(k, v)
				}
			  })
			}
			return fd
		  })(formdata || new FormData())()
		return toFormData(object);
		  

        var formData = new FormData();
	
		function appendFormData(data, root) {
			if (!ignore(root)) {
				if (data instanceof File) {
                    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
					formData.append(root, data);
				} else if (Array.isArray(data)) {
					for (var i = 0; i < data.length; i++) {
						appendFormData(data[i], root + '[' + i + ']');
					}
				} else if (typeof data === 'object' && data) {
					for (var key in data) {
						if (data.hasOwnProperty(key)) {
							if (root === '') {
								appendFormData(data[key], key);
							} else {
								appendFormData(data[key], root + '.' + key);
							}
						}
					}
				} else {
					if (data !== null && typeof data !== 'undefined') {
						formData.append(root, data);
					}
				}
			}
		}
	
		function ignore(root){
			return Array.isArray(ignoreList)
				&& ignoreList.some(function(x) { return x === root; });
		}
	
		appendFormData(object, rootName);
		return formData;
    }
}