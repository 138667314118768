<div #mainContent *ngIf="true || creative" class="creative-view" (resized)="onResized($event)">
    <!-- ======== NOT USED ============ -->
	<mat-sidenav-container *ngIf="false" style="height:100%;display:none;" autosize="true">
        <!-- tasks -->
        <mat-sidenav opened mode="side">
            <div *ngIf="userAction" class="actions">
                <span>{{userAction.message}}</span> <!-- todo loop if multiple actions in future-->
            </div>
            <div class>
				<ngContainer >
					<app-task class="task"
					*ngFor="let task of tasks | callback : filterTasks"
                    [(assets)]='assets'
                    [tasks]='tasks'
                    [task]='task'
                    [selectedTask]="selectedTask"
                    [creativeState]="this.creative.state"
                    [user]='this.user'
                    (onTaskSelected)="onTaskSelected($event)"
                    (onTaskAction)="onTaskAction($event)">
                </app-task>
				</ngContainer>
                
                <h2 *ngIf="!tasks" style="text-align: center;">Loading tasks...</h2>
                <h2 *ngIf="tasks?.length == 0" style="text-align: center;">You have no tasks...</h2>
            </div>
            <div style="padding: 0.75em;">
                <button mat-mini-fab color="primary" (click)="getTasks()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </mat-sidenav>
        <!-- assets -->
        <mat-sidenav opened mode="side" position="end"> 
            <!--<app-assets #assetsManager [creative_uuid]="creative_uuid" [(assetsIn)]="assets" [(selectedAsset)]="selectedAsset" [editable]="true" [markerVOs]="markerVOs$" (eventDeleteAssets)="deleteAllAssets()"></app-assets>--><!--[editable]="version == latestVersion" [(selectedAsset)]="selectedAsset"-->
            <app-assets #assetsManager [creative_uuid]="creative_uuid" [editable]="true" [markerVOs]="markerVOs$" (eventDeleteAssets)="deleteAllAssets()"></app-assets><!--[editable]="version == latestVersion" [(selectedAsset)]="selectedAsset"-->
        </mat-sidenav>
        <!-- main content -->
        <mat-sidenav-content>
            <div>
                <!-- Main content here! -->
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
	<!-- ==== END NOT USED ==== -->

    <div class="content">
		<!-- left -->
        <div class="left" *ngIf="panelOpen" #left>
            <app-grid class="grid"
				[project]="project"
				[projectChannels]="projectService.channels$ | async"
				[creatives]="creatives"
                [workflows]="projectService.workflows$ | async"
				[selectedChannel]="projectChannel"
				[formats]="formats"
                [activeCreative]="creative"
                (channelMenuChange)="onChannelMenuChange($event)"
                >
			</app-grid>
        </div>
		<!-- middle -->
        <div class="middle">
			<div class="middle-left">
				<div class="top-bar flex center split gap-25">
					<!-- LEFT -->
					<div class="flex center gap-50" style="margin-left: 0.25rem;">
						 <button mat-icon-button style="margin-right: -0.25rem;" [matTooltip]="panelOpen ? 'Close quick nav' : 'Quick nav'" (click)="togglePanel()"><mat-icon>{{panelOpen ? 'chevron_left' : 'menu_open'}}</mat-icon></button>
						 <div *ngIf="creative" style="position: relative;">
							 <div *ngIf="creative.label !='null'" class="currentLabel" [ngStyle]="{backgroundColor:'#'+creative.label}"></div>
							 <div class="state-all" [ngClass]="'state-'+creative.state" > <!-- getCreativeClass()-->
								 <mat-icon *ngIf="getIcon()" [ngClass]="{'red' : creative.state == 'warning' || creative.warning }" >{{getIcon()}}</mat-icon>
								 <span [ngClass]="{'red' : creative.state == 'warning' || creative.warning }">
									 {{creative.state == 'warning' ? 'no reviewers' : creative.state}}
								 </span>
							 </div>
						 </div>
						 <!--
						 <div class="state" *ngIf="creative">
							 <div class="tab"><button mat-icon-button [matTooltip]="panelOpen ? 'Close quick nav' : 'Quick nav'" (click)="togglePanel()"><mat-icon>{{panelOpen ? 'chevron_left' : 'menu_open'}}</mat-icon></button></div>
							 <div style="position: relative;">
								 <div *ngIf="creative.label && creative.label !='null'" class="currentLabel" [ngStyle]="{backgroundColor:'#'+creative.label}"></div>
								 <div [ngClass]="getCreativeClass()">
									 <mat-icon>{{getIcon()}}</mat-icon>
									 <span class="currentState">
										 {{creative.state}}
									 </span>
								 </div>
							 </div>
							 <mat-icon *ngIf="creative.flag" style="margin-left: 0.25em;">{{getFlag(creative.flag)}}</mat-icon>
						 </div>
						 -->
						 <div class="creative-version" *ngIf="creative">
							 <!--
							 <mat-select class="select_version" [value]="creative.version" appearance="fill" (selectionChange)="onVersionChange($event)">
								 <mat-option *ngFor="let version of getVersions()" [value]="version">
									 v{{version}}
								 </mat-option>
							 </mat-select>
							 -->
							 <div class="version-select"
							 [class.warning]="!isLatestVersion">
								 <div style="display: flex; align-items: center;" [matMenuTriggerFor]="versionMenu">
									 <span class="version" matTooltip="creative version">
										 v{{version == -1 ? creative.version : version}}
									 </span>
									 <mat-icon>arrow_drop_down</mat-icon>                        
								 </div>
								 <mat-menu [disabled]="versions.length == 0" #versionMenu="matMenu">
									 <button mat-menu-item *ngFor="let availableVersion of versions"
										 [disabled]="(this.version == -1 && availableVersion == creative.version) || availableVersion == this.version"
										 (click)="changeVersion(availableVersion)">
											 v{{availableVersion}}
									 </button>
								 </mat-menu>
							 </div>
						 </div>
						 <button mat-icon-button [matMenuTriggerFor]="menuRefresh">
							 <span matTooltip="Reload..."><mat-icon>refresh</mat-icon></span>
						 </button>
						 <mat-menu #menuRefresh="matMenu">
							 <button mat-menu-item (click)="this.reloadCreative()"><mat-icon>refresh</mat-icon>Reload creative</button>
							 <button mat-menu-item (click)="this.loadAssets()"><mat-icon>refresh</mat-icon>Reload Assets</button>
							 <button mat-menu-item (click)="this.getTasks()"><mat-icon>refresh</mat-icon>Reload Tasks</button>
						 <!-- <button mat-menu-item [disabled]="true" (click)="this.copyCreative()"><mat-icon>content_copy</mat-icon>Copy creative</button> -->
						 </mat-menu>
						 
						 <span *ngIf="!isLatestVersion" class="version-warning">
							 This is not the latest version!
						 </span>
		 
							 
		 
							 <!--
							 <ng-container *ngIf="isProduction() || isAdmin()">
								 <div class="btn-divider"></div>
								 <button style="margin-right: 0.5em;" mat-icon-button matTooltip="copy creative (not implemented)" [disabled]="true" (click)="copyCreative()"><mat-icon>content_copy</mat-icon></button>
							 </ng-container>
							 -->
						 <!-- NOT USED -->
						 <div *ngIf="false">
							 <mat-button-toggle-group
								 #group="matButtonToggleGroup"
								 [hideMultipleSelectionIndicator]="true"
								 multiple
							 >
								 <mat-button-toggle value="marker"><mat-icon>format_align_left</mat-icon></mat-button-toggle>
								 <mat-button-toggle value="drag"><mat-icon>format_align_right</mat-icon></mat-button-toggle>
							 </mat-button-toggle-group>
						 </div>
						 <div *ngIf="false" class="creative-zoom" >
							 <button mat-button  [matMenuTriggerFor]="zoommenu">zoom</button>
							 <mat-menu #zoommenu="matMenu">
								 <button mat-menu-item *ngFor="let zoom of [0.25, 0.5, 1, 2, 3]" (click)="updateZoom(zoom)">
								 <span>{{zoom}}</span>
								 </button>  
							 </mat-menu>
						 </div>
						 <div *ngIf="false" class="creative-color">
							 <mat-slide-toggle [checked]="creative.bg_transparent" (change)="onCreativeTransparentChange($event)" ></mat-slide-toggle>
							 <mat-icon>format_color_reset</mat-icon>
							 <button (click)="isOpen = !isOpen" mat-icon-button
								 cdkOverlayOrigin
								 #trigger="cdkOverlayOrigin"
							 >
								 <mat-icon>palette</mat-icon>
							 </button>
							 <span class="swatch" [ngStyle]="getSwatchStyle()"></span>
							 <!--<span class="swatch" [ngStyle]="{background:'#'+initalColor}"></span>
							 <span class="swatch" [ngStyle]="{background:'#'+creative.bg_color}"></span>
							 -->
							 <button mat-icon-button (click)="saveColorChanges()" [matTooltip]="'save creative colours'"><mat-icon>save</mat-icon></button>
							 <button mat-icon-button (click)="undoColorChanges()"  [matTooltip]="'undo creative colour changes'"><mat-icon>undo</mat-icon></button>
							 <ng-template
								 cdkConnectedOverlay
								 cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
								 [cdkConnectedOverlayHasBackdrop]="true" 
								 [cdkConnectedOverlayOrigin]="trigger"
								 [cdkConnectedOverlayOpen]="isOpen"
								 (detach)="isOpen = false" (backdropClick)="isOpen = false"
								 >
								 <div>
									 <color-chrome [disableAlpha]="true" (onChange)="onCreativeColorChange($event)" (onChangeComplete)="onCreativeColorChange($event, true)" [color]="creative.bg_color || '000000'" ></color-chrome>
								 </div>
							 </ng-template>
						 </div>
						 <!-- END NOT USED -->
					</div>
	
					<!-- RIGHT -->
					<div class="flex center" style="height: 100%; margin-right: 0.75rem;"><!-- class="btns-view"-->
						<mat-slide-toggle *ngIf="(isProduction()) && isProductionState()" [disabled]="layout.name != 'custom'" color="primary" style="transform: scale(0.75); transform-origin: 75% 50%;" [checked]="this.assetsComponentService.draggable$ | async" (change)="assetsComponentService.draggable = $event.checked" labelPosition="before">Enable dragging</mat-slide-toggle>
					
						<div class="btn-divider"></div>
						<!-- visibility toggle buttons -->
						<div class="flex center">
							<!-- <span style="font-size: 0.6rem; text-wrap: nowrap;">hold to hide:</span> -->
							<button mat-icon-button matTooltip="Hold to hide amend markup" (mousedown)="tempHideMarkup($event)" [style.opacity]="showMap.has(CreativeShow.Markup) ? 0.5: 1">
								<mat-icon>location_off</mat-icon>
							</button>
						</div>
						<div *ngIf="false">
							<button mat-icon-button matTooltip="Toggle amend markup" (click)="showMap.toggle(CreativeShow.Markup)" [style.opacity]="showMap.has(CreativeShow.Markup) ? 1: 0.5">
								<mat-icon>place</mat-icon>
							</button>
							<button mat-icon-button matTooltip="Toggle amend markup connections" (click)="showMap.toggle(CreativeShow.Connections)" [style.opacity]="showMap.has(CreativeShow.Connections) ? 1: 0.5">
								<mat-icon style="transform: rotate(90deg);">route</mat-icon>
							</button>
							<button mat-icon-button matTooltip="Toggle asset annotations"  (click)="showMap.toggle(CreativeShow.Annotations)" [style.opacity]="showMap.has(CreativeShow.Markup) ? 1: 0.5">
								<mat-icon>article</mat-icon>
							</button>
							<button mat-icon-button matTooltip="Toggle asset metadata"  (click)="showMap.toggle(CreativeShow.Metadata)" [style.opacity]="showMap.has(CreativeShow.Markup) ? 1: 0.5">
								<mat-icon>data_object</mat-icon>
							</button>
							<div class="btn-divider"></div>
						</div>
						<div class="btn-divider"></div>
						<!-- layout mode -->
						<div *ngIf="!inFullscreenMode && layout" [matTooltip]="'current view layout: '+layout?.tooltip" class="layout-new" [matMenuTriggerFor]="layoutmenu">
							<mat-icon [color]="layout?.name == creative.layout ? 'primary' : 'warn'">{{layout?.icon}}</mat-icon>
							<mat-icon>arrow_drop_down</mat-icon>
	
							<mat-menu #layoutmenu="matMenu">
								<!--<button *ngFor="let option of sortOptions" mat-menu-item [disabled]="sort==option.sort && sortReverse==option.sortReverse" (click)="selectSort(option)">{{option.label}}</button>-->
	
								<button mat-menu-item *ngFor="let lo of layouts" [disabled]="lo.name == layout?.name" (click)="updateLayout(lo); $event.stopPropagation();">
									<mat-icon>{{lo.icon}}</mat-icon><span>{{lo.tooltip}}</span><span class="layout-saved">{{lo.name == creative.layout ? '(saved)' : ''}}</span>
								</button>
								<ng-container *ngIf="isProduction()">
									<mat-divider></mat-divider>
									<div [matTooltip]="'Copy the current layout to custom'">
										<button mat-menu-item (click)="copyLayout($event)" [disabled]="!isProductionState() || layout.name == 'custom'" >
											<mat-icon>content_copy</mat-icon>
											<span>Copy to custom</span>
										</button>
									</div>
									<mat-divider></mat-divider>
									<div [matTooltip]="'Arrange assets into a grid (custom layout only)'">
										<button mat-menu-item (click)="gridify($event)" [disabled]="!isProductionState() || layout.name != 'custom'" >
											<mat-icon>grid_view</mat-icon>
											<span>Gridify assets</span>
										</button> 
									</div>
									<mat-divider></mat-divider>
									<div [matTooltip]="'Pack assets'">
										<button mat-menu-item (click)="pack($event)" [disabled]="!isProductionState() || layout.name != 'custom'" >
											<mat-icon>grid_view</mat-icon>
											<span>Pack assets</span>
										</button> 
									</div>
									<mat-divider></mat-divider>
									<!-- <button mat-menu-item (click)="copyLayout($event)" [disabled]="!showSaveBg" ><span>SAVE layout</span></button>  -->
									<div mat-menu-item>
										<button [disabled]="!isProductionState() || !showSaveBg" style="line-height: 2.7em; font-size: 0.9em; margin-left: 1.5em;" mat-flat-button color="primary" (click)="saveViewSettings()">Save layout</button>
									</div>
								</ng-container>
								
								<!--
								<div *ngIf="showSaveBg" style="display: flex; align-items: center; height: 0; margin-right: -2.5em;">
									<button matTooltip="revert to saved settings" mat-icon-button color="warn" style="margin-top: -0.3em;" (click)="undoViewChanges()"><mat-icon>reply</mat-icon></button>			
									<button style="transform: scale(0.6); margin-left: -1.5em;" mat-flat-button color="primary" (click)="saveViewSettings()">Save view settings</button>
								</div>
								-->
							</mat-menu>
						</div>
						<!-- 
						<ng-container *ngIf="isProduction() || isAdmin()">
							<div class="btn-divider"></div>
							
							<button style="margin-right: 0.5em;" mat-icon-button matTooltip="copy creative (not implemented)" [disabled]="true" (click)="copyCreative()"><mat-icon>content_copy</mat-icon></button>
						</ng-container>
						-->
						<!--
						<div *ngIf="true" class="layout">
							<div>
								<button mat-icon-button *ngFor="let lo of layouts" [ngClass]="{currentLayout:lo.current, selectedLayout:lo.active}" [matTooltip]="lo.tooltip" (click)="updateLayout(lo)">
									<mat-icon>{{lo.icon}}</mat-icon>
								</button>
							</div>
						</div>
						
						<div>
							<button mat-icon-button [disabled]="layout=='custom'" matTooltip="Copy layout to custom" (click)="copyLayout($event)"><mat-icon>content_copy</mat-icon></button>
							<button mat-icon-button (click)="saveLayoutChanges()" [matTooltip]="'save creative layout'"><mat-icon>save</mat-icon></button>
						</div>
						-->
						<div *ngIf="inFullscreenMode">
							<button mat-stroked-button class="small" style="display: flex; align-items: center;" (click)="toggleFullscreen($event)">exit fullscreen</button>
						</div>
						<div></div>
					</div>
				</div> 
				<!--  <div class="rule-h"></div> -->
				<div style="position: relative; width: 100%; height: 100%; overflow: hidden;">
					<div class="assetStage dropwrapper" #assetStage appDnd [disabled]="!isProduction()" (dragLeave)="clearFileDrop($event)"  (fileOver)="handleFileOver($event)" (fileDropped)="handleFileDrop($event)" (click)="handleStageClick($event)" (contextmenu)="onRightClick($event)"><!---->
						<!--  [selectedTask]="selectedTask"-->
						<div class="dragArea">
							<div class="drag-outline">
								<span class="drag-copy">Drop file(s) here...</span>
							</div>
						</div>
						<!-- -->
						<app-creative-viewer
							[ngClass]="{'checker':bg_transparent}"
							[class.noTouchy]="marker"
							[ngStyle]="bg_transparent ? {} : {'background': bg_color}" #creativeViewer
							[assets]="assets"
							[selectedAsset]="selectedAsset"
							[tasks]="tasks"
							[markerVOs$]="markerVOs$"
							[layout]="layout?.name"
							[zoom]="zoom"
							[editMode]="assetsComponentService.draggable$ | async"
							[(galleryIndex)]="galleryIndex"
							[transparent]="bg_transparent"
							[color]="bg_color" 
							[showMarkup]="showMap.get(CreativeShow.Markup)"
							[isProduction]="isProduction() && isProductionState()"
							[saving] = "saving"
							(scrolled)="updateMarkerConnections()"
							(markerMouseEvent)="onMarkerMouseEvent($event)"
							(selected)="onAssetSelected($event)"
							(snapping)="onSnappingUpdate($event)"
							(layoutChange)="$event ? updateMarkers() : updateMarkerConnections()"
						></app-creative-viewer>
						<div *ngIf="assets?.length == 0" class="instructions">
							<span class="instructions-copy">To get started, drop files here. Or add assets via the asset menu on the right</span>
						</div>
						<div *ngIf="false" style="display: none;" class="assets" [ngClass]="layout?.name" #assetsContainer (mousedown)="assetEditMode ? onAssetSelected(null, $event) : return;">
							<ng-container *ngFor="let asset of assets; index as i">
								<app-asset 
								[asset]="asset"
								[assets]="assets"
								[tasks]="tasks"
								[selected]="asset==selectedAsset"
								[assetEditMode]="assetEditMode"
								[index]="i"
								(markerClicked)="onMarkerClicked($event)"
								class="asset"
								[ngClass]="{'selected':selected}" (mousedown)="assetEditMode ? onAssetSelected(asset, $event) : return;"
								[ngStyle]="{'display':layout?.name =='gallery' && ((!selectedAsset && i != galleryIndex) || (selectedAsset && selectedAsset != asset)) ? 'none' : '', 'width.px':asset.width || '100%', 'height.px':asset.height || '100%', 'left.px':asset.x || 0, 'top.px':asset.y || 0}"
								appDraggable #draggable [disabled]="!assetEditMode || layout?.name != 'custom'" [target]="asset" [snapTargets]="assets" (onSnappingUpdate)="onSnappingUpdate($event)"
								></app-asset>
							</ng-container>
							<div *ngIf="false">
								<asset class="asset" *ngFor="let asset of assets; index as i" [ngClass]="{'selected':asset == selectedAsset, 'markerOver':asset.markerOver}" (mousedown)="assetEditMode && onAssetSelected(asset, $event)"
								[ngStyle]="{'display':layout?.name =='gallery' && ((!selectedAsset && i != galleryIndex) || (selectedAsset && selectedAsset != asset)) ? 'none' : 'none', 'width.px':asset.width || '100%', 'height.px':asset.height || '100%', 'left.px':asset.x || 0, 'top.px':asset.y || 0}"
								appDraggable #draggable [disabled]="!assetEditMode || layout?.name != 'custom'" [target]="asset" [snapTargets]="assets" (onSnappingUpdate)="onSnappingUpdate($event)">
									<!-- [disabled]="!assetEditMode" -->
									<!--    <h3>{{asset.name}}</h3> -->
									<ng-container *ngIf="asset.visible">
										<ng-container [ngSwitch]="asset.type">
											<app-image *ngSwitchCase="'image'" [asset]="asset"></app-image>
											<app-audio *ngSwitchCase="'audio'" [asset]="asset"></app-audio>
											<app-video *ngSwitchCase="'video'" [asset]="asset"></app-video>
											<app-pdf *ngSwitchCase="'pdf'" [asset]="asset"></app-pdf>
											<app-iframe *ngSwitchCase="'iframe'" [asset]="asset"></app-iframe>
											<app-html-banner *ngSwitchCase="'banner_html'" [asset]="asset"></app-html-banner>
											<app-html-link *ngSwitchCase="'link'" [asset]="asset"></app-html-link>
											<app-file *ngSwitchCase="'file'" [asset]="asset"></app-file>
											<app-egnyte *ngSwitchCase="'egnyte'" [asset]="asset"></app-egnyte>
											<ng-container *ngSwitchDefault>
												<app-empty [asset]="asset"></app-empty>
											</ng-container>
											<!--<ng-container *ngSwitchDefault><h2>No renderer found for asset type {{asset.type}}</h2></ng-container>-->
										</ng-container>
										<div class="assetClickBlock" *ngIf="assetEditMode">
											<!--<mat-icon>ads_click</mat-icon>-->
										</div>
										<div *ngIf="hasMarker(asset, creative)" style="position:absolute;top:0;left:0;">
											<!--<ng-container [ngSwitch]="getMarker().type"></ng-container>-->
											
											<!-- track by needed or the marker was getting removed and dragging failed :( )-->
											<div *ngFor="let marker of getMarkers(asset); trackBy: markerTrackBy" [ngClass]="{active:marker.task==selectedTask}" class="drag-target"
											(click)="onMarkerClick($event, marker.task)"
											cdkDrag [cdkDragDisabled]="marker.task.state != 'local'" (cdkDragStarted)="onMarkerDragStarted($event)" (cdkDragMoved)="onMarkerDragMoved($event)" (cdkDragEnded)="onMarkerDragged($event, marker.marker, asset)"
											[style.transform]="'translate(' + marker.marker.x0 +'px, '+marker.marker.y0 +'px)'" style="transform: translate(-12px, -12px); cursor: pointer;">
												<mat-icon [matTooltipDisabled]="marker.task.state == 'local'" [matTooltip]="marker.task.content" >location_searching</mat-icon>
											</div>
										</div>
										<div *ngIf="assetEditMode" [ngClass]="asset == selectedAsset ? 'highlight2' : 'highlight'"></div>
										<ng-container *ngIf="asset.type=='image'">
											<ng-template></ng-template>
												<!--
											<div class="image"
												[ngStyle]="{'backgroundImage':'url(\'' + (asset.preview || ('/api/file/'+asset.uri)) + '\')'}">
												{{asset.name}}
											</div>
											-->
											<!--
											<div [ngStyle]="{'pointerEvents':'none','backgroundImage':'url(\'' + asset.preview + '\')', 'width':'100%', 'height':'100%', 'backgroundSize':'contain', 'backgroundRepeat':'no-repeat', 'backgroundPosition':'50% 50%'}">
												hiya
											</div>
										-->
										
											<!--<img [src]="asset.preview | safe:'url'" />-->
										</ng-container>
									</ng-container>                
								</asset>
							</div>
					
						</div>
						<div style="display: none;"  class="assets-overlay">
							<!-- overlays -->
							
							<!--<div class="highlight" [ngStyle]="getHighlightStyle()"></div>-->
							<div class="snapX" *ngIf="snapping && snapping['snapping'] && snapping['snappingX'] !== false" [ngStyle]="{'left.px':snapping['snappingX']}"></div>
							<div class="snapY" *ngIf="snapping && snapping['snapping'] && snapping['snappingY'] !== false" [ngStyle]="{'top.px':snapping['snappingY']}" ></div>
							<div *ngIf="false" #markerContainer class="marker" style="position: absolute; top:0; left:0; z-index: 999;">
								<div *ngIf="marker" [style.transform]="'translate('+marker.x0+'px,'+marker.y0+'px)'" style="cursor: pointer;"> <!--transform: translate(-12px, -12px); -->
									<mat-icon>location_searching</mat-icon>
								</div>
							</div>
						</div>
					</div>
					<!-- SUMMARY + BUTTONS -->
					<div #actionBar class="action-bar">
						<div class="action-bar-handle"
							appDraggable
							[disabled]="false"
							(onMouseDown)="onActionBarMouseDown($event)"
							(dragUpdate)="onActionBarDragUpdate($event)"
							[target]="actionBarPos">
							<mat-icon>drag_indicator</mat-icon>
						</div>
						<div class="action-bar-content">
							<div class="mainTasks"> <!-- NON-AMEND TASKS -->
								<ng-container *ngFor="let task of tasks | callback : filterTasks2; trackBy:trackByTaskUUID">
									<app-task
										class="button-task"
										[(assets)]='assets'
										[(tasks)]='tasks'
										[task]='task'
										[selectedTask]="selectedTask"
										[user]='this.user'
										[creativeState]="this.creative.state"
										[buttonsDisabled]="loadingCreative || tasksDisabled || assetsSaving || saving || loadingTasks || activeUploads?.length || getDirtyAssetCount() || getProcessingAssets()" 
										(mouseenter)="taskOver(task)"
										(mouseleave)="taskOut(task)"
										(onTaskSelected)="onTaskSelected($event)"
										(onTaskAction)="onTaskAction($event)"
										(onTaskDisable)="onTaskDisable($event)"> <!-- [buttonsDisabled]="tasksDisabled || getDirtyAssetCount() || saving" -->
									</app-task>
								</ng-container>
								<!--<h2>TD:{{isTasksDisabled()}}</h2>-->
							</div>
							<!--
							<div *ngIf="taskButtons" class="buttons-summary" [ngClass]="{'grey-out':tasksDisabled}">
								<ng-container *ngFor="let button of taskButtons" [ngSwitch]="button.type">
									<button *ngSwitchCase="'flat'" class="button-task" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="getDirtyAssetCount() || buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message, button.task)" mat-flat-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'stroked'" class="button-task" style="background-color: white;" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="getDirtyAssetCount() || buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message, button.task)" mat-stroked-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'basic'" class="button-task" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="getDirtyAssetCount() || buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message, button.task)" mat-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'icon'" mat-mini-fab  #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="getDirtyAssetCount() || buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message, button.task)" [color]="button.colour"><mat-icon class="icon-small" >{{button.icon}}</mat-icon></button>
								</ng-container> 
							</div>
							-->
							<div *ngIf="false">
								<!-- debugging -->
								<span *ngIf="loadingCreative">loadingCreative</span>
								<span *ngIf="assetsSaving">assetsSaving</span>
								<span *ngIf="saving">saving</span>
								<span *ngIf="activeUploads?.length">activeUploads.length</span>
								<span *ngIf="getProcessingAssets()">getProcessingAssets()</span>
							</div>
							<!--<mat-progress-bar [ngStyle]="{'visibility':saving ? 'visible' : 'hidden'}" mode="indeterminate"></mat-progress-bar>-->
							<!--<mat-progress-bar style="display: flex;flex-grow: 1;width: unset;" *ngIf="loadingCreative || assetsSaving || saving || loadingTasks || activeUploads?.length || getProcessingAssets()" mode="indeterminate"></mat-progress-bar>-->
							<mat-progress-bar style="display: flex; width: unset;" [class.hidden]="!(loadingCreative || assetsSaving || saving || loadingTasks || activeUploads?.length || getProcessingAssets())" mode="indeterminate"></mat-progress-bar>
							<div class="taskSummary">
								<ng-container *ngIf="!(loadingCreative || saving|| loadingTasks || assetsSaving || activeUploads?.length); else savingMsg">
									<div *ngIf="getDirtyAssetCount() == 0; else unSavedAssetsMsg">
										<div *ngIf="taskSummaryVO">
											<div *ngFor="let summaryLine of taskSummaryVO.mySummary" [ngClass]="{'linebreak': summaryLine == ''}" >{{summaryLine}}</div>
										</div>
									</div>
								</ng-container>
								<ng-template #unSavedAssetsMsg>
									<div class="red">Save changes to {{getDirtyAssetCount()}} assets to proceed.</div>
								</ng-template>
								<ng-template #savingMsg>
									<div style="color: grey">
										<span *ngIf="loadingCreative">Creative Loading...</span>
										<span *ngIf="loadingTasks">Tasks Loading...</span>
										<span *ngIf="assetsSaving">Assets Saving...</span>
										<span *ngIf="activeUploads?.length">Assets uploading...</span>
										<span *ngIf="saving">Saving...</span>
									</div>
								</ng-template>
							</div>
						</div>
					</div>
					<!-- END SUMMARY + BUTTONS -->
				</div>
				<!-- right click menu -->     
				<div #triggerRightMenu style="visibility: hidden; position: absolute;"
					[style.left.px]="menuTopLeftPosition.x" 
					[style.top.px]="menuTopLeftPosition.y" 
					[matMenuTriggerFor]="rightMenu"
					(menuOpened)="onRightClickMenuOpened($event)"
					(menuClosed)="onRightClickMenuClosed($event)"
					>
				</div>
				<mat-menu #rightMenu="matMenu" [hasBackdrop]="true"> 
					<ng-template matMenuContent let-items="items" > 
						<ng-container *ngFor="let item of items;">
							<ng-container *ngIf="item">
								<button [disabled]="item.disabled" (click)="onRightClickMenu(item)" mat-menu-item>
									<mat-icon *ngIf="item.icon" [style.transform]="item.icon == 'play_for_work' ? 'rotate(90deg)': ''">{{item.icon}}</mat-icon>
									<span>{{item.label}}</span>
								</button>
							</ng-container>
							<ng-container *ngIf="!item">
								<mat-divider></mat-divider>
							</ng-container>
						</ng-container>
					</ng-template> 
				</mat-menu>
			</div>
			<div class="middle-right">
		<div class="side-panel">
			<div class="content" *ngIf="this.creativePanels.size">
				<div class="dragbar" [class.dragging]="dragging" (mousedown)="dragbardown($event)"></div>
				<div class="panels" orderable>
					<div *ngIf="creativePanels.has(CreativeMenu.Amends)" class="panel">
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>where_to_vote</mat-icon><span>Amends</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Amends)"><mat-icon>close</mat-icon></button>
						</div>
						<div class="panel-content taskContents">
							<!--top-->
							<!-- tasks-->
							<div class="tasks" #taskContainer>
								<div>
									<div class="instruction" *ngIf="tasks && numAmendTasks == 0" style="text-align: center;">There are no saved amends.</div>
									<!--<ngContainer *ngFor="let task of tasks | callback : filterTasks;trackBy:trackByTaskUUID">-->
									<ngContainer *ngFor="let task of tasks | callback : filterTasks; trackBy:trackByTaskUUID">
										<app-task *ngIf="task.type == 'amend'"
											class="task"
											[(assets)]='assets'
											[(tasks)]='tasks'
											[task]='task'
											[selectedTask]="selectedTask"
											[markerVOs$]="markerVOs$"
											[user]='this.user'
											[creativeState]="this.creative.state"
											[buttonsDisabled]="tasksDisabled"
											(mouseenter)="taskOver(task)"
											(mouseleave)="taskOut(task)"
											(onTaskSelected)="onTaskSelected($event)"
											(onTaskAction)="onTaskAction($event)"
											(onTaskDisable)="onTaskDisable($event)">
										</app-task>
									</ngContainer>                                   
									<div class="instruction" *ngIf="!tasks" style="text-align: center;">Retrieving amend info...</div>
									
								</div>
							</div>
							<!-- bottom -->
							<div class="tasksBottomPanel" *ngIf="taskEvents.length">
								<div *ngIf="false" style="display: flex; position: relative; bottom:0">
									<div>
										<mat-slide-toggle [(ngModel)]="showMarkup" matTooltip="not implemented yet">markup</mat-slide-toggle>   <!-- (change)="editModeChange($event)"-->
									</div>
									<div>
										<mat-slide-toggle [(ngModel)]="showOtherTasks" matTooltip="show other users tasks">others</mat-slide-toggle>   <!-- (change)="editModeChange($event)"-->
									</div>
									<div>
										<mat-slide-toggle [(ngModel)]="showClosed" matTooltip="show closed tasks">closed</mat-slide-toggle> 
									</div>
								</div>
								<div class="taskEvents">
									<div class="taskEventList">
										<ul>
											<li *ngFor="let event of taskEvents; index as i; count as count">
												<span>{{getTaskEventString(taskEvents[count-1-i])}}</span>    
											</li>
										</ul>
									</div>                                    
								</div>
							</div>                   
						</div>
					</div>
					<div *ngIf="creativePanels.has(CreativeMenu.Assets)" class="panel assetsPanel">
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>category</mat-icon><span>Assets</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Assets)"><mat-icon>close</mat-icon></button>
						</div>
						<app-assets #assetsManager class="panel-content"
							[creative_uuid]="creative_uuid"
							[production]="isProduction()"
							[admin]="isAdmin()"
							[state]="creative.state"
							[editable]="areAssetsEditable()"
							[layout]="layout?.name"
							(selectAsset)="selectAsset($event)"
							(assetsSavedOrDeleted)="assetsSavedOrDeleted($event)"
							(onEditModeChange)="onAssetEditModeChange($event)"
							(eventDeleteAssets)="deleteAssets($event)"
						></app-assets><!--[(selectedAsset)]="selectedAsset"-->
					</div>
					<div *ngIf="creativePanels.has(CreativeMenu.Discussion)" class="panel">  
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>forum</mat-icon><span>Discussion</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Discussion)"><mat-icon>close</mat-icon></button>
						</div>
						<div class="panel-content"> <!--component-scroll-self-->
							<app-discussion [project_uuid]="creative.project?.uuid" [messages]="creativeService.discussion$" (typing)="handleTyping($event)" (submitMessage)="handleSubmitMessage($event)" (loadMore)="loadMore($event)" (clearUnreadFlag)="unreadDiscussions = false"></app-discussion>
						</div>
					</div>
					<div *ngIf="creativePanels.has(CreativeMenu.Settings)" class="panel">
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>settings</mat-icon><span>Settings</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Settings)"><mat-icon>close</mat-icon></button>
						</div>
						<div class="panel-content">
							<div class="settings">
								<div style="display: flex;">
									<!-- background -->
									<div style="display: flex; align-items: center;">
										<!-- swatches -->
										<div matTooltip="background colour" class="backgroundToggle" [ngStyle]="{'background-color':creative?.bg_color}" ><!--(click)="bgColour = creative.bg_color"-->
											<!--<div class="bgCol-icon">
												<mat-icon *ngIf="bgColour == creative.bg_color">done</mat-icon>
												<mat-icon *ngIf="bgColour != creative.bg_color">reply</mat-icon>
											</div>-->
											<div class="swatch transp" (click)="bg_transparent = !bg_transparent; showSaveBgCol = canShowSaveBgCol()"><!--TODO: could use (ngModelChange) to detect bg_tarnsparent changes from colourPicker-->
												<div>
													<div style="width: 6px; height: 6px; background-color: rgb(211, 211, 211); position: absolute; top: 0px; left: 0px;"></div>
													<div style="width: 6px; height: 6px; background-color: rgb(211, 211, 211); position: absolute; top: 6px; left: 6px;"></div>
												</div>
												<mat-icon class="transTile-on" style="z-index: 9999;" *ngIf="bg_transparent">done</mat-icon>
											</div>
											<div *ngIf="!bg_transparent" class="swatch" [ngStyle]="{'background-color': bg_color}">
												<input class="hiddenInput" type="color" [(ngModel)]="bg_color" (change)="bg_transparent = false; showSaveBgCol = canShowSaveBgCol()" (click)="clickSwatch($event); "> <!--(change)="bg_transparent = false; showSaveBg = canShowSaveBg()"  showSaveBg = canShowSaveBg()-->
											</div>
										</div>
									</div>
									<!-- save/revert -->
									<ng-container *ngIf="isProduction() || isAdmin()">
										<div *ngIf="showSaveBgCol" style="display: flex; align-items: center;">
											<button matTooltip="revert to saved background" mat-icon-button color="warn" style="margin-top: -0.3em;" (click)="undoBgColChanges()"><mat-icon>reply</mat-icon></button>			
											<button matTooltip="save background" mat-icon-button color="primary" (click)="saveBgColSettings()"><mat-icon>save</mat-icon></button>
										</div>
									</ng-container>
								</div>
								<mat-slide-toggle color="primary" [checked]="creative.viewonly" (change)="toggleViewOnly()" >
									<span style="vertical-align: middle;" [ngClass]="creative.viewonly ? 'primary':'off'">View only</span>
								</mat-slide-toggle>	
								<button mat-flat-button color="accent" matTooltip="Copy creative" (click)="this.copyCreative()"><mat-icon>content_copy</mat-icon>Copy Creative</button>
								<button mat-flat-button color="warn" matTooltip="Delete this creative" (click)="this.deleteCreative()"><mat-icon>delete</mat-icon>Delete Creative</button>
							</div>
						</div>
					</div>
					<div *ngIf="creativePanels.has(CreativeMenu.Info)" class="panel" >
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>info_outline</mat-icon><span>Info</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Info)"><mat-icon>close</mat-icon></button>
						</div>
						<div class="panel-content">
							<app-info
								[workflow]="workflow" 
								[creative]="creative" 
								[tasks]='tasks' 
								[delivery]="creative.delivery_packages"
								[productionTask]="productionTask"
								(onResetApprovals)= "resetInfo()"
							></app-info>
							
						</div>
					</div>
					<div *ngIf="creativePanels.has(CreativeMenu.Log)" class="panel">
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>list_alt</mat-icon><span>Log</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Log)"><mat-icon>close</mat-icon></button>
						</div>
						<div class="panel-content">
							<div class="creative-log-loading" *ngIf="(creativeService.logLoading$ | async)">
								<mat-spinner  diameter="50"></mat-spinner>
							</div>
							<div class="creative-log" *ngIf="creativeService.log$ | async">
								<ul>
									<li *ngFor="let log of (creativeService.log$ | async) ">
										<div class="log-item">
											<div class="log-topline">
												<span class="log-message">{{log.message}}</span>
												<span class="log-version">v{{log.version}}</span>
											</div>
											<div class="log-byline">
												<span class="log-user">{{log.user.name}}</span>
												<span class="log-time">{{log.time}}</span>    
											</div>
										</div>
									</li>
								</ul>
							<!--
								<ul>
									<li *ngFor="let log of creativeLog; index as i; count as count">
										<ng-container *ngIf="formatLog(creativeLog[count-1-i]) as row">
											<span>{{row.message}}</span>
											<span class="user">{{row.user}}</span>
											<span *ngIf="row.version != null">(v{{row.version}})</span>
											<span *ngIf="row.time">({{row.time}})</span>
										</ng-container>                                        
									</li>
								</ul>
							-->
							</div>
						</div>
					</div>
					<div *ngIf="creativePanels.has(CreativeMenu.Help)" class="panel" >
						<div class="panel-title flex center split">
							<div class="flex center gap-25"><mat-icon>question_mark</mat-icon><span>Help</span></div>
							<button class="close" mat-icon-button (click)="toggleCreativePanel(CreativeMenu.Help)"><mat-icon>close</mat-icon></button>
						</div>
						<div class="panel-content"></div>
					</div>
				</div>
				<div class="content-overlay">
					<span>Close panel</span>
				</div>
			</div>
		</div>
			</div>
			<div class="middle-overlay">
				<!-- markers -->
				<div #markerContainer class="marker" style="position: absolute; top:0; left:0; z-index: 999;" [ngClass]="markerTask?.colour">
					<div *ngIf="marker" [style.transform]="'translate('+(marker.x0 + (marker.offsetX || 0))+'px,'+(marker.y0 + (marker.offsetY || 0))+'px)'" style="cursor: pointer;"> <!--transform: translate(-12px, -12px); -->
						<mat-icon>place</mat-icon>
						<div *ngIf="false && marker.debug" style="color:white; text-shadow: 0 0 2px red; max-width: 300px;">{{marker.debug | json}}</div>
						<span *ngIf="false && marker.metadata?.page">p{{marker.metadata?.page + 1}}</span>
					</div>
				</div>

				<!-- marker lines -->
				<div class="marker-lines" #markerLines resizeObserver (resize)="onMarkerlinesResize($event)">
					<ng-container *ngIf="showMap.has(CreativeShow.Connections)">
						<!-- get all markers and render lines from the marker to the task that matches -->
						<div *ngFor="let connection of connections; index as index; trackBy:trackByMarkerConnection">
							<svg>
								<linearGradient [id]="'Gradient' + index" gradientUnits="userSpaceOnUse" [attr.x1]="connection.x" [attr.y1]="connection.y" [attr.x2]="connection.tx" [attr.y2]="connection.ty">
									<stop class="stop" [ngClass]="connection.colour" [attr.stop-opacity]="connection.alpha" offset="0%" />
									<!--<stop stop-color="yellow" offset="50%" />-->
									<stop class="stop" [ngClass]="connection.colour" [attr.stop-opacity]="connection.alphaEnd" offset="100%" />
								</linearGradient>
								<path class="line" [class.anim]="connection.anim" [ngStyle]="{'stroke-dasharray':connection.length, 'stroke-dashoffset':connection.length, 'opacity':(connection.hover ? 0.66 : 1)}" [attr.stroke]="'url(#Gradient'+index+')'" [attr.d]="connection.d" />
								<path class="connector" *ngIf="connection.dc" [ngStyle]="{'opacity':(connection.hover ? 0.66 : 1)}" [attr.fill]="'url(#Gradient'+index+')'" [attr.d]="connection.dc" />
								<!--<rect [attr.x]="connection.tx-4" [attr.y]="connection.ty-4" [attr.width]="4" [attr.height]="8" ></rect>-->
								<!--<line [attr.x1]="connection.x" [attr.y1]="connection.y" [attr.x2]="connection.tx" [attr.y2]="connection.ty"
									style="stroke: rgb(255, 0, 0); stroke-width: 2;" />-->
							</svg>
						</div>
					</ng-container>                    
				</div>
			</div>

        </div>
		<!-- right -->
        <div class="right">
			<div class="side-panel-menu">
				<div class="menu-item" [class.active]="creativePanels.has(CreativeMenu.Amends)">
					<button mat-icon-button [matTooltip]="actionableAmendTasks?.messages?.length ? actionableAmendTasks.messages.join('\n') :  'Amends'" (click)="toggleCreativePanel(CreativeMenu.Amends)"><mat-icon>where_to_vote</mat-icon></button>
					<span *ngIf="tasks && actionableAmendTasks.total" class="badge"
						[matBadge]="actionableAmendTasks.totalUnactioned ? actionableAmendTasks.totalUnactioned : '&#8288;'" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></span>
				</div>
				<div class="menu-item" [class.active]="creativePanels.has(CreativeMenu.Assets)">
					<button mat-icon-button [matTooltip]="'Assets'" (click)="toggleCreativePanel(CreativeMenu.Assets)"><mat-icon>category</mat-icon></button><!-- desktop_landscape_add -->
					<span *ngIf="getDirtyAssetCount()>0" class="badge"
						[matBadge]="getDirtyAssetCount()" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></span>
				</div>
				<div class="menu-item" [class.active]="creativePanels.has(CreativeMenu.Info)">
					<button mat-icon-button [matTooltip]="'Creative info'" (click)="toggleCreativePanel(CreativeMenu.Info)"><mat-icon>info_outline</mat-icon></button>
				</div>
				<div class="menu-item" [class.active]="creativePanels.has(CreativeMenu.Discussion)">
					<button mat-icon-button [matTooltip]="'Discussion'" (click)="toggleCreativePanel(CreativeMenu.Discussion)"><mat-icon>forum</mat-icon></button><!-- chat-->
					<spam *ngIf="unreadDiscussions" class="badge" matBadge="&#8288;" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></spam>
				</div>
				<div class="menu-item" [class.active]="creativePanels.has(CreativeMenu.Log)">
					<button mat-icon-button [matTooltip]="'Creative log'" (click)="toggleCreativePanel(CreativeMenu.Log)"><mat-icon>list_alt</mat-icon></button>
				</div>
				<div class="menu-item" *ngIf="isProduction() || isAdmin()" [class.active]="creativePanels.has(CreativeMenu.Settings)">
					<button mat-icon-button [matTooltip]="'Creative settings'" (click)="toggleCreativePanel(CreativeMenu.Settings)"><mat-icon>settings</mat-icon></button>
				</div>
				<div class="menu-item" [class.active]="creativePanels.has(CreativeMenu.Help)">
					<button mat-icon-button [matTooltip]="'Help'" (click)="toggleCreativePanel(CreativeMenu.Help)"><mat-icon>question_mark</mat-icon></button><!-- help--->
				</div>
			</div>
		</div>

		<!-------   NOT USED  ------------>
		<div *ngIf="false" class="amends">
			<div style="overflow:hidden">   <!-- required to make mat tab group behave -->
				<mat-tab-group *ngIf="false" #tabGroup animationDuration="0ms" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onSubTabChange($event)"> 
				

					<!--  === TAB: AMENDS === -->
					<mat-tab class="mattab" label="amends">
						<ng-template mat-tab-label>
							<div>
								<span *ngIf="taskSummaryVO?.myCount>0" style="position: absolute; right: 18px;"
								[matBadge]="taskSummaryVO.myCount" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></span>
								<span>amends <span class="list-counter" *ngIf="tasks && numAmendTasks">({{numAmendTasks}})</span></span>
							</div>  
						</ng-template>
						<!--
						<ng-template mat-tab-label>
						<span>tasks</span>
						<mat-icon (click)="getTasks()">refresh</mat-icon>
						</ng-template>
						-->
						<!--
						<div class="taskSearchFilter">
							<mat-form-field>
								<mat-label>Search/Filter</mat-label>
								<input matInput type="text" [(ngModel)]="taskSearch">
								<button mat-button *ngIf="taskSearch" matSuffix mat-icon-button aria-label="Clear" (click)="taskSearch=''">
								<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>  
							<mat-slide-toggle>Hide closed</mat-slide-toggle>
						</div> 
						-->

					</mat-tab>

					<!--  === TAB: ASSETS === -->
					<mat-tab label="assets">
						<ng-template mat-tab-label>
							<div>
								<span *ngIf="getDirtyAssetCount()>0" style="position: absolute; right: 18px;"
								[matBadge]="getDirtyAssetCount()" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></span>
								<span>assets <span class="list-counter" *ngIf="assets && assets.length">({{assets.length}})</span></span>
							</div>  
						</ng-template>
						<!-- old content -->
					</mat-tab>

					<!--  === TAB: INFO === -->
					<mat-tab label="info">

	
						<!--<mat-card class="creative">
							<mat-card-content>
								-->
									<!-- 
									<div *ngIf="creative">
										<p>name: {{creative.name}}</p>
										<p>version: {{creative.version}}</p>        
										<p>date: {{creative.created_at}}</p>                    
										<p>state: {{creative.state}}</p>
									</div> 
									<mat-divider></mat-divider>
									-->
									
									
								


									<!-- options -->
									<!-- 
									<fieldset>
										<legend>Options:</legend>
										<div class="optionRow">
											<app-colour-label
												[selectedColour]="creative.label || null"
												(colourSelected)="setLabel($event)">
											</app-colour-label>
										</div>
										<mat-divider></mat-divider>
										<div class="optionRow">
											<app-pause-creative-widget
												[isPaused]="creative.paused || false"
												(setPaused)="pauseCreative($event)">
											</app-pause-creative-widget>
										</div>
									</fieldset>
									-->
									<!--
									<fieldset>
									<legend>Currently viewing:</legend>
										<div style="padding-bottom: 0.5em;">
											<span *ngFor="let activeUser of pusherService.creativeChat.users | async" class="activeUser">{{activeUser.initials}}</span>
										</div>
									</fieldset>
									-->
									<!--
									<mat-divider></mat-divider> 
									<div *ngIf="userActions && userActions.length">
										<p>TODOs:</p>
										<div style="display: table; width: 100%;">                        
											<div *ngFor="let userAction of userActions" style="display: table-row;">
												<div style="display: table-cell;">
													{{userAction.user ? userAction.user.name : ''}}
												</div>
												<div style="display: table-cell;">
													{{userAction.message}} 
												</div>
												<div style="display: table-cell;">
													{{["&#9746;","&#9744;","&#9745;"][userAction.action+1]}}
												</div>
											</div>
										</div>
									</div>
									<mat-divider></mat-divider> 
									-->
									<!--
									<div *ngIf="creative">
										<p>Creative actions:</p>
										<div style="display: table; width: 100%;">                        
											<div *ngFor="let action of creative.actions" style="display: table-row;">
												<div style="display: table-cell;">
													{{formatUser(action.user)}}
												</div>
												<div style="display: table-cell;">
													{{formatDate(action.created_at)}} 
												</div>
												<div style="display: table-cell;">
													{{action.action}} 
												</div>
											</div>
										</div>
									</div> 
									-->                                                   
							
							
								<!-- label -->
								<!-- <div>
									<app-colour-label
										[selectedColour]="creative.label"
										(colourSelected)="setLabel($event)"
									></app-colour-label>
								<app-flag [selectedFlag]="creative.flag" (flagSelected)="flagSelected($event)" ></app-flag> 
									
									<h3>Label:</h3>                            
									<div style="display: flex;">
										<div><button (click)="setLabel(null)" class="colour-label" mat-icon-button><mat-icon>block</mat-icon></button></div>
										<div *ngFor="let label of labels" [style]="{color:'#'+label}" [ngClass]="{'selected-label':creative.label==label}"><button (click)="setLabel(label)" class="colour-label" mat-icon-button><mat-icon >circle</mat-icon></button></div>
									</div>
										
								</div> -->
								
								<!-- public -->
								<!-- <div>
									<mat-slide-toggle [disabled]="savingPublic" (change)="togglePublic($event)" [(ngModel)]="creative.public">Public: {{creative.public ? 'true' : 'false'}}</mat-slide-toggle>
								</div> -->
								<!-- production -->
								<!-- <div style="padding: 0 1em" *ngIf="production">
									<span>Production:</span>
									<button mat-button [matMenuTriggerFor]="menu">{{production.current ? production.current.name : "app"}}</button>
									<mat-menu #menu="matMenu">
										<button *ngFor="let productionUser of production.available" (click)="setProduction(productionUser)" [disabled]="productionUser.uuid == production.current?.uuid" mat-menu-item>{{productionUser.name}}</button>
									</mat-menu>
								</div> -->
							<!--</mat-card-content> -->
						<!-- <mat-card-actions> -->
							<!-- </mat-card-actions>
						</mat-card> -->
					</mat-tab>

					<!--  === TAB: LOG === --> <!-- creative log -->
					<mat-tab label="log">
						
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
		<!-------  END NOT USED  --------->
    </div>
</div>   
    
    <!--<button mat-raised-button (click)="getAmends()">get amends</button>-->
    
    <!-- creative actions 
    <div id="bottom">
        <mat-card *ngIf="true || isStageActionsVisible()">
            <mat-card-header>
                <mat-card-title >Creative main actions</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-card-actions *ngIf="this.state === CreativeState.Production">
                    <button *ngIf="isCompleteCreativeVisible()" mat-raised-button color="primary" [disabled]="!canCompleteCreative()" (click)="this.completeCreative()">Build Complete</button>
                </mat-card-actions>
                <mat-card-actions *ngIf="this.state === CreativeState.Approval">
                    <button mat-raised-button color="primary" [disabled]="!canApproveCreative()" (click)="this.approveCreative()">Approve Creative</button>
                    <button mat-raised-button color="primary" [disabled]="!canSubmitAmendRequests()" (click)="this.submitAmendRequests()">Submit amend request(s)</button>
                </mat-card-actions>
                <hr>
                <div id="testing">
                    <button mat-raised-button (click)="this.resetState()">reset state</button>
                    <button *ngIf="testSelectedName" mat-raised-button (click)="this.nextStage()">next stage</button>
                    <hr>
                    <mat-form-field>
                      <mat-label>Choose test</mat-label>
                      <select [(ngModel)]="testSelectedName" matNativeControl required>
                        <option *ngFor="let testName of testNames" [value]="testName">{{testName}}<option>
                      </select>
                    </mat-form-field>
                   <p>You selected test: {{testSelectedName}}</p>
                    <div *ngIf="testSelected">
                        <mat-list dense>
                            <mat-list-item *ngFor="let stage of testSelected.stages; let index = index;" [ngStyle]="getDebugRowStyle(stage, index)">
                                {{stage}}
                            </mat-list-item>
                        </mat-list>
                    </div>	
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    -->