import { Component, ElementRef, ViewChild } from '@angular/core';
import { IMarker, MarkerOption } from 'src/app/models/marker.model';
import { BaseAssetComponent } from '../baseAsset.component';
import { AssetMetadataService } from 'src/app/services/assetMetadata.service';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent extends BaseAssetComponent {

  @ViewChild('video') video : ElementRef<HTMLVideoElement>;

  private defaultTimeGap = 2;
  private wantsSeek:number = -1;
  constructor(protected assetMetadataService: AssetMetadataService)
  {
    super();
    this.defaultWidth = 640;
    this.defaultHeight = 360;
  }
  ngOnInit(): void {

  }
  ngAfterViewInit() {
	if(this.video){
		this.sub = fromEvent(this.video.nativeElement, "error").subscribe(e => {
			 // video playback failed - show a message saying why
			 let message;
			  switch (this.video.nativeElement.error.code) {
				case MediaError.MEDIA_ERR_ABORTED:
				  message = 'You aborted the video playback.';
				  break;
				case MediaError.MEDIA_ERR_NETWORK:
				  message = 'A network error caused the video download to fail part-way.';
				  break;
				case MediaError.MEDIA_ERR_DECODE:
				  message = 'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.';
				  break;
				case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
				  message = 'The video could not be loaded, either because the server or network failed or because the format is not supported.';
				  break;
				default:
				  message = 'An unknown error occurred.';
				  break;
			  }
			console.warn("video playback error", message);
		});
	}
  }
  onMetaData(event:Event)
  {    
    if(this.assetVO.preview)
    {
      let video:HTMLVideoElement = event.target as HTMLVideoElement;
      if(!this.assetVO.fileMetadata)  this.assetVO.fileMetadata = {};
      this.assetVO.fileMetadata.width = video.videoWidth;
      this.assetVO.fileMetadata.height = video.videoHeight;
      this.assetVO.fileMetadata.duration = video.duration;

      // update asset dimensions if asset is new
      if(!this.assetVO.asset.uuid)
      {
       	// if(isNaN(this.assetVO.asset.width)) this.assetVO.asset.width = this.assetVO.fileMetadata.width;
        // if(isNaN(this.assetVO.asset.height)) this.assetVO.asset.height = this.assetVO.fileMetadata.height;
		    this.assetVO.asset.width = this.assetVO.fileMetadata.width;
        this.assetVO.asset.height = this.assetVO.fileMetadata.height;
      }
      this.assetMetadataService.assetMetaSubject.next(this.assetVO);
    }
  }
  seek(time:number)
  {
    let videoElement = this.video.nativeElement;
    if(videoElement && videoElement.duration)
    {
      //console.log("check pause", videoElement.paused);
      if(!videoElement.paused)
      {
        videoElement.pause();
      }
      //console.log("check pause 2", videoElement.paused);
      videoElement.currentTime = time;
    }else{
      // store and wait...
      this.wantsSeek = time;
      console.warn("TODO implement a wait");
    }    
  }
  markerDrop(marker:IMarker, x:number, y:number):boolean
		{
      let videoElement = this.video.nativeElement;
			// marker.asset_uuid = this.asset.uuid;?
      // set the meta data only if not already set
      if(!marker.metadata){
        marker.metadata = {in:videoElement.currentTime, out:videoElement.currentTime + 2};
      }

			return false;
		}
    async getAmendOptions(x: number, y: number, specific:boolean) : Promise<MarkerOption[]> {
      let videoElement = this.video.nativeElement;
      let data =  {in:videoElement.currentTime, out:videoElement.currentTime + this.defaultTimeGap}
      //let options = super.getAmendOptions(x, y, page); // include this for a generat positional marker
      let message1 = specific ? 'Link current time to amend' : 'Add amend at current time to this video';
      let message2 = specific ? 'Add marker at current time' : 'Add amend with marker at current time to this video';
      let options = [];
      options.unshift(new MarkerOption(message1, MarkerOption.ICON_TIME, MarkerOption.TYPE_GENERAL, data));
      options.unshift(new MarkerOption(message2, MarkerOption.ICON_MARKER_TIME, MarkerOption.TYPE_MARKER, data));
      //options.unshift({label:'Add general amend at current time', icon:'more_time', type:'general', data});
      //options.unshift({label:'Add marker at current time', icon:'schedule_send', type:'marker', data});      
      return new Promise((resolve, reject) => resolve(options));
    }
    updateMarker(marker:IMarker, message:string)
    {
      let videoElement = this.video.nativeElement;
      if(!videoElement) return;
       if(message == "in"){
        marker.metadata.in = videoElement.currentTime;
        if(marker.metadata.in >= marker.metadata.out)
        {
          marker.metadata.out = Math.min(marker.metadata.in + this.defaultTimeGap, videoElement.duration)
        }
       }else if(message == "out"){
        marker.metadata.out = videoElement.currentTime;
        if(marker.metadata.out <= marker.metadata.in)
        {
          marker.metadata.in = Math.max(0,  marker.metadata.out - this.defaultTimeGap)
        }
       }
       marker.metadata.in = marker.metadata.in;
       marker.metadata.out = marker.metadata.out;
    }
}
