import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/api/admin.service';
import { buildDate } from 'src/environments/version';
import { IUser } from 'src/app/models/user.model';
import { PusherService } from 'src/app/services/pusher.service';
import { IMessage } from 'src/app/models/message';

@Component({
  selector: 'app-admin-websockets',
  templateUrl: './admin-websockets.component.html',
  styleUrls: ['./admin-websockets.component.scss']
})
export class AdminWebsocketsComponent implements OnInit {

  activeUsers:IUser[];
  buildDate = buildDate;
  constructor(
    public adminService:AdminService,
    public snack:MatSnackBar,
    public pusherService:PusherService,
    ) { }

  ngOnInit(): void {
    this.loadActiveUsers();
    this.pusherService.myChat.messages.subscribe((message:IMessage) => {
      console.log("my admin message", message);
      // find the user, slap the api version info onto the watsit
      if(message.metadata?.action == "message" && message.type == "message")
        {
          // get the user
          let user_uuid = message.user;
          let user = this.activeUsers.find(user => user.uuid == user_uuid)
          if(user){
            if(user['status'] == "requested") user['status'] = null;
            if(!user['status']) user['status'] = [];
            user['status'].push(message.data);
            //user["api-version"] = message.message.message["api-version"];
            //user["route"] = message.message.message["route"];
            //user["browser"] = message.message.message["browser"];
            //user["version"] = message.message.message["version"];
            //user["buildDate"] = message.message.message["buildDate"];
            this.activeUsers = this.activeUsers.concat([]);
          }
        }
      
    })
  }
  loadActiveUsers()
  {
    this.adminService.websocketUsers().subscribe(res => {
      this.activeUsers = res.data;
      this.activeUsers.forEach(activeUser => activeUser['status'] = null);     
    });
  }
  redisCheck()
  {
    this.snack.open("redis check not implemented", null, {duration:3000, panelClass:'error'});
  }
  redisPurge()
  {
    this.snack.open("redis purge not implemented", null, {duration:3000, panelClass:'error'});
  }
  getUserStatus(user)
  {
    user['status'] = 'requested';
    this.adminService.userMessage(user.uuid, {message:"status"}).subscribe(res => {
      console.log("res",res );
    });
  }
  userReload(user, force:boolean = false, session = null)
  {
    this.adminService.userMessage(user.uuid, {message:"reload", force, session}).subscribe(res => {
      console.log("res",res );
    });
  }
}
