import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/api/user.service';
import { IUser, UserRoles } from 'src/app/models/user.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericDialogComponent, GenericDialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/api/admin.service';

@Component({
	selector: 'app-admin-users',
	templateUrl: './admin-users.component.html',
	styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
	
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	private _unsubscribe = new Subject<boolean>();
	displayedColumns: string[] = ['name', 'email', 'roles', 'options', 'active'];
	dataSource:MatTableDataSource<IUser>;
	users:IUser[];
	search:string;

	constructor(private userService:UserService,
				private adminService:AdminService,
				private dialog:MatDialog,
				private snackBar:MatSnackBar) { }

	ngOnInit(): void
	{
		this.loadUsers();
	}
	loadUsers()
	{
		this.userService.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			this.users = response.data;
			for (let i = 0; i < this.users.length; i++) {
				const user = this.users[i];
				user.rolesMap = {};
				user.roles = UserRoles.rolesToString(user.rolez).split(", ");
				for (let j = 0; j < user.roles.length; j++) {
					//user.rolesMap[user.roles[j].name] = true;
					user.rolesMap[user.roles[j]] = true;
				}
			}
			this.dataSource = new MatTableDataSource<IUser>(this.users);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.dataSource.filter = this.search?.trim().toLowerCase();
			// https://stackoverflow.com/questions/46743115/default-sorting-in-angular-material-sort-header
			//this.sort.sort(({ id: 'name', start: 'asc'}) as MatSortable);
		});
	}
	getRoles(user:IUser)
	{
		return user.roles.join(', ');//Object.keys(user.rolesMap).join(",");
	}
	applyFilter(event: Event) {
		//const search = (event.target as HTMLInputElement).value;
		this.dataSource.filter = this.search.trim().toLowerCase();
	}
	clearSearch()
	{
		this.search = null;
		this.dataSource.filter = null
	}
	addUser()
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add User",
				negative:"Cancel",
				positive:"Create",
				form:[
					{ name:"name", type:"text", placeholder:"Enter Name", value:"", validator:Validators.required},
					{ name:"email", type:"text", placeholder:"Enter Email", value:"", validator:[Validators.required, Validators.email]}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				this.snackBar.open("creating user...", '', {duration:5000});
				this.userService.create(result.formGroup.value).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("user created", "", {duration:2000});
					this.loadUsers();
				});
			}
		});
	}
	editUser(user:IUser)
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Edit User",
					negative:"Cancel",
					positive:"Save",
					form:[
						{ name:"name", type:"text", placeholder:"Enter Name", field:"name", label:"name", validator:Validators.required},
						{ name:"email", type:"text", placeholder:"Enter Email", field:"email", label:"email", validator:[Validators.required, Validators.email]},
						{ name:"super", type:"checkbox", field:"rolesMap.super", label:"is Super?"},
						{ name:"admin", type:"checkbox", field:"rolesMap.admin", label:"is Admin?"},
						{ name:"standard", type:"checkbox", field:"rolesMap.standard", label:"is Standard?"},
						{ name:"active", type:"checkbox", field:"active", label:"active?"},
					],
					model:user
				}
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				//let channel = result.model;
				//channel.name = result.formGroup.value.name;
				this.snackBar.open("saving user...", '', {duration:5000});
				//console.log("edit user: user", user);
				//console.log("edit user: model", result.formGroup.value);
				this.userService.update(user.uuid, result.formGroup.value).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("user saved", "", {duration:2000});
					this.loadUsers();
				});
			}
		});	
	}
	impersonateUser(user:IUser)
	{
		this.adminService.impersonate(user.uuid).subscribe(res => {
			window.location.href = "/";
		}, err =>
		{
			
		});
	}
	deleteUser(user:IUser)
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Delete User",
				subtitle:user.name,
				body:"Are you sure you wish to delete this user?",
				positive:"Delete",
				negative:"Cancel"
			}
		  });
		  dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
			  this.snackBar.open("deleting user...", "", {duration:3000});
			  this.userService.delete(user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(res.data)
				{
					this.forceDeleteUser(user, res.data);
				}else{
					this.snackBar.open("user deleted", "", {duration:2000});
					this.loadUsers();
				}
			  });
			}
		  });
	}
	forceDeleteUser(user:IUser, data:any)
	{
		let info = ` User in ${data.numProjects} projects and has ${data.numTasks} tasks`;
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Force Delete User",
				subtitle:user.name,
				body:"Are you sure you wish to delete this user?" + info,
				positive:"Delete",
				negative:"Cancel"
			}
		  });
		  dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
			  this.snackBar.open("deleting user...");
			  this.userService.delete(user.uuid, new HttpParams({fromObject:{force:true}}) ).pipe(takeUntil(this._unsubscribe)).subscribe(res => {

				this.snackBar.open("user deleted", "", {duration:2000});
				this.loadUsers();
			  });
			}
		  });
	}
	/*
	ngAfterViewInnit()
	{
		
	}*/

}
