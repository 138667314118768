<h2>Upload Asset</h2>
<mat-form-field class="input_field">
	<input matInput #add_asset_name class="login_input" placeholder="Name" value="" (keyup)="setAssetSaveDataDelayed('name', $event.target.value)" (change)="setAssetSaveData('name', $event.target.value)">
</mat-form-field>
<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_width id="upload_asset_w" class="login_input" placeholder="Width" value="{{asset_width}}" (keyup)="setAssetSaveDataDelayed('width', $event.target.value)" (change)="setAssetSaveData('width', $event.target.value)">
</mat-form-field>
<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_height id="upload_asset_h" class="login_input" placeholder="Height" value="{{asset_height}}" (keyup)="setAssetSaveDataDelayed('height', $event.target.value)" (change)="setAssetSaveData('height', $event.target.value)">
</mat-form-field>
<mat-form-field class="asset_type">
  <mat-label>Type</mat-label>
  <mat-select id="asset_type" [value]="asset_type_id" (selectionChange)="setAssetTypeId($event.value)">
    <mat-option *ngFor="let asset_type of asset_types" [value]="asset_type.uuid">
      {{asset_type.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div *ngIf="asset_type_id == 1" class="image_type additional_attributes">
	<div class="upload_file">
		<h4>File</h4>
		<app-image-uploader [width]="asset_width" [height]="asset_height" [parent]="this"></app-image-uploader>
	</div>
</div>
<div class="button_wrapper_save">
	<button *ngIf="required_fields_filled" mat-raised-button (click)="uploadAsset({name: add_asset_name.value, width: add_asset_width.value, height: add_asset_height.value})">Save</button>
</div>