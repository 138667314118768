<mat-card>
    <mat-card-header>
        <mat-card-title>Manage accounts</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div>
            <div>
                <button mat-raised-button (click)="createAccount()">Create new Account</button>
                <!--

                    <mat-list>
                        <mat-list-item *ngFor="let account of this.accountService.accounts | async">
                            <span>{{account.name}}</span>
                        </mat-list-item>
                    </mat-list>
                -->
                <app-genericlist
                    [items]="this.accountService.accounts$ | async"
                    [search]="search"
                    (clickItem)="selected($event)"
                    (editItem)="edit($event)"
                    (deleteItem)="delete($event)">
                </app-genericlist>
            </div>
           <!--
               <div>
                   <div *ngIf="this.account">
                       <span>selected account: {{this.account.name}}</span>
                       <div>account users: {{this.account.users.length}}</div>
                       <app-genericlist
                           [items]="(this.accountService.account$ | async)?.users"
                           [label]='name'>
                       </app-genericlist>
                   </div>
               </div>
           -->
        </div>

    </mat-card-content>
    <mat-card-footer>

    </mat-card-footer>
</mat-card>