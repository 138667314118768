import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Operator } from 'rxjs';
import { ApiResponse, OVBaseService } from './OvBaseService';
import { wrapper } from '../utils/wrapper';

@Injectable({
	providedIn: 'root'
})
export class AdminService extends OVBaseService<any, string> {
	stopImpersonation() {
		return this._post(`${this.base}/impersonate`);
	}
	impersonate(uuid: string) {
		return this._post(`${this.base}/impersonate/${uuid}`);
	}
	constructor(protected http: HttpClient) {
		super(http, 'admin');
	}
	private lift<T, R>(source: Observable<T>, operator: (sink: Observer<R>) => Observer<T>): Observable<R> {
		return new Observable<R>((sink: Observer<R>) => {
		  return source.subscribe(operator(sink));
		});
	  }
	// disk stats
	diskStats(region:string):Observable<ApiResponse<any>>{
		//return this.http.get<ApiResponse<any>>(`${this.base}/diskStats`, {headers:{'Region-Override':region}});
		let base = this.base;
		if(base.indexOf("/api/") != -1)
		{
			// local - dont change
		}else{
			base = base.split("api.", 2).join(`api.${region}.`);
		}
		return this._get(`${base}/diskStats`);
		return this._get(`${this.base}/diskStats`).pipe(wrapper(
			(sub, ob) => console.log("init", sub, ob),
			(value) => console.log("next", value),
			() => console.log("complete"),
		));
	}
	dbStats():Observable<ApiResponse<any>>{
		return this._get(`${this.base}/dbStats`);
	}
	spacesPolicy():Observable<ApiResponse<any>>{
		return this._get(`${this.base}/awsPolicy/spaces`);
	}
	r2Policy():Observable<ApiResponse<any>>{
		return this._get(`${this.base}/awsPolicy/r2`);
	}
	websocketUsers():Observable<ApiResponse<any>>{
		return this._get(`${this.base}/websocketUsers`);
	}
	jobs():Observable<ApiResponse<any>>{
		return this._get(`${this.base}/jobs`);
	}
	testJob():Observable<ApiResponse<any>>{
		return this._get(`${this.base}/testJob`);
	}
	userMessage(uuid:string = null, data:any = null):Observable<ApiResponse<any>>{
		return uuid ? this._post(`${this.base}/userMessage/${uuid}`, data) : this._post(`${this.base}/userMessage`, data);
	}
}
