<div class="panel_top">
	<h2>{{asset.name}}</h2>
	<div class="icons">
		<button mat-icon-button><mat-icon>search</mat-icon></button>
		<button mat-icon-button><mat-icon>settings</mat-icon></button>
	</div>
</div>
<div class="panel_middle">
	<p>Layer properties</p>
	<div class="coords">
		<div class="column">
<!-- 		<mat-form-field class="input_field"> -->
			<span class="label">X:</span>
			<input matInput type="number" #add_asset_x id="_asset_x" class="login_input" placeholder="X" value="{{asset.x}}" (keyup)="setAssetSaveDataDelayed('x', $event.target.value)" (change)="setAssetSaveData('x', $event.target.value)">
			<span class="units">px</span>
<!-- 		</mat-form-field> -->
		</div>
		<div class="column">
<!-- 		<mat-form-field class="input_field"> -->
			<span class="label">Y:</span>
			<input matInput type="number" #add_asset_y id="_asset_y" class="login_input" placeholder="Y" value="{{asset.y}}" (keyup)="setAssetSaveDataDelayed('y', $event.target.value)" (change)="setAssetSaveData('y', $event.target.value)">
			<span class="units">px</span>
<!-- 		</mat-form-field> -->
		</div>
	</div>
	<div class="dimensions">
		<div class="column">
<!-- 		<mat-form-field class="input_field"> -->
			<span class="label">W:</span>
			<input matInput type="number" #add_asset_width id="_asset_w" class="login_input" placeholder="Width" value="{{asset.width}}" (keyup)="setAssetSaveDataDelayed('width', $event.target.value)" (change)="setAssetSaveData('width', $event.target.value)">
			<span class="units">px</span>
<!-- 		</mat-form-field> -->
		</div>
		<div class="column">
<!-- 		<mat-form-field class="input_field"> -->
			<span class="label">H:</span>
			<input matInput type="number" #add_asset_height id="_asset_h" class="login_input" placeholder="Height" value="{{asset.height}}" (keyup)="setAssetSaveDataDelayed('height', $event.target.value)" (change)="setAssetSaveData('height', $event.target.value)">
			<span class="units">px</span>
<!-- 		</mat-form-field> -->
		</div>
	</div>
	<p>File size: 99.47KB</p>
	<p>URL: https://oceanview.fivebyfivedigital.com/bin/</p>
</div>
<div class="panel_bottom">
	<p>Layer properties</p>
	<mat-list role="list">
		<mat-list-item *ngFor="let asset of assets; index as i" role="listitem" class="list-item">
			<div class="row asset" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
				<p class="name">{{asset.name}} ({{asset.width}}x{{asset.height}})</p>
				<button class="icon" mat-icon-button (click)="ds.openEditAssetDialog({parent:this, asset:asset})"><mat-icon>edit</mat-icon></button>
			</div>
		</mat-list-item>
	</mat-list>
</div>