 <!--<div class="audio">
    <p>{{asset.name}}</p>
	<audio *ngIf="getPath()" (loadedmetadata)="onMetaData($event)" [src]="getPath() | safe:'url'" controls controlsList="nodownload" preload="auto"></audio> 
    <div *ngIf="!getPath()">
		<p class="warning">No audio file..</p>
		<audio controls controlsList="nodownload" preload="auto"></audio>
	</div>
</div>
-->
<div class="audio">
		<span class="filename">{{baseName()}}</span>
		<audio *ngIf="getPath()" #audio (loadedmetadata)="onMetaData($event)" [src]="getPath() | safe:'url'" controls controlsList="nodownload" preload="auto"></audio> <!-- autoplay -->
    
	<div *ngIf="!this.getPath()" class="placeholder"  [ngStyle]="getStyle()">
		<div class="center">
        	<mat-icon class="">music_note</mat-icon>
			<span *ngIf="parentView != 'preview'">{{this.assetVO.asset.name}}</span>
		</div>
        <div *ngIf="parentView != 'preview'" class="message">upload an audio file</div>
    </div>   
</div>