import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IApprovalGroup } from 'src/app/models/ApprovalGroup.model';
import { IChannel } from 'src/app/models/channel.model';
import { CreativeState, ICreative } from 'src/app/models/creative.model';
import { Permission } from 'src/app/models/permissions.model';
import { ITask } from 'src/app/models/task.model';
import { IUser } from 'src/app/models/user.model';
import { IWorkflow } from 'src/app/models/workflow.model';


@Component({
	selector: 'app-workflow-widget',
	templateUrl: './workflow-widget.component.html',
	styleUrls: ['./workflow-widget.component.scss']
})
export class WorkflowWidgetComponent implements OnInit {

	@Input() workflow:IWorkflow;
	@Input() groupInfo:any[];
	@Input() channel:IChannel;
	@Input() state:string;
	@Input() currentGroup:IApprovalGroup;
	//@Input() channelUsers:IUser[];

	//public currentGroup:IApprovalGroup;
	public groupsApproved:boolean[];
	public moderators:IUser[];

	constructor() { }

	ngOnInit(): void {

		//console.log("NG WF-WIDGET INIT");

		this.moderators = [];
		// TODO do this only when the workflow is set.. not always there on init
		
		
	}

	ngOnChanges(changes: SimpleChanges) {
		
		if(!changes) return;
		//console.log("NG WF-WIDGET CHANGES", changes);
		if(!this.workflow || !this.moderators) return;
		for (let i = 0; i < this.workflow['users'].length; i++)
		{
				const user = this.workflow['users'][i];
				if(Permission.hasPermission(user.scoped_permissions, Permission.VALIDATOR))
				{
					this.moderators.push(user);
				}
		}

		if(!this.groupInfo) return;
		//console.log("WORKFLOW WIDGET "+this.groupInfo[0].done);
		//this.getCurrentGroup();
		
	}

	/*
	//currentGroup is now pulled in an an INPUT from parent INFO PANEL
	getCurrentGroup(){
		console.log("getting current group");
		let currentGrp = null;
		for (let i = 0; i < this.groupInfo.length; i++) {
			let grp = this.groupInfo[i];
			grp.done = false;
			//get approver info
			let numApprovers = grp.total;
			let numApproved = grp.approved;
			//count fully approved
			if (numApprovers != 0 && numApproved == numApprovers )
			{
				grp.done = true;
			} else if(this.canBeCurrentGrp(this.state)) {
				if(!currentGrp && (grp.users.length == 0 || grp.users[0].state != 'waiting')) currentGrp = grp;
			}
		}
		this.currentGroup = currentGrp;
	}
	*/
	canBeCurrentGrp(state){
		switch(state){
			case CreativeState.APPROVAL:// "approval":
			//case "checking":
			case CreativeState.QUALIFYING://"qualifying":
			case CreativeState.CONFIRMING://"confirming":
			case CreativeState.AMENDING://"amending":
			case CreativeState.WARNING://"warning":
				return true;
			
			default: return false;
		}
	}

	getApproverIcon(userState){
		let iconObj = {icon :'', iconTooltip:''};
		switch(userState){
			case "approval": iconObj = {icon :'how_to_reg', iconTooltip:'with approver to review'};break;//iconName = 'how_to_reg';break;//check_circle_outline
			case "approved": iconObj = {icon :'check', iconTooltip:'approved'};break;
			case "submitted": iconObj = {icon :'feedback_outline', iconTooltip:'amends submitted'};break;
			case "checking": iconObj = {icon :'rule', iconTooltip:'reviewing completed amends'};break;
			case "waiting": iconObj = {icon :'hourglass_empty', iconTooltip:'waiting for next approval stage'};break;
			case "amending": iconObj = {icon :'feedback', iconTooltip:'submiited amends in production'};break;
		}
		return iconObj;
	}

	isIndicatedUser(userState){
		switch(userState){
			case "approval": 
			//case "approved":
			//case "submitted": 
			case "checking": 
			//case "waiting": 
			//case "amending": 
			return true;
		}
		return false;

		return true;
	}
	scrollIntoView(element: HTMLElement){
		element.scrollIntoView({ block: 'center',  behavior: 'smooth', inline: 'center' });
	}
	/*
	ngOnChanges(changes: SimpleChanges): void {
		return;
		if(!this.workflow) return;
		// work out which groups are all approved (done) - single index
		// which is the active one.. any task.state !waiting FIRST OCCURANCE
		const approvalGroups = this.workflow.approval_groups;
		const users = this.workflow["users_from_secondary"];
		// we have the users now put them in the correct approval groups
		// first empty
		console.log("lets go");
		for (let i = 0; i < approvalGroups.length; i++) {
			approvalGroups[i].users.length = 0;
		}
		for (let i = 0; i < users.length; i++) {
			const user = users[i];
			let data:number = user["scoped_data"];
			if(data)
			{
				approvalGroups[data-1].users.push(user);
			}				
		}

		this.groupsApproved = [];
		this.currentGroup = null;
	}*/
	/*
	ngOnChanges(changes: SimpleChanges): void {
		if(!this.workflow) return;
		this.groupsApproved = [];
		this.currentGroup = null;
		// work out which groups are all approved (done) - single index
		// which is the active one.. any task.state !waiting FIRST OCCURANCE
		const approvalGroups = this.workflow.approval_groups;
		for (let i = 0; i < approvalGroups.length; i++) {
			const group:any = approvalGroups[i];
			let users:any[] = group.users;
			if(users?.length)
			{
				let allApproved = true;
				for (let j = 0; j < users.length; j++) {
					const user = users[j];
					// filter users with no tasks
					if(!user.tasks || user.tasks.length == 0)
					{
						users.splice(j--, 1);
						continue;
					}
					// find approval task
					for (let k = 0; k < user.tasks.length; k++) {
						const task = user.tasks[k];
						if(task.type == "approval")
						{
							user.approvalTask = task;
							break;
						}
					}
					const approvalTask:ITask = user.approvalTask;
					if(approvalTask.state != "approved" )
						allApproved = false;
					if(!this.currentGroup && (approvalTask.state == "approval" || approvalTask.state == "checking") )
						this.currentGroup = group;        
				}
				// need at least one user for all approved to be true
				this.groupsApproved.push(users.length && allApproved);
			}else{
				// no users is this the current group?
				//this.currentGroup = group;
				//break;
			}
		}
		}
	*/

}
