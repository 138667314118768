import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset, AssetVO } from 'src/app/models/asset.model';

export interface DetailedAssetListData {
	assets: AssetVO[];
}

@Component({
  selector: 'app-detailed-asset-list',
  templateUrl: './detailed-asset-list.component.html',
  styleUrls: ['./detailed-asset-list.component.scss']
})

export class DetailedAssetListComponent implements OnInit {

  displayedColumns = ["assetname", "type", "width", "height", "size", "visible", "deliverable"];
	constructor(public dialogRef: MatDialogRef<DetailedAssetListComponent>, @Inject(MAT_DIALOG_DATA) public data: DetailedAssetListData) { }
	
  public assetData: AssetVO[];

  ngOnInit(): void {
    //console.log("detail", this.data);
    this.assetData = this.data.assets.map(function(e){return e;});
    //for (let i = 0; i < this.data.assets.length; i++) {
    for (let i = 0; i < this.assetData.length; i++) {
      //const assetVO = this.data.assets[i];
      const assetVO = this.assetData[i];
      // check for dimensions in asset name
      let dimensionsMatch = assetVO.asset.name.match(/([\d]+x[\d]+)/);
      if(dimensionsMatch){
        let dimensions = dimensionsMatch[0].split("x");
       assetVO["name_width"] = parseInt(dimensions[0]);
       assetVO["name_height"] = parseInt(dimensions[1]);
      }
      // check for dimensions in asset file
      if(Asset.isFile(assetVO.asset))
      {
        let dimensionsMatch = (this.getDisplayContentURI(assetVO))?.match(/([\d]+x[\d]+)/);
        if(dimensionsMatch){
          let dimensions = dimensionsMatch[0].split("x");
          assetVO["filename_width"] = parseInt(dimensions[0]);
          assetVO["filename_height"] = parseInt(dimensions[1]);
        }
      }
    }
  }
  formatBytes(value)
  {
    if(!value || isNaN(value)) return null;
    let kb = 1024;
    let mb = 1024*1024;
    let gb = 1024*1024*1024;

    if(value < mb) return (value/kb).toFixed(2) + " kb";
    if(value < gb) return (value/mb).toFixed(2) + " Mb";
    return (value/gb).toFixed(2) + " Gb";
  }
  getDisplayName(assetVO:AssetVO)
  {
    if(Asset.isFile(assetVO.asset))
    {
      return assetVO.asset.uri?.split("/").pop() || assetVO.asset.name;
    }else{
      return assetVO.asset.name;
    }
  }
  getDisplayContentURI(assetVO:AssetVO)
  {
    if(Asset.isFile(assetVO.asset))
    {
      return this.hasFileName(assetVO) ? assetVO.asset.uri?.split("/").pop() : null ;
    }else return assetVO.asset.uri ? assetVO.asset.uri : null ;
  }
  hasFileName(assetVO:AssetVO)
  {
    return Asset.isFile(assetVO.asset) && assetVO.asset.uri;
  }

  clickAsset(assetVO){
    this.dialogRef.close(assetVO);
  }

}
