import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/api/admin.service';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';

@Component({
  selector: 'app-admin-stats',
  templateUrl: './admin-stats.component.html',
  styleUrls: ['./admin-stats.component.scss']
})
export class AdminStatsComponent extends BaseComponent implements OnInit {

	public diskUseage:any = {};

  constructor(
    public adminService:AdminService,
  ) { 
    super();
  }

  ngOnInit(): void {
    this.sub = this.adminService.diskStats("uk").subscribe(res => {
			//console.log("disk", res);
			this.diskUseage['uk'] = {
        total: res.data['total_space'],
        free: res.data['free_space'],
        utilised: Math.floor(res.data['utilised_space'] * 100),
      };
		});
    this.sub = this.adminService.diskStats("us").subscribe(res => {
      this.diskUseage['us'] = {
        total: res.data['total_space'],
        free: res.data['free_space'],
        utilised: Math.floor(res.data['utilised_space'] * 100),
      };
		});
    this.sub = this.adminService.diskStats("au").subscribe(res => {
			this.diskUseage['au'] = {
        total: res.data['total_space'],
        free: res.data['free_space'],
        utilised: Math.floor(res.data['utilised_space'] * 100),
      };
		});

		this.sub = this.adminService.dbStats().subscribe(res => {
			console.log("db", res);
		});

		this.sub = this.adminService.spacesPolicy().subscribe(res => {
			console.log("spaces", res)
		});
    this.sub = this.adminService.r2Policy().subscribe(res => {
			console.log("r2", res)
		});
  }

}
