import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '../utils/FileUtils';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  transform(value: unknown, suffix:string = null): string {
    let result = formatBytes(Number(value));
    return suffix ? result + suffix : result;
  }
}
