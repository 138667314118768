import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  @Input() key:string;
  @Input() type:string = null;
  @Input() label:string = null;
  public tip:string;
  constructor(private helpService:HelpService, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.tip = this.helpService.getTooltip(this.key);
  }
  show(event)
  {
    let helpContent = this.helpService.getContent(this.key);
    let data = {
      titleIcon: 'help_outline',
      title: helpContent.title,
      positive:"ok",
      body: helpContent.body,
    };
    //console.log("help data:",data);
    this.dialog.open(GenericDialogComponent, {data});
  }
}
