import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit {
  @Input() canvas;
  @ViewChild('canvasRef') canvasRef: ElementRef<HTMLCanvasElement>;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(){    
    let canvasElement = this.canvasRef.nativeElement;  
    let ctx = canvasElement.getContext("2d");
    //console.log("DRAWING");
    ctx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height);    
  }
}
