<mat-dialog-content>


    <p>Removing {{data.user_name}} as production from channel(s)</p>
    <ul *ngIf="data.channels">
        <li *ngFor="let channel of data.channels">{{channel.name}}</li>
    </ul>
    <mat-stepper *ngIf="creatives && creatives.length > 0" #mainStepper >
        <mat-step label="Info" state="phone">
        <p>Production info:</p>
        <p>Production for {{creatives?.length}} creatives</p>
        <div class="button-group">
            <button mat-stroked-button color="warn" matDialogClose>Cancel</button>
            <button mat-stroked-button color="primary" matStepperNext>Next</button>
        </div>
        </mat-step>
        <mat-step label="Reassign creatives" *ngIf="creatives?.length" state="chat" (interacted)="interacted($event)">
        
            <mat-stepper *ngIf="data.channels">
                <mat-step *ngFor="let channel of data.channels; last as isLast; first as isFirst; index as channelIndex" [label]="channel.name" state="phone">
                    <span>Actions for channel {{channel.name}}</span>
                    <p>List of this users creatives in production, what are you going to do with each one.</p>
                    <mat-list>
                    <mat-list-item class="creative-item" *ngFor="let creativeAction of creativeActionsByChannel[channelIndex]">
                        <span mat-line>{{creativeAction.creative.format.group}} - {{creativeAction.creative.format.name}}</span>
                        <mat-form-field appearance="fill">
                            <mat-label>Action</mat-label>
                            <mat-select [(ngModel)]="creativeAction.action">
                                <mat-option default [value]="'none'">- None -</mat-option>
                            <mat-optgroup [label]="'Or reassign to:'"
                            [disabled]="false">
                                <mat-option *ngFor="let user of data.otherProductionUsers[channelIndex]" [value]="user.uuid" [disabled]="user.uuid == this.data.user_uuid">
                                    {{user.name}}
                                </mat-option>                    
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <button class="btn-copy" (click)="copyActionToOthers(creativeAction, channelIndex)" [matTooltip]="'Copy this action to all creatives'" mat-icon-button><mat-icon>content_copy</mat-icon></button>
                </mat-list-item>
        </mat-list>
                    <div class="button-group">
                        <button *ngIf="!isFirst" mat-stroked-button matStepperPrevious>Back</button>
                        <button *ngIf="!isLast" mat-stroked-button matStepperNext>Next</button>
                        <button *ngIf="isLast" mat-stroked-button (click)="mainStepper.next()">Done</button>
                    </div>
                </mat-step>
            </mat-stepper>

            <!--

                <p>List of this users creatives in production, what are you going to do with each one.</p>
                <mat-list>
            <mat-list-item class="creative-item" *ngFor="let creativeAction of creativeActions">
                <span mat-line>{{creativeAction.creative.format.group}} - {{creativeAction.creative.format.name}}</span>
                <mat-form-field appearance="fill">
                    <mat-label>Action</mat-label>
                    <mat-select [(ngModel)]="creativeAction.action">
                        <mat-option default [value]="'none'">- None -</mat-option>
                    <mat-optgroup [label]="'Or reassign to:'"
                    [disabled]="false">
                        <mat-option *ngFor="let user of data.otherProductionUsers" [value]="user.uuid" [disabled]="user.uuid == this.data.user_uuid">
                            {{user.name}}
                        </mat-option>                    
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <button class="btn-copy" (click)="copyActionToOthers(creativeAction)" [matTooltip]="'Copy this action to all creatives'" mat-icon-button><mat-icon>content_copy</mat-icon></button>
        </mat-list-item>
    </mat-list>

    <div class="button-group">
        <button  mat-stroked-button matStepperPrevious>Back</button>
        <button mat-stroked-button color="primary"  mat-stroked-button matStepperNext>Next</button>
    </div>
    -->
        </mat-step>
        <mat-step label="Step 3">
        <p>You're welcome.</p>
        <div class="button-group">
            <button mat-stroked-button matStepperPrevious>Back</button>
            <button (click)="submit()" mat-stroked-button color="primary" mat-stroked-button>Submit</button>
        </div>
        </mat-step>
    
        <!-- Icon overrides. -->
        <ng-template matStepperIcon="phone">
        <mat-icon>call_end</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="chat">
        <mat-icon>forum</mat-icon>
        </ng-template>
    </mat-stepper>
</mat-dialog-content>