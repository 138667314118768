import { Component } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent extends BaseAssetComponent{

  constructor() {
    super();
	  this.defaultWidth = 640;
	  this.defaultHeight = 360;
  }

  ngOnInit(): void {
  }
  isValid()
  {
    let uri = this.uri();
    if(!uri) return false;
	
	uri = decodeURIComponent(uri);
    let urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    let valid = urlRegex.test(uri);
    return valid;
  }
  getPath()
  {
    let uri = this.uri();
    if(!uri) return null;
    //if(uri.startsWith("http://" || ) 
    return decodeURIComponent(uri);
  }
}
