<p>Import asset JSON</p>
<mat-form-field class="full-width">
    <mat-label>Paste json here...</mat-label>
    <textarea
        matInput [(ngModel)]="value"
        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="4" cdkAutosizeMaxRows="20"
    ></textarea>  <!--placeholder="paste json here..."-->
    <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
<div>
    <button [disabled]="!valid()" mat-raised-button color="primary" (click)="import()" matTooltip="Import asset json">Import assets</button>
</div>
