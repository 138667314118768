<div class="iframe">
	<ng-container *ngIf="this.getPath(); else elseNoPath">
		<ng-container *ngIf="this.isValid(); else invalidPath;">
			<iframe [src]="getPath() | safe:'resourceUrl'" frameborder="0" width="100%" height="100%"></iframe>
		</ng-container>
		<ng-template #invalidPath>
			<p>Url is invalid..</p>
		</ng-template>
	</ng-container>
	<ng-template #elseNoPath>
		<div class="placeholder" [ngStyle]="getStyle()">
			<div class="center">
				<mat-icon class="">web_asset</mat-icon>
				<span *ngIf="parentView != 'preview'">{{assetVO.asset.name}}</span>
			</div>
			<div *ngIf="parentView != 'preview'" class="message">no iframe URL saved</div>
		</div>   
	</ng-template>
</div>