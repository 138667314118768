<ng-container>
    <!-- assets -->
    <div id="assets" class="assets" [ngClass]="layout" [ngStyle]="{transform: 'scale('+ zoom +')'}" #assetsContainer (mousedown)="onAssetSelected(null, $event)"> <!-- editMode ? onAssetSelected(null, $event) : null --> <!-- null should be return WTF?!? -->

		<ng-container *ngFor="let assetVO of assets; index as i; trackBy: assetTrackBy">
             <!--[selectedTask]="selectedTask" -->
            <app-asset
                *ngIf="assetVO.asset.visible && (layout != 'gallery' || showAssetIfGalleryMode(assetVO))"
                [class.assetPdf]="assetVO.asset.type=='pdf' && layout=='gallery'"
                [assetVO]="assetVO"
                [assets]="assets"
                [tasks]="tasks"              
                [selected]="assetVO==selectedAsset"
                [assetEditMode]="editMode"
                [index]="i"
                [showMarkup]="showMarkup"
                [layout]="layout"
                [galleryMode]="layout=='gallery'"
                [markerVOs$]="markerVOs$"
                (layoutChange)="layoutChange.emit($event)"
                (markerMouseEvent)="markerMouseEvent.emit($event)"
                class="asset"
                [ngClass]="{'selected':selected}"
                [ngStyle]="getAssetStyle(assetVO, i)"
                (mousedown)="onAssetSelected(assetVO, $event)"
                appDraggable #draggable [disabled]="!editMode || layout != 'custom' || saving" [targetVO]="assetVO" [target]="assetVO.asset" [snapTargets]="assets" (dragUpdate)="dragUpdate($event)" (onSnappingUpdate)="onSnappingUpdate($event)"
            ></app-asset>
            <!--(mousedown)="editMode ? onAssetSelected(assetVO, $event) : return;" -->
        </ng-container>   
    </div>
    <!-- snapping -->
    <div *ngIf="snapping?.snapping" class="snapping">
        <div class="snapX" *ngIf="snapping.snapping && snapping.x !== false" [ngStyle]="{'left.px':snapping.x}"></div>
        <div class="snapY" *ngIf="snapping.snapping && snapping.y !== false" [ngStyle]="{'top.px':snapping.y}" ></div>
    </div>
    <!-- assets -->
    <div class="gallery-controls" *ngIf="assets && layout=='gallery'">
        <div class="arrow prev" *ngIf="assets.length > 1">
            <button [disabled]="assets.length <= 1" (click)="galleryMove(-1)" mat-mini-fab><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div class="arrow next" *ngIf="assets.length > 1">
            <button [disabled]="assets.length <= 1" (click)="galleryMove(1)" mat-mini-fab><mat-icon>arrow_forward</mat-icon></button>
        </div>
        <div class="pagination" *ngIf="assets.length > 1">
            <span *ngFor="let asset of assets; index as i" (click)="galleryGoto(i)" [class.active]="(!selectedAsset && i == galleryIndex) || selectedAsset == asset"></span>
        </div>
    </div>
</ng-container>
