import { Component, OnInit } from '@angular/core';
import { Upload, Upload2, UploadsService } from 'src/app/api/uploads.service';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent implements OnInit {

  uploads:Upload[];
  constructor(public uploadsService:UploadsService) { }

  ngOnInit(): void {
    this.uploadsService.uploads.subscribe(uploads => {
			this.uploads = uploads;
		});
  }
  /*
  getTotalProgress()
  {
    // should this only include active? uploads
    let totalProgress = "";
    if(this.uploads?.length)
    {
      let total = 0;
      this.uploads.forEach(upload => {
        total += upload.progress;
      });
      total /= this.uploads.length;
      totalProgress = Math.floor(total) + "%";
    }
    return totalProgress
  }*/
  cancel(upload:any)
  {
    this.uploadsService.cancel(upload);
  }
  remove(upload:any)
  {
    this.uploadsService.remove(upload);
  }
  retry(upload:any)
  {
    this.uploadsService.retry(upload);
  }

  filterActive = (upload:Upload2) => upload.state == Upload2.STATE_STARTED;
  filterInActive = (upload:Upload2) => upload.state != Upload2.STATE_STARTED;
}
