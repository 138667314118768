import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeOnObservable } from 'rxjs/internal-compatibility';
import { ProjectService } from 'src/app/api/project.service';
import { WorkgroupService } from 'src/app/api/workgroup.service';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { IProject, Project } from 'src/app/models/project.model';
import { IUser } from 'src/app/models/user.model';
import { IWorkflow } from 'src/app/models/workflow.model';
import { ProjectCloneComponent } from 'src/app/views/prototypes/project/project-clone/project-clone.component';

@Component({
	selector: 'app-project-home',
	templateUrl: './project-home.component.html',
	styleUrls: ['./project-home.component.scss']
})
export class ProjectHomeComponent extends BaseComponent implements OnInit {

	@Input() project : IProject;	
	@Input() workflows : IWorkflow[];
	@Input() isAdmin : boolean;

	@Output() edit : EventEmitter<any> =  new EventEmitter();
	@Output() changeTab : EventEmitter<any> =  new EventEmitter();

	availableUsers: IUser[];
	userAdmins: IUser[];
	projectUsers: IUser[];
	approversTotal: number;
	approvalsTotal: number;
	creativesTotal: number;
	//@Input() availableUsers : IUser[];


	constructor(
		public workgroupService: WorkgroupService,
		public projectService: ProjectService,
		private dialog: MatDialog,
		) {
			super();
		}

	public percentApprovals: number;

	ngOnInit(): void {
		this.sub = this.workgroupService.users.pipe().subscribe((users:IUser[]) =>{
			this.availableUsers = users;
			//console.log("available users", this.availableUsers);
		});

		this.sub = this.projectService.team.pipe().subscribe((team:any) =>{
			if(team){
				//this.channels.sort((a, b) => a.order - b.order);
				this.userAdmins = team.admins;
				this.projectUsers = team.users;
				this.projectUsers.sort((a, b) => a.name.localeCompare(b.name));
				//this.projectUsers.sort((a, b) => a.name - b.name);
			} 
		});

		this.isAdmin

	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.workflows?.currentValue)
		{
			this.getMetrics(changes.workflows.currentValue);
		}

		// Do we need this as it should reload itself no?
		if(changes.project?.currentValue){
			this.workgroupService.loadUsers(changes.project.currentValue.workgroup_uuid);
			//this.projectService.loadTeam(changes.project.currentValue.uuid);
		}

	}

	getMetrics(workflows){
		if(!workflows) return;
		//for each workflow
			//for each creative
				//for each groupInfo.groups
					//+= total
					//+= approvals
		this.approversTotal = 0;
		this.approvalsTotal = 0;
		this.creativesTotal = 0;

		workflows.forEach(workflow =>{
			workflow.creatives?.forEach(creative =>{
				this.creativesTotal ++;
				creative.groupInfo.groups.forEach(group => {
					this.approversTotal += group.total;
					this.approvalsTotal += group.approved;
				})
			})
		})
		//approvals/totals %
		this.percentApprovals = Math.floor(100* this.approvalsTotal/this.approversTotal);
	}

	userIsAdmin(user:IUser){
		return ( this.userAdmins.find(admin => admin.uuid == user.uuid) != undefined);
	}

	copyProject(project:Project, event:MouseEvent = null)
	{
		if(event)event.stopPropagation();
		const dialogRef2 = this.dialog.open(ProjectCloneComponent, {
			data:{project}
		});
		dialogRef2.afterClosed().subscribe((result) => {
			if(result)
			{
				//this.loadProjects();
				//console.log("project copied");
			}
		});
		return;
	}

	editProjectOverview(){
		this.edit.emit({type:"overview", data:this.project});
	}

	setTabIndex(tabIndex){
		this.changeTab.emit({tabIndex});
	}

}
