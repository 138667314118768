import { Component, OnInit } from '@angular/core';
import { PusherService } from 'src/app/services/pusher.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  messages:any[] = [];
  message:string = null;
  constructor(public pusherService:PusherService) { }

  ngOnInit(): void {
    this.pusherService.init("");
    this.pusherService.joinAppChat();

    this.pusherService.appChat.whispers.pipe().subscribe(whisper => {
      //console.log("whisper", whisper); 
      this.messages.push(whisper.whisper);   
    });
  }
  ngOnDestroy(): void {
		this.pusherService.leaveAppChat();		
	}
  handleInput(e)
  {
    //console.log("input",this.message, e);
   // if(this.message.length)
  }
  sendMessage() {
    this.pusherService.appChat.whisper("chat", {message:this.message});
    this.message = null;
  }

}
