<div class="login-container">
	<div class="login">
		<mat-card>
			<app-password-pair [title]="'Reset password'" (done)="resetPassword($event)"></app-password-pair>
			<form *ngIf="false" class="login-form" [formGroup]="resetPasswordForm" autocomplete="off">
				<div class="inputs">
					<p>Reset password</p>
					<mat-form-field>
						<input type="password" id="password1" [type]="hidePassword1 ? 'password' : 'text'" formControlName="password" class="login_input" matInput placeholder="Password" value="">
						<mat-icon matSuffix (click)="hidePassword1 = !hidePassword1">{{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
						<mat-error *ngIf="resetPasswordForm.controls.password.errors?.required">Password is <strong>required</strong></mat-error>
						<mat-error *ngIf="resetPasswordForm.controls.password.errors?.minlength">Password is <strong>too short</strong></mat-error>
					</mat-form-field>
					<mat-form-field>
						<input type="password" id="password2" [type]="hidePassword2 ? 'password' : 'text'" formControlName="confirm" class="login_input" matInput placeholder="Confirm Password" value="">
						<mat-icon matSuffix (click)="hidePassword2 = !hidePassword2">{{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
						<mat-error *ngIf="resetPasswordForm.controls.confirm.errors?.required">Confirm password <strong>required</strong></mat-error>
						<mat-error *ngIf="resetPasswordForm.controls.confirm.errors?.mustMatch">Passwords <strong>do not match</strong></mat-error>
					</mat-form-field>
				</div>
				<div class="button_wrapper">
					<button [disabled]="!resetPasswordForm.valid" (click)="resetPassword()" mat-raised-button>Submit</button>
				</div>
			</form>
		</mat-card>
	</div>
</div>