import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Globals } from '../global';
import { IAccount } from '../models/account.model';
import { Permission } from '../models/permissions.model';
import { IUser, UserRoles } from '../models/user.model';

export class AppUser {
	data:any;
	constructor(input:string | any = null) {
		this.data = typeof input == 'string' ? JSON.parse(input) : input;
	}
	get impersonated()
	{
		return this.data?.impersonated;
	}
	get uuid()
	{
		return this.data?.uuid;
	}
	get email()
	{
		return this.data?.email;
	}
	get name()
	{
		return this.data?.name;
	}
  get rolez()
	{
		return this.data?.rolez;
	}
	/*
	get admin():boolean
	{
		return (this.data?.rolez & UserRoles.ADMIN) == UserRoles.ADMIN;
	}*/
	get super():boolean
	{
		return (this.data?.rolez & UserRoles.SUPER) == UserRoles.SUPER;
	}
	/*
	get adminOrSuper():boolean
	{
		return (this.data?.rolez & (UserRoles.ADMIN | UserRoles.SUPER)) != 0;
	}*/
	get accounts():IAccount[]
	{
		return this.data?.accounts;
	}
}

let API_URL = Globals.BASE_API_URL;

@Injectable({
  providedIn: 'root'
})
export class AppUserService {


	auth = new Subject<boolean>();

	private account = new BehaviorSubject<IAccount>(null);
	account$ = this.account.asObservable();

	private accountAdmin = new BehaviorSubject<boolean>(false);
	accountAdmin$ = this.accountAdmin.asObservable();

	private appSuper = new BehaviorSubject<boolean>(false);
	appSuper$ = this.appSuper.asObservable();

	private appSuperOrAccountAdmin = new BehaviorSubject<boolean>(false);
	appSuperOrAccountAdmin$ = this.appSuperOrAccountAdmin.asObservable();
	
  	// new user flow
	appUser:AppUser;
	appUserSubject:ReplaySubject<AppUser> = new ReplaySubject<AppUser>(1);
	appUserObservable$:Observable<AppUser>= this.appUserSubject.asObservable();

  constructor(private http: HttpClient)
  {
	this.accountAdmin$.pipe().subscribe(accountAdmin =>
	{
		let superOrAdmin = accountAdmin || this.appSuper.value;
		if(superOrAdmin != this.appSuperOrAccountAdmin.value)
		{
			this.appSuperOrAccountAdmin.next(superOrAdmin);
		}
	});
	this.appSuper$.pipe().subscribe(appSuper =>
	{
		let superOrAdmin = this.accountAdmin.value || appSuper;
		if(superOrAdmin != this.appSuperOrAccountAdmin.value)
		{
			this.appSuperOrAccountAdmin.next(superOrAdmin);
		}
	});
  }
  	getAuth()
	{
		return new Observable<boolean>((observer) => {
			//console.log("can activeate- get auth", this.appUser);
			
			if(this.appUser)
			{
				observer.next(true);
				observer.complete();
			}else {
				this.appUserObservable$.pipe(take(1)).subscribe(res => 
					{
						//.log("ativate on auth on sub", res);
						
						observer.next(res ? true : false);
						observer.complete();
					}, err => {
						observer.next(false);
						observer.complete();
					});
				this.getAppUser();
				/*
				this.http.get(API_URL+'user/me').subscribe(
					(res:any) => {
						console.log("get auth success");
						observer.next(true);
						observer.complete();
						
					},
					err => {
						console.log("get auth fail");
						observer.next(false);
						observer.complete();
					}
				);*/
			}
		  });
		/*
		console.log("get auth");
		let authObservable = this.auth.asObservable();
		this.http.get(API_URL+'user/me').subscribe(
			(res:any) => {
				console.log("get auth success");
				this.auth.next(true);
				this.auth.complete();
				
			},
			err => {
				console.log("get auth fail");
				this.auth.next(false);
				this.auth.complete();
			}
		);
		authObservable.subscribe(res => {
			console.log("BOOM", res);
		})
		return authObservable;*/
	}
	getAppUser()
	{
		//console.log("get app user");
		
		this.http.get(API_URL+'user/me').subscribe(
			(res:any) => {
				//console.log("get app user - success");
				if(res?.data)
				{
					this.appUser = new AppUser(res.data[0]);
				} else {
					this.appUser = null;
				}

				this.setAccount(localStorage.getItem("account_uuid"));
				this.appUserSubject.next(this.appUser);				
				this.appSuper.next(this.appUser ? (this.appUser.rolez & UserRoles.SUPER) == UserRoles.SUPER : false);
			},
			err => {
				console.log("get app user - fail");
				this.appUser = null;
				this.appUserSubject.next(this.appUser);
				//this.appUserSubject.error(err);
			}
		);
	}
	setAccount(account_uuid: string) {
		if(!account_uuid)
		{
			if(!this.appUser) return;
			account_uuid = this.appUser.accounts[0]?.uuid || null;
		}
		if(account_uuid)
		{
			localStorage.setItem("account_uuid", account_uuid);
			if(!this.appUser) return;
			let account = this.appUser.accounts.find(account => account.uuid == account_uuid);
			if(!account) account = this.appUser.accounts[0] ?? null;
			//console.log("app user service set account", account);
			
			this.account.next(account);
			if(account)
			{
				this.accountAdmin.next( (account.permissions & Permission.ADMIN) ==Permission.ADMIN );
			}else {
				this.accountAdmin.next(false);
			}
		}		
	}
  /*
  public uuid()
  {
    return this._userSubject.value?.uuid;
  }
  public hasRole(role:number):boolean
  {
    if(!this._userSubject.value) return false;
    return (this._userSubject.value.rolez & role) === role;
  }
  public hasAnyRole(role:number):boolean
  {
    if(!this._userSubject.value) return false;
    return (this._userSubject.value.rolez & role) !== 0;
  }

  // is logged in user a system admin
  public isAdmin()
  {
    if(!this._userSubject.value) return false;
    return (this._userSubject.value.rolez & UserRoles.ADMIN) === UserRoles.ADMIN;
  }
  // is logged in user a system super
  public isSuper()
  {
    if(!this._userSubject.value) return false;
    return (this._userSubject.value.rolez & UserRoles.SUPER) === UserRoles.SUPER;
  }
  public isArchitect()
  {
    if(!this._userSubject.value) return false;
    return (this._userSubject.value.rolez & UserRoles.ARCHITECT) === UserRoles.ARCHITECT;
  }
  public isAny()
  {
    return this.hasAnyRole(UserRoles.ALL);
  }
  */
  message(data:any)
  {
	  return this.http.post(API_URL+'user/message', data);
  }
}
