import { ChangeDetectorRef, Component, Directive, ElementRef, HostListener, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { CreativeService } from 'src/app/api/creative.service';
import { ProjectService } from 'src/app/api/project.service';
import { Project2Service } from 'src/app/api/project2.service';
import { TasksService } from 'src/app/api/task.service';
import { AssetService } from 'src/app/api/assets.service';
/**
 * models
 */
import { IProject } from 'src/app/models/project.model';
import { ApiResponse } from 'src/app/api/OvBaseService';
import Echo from 'node_modules/laravel-echo/dist/echo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ITask, Task, TaskFlag, TaskType, TaskState, TaskRole } from 'src/app/models/task.model';
import { TaskCaptureEvent, TaskComponent, TaskEvent } from '../tasks/task/task.component';
import { Asset, AssetVO, IAsset } from 'src/app/models/asset.model';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { AssetsComponent } from '../assets/assets.component';
import { DraggableDirective, DraggableEvent } from 'src/app/directives/draggable.directive';
import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { Creative, CreativeState, ICreative } from 'src/app/models/creative.model';
import { Marker, MarkerOption, MarkerVO } from 'src/app/models/marker.model';
import { dateToAge } from 'src/app/utils-date';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';
import { DndResult } from 'src/app/directives/dnd.directive';
import { MatDialog } from '@angular/material/dialog';
import { CopyCreativeDialogComponent, CopyCreativeDialogData } from 'src/app/dialogs/copy-creative-dialog/copy-creative-dialog.component';
import { IUser } from 'src/app/models/user.model';
import { AppUser, AppUserService } from 'src/app/services/app-user.service';
import { Permission } from 'src/app/models/permissions.model';
import { PusherService } from 'src/app/services/pusher.service';
import { ApprovalChain } from 'src/app/models/ApprovalChain.model';
import { IChannel } from 'src/app/models/channel.model';
import { IFormat } from 'src/app/models/format.model';
import { FlagComponent } from 'src/app/components/widgets/flag/flag.component';
import { AssetComponent } from '../assets/asset/asset.component';
import { IWorkflow, Workflow } from 'src/app/models/workflow.model';
import { WorkflowService } from 'src/app/api/workflow.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Point } from 'src/app/utils/Point';
import { GrowingPacker } from 'src/app/utils/packer';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ColorEvent } from 'ngx-color';
import { CreativeViewerComponent } from '../../creative/creative-viewer/creative-viewer.component';
import { IUpload, UploadState, UploadsService } from 'src/app/api/uploads.service';
import { ModelUtils } from 'src/app/utils/ModelUtils';
import { clamp, round } from 'src/app/utils/MathUtils';
import { DialogService } from 'src/app/services/dialog.service';

import {Subscription, timer, fromEvent} from 'rxjs'; 
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { ProjectWorkflowsComponent } from '../../project-workflows/project-workflows.component';
import { CreativeApprovalGroupsComponent } from 'src/app/components/widgets/creative-approval-groups/creative-approval-groups.component';
import { AssetMetadataService } from 'src/app/services/assetMetadata.service';
import { IMessage } from 'src/app/models/message';
import { AssetsComponentService } from '../assets/assetsComponent.service';
import BoolMap from 'src/app/utils/BoolMap';
import { CreativeDiscussionService, IChatMessage } from './creativeDiscussion.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
// https://stackoverflow.com/questions/25993861/how-do-i-get-typescript-to-stop-complaining-about-functions-it-doesnt-know-abou
declare global {
	interface Document {
		webkitExitFullscreen: () => void;
		msExitFullscreen:() => void;
	}
}
/*
@Directive({selector: 'asset'})
export class AssetWrapper {
}*/

enum CreativeMenu {
	Amends,
	Assets,
	Settings,
	Info,
	Discussion,
	Log,
	Help
}
enum CreativeShow {
	Markup,
	Connections,
	Annotations,
	Metadata,
}

/**
 * Creative api flow
 * - load creative
 * in response it then calls
 * - user permissions
 * - creative tasks
 * - chain (info panel - could be deferred)
 * - production users (should only do this if admin I guess - maybe)
 * - get all creatives (for the mini grid - could defere this until panel is opened)
 */

@Component({
	selector: 'app-creative',
	templateUrl: './creative.component.html',
	styleUrls: ['./creative.component.scss']
})
export class CreativeComponent extends BaseComponent implements OnInit, OnChanges, CanComponentDeactivate {

	@ViewChild('assetsManager') assetsManager : AssetsComponent;
	@ViewChild('draggable') draggable : DraggableDirective;
	@ViewChildren('actionBar') actionBar : QueryList<ElementRef<HTMLDivElement>>;
	@ViewChildren(TaskComponent) taskList!: QueryList<TaskComponent>;

	private _unsubscribe = new Subject<boolean>();

	public creative_uuid:string;

	public creativeSearch:string = '';
	public isOpen = false;
	public user:AppUser;
	public permissions:{project:number, workflow:number, channel:number};
	public userPermissions;
	public userRole;


	public creative:ICreative;
	public creativeLog:any[];
	public versions: number[] = [];

	// view bits
	public layout : {name:string, icon:string, tooltip:string};	
	public galleryIndex:number = 0;
	public zoom = 1;
	public showMap:BoolMap<CreativeShow> = new BoolMap();
	public CreativeShow = CreativeShow;	// expose enum to html
	public bg_color: string;
	public bg_color_default: string = "#FFFFFF";
	public bg_transparent: boolean;

	
	// assets
	private  _assets:AssetVO[] = [];
	public set assets(assets:AssetVO[]) {
		this._assets = assets;
		// assets changed might need to check selected asset still matches
		if(this.assets && this.selectedAsset)
		{
			let index = this.assets.findIndex(assetVO => this._selectedAsset.asset.uuid ? assetVO.asset.uuid == this._selectedAsset.asset.uuid : assetVO.asset.id == this._selectedAsset.asset.id);
			if(index != -1){
				this.selectAsset(this.assets[index]);
			} else {
				this.selectAsset(null);
			}
		}
		if(this.layout?.name == "gallery")
		{
			// set gallery index to highest.. maybe - maybe not
			// gallery must show something but it doesnt need to be selected per say
			this.galleryIndex = this.assets?.length ? Math.min(this.galleryIndex, this.assets.length - 1) : 0;
		}
	}
	public get assets():AssetVO[]{
		return this._assets;
	}
	private _selectedAsset:AssetVO;
	public get selectedAsset():AssetVO { return this._selectedAsset};
	public set selectedAsset(selectedAsset:AssetVO) { 
		this._selectedAsset = selectedAsset;
		// updates
		// update galleryIndex if required
		if(this.layout?.name == "gallery")
		{
			if(this._selectedAsset)
			{
				// find the selected asset index
				let index = this.assets.findIndex(assetVO => this._selectedAsset.asset.uuid ? assetVO.asset.uuid == this._selectedAsset.asset.uuid : assetVO.asset.id == this._selectedAsset.asset.id);
				if(index != -1)	this.galleryIndex = index;
				else this.galleryIndex = 0;
			} else {
				// null selected, but probably don't want to clear the gallery index
				// set it to highest value of current and num assets perhaps
				this.galleryIndex = this.assets?.length ? Math.min(this.galleryIndex, this.assets.length -1) : 0;
			}
		}
	};

	public tasks:Task[];
	public taskSearch:string = '';
	private errorMessage;

	// markerVOs
	public markerVOs:MarkerVO[] = null;
	private markerVOsSubject:BehaviorSubject<MarkerVO[]> = new BehaviorSubject<MarkerVO[]>(this.markerVOs);
	public markerVOs$:Observable<MarkerVO[]> = this.markerVOsSubject.asObservable();

	// users associated with this creative
	public users;
	public selectedUser;
	public selectedUserPermissions;
	public creativeUsers;
	public userActions:any[];
	public userAction:any;

	public showMarkup:boolean = true;
	public showClosed:boolean = true;
	public showOtherTasks:boolean = true;

	public taskEvents:any[] = [];
	private taskEventTimer:any;

	public chain:ApprovalChain;
	public workflow:IWorkflow;		// the creatives workflow
	public channel:string;			// the creative's channel
	public projectChannel:string;	// the creative's project channel

	public taskSummary: string;

	@ViewChild('mainContent') mainContent;
	@ViewChildren('markerLines') markerLines:QueryList<ElementRef<HTMLDivElement>>;
	@ViewChildren('assetStage') assetStage:QueryList<ElementRef<HTMLDivElement>>;
	@ViewChildren('taskContainer') taskContainer:QueryList<ElementRef<HTMLDivElement>>;

	//@ViewChildren(AssetComponent) assetElementList!: QueryList<AssetComponent>;// now in viewer
	@ViewChildren('left') left:QueryList<ElementRef<HTMLElement>>;
	@ViewChild('tabGroup') tabGroup:MatTabGroup;
	selectedTabIndex:number = 0;
	@ViewChild('creativeViewer') creativeViewer:CreativeViewerComponent;


	menuTopLeftPosition =  {x: 0, y: 0}
	@ViewChildren(MatMenuTrigger) matMenuTrigger: QueryList<MatMenuTrigger>; // , {static:true}
	@ViewChild('trigger') matMenu: MatMenuTrigger; // , {static:true}
	@ViewChild('triggerRightMenu') triggerRightMenu: MatMenuTrigger; // , {static:true}
	@ViewChild('rightMenu')rightMenu: any; // , {static:true}

	//@ViewChild('trigger') matMenuTrigger: MatMenuTrigger;

	layouts:{name:string, icon:string, tooltip:string}[] = [
		{name:'custom',			icon:'control_camera',			tooltip:"Custom"},//dashboard open_with
		{name:'flow',			icon:'dashboard',				tooltip:"Flow"},//view_compact
		{name:'vertical left',	icon:'align_horizontal_left',	tooltip:"Vertical Left"},
		{name:'vertical-auto',	icon:'vertical_distribute',		tooltip:"Vertical"},
		//{name:'vertical',		icon:'align_horizontal_center',	tooltip:"Vertical Center"},
		{name:'horizontal',		icon:'horizontal_distribute',	tooltip:"Horizontal"},
		{name:'gallery',		icon:'view_carousel',			tooltip:"Gallery view"},
		{name:'fullscreen',		icon:'fullscreen',				tooltip:"Fullscreen"},
	];
	private defaultLayoutName: string = 'custom';

	private _tasksDisabled: boolean;	
	public taskSummaryVO: { mySummary: any[]; myCount: number; };
	public productionTask: Task;e
	public taskButtons: any[];
	public assetsSaving: number = 0;
	public loadingTasks: boolean;
	public saving: boolean = false;
	public loadingCreative: boolean;

	public inFullscreenMode: boolean = false;
	public showSaveBg: boolean = false;
	public showSaveBgCol: boolean = false;
	
	maxViewableVersion: number;
	numAmendTasks: number;
	actionableAmendTasks: {total:number, totalActioned:number, totalUnactioned:number, messages:string[]};	// non-main tasks that require attention
	
	
	
	public get tasksDisabled() : boolean {
		return this._tasksDisabled;
	}
	public set tasksDisabled(value:boolean) {
		//console.log("tasksDisabled set", value);
		this._tasksDisabled = value;
	}
	tasksDisabledCanChange: boolean;

	private _listening:boolean;

	public panelOpen:boolean = false;
	public project:IProject;
	public creatives:ICreative[];
	public formats:IFormat[];
	public channels:IChannel[];
	//public selectedChannel:IChannel;

	public version:number;
	public isLatestVersion:boolean;
	private latestVersion:number;

	connections = [];
	overTask:ITask;
	userMarkerOffset:boolean = false;
	/* task/asset marker code */
	@ViewChild('markerContainer') markerContainer: ElementRef;
	private markerTask:Task;
	public marker:Marker;
	private mouseMoveRef:any;
	private mouseUpRef:any;
	//https://dev.to/angular/ain-t-nobody-needs-hostlistener-fg4
	public offsetX = 0;
	public offsetY = 0;
	selectedTask:ITask;
	public assetEditMode : boolean = false;
	@ViewChild('assetsContainer') assetsContainer;
	
	assetDebounce;

	/*

	                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='custom', selectedLayout:layout=='custom'}" matTooltip="Custom position" (click)="layout='custom'"><mat-icon>dashboard</mat-icon></button>
                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='flow', selectedLayout:layout=='flow'}" matTooltip="Flow" (click)="layout='flow'"><mat-icon>view_compact</mat-icon></button>
                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='vertical left', selectedLayout:layout=='vertical left'}" matTooltip="Vertical Left" (click)="layout='vertical left'"><mat-icon>align_horizontal_left</mat-icon></button>
                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='vertical', selectedLayout:layout=='vertical'}" matTooltip="Vertical Center" (click)="layout='vertical'"><mat-icon>align_horizontal_center</mat-icon></button>
                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='horizontal', selectedLayout:layout=='horizontal'}" matTooltip="Horizontal" (click)="layout='horizontal'"><mat-icon>horizontal_distribute</mat-icon></button>
                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='vertical-auto', selectedLayout:layout=='vertical-auto'}" matTooltip="Vertical" (click)="layout='vertical-auto'"><mat-icon>vertical_distribute</mat-icon></button>
                            <button mat-icon-button [ngClass]="{currentLayout:layout==creative.layout, unselectedLayout:layout!='gallery', selectedLayout:layout=='gallery'}" matTooltip="Gallery view" (click)="layout='gallery'"><mat-icon>view_carousel</mat-icon></button>
							*/


	constructor(
		public assetsComponentService:AssetsComponentService,
		public creativeDiscussionService:CreativeDiscussionService,
		private _snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private router:Router,
		private project2Service: Project2Service,
		private workflowService: WorkflowService,
		private api:ApiService,
		private assetsService:AssetService,
		private tasksService:TasksService,
		public projectService: ProjectService,
		public creativeService: CreativeService,
		public appUserService:AppUserService,
		public pusherService:PusherService,
		private dialog: MatDialog,
		private uploadService:UploadsService,
		public cdr: ChangeDetectorRef,
		private ds:DialogService,
		private assetMetadataService:AssetMetadataService,
		private lss: LocalStorageService,
		private fb: FormBuilder,
	)
	{
		super();
		// show markers and connectors by default
		this.showMap.set(CreativeShow.Markup, true);
		this.showMap.set(CreativeShow.Connections, true);

		this.pusherService.init(this.api.token);

		this.sub = this.assetMetadataService.assetMeta.pipe().subscribe(assetVO => {
			let target = this.assets?.find(asset => asset == assetVO);
			if(target){
				this.creativeViewer.triggerResize();
			}
		})
		/*
		let bob = new User("bob", "bob@bob.com");
		bob.name = "bill";
		console.log(bob.isDirty());
		bob.setSource(bob.clone());
		console.log(bob.isDirty());
		bob.name = "bob";
		console.log(bob.isDirty());
		bob.save(amendsService).pipe(takeUntil(this._unsubscribe)).subscribe(
			result => {
				console.log("saved", result);
			},
			error => this.errorMessage = <any>error
		);*/

		/*
		let amend = new Amend();
		amend.request = "hi from angular " + Math.random();
		amend.save(amendsService).pipe(takeUntil(this._unsubscribe)).subscribe(
			result => {
				console.log("saved", result);
			},
			error => this.errorMessage = <any>error
		);*/

		// TODO @benw carry on this sexy work, interfaces vs casing (tricky with deep objects).. hmmmm
		/*
		this.project2Service.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				const projs:Project2[] = response.data as Project2[];
				console.log("BINGO BANGL", Project2.hello(projs[0]))
			},
			error => this.errorMessage = <any>error
		);*/
	}

	tempHideMarkup()
	{
		this.showMap.set(CreativeShow.Markup, false);
		this.showMap.set(CreativeShow.Connections, false);
		this.sub = fromEvent(window, "mouseup").subscribe(event => {
			this.showMap.set(CreativeShow.Markup, true);
			this.showMap.set(CreativeShow.Connections, true);
		});
	}

	@HostListener("window:beforeunload", ["event"])
	unloadHandler(event: WindowEventHandlers): boolean {
	  return this.canDeactivate(null, null) as boolean;
	}
	getDirtyAssetCount():number {
		if(this.assets)
		{
			return this.assets.filter(assetVO => (assetVO.file && !this.isAssetUploading(assetVO)) || assetVO.isDirty() || (assetVO.asset?.id<0)).length;
		}
		return 0;
	}
	// return number of assets that are being analysed or processed
	getProcessingAssets()
	{
		if(this.assets)
		{
			const FLAG_UNZIPPING    	= 1 << 4;	// 16
			const FLAG_PROCESSING    	= 1 << 5;	// 32
			const mask = FLAG_UNZIPPING;// | FLAG_PROCESSING	// processing no longer a blocker
			return this.assets.filter(assetVO => (assetVO.asset) && (assetVO.asset.flag != null) && (assetVO.asset.flag & mask)).length;
		}
		return 0;
	}
	isAssetUploading(assetVO:AssetVO):IUpload|null
	{
		return this.activeUploads?.find(upload => upload.data?.asset == assetVO.asset?.uuid)
	}
	/**
	 * TODO collect all messages and return at end
	 * If multiple messages use generic language, if 1 use specific
	 * @param currentState 
	 * @param nextState 
	 * @returns 
	 */
	canDeactivate(currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | boolean
	{
		let shouldLeaveChats = true;
		
			
			if(currentState && nextState)
			{
				// this could be a lot better
				//console.log("can deactiveate", nextState.url.indexOf('creative') !== -1);
				
				if(nextState.url.indexOf('creative') !== -1)
				{
					shouldLeaveChats = false;
				}
			}
			// check if there are any unsaved asset changes
			let dirtyAssets = this.getDirtyAssetCount();
			if(dirtyAssets)
			{
				//return window.confirm(`There are ${dirtyAssets} unsaved assets, are you sure you want to leave`);
				return this.deactivateWarning(`There are ${dirtyAssets} unsaved assets!`, "Are you sure you want to leave without saving?", "saving assets");
			}
	
			// task items
			if(this.tasks)
			{
				// check for any new or unsaved tasks (with changes)
				let editable = this.tasks.filter(task => {
					if(task.type != TaskType.AMEND) return false;
					let comp = this.taskList.find(comp => comp.task == task);
					if(!comp) return false;
					return task.state == TaskState.AMEND_LOCAL ? comp.editable : comp.editable && comp.canSave();
				});
				if(editable.length)
				{
					//return window.confirm(`You have ${editable.length} unsaved amends!  \n\nAre you sure you want to leave without saving?`)
					return this.deactivateWarning(`You have ${editable.length} unsaved amends!`, "Are you sure you want to leave without saving?", "saving amends");
				}

				let unactioned = this.tasks.filter(task => {
					if(task.type != TaskType.AMEND || !task.open) return false;
					//approver
					if(!task.actioned){
						if(this.isApprover() 
							&& task.user_uuid == this.appUserService.appUser.uuid
							&& task.role == TaskRole.AMEND_ROLE_APPROVER
						) return true;
						if(this.isProduction() 
							&& task.role == TaskRole.AMEND_ROLE_PRODUCTION
						) return true;
						if(this.isAmendManager() 
							&& task.role == TaskRole.AMEND_ROLE_MODERATOR
						) return true;
					}
				});

				// if no unactioned - check for any unsubmitted tasks
				let unsubmitted = []
				if(unactioned.length == 0){
					unsubmitted = this.tasks.filter(task => {
						if(task.type != TaskType.AMEND || !task.open || !task.actioned ) return false;
						//approver
						if(this.isApprover() 
							&& task.user_uuid == this.appUserService.appUser.uuid
							&& task.role == TaskRole.AMEND_ROLE_APPROVER
							&& task.state != TaskState.AMEND_DONE //ignore completed, confirmed amends
						) return true;
						if(this.isProduction() 
							&& task.role == TaskRole.AMEND_ROLE_PRODUCTION
						) return true;
						if(this.isAmendManager() 
							&& task.role == TaskRole.AMEND_ROLE_MODERATOR
						) return true;
						/*return task.type == TaskType.AMEND 
						&& task.state == TaskState.AMEND_LOCAL
						&& task.user_uuid == this.appUserService.appUser.uuid;
						*/
					});
				} else {
					unsubmitted = this.tasks.filter(task => {
						if(task.type != TaskType.AMEND || !task.open || !task.actioned ) return false;
						//approver
						if(this.isApprover() 
							&& task.user_uuid == this.appUserService.appUser.uuid
							&& task.role == TaskRole.AMEND_ROLE_APPROVER
							&& task.state == TaskState.AMEND_TODO //always check for new approver amends
						) return true;
						if(this.isAmendManager() 
							&& task.user_uuid == this.appUserService.appUser.uuid
							&& task.role == TaskRole.AMEND_ROLE_MODERATOR
							&& task.state == TaskState.AMEND_TODO //always check for new moderator amends
						) return true;
					});
				}

				if(unsubmitted.length)
				{
					//return window.confirm(`You have ${unsubmitted.length} unsubmitted amends!  \n\nAre you sure you want to leave without submitting them?`)
					let unactionedMsg = (unactioned.length == 0) ? '' : ' (You may have other amend actions to complete first)';
					return this.deactivateWarning(`You have ${unsubmitted.length} unsubmitted amends!${unactionedMsg}.`, "Are you sure you want to leave without submitting them?", "submitting amends");
				}
			}
	
			
			// check if layout needs saving (disabled for now)
			if(false && this.canShowSaveBg()){// && this.isProductionState()){
				//return window.confirm(`You have unsaved layout changes. \n\nAre you sure you want to leave without saving?`)
				return this.deactivateWarning("You have unsaved layout changes", "Are you sure you want to leave without saving?", "saving layout");
			}	
			//check if bg colour needs saving
			if(this.canShowSaveBgCol()){
				//return window.confirm(`You have unsaved background colour changes. \n\nAre you sure you want to leave without saving?`)
				return this.deactivateWarning(`You have unsaved background colour changes!`, "Are you sure you want to leave without saving?", "saving changes");
			}		
		
		//this.ngOnDestroy(); // this causes so many issues :S
		if(shouldLeaveChats) this.leaveChats();
		return true;
	}
	deactivateWarning(subtitle:string, body:string, btnMsgSuffix:string = "saving"):Observable<boolean>
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Leaving so soon?",
				subtitle,
				body,
				positive:"Continue without "+btnMsgSuffix+".",
				negative:"Stay",
			}
		  });
		  let subject:Subject<boolean> = new Subject();
		  let obs = subject.asObservable();
		  dialogRef.afterClosed().subscribe((result: any) => {
			subject.next(result ? true : false);
		});
		return obs;
	}
	timerSubscription: Subscription;
	activeUploads:IUpload[];
	ngOnInit(): void {

		//console.log("Amends::getUser TODO improve this workflow");
		this.appUserService.appUserSubject.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.user = res;
			/*
			this.creativeService.getUserRole(this.creative.uuid, this.user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				console.log("USER ROLE", res);

			});*/
		});

		// load a new creative based on the uuid
		this.route.params.subscribe(params => {

			this.creative_uuid = params["uuid"];

			// cleanup between creatives
			if(this.selectedAsset)	this.selectedAsset = null;
			if(this.tasks)			this.tasks.length = 0;
			if(this.assets)			this.assets.length = 0;
			if(this.creativeLog)	this.creativeLog = null;

			// reset version info between creatives
			this.version = this.latestVersion = -1;
			this.isLatestVersion = true;

			this.loadCreative();
			
		});

		this.timerSubscription = timer(0, 10000).pipe( 
			takeUntil(this._unsubscribe)
		  ).subscribe(() => {
			  // ping creative uuid to project
			  if(this.creative_uuid && this.pusherService.projectChat)
			  {
				this.pusherService.projectChat.whisper('creative', {message:this.creative_uuid, extra:'timer '+this._unsubscribe.closed+","+this._unsubscribe.isStopped});
			  }
		  }); 

		// set up listeners for new creative
		this.creativeSubscriptions();



		// https://pusher.com/docs/channels/getting_started/javascript
		/*
		// @ts-ignore
		var pusher = new window.Pusher('testapp', {
			cluster: 'mt1'
		  });
		  var channel = pusher.subscribe('events');
		  channel.bind('my-event', function(data) {
			alert('An event was triggered with message: ' + data.message);
		  });*/
		  return;
	// @ts-ignore
		  window.Pusher = require('pusher-js');
		  console.log("TOKEM", this.api.token);
		  // @ts-ignore
			window.Echo = new Echo({
				broadcaster: 'pusher',
				key: "websocketkey",//process.env.MIX_PUSHER_APP_KEY,
				cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
				forceTLS: false,
				wsHost: window.location.hostname,
				wsPort: 8443,
				auth: {
					headers: {
						//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
						Authorization: 'Bearer ' + this.api.token,// BEARER_TOKEN,
					},
				},
			});
		  // @ts-ignore
		  window.Echo.channel('events').listen('RealTimeMessage', (e) =>{
			  this._snackBar.open(e.message, null, {duration: 2000});
		  });
		  // @ts-ignore
		  window.Echo.private('events').listen('RealTimeMessage', (e) =>{
			this._snackBar.open("private: " + e.message, null, {duration: 2000});
		});
	}
	/*
	leftLeaveListenerAdded = false;
	ngAfterViewChecked()
	{
		if(this.left && !this.leftLeaveListenerAdded)
		{
			this.leftLeaveListenerAdded = true;
			this.left.nativeElement.addEventListener("mouseleave", () => {
				this.panelOpen = false;
				this.leftLeaveListenerAdded = false;
			});
		}
	}*/
	ngAfterViewInit()
	{
		/*
		this.left.changes.subscribe(r => {
			setTimeout(() => this.updateMarkerConnections(), 100);
			//this.updateMarkerConnections()
		});*/



		//console.log("creative ::> ngAfterViewInit assetStage", this.assetStage);
		//console.log("creative ::> ngAfterViewInit taskContainer", this.taskContainer);
		this.taskContainer.changes.subscribe((r) => {
			if(!r.first) return;
			// todo just get a reference to this element?
			// TODO - could we not just use `(scroll)="onAssetStageScroll($event)"
			r.first.nativeElement.addEventListener('scroll', (e) => {
				// update marker connections of DOOOM
				// https://stackoverflow.com/questions/66269273/how-to-detect-the-scroll-event-inside-angular-material-tabs-and-how-to-scroll-ba
				//this.cdr.markForCheck();				
				this.updateMarkerConnections();
			});
			
		});
		this.taskList.changes.subscribe((r) => {
			this.updateMarkerConnections(true);
		});
			


		this.assetStage.changes.subscribe((r) => {
			r.first.nativeElement.addEventListener('scroll', (e) => {
				// update marker connections of DOOOM
				this.updateMarkerConnections();
				//this.cdr.markForCheck();
			});

			let observeForLayoutChanges = true;
			if(observeForLayoutChanges)
			{
				
				let observer = new MutationObserver(mutations => {
					mutations.forEach((mutation) => {
					  if(mutation.attributeName == "ng-reflect-layout")
					  {
						this.updateMarkerConnections();
					  }
					});   
				  });
				  var config = { attributes: true, childList: true, characterData: true };		  
				  observer.observe(r.first.nativeElement.firstElementChild, config);
				  // TODO cleanup
				  // https://stackoverflow.com/questions/36130393/angular2-directive-how-to-detect-dom-changes
			}


			r.first.nativeElement.addEventListener('contextmenu', (e) => {
				//console.log("contextmenu t", e);
				this.onRightClick(e);
			  },true);
			  r.first.nativeElement.addEventListener('contextmenu', (e) => {
				//console.log("contextmenu f", e);
				this.onRightClick(e);
			  },false);
		  });
		  
		  /*
		this.assetStage.nativeElement.addEventListener('contextmenu', (e) => {
			console.log("contextmenu t", e);
			this.onRightClick(e);
		  },true);
		  this.assetStage.nativeElement.addEventListener('contextmenu', (e) => {
			console.log("contextmenu f", e);
			this.onRightClick(e);
		  },false);*/

	
		  this.actionBar.changes.subscribe((r) => {
			if(this.actionBar.first) {
				this.positionActionBar()
			}
		  });
		  if(this.actionBar.first) {
			this.positionActionBar()
		}
	}
	positionActionBar()
	{
		console.log("POSITION ACTION BAR");
		
		const actionBar = this.actionBar.first.nativeElement;
		// check for saved position
		var actionbarstyle;
		if(actionbarstyle = localStorage.getItem("actionbar"))
		{
			let style = JSON.parse(actionbarstyle);
			Object.assign(actionBar.style, style);
		} else{
			actionBar.style.top = "0";
			actionBar.style.left = "50%";
			actionBar.style.transform = "translateX(-50%)";
		}
		this.updateActionBarBorders(actionBar);
	}
	toPercentString(value:number, multuplier:number = 1, round:boolean = false)
	{
		value *= multuplier;
		if(round) value = Math.round(value);
		return value + "%";
	}
	onMarkerlinesResize(e:ResizeObserverEntry)
	{
		this.updateMarkerConnections();		
	}
	ngOnChanges(changes:SimpleChanges)
	{
		//console.log("changes", changes)
		if(changes.layout)
		{
			this.updateMarkerConnections();
		}
	}
	ngOnDestroy() {

		super.ngOnDestroy();
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
		this.leaveChats();
		// TODO better debouce stratagy that just works across the app
		if(this.assetDebounce) clearTimeout(this.assetDebounce);
	}
	/*ngAfterViewInit(){}*/
	// called once
	creativeSubscriptions()
	{
		// listen to assets
		this.sub = this.creativeService.assetsObservable$.subscribe(
			(assetsIn) => {
				//console.log("creative assets loaded");				
				// make vos
				let assets = assetsIn.map(asset => new AssetVO(asset));
				// cachebust but only in production states
				if(this.isProduction() && this.isProductionState()) assets.forEach(assetVO => assetVO.updateCacheBust());

				assets.sort((a, b) => a.asset.order - b.asset.order);
				// clamp gallery index if assets list changed
				if(this.galleryIndex) this.galleryIndex = assets.length ? Math.min(assets.length - 1, this.galleryIndex) : 0;
				
				/*
					let diffs = [];
					console.log("diff", this.assets[0].isDirty(diffs), diffs);
					this.assets[0].asset.name = "adam";
					console.log("diff", this.assets[0].isDirty(diffs), diffs);
					this.assets[0].revert();
					console.log("diff", this.assets[0].isDirty(diffs), diffs);
				*/				
				//this.assets.forEach(asset => Asset.createReferece(asset))   // this will create a reference to the original that gets passed in
				this.assetsComponentService.assets = assets;
			}
		);
		this.sub = this.assetsComponentService.assets$.subscribe(assets => {
			this.assets = assets;

			/* we have a better way of doing this now - not necessary for every time assets are loaded
			// open assets panel if required
			if(this.assets?.length && !this.creativePanels.has(CreativeMenu.Assets))
			{
				if(this.isProductionState())
				{
					this.creativePanels.set(CreativeMenu.Assets, true);
				}
				// should we open the amends panel if there are amends and we are in an amending state?
			}
				*/
		})
		this.sub = this.assetsComponentService.selectedAssets$.subscribe(asset => {
			this.selectedAsset = asset;
		})
		// listen to tasks
		this.sub = this.tasksService.tasksObservable$.subscribe(
			(tasks) => {
				//console.log("tasks observable changed...", tasks?.length);
				
				// grab any local tasks
				let localTask = this.tasks?.find(t => t.state == 'local');

				let myApprovalTask =  tasks?.find(t => t.type == 'approval' && t.open && t.user_uuid == this.appUserService.appUser.uuid);

				//store the production task in case it is filteted out by user role below. Gets passed to info panel
				this.creative.production_task = tasks?.find(t => t.type == 'production');


				//console.log("productionTask",this.productionTask);
				// filter tasks based on my role + creative state
				/**
				 * Rules:
				 * - amend tasks (every one) - always show 
				 * - production tasks (production only) - always show for production state
				 * - approval tasks (approvers only)
				 * 		- if only approver - once open always show (can request an amend at any time now) 
				 * 		- if also amend manager - dont show in qualifying or production
				 * - moderator tasks (moderators only)
				 * 		- if only moderator - always show
				 * 		- if approver also only show in production and qualifying or in any approval state not mine
				 * - future issue:
				 * 		hide mod task in production if no other users amends need qualifying - maybe
				 */

				//creative states
				let isProductionstate = this.isProductionState();
				let isModeratorState = this.isAmendManagerState();
				let isApprovalState = this.isApprovalState();



				tasks = tasks.filter(task => {
					if(task.type == "amend") return true;
					if(task.type == 'production') return (this.isProduction());//isProductionstate && 
					if(task.type == 'moderation') {
						return true; //always show mod task if have it
						if(!this.isApprover()) return true; // if just AM, always show
						if(isProductionstate || isModeratorState) return true; //if both, 
						if(isApprovalState && myApprovalTask && myApprovalTask.open && myApprovalTask.state != "approval") return true;
						return false;
					}
					if(task.type == 'approval') {
						if(!myApprovalTask?.open) return false;
						// if am production and in production ignore my approval task as pointless
						// if(this.isProduction() && isProductionstate) return false; // removed this again as you then cannot anytime amend
						//if(!this.isAmendManager()) return true;
						//if((isProductionstate || isModeratorState)) return false; //why? this prevents anytime amends for approvers
						if(myApprovalTask.state == "waiting") return false;	// approval task states used to determine task summary messaging ...ignore this comment -> amend manager and already approved or not ready
						return true;
					}						
					//TODO need to account for creative.state == "done" nad just show moderator (if AM) or approval task ...for ability to add late amends
					return true;
				})

				// filter task responses

				//user role
				let user_uuid = this.appUserService.appUser.uuid;
				let isProduction = this.isProduction();
				let isAmendManager = this.isAmendManager();
				let isApprover = this.isApprover();
				let isAdmin = this.isAdmin();
				let isApproverOnly = (isApprover && !isAmendManager && !isProduction && !isAdmin);

				tasks.forEach(task => {
					if(task.type != TaskType.AMEND) return;
					let filteredMessages = task.messages.filter(message =>
					{
						// we can always see our own messages
						let mine = message.user.uuid == user_uuid;
						if(mine) return true;
			
						//match recipient roles to specific trigger actions
						switch(message.action){
							case TaskEvent.ACTION_MODERATOR_AMEND_QUERY:
								if((isApprover || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_MODERATOR && (task.flag & TaskFlag.FLAG_AMEND_QUERIED))) return true;
								if(isProduction && (isProductionstate || (task.state == TaskState.AMEND_DONE && !(task.flag & TaskFlag.FLAG_AMEND_QUERIED)))) return true;
								break;
							case TaskEvent.ACTION_APPROVER_AMEND_QUERY_ANSWER:
								//TODO - AM's wont be able to see any previous answers if another query is sent to approver, until the next answer comes back
								if((isAmendManager || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_APPROVER && (task.flag & TaskFlag.FLAG_AMEND_QUERIED))) return true;
								if(isProduction && (isProductionstate || (task.state == TaskState.AMEND_DONE && !(task.flag & TaskFlag.FLAG_AMEND_QUERIED)))) return true;
								break;
							case TaskEvent.ACTION_MODERATOR_AMEND_DENY:
								if((isApprover || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_MODERATOR && (task.flag & TaskFlag.FLAG_AMEND_DENIED))) return true;
								break;

							case TaskEvent.ACTION_PRODUCTION_AMEND_DECLINE:
								if((isAmendManager || isAdmin) && (task.role != TaskRole.AMEND_ROLE_PRODUCTION || (task.flag & TaskFlag.FLAG_AMEND_DECLINED_PUSHBACK))) return true;
								if(isApprover && isApprovalState) return true;
								break;
							case TaskEvent.ACTION_MODERATOR_AMEND_DECLINE_PUSHBACK:
								if((isProduction || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_MODERATOR && (task.flag & TaskFlag.FLAG_AMEND_DECLINED_PUSHBACK))) return true;
								break;
							case TaskEvent.ACTION_MODERATOR_AMEND_REJECT:
								if((isProduction || isAdmin) && (task.role != TaskRole.AMEND_ROLE_MODERATOR || (task.flag & TaskFlag.FLAG_AMEND_REJECTED_PUSHBACK) )) return true;
								break;
							case TaskEvent.ACTION_APPROVER_AMEND_REJECT:
								if((isAmendManager || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_APPROVER && (task.flag & TaskFlag.FLAG_AMEND_REJECTED))) return true;
								if(isProduction && (isProductionstate || task.state == TaskState.AMEND_DONE)) return true;
								break;
							case TaskEvent.ACTION_PRODUCTION_AMEND_REJECTED_PUSHBACK:
								if((isAmendManager || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_PRODUCTION && (task.flag & TaskFlag.FLAG_AMEND_REJECTED_PUSHBACK))) return true;
								if(isApprover && isApprovalState) return true;
								break;
							case TaskEvent.ACTION_MODERATOR_AMEND_REJECTED_PUSHBACK:
								if((isApprover || isAdmin) && !(task.role == TaskRole.AMEND_ROLE_MODERATOR && (task.flag & TaskFlag.FLAG_AMEND_REJECTED_PUSHBACK))) return true;
								if(isProduction && (isProductionstate || (task.state == TaskState.AMEND_DONE && !(task.flag & TaskFlag.FLAG_AMEND_REJECTED_PUSHBACK)))) return true;
								break;	

							//default: return false;																											
						}

						// if message is older than creative version
						if(this.creative.version > message.creative_version) return true;

						// if the message role matches my role then we are ok
						if(
							(message.role == "production" && isProduction) || 
							(message.role == "moderator" && isAmendManager) || 
							(message.role == "approver" && isApprover) 
						) return true;

						return false;
					});

					// transform the responses
					task.responses = filteredMessages.map((message) => {
						let mine = message.user.uuid == user_uuid;
						let userName = mine ? "You" : message.user.name;
						return {message:message.message, user:userName + " - " + message.action + " v" + message.creative_version, user_uuid:message.user.uuid};
					})
				});

				this.tasks = tasks;
				this.numAmendTasks = this.getNumAmendTasks();
				this.actionableAmendTasks = this.getActionableTasks();

				// reinject any local tasks
				if(localTask)
				{
					this.tasks.splice(this.tasks.findIndex(t => t.type == TaskType.APPROVAL && t.user_uuid == this.user.uuid) + 1, 0, localTask);
				}
				// reselect if possible
				if(this.selectedTask)
				{
					let found = this.tasks.find(t => t.uuid == this.selectedTask.uuid)
					this.selectTask(found);
				}


				this.generateMarkerVOs();
				this.updateMarkerConnections();
				
				this.tasks.forEach(task => {
					this.updateTaskAssetName(task);
				});

				// only want to undisable tasks when we triggered the request to get tasks
				if(this.tasksDisabled && this.tasksDisabledCanChange)
				{
					this.tasksDisabled = false;
				}
				this.gatherTaskButtons();

				//TODO - might need to check if approval task is open
				let minCreativeVersion = (isApproverOnly) ? myApprovalTask.creative_version : 0;
				let maxCreativeVersion = (isApproverOnly) ? (myApprovalTask.creative_version_max || myApprovalTask.creative_version) : this.creative.version_latest;
				this.maxViewableVersion = maxCreativeVersion;
				this.getVersions(minCreativeVersion, maxCreativeVersion);

				//this.saving = false;
				this.loadingTasks = false;
				this.getMyTaskSummary();

				// show tasks panel if required
				if(this.tasks?.filter(task => task.type == TaskType.AMEND).length && !this.creativePanels.has(CreativeMenu.Amends))
				{
					if(this.creative.state != CreativeState.DONE)//this.isApprovalState() && //can be any state if you have submitted any amends
					{
						this.creativePanels.set(CreativeMenu.Amends, true);
					}
				}
			}
		);

		this.sub = this.projectService.latestDiscussions.subscribe(messages => {
			if(!messages) return;
			if(!this.creative) return;
			this.unreadDiscussions = false;
			const project_uuid = this.creative["project"].uuid;
			let localData = this.lss.get(`discussion_dates_${project_uuid}`);
			let cutoff = Date.now() - (31 * 24 * 60 * 60 * 1000);	// 31 days in past
			messages.forEach(message => {
				const date = Date.parse(message.created_at);
				if(date < cutoff) return;
				if(!localData) {
					this.unreadDiscussions = true;
				} else {
					const savedDate = localData[message.creative_id];			
					if(!savedDate || date > savedDate)
					{
						this.unreadDiscussions = true;
					}
				}
			});
		});
	}
	listen()
	{
		this.pusherService.joinProjectChat(this.creative["project"].uuid, () => {
			// notify others when I join
			this.pusherService.projectChat.whisper('creative', {message:this.creative_uuid, extra:'i join'});
		}, () => {
			// notify others when a new user Joins, could target that specific user only if we wanted..	
			this.pusherService.projectChat.whisper('creative', {message:this.creative_uuid, extra:'other join'});
		});

		// tell any listeners I am looking at this creative
		this.pusherService.projectChat.whisper('creative', {message:this.creative_uuid, extra:"creativeLoaded - listen"});


		// leave any existing creative chat then join the next one (automatic when joining)
		this.pusherService.joinCreativeChat(this.creative_uuid);

		if(!this._listening)
		{
			this._listening = true;

			// Join the project chat as it should be a one off (maybe)

			//this.pusherService.joinProjectChat(this.creative["project"].uuid, false, this.creative_uuid);
			//this.pusherService.joinProjectChat(this.creative["project"].uuid, true, this.creative_uuid, this.creative_uuid);
			this.pusherService.projectChat.messages.pipe(takeUntil(this._unsubscribe)).subscribe(
			(message:IMessage) => {
				console.log('creative listeneing to project message', message);
				if(message.user == this.appUserService?.appUser.uuid) return;

				if(message.type == 'creative' && message.metadata.action == 'update')
				{
					this.getTasks();
				}
				else if(message.type == 'workflow')
				{
					if(message.metadata.action == 'remove_approver')
					{
						this.getTasks();
						this.reloadCreative();
					} else if(message.metadata.action == 'remove_production')
					{
						this.getTasks();
						this.reloadCreative();
						if(this.panelOpen)this.togglePanel();
					}
				}
				else if(message.metadata.type == 'group')
				{
					this.getTasks();
					this.reloadCreative();
				}
				else if(message.type == "task")
				{
					//do nothing
				} else 
					//TODO This is super lazy, debounce this!
					this.loadWorkflows();
			});

			this.pusherService.getCreativeMessage().pipe(takeUntil(this._unsubscribe)).subscribe(
				(response) => {
					//console.log("CREATIVE MESSAGE", response);
			});
			this.pusherService.creativeChat.whispers.subscribe(whisper => {
				console.log("CREATIVE WHISPER", whisper);
				if(whisper.event == "creative" && whisper.whisper?.action === 'discussion.typing')
				{
					this.creativeDiscussionService.someoneTyping(whisper.whisper);
				}
					
					/*
{
    "event": "creative",
    "whisper": {
        "typing": false,
        "message": "is typing...",
        "user": {
            "uuid": "9b116f59-c678-4abe-b187-3e7881570e97",
            "name": "Adam Lawrance",
            "initials": "AL",
            "session": "c4ddc9800aa11eaec3dece7156a6e6feda85",
            "time": 1726841262.300057,
            "region": "local"
        }
    }
}

					*/
			})
			this.pusherService.creativeChat.messages.pipe(takeUntil(this._unsubscribe)).subscribe(
				(message:IMessage) => {
					//console.log("creative listening to - creative message:", message);
					// ignore any messages I generate
					// upload is async so we want to update on the sucess of that
					//  && message.action != 'build_started' <- why was this there?
					// (message.action != 'upload') &&  <- why was this here hmm?
					if(message.user == this.appUserService?.appUser.uuid) return;
					this._snackBar.open(message.metadata.message, null, {duration:1500});

					if(message.type == "task")
					{
						// tasks are bespoke to the viewer so updates to them need to be reloaded indiviualy
						// apart from delete, we can just remove it
						if(message.metadata.action == "delete")
						{
							let returnedTask = message.data[0];
							let taskIndex = this.tasks.findIndex(task => task.uuid == returnedTask.uuid)
							if(taskIndex != -1) {
								this.tasks.splice(taskIndex, 1);
								this.forceTasksAngularUpdate();
							}
						} else {
							this.respondToTaskAction(message.metadata.action)
						}
						return;

					}else if(message.type == "taskfile")
					{
						if(message.metadata.action == "create" || message.metadata.action == "upload")
						{
							let returnedTaskFile = message.data[0];
							let task = this.tasks.find(task => task.uuid == returnedTaskFile.task_uuid);
							if(task)
							{
								let taskFileIndex = task.files.findIndex(taskFile => taskFile.uuid == returnedTaskFile.uuid)
								if(taskFileIndex != -1)
								{
									// merge in
									task.files[taskFileIndex] = {...task.files[taskFileIndex], ...returnedTaskFile};
									this.tasks = this.tasks.concat([]);
								}else{
									task.files.push(returnedTaskFile);
									this.tasks = this.tasks.concat([]);
								}
							}
							return;
						}

					}else if(message.type == "asset")
					{
						// TODO only if I am production... or not approval? or some other flag, cheers adam you scope creeping son of a gun
						if(this.isProduction())
						{
							//console.log("creative message", message)
							if(message.data)
							{
								if(message.metadata.action == "delete")
								{
									this.removeAssets(message.data);
									return;
								}
								// see if any currently "uploading"
								let uploadingCount = this.assets.filter(assetVO => assetVO.asset.flag != 0).length;
								//console.log("assets with flags", uploadingCount);
								
								const originalAssetCount = this.assets?.length || 0;

								let messageAssets = message.data;
								for (let i = 0; i < messageAssets.length; i++) {
									const messageAsset = messageAssets[i];
									//let assetIndex = this.assets.findIndex(assetVO => assetVO.asset.uuid == messageAsset.uuid );
									let assetVO = this.assets.find(assetVO => assetVO.asset.uuid == messageAsset.uuid );
									//console.log("creative message asset merge", assetVO);
									
									if(assetVO)
									{
										// clear any previews if the incoming asset has a uri
										if(messageAsset.uri)
										{
											// found asset, dont need preview anymore
											assetVO.preview = null;
										}
										// overrites the asset
										//this.assets[assetIndex] = new AssetVO(messageAsset);
										assetVO.merge(messageAsset);
										// cache bust on upload only not processed
										if( message.metadata.action == "upload" && this.isProduction() && this.isProductionState())	assetVO.updateCacheBust();
									}else{
										// not in my list so add
										this.assets.push(new AssetVO(messageAsset));
									}
								}

								let uploadingCount2 = this.assets.filter(assetVO => assetVO.asset.flag != 0).length;
								//console.log("assets with flags after merge", uploadingCount2);

								const newAssetCount = this.assets?.length || 0;
								if(originalAssetCount == 0 && newAssetCount > 0)
								{
									this.getTasks();
								}
								// trigger update - is this still needed?
								this.assetsComponentService.assets = this.assets;
							}
							return;
							// might want to debounce this as it can be called a bunch if times in quick succession
							if(this.assetDebounce) clearTimeout(this.assetDebounce);
							this.assetDebounce = setTimeout(() => {
								this.loadAssets();								
							}, 333);
							//this.reloadCreative();
						}else{
							//console.log("asset update non production user");
							
						}

					}else if(message.type == "creative")
					{
						if(message.metadata.action == "layout")
						{
							// layout changed - reload creative
							// this ended up being more complex due to local saved settings and other things, on hold for now
							// this.reloadCreative();
							return;
						}
						if(message.metadata.action.indexOf ("chat_message") != -1)
						{
							// injett/merge the message or just reload
							if(this.creativePanels.has(CreativeMenu.Discussion))
							{
								if(message.metadata.action == "chat_message")
								{
									const merge = true;
									if(merge) {
										this.creativeService.mergeDiscussionMessages(message.data);
										//this.creativeDiscussionService.mergeMessage(message.data[0]);
									} else {
										this.loadDiscussion(true);
									}
								} else if(message.metadata.action == "chat_message_update") {
									this.creativeService.replaceDiscussionMessages(message.data);
								} else if(message.metadata.action == "chat_message_delete") {
									this.creativeService.deleteExistingMessages(message.data);
								}
							} else if(message.metadata.action == "chat_message") {
								// if panel is closed but we got a new message
								this.unreadDiscussions = true;
							}
							// could also indicate that a message has been sent... 
							return;
						}
						if(message.metadata.action == "delete_assets")
						{
							this.removeAssets(message.data);
						}else{
							//console.log("recieved creative message [ type: creative | not delete_assets]")
							this.reloadCreative();
							//this.loadWorkflows();
							if(this.panelOpen)
							{
								this.tempSub = this.projectService.loadWorkflows(this.project.uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`, `creatives:[creative2.uuid:${this.creative.uuid}]`]);
							}
						}
					}
					//this._snackBar.open(message.message, null, {duration: 2000});
			});
		}
	}
	/**
	 * Remove assets localy, no call to api to delete
	 * If no assets are left but there were some before it will reload the tasks
	 * @param assets A list of IAssets to delete, all should have uuids as this is triggered by remote calls
	 * @returns 
	 */
	private removeAssets(assets:IAsset[])
	{
		if(!assets?.length) return;

		const originalAssetCount = this.assets?.length || 0;
		// find and remove
		assets.forEach(deletedAsset => {
			if(this.selectedAsset?.asset.uuid == deletedAsset.uuid)	this.selectAsset(null)
			let assetIndex = this.assets.findIndex(assetVO => assetVO.asset.uuid ? assetVO.asset.uuid == deletedAsset.uuid : assetVO.asset.id == deletedAsset.id)
			if(assetIndex != -1)	this.assets.splice(assetIndex, 1);
		});
		const newAssetCount = this.assets?.length || 0;
		if(originalAssetCount > 0 && newAssetCount == 0)
		{
			// do we need to reload the creative?
			// do we only need to do this if we are in production
			// reload the tasks at this point
			this.getTasks();
		}
		// trigger settter
		//this.assets = this.assets;//.concat([]);
		this.assetsComponentService.assets = this.assets;
	}
	selectAsset(select:AssetVO)
	{
		this.assetsComponentService.selectedAsset = select;
		//this.selectedAsset = select ? this.assets.find(assetVO => select.asset.uuid ? select.asset.uuid == assetVO.asset.uuid : select.asset.id == assetVO.asset.id) : null;
	}
	deleteOldTaskEvents()
	{
		this.taskEvents.length = 0;
	}
	getTaskEventString(taskEvent)
	{
		return taskEvent;
	}
	// state
	isProductionState()
	{
		if(!this.creative) return false;
		return (this.creative.state == CreativeState.NEW || this.creative.state == CreativeState.BUILD || this.creative.state == CreativeState.AMENDING);
	}
	isApprovalState()
	{
		if(!this.creative) return false;
		return (this.creative.state == CreativeState.APPROVAL || this.creative.state == CreativeState.WARNING || this.creative.state == CreativeState.DONE);
	}
	isAmendManagerState()
	{
		if(!this.creative) return false;
		return this.creative.state == CreativeState.QUALIFYING;
	}
	// permissions - could cache these
	/** Does the current user have production permissions on the current channel */
	isProduction()
	{
		return (this.permissions?.channel || 0) & Permission.PRODUCTION;	// this.userPermissions & Permission.PRODUCTION;
	}
	/** Does the current user have approver permissions on the current channel */
	isApprover()
	{
		return (this.permissions?.channel || 0) & Permission.APPROVAL; //return this.userPermissions & Permission.APPROVAL;
	}
	/** Does the current user have amend manager permissions on the current workflow */
	isAmendManager()
	{
		return (this.permissions?.workflow || 0) & Permission.VALIDATOR; //return this.userPermissions & Permission.VALIDATOR;
		if(!this.workflow) return false;
		let users = this.workflow['users'];
		if(!users?.length) return false;
		let me = users.find(user => user.uuid = this.appUserService.appUser.uuid);
		if(!me) return false;
		return me.scoped_permissions & Permission.VALIDATOR;
	}
	isAdmin()
	{
		return (this.permissions?.project || 0) & Permission.ADMIN; 
	}
	isSuper()
	{
		return (this.appUserService.appUser.super); 
	}
	boot()
	{
		this.router.navigate([`/project/${this.creative["project"].uuid}`], {fragment:'Grid'});
	}
	clean()
	{
		this.unsub();
		this.leaveChats(true);
		this.numAmendTasks = 0;

		if(this.activeUploads)	this.activeUploads.length = 0;
		this.assetsSaving = 0;
		this.loadingTasks = false;

		// clear up
		// this.creative = null; // currently there are too many dependancies to kill this yet
		this.assetsComponentService.assets = null;
		this.selectAsset(null);
		this.tasks = null;
		this.taskSummaryVO = null;

		// clear the log
		this.creativeService.clearLog();

		// clear the discussion
		this.creativeService.clearDiscussion();
		this.creativeDiscussionService.clearTyping();
	}
	loadCreative()
	{
		// should always be a new creative
		// cleanup first if creative... or loading creative?
		this.clean();

		this.loadingCreative = true;
		this.creativeService.findOne(this.creative_uuid, {version:this.version}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				let creativeIn = response["data"][0];
				this.permissions = creativeIn['permissions'];
				if(this.creative)
				{
					// check if loading a new creative and if so reset gallery index
					if(this.creative.uuid != creativeIn.uuid)
					{
						this.galleryIndex = 0;
					}
					// this should be handled in the leaveChats(true) above
					this.pusherService.projectChat.whisper('creative', {leaving:true, message:this.creative.uuid, extra:'creativeloaded'});
				}else {
//					this.creative = creativeIn; //need to make sure this.creative is set before calling setAssetEditMode()
//					if(creativeIn.state == "new" || creativeIn.state == "build")
//					{
//						/// this could be handled better I think so it is all in one place
//						this.selectedTabIndex = 1;
//						this.setAssetEditMode(true);
//					}
				}
				this.creative = creativeIn;
				this.assetsComponentService.draggable = this.isProductionState();
				//this.permissions = this.creative['permissions']; //moved further up because setAssetMode needs to know if user is Prouction or not on ncoming creative
				// quick access check
				if(!this.isApprover() && !this.isProduction() && !this.isAmendManager() && !this.isAdmin() && !this.isSuper())
				{
					this.boot();
					return;
				}
				
				// project reference from creative
				this.project = this.creative["project"];
				//console.log("loadcreative",this.creative);
				this.creative.bg_color = '#' + (this.creative.bg_color) || this.bg_color_default;
				this.bg_color =this.creative.bg_color;
				this.bg_transparent = this.creative.bg_transparent;

				//this.creative_uuid = this.creative.uuid;
				this.layout = this.findLayout();
				this.showSaveBg = this.canShowSaveBg();
				//console.log("this.layout",this.layout);
				//this.latestVersion = this.creative.version;
				this.latestVersion = this.creative.version_latest;
				this.isLatestVersion = this.creative.version == this.creative.version_latest;
				/*
				if(this.latestVersion == -1)
				{
					// grab a ref to the real current version - set once
					//this.version = this.latestVersion;
				}*/
				//console.log("getCreative", this.latestVersion, this.creative);
				// this.amends = this.creative.amends as Amend[];
				// this.tasks = this.creative.tasks as Task[];
				this.onCreativeLoaded();
			},
			error => {
				//console.log("creative error", error);loadingCreative
				this.errorMessage = <any>error
			}
		);
	}
	reloadCreative()
	{
		//console.log("reloadCreative()")
		this.loadingCreative = true;
		// when reloading we need to decide when to reload the "current chosen version" vs the "latest version".. or do we always use the current chosen version
		this.creativeService.findOne(this.creative_uuid, {version:this.version}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				let creativeIn = response["data"][0];
				this.permissions = creativeIn['permissions'];
				this.creative = creativeIn;

				this.creative.bg_color = '#' + (this.creative.bg_color) || this.bg_color_default;
				this.bg_color =this.creative.bg_color;
				this.bg_transparent = this.creative.bg_transparent;

				//this.layout = this.findLayout();	
				//this.latestVersion = this.creative.version;
				this.latestVersion = this.creative.version_latest;
				this.isLatestVersion = this.creative.version == this.creative.version_latest;
				/*
				if(this.latestVersion == -1)
				{
					// grab a ref to the real current version - set once
					this.version = this.latestVersion;
				}*/
				//console.log("creative loaded");
				this.loadingCreative = false;
				this.getMyTaskSummary();
				this.actionableAmendTasks = this.getActionableTasks();
				this.checkSidePanel();

				this.assetsComponentService.draggable = this.isProductionState();
			},
			error => {
				this.errorMessage = <any>error
			}
		);
	}
	loadCreativeOriginal(callOnLoad:boolean = true)
	{
		throw new Error("unused function");
		
		//TODO - THIS DOESNT APPEAR TO BE USED
		// TODO lock the page
		this.creativeService.findOne(this.creative_uuid, {version:this.version}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				let creativeIn = response["data"][0];
				this.permissions = creativeIn['permissions'];
				if(this.creative)
				{
					// this should be handled in the leaveChats(true) above
					this.pusherService.projectChat.whisper('creative', {leaving:true, message:this.creative.uuid, extra:'creativeloaded'});
				}else {
					this.creative = creativeIn; //need to make sure this.creative is set before calling setAssetEditMode()
					if(creativeIn.state == "new" || creativeIn.state == "build")
					{
						/// this could be handled better I think so it is all in one place
						this.selectedTabIndex = 1;
						this.setAssetEditMode(true);
					}
				}
				this.creative = creativeIn;
				//this.permissions = this.creative['permissions']; //moved further up because setAssetMode needs to know if user is Prouction or not on ncoming creative
				// quick access check
				if(!this.isApprover() && !this.isProduction() && !this.isAmendManager() && !this.isAdmin())
				{
					this.boot();
					return;
				}
				
				// project reference from creative
				this.project = this.creative["project"];

				this.creative.bg_color = '#' + (this.creative.bg_color) || this.bg_color_default;
				this.bg_color = this.creative.bg_color;
				this.bg_transparent = this.creative.bg_transparent;

				this.creative_uuid = this.creative.uuid;
				this.layout = this.findLayout();			
				//console.log("this.layout",this.layout);
				if(this.latestVersion == -1)
				{
					//TODO - this might need to be updated to use creative.version_latest (may not be the same number as creative.version)
					// grab a ref to the real current version - set once
					this.latestVersion = this.creative.version;
					this.version = this.latestVersion;
				}
				//console.log("getCreative", this.latestVersion, this.creative);
				// this.amends = this.creative.amends as Amend[];
				// this.tasks = this.creative.tasks as Task[];


				
				//console.log("assets", this.assets);
				// TODO improve the split out for load assets
				if(callOnLoad){
					this.loadAssets();
					this.onCreativeLoaded();
				}
			},
			error => {
				//console.log("creative error", error);
				this.errorMessage = <any>error
			}
		);
	}
	loadAssets()
	{
		//console.log("loadAssets()");
		this.tempSub = this.creativeService.loadAssets(this.creative.uuid, {version:this.version});
	}
	loadChannels()
	{
		this.tempSub = this.projectService.loadChannels(this.project.uuid);
	}
	loadWorkflows()
	{
		// only if the panel is open
		if(this.panelOpen)
		{
			this.loadChannels(); // might be better to call it directly not here
			this.tempSub = this.projectService.loadWorkflows(this.project.uuid);
		}
	}
	togglePanel()
	{
		this.panelOpen = !this.panelOpen;
		if(!this.panelOpen)
		{
			this.resetProjectChannel();
		}
		localStorage.setItem('minigrid', this.panelOpen.toString());
		this.loadWorkflows();
	}
	loadLog()
	{
		this.creativeService.loadLog(this.creative_uuid);
	}
	loadDiscussion(slient:boolean = false)
	{
		if(!slient)	this.creativeDiscussionService.loading();
		this.creativeService.loadDiscussion(this.creative_uuid);
	}
	handleTyping(typing:boolean)
	{
		this.pusherService.creativeChat.whisper('creative', {action:'discussion.typing', typing});
	}
	private messageQueue:{creative_uuid:string, text:string}[] = [];
	handleSubmitMessage(text:string)
	{
		this.messageQueue.push({creative_uuid:this.creative_uuid, text});
		if(this.messageQueue.length == 1)	this.processMessageQueue();
	}
	processMessageQueue()
	{
		console.log("processMessageQueue", this.messageQueue?.length);
		if(!this.messageQueue?.length) return;
		const data = this.messageQueue[0];
		console.log("queuing message", data.text);
		this.creativeService.sendDiscussionMessage(data.creative_uuid, data.text).subscribe((response:ApiResponse<IChatMessage>) => {
			let messages:IChatMessage[] = response.data;
			// this.loadDiscussion(true);
			// update last-read date so we don't see an unread message badge
			const key = `discussion_dates_${this.creative["project"].uuid}`;
			let localData = this.lss.get(key, {});
			localData[this.creative_uuid] = Date.parse(messages[messages.length-1].created_at.split(" ").join("T") + 'Z');
			this.lss.set(key, localData);

			this.messageQueue.shift();
			this.creativeService.mergeDiscussionMessages(messages);
			console.log("message sent", data.text);
			this.processMessageQueue();
		});
	}
	loadMore()
	{
		console.log("LOAD MOEAR");
		
		this.creativeService.loadDiscussion(this.creative_uuid, true);
	}
	sendDiscussionMessage(text:string)
	{

	}
	/*
	getCreativeLog()
	{

		this.tempSub = this.creativeService.getLog(this.creative_uuid).subscribe(
			(response) => {
				//console.log("log got", response);
				this.creativeLog = response["data"];
			},
			error => this.errorMessage = <any>error
		);
	}
	*/
	assetsSavedOrDeleted(creativeReload = false){
		if(creativeReload) this.reloadCreative();
		this.getTasks();

		// update gallery index incase asset deleted
		if(this.layout.name == "gallery")
		{
			if(this.galleryIndex > this.assets.length - 1) this.galleryIndex = this.assets.length - 1;
		}
	}
	/*
	getUserAction()
	{
		this.tempSub = this.creativeService.getUserAction(this.creative.uuid).subscribe(
			(response) => {
				this.userAction = response["data"][0];
			},
			error => this.errorMessage = <any>error
		);
	}
*/
	// are we an approver / can we add an amend
	async onRightClick(event:PointerEvent, task:ITask = null)
	{
		// check the state
		// look for amends under click 
		event.preventDefault();
		event.stopPropagation();

		// ensure user is approver or amend manager
		//let isApprover = this.userPermissions & Permission.APPROVAL;
		//let isAmendManager = this.userPermissions & Permission.VALIDATOR;
		let isApprover = this.isApprover();
		let isAmendManager = this.isAmendManager();
		if(!isApprover && !isAmendManager) return;
		// ensure creative is at approval (or checking? or submitted?)
		// if(this.creative.state != "approval") return;	// not applicable now we can add amends at any time	
		// TODO ensure user's approval task is at approval (or any other )
		// TODO know when we are targeting an approval task vs an amend manager task... (maybe look at state)
		// i..e if at approval use the approval state as preference
		if(isAmendManager)
		{
			let modeatorTask = this.tasks?.find((task) => task.type == 'moderation');
			// do we need to do anything with this...
		}else if(isApprover)
		{
			let myApprovalTask = this.tasks?.find((task) => task.type == 'approval' && task.user_uuid == this.user.uuid);
			if(!myApprovalTask || !myApprovalTask.open || myApprovalTask.state == 'waiting') return;
		}
		//if(!myApprovalTask || (myApprovalTask.state != 'approval')) return;

		let specific = task != null; //TODO no task is sent thru from anywhere, so always false?
		let x = event.clientX;
		let y = event.clientY;	

		// menu options
		let menuOptions:MarkerOption[] = [];

		if(!this.creative.viewonly)
		{
			// find assets under click point
			let assetsUnderPoint = this.getAssetsUnderPoint(x, y);
			let topAsset = assetsUnderPoint.length ?  assetsUnderPoint[assetsUnderPoint.length-1] : null;
			
			if(topAsset)
			{
				// asset specific general amend
				let message = specific ? 'Attach amend to this asset' : 'Attach amend to this asset';//this is pointless
				let option = new MarkerOption(message, MarkerOption.ICON_ATTACH, MarkerOption.TYPE_MARKER_HIDDEN)
				menuOptions.push(option);
	
				// any other asset amends
				let amendOptions = await topAsset.getAmendOptions(x, y, specific);
				menuOptions = amendOptions.concat(menuOptions);
			}
	
			for (let i = 0; i < menuOptions.length; i++) {
				const menuOption:MarkerOption = menuOptions[i];
				if(menuOption) {	//skip any null options (dividers)
					menuOption.assetComponent = topAsset;
					menuOption.position = topAsset ? topAsset.globalToLocal(new Point(x,y)) : null;				
				}
			}
			
	
			
			// we pass to the menu the information about our object
	
			if(!task)
			{
				if(menuOptions.length)menuOptions.push(null);
				menuOptions.push(new MarkerOption('Add general amend', MarkerOption.ICON_GENERAL, MarkerOption.TYPE_GENERAL));
		
				// disable any menu options if an new amend is in play
				let anyNewAmends = this.tasks.find(t => t.type == TaskType.AMEND && t.state == "local");	 //&& t.marker
				if(anyNewAmends)
				{
					//menuOptions.unshift(new MarkerOption('Clear markup', MarkerOption.ICON_DELETE, MarkerOption.TYPE_DELETE))
					menuOptions.forEach(option => option ? option.disabled = true : null);
				}
			} else {
				menuOptions.forEach(option => {
					if (option) option.task = task
				});
			}
		} else {
			menuOptions = [
				new MarkerOption('Amend requests not permitted.', null, null,null,true),
				new MarkerOption('Creative is in \'VIEW ONLY\' mode', null, null,null,true),
			];
		}

		
		let trigger = this.matMenuTrigger.find(item => item.menu == this.rightMenu);
//		let matMenuTrigger = this.matMenuTrigger.get(this.matMenuTrigger.length-2);
//		matMenuTrigger.menuData = {items: menuOptions};

		// we record the mouse position in our object
		//const element = document.querySelector(".middle-left");
		const element = this.assetStage.first?.nativeElement.parentElement;
		if(element)
		{
			const bounds = element.getBoundingClientRect();
			this.menuTopLeftPosition.x = event.clientX - bounds.x + element.offsetLeft;
			this.menuTopLeftPosition.y = event.clientY - bounds.y + element.offsetTop;
		} else {
			this.menuTopLeftPosition.x = event.clientX;
			this.menuTopLeftPosition.y = event.clientY;
		}


		// we open the menu
		trigger.menuData = {items: menuOptions};
		trigger.openMenu();
		




		// we open the menu
//		matMenuTrigger.openMenu();
		//this.matMenu.openMenu();

		
		//let taskEvent = new TaskEvent(TaskEvent.REQUEST, null, '', {content:'test'});
		//let t = new Task();
		//t.
		//this.onTaskAction(taskEvent);
	}
	onRightClickMenuOpened()
	{
		// TODO this is a bit bogus and there can be multiple backdrops if you click quick so we grab the last
		// https://github.com/angular/components/issues/5007
		let backdrops = document.getElementsByClassName('cdk-overlay-backdrop');
		if(!backdrops.length) return;
		backdrops[backdrops.length-1].addEventListener('contextmenu', (offEvent: any) => {
			offEvent.preventDefault();
			
			/*
			offEvent.stopImmediatePropagation();
			offEvent.stopPropagation();
			*/
			let trigger = this.matMenuTrigger.find(item => item.menu == this.rightMenu);
			trigger.menuClosed.pipe(take(1)).subscribe((e) =>{
				//re trigger the right click menu
				// only do if this is in asset stage bounds
				let x = offEvent.clientX;
				let y = offEvent.clientY;
				let bounds = this.assetStage.first.nativeElement.getBoundingClientRect();
				if(x < bounds.left || x > bounds.right || y < bounds.top || y > bounds.bottom) return;
				this.onRightClick(offEvent);
			})
			trigger.closeMenu();
			// TODO work out how to re-open

		});
	}
	onRightClickMenuClosed()
	{
		//console.log("marker menu closed");
		// this is duplicate code!
		// clear references
		this.mouseMoveRef = null;
		this.mouseUpRef = null;
		this.marker = null;
		this.markerTask = null;

		this.updateMarkerConnections();
	}
	onRightClickMenu(option:MarkerOption)
	{
		let isApprover = this.isApprover();
		let isAmendManager = this.isAmendManager();

		if(!isApprover && !isAmendManager) return;

		// TODO rename to source task
		let sourceTask;
		if(isAmendManager)
		{
			sourceTask = this.tasks.find((task) => task.type == 'moderation');
		}else if(isApprover)
		{
			// only allow if I am approval and task is at approval
			sourceTask = this.tasks.find((task) => task.type == 'approval' && task.user_uuid == this.user.uuid);
			if(!sourceTask || !sourceTask.open || sourceTask.state == 'waiting') return;
			//if(!sourceTask || (sourceTask.state != 'approval')) return;	// do we ned this check still 
		}

		// disable the approval task
		sourceTask['disabled'] = true;


		let sourceTaskComponent = this.taskList.find((taskComponent) => taskComponent.task == sourceTask);
		//sourceTaskComponent.setEvent(TaskEvent.REQUEST_AND_SUBMIT);//no longer in use
		let marker:Marker = null;
		let taskEvent:TaskEvent;
		document.getSelection().empty();
		switch (option.type) {
			case MarkerOption.TYPE_GENERAL:
				/*
				// no visual marker just create an amend request...
				sourceTaskComponent.message = "";
				sourceTaskComponent.needsMessage = true;

				// focus the elmnt
				sourceTaskComponent.wantsMessageFocus = true;
				
				// trigger an update
				sourceTaskComponent.cdr.detectChanges();
				*/
				if(option.data)
				{
					marker = new Marker();
					marker.type = MarkerOption.TYPE_MARKER_HIDDEN;
					marker.x0 = 0;
					marker.y0 = 0;
				}
				break;
			case MarkerOption.TYPE_TEXT_CHANGE:
				marker = new Marker();
				marker.type = MarkerOption.TYPE_TEXT_CHANGE;				
				break;
			case MarkerOption.TYPE_TEXT_DELETE:					
				marker = new Marker();
				marker.type = MarkerOption.TYPE_TEXT_DELETE;
				break;
			case MarkerOption.TYPE_MARKER:
				marker = new Marker();
				marker.type = "target";
				marker.x0 = option.position.x;
				marker.y0 = option.position.y;
				break;
			case MarkerOption.TYPE_MARKER_HIDDEN:
				marker = new Marker();
				marker.type = MarkerOption.TYPE_MARKER_HIDDEN;
				marker.x0 = 0;
				marker.y0 = 0;
				break;
			default:
				console.warn(`option type ${option.type} not recognised!`)
				return;
				break;
		}
		
		if(option.data)				marker.metadata = option.data;
		if(option.assetComponent)	marker.asset_uuid = option.assetComponent.assetVO.asset.uuid;

		if(option.task)
		{
			option.task.marker = marker;
			let taskComponent = this.taskList.find((taskComponent) => taskComponent.task == option.task);
			if(taskComponent)
			{
				// focus the input on the task
				// TODO this implementation needs improvement i.e. to target the correct input textarea not just all of them (maybe)
				taskComponent.focus();
			}
		}else{
			taskEvent = new TaskEvent(sourceTaskComponent, TaskEvent.REQUEST, sourceTask, null, marker);
			this.onTaskAction(taskEvent);
		}

		// new marker may have been added - need to update the VOs
		this.generateMarkerVOs();

		sourceTaskComponent.cdr.detectChanges();
		this.updateMarkerConnections();
		this.updateTaskAssetName(sourceTask);
		this.updateActiveMarkers();
	}


	onCreativeLoaded()
	{
		// revalidate assetEditMode
		this.setAssetEditMode(this.assetEditMode);

		if(localStorage.getItem('minigrid') == 'true')
		{
			if(!this.panelOpen)
			{
				this.togglePanel();
			}
		}

		this.listen();
		this.loadAssets();
		this.getTasks();
		this.getWorkflow();

		// load the log if log open
		if(this.creativePanels.has(CreativeMenu.Log))
			this.loadLog();

		// load the discussions if discussion panal open
		if(this.creativePanels.has(CreativeMenu.Discussion))
			this.loadDiscussion();

		// should I open a panel in a given state (only if no panels already open)
		this.checkSidePanel();
		

		// temp subscription to uploads service that should get cleaned and reset with each new creative
		this.tempSub = this.uploadService.uploads$.subscribe((uploads:IUpload[]) => {
			this.activeUploads = uploads.filter(upload => upload.data.creative_uuid == this.creative_uuid && (upload.state == UploadState.STARTED || upload.state == UploadState.READY));
		})
		// temp subscription to assets service that should get cleaned and reset with each new creative
		this.tempSub = this.assetsService.posts$.subscribe(posts =>{
			let keys = Object.keys(posts).filter(key => key.indexOf(`assets2/${this.creative_uuid}`));
			//this.assetsSaving = keys.length != 0;
			keys.length != 0 ? this.assetsSaving |= (1<<0) : this.assetsSaving &= ~(1<<0);
		})
		this.tempSub = this.assetsService.deletes$.subscribe(posts =>{
			let keys = Object.keys(posts).filter(key => key.indexOf(`assets2/${this.creative_uuid}`));
			keys.length != 0 ? this.assetsSaving |= (1<<1) : this.assetsSaving &= ~(1<<1);
		})
		this.loadingCreative = false;

		this.loadLastDiscussion();
		return;
		//this.getCurrentUserPermissions();


		/*
		this.projectService.findOne(parseInt(this.creative.project_id)).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.project = response;
			},
			error => this.errorMessage = <any>error
		);*/
		this.project2Service.findAll("").pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				console.log("TASKS", response.data);
			},
			error => this.errorMessage = <any>error
		);
	}
	unreadDiscussions:boolean;
	loadLastDiscussion()
	{
		const project_uuid = this.creative["project"].uuid;
		this.projectService.loadLatestDisussions(project_uuid , [this.creative_uuid]);
	}
	onChannelMenuChange(projectChannel:string)
	{
		//this.selectedChannel = channel;
		//this.channel = channel;
		this.projectChannel = projectChannel;
	}
	/*
	getChain()
	{
		this.creativeService.getChain(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.chain = response.data.length ? response.data[0] : null;
			},
			error => this.errorMessage = <any>error
		);
	}*/
	
	getWorkflow()
	{
		//TODO workflow widget .ts initialises before this data is returned
		//if(this.workflow?.id === this.creative?.workflow_id) return; // TODO in future take param to signify click vs automated
		this.tempSub = this.workflowService.findOne(this.creative.workflow_uuid.toString()).subscribe(
			(response) => {
				this.workflow = response.data.length ? response.data[0] : null;
				Workflow.decorate(this.workflow);
				this.resetProjectChannel();				
			},
			error => this.errorMessage = <any>error
		);
	}

	resetProjectChannel()
	{
		if(!this.workflow) this.projectChannel = null;
		else {
			this.projectChannel = this.workflow.channels.find(channel => channel.uuid == this.creative.channel_uuid).project_channel_uuid;
		}
	}

	resetInfo(){
		this.getWorkflow();
		this.getTasks();
		this.reloadCreative();
	}
	/*
	//...from info panel
	getWorkflow()
	{
		this.creativeService.getWorkflow(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.workflow = response.data[0] ?? null;
				console.log("WORKFLOW (INFO)",this.workflow);
			},
			error => this.errorMessage = <any>error
		);
	}*/



	getHighlightStyle()
	{
		if(!this.selectedAsset || !this.assetsContainer){
			return {};
		}else{
			let index = this.assets.indexOf(this.selectedAsset);
			if(index == -1) return {};
			let element = this.assetsContainer.nativeElement.children[index];
			if(!element) return {};
			let bounds = element.getBoundingClientRect();
			let parentBounds = element.parentElement.getBoundingClientRect();
			return {'width.px':bounds.width, 'height.px':bounds.height, 'left.px':bounds.left - parentBounds.left, 'top.px':bounds.top - parentBounds.top};
		}

	}
	getAssets()
	{
		throw new Error("unused function... maybe");
		/*
		this.creativeService.getAssets(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.assets = response.data as Asset[];
				for (let i = 0; i < this.assets.length; i++) {
					Asset.createReferece(this.assets[i]);	// this will create a reference to the original that gets passed in
				}
			},
			error => this.errorMessage = <any>error
		);*/
	}
	handleAssetsDropped(e)
	{
		//console.log("droppped", e);
	}
	// this is greedy and shouldnt do so much at once
	getTasks()
	{
		// don't reload the task list if we are adding a new one (causes too many issues for now)
		// ideally remove in future
		//if(this.tasks?.find(t => t.state == 'local')) return;

		// dont load if editing something
		/*
		if(this.tasks && this.taskList?.length)
		{
			let editingTaskComponent = this.taskList.find(t => t.editable);
			if(editingTaskComponent)	return;
		}*/

		//this.loadLog();
		//this.getUserAction();
		this.loadingTasks = true;
		this.tempSub = this.tasksService.loadTasks(this.creative.uuid);
	}
	updateTaskAssetName(task:ITask)
	{
		if(task.marker && task.marker.asset_uuid){
			task.marker['asset_name'] = this.assets?.find(assetVO => assetVO.asset.uuid == task.marker.asset_uuid)?.asset.name;
		}else if(task.marker) {
			task.marker['asset_name'] = null;
		}
	}
	trackByTaskUUID(index:number, task:ITask)
	{
		return task.uuid;
	}


	/**
	 * Can only make modifications to the current version
	 * 
	 */
	areAssetsEditable()
	{
		return this.version == -1 || this.version == this.latestVersion;
	}
	/**
	 * Tells us about the current users role with respect to this creative based on the workflow and channel it belongs to
	 */
	getCurrentUserPermissions()
	{
		return;
		/*
		this.creativeService.getUserPermissions(this.creative.uuid, this.user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//console.log("\tcurrent user permissions", res);
			this.userPermissions = res["data"];
			//this.user.permissions = this.userPermissions;
			// TODO better user permissions system
			//this.user.permissions = {creative:{[this.creative.uuid]:this.userPermissions}};

			// check user access here then carry on:
			// am I an approver on this and am I waiting for my approval group to be active.. if so then I cannot see this
			let approvalTask:Task = this.creative["approval_task"];

			if(this.appUserService?.appUser['admin'] == false && !this.isProduction() && approvalTask?.state == "waiting")
			{
				this.router.navigate([`/project/${this.creative["project"].uuid}`], {fragment:'Grid'});
				return;
			}

			this.creativeService.getUserActions(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
				(response) => {
					this.userActions = response["data"];
				},
				error => this.errorMessage = <any>error
			);
			//this.getAssets(); // assets are now loaded with the creative...
			this.getTasks();
			this.getWorkflow();

			this.formats = this.project["formats"];
			
			//this.projectService.getCreatives(this.project.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			//	(response) => {
			//		this.creatives = response["data"];
			//		console.log("creatives", response);
			//	},
			//	error => this.errorMessage = <any>error
			//);
			//
		});
		*/
		/*
		this.projectService.getUserPermissions(this.creative.project_id, this.user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			console.log("\tcurrent user permissions", res);
			this.userPermissions = res["data"];
			this.user.permissions = this.userPermissions;
			// TODO better user permissions system
			//this.user.permissions = {creative:{[this.creative.uuid]:this.userPermissions}};
		});*/
	}

	onAssetEditModeChange(editMode: boolean) : void
	{
		//console.log("onAssetEditModeChange", editMode);
		this.setAssetEditMode(editMode);
	}
	setAssetEditMode(value:boolean)
	{
		console.log("setAssetEditMode", value);
		
		if(!this.isProduction())
		{
			this.assetEditMode = false;
		}else if (this.creative.state != "new" && this.creative.state != "build" && this.creative.state != "amending")
		{
			this.assetEditMode = false;
		}else {
			this.assetEditMode = value;
		}
	}
	onAssetSelected(asset : AssetVO, event:MouseEvent = null) : void
	{
		if(event && event.button == 1){
			// return on middle click
			//event.preventDefault();
			//event.stopImmediatePropagation();
			//event.stopPropagation();
			return;
		}
		
		//console.log("on asset selected", asset, index);
		//this.selectedAsset = asset;
		this.assetsComponentService.selectedAsset = asset;
		return;
		let index;
		if(index != -1)	//this.layout == "custom" && 
		{
			this.galleryIndex = index;
		}
	}
	handleStageClick(event:MouseEvent)
	{
		if(event.target['id'] != 'assets') return;		
		// clear selected task when empyu asset area is clicked
		if(this.selectedTask)
		{
			this.selectTask(null);
		}
	}
	copyLayout(event:MouseEvent)
	{
		if(event) event.stopPropagation();
		//console.log("COPY LAYOUT");
		// get asset elements and slap their positions into the assets themselves
		//console.log(this.creativeViewer.getAssetComponents());
		if(!this.creativeViewer) return;
		this.creativeViewer.getAssetComponents().forEach((assetComp, index) => {
			//console.log("asset", index, asset.element);
			let assetElement = assetComp.element.nativeElement;
			let x = assetElement.offsetLeft;
			let y = assetElement.offsetTop;
			assetComp.assetVO.asset.x = x;
			assetComp.assetVO.asset.y = y;
		});

		this.layout = this.layouts.find(lo => lo.name == 'custom');
		this.showSaveBg = true;
	}
	/**
	 * Arrange assets into a grid based on a number of columns.
	 * Only applys to assets with dimensions.
	 */
	gridify($event)
	{
		let form = [
			{ name: "columns", type: "text", placeholder:"Number of columns", label: "Number of columns", value: 2 },
			{ name: "padding", type: "text", placeholder:"Padding", label: "Padding", value: 0 },
		];
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Gridify assets",
				subtitle: "Sort assets into a grid",
				negative: "Cancel",
				positive: "Gridify",
				form
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
				//console.log("gridify result", result)
				let cols = parseFloat(result.form[0].control.value);
				let padding = parseFloat(result.form[1].control.value);
				let rows = Math.ceil(this.assets.length / cols);  
				// get max width for each column
				let maxWidths = new Array(cols);
				let maxHeights = new Array(rows);
				maxWidths.fill(0);
				maxHeights.fill(0);
				this.assets.forEach((assetVO, index) => {
					const col = index % cols;
					const row = Math.floor(index / cols);
					if(Asset.hasDimensions(assetVO.asset))
					{
						if(assetVO.asset?.width > maxWidths[col]) maxWidths[col] = assetVO.asset?.width;
						if(assetVO.asset?.height > maxHeights[row]) maxHeights[row] = assetVO.asset?.height;
					}
				});
				// accumulate the widths.heights
				let width = 0;
				let height = 0;
				maxWidths = maxWidths.map(value => width += value);
				maxHeights = maxHeights.map(value => height += value);
				
				// position those puppies
				this.assets.forEach((assetVO, index) => {
					const col = index % cols;
					const row = Math.floor(index / cols);
					if(Asset.hasDimensions(assetVO.asset))
					{
						if(col > 0) assetVO.asset.x = maxWidths[col-1] + col * padding;
						else assetVO.asset.x = 0;

						if(row > 0) assetVO.asset.y = maxHeights[row-1] + row * padding;
						else assetVO.asset.y = 0;
					}
				});
			}
		});
	}
	async pack()
	{
		let form = [
			//{ name: "width", type: "text", placeholder:"Max width", label: "Max width", value: 0 },
			//{ name: "auto", type: "checkbox", placeholder:"Auto width", label: "Auto width", value: false },
			{ name: "padding", type: "text", placeholder:"Padding", label: "Padding", value: 0 },
		];
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Pack assets",
				subtitle: "Pack assets into an optimal arrangement",
				negative: "Cancel",
				positive: "Pack",
				form
			}
		});
		const result = await dialogRef.afterClosed().toPromise();
		if(result)
		{
			const blocks = [];
			const padding = parseFloat(result.form[0].control.value);
			this.assets.forEach(assetVO => {
				const asset = assetVO.asset;
				if(Asset.hasDimensions(asset))
				{
					blocks.push({w:asset.width + padding, h:asset.height + padding, asset});
				}
			});
			// https://stackoverflow.com/questions/21078959/an-approach-to-implement-rectangular-bin-packing
			// https://codeincomplete.com/articles/bin-packing/
			// https://github.com/jakesgordon/bin-packing/blob/master/README.md
			//blocks.sort((a,b) => (b.h < a.h ? -1 : 1)); // sort inputs for best results
			blocks.sort((a,b) => ((b.w*b.h) < (a.w*a.h) ? -1 : 1)); // sort inputs for best results
			const packer = new GrowingPacker();
			packer.fit(blocks);

			for(var n = 0 ; n < blocks.length ; n++) {
				var block = blocks[n];
				if (block.fit) {
					block.asset.x = block.fit.x;
					block.asset.y = block.fit.y;
				}
			}
		}
	}
	saveLayoutChanges()
	{
		if(this.layout.name != this.creative.layout)
		{
			this._snackBar.open("saving layout", null, {duration: 4000});
			this.creativeService.setLayout(this.creative.uuid, this.layout.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this._snackBar.open("layout saved", null, {duration: 2000});
				// layout updated... 
				this.creative.layout = res.data[0];
			});
		}
	}
	// https://imvikaskohli.medium.com/how-to-implement-fullscreen-mode-in-angular-fb9f55c24f67
	// https://sreyaj.dev/fullscreen-toggle-angular-using-directives
	toggleFullscreen()
	{
		if(!document.fullscreenEnabled) return;

		let element = this.mainContent.nativeElement;
		// are we in full screen ?
		this.inFullscreenMode = !!document.fullscreenElement;

		if(document.fullscreenElement)
		{
			if (document.exitFullscreen)				document.exitFullscreen();
			else if (document.webkitExitFullscreen) 	document.webkitExitFullscreen();	/* Safari */
			else if (document.msExitFullscreen)			document.msExitFullscreen();		/* IE11 */
		}else{
			if (element.requestFullscreen) 				element.requestFullscreen();
			else if (element.webkitRequestFullscreen)	element.webkitRequestFullscreen();	/* Safari */
			else if (element.msRequestFullscreen)		element.msRequestFullscreen();		/* IE11 */
		}

		

	}
	@HostListener('document:fullscreenchange', ['$event'])
   @HostListener('document:webkitfullscreenchange', ['$event'])
   @HostListener('document:mozfullscreenchange', ['$event'])
   @HostListener('document:MSFullscreenChange', ['$event'])
	fullscreenmodes(event){
    	this.inFullscreenMode = !!document.fullscreenElement;
    }
	galleryMove(dir){
		throw new Error("bogus function - remove");
		
		if(this.selectedAsset)
		{
			this.galleryIndex = this.assets.indexOf(this.selectedAsset);
		}
		this.galleryIndex += dir;
		this.galleryIndex = ((this.galleryIndex % this.assets.length) + this.assets.length) % this.assets.length;
		if(this.selectedAsset)
		{
			this.selectedAsset = this.assets[this.galleryIndex];
		}
	}

	onTaskSelected(e)
	{
		//console.log("task selected", e);
		if(e.type == TaskEvent.SELECT)
		{
			this.selectTask(e.task, true);
		}
	}
	selectTask(task:Task, scrollAssetIntoView:boolean = false)
	{
		// fix - incase selected task is orphaned, so clear it
		if(this.selectedTask)
		{
			let found = this.tasks.find(t => t == this.selectedTask);
			if(!found) this.selectedTask = null;
		}
		
		if(task == null)
		{
			this.selectedTask = null;
		}else if(this.selectedTask && this.selectedTask.id == task.id)
		{
			return;
			// this.selectedTask = null;
		}else{
			this.selectedTask = task;				
			if(this.selectedTask.marker){


				// if task has an asset associated with it
				// if task marker has an asset assocated with it
				if(this.creativeViewer.layout == "gallery" && this.selectedTask.marker.asset_uuid)
				{
					let assetIndex = this.assets.findIndex(assetVO => assetVO.asset.uuid == this.selectedTask.marker.asset_uuid)

					if(this.creativeViewer.galleryIndex != assetIndex)
					{
						this.creativeViewer.galleryGoto(assetIndex);
						this.creativeViewer.assetElementList.changes.pipe(take(1)).subscribe(e => {
							// now scroll that shizzle in to view
							if(scrollAssetIntoView)
							{
								this.creativeViewer.getAssetComponents().forEach((assetComponent) =>
								{
									if(assetComponent.assetVO.asset.uuid == this.selectedTask.marker.asset_uuid)
									{
										assetComponent.scrollMarkerIntoView(this.selectedTask.marker);
										return;
									}
								})
							}
						});
					}else {
						// now scroll that shizzle in to view
						if(scrollAssetIntoView)
						{
							this.creativeViewer.getAssetComponents().forEach((assetComponent) =>
							{
								if(assetComponent.assetVO.asset.uuid == this.selectedTask.marker.asset_uuid)
								{
									assetComponent.scrollMarkerIntoView(this.selectedTask.marker);
									return;
								}
							})
						}
					}
					
				}else{
					// scroll any related asset into view
					if(scrollAssetIntoView)
					{
						this.creativeViewer.getAssetComponents().forEach((assetComponent) =>
						{
							if(assetComponent.assetVO.asset.uuid == this.selectedTask.marker.asset_uuid)
							{
								assetComponent.scrollMarkerIntoView(this.selectedTask.marker);
								return;
							}
						})
					}
				}

				// does marker have a timecode
				if(this.selectedTask.marker?.metadata?.in != null)
				{
					// grab the asset
					let assetComp = this.creativeViewer.assetElementList.find(assetComp => assetComp.assetVO.asset.uuid == this.selectedTask.marker.asset_uuid);
					if(assetComp)
					{
						// seek to time
						assetComp.seek(this.selectedTask.marker.metadata.in);
					}
				}

			}
			this.updateActiveMarkers();
		}
		this.updateMarkerConnections();
	}
	
	updateActiveMarkers()
	{
		this.markerVOs?.forEach(markerVO => markerVO.active = (this.selectedTask && markerVO.marker == this.selectedTask?.marker));
			;
		/*
		if(this.selectedTask?.marker)
			this.selectedTask.marker["active"] = true;
		// false them all
		this.tasks.forEach(task => {
			if(task != this.selectedTask && task.marker)
				task.marker["active"] = false;
		});
		*/
	}
	onMarkerClick(e:MouseEvent, task:Task)
	{
		throw new Error("Redundant function");
		// TODO delete this function
		this.selectedTask = task;
	}
	onMarkerMouseEvent(event:{task:Task, event:string})
	{
		//this.selectedTask = task;
		this.updateActiveMarkers();
		let task = event.task;
		if(event.event == 'click' || event.event == 'start')
		{
			this.selectTask(task as Task);
			if(!this.creativePanels.has(CreativeMenu.Amends))
			{
				this.creativePanels.set(CreativeMenu.Amends, true);
			}
			/*
			do in the component
			this.taskList.forEach((taskComponent) =>
			{
				if(taskComponent.task == task)
				{
					taskComponent.scrollIntoView();
					return;
				}
			})*/
		}else if(event.event == 'enter')
		{
			this.taskOver(task, true);
		}else if(event.event == 'leave')
		{
			this.taskOut(task);
		}else if(event.event == 'clear')
		{
			task.marker = null;
			// find and remove the markerVO
			let index = this.markerVOs.findIndex(markerVO => markerVO.task == task);
			if(index != -1)
			{
				this.markerVOs.splice(index, 1);
				this.updateMarkerVOs();
			}
		}
	}
	onMarkerDragStarted(e:CdkDragStart)
	{
		//console.log("marker drag start", e);
	}
	onMarkerDragMoved(e:CdkDragMove)
	{
		//console.log("marker drag move", e);
		this.updateMarkerConnections();
	}
	onMarkerDragged(e:CdkDragEnd, marker, task)
	{
		//console.log("marker dragged", e);
		/*
		let position = e.source.getFreeDragPosition();
		marker.x0 += position.x;
		marker.y0 += position.y;
		console.log(position);*/
		marker.x0 += e.distance.x;
		marker.y0 += e.distance.y;

		
	}
	/**
	 * Finds any markup that is assigned against this asset that matches the current version
	 * @param asset
	 * @returns
	 */
	getMarkers(asset:IAsset):any[]
	{
		throw new Error("No longer used - in asset");
		
		let markers:Array<any> = [];
		if(this.tabGroup.selectedIndex != 0) return markers;
		if(!this.tasks || this.tasks.length == 0) return markers;
		for (let i = 0; i < this.tasks.length; i++) {
			const task = this.tasks[i];

			//single marker
			if(task.marker && task.marker.asset_uuid == asset.uuid && task.creative_version == this.version) markers.push({task, marker:task.marker});

			// multiple markers
			if(task.markers?.length){
				for (let j = 0; j < task.markers.length; j++) {
					const marker = task.markers[j];
					if(marker.asset_uuid == asset.uuid && task.creative_version == this.version) markers.push({task, marker});
				}
			}
		}
		return markers;
	}
	markerTrackBy(index, marker:any) {
		return marker.marker.uuid;
	}
	// TODOs
	// rename annotation to marker - done
	// logins for BEN.2 - done
	// multiple markers per task (optional) - single
	// must be child of single asset

	// abilibly to select a task (highlight or lowlight)
	// click a marker which will select the task
	// some way to toggle markers (possible solo also)

	// approval group as a standalone scope, approver/basic_approver/approver_manager are now permissions on AG not project?

	hasMarker(asset:IAsset, creative:ICreative)
	{
		if(!this.tasks || this.tasks.length == 0) return false;
		for (let i = 0; i < this.tasks.length; i++) {
			const task = this.tasks[i];

			// single marker
			if(task.marker?.asset_uuid == asset.uuid) return true;

			// multiple markers
			if(task.markers?.length){
				for (let j = 0; j < task.markers.length; j++) {
					const marker = task.markers[j];
					if(marker.asset_uuid == asset.uuid) return true;
				}
			}
		}
		return false;
	}

	handleMouseMove(e:MouseEvent)
	{
		
		/*
		let scrollTop = 0;
		let el = this.markerContainer.nativeElement;
		while(el)
		{
			scrollTop += el.scrollTop;
			el = el.parentElement;
		}
		//this.marker.x0 = e.clientX - this.offsetX;
		//this.marker.y0 = e.clientY - this.offsetY + scrollTop;
		*/
		let offset = this.offset(this.markerContainer.nativeElement);

		// set marker 
	
		this.marker.x0 = e.clientX - offset.left - this.offsetX;
		this.marker.y0 = e.clientY - offset.top - this.offsetY;
		this.marker['debug'] = {
			global:[e.clientX, e.clientY],
			creative:[e.clientX - offset.left, e.clientY - offset.top],
			local:{x:0, y:0},
		};

		// see if we are over any assets
		let out = {x:0, y:0};
		this.marker.asset_uuid = null;
		this.creativeViewer.getAssetComponents().forEach((assetComponent, index) => {
			if(assetComponent.isPointOver(e.clientX, e.clientY, out))
			{
				this.marker['debug'].x = out.x;
				this.marker['debug'].y = out.y;

				this.marker.asset_uuid = assetComponent.assetVO.asset.uuid;	// temp pointer to asset uuid for marker connection info
				assetComponent.assetVO.markerOver = true;
				//this.assets.find((ass) => ass == assetVO.asset).markerOver = true;
			}else{
				assetComponent.assetVO.markerOver = false;
				//this.assets.find((ass) => ass == assetVO.asset).markerOver = false;
			}			
		});

		// draw a line for this temp marker
		this.updateMarkerConnections();
	}
	/**
	 * Handle mouse up while dragging a marker
	 * If the marker is over an asset that can have a marker placed on it, then we can create a new marker for the current task and
	 */
	handleMouseUp(e:MouseEvent)
	{
		// clean up the listeners
		window.removeEventListener("mousemove", this.mouseMoveRef);
		window.removeEventListener("mouseup", this.mouseUpRef);

		// reset
		this.creativeViewer.getAssetComponents().forEach((assetComponent, index) => {
			if(assetComponent.assetVO) assetComponent.assetVO.markerOver = false
		});

		let assetsUnderPoint = this.getAssetsUnderPoint(e.clientX, e.clientY);
		if(assetsUnderPoint.length)
		{
			this.onRightClick(e as PointerEvent, this.selectedTask);
			/*
			let assetComponent:AssetComponent = assetsUnderPoint[0];
			assetComponent.markerDrop(this.marker, e.clientX, e.clientY);
			this.marker.task_uuid = this.markerTask.uuid;
			this.markerTask.marker = this.marker;*/
		}else{
			// clear references
			this.marker = null;
			this.markerTask = null;
			this.updateMarkerConnections();
		}
		// clear references
		this.mouseMoveRef = null;
		this.mouseUpRef = null;

		return;

		// see if we are over an asset
		let assets:any[] = [];//this.getAssetsUnderMarker(false);
		console.log("MOUSE DROP", assets);
		if(assets)
		{
			// create a marker to be associated with the given task and asset
			let asset = assets[0];
			this.marker.task_uuid = this.markerTask.uuid;
			this.marker.asset_uuid = asset.uuid;
			this.marker.x0 -= asset.x;
			this.marker.y0 -= asset.y;

			this.markerTask.marker = this.marker;


			let found = false;
			for (let i = 0; i < this.tasks.length; i++) {
				const task = this.tasks[i];
				if(task == this.markerTask)
				{
					found = true;
					break;
				}
			}
			console.log("FOUND OR NOT?", found);

			// this.markerTask.marker = this.marker;
			for (let i = 0; i < this.tasks.length; i++) {
				const task = this.tasks[i];
				if(task.uuid == this.markerTask.uuid)
				{
					task.marker = this.marker;
					break;
				}
			}

			// loop tasks and set that way?


			// multiple markers
			// this.markerTask.markers ? this.markerTask.markers.push(this.marker) : this.markerTask.markers = [this.marker];

			/*
			let index = this.tasks.indexOf(this.markerTask);
			if(index != -1)
			{
				this.tasks[index] = this.markerTask;
				this.tasks = this.tasks;
			}*/
			this.tasks = this.tasks.concat([]);
		}
		// clear references
		this.mouseMoveRef = null;
		this.mouseUpRef = null;
		this.marker = null;
		this.markerTask = null;

	}
	/**
	 * 
	 * @param x global x coord
	 * @param y global y coord
	 * @returns {AssetComponent[]} List of AssetComponents if any, under point 
	 */
	getAssetsUnderPoint(x:number, y:number):AssetComponent[]
	{
		let assetsUnderPoint:AssetComponent[] = [];
		this.creativeViewer.getAssetComponents().forEach((assetComponent) => {
			if(assetComponent.isPointOver(x, y)) assetsUnderPoint.push(assetComponent);
		});
		return assetsUnderPoint;
	}
	offset(el) {
		var rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
	}


	onTaskDisable(isDisabled:boolean){
		this.tasksDisabled = isDisabled;
		//this.taskButtonsDisabled = isDisabled;
	}

	taskAction(action:string, needsMessage:boolean = false, message:string = null, button:HTMLButtonElement = null, note:string = null, task:Task){
		return;
		//console.log("TASK ACTION:",action);
		// if a note is present we can show the user a warning/popup first before they go ahead with the action giving them a chance to cancel
		if(note)
		{
			this.ds.openConfirm({
				title:"task warning/message",
				message:note,
				confirmAction: () => {
					//console.log("confirmAction");
					this.taskAction(action, needsMessage, message, button, null, task);
				},
				dismissAction: () => {
					//console.log("dismissAction");					
				},
				afterClosed: () => {
					//console.log("afterClosed");						
				}
			})
			return;
		}
		if(button)
		{	
			//task["disabled"] = true;
			//button.disabled = true;
			this.onTaskDisable(true);
		}
		
		this.onTaskAction(new TaskEvent(null, action, task, message));
	}

	onTaskAction(e:TaskEvent)
	{

		if(e.type == TaskEvent.DISABLE)
		{
			// disable other tasks	
//			this.tasks.forEach(t => t['disabled'] = t != e.task);
//			this.forceTasksAngularUpdate();
			return;
		}
		if(e.type == TaskEvent.ENABLE)
		{
			// enable all tasks	
//			this.tasks.forEach(t => t['disabled'] = false);
//			this.forceTasksAngularUpdate();
			return;
		}
		if(e.type == TaskEvent.MARKER)
		{
			// ensure the task is now the selected task - require to draw solid connection lines
			this.selectTask(e.task);
			this.markerTask = e.task;
			let message = JSON.parse(e.message);
			this.marker = new Marker();
			this.marker.visible = true;
			this.marker.type = "target";
			this.marker.x0 = message.x;
			this.marker.y0 = message.y;
			this.offsetX = this.userMarkerOffset ? message.dx : 0;
			this.offsetY = this.userMarkerOffset ? message.dy : 0;
			// get offset to correct coordinates
			/*
			this.offsetX = 0;
			this.offsetY = 0;
			let el = this.markerContainer.nativeElement;
			while(el){
				this.offsetX += el.offsetLeft;
				this.offsetY += el.offsetTop;
				console.log("el offsets", el.offsetLeft, el.offsetTop, el.scrollTop)
				el = el.parentElement;
			}
			//this.marker.x0 -= this.offsetX;
			//this.marker.y0 -= this.offsetY;
			*/
			let offset = this.offset(this.markerContainer.nativeElement);
			this.marker.x0 -= offset.left;
			this.marker.y0 -= offset.top;
			//console.log("final offsets", this.offsetX, this.offsetY);
			//console.log("final offset", offset);

			window.addEventListener("mousemove", this.mouseMoveRef = this.handleMouseMove.bind(this));	//, {passive:true}
			window.addEventListener("mouseup", this.mouseUpRef = this.handleMouseUp.bind(this));

			// NO ASSET YET!
			//e.task.markers ? e.task.markers.push(marker) : e.task.markers = [marker];
			return;
		}
		if(e.type == TaskEvent.MARKER_EDIT)
		{
			// find the markerVO and make that puppy editable
			let markerVO = this.markerVOs.find(markerVO => markerVO.task == e.task);
			if(markerVO) markerVO.editable = true;
			//this.updateMarkerVOs();
			return;
		}
		if(e.type == TaskEvent.MARKER_EDIT_CANCEL)
		{
			// find the markerVO and make that puppy editable
			let markerVO = this.markerVOs.find(markerVO => markerVO.task == e.task);
			if(markerVO){
				markerVO.editable = false;
				//markerVO.markChanged();
			}
			//this.updateMarkerVOs();
			this.updateMarkerConnections();
			return;
		}
		if(e.type == TaskEvent.MARKER_CLEAR)
		{
			// clear an unsaved marker
			e.task.marker = null;
			this.generateMarkerVOs();
			this.updateMarkerConnections();
			return;
		}
		if(e.type == TaskEvent.MARKER_UPDATE)
		{
			let targetAssetComp = this.creativeViewer.assetElementList.find(assetComponent => assetComponent.assetVO.asset.uuid == e.task.marker.asset_uuid);
			if(targetAssetComp)
			{
				targetAssetComp.baseAsset.updateMarker(e.task.marker, e.message);
			}
			return;
		}
		if(e.type == TaskEvent.EDIT)
		{
			// disable everything BUT the current task
			this.tasksDisabled = true;
			this.tasksDisabledCanChange = false;
			this.forceTasksAngularUpdate();
			return;
		}else if(e.type == TaskEvent.CANCEL_EDIT)
		{
			// undisable everything	
			//this.tasks.forEach(t => t['disabled'] = false);
			this.tasksDisabled = false;
			this.tasksDisabledCanChange = true;			
			this.forceTasksAngularUpdate();
			
			// a removed marker may be put back by this so regenrate
			this.generateMarkerVOs();
			this.updateMarkerConnections();
			//this.updateMarkerVOs();
			return;
		}
		if (e.type == TaskEvent.SAVE) {
			//console.log("TASK EVENT SAVE", e.component == null)
			if(e.component) e.component.saving = true;
			//else this.saving = true;

			this._snackBar.open("Saving task", null, {duration: 4000});
			let task = e.task;
			this.selectTask(null);
			this.updateMarkerConnections();
			this.tasksDisabled = true;
			this.tasksDisabledCanChange = false;

			// TODO has marker changed or been added, in which case we need to handle that..
			if(typeof task.marker?.metadata == "object") task.marker.metadata = JSON.stringify(task.marker.metadata);
			//clone task object to remove task.markerVO
			let taskClone = {...task};
			taskClone.markerVO = null;
			this.tasksService.taskSubmit(taskClone, this.creative_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
				// this._snackBar.open("Task submitted", null, {duration: 2000});
				let responseTask = response.data[0];
				//console.log("responseTask",responseTask);
				task.uuid = responseTask.uuid;
				// are there any new file attachments that need uploading
				let attachments = task.files;//e.data?.attachments;
				if(attachments)
				{
					for (let i = 0; i < attachments.length; i++) {
						const attachment:TaskCaptureEvent = attachments[i];
						// skip unchanged 
						if(!attachment.file) continue;
						this.addAttachment(responseTask, attachment);
						//this.uploadAttachment(responseTask, attachment);
					}
				}
				this._snackBar.open("Task saved", null, {duration: 2000});
				
				if(e.component) e.component.saving = false;
				//this.saving = false;
				e.component.editable = false;
				task.state = "saved"; // this enables it to be binned off
				//this.mergeResponse(response);

				this.tasksDisabledCanChange = true;
				//this only triggers an update for self, other users get realtime message to repsond to from task store() on back-end
				this.respondToTaskAction(e.type, e.task);//this.getTasks();

				//this.askToSubmit(task);
				//console.log("SAVE AMEND",(this.creative_uuid, e.task.uuid, e.type, e.message, e.task));
			});
			/*
			this.tasksService.taskSubmit(e.task, this.creative_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
				this._snackBar.open("Task saved", null, {duration: 2000});
				this.mergeResponse(response)}
			);*/
		} else if (e.type == TaskEvent.REQUEST) {
			// go to amends tab
			this.selectedTabIndex = 0;

			//check user is viewing latest creative version
			if(this.creative.version != this.maxViewableVersion)
			{
				//console.log("NOT MAX VIEWABLE VERSION");
				e.task['disabled'] = false;
				
				this.ds.openConfirm({
					title:"Creative version too old",
					message: "Amends cannot be requested while viewing an older version of a creative.",
					message2: "Reload the latest available version to request an amend",
					confirm_button_label: "Reload latest available version",
					dismiss_button_label: "Cancel",
					width: 400,
					confirmAction: () => {
						this.version = -1;
						this.reloadCreative();
					},
					dismissAction: () => {
						//console.log("dismissAction");					
					},
					afterClosed: () => {
						//console.log("afterClosed");						
					}
				})

			} else {
				console.log("TASK REQUESTED");
				// Create a new local amend ready for saving
				// will disable approval button
				// will disable send to approval and send to production if moderator
				const task = new Task();
				task.type = "amend";
				task.open = true;
				task.role = "approver";
				task.state = "local";
				task.flag = 0;
				task.colour = "amend-state-unsaved";
				task.creative_version = this.creative.version;
				task['label'] = {'label':"New, unsaved", 'colour':"red"};
				task.user_uuid = this.user.uuid;
				task.uuid = (-Math.floor(Math.random() * 0xFFFFFF)).toString();
	
				if(e.data)
				{
					task.marker = e.data as Marker;
					task.marker.task_uuid = task.uuid;
				}
	
				// moderated not approval...
				if(e.task.type == "moderation" || e.component.task.type == "moderation")
				{
					task.flag = TaskFlag.FLAG_AMEND_MANAGER;
				}
	
				// disable all tasks
				this.tasksDisabled = true;
				this.tasksDisabledCanChange = false;
	
				// inser this task immediately after the 
				let index = this.tasks.indexOf(e.task) + 1;
				this.tasks.splice(index, 0, task);
	
				this.selectTask(task);
	
				this.forceTasksAngularUpdate();

				// open amends panel when adding a new amend
				if(!this.creativePanels.has(CreativeMenu.Amends))
				{
					this.creativePanels.set(CreativeMenu.Amends, true);
				}
			}
		} else if (false){// && e.type == TaskEvent.REQUEST_AND_SUBMIT) {
			throw new Error("Unused task action event: "+ e.type);
			
			const task = new Task();
			task.type = "amend";
			task.state = "local";
			task.flag = 0;
			task.uuid = (-Math.floor(Math.random() * 0xFFFFFF)).toString();
			task.content = e.message;

			if(e.task.marker)
			{
				task.marker = e.task.marker;
				// convert to json string on the way out..
				task.marker.metadata = task.marker.metadata ? JSON.stringify(task.marker.metadata) : null;
				e.task.marker = null;
			}
			
			//this._snackBar.open("Submitting task", null, {duration: 4000});
			this.tasksService.taskSubmit(task, this.creative_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
				//this._snackBar.open("Task submitted", null, {duration: 2000});
				let responseTask = response.data[0];
				// if it was a success lets upload any task files...
				if(e.data?.audioData){
					let file = e.data.audioData;
					this.uploadService.add(`upload/creative/${this.creative_uuid}/task/${responseTask.uuid}`, {file});
				}
				if(e.data?.videoData){
					// TODO EXTRA...
					let file = e.data.videoData;
					let extra =  e.data.videoData["crop"] ? {crop: e.data.videoData["crop"]} : null;
					this.uploadService.add(`upload/creative/${this.creative_uuid}/task/${responseTask.uuid}`, {file});	//, extra
					/*
					task['videoFile'] = e.data.videoData;
					if(e.data.videoData["crop"])
					{
						console.log("WE CROPPED");
						task['videoCrop'] = e.data.videoData["crop"];
					}*/
				}
				
				this.mergeResponse(response)
			});
		} 
		else if (e.type == TaskEvent.SAVE_EDIT) {

			// handle new attachments
			// handle new markup
			// change changes or removals of old markup/attachments
			// old attachments can ONLY be removed not edited.
			// possible attachmented can be removed in seperate calls
			
			// send only changes
			let task = e.task;
			let changedProps = ModelUtils.isDirty(task, task.reference, {exclude:['buttons', 'disabled', 'marker.asset_name', 'markerVO']});
			if(changedProps)
			{
				let changes = ModelUtils.buildFromChanges<Task>(task, task.reference, changedProps);
				//console.log("UPDATE CHANGES A",changes);
				let attachmentUploads = [];
				if(changedProps.includes('files'))
				{
					// files have changed...
					// deleting is easy - won't be in list sent up
					// new is easy - just add to the upload queue
					// updated ones just pull out to trigger a delete and treat as new
					for (let i = 0; i < changes.files.length; i++) {
						const attachment:TaskCaptureEvent = changes.files[i];
						if(attachment.file)
						{
							changes.files.splice(i--, 1);
							attachmentUploads.push(attachment);
						}						
					}
				}
				// convert metadata to json string if required
				if(typeof changes.marker?.metadata == "object") changes.marker.metadata = JSON.stringify(changes.marker.metadata);
				//console.log("changes_trimmed", changes);
				this._snackBar.open("Updating task", null, {duration: 4000});
				//console.log("UPDATE CHANGES B",changes);
				this.tasksService.taskUpdate(task.uuid, this.creative_uuid, changes).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
					this._snackBar.open("Task updated", null, {duration: 2000});
					
					// do we need saving?
					if(e.component) e.component.saving = false;
					//this.saving = false;
					if(attachmentUploads.length)
					{
						attachmentUploads.forEach(attachment => {
							this.addAttachment(task, attachment);
							//this.uploadAttachment(task, attachment);
						})
					}

					this.tasksDisabledCanChange = true;
					// grab all the tasks again
					//this.getTasks();
					this.respondToTaskAction(e.type, e.task);
					//this.askToSubmit(task);
					return;
					let responseTask = response.data[0];				
					this.mergeResponse(response);
					e.component.saving = false;
					// upload any new files

					// undisable everything
					this.tasks.forEach(t => t['disabled'] = false);
					this.forceTasksAngularUpdate();
				}, (error) => {
						// TODO
						console.warn("handle task save edit error..");						
					}
				);			

			}else {
				// hmmm
			}

	
		} else if (e.type == TaskEvent.CANCEL_NEW) {

			// undisable everything	
			this.tasksDisabled = false;
			this.tasksDisabledCanChange = true;

			// kill the task
			let index = this.tasks.indexOf(e.task);
			if(index != -1) this.tasks.splice(index, 1);

			this.selectTask(null);

			// update markerVOs as task has been removed and may have had a marker
			this.generateMarkerVOs();
			this.forceTasksAngularUpdate();
		} else {
			
			if(e.component.task.type == TaskType.AMEND) e.component.saving = true;
			//else this.saving = true;

			this.assetsComponentService.selectedAsset = null;
			//this.selectedAsset = null;

			// all the rest
			this._snackBar.open(`Task action ${e.type}`, null, {duration: 4000});
			
			// if the task action is not an amend action (i.e. is an approver or production or amend manager)

			// lock everything down until we get a response
			this.tasksDisabled = true;
			this.tasksDisabledCanChange = false;
			//this.disableAllTasks(); // might need to only unlock when we get a response from getting all the tasks back...

			//console.log("pre task service",e.type);
			this.tasksService.taskAction(this.creative_uuid, e.task.uuid, e.type, e.message).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
				this._snackBar.open(`Task action ${e.type} success`, null, {duration: 2000});
				//this.mergeResponse(response); // NO LONGER MERGE, just request all again
				this.tasksDisabledCanChange = true;
				this.respondToTaskAction(e.type, e.task, response);	// respond to my own actions
				if(e.component) e.component.saving = false;
				//this.saving = false;

			}, error =>{
				this._snackBar.open(`Task action ${e.type} failed`, null, {duration: 2000});				
			});
		}
	}
	/*
	askToSubmit(task)
	{
		let unActionedAMamends;
		let isAMamend = ((task.flag & TaskFlag.FLAG_AMEND_MANAGER) != 0) ? true : false;
		let approvalsPending;
		if(isAMamend) { 
			unActionedAMamends = this.tasks?.find((task) => task.type == 'amend' && task.role == TaskRole.AMEND_ROLE_MODERATOR && task.actioned == false);
			//find any unresolved approval tasks in same group
			let currentGroup = this.creative.groupInfo.groups[this.creative.groupInfo.current];
			approvalsPending = currentGroup.total - (currentGroup.approved + currentGroup.submitted);

		}
		//console.log("unactioned AM amends:",unActionedAMamends);
		if(!isAMamend || (isAMamend && !unActionedAMamends))
		{
			let message =  "You have saved amends. Would you like to submit them now?";
			
			if(approvalsPending) var message2 = "\n NOTE: Some approvers in the current group are still yet to respond. Submitting your Manager amends now will prevent any further approvals.";

			this.ds.openConfirm({
				title:"Submit amends?",
				message: message,
				message2: message2,
				confirm_button_label: "Submit amends",
				dismiss_button_label: "Continue reviewing",
				width: 400,
				confirmAction: () => {
					if(isAMamend){
						//console.log("confirm submit amends - moderator",this.tasks);
						let mymoderatorTask = this.tasks?.find((task) => task.type == TaskType.MODERATOR);
						//console.log("mymoderatorTask",mymoderatorTask);
						if(mymoderatorTask)
						{
							let comp = this.taskList.find(comp => comp.task == mymoderatorTask);
							this.onTaskAction(new TaskEvent(comp, TaskEvent.ACTION_MODERATOR_SEND_PRODUCTION, mymoderatorTask));
						}
					} else {
						//console.log("confirm submit amends - approver");
						let myApprovalTask = this.tasks?.find((task) => task.type == TaskType.APPROVAL && task.user_uuid == this.user.uuid);
						if(myApprovalTask && myApprovalTask.open && myApprovalTask.state != 'waiting')
						{
							let comp = this.taskList.find(comp => comp.task == myApprovalTask);
							this.onTaskAction(new TaskEvent(comp, TaskEvent.ACTION_APPROVER_REQUESTS_SUBMIT, myApprovalTask));
						}
					}
					//this.taskAction(action, needsMessage, message, button, null, task);
				},
				dismissAction: () => {
					//console.log("dismissAction");					
				},
				afterClosed: () => {
					//console.log("afterClosed");						
				}
			})
		}
	}
	*/
	//tasklock
	disableAllTasks(exclude:Task | Task[] = null)
	{
		//console.log("disabling all tasks");
		
		this.tasks.forEach(t => {
			if(exclude)
			{
				if(Array.isArray(exclude))
				{
					if(exclude.includes(t)) return;					
				} else {
					if(t == exclude) return;
				}
			}
			t['disabled']=true
		});
	}
	unDisableAllTasks()
	{
		this.tasks.forEach(t => t['disabled'] = false);
	}

	taskOver(task:Task, flip:boolean = false)
	{
		// Don't activate hover on selected task fool
		if(task == this.selectedTask) return;
		this.overTask = task;	
		this.updateMarkerConnections(flip);
	}
	taskOut(task:Task)
	{
		this.overTask = null;
		this.updateMarkerConnections();
	}
	onResized(event: Event)
	{
		//console.log("creative resized...");
		
		this.updateMarkerConnections();
	}
	generateMarkerVOs()
	{
		// generate markerVOs
		const oldMarkers = this.markerVOs;
		this.markerVOs = [];
		this.tasks.filter(task => task.marker).forEach(task =>
		{
			// try and recycle - if not found create
			let markerVO = oldMarkers?.find(markerVO => markerVO.task == task);
			if(!markerVO){
				markerVO = new MarkerVO(task, task.marker, false);
				task.markerVO = markerVO;
				// how do we know to default to editable if new? - no uuid on task? no uuid on marker?
				//console.log("generating marker",  markerVO.marker.id, markerVO.marker.uuid, markerVO.task.id, markerVO.task.uuid);
				markerVO.editable = (markerVO.marker.id < 0);
			}
			this.markerVOs.push(markerVO);
		});
		this.updateMarkerVOs();
	}
	updateMarkerVOs()
	{
		this.markerVOsSubject.next(this.markerVOs);
	}
	/**
	 * Generate additional contextual data for markers such as
	 * - visiblilty, offsets
	 * TODO move this extra info into the markerVO so as not to polute 
	 */
	updateMarkers()
	{
		this.markerVOs.forEach(markerVO => {
			let assetComp:AssetComponent = this.creativeViewer.assetElementList.find(assetComp => assetComp.assetVO.asset.uuid == markerVO.marker.asset_uuid);
			if(!assetComp){
				// console.warn("asset component not found for marker");
				// Possible that asset has since been deleted, so no asset component can be found
				return;	
			}
			// do we need to reassign? - no
			//markerVO.marker = assetComp.getMarkerRenderInfo(markerVO.marker);
			assetComp.getMarkerRenderInfo(markerVO);
		})
		return;
//		// need assets and tasks available to compute		
//		if(!this.assets?.length || !this.tasks?.length)	return;
//		// loop through the assets and tasks and add markers to each asset
//
//		this.assets.forEach(assetVO => {
//			if(!assetVO.markerVOs) assetVO.markerVOs = [];
//			else assetVO.markerVOs.length = 0;
//			let assetComp = this.creativeViewer.assetElementList.find(comp => comp.assetVO.asset == assetVO.asset);
//			if(!assetComp) return;
//			this.tasks.forEach(task => {
//				// only amend tasks have markup
//				if(task.type != TaskType.AMEND) return;
//				// skip if no marker
//				if(!task.marker)  return;
//
//				// for amend markup we only show it for the original version is was created on (or if new/unsaved)
//				//if(task.marker.uuid && asset.version != task.creative_version) return;
//
//				if(task.marker.asset_uuid == assetVO.asset.uuid){
//					let marker = assetComp.getMarkerRenderInfo(task.marker);
//					//let colour = Task.getColour(task, this.user.uuid);
//					console.log("updating markers");
//										
//					if(marker.visible !== false) {
//						let markerVO = new MarkerVO(task, marker, task == this.selectedTask);
//						let taskComp = this.taskList.find(taskComp => taskComp.task == task);
//						markerVO.editable = taskComp.editable;
//						assetVO.markerVOs.push(markerVO);
//					}
//				}
//			});
//		})
	}

	updateMarkerConnections(flip:boolean = false)
	{
		this.connections.length = 0;	// this.connections = [];
		
		// need assets and tasks available to compute		
		if(!this.assets?.length || !this.tasks?.length)	return;

		//let stageBounds = this.assetStage.first.nativeElement.getBoundingClientRect();
		let stageBounds = this.creativeViewer.assetsContainer.nativeElement.getBoundingClientRect();
		// get all markers
		// get their local coords
		// convert to "global" / creative space
		// get the task coords
		// convert to global
		// connect em with a sexy line
		// profit

		//let markerVOs = [];
		/*
		this.assets.forEach(asset => {
			markerVOs = markerVOs.concat(this.getMarkers(asset));
		});*/
		//markerVOs.forEach( markerVO => {
		//	let marker = this.selectedTask.marker;
	//		if(!marker) return connections;
	//		let task = this.selectedTask;
			//if(task != this.selectedTask) return;
		
		// update markers to get the latest info required to render them
		this.updateMarkers();
		
		if(this.selectedTask)
		{
			let connection = this.getTaskConnection(this.selectedTask, stageBounds, flip);
			if(connection)	this.connections.push(connection);
		}
		if(this.overTask)
		{
			let connection = this.getTaskConnection(this.overTask, stageBounds, flip);
			if(connection){
				connection.hover = true;
				this.connections.push(connection);
			}
		}
		//});		
	}

	/**
	 * Get a connection object between a task and a marker
	 * also works for the temp marker pre-drop on an asset
	 * @param task 
	 * @param stageBounds 
	 * @param flip 
	 * @returns 
	 */
	getTaskConnection(task:ITask, stageBounds:DOMRect, flip:boolean = false):any
	{
		flip = !flip;	// flipping flop I got this flipped (－‸ლ)
		//if(!task?.marker) return null;
		let marker = task.marker || this.marker;
		let markerVO:MarkerVO = task.marker ? this.markerVOs.find(markerVO=>markerVO.marker == marker) : null;
		
		if(!marker) return null;
		if(markerVO?.visible === false) return null;

			// task coordinates first!!
			let taskComp = this.taskList.find(taskComp => taskComp.task == task);
			if(!taskComp) return null;
			let tx = 0;
			let ty = 0;
			if(taskComp){
				let taskBounds = taskComp.element.nativeElement.getBoundingClientRect();
				tx = taskBounds.left;
				ty = taskBounds.top;				
				if(isNaN(tx) || isNaN(ty) || tx < 10)
				{
					return null;
				}
				ty += 10;
				let markerLineBounds = this.markerLines.first.nativeElement.getBoundingClientRect();
				ty -= markerLineBounds.top;
				tx -= markerLineBounds.left;
				///connection.ty -= taskComp.element.nativeElement.parentElement.parentElement.parentElement.parentElement.offsetTop;
			}else{
				return null;
			}


			// get position of marker in asset
			let assetComp = this.creativeViewer.assetElementList.find(assetComp => assetComp.assetVO.asset.uuid == marker.asset_uuid);
			if(!assetComp && marker != this.marker) return null;
	
			// marker coords in asset space
			let mx = marker.x0 + (markerVO?.offsetX || 0);
			let my = marker.y0 + (markerVO?.offsetY || 0);

			// is the marker currenty dragging
			let drag = marker['drag'];
			if(drag)
			{
				mx += drag.x;
				my += drag.y;
			}
			
			// connector coords in asset space
			let x = mx;
			let y = my;

			let assetBounds = assetComp?.element.nativeElement.getBoundingClientRect();
			if(marker == this.marker)//!assetBounds && 
			{
				// fake the bounds!!
				// are we dragging the initial temp marker!
				assetBounds = {left:0, top:0, width:stageBounds.width, height:stageBounds.height};
			}else if (!assetBounds)
			{
				return null;
			}

			// adjust for asset position (only works in custom layout)
			if(false)
			{
				x += assetComp.assetVO.asset.x || 0;
				y += assetComp.assetVO.asset.y || 0;

				// adjust for scroll
				y -= this.assetStage.first.nativeElement.scrollTop;
				x -= this.assetStage.first.nativeElement.scrollLeft;
			}else{
				// only offset marker if not global temp marker
				if(marker != this.marker)
				{
					x += assetBounds.left;
					y += assetBounds.top;

					// adjust for stage y offset
					x -= stageBounds.left;
					y -= stageBounds.top;
				}else {
					y -= 40;;
				}

			}

			// apply scale - TODO
			/*
				x *= this.zoom;
				y *= this.zoom;
			*/


			// is it a text box marker
			if(marker?.metadata?.rects)
			{
				if(marker.metadata.rects.length == 1)
				{
					let rect = marker.metadata.rects[0];
					// average
					//x += ((rect.x + rect.width * 1) * markerVO.scale || 1);
					//y += ((rect.y + rect.height * 0.5) * markerVO.scale || 1);
					// best
					let scale = markerVO?.scale || 1;
					x = clamp(tx, x + (rect.left * scale), x + (rect.right * scale));
					//y = y + rect.y;
					y = clamp(ty, y + (rect.top * scale) + 5, y + (rect.bottom * scale) - 5);	// 5 is connector radius
					if(ty == y) y += 0.25;	// hack to fix dissapearing flatline - ensures not flat
				}else{
					// we have multiple rects, get the right most one on the top line
					let min_y = marker.metadata.rects[0].y;
					let min_x = marker.metadata.rects[0].x;
					let index = marker.metadata.rects.findLastIndex(rect => rect.x > min_x - 2 && Math.abs(rect.y - min_y) < 5);	// 3 used for fuzz
					let rect = marker.metadata.rects[Math.max(index, 0)];	// index -1
					let scale = markerVO?.scale || 1;
					x = clamp(tx, x + (rect.left * scale), x + (rect.right * scale));
					y = clamp(ty, y + (rect.top * scale) + 5, y + (rect.bottom * scale) - 5);	
				}
			}else if(marker.type == MarkerOption.TYPE_MARKER_HIDDEN){
				x = clamp(tx, x, x + assetBounds.width);
				//y = this.clamp(ty, y, y + assetBounds.height);
				y += 15;
			}else{
				x += 18;
			}	
			
			// we can fade out clamped connections to indicate they are out of view
			let alpha = 1;
			let alphaEnd = 1;

			// clamp pdf connections
			if(marker.type == MarkerOption.TYPE_TEXT_CHANGE || marker.type == MarkerOption.TYPE_TEXT_DELETE)	// && !isNaN(marker.metadata?.page
			{
				let ix = x;
				let iy = y;
				let offset = !isNaN(marker.metadata?.page) ? 40 : 60;
				y = clamp(y, -stageBounds.top + assetBounds.top + offset, -stageBounds.top + assetBounds.bottom);
				x = clamp(x, -stageBounds.left + assetBounds.left, -stageBounds.left + assetBounds.right);
				// console.log("marker", ix, x, ix != x)
				if(iy != y || ix != x)
				{
					alpha = 0;
				}
			} 			
			if(marker.type == MarkerOption.TYPE_TARGET){
				// alpha out of bounds	
				if(mx < 0 || mx > assetBounds.width || my < 0 || my > assetBounds.height)
				{
					alpha = 0;
				}
			}
			// temp marker hack
			if(marker == this.marker)
			{
				if(!marker.asset_uuid) alpha = 0;
			}


			let dx = Math.abs(tx - x);
			let dy = Math.abs(ty - y);
			
			let bez = dx * 0.5;
			let length = dx + dy;
			
			let colour = task.colour || '';

			let versionMatch = Math.abs(task.creative_version - (this.version != -1 ? this.version : this.creative.version)) <= 1 ;
			let anim = true;
			if(!versionMatch){
				length = 10;
				anim = false;
			}
			if(!task.open){
				alpha *= 0.25;
				alphaEnd *= 0.25;
			}
			let connection = {x ,y, tx, ty, d:'', dc:'', length, anim, id:marker.id, colour, alpha, alphaEnd};

			let start = flip ? {x:connection.tx, y:connection.ty} : {x:connection.x, y:connection.y};
			let end = flip ? {x:connection.x, y:connection.y} : {x:connection.tx, y:connection.ty};
			if(flip)	bez *= -1;
			connection.d = `M ${connection.x} ${connection.y} L ${connection.tx} ${connection.ty}`;
			connection.d = `M ${start.x} ${start.y} C ${start.x + bez} ${start.y} ${end.x - bez} ${end.y} ${end.x} ${end.y}`;
			let dir = flip ? 0 : 1;
			let dir2 = 1 - dir;
			connection.dc = `M ${start.x} ${start.y} v -5 a 1 1 0 0 ${dir} 0 10 Z ` + ` M ${end.x} ${end.y} v -5 a 1 1 0 0 ${dir2}  0 10 Z`;
			
			if(isNaN(connection.x) || isNaN(connection.y) || isNaN(connection.tx) || isNaN(connection.ty))
			{
				throw new Error("connection error" + JSON.stringify(connection));
			}
			return connection;
	}
	trackByMarkerConnection(index:number, connection:any)
	{
		return connection.id;
	}

	addAttachment(task:Task, attachment:TaskCaptureEvent)
	{
		// clear any previews - dont want to upload those
		if(attachment.preview)	attachment.preview = null;
		
		//save a new task_file and get back it's uuid
		this.tasksService.addAttachment(this.creative_uuid, task.uuid, attachment).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			let responseTaskFile = response.data[0].files.find(taskfile => taskfile.type == attachment.type);
			//use the task_file uuid to upload the attachment file to correct path
			//console.log("responseTaskFile",responseTaskFile);
			attachment.taskfile_id = responseTaskFile.uuid;
			this.uploadAttachment(task, attachment);
		});
							
	}
	uploadAttachment(task:Task, attachment:TaskCaptureEvent)
	{
		// clear any previews - dont want to upload those
		if(attachment.preview)	attachment.preview = null;
		// this.uploadService.add(`upload/creative/${this.creative_uuid}/task/${task.uuid}/chunkTest`, {file:attachment.file, data:attachment});
		let account_uuid = localStorage.getItem("account_uuid"); // TODO better way, i.e. from returned asset (target)
		let creative_uuid = this.creative_uuid;
		let task_uuid = task.uuid;
		let taskfile_uuid = attachment.taskfile_id;
		let uri = `${account_uuid}/${creative_uuid}/task_files/${task_uuid}/${taskfile_uuid}/${attachment.file.name}`;
		let workerRoute = `upload/${uri}`

		this.uploadService.addWorker(workerRoute,
			{file:attachment.file, data:attachment},
			null, 
			(upload) => {
				let subject = new Subject<boolean>();
				let metadata = attachment;//upload.response.metadata
				this.tasksService.complete(uri, upload.response.region, metadata).subscribe(res => 
					{
						//console.log("RES", res)
						subject.next(true);
					}, err => subject.next(false));
				return subject.asObservable();
			});			
	}
	// get the number of tasks that need my attentions
	/* not used - see getMyTaskSummary()
	getMyTaskCount()
	{
		if(!this.creative || !this.tasks) return 0;
		let myCount = 0; //only add to count for amend tasks
		let isAmendManager = this.isAmendManager();
		let isApprover = this.isApprover();
		let isProduction = this.isProduction();

		if(this.creative.state == "build" || this.creative.state == "amending")
		{
			if(isProduction)
			{
				if(this.creative.state == "build")
				{
					//myCount += 1;
				}else{
					// production amends to do

					let amends = this.tasks.filter(task => task.open && task.state == TaskState.AMEND_TODO && task.role == TaskRole.AMEND_ROLE_PRODUCTION && task.actioned == false)
					if(amends.length) myCount += amends.length;
					else myCount += 1;
				}
			}
			if(isApprover){
				//approver amends to confirm/acknowldege
				let amends = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && task.user_uuid == this.user.uuid)
					if(amends.length) myCount += amends.length;
			}
			if(isAmendManager){
				//approver amends to confirm/acknowldege
				let amends = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_MODERATOR && task.actioned == false)
					if(amends.length) myCount += amends.length;
			}
		}else if(this.creative.state == "qualifying")
		{
			if(isAmendManager)
			{
				let amendsToQualify = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_MODERATOR && task.actioned == false)
				if(amendsToQualify.length)  myCount += amendsToQualify.length;
				//else  myCount += 1;
			}
			if(isApprover)
			{
				let apprCount = 0;
				let amendsToSubmit = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && task.state == 'new' && task.actioned)
				if(amendsToSubmit.length > 0)   myCount += 1;//apprCount +=1;
				
				let amendsToAcknowledge = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && (task.state == 'declined' || task.state == 'denied') && !task.actioned)
				myCount += (amendsToAcknowledge.length);//apprCount
				
				//return apprCount;
			}
		}else if(this.creative.state == "approval")
		{
			if(isApprover)
			{
				let uuid = this.appUserService.appUser.uuid;
				let myApprovalTask = this.tasks.find(task => task.open && task.type == "approval" && task.user_uuid == uuid);
				if(!myApprovalTask) return 0;
				if(myApprovalTask.state == "approved") return 0;
				let myAmends = this.tasks.filter(task => task.open && task.user_uuid == uuid && task.role == TaskRole.AMEND_ROLE_APPROVER && task.actioned == false)
				if(myAmends.length) myCount += myAmends.length;
				//else myCount += 1;
			}
		}
		return myCount;

	}
	*/

	gatherTaskButtons()
	{
		this.taskButtons = [];
		this.tasks.forEach(task => {
			if(task.type != 'amend' ){
				if(task.buttons){
					if(task.buttons[0].constructor === Array){
						if(task.buttons[0].length) task.buttons[0].forEach(t => {t.task = task; this.taskButtons.push(t)});	
						if(task.buttons[1].length) task.buttons[1].forEach(t => {t.task = task; this.taskButtons.push(t)});
					} else {
						task.buttons.forEach(t => {t.task = task; this.taskButtons.push(t)});
					}
				}
			}
		})
		//console.log("taskButtons:",this.taskButtons);
	}
	getMyTaskSummary()
	{
		//TODO - would this be better if done on the back end after Creative2Controller.php->getState() as that funtion is getting task counts for grid
		//returns an object with summary text and a task count,

		if(!this.creative)	return;// 0;
		if(!this.tasks)		return;// 0;
		let isProductionState = (this.creative.state == CreativeState.NEW || this.creative.state == CreativeState.BUILD);
		if(this.creative.viewonly && !isProductionState) {
			this.setSummaryVO(["This creative is currently in INFO (view only) mode."], 0);
			return;
		}

		let myCount = 0;
		let mySummary = [];
		let isAmendManager = this.isAmendManager();
		let isApprover = this.isApprover();
		let isProduction = this.isProduction();

		//filter all this users tasks first to get counts/summaries of each state/role combo
		let amendsProd = [];
		let amendsProdTodo = [];
		let amendsProdSubmit = 0;
		let amendsProdAcknowledge = [];
		let amendsAppr = [];
		let amendsApprNew = [];
		let amendsApprConfirm = [];
		let amendsApprAcknowledge = [];
		let amendsApprActioned = [];
		let amendsApprRejected = [];
		let amendsApprQueried = [];
		let amendsMod = [];
		let amendsModToQualify = [];
		let amendsModToConfirm = [];
		let amendsModToSendProd = [];
		let amendsModToSendAppr = [];
		let amendsModAcknowledge = [];
		let amendsModRejected = [];
		let amendsModQueried = [];
		let amendsModNew = [];
		let amendsModDeclinePushback = [];

		if(this.tasks)
		{
			//TODO swap equivalent TaskFlag for any deprecated task.state = "denied" or "queried" or "new"
			//TODO add task.role and task.state eNUMS
			if(isProduction)
			{
				amendsProd = this.tasks.filter(task => task.open && task.state == TaskState.AMEND_TODO && task.role == TaskRole.AMEND_ROLE_PRODUCTION);
				amendsProdTodo = this.tasks.filter(task => task.open && task.state == TaskState.AMEND_TODO && task.role == TaskRole.AMEND_ROLE_PRODUCTION && task.actioned == false);
				amendsProdSubmit = amendsProd.length - amendsProdTodo.length;
				amendsProdAcknowledge = amendsProdTodo.filter(task => task.flag & TaskFlag.FLAG_AMEND_LATE);
			}
			
			if(isApprover){
				amendsAppr = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && task.user_uuid == this.user.uuid);
				amendsApprNew = amendsAppr.filter(task => task.actioned == true && task.state == TaskState.AMEND_TODO && task.flag == 0);
				amendsApprConfirm = amendsAppr.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && task.user_uuid == this.user.uuid && task.state == TaskState.AMEND_DONE && task.flag == 0 && !task.actioned);
				amendsApprAcknowledge = amendsAppr.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && task.user_uuid == this.user.uuid && task.state == TaskState.AMEND_DONE && (task.flag & (TaskFlag.FLAG_AMEND_DECLINED | TaskFlag.FLAG_AMEND_DENIED)));
				amendsApprActioned = amendsAppr.filter(task => task.actioned);
				amendsApprRejected = amendsAppr.filter(task => task.actioned == true && task.state == TaskState.AMEND_TODO && task.flag & TaskFlag.FLAG_AMEND_REJECTED);
				amendsApprQueried = amendsAppr.filter(task => (task.flag & TaskFlag.FLAG_AMEND_QUERIED));
			}
			
			if(isAmendManager)
			{
				amendsMod = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_MODERATOR);
				amendsModToQualify = amendsMod.filter(task => task.actioned == false && task.state == TaskState.AMEND_TODO);
				amendsModToConfirm = amendsMod.filter(task => task.actioned == false && task.state == TaskState.AMEND_DONE && (task.flag == 0 || (task.flag & TaskFlag.FLAG_AMEND_MANAGER)) && !(task.flag & TaskFlag.FLAG_AMEND_DECLINED));
				amendsModToSendProd = amendsMod.filter(task => task.actioned == true && task.state == TaskState.AMEND_TODO);
				amendsModNew = amendsMod.filter(task => task.actioned == true && task.state == TaskState.AMEND_TODO && (task.flag & TaskFlag.FLAG_AMEND_MANAGER));
				amendsModToSendAppr = amendsMod.filter(task => task.actioned == true && task.state == TaskState.AMEND_DONE && !(task.flag & TaskFlag.FLAG_AMEND_MANAGER));
				amendsModAcknowledge = amendsMod.filter(task => task.actioned == false && task.state == TaskState.AMEND_DONE && (task.flag & TaskFlag.FLAG_AMEND_DECLINED));//&& task.user_uuid == this.user.uuid
				amendsModRejected = amendsModToSendProd.filter(task => task.flag & TaskFlag.FLAG_AMEND_REJECTED);
				amendsModDeclinePushback = amendsModToSendProd.filter(task => task.flag & TaskFlag.FLAG_AMEND_DECLINED_PUSHBACK);
				amendsModQueried = this.tasks.filter(task => task.open && task.role == TaskRole.AMEND_ROLE_APPROVER && (task.flag & TaskFlag.FLAG_AMEND_QUERIED));
			}	
		}
		
		//then priortise summaries (and stack if required) relative to the current creative state
		//set any non-role zero message for state (e.g. "no tasks, waiting for approvers" for production user at approval)
		
		let count_ack = 0;
		let count_conf = 0
		//these tasks are independent of creative state
		if(isApprover)
		{
			//approver amends to submit/confirm/acknowldege
			let apprArr = [];
			if(amendsApprRejected.length) apprArr.push(amendsApprRejected.length+" rejected");
			if(amendsApprNew.length) apprArr.push(amendsApprNew.length+" new");//need to check lower down to add in extra message about not approver until submitted etc
			
			if((amendsApprNew.length + amendsApprRejected.length)> 0) {
				let appStr = apprArr.join(", ");
				mySummary.push("You have "+appStr+" amends to submit.");
				//myCount += 1;
				//myCount += (amendsApprRejected.length + amendsApprNew.length);
			}

			if((amendsApprQueried.length)> 0) {
				mySummary.push("You have "+amendsApprQueried.length+" request queries to answer.");
				myCount += (amendsApprQueried.length);
			}
			
			if(amendsApprAcknowledge.length){
				count_ack += amendsApprAcknowledge.length
				//myCount += (amendsApprAcknowledge.length);
			}
			if(amendsApprConfirm.length){
				count_conf += amendsApprConfirm.length
				//myCount += (amendsApprConfirm.length);
			}
		}

		if(isAmendManager){
			//moderator amends to accept/confirm/acknowldege
			

			if(amendsModToQualify.length){
				mySummary.push("You have "+amendsModToQualify.length+" new amend requests to accept.");
				myCount += amendsModToQualify.length;
			} else {
				//only say how many to send if none to qualify
				let qualArr = [];
				if(amendsModRejected.length) qualArr.push(amendsModRejected.length+" 'rejected'");
				if(amendsModDeclinePushback.length) qualArr.push(amendsModDeclinePushback.length+" 'decline push-back'");
				let newAmends =  amendsModToSendProd.length - (amendsModRejected.length + amendsModDeclinePushback.length);
				if(newAmends) qualArr.push(newAmends+" 'new'");
				if(amendsModToSendProd.length){
					let qualStr = qualArr.join(", ");
					mySummary.push("You have "+qualStr+" amends to send to Production.");
					//myCount += 1;
					//myCount += amendsModToSendProd.length;
				}
			}
			if(amendsModToConfirm.length){
				count_conf += amendsModToConfirm.length;
			}
			if(amendsModAcknowledge.length){
				count_ack += amendsModAcknowledge.length
			}

			if(amendsModQueried.length){ 
				mySummary.push("Waiting for responses from "+amendsModQueried.length+" queries.");
			}
			
		}
		
		if(count_conf > 0) mySummary.push("You have "+count_conf+" completed amends to confirm.");
		if(count_ack > 0) mySummary.push("You have "+count_ack+" amend responses to acknowledge.");
		myCount += count_conf + count_ack;

		// state dependent tasks...maybe
		if(this.creative.state == CreativeState.NEW || this.creative.state == CreativeState.BUILD || this.creative.state == CreativeState.AMENDING)
		{
			if(isProduction) 
			{
				if(this.assets?.length == 0){
					mySummary.push("Start adding assets in the assets panel to the right or drop them onto the stage.");
					//myCount += 1;
				} else {
					let hasURI = this.assets?.filter(assetVO => assetVO.asset.uri).length > 0 ? true : false;
					if(hasURI){
						if(this.creative.state != CreativeState.AMENDING)
						{
							if(this.creative.viewonly){
								mySummary.push("Click 'Build completed' to release "+this.assets.length+" assets as a view-only INFO slot.");
							} else {
								mySummary.push("Click 'Build completed' to send "+this.assets.length+" saved assets for approval.");
							}
						}
					} else {
						mySummary.push("Add or upload content to placeholder assets to proceed.");
						myCount += 1;
					}
					//myCount += 1;
				}
			} else {
				if(!mySummary.length) mySummary.push("There are no tasks for you to action.");
				mySummary.push("Waiting for production to be completed.");
			}
		}
		if(this.creative.state == CreativeState.BUILD || this.creative.state == CreativeState.AMENDING)
		{
			if(isProduction)
			{
				if(amendsProdAcknowledge.length)//late amends take precedence
				{
					mySummary.push("You have "+amendsProdAcknowledge.length+" late amend requests to acknowledge.");
					myCount += amendsProdAcknowledge.length;
				} else {
					// might have amends in playy at any point, not just 'amending' state
					if(amendsProdTodo.length){
						mySummary.push("You have "+amendsProdTodo.length+" amends to do.");
						myCount += amendsProdTodo.length;
					} 

					/*if(this.creative.state == "build")
					{
						mySummary.push("'Complete build' in tasks tab to send "+this.assets.length+" saved assets for approval.");
						myCount += 1;
					}else{*/
					if(this.creative.state == CreativeState.AMENDING){	
						if(amendsProdSubmit == amendsProd.length && myCount == 0)  {
							mySummary.push("All production amend tasks resolved... Send creative to be reviewed.");
							//myCount += 1;
						} 
					}
				}
				
			}
			/*if(isApprover){
				//approver amends to confirm/acknowldege
				
				if(amendsApprConfirm.length){
					mySummary.push("You have "+amendsApprConfirm.length+" completed amends to confirm.");
					myCount += amendsApprConfirm.length;
				}
				if(amendsApprAcknowledge.length){
					mySummary.push("You have "+amendsApprAcknowledge.length+" amend responses to acknowledge.");
					myCount += amendsApprConfirm.length;
				}
			}
			if(isAmendManager){
				//moderator amends to accept/confirm/acknowldege
				if(amendsModToQualify.length){
					mySummary.push("You have "+amendsModToQualify.length+" new amend requests to accept.");
					myCount += amendsModToQualify.length;
				}
				if(amendsModToConfirm.length){
					mySummary.push("You have "+amendsModToConfirm.length+" completed amends to confirm.");
					myCount += amendsApprConfirm.length;
				}
				if(amendsModAcknowledge.length){
					mySummary.push("You have "+amendsModAcknowledge.length+" amend responses to acknowledge.");
					myCount += amendsModAcknowledge.length;
				}
			}*/
			else {
				if(!mySummary.length) mySummary.push("There are no tasks for you to action.");
				//let waitStr = (this.creative.state == "build") ? "build" : "amends";
				if(this.creative.state == CreativeState.AMENDING) mySummary.push("Waiting for amends to be completed on latest version.");
			}


		}else if(this.creative.state == CreativeState.QUALIFYING)
		{
			if(isAmendManager)
			{
				/*
				if(amendsModToQualify.length){
					mySummary.push("You have "+amendsModToQualify.length+" new amends to accept. ");
					myCount += amendsModToQualify.length;
					if(amendsModToConfirm.length){ //also has some done amends to confirm/senmd to approval
						mySummary.push("New requests must be actioned before completed/confirmed amends can be sent back for approval.");
					}
				} else {//no amends to accept
				*/
				if(amendsModToQualify.length)
				{
					
					if(amendsModToSendProd.length) {
						mySummary.push("");//hacky line break
						mySummary.push("You must action all new requests before you can send accepted requests back to Production.");
					}
				} else {//nothing to qulaify
					if(amendsModToSendProd.length){//has amends to send to production
						if(amendsModToConfirm.length){ //has amends to confirm
							mySummary.push("");//hacky line break
							mySummary.push("NOTE: New requests must be sent to production before confirmed amends can be sent for re-approval.");
						}
						//
					}else {//nothing to send to production
						if(myCount == 0){//no amends to confirm/acknowledge
							//...any amends to send to approval
							if(amendsModToSendAppr.length) {
								mySummary.push("You have "+amendsModToSendAppr.length+" amends to send back to Approvers.");
							} else {
								//all resolved, so just pass the creative on to approvers
								mySummary.push("All amend tasks resolved, creative is ready to send back to Approvers.");
							}
						}
					}
				} 
				/*}*/ 	
				/*if(amendsModAcknowledge.length){
					mySummary.push("You have "+amendsModAcknowledge.length+" declined amends to acknowledege");
					myCount += amendsModAcknowledge.length;
				}*/
			} else {
				if(!mySummary.length) mySummary.push("There are no tasks for you to action.");
				mySummary.push("Waiting for production checks on latest version.");
			}
			/* I think these approver tasks can be counted at any creative state -- move to top
			else if(isApprover)
			{
				if(amendsProdSubmit > 0) {
					mySummary.push("You have "+amendsProdSubmit+" new amend requests to submit.");
					myCount +=1;
				}
				
				if(amendsApprAcknowledge.length){
					mySummary.push("You have "+amendsApprAcknowledge.length+" amend responses to acknowledge.");
					myCount += (amendsApprAcknowledge.length);
				}
				if(amendsApprConfirm.length){
					mySummary.push("You have "+amendsApprConfirm.length+" completed amends to confirm.");
					myCount += (amendsApprConfirm.length);
				}
			}*/
		}else if(this.creative.state == CreativeState.APPROVAL || this.creative.state == CreativeState.APPROVED || this.creative.state == CreativeState.SUBMITTED)
		{
			let currentGroup = this.creative.groupInfo.groups[this.creative.groupInfo.current];
			let approvalsPending = currentGroup.total - (currentGroup.approved + currentGroup.submitted);
			let userdata = this.appUserService.appUser;
			
			let myApprovalTask = this.tasks.find(task => task.open && task.type == "approval" && task.user_uuid == userdata.uuid);
			if(isApprover && myApprovalTask)
			{
				//if(!myApprovalTask) return;
				if(myApprovalTask.state == "approved"){
					mySummary.push("You have already approved this creative.");// Waiting for "+approvalsPending+" other approvers in your group to review.");
				} else if (myApprovalTask.state == "submitted"){
					if(mySummary.length == 0) mySummary.push("You have submitted amends for this creative.");// Waiting for "+approvalsPending+" other approvers in your group to review.");
				} else {
					/*probably dont need this...
					let amendsApprSubmit = (amendsApprNew.length + amendsApprRejected.length);
					if(isAmendManager) amendsApprSubmit += amendsModToSendProd.length;
					if(!amendsApprSubmit && !amendsApprAcknowledge.length) {
						mySummary.push("Approve this creative or request amends in the Tasks tab.");
						myCount += 1;
					}
					*/
				}
			} else {
				if(!mySummary.length) mySummary.push("There are no tasks for you to action.");	
			}

			let isMyGroup = this.creative.groupInfo.myGroup == this.creative.groupInfo.current;
			let groupStr = isMyGroup ? "your group" : "group: '"+currentGroup.name+"'";
			let usersGroup = this.creative.groupInfo.groups[this.creative.groupInfo.myGroup];
				
			if(myApprovalTask?.state == "approval" && !(isAmendManager && amendsModNew.length))
			{
				if(usersGroup?.mode == 'anyone' && usersGroup?.done){
					mySummary.push("This creative has already been approved by someone else in your approval group (see info tab).");
				}
				
				if (isMyGroup)
				{
					if(mySummary.length == 0){//no other tasks have been flagged
						if (approvalsPending == 1) mySummary.push("This creative is ready for you to review.");
						else mySummary.push("Ready for you and "+(approvalsPending-1)+" other approvers in "+groupStr+" to review.");	
					}
					
				}
				else if(approvalsPending > 0) mySummary.push("Waiting for "+approvalsPending+" approvers in "+groupStr+" to review.");
				//if(usersGroup != currentGroup && approvalsPending > 0) mySummary.push("Waiting for "+approvalsPending+" approvers in "+groupStr+" to review.");
			}
		}else if (this.creative.state == CreativeState.DONE){
			mySummary.push("This creative has been approved.");
		}
		//return myCount;
		this.setSummaryVO(mySummary, myCount);

		//console.log("mySummary",mySummary);

	}

	setSummaryVO(mySummary, myCount)
	{
		this.taskSummaryVO = {mySummary, myCount};
	}	
	respondToTaskAction(action:string, task:Task=null, response:any = null)
	{
		//console.log("respondToTaskActions");
		// TODO better decide with actions will need to trigger what calls
		// workflow is needed for approval group info at a guess? - loads channels and workflow
		// actions that could change that side of things are
		// build_started -> production owner change
		// 
		// define arrays of actions against responses
		// workflows are for getting info about people in approval groups and their approval tasks states

		//reset the creative to the latest version in case an older version was selected before actioned - need to make sure user is reviewing the latest assets (only affetcs users with multiple roles submittng to themselves)

		if(response && response.request?.action){
			if(TaskEvent.resetToLatestVersion.includes(response.request.action))	this.version = -1;
		}

		let reloadTriggered = false;
		if(TaskEvent.reloaderActions.includes(action)){
			//main creative view 
			this.reloadCreative();
			if(this.panelOpen )
			{
				//updates mini-grid only
				this.tempSub = this.projectService.loadWorkflows(this.project.uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`, `creatives:[creative2.uuid:${this.creative.uuid}]`]);
			}
			reloadTriggered = true;
		};

		// go and get all the tasks if creative reloaded, user is Admin or this has been triggered by a user's own task action not a message
		if(reloadTriggered || this.isAdmin() || task) 
		{ 			
			this.getTasks(); 
		}

		// load assets if not from me: was (... && useruuid && useruuid != task.user_uuid)
		if(TaskEvent.loadCreativeAssets.includes(action) && !task) 
			this.loadAssets();
	}

	forceTasksAngularUpdate() : void {
		this.tasks = this.tasks.concat([]);	// dirty trigger update
	}
	mergeResponse(response:ApiResponse<Task>) // no longer used
	{
		//console.log("MERGE RESPONSE", response);
		let request:any = response.request;
		if(request?.action == "delete")
		{
			let returnedTask:Task = response.data[0];
			this.tasks.splice(this.tasks.findIndex(t => t.uuid == returnedTask.uuid), 1);
		}else{
			let returnedTask:Task = response.data[0];
			let merged = false;
			for (let i = 0; i < this.tasks.length; i++) {
				const task = this.tasks[i];
				if(task.uuid == returnedTask.uuid)
				{
					//Task.merge(task, returnedTask);
					Task.decorateTask(returnedTask);
					this.tasks.splice(i, 1, returnedTask);
					merged = true;
					if(this.selectedTask && this.selectedTask.uuid == returnedTask.uuid)
					{
						this.selectTask(returnedTask);
					}
					break;
				}
			}		
			if(!merged && request?.action == 'store')
			{
				// cant match on uuid so lets try other criteria.. might be better just to delete one when it is submitted then no need to merge just add
				for (let i = 0; i < this.tasks.length; i++) {
					const task = this.tasks[i];
					if(task.state == "local" && task.uuid == response.request.task.uuid)
					{
						//Task.merge(task, returnedTask);
						Task.decorateTask(returnedTask);
						this.tasks.splice(i, 1, returnedTask);
						merged = true;
						break;
					}
				}
			}
		}

		this.forceTasksAngularUpdate();
		// re-load things at this point a bit messy but ok for now
		// TODO improve this..	
		if(!response.request.action) return;
		this.getTasks();
	}
	handleTaskEvent(e:any)
	{

	}
	onActionBarMouseDown(e:DraggableEvent)
	{
		// @ts-ignore
		const actionBar:HTMLElement = document.getElementsByClassName("action-bar")[0];
		// @ts-ignore
		const actionBarHandle:HTMLElement = document.getElementsByClassName("action-bar-handle")[0];
		const powerBarHandleBounds = actionBarHandle.getBoundingClientRect();
		const powerBarBounds = actionBar.getBoundingClientRect();
		const powerBarParentsBounds = actionBar.parentElement.getBoundingClientRect();
		const left = powerBarBounds.left - powerBarParentsBounds.left;
		const top = powerBarBounds.top - powerBarParentsBounds.top;
		this.actionBarPos.x = left;
		// TODO - understand this line below
		this.actionBarPos.y = powerBarBounds.top - powerBarParentsBounds.bottom;
		//this.actionBarPos.top = top;
	}
	onActionBarDragUpdate(e:DraggableEvent)
	{
		// @ts-ignore
		let actionBar:HTMLElement = document.getElementsByClassName("action-bar")[0];
		let save:boolean = false;

		//e.data.event;
		switch(e.type){
			case "start":
				// store the offset
				console.log("delta", e.data.delta, this.actionBarPos)
				//this.actionBarPos.x = 0;
				//this.actionBarPos.y = 0;
				//return;
				actionBar.classList.add("action-bar-dragging");
				break;
			case "drag":

				break;
			case "stop":
				actionBar.classList.remove("action-bar-dragging");
				save = true;
				break;
		}

		
		actionBar.style.transform = `translate(${this.actionBarPos.x}px, ${this.actionBarPos.y}px)`;
		actionBar.style.left = actionBar.style.right = actionBar.style.top = actionBar.style.bottom = null;
		
		// need to no allow it to be dragged out of the area
		// then convert to a percentage so it can be resized
		var powerBarBounds = actionBar.getBoundingClientRect();
		let powerBarParentsBounds = actionBar.parentElement.getBoundingClientRect();
		

		var left = powerBarBounds.left - powerBarParentsBounds.left;
		var right = powerBarParentsBounds.right - powerBarBounds.right;
		var top = powerBarBounds.top - powerBarParentsBounds.top;
		var bottom = powerBarParentsBounds.bottom - powerBarBounds.bottom;
		if(left < 0)
		{
			this.actionBarPos.x -= left;
			actionBar.style.transform = `translate(${this.actionBarPos.x}px, ${this.actionBarPos.y}px)`;
		}
		if(right < 0)
		{
			this.actionBarPos.x += right;
			actionBar.style.transform = `translate(${this.actionBarPos.x}px, ${this.actionBarPos.y}px)`;
		}
		if(top < 0)
		{
			this.actionBarPos.y -= top;
			actionBar.style.transform = `translate(${this.actionBarPos.x}px, ${this.actionBarPos.y}px)`;
		}
		if(bottom < 0)
		{
			this.actionBarPos.y += bottom;
			actionBar.style.transform = `translate(${this.actionBarPos.x}px, ${this.actionBarPos.y}px)`;
		}

		//console.log("left", left, "right", right, "top", top, "bottom", bottom);
		let leftPercent = round((left/powerBarParentsBounds.width)*100);
		let rightPercent = round((right/powerBarParentsBounds.width)*100);
		let topPercent = round((top/powerBarParentsBounds.height)*100);
		let bottomPercent = round((bottom/powerBarParentsBounds.height)*100);
		let widthPercent = round((powerBarBounds.width/powerBarParentsBounds.width)*100);

		//console.log(leftPercent + rightPercent + widthPercent);

		leftPercent = clamp(leftPercent, 0, 100);
		rightPercent = clamp(rightPercent, 0, 100);
		topPercent = clamp(topPercent, 0, 100);
		bottomPercent = clamp(bottomPercent, 0, 100);
		//console.log("leftPercent", leftPercent, "rightPercent", rightPercent, "topPercent", topPercent, "bottomPercent", bottomPercent);
	
		actionBar.style.transform = null;
		let style = {left:'unset', right:'unset', top:'unset', bottom:'unset'};
		if(leftPercent <= rightPercent)		style.left = leftPercent + "%";
		else								style.right = rightPercent + "%";
		if (topPercent <= bottomPercent)	style.top = topPercent + "%";
		else								style.bottom = bottomPercent + "%";
		// set the styles
		Object.assign(actionBar.style, style);
		// save in local storage
		if(save)
		{
			localStorage.setItem("actionbar", JSON.stringify(style));
		}
		this.updateActionBarBorders(actionBar);
	}
	private updateActionBarBorders(actionBar:HTMLElement)
	{
		// note if parseFloat returns a NaN then if will always be false for '<', '>' or '==' comparisons
		actionBar.style.borderTopLeftRadius = actionBar.style.borderTopRightRadius = actionBar.style.borderBottomLeftRadius = actionBar.style.borderBottomRightRadius = null;
		if(actionBar.style.top != "unset" && parseFloat(actionBar.style.top) <= 0) {
			actionBar.style.borderTopLeftRadius = actionBar.style.borderTopRightRadius = "0";
		}
		if(actionBar.style.bottom != "unset" && parseFloat(actionBar.style.bottom) <= 0) {
			actionBar.style.borderBottomLeftRadius = actionBar.style.borderBottomRightRadius = "0";
		}
		if(actionBar.style.left != "unset" && parseFloat(actionBar.style.left) <= 0) {
			actionBar.style.borderTopLeftRadius = actionBar.style.borderBottomLeftRadius = "0";
		}
		if(actionBar.style.right != "unset" && parseFloat(actionBar.style.right) <= 0) {
			actionBar.style.borderTopRightRadius = actionBar.style.borderBottomRightRadius = "0";
		}
	}
	actionBarPos:any = {x:0, y:0};
	position:{x:number, y:number} = {x:NaN, y:NaN};
	lastPosition:{x:number, y:number} = {x:0, y:0};
	dragging:boolean;
	dragbardown(e:MouseEvent)
	{
		// store starting conditions
		const parent = e.target['parentElement'];
		const width = parent.clientWidth;
		// start draggin
		this.dragging = true;
		e.preventDefault();
		const mouseX = e.clientX;
		const mouseY = e.clientY;

		const positionX = !isNaN(this.position.x) ? this.position.x : mouseX;
		const positionY = !isNaN(this.position.y) ? this.position.y : mouseY;
		const handleDrag = (e) => {
			const dx = e.clientX - mouseX;
			const dy = e.clientY - mouseY;
			this.position.x = positionX + dx;
			this.position.y = positionY + dy;
			this.lastPosition = { ...this.position };

			let newWidth = width - dx;
			parent.style.width = newWidth + 'px';
			if(newWidth < 250)	// perhaps make it a percentage
			{
				if(!parent.classList.contains("close")) parent.classList.add("close");
			} else {
				if(parent.classList.contains("close")) parent.classList.remove("close");
			}
		  };
		
		  const finishDrag = (e) => {
			window.document.removeEventListener('mousemove', handleDrag);
			window.document.removeEventListener('mouseup', finishDrag);
			this.dragging = false;
			if(parent.classList.contains("close")) {
				parent.classList.remove("close");
				this.creativePanels.clear();
			}
		  };
		
		  window.document.addEventListener('mousemove', handleDrag);
		  window.document.addEventListener('mouseup', finishDrag);
	}
	public creativePanels:Map<CreativeMenu, boolean> = new Map();
	public CreativeMenu = CreativeMenu;
	toggleCreativePanel(item:CreativeMenu)
	{
		console.log("creative panel", item);
		if(this.creativePanels.has(item))
		{
			this.creativePanels.delete(item);
		} else {
			this.creativePanels.set(item, true);
		}
		if(item == CreativeMenu.Log && this.creativePanels.has(item))
		{
			this.loadLog();
		}
		if(item == CreativeMenu.Discussion && this.creativePanels.has(item))
		{
			this.loadDiscussion();
		}
		if(this.creativePanels.size == 0)
		{
			// no panels open
			
		}
	}
	onSubTabChange(e:MatTabChangeEvent) : void
	{
		
		//console.log("onSubTabChange",  e.tab.textLabel, e.index, e);
		if(e.tab.textLabel != "assets")
		{
			this.assetsComponentService.selectedAsset = null;
			if(this.assetEditMode)	this.setAssetEditMode(false);
		}
		if(e.tab.textLabel == "assets")
		{
			this.setAssetEditMode(true);
		}
		this.showMarkup = e.tab.textLabel == "amends";

		// load the log when switching to the tab
		if(e.tab.textLabel == "log")
		{
			this.creativeService.loadLog(this.creative_uuid);
		}
	}



	
	leaveChats(creativeOnly:boolean = false)
	{
		if(this._listening)
		{			
			if(this.creative)
			{
				this.pusherService.leaveCreativeChat(this.creative.uuid);
				this.pusherService.projectChat.whisper('creative', {leaving:true, message:this.creative.uuid, extra:'leaveChats'});
			}
			if(!creativeOnly)	this.pusherService.leaveProjectChat(this.creative["project"].uuid);
		}
	}

	// utility - put these in base class or utils
	formatUser(user: any, short:boolean = false){
		if(short)
		{
			let parts = user.name.split(' ');
			if(parts.length == 1) return user.name;
			else return user.parts[0] + "." + parts[parts.length-1].charAt(0);
		}else{
			return user.name;
		}
	}
	formatDate(timestamp, short:boolean = false)
	{
		const date = new Date(timestamp);
		// https://www.w3schools.com/jsref/jsref_tolocalestring.asp
		return date.toLocaleString();	// date.toLocaleDateString() + ", " + date.toLocaleTimeString();
	}
	// action -> by -> time
	formatLog(log:any)
	{
		let result:any = {};
		if(!log.action)//if(log.state) // sometime state was null for tasks (new production tasks) so switched to check for absence of action
		{
			// task
			if(log.user) result.user = log.user.name;
			else result.user = "app";
			if(log.created_at)	result.time = dateToAge(log.created_at);
			result.message = `${log.type} task created by`;
		}else{
			// task action
			if(log.user) result.user = log.user.name;
			if(log.creative_version != undefined) result.version = log.creative_version;
			else result.user = "app";
			if(log.created_at)	result.time = dateToAge(log.updated_at);
			if(log.task.type == "production")
			{
				result.message = `task ${log.action} by`;
			}else if(log.task.type == "approval")
			{
				if(log.action == "Submitted") log.action = "submitted amends"; 	// TODO remove this filth
				result.message = `task ${log.action} by`;
			}else if(log.task.type == "amend")
			{
				result.message = `task ${log.action} by`;
			}
		}
		// TODO inject user uuid for linking and task link also?
		return result;
	}
	copyCreative(creative:ICreative = null)
	{
		const dialogRef = this.dialog.open(CopyCreativeDialogComponent, {
			data: {
					creative:this.creative
				}
		  });
		dialogRef.afterClosed().subscribe((result: CopyCreativeDialogData) => {
			if(result)
			{

			}
		});
	}
	getVersions(minVersion = 0, maxVersion = 0)
	{
		// TODO adapt this based on your permissions, i.e. if you are an approver clamp it to your approval task version
		// might need to defer untill tasks loaded
		//let versions = [];
		this.versions = [];
		
		for(let i = minVersion; i <= maxVersion; i++)//this.creative.version
		{
			this.versions.push(i);
		}
		//return versions;
	}

	onVersionChange(e)
	{
		this.changeVersion(e.value);
		
	}
	changeVersion(version)
	{
		if(this.version == version) return;
		this.version = version;
		//this.isLatestVersion = this.version == -1 || this.version == this.creative?.version;
		this.isLatestVersion = this.version == -1 || this.version == this.creative?.version_latest;
		this.reloadCreative();
		this.loadAssets();
		this.assetsComponentService.selectedAsset = null;
	}
	clearFileDrop()
	{
		this.creativeViewer.assetElementList.forEach(assetComponent => {
			assetComponent.fileOver = false;
		});
	}
	handleFileOver(event:DragEvent)
	{
		this.clearFileDrop();
		if(event.ctrlKey)
		{
			let assets = this.getAssetsUnderPoint(event.clientX, event.clientY);
			if(assets?.length)
			{
				let assetComponent = assets[assets.length-1];
				// todo visualize a file replace!
				let assetVO = assetComponent.assetVO;
				if(Asset.isFile(assetVO.asset))
				{
					assetComponent.fileOver = true;
				}
			}
		}
	}
	
	handleFileDrop(dndResult:DndResult)
	{
		console.log("handleFileDrop", this.assetEditMode);
		this.clearFileDrop();
		if(false && !this.assetEditMode)
		{
			this.tabGroup.selectedIndex = this.tabGroup._tabs.toArray().findIndex(t => t.textLabel == "assets");
			this.setAssetEditMode(true);
			// TODO build started if not already!
		}
		//any exisitng assets?
		let noAssets = !this.assets?.length;
		// turn on assets panel if not already active
		if(!this.creativePanels.has(CreativeMenu.Assets))
		{
			this.creativePanels.set(CreativeMenu.Assets, true);
		}
		// enable asset dragging
		if(!this.assetsComponentService.draggable)	this.assetsComponentService.draggable = true;
		let files:File[] = [];
		for (let i = 0; i < dndResult.files.length; i++) {
			files.push(dndResult.files[i]);
		}
		let event = dndResult.event;
		let offset = this.creativeViewer.getScrollOffset();
		let position = {x:event.offsetX + offset.x, y:event.offsetY + offset.y};
		let tryReplace = event.ctrlKey && files.length == 1;
		let targetAsset:AssetVO = null;
		if(tryReplace)
		{
			let assets = this.getAssetsUnderPoint(event.clientX, event.clientY);
			//console.log("handleFileDrop", event.clientX, event.clientY, assets.length);
			if(assets?.length)
			{
				targetAsset = assets[assets.length-1].assetVO;
				// todo visualize a file replace!
			}
		}
		//this.assetsManager.onFilesDragged(files, position, targetAsset);
		this.assetsComponentService.onFilesDragged(this.creative.uuid, files, position, targetAsset);
		if(noAssets && this.layout?.name != "gallery") this.askLayout();
	}

	askLayout(){
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Set to layout gallery mode?",
				//subtitle: "",
				body: "Would you like to automatically set the creative's layout to 'gallery' mode?",
				negative: "No thanks",
				positive: "Set 'gallery' mode",
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
				//console.log("setting gallery mode - not implemented");
				let galleryLO = this.layouts.find(lo => lo.name == 'gallery');
				this.updateLayout(galleryLO);
				this.saveLayoutChanges();
			}
		});
	}
	setLabel(color:string)
	{
		this._snackBar.open("Setting label", null, {duration: 4000});
		this.creativeService.setLabel(this.creative_uuid, color).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			this._snackBar.open("label set", null, {duration: 2000});
			this.creative.label = color;
			//this.mergeResponse(response)}
		});
	}

	pauseCreative(isPaused)
	{
		let msgStart = (isPaused) ? "Pausing creative" : "Un-pausing creative";
		let msgEnd	 = (isPaused) ? "Creative paused" : "Creativ un-paused";
		this._snackBar.open(msgStart + " TO DO", null, {duration: 4000});
		
		// TODO add pause/un-pause to creativeService
		/*
		this.creativeService.pauseCreative(this.creative_uuid, isPaused).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			
			this._snackBar.open(msgEnd, null, {duration: 2000});
			this.creative.paused = isPaused;
			//this.mergeResponse(response)}
		});
		*/
	}

	flagSelected(flag:number)
	{
		this._snackBar.open("Setting flag", null, {duration: 4000});
		this.creativeService.setFlag(this.creative_uuid, flag).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			this._snackBar.open("flag set", null, {duration: 2000});
			this.creative.flag = flag;
			//this.mergeResponse(response)}
		});
	}

	
	public savingPublic:boolean = false;
	togglePublic(event)
	{
		this.savingPublic = true;
		this._snackBar.open("Updating public", null, {duration: 4000});
		this.creativeService.updatePublic(this.creative_uuid, this.creative.public).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			this._snackBar.open("public updated", null, {duration: 2000});
			this.savingPublic = false;
			// this.creative.public = response.data[0];
		});
	}
	
	getNumAmendTasks():number
	{
		return this.tasks?.filter(t => t.type == 'amend').length || 0;
	}
	// return the number of tasks that are for me to deal with, actioned or otherwise
	getActionableTasks():any
	{
		if(!this.tasks?.length) return 0;
		let total = 0;
		let totalActioned = 0;
		let messages:string[] = [];
		if(this.isProduction() && this.isProductionState())
		{
			let productionTasks = this.tasks.filter(task => task.open && task.type == TaskType.AMEND && task.role == TaskRole.AMEND_ROLE_PRODUCTION);
			let countProduction = productionTasks.length;
			let countActionedProduction = productionTasks.filter(task => task.actioned).length;
			total += countProduction;
			totalActioned += countActionedProduction;
			if(countProduction && countProduction > countActionedProduction)
			{
				messages.push(`do ${countProduction - countActionedProduction} amends`);
			}else if(countProduction) {
				messages.push(`submit ${countProduction} amends`);
			}
		}
		if(this.isApprover())	 // && this.isApprovalState() <-- don't need state check as amends can be late
		{
			let approverTasks = this.tasks.filter(task => task.open && task.type == TaskType.AMEND && task.role == TaskRole.AMEND_ROLE_APPROVER && task.user_uuid == this.user.uuid);
			let countApprover = approverTasks.length;
			let countActionedApprover = approverTasks.filter(task => task.actioned).length;
			total += countApprover;
			totalActioned += countActionedApprover;
			if(countApprover && countApprover > countActionedApprover)
			{
				messages.push(`do ${countApprover - countActionedApprover} amends`);
			}else if(countApprover){
				messages.push(`submit ${countApprover} amends`);
			}
		}
		if(this.isAmendManager())	//  && this.isAmendManagerState() <-- don't need state check as amends can be late
		{
			let moderatorTasks = this.tasks.filter(task => task.open && task.type == TaskType.AMEND && task.role == TaskRole.AMEND_ROLE_MODERATOR);
			let countModerator = moderatorTasks.length;
			let countActionedModerator = moderatorTasks.filter(task => task.actioned).length;
			total += countModerator;
			totalActioned += countActionedModerator;
			if(countModerator && countModerator > countActionedModerator)
			{
				messages.push(`do ${countModerator - countActionedModerator} amends`);
			}else if(countModerator){
				messages.push(`submit ${countModerator} amends`);
			}
		}
		return {total, totalActioned, totalUnactioned: total - totalActioned, messages};
	}
	filterTasks = (task:ITask) =>
	{
		//now main task buttons have been moved out of amend spanel, just filter for 'amend' type tasks
		if(!this.showClosed && !task.open) return false;
		if(task.type != 'amend') return false;
		if(this.showOtherTasks) return true; //TODO - what's this used for?
		else return task.user_uuid == this.user.uuid;
	}
	// get non-amend tasks
	filterTasks2 = (task:ITask) =>
	{
		if(task.type == 'amend') return false;
		return true;
	}
	getCreativeClass()
	{
		let style = {'state-all':true};//{'fao':this.needsAttention(creative, i)};
		style['state-'+this.creative.state] = true;
		return style;
	}
	getFlag(value)
	{
		return FlagComponent.getIconFromValue(value);
	}
	getIcon()
	{
		return Creative.getIcon(this.creative.state);
		/*
		//this is duplicated in grid.component.ts
	  switch (this.creative.state) {
		case "new":
			return "highlight_alt";//"edit";
		case "build":
			return "settings";
		case "approval":
		case "reviewing":
			return "visibility";
		case "qualifying":
			return "policy";//"manage_search";
		case "amending":
			return "build";
		case "confirming":
			return "gpp_good";//"playlist_add_check";
		case "checking":
			return "search"; //"flaky"
		case "done":	
		case "approved":
			return "check_circle";
		case "delivered":
			return "send";
		case "warning":
			return "warning";
		case "queried"://approver override
			return "question_mark";//"contact_support";
		case "submitted"://approver override
			return "feedback";		
		case "reviewed"://"approved"://approver override
			return "done";		
		default:
			break;
	  }
	  */
	}
	/*
	getSwatchStyle()
	{
		let colA:string = '#' + this.initalColor;
		let colB:string = '#' + this.creative.bg_color;
		return {
			'background-image': `repeating-linear-gradient(135deg, ${colA} 0%, ${colA} 50%, ${colB} 50%, ${colB} 100%)`
		}
	}
	*/
	updateLayout(lo:any)
	{
		if(lo == this.layout) return;
		if(lo.name == 'gallery')
		{
			// the the current task is connected to an asset via a marker then send the gallery to that asset
			if(this.selectedTask?.marker?.asset_uuid)
			{
				this.creativeViewer.galleryGotoAsset(this.selectedTask.marker.asset_uuid)
			}
		}
		if(lo.name == 'fullscreen'){
			this.toggleFullscreen();
		} else {
			//this.layout = lo.name;
			this.layout = lo;
			//can save layout?
			this.showSaveBg = this.canShowSaveBg();//this.saveLayoutChanges();
		}
		this.updateMarkerConnections();

		// cache layout
		if(this.layout.name != 'fullscreen')
		{
			let key = 'creative_settings';
			if(this.layout.name == this.creative?.layout)
			{
				// delete the entry as it was saved anyway
				let settingsString = localStorage.getItem(key);
				let settings;
				if(settingsString)
				{
					settings = JSON.parse(settingsString);
					let creative_settings = settings.creatives[this.creative.uuid];
					if(creative_settings)
					{
						delete settings.creatives[this.creative.uuid];
						localStorage.setItem(key, JSON.stringify(settings));
					}
				}
			}else{
				let settingsString = localStorage.getItem(key);
				let settings;
				if(settingsString)
				{
					settings = JSON.parse(settingsString);
				}else {
					settings = {v:1, creatives:{}};
				}
				let creative_settings = settings.creatives[this.creative.uuid] || {};
				creative_settings.layout = this.layout.name;
				creative_settings.date = Date.now();
				settings.creatives[this.creative.uuid] = creative_settings;			
				localStorage.setItem(key, JSON.stringify(settings));
			}

		}
	}
	assetResizeTrigger()
	{
		// unsued for now
		throw new Error("Unused function");		
		this.creativeViewer.triggerResize();
	}
	undoViewChanges()
	{
		//this.bg_color = this.creative.bg_color;
	 	//this.bg_transparent = this.creative.bg_transparent;
		//let loToFind = this.creative.layout ?? this.defaultLayoutName;
		this.layout = this.findLayout();//layouts.find(lo => lo.name == loToFind);
		this.showSaveBg = false;
	}
	undoBgColChanges()
	{
		this.bg_transparent = this.creative.bg_transparent;
		if(!this.bg_transparent && this.creative.bg_color) this.bg_color = this.creative.bg_color;
		//let loToFind = this.creative.layout ?? this.defaultLayoutName;
		//this.layout = this.findLayout();//layouts.find(lo => lo.name == loToFind);
		this.showSaveBgCol = false;
	}

	saveViewSettings(){
		//this.saveColorChanges();
		this.saveLayoutChanges();
	}
	saveBgColSettings(){
		this.saveColorChanges();
		//this.saveLayoutChanges();
	}

	saveColorChanges()
	{
		this.showSaveBgCol = false;
		let data:any = {};
		if(this.creative.bg_color 		!= this.bg_color) 		data.bg_color 		= (this.bg_color.indexOf('#') != -1) ? this.bg_color.substring(1): this.bg_color;
		if(this.creative.bg_transparent != this.bg_transparent) data.bg_transparent = this.bg_transparent;
		if(this.creative.layout 		!= this.layout.name) 	data.layout 		= this.layout.name;
		this._snackBar.open("saving creative background", null, {duration: 4000});
		this.creativeService.setColorInfo(this.creative.uuid, data).pipe(takeUntil(this._unsubscribe)).subscribe( res => {
			this.creative.bg_color = this.bg_color;
			this.creative.bg_transparent = this.bg_transparent;
			this.creative.layout = this.layout.name;
			this._snackBar.open("creative background saved", null, {duration: 2000});
		});
	}
	saveViewOnly()
	{
		this._snackBar.open("saving creative 'view only' setting", null, {duration: 4000});
		this.creativeService.setViewOnly(this.creative.uuid, this.creative.viewonly).pipe(takeUntil(this._unsubscribe)).subscribe( res => {
			this.respondToTaskAction(TaskEvent.ACTION_VIEWONLY_TOGGLE);
			this._snackBar.open("creative 'view only' setting saved", null, {duration: 2000});
		});
	}
	onCreativeTransparentChange(e:MatSlideToggleChange)
	{
		//console.log(e);
		this.creative.bg_transparent = e.checked;
	}

	toggleTransparent(e:MouseEvent){
		this.creative.bg_transparent = !this.creative.bg_transparent;
	}
	// https://www.npmjs.com/package/ngx-color
	onCreativeColorChange(e:ColorEvent, done:boolean = false)
	{
		this.creative.bg_color = e.color.hex.substring(1);
		//if(done)console.log("DONE");
		//console.log(e);
		this.showSaveBgCol = this.canShowSaveBgCol();
	}

	public clickSwatch(e:MouseEvent)
	{
		e.stopPropagation();
		//e.preventDefault();
		this.bg_transparent = false;
		this.showSaveBgCol = this.canShowSaveBgCol();
	}

	canShowSaveBg(){
		return (
			(this.isAdmin() || (this.isProduction() && this.isProductionState())) &&
			(
				//(this.creative.bg_color && (this.creative.bg_color != this.bg_color)) || 
				//(this.creative.bg_transparent != this.bg_transparent) ||
				(this.creative.layout != this.layout.name && !(this.creative.layout == '' && this.layout.name == this.defaultLayoutName)) 
			)
		);
	}
	canShowSaveBgCol(){
		return (
			(this.isAdmin() || (this.isProduction() && this.isProductionState())) &&
			(
				((this.creative.bg_color != this.bg_color)) //this.creative.bg_color && 
				|| (this.creative.bg_transparent != this.bg_transparent)
				//|| (this.creative.layout != this.layout.name && !(this.creative.layout == '' && this.layout.name == this.defaultLayoutName)) 
			)
		);
	}

	findLayout() {
		// check local cache first
		let key = 'creative_settings';
		let settingsString = localStorage.getItem(key);
		let layoutName;
		if(settingsString)
		{
			try{
				let settings = JSON.parse(settingsString);
				let creative_settings = settings.creatives[this.creative.uuid];
				if(creative_settings)
				{
					layoutName = creative_settings.layout;
				}
			} catch (e)
			{
				layoutName = null;
			}

			
			// TODO error handling plus touch of date and deleting stale
		}
		if(!layoutName) layoutName = this.creative.layout || this.defaultLayoutName;
		return this.layouts.find(lo => lo.name == layoutName);
	}

	updateZoom(zoom:number)
	{
		this.zoom = zoom;
	}
	/**
	 * 
	 * @param assetVO null to delete all, or pass in assetvo to delete
	 */
	deleteAssets(assetVO:AssetVO = null)
	{
		// delete all the assets the user knows about
		const title = assetVO ? "Delete asset" : "Delete all assets";
		const body = (assetVO ? "Are you sure you want to delete this asset?" : "Are you sure you want to delete all the assets in this creative?") + "\nThis cannot be undone.";
		this.ds.openGenericConfirm({title, body}).subscribe(result =>{
			if(result)
			{
				if(assetVO){
					let upload = this.activeUploads.find(upload => upload.data.asset == assetVO.asset.uuid);
					if(upload) upload.cancel();
					if(assetVO == this.selectedAsset) this.selectAsset(null);
					if(!assetVO.asset.uuid)
					{
						this.removeAssets([assetVO.asset]);
					} else {
						this._snackBar.open("deleting asset", "", {duration:4000});
						this.assetsService.delete(assetVO.asset.uuid).subscribe(response => {
							this._snackBar.open("asset deleted", "", {duration:2000});
							this.removeAssets(response.data);//.map(asset => new AssetVO(asset)));
						});
					}
				} else {
					// if any of the assets are uploading we need to cancel them
					this.activeUploads.forEach(upload => upload.cancel());
					this.selectAsset(null);
					this.assetsSaving |= (1<<2);
					this._snackBar.open("deleting all assets", "", {duration:4000});
					let assets = this.assets.map(assetVO => assetVO.asset);
					this.creativeService.deleteAssets(this.creative_uuid, assets).subscribe(response => {
						this.assetsSaving &= ~(1<<2);
						this._snackBar.open("all assets deleted", "", {duration:2000});
						assets = response.data as Asset[];
						this.assetsComponentService.assets = assets.map(asset => new AssetVO(asset));
						this.getTasks();
					});
				}

			}
		});
	}
	deleteCreative()
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Delete Creative",
				subtitle:this.creative.format.name,
				body:"Are you sure you wish to delete this creative",
				positive:"Delete",
				negative:"Cancel"}
		  });
		  dialogRef.afterClosed().subscribe((result: any) => {
			if(result)
			{
				this._snackBar.open("deleting creative...", '', {duration:5000});
				let project_uuid = this.creative['project'].uuid;
				
				this.creativeService.delete(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
					(response) => {
						this._snackBar.open("creative deleted...", '', {duration:5000});
						//go back to grid
						this.router.navigate([`project/${project_uuid}`],{fragment:'Grid'});
					},
					error => this.errorMessage = <any>error
				);
			}
		  });
	}

	toggleViewOnly(){
		this.creative.viewonly = !this.creative.viewonly;
		this.saveViewOnly();
	}

	checkSidePanel(){
		if(this.creativePanels.size == 0)
		{
			/*if(this.isAdmin()){
				this.creativePanels.set(CreativeMenu.Assets, true);
				this.creativePanels.set(CreativeMenu.Amends, true);
			}
			else */ 
			if(((this.creative.state == CreativeState.NEW || this.creative.state == CreativeState.BUILD)) && this.isProduction()){
				this.creativePanels.set(CreativeMenu.Assets, true);
			} 			
			else if(this.creative.state == CreativeState.QUALIFYING && this.isAmendManager()){
				this.creativePanels.set(CreativeMenu.Amends, true);
			} 			
			else if(this.creative.state == CreativeState.QUERIED && this.isApprover()){
				this.creativePanels.set(CreativeMenu.Amends, true);//TODO need to check if user is the author of the queried amend
			}
			else if(this.creative.state == CreativeState.AMENDING && this.isProduction()){
				this.creativePanels.set(CreativeMenu.Amends, true);
			}		 		
		}
	}

}


/*

Thoughts - roles/permissions etc..

should there be any logic on the frontend... I think lets minimise it
anything permission based could be send through by the backend

i.e. api/creative/id/permissions


https://dba.stackexchange.com/questions/12046/what-is-a-basic-model-for-making-a-database-with-users-and-groups/12107#12107
https://medium.com/bluecore-engineering/implementing-role-based-security-in-a-web-app-89b66d1410e4

*/
