import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '../models/user.model';
import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class UserService extends OVBaseService<IUser, string> {
	constructor(protected http: HttpClient) {
		super(http, 'user');
	}
	poke(uuid:string)
	{
		return this._post(`${this.base}/poke`, {uuid});
	}
}
