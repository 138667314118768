import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], property: string, reverse:boolean = false): any[] {
    if (!value || !property)
      return value;
    
    if(property.indexOf('.') != -1)
    {
      let prop = property.split('.');
      var len = prop.length;
      value.sort(function (a, b) {
          var i = 0;
          while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
          if (a < b) {
              return reverse ? 1 : -1;
          } else if (a > b) {
              return reverse ? -1 : 1;
          } else {
              return 0;
          }
      });
      return [...value];
    }
    let sortFn:(a: any, b: any) => any = (a, b) => {
      let value: number = 0;
      if (a[property] === undefined) value = -1;
      else if (b[property] === undefined) value = 1;
      else value = a[property] > b[property] ? 1 : (b[property] > a[property] ? -1 : 0);
      return reverse ? (value * -1) : value;
    };
    value.sort(sortFn);
    return [...value];
  }
}