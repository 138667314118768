<h1 mat-dialog-title>Clone project:: {{data.project.name}}</h1>
<div mat-dialog-content>
	<mat-form-field style="width:100%">
		<mat-label>New name</mat-label>
		<input matInput [(ngModel)]="data.output.name">
	</mat-form-field>
	<p>Select which properties to clone</p>
	<ul>
		<li>
			<mat-checkbox [(ngModel)]="data.output.channels" disabled="true">Channels</mat-checkbox>
		</li>
		<li>
			<mat-checkbox [(ngModel)]="data.output.admins" disabled="true">Admins</mat-checkbox>
		</li>
		<li>
			<mat-checkbox [(ngModel)]="data.output.workflows">Workflows</mat-checkbox>
			<ul *ngIf="data.output.workflows">
				<li>
					<mat-checkbox [(ngModel)]="data.output.users">Users</mat-checkbox>
				</li>
				<li>
					<mat-checkbox [(ngModel)]="data.output.formats">Formats</mat-checkbox>
					<ul *ngIf="data.output.formats">
						<li>
							<mat-checkbox [(ngModel)]="data.output.creatives">Creatives</mat-checkbox>
							<!--

								<ul *ngIf="data.output.creatives">
									<li>
										<mat-checkbox [(ngModel)]="data.output.assets">Assets</mat-checkbox>
									</li>
								</ul>
							-->
						</li>
					</ul>
				</li>
			</ul>
		</li>

	</ul>
	<!--

	  <mat-form-field appearance="fill">
		  <mat-label>Favorite Animal</mat-label>
		  <input matInput [(ngModel)]="data.animal">
		</mat-form-field>
	-->
</div>
<div mat-dialog-actions>
	<button mat-button (click)="this.dialogRef.close(null)">Cancel</button>
	<button mat-button (click)="clone()" cdkFocusInitial>Clone</button>
</div>