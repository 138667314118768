import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Asset, AssetVO } from 'src/app/models/asset.model';

export interface ExportAssetListData {
	assets: AssetVO[];
}

@Component({
  selector: 'app-asset-list-export',
  templateUrl: './asset-list-export.component.html',
  styleUrls: ['./asset-list-export.component.scss']
})
export class AssetListExportComponent implements OnInit {

  assetsControl:FormControl;
  dataControl:FormControl;
	constructor(
    private snackBar:MatSnackBar,
    public dialogRef: MatDialogRef<AssetListExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportAssetListData
  ) { }
	


  ngOnInit(): void {
    this.assetsControl = new FormControl();
    this.dataControl = new FormControl(["name"]);
    this.dataControl.get('name').setValue("name");
    //console.log(this.dataControl.value);
  }
  export()
  {
    //console.log(this.assetsControl.value);
    let settings:string[] = this.dataControl.value;
    let assetsToExport = this.assetsControl.value;
    if(assetsToExport.length)
    {
      let assetsOut = [];
      assetsToExport.forEach((assetVO:AssetVO) => {
        let asset:{ 
          name:string,
          type:string,
          width?:number,
          height?:number,
          x?:number,
          y?:number,
          order?:number,
          visible?:boolean,
          deliverable?:boolean,
          annotation?:string
        } = {name:assetVO.asset.name, type:assetVO.asset.type};
        if(settings.includes("dimensions"))
        {
          asset.width = assetVO.asset.width;
          asset.height = assetVO.asset.height;
        }
        if(settings.includes("position"))
        {
          asset.x = assetVO.asset.x;
          asset.y = assetVO.asset.y;
        }
        if(settings.includes("order"))
        {
          asset.order = assetVO.asset.order;
        }
        if(settings.includes("spec"))
        {
          asset.annotation = assetVO.asset.annotation;
        }
        // asset visibility default true
        asset.visible = settings.includes("visible") ? assetVO.asset.visible : true;
        // asset deliverbility default true
        asset.deliverable = settings.includes("deliverable") ? assetVO.asset.deliverable : true
        assetsOut.push(asset);
      });
      this.copTextToClipboard(JSON.stringify(assetsOut, null, '\t')); // '\t' -> 2
      this.dialogRef.close();
    }else {
      this.copTextToClipboard("");
    }
    
  }
  /**
   * This should be a utility function!
   * Copy text to the clipboard and invoke snackbar
   */
  copTextToClipboard(str:string,label:string=null)
	{	
		navigator.clipboard.writeText(str).then().catch(e => console.log(e));
		let snack_msg = (label != null ? (label + ' ') : '') + "copied to clipboard";
		this.snackBar.open(snack_msg, null, {duration: 2000});
	}
}
