	// It would be nice if would could see if we were saving and if so, for what creatives

import { Observable, Subscriber, Subscription } from "rxjs";

	// hmmm: https://rxjs.dev/guide/operators#creating-new-operators-from-scratch
	export function wrapper<T>(
        initHook:(subscriber?:Subscription, observable?:Observable<T>)=>void = null,
        nextHook:(value?:T) => void = null,
        completeHook:() => void = null,
        errorHook:(err?:any) => void = null,
    ) {
		    return (observable: Observable<T>) =>
		    new Observable<T>((subscriber) => {
                //if(initHook) initHook.length == 2 ? initHook(subscriber, observable) : initHook.length == 1 ? initHook(subscriber) : initHook();
                const subscription = observable.subscribe({
                    next(value) {
                        if(nextHook) nextHook.length == 1 ? nextHook(value) : nextHook();
                        subscriber.next(value);				 
                    },
                    error(err) {				
                        subscriber.error(err);
                        if(errorHook) errorHook.length == 1 ? errorHook(err) : errorHook();
                    },
                    complete() {
                        subscriber.complete();
                        if(completeHook) completeHook();			
                    },
                });
                if(initHook) initHook.length == 2 ? initHook(subscription, observable) : initHook.length == 1 ? initHook(subscriber) : initHook();
                // Return the finalization logic. This will be invoked when
                // the result errors, completes, or is unsubscribed.
                return () => {
                subscription.unsubscribe();
                };
		    });
	  }