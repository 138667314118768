<!-- admin menu-->
<mat-sidenav-container style="height:100%;">
    <mat-sidenav mode="side" opened>
        <mat-list>
            <div mat-subheader>
                <mat-icon>widgets</mat-icon>General</div>
                <!--<a [routerLink]="['./', { outlets: {  admin: ['users'] } }]">Users</a>-->
            <mat-list-item (click)="loadAccounts()"><mat-icon>manage_accounts</mat-icon>Accounts</mat-list-item>
            <mat-list-item (click)="loadUsers()"><mat-icon>people</mat-icon>Users</mat-list-item>
            <mat-list-item *ngIf="false" (click)="loadJobs()"><mat-icon>task</mat-icon>Jobs</mat-list-item>
            <mat-list-item (click)="loadWebsockets()"><mat-icon>hub</mat-icon>Websockets</mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader><mat-icon>query_stats</mat-icon>Monitoring</div>
            <mat-list-item (click)="loadStats()"><mat-icon>insert_chart</mat-icon>stats</mat-list-item>
          </mat-list>
    </mat-sidenav>
    <mat-sidenav-content class="content">
        <router-outlet style="display: none;"></router-outlet>  <!-- name="admin"-->
    </mat-sidenav-content>
</mat-sidenav-container>
  


<div *ngIf="users">
    <h2>active users:</h2>
    <mat-selection-list [multiple]="false"> <!-- (selectionChange)="onProjectSelected($event)"-->
        <mat-list-option *ngFor="let user of users" [value]="user">{{user.name}}</mat-list-option>
    </mat-selection-list>
</div>