<div class="video">
	<video #video class="video" (loadedmetadata)="onMetaData($event)" *ngIf="getPath()" [src]="getPath() | safe:'url'" controls controlsList="nodownload" preload="metadata"></video> <!-- autoplay -->
	<!--
	<div *ngIf="!getPath()">
		<video #video class="video" controls controlsList="nodownload" preload="auto"></video>
		<span class="warning">No video file..</span>
	</div>
	-->
	<div *ngIf="!this.getPath()" class="placeholder" [ngStyle]="getStyle()">
		<div class="center">
        	<mat-icon class="">videocam</mat-icon>
			<span *ngIf="parentView != 'preview'">{{assetVO.asset.name}}</span>
		</div>
        <div *ngIf="parentView != 'preview'" class="message">upload a video file</div>
    </div>   
</div>