import { COMMA, ENTER, P } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { CreativeService } from 'src/app/api/creative.service';
import { ProjectService } from 'src/app/api/project.service';
import { Project2Service } from 'src/app/api/project2.service';
import { TasksService } from 'src/app/api/task.service';
import {map, startWith} from 'rxjs/operators';
import Echo from 'node_modules/laravel-echo/dist/echo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaskEvent } from './task/task.component';
import { Task } from 'src/app/models/task.model';
import { Project } from 'src/app/models/project.model';
import { CreativeState, ICreative } from 'src/app/models/creative.model';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

	private _unsubscribe = new Subject<boolean>();
	
	public creative_id:string;

	public creativeSearch:string = '';

	public user;
	public userPermissions;
	public userRole;

	public creatives:ICreative[];
	public currentCreative:ICreative;
	public projects:Project[];
	public selectedProject:Project

	public tasks:Task[];
	public taskSearch:string = '';
	public selectedTask:Task;
	private errorMessage;


	// users associated with this creative
	public users;
	public selectedUser;
	public selectedUserPermissions;
	public creativeUsers;
	public userActions:any[];

	constructor(private _snackBar: MatSnackBar,
				private route: ActivatedRoute,
				private project2Service: Project2Service,
				private api:ApiService,
				private tasksService:TasksService,
				private projectService: ProjectService,
				private creativeService: CreativeService)
	{
		/*
		let bob = new User("bob", "bob@bob.com");
		bob.name = "bill";
		console.log(bob.isDirty());
		bob.setSource(bob.clone());
		console.log(bob.isDirty());
		bob.name = "bob";
		console.log(bob.isDirty());
		bob.save(amendsService).pipe(takeUntil(this._unsubscribe)).subscribe(
			result => {
				console.log("saved", result);
			},
			error => this.errorMessage = <any>error
		);*/

		/*
		let amend = new Amend();
		amend.request = "hi from angular " + Math.random();
		amend.save(amendsService).pipe(takeUntil(this._unsubscribe)).subscribe(
			result => {
				console.log("saved", result);
			},
			error => this.errorMessage = <any>error
		);*/
		this.permissionsInit();

		// TODO @benw carry on this sexy work, interfaces vs casing (tricky with deep objects).. hmmmm
		/*
		this.project2Service.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				const projs:Project2[] = response.data as Project2[];
				console.log("BINGO BANGL", Project2.hello(projs[0]))
			},
			error => this.errorMessage = <any>error
		);*/
	}

	ngOnInit(): void {
		
		//use route to get creative id, in the mean time...
		//console.log("this.route", this.route);
		this.route.params.subscribe(params => {
			if(params['uuid']){
				//console.log("route params", params);
				//this.api.getCreative({creative_id: this.uuid});
				//this.api.getAssetsForCreative({creative_id: this.uuid});
			}
		});
		this.creative_id = "1a8bd919-bb5b-427c-a8d2-cbb6cb98ef8c";

		// get the current user
		this.getUser();

		this.loadProjects();
		//this.loadCreatives();		

		// https://pusher.com/docs/channels/getting_started/javascript
		/*
		// @ts-ignore
		var pusher = new window.Pusher('testapp', {
			cluster: 'mt1'
		  });
		  var channel = pusher.subscribe('events');
		  channel.bind('my-event', function(data) {
			alert('An event was triggered with message: ' + data.message);
		  });*/
		  return;
// @ts-ignore
		  window.Pusher = require('pusher-js');
		  console.log("TOKEM", this.api.token);
		  // @ts-ignore
			window.Echo = new Echo({
				broadcaster: 'pusher',
				key: "websocketkey",//process.env.MIX_PUSHER_APP_KEY,
				cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
				forceTLS: false,
				wsHost: window.location.hostname,
				wsPort: 8443,
				auth: {
					headers: {
						//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
						Authorization: 'Bearer ' + this.api.token,// BEARER_TOKEN,
					},
				},
			});
		  // @ts-ignore
		  window.Echo.channel('events').listen('RealTimeMessage', (e) =>{
			  this._snackBar.open(e.message, null, {duration: 2000});
		  });
		  // @ts-ignore
		  window.Echo.private('events').listen('RealTimeMessage', (e) =>{
			this._snackBar.open("private: " + e.message, null, {duration: 2000});
		});
	}
	getCreatives():ICreative[]
	{
		if(!this.creativeSearch) return this.creatives;
		let creatives:ICreative[] = [];
		for (let i = 0; i < this.creatives.length; i++) {
			const creative:ICreative = this.creatives[i];
			if(creative.name.indexOf(this.creativeSearch) != -1)
			{
				creatives.push(creative);
			}
		}
		return creatives;
	}
	loadProjects()
	{
		this.projectService.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.projects = response["data"];
			},
			error => this.errorMessage = <any>error
		);
	}
	loadCreatives()
	{
		this.creativeService.findAll({project:this.selectedProject.uuid}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.creatives = response["data"] as ICreative[];
				//console.log("creatives", response);
			},
			error => this.errorMessage = <any>error
		);
	}
	getCreative(id:number)
	{
		// TODO lock the page
		this.creativeService.findOne(id.toString()).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.currentCreative = response["data"] as ICreative;
				//console.log("getCreative", this.currentCreative);
				// this.amends = this.currentCreative.amends as Amend[]; 
				this.tasks = this.currentCreative.tasks as Task[];
				this.onCreativeLoaded();
			},
			error => this.errorMessage = <any>error
		);
	}
	onCreativeLoaded()
	{
		this.getCurrentUserPermissions();
		/*
		this.creativeService.getUserActions(this.currentCreative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.userActions = response["data"];
			},
			error => this.errorMessage = <any>error
		);
		*/
		this.tasksService.getTasks(this.currentCreative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.tasks = response.data as Task[];
				//console.log("TASKS", this.tasks);
			},
			error => this.errorMessage = <any>error
		);

		this.project2Service.findAll("").pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				//console.log("TASKS", response.data);
			},
			error => this.errorMessage = <any>error
		);
	}
	onTaskSelected(e)
	{
		//console.log("task selected", e);
		if(e.type == TaskEvent.SELECT)
		{
			if(this.selectedTask && this.selectedTask.id == e.task.id)
			{
				this.selectedTask = null;
			}else{
				this.selectedTask = e.task;
			}
		}
	}
	onProjectSelected(e)
	{
		this.selectedProject = e.option.value as Project;
		//console.log(e, e.value, this.selectedProject);
		this.loadCreatives();
		this.getUsers();
	}
	onUserSelected(e)
	{
		this.selectedUser = e.option.value;
		this.getSelectedUserPermissions();
		//console.log("on user selected", this.selectedUser);
	}
	onCreativeSelected(e)
	{
		//console.log("onCreativeSelected");
		// reset user role
		this.userRole = null;
		let selectedCreative = e.value as ICreative;
		this.getCreative(selectedCreative.id);		
	}
	handleTaskEvent(e:any)
	{
		
	}
	getUser()
	{
		//console.log("Amends::getUser TODO improve this workflow");
		this.user = this.api.getCurrentUser();
		if(this.user) this.user = this.user.user;
		//console.log("\t", this.user);
		this.api.currentuser.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.user = res.user;
			//console.log("\t", this.user);
			// TODO - check if and why we need this? - maybe if user is null
		});
	}
	
	onRoleChanged(e){
		/*
		console.log("roll change", e.value);
		this.creativeService.setUserRole(this.currentCreative.uuid, this.user.uuid, e.value).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			console.log("\tgetUserRole", res);
			this.getUserRole();
		});*/
	}
	getUserRole()
	{
		/* not used ??
		this.creativeService.getUserRole(this.currentCreative.uuid, this.user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//console.log("\tgetUserRole", res);
			this.userRole = res["data"]["role"];
		});
		*/
	}

	getUsers()
	{
		this.projectService.getUsers(this.selectedProject.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//console.log("\tgetUsers", res);
			this.users = res["data"];
		});
	}
	getSelectedUserPermissions()
	{
		this.projectService.getUserPermissions(this.selectedProject.uuid, this.selectedUser.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//console.log("\tselected user permissions", res);
			this.selectedUserPermissions = res["data"];
		});
	}
	getCurrentUserPermissions()
	{
		this.projectService.getUserPermissions(this.selectedProject.uuid, this.user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//console.log("\tcurrent user permissions", res);
			this.userPermissions = res["data"];
			this.user.permissions = this.userPermissions;
			// TODO better user permissions system
			//this.user.permissions = {creative:{[this.currentCreative.uuid]:this.userPermissions}};
		});
	}

	/* amend actions */


	/**
	 * Can the current user add a new amend
	 * - depends on creative state
	 * - depends on any existing new Amends
	 */
	
	// TODO could just make these strings perhaps or strings grouped under scope
	hasPermission(permission:string, fuzzy:boolean = false):boolean
	{
		if(!this.userPermissions) return false;
		for (let i = 0; i < this.userPermissions.length; i++) {
			const perm = this.userPermissions[i];
			if(!fuzzy)
			{
				if(perm.name == permission) return true;
			}else{
				if(perm.name.indexOf(permission) != -1) return true;
			}			
		}
		return false;
	}

	/**
	 * Can the current user set the creative as completed
	 * User must have production role
	 * All (if any) amends must be completed or rejected or a combination of those two states
	 * At least one asset must have been updated / uploaded?
	 */
	creativeCanComplete():boolean {
		if(this.doesUserPermissionsMatchState())
		{
			// check all amends
			//let unactionedAmends = arraySearch(this.amends, {open:true, state:"todo"});		// , version:this.currentCreative.version
			//if(unactionedAmends.length == 0) return true;
		}
		return false;
	}
	doesUserPermissionsMatchState(state:string = this.currentCreative?.state)
	{
		if(!state || !this.currentCreative?.state || state != this.currentCreative?.state) return false;
		switch (state) {
			case "production":
				return this.hasPermission("production");
			case "approval":
				return this.hasPermission("approval", true);
			default:
				return false;
		}
	}
	/**
	 * Can the current user approve the creative
	 */
	creativeCanApprove():boolean{
		if(this.doesUserPermissionsMatchState())
		{


			return true;
		}
		return false;
	}
	/**
	 * Can the current user submit requested amends
	 */
	creativeCanSubmitAmends():boolean{
		if(!this.currentCreative) return false;
		if(this.currentCreative.state == CreativeState.APPROVAL && this.hasPermission("approval", true))
		{
	
		}
		return false;
	}

	creativeComplete()
	{
		// double check
		this.creativeService.creativeComplete(this.currentCreative.id).pipe(takeUntil(this._unsubscribe)).subscribe(
			(result:any) => {
				//console.log("creativeComplete", result);
				// reload the creative
				this.getCreative(this.currentCreative.id);
			},
			error => this.errorMessage = <any>error
		);
	}
	creativeApprove()
	{
		// double check
		this.creativeService.creativeApprove(this.currentCreative.id).pipe(takeUntil(this._unsubscribe)).subscribe(
			(result:any) => {
				//console.log("creativeComplete", result);
				// reload the creative
				this.getCreative(this.currentCreative.id);
			},
			error => this.errorMessage = <any>error
		);
	}
	creativeSubmitRequests()
	{
		// double check
		this.creativeService.creativeSubmitRequests(this.currentCreative.id, this.currentCreative.amends).pipe(takeUntil(this._unsubscribe)).subscribe(
			(result:any) => {

				// reload the creative
				this.getCreative(this.currentCreative.id);
			},
			error => this.errorMessage = <any>error
		);
	}

	/* USER PERMISSIONS*/
	allPermissions: string[] = ['access', 'production', 'approval'];
	filteredPermissions: Observable<string[]>;
	permissionSelectable = true;
	permissionRemovable = true;
	permissionSeparatorKeysCodes: number[] = [ENTER, COMMA];
	permissionAddOnBlur = true;
	permissionCtrl = new FormControl();

	@ViewChild('permissionInput') permissionInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	

	permissionsInit()
	{
		this.filteredPermissions = this.permissionCtrl.valueChanges.pipe(
			startWith(null),
			map((permission: string | null) => permission ? this._filter(permission) : this.allPermissions.slice()));
	}
	removeUserPermission(permission)
	{
		if(!this.selectedUserPermissions) return;
		const index = this.selectedUserPermissions.indexOf(permission);
		if (index >= 0) {
			// remove permission
			//console.log("removing permission", permission);
			this.apiCall(this.projectService.removeUserPermission(this.selectedProject.uuid, this.selectedUser.uuid, permission.name),
				value => this.getSelectedUserPermissions(),
				error => console.log("error", error)
			)
		}
	}
	addUserPermission(event)
	{
		// Add permission
		this._addUserPermission(event.value);
	
		// Reset the input value
		const input = event.input;
		if (input) {
		  input.value = '';
		}
	}
	_addUserPermission(permission)
	{
		if ((permission || '').trim()) {
			//console.log("adding permission", permission);
			this.apiCall(this.projectService.grantUserPermission(this.selectedProject.uuid, this.selectedUser.uuid, permission),
				value => this.getSelectedUserPermissions(),
				error => console.log("error", error)
			)
		}
	}
	apiCall(call : Observable<any>, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void){
		return call.pipe(takeUntil(this._unsubscribe)).subscribe(next,	error);
	}
	permissionSelected(event: MatAutocompleteSelectedEvent): void {
		this._addUserPermission(event.option.viewValue);
		this.permissionInput.nativeElement.value = '';
		this.permissionCtrl.setValue(null);
	  }
	
	  private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
	
		return this.allPermissions.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
	  }
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}

	// utility - put these in base class or utils
	formatUser(user: any, short:boolean = false){
		if(short)
		{
			let parts = user.name.split(' ');
			if(parts.length == 1) return user.name;
			else return user.parts[0] + "." + parts[parts.length-1].charAt(0);
		}else{
			return user.name;
		}
	}
	formatDate(timestamp, short:boolean = false)
	{
		const date = new Date(timestamp);
		// https://www.w3schools.com/jsref/jsref_tolocalestring.asp
		return date.toLocaleString();	// date.toLocaleDateString() + ", " + date.toLocaleTimeString();
	}


	reloadCreative()
	{
		this.getCreative(this.currentCreative.id);
	}
}


/*

Thoughts - roles/permissions etc..

should there be any logic on the frontend... I think lets minimise it
anything permission based could be send through by the backend

i.e. api/creative/id/permissions


https://dba.stackexchange.com/questions/12046/what-is-a-basic-model-for-making-a-database-with-users-and-groups/12107#12107
https://medium.com/bluecore-engineering/implementing-role-based-security-in-a-web-app-89b66d1410e4

*/

