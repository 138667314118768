import { Component, ElementRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';
import { MatSliderChange} from '@angular/material/slider'
//import * as pdfjsLib from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { AnnotationMode } from 'pdfjs-dist/legacy/build/pdf';
import { IMarker, MarkerOption, MarkerVO } from 'src/app/models/marker.model';
import { getRect } from 'src/app/utils-html';
import { Point } from 'src/app/utils/Point';
import { convertDOMRect } from 'src/app/utils/dom';
import { AssetVO } from 'src/app/models/asset.model';

/**
 * We use this to render pdfs
 * 2 main viewing modes, continuous and single
 * 
 * Flow
 * PDF as soon as available
 * - render visible pages (1 at a time)
 * - render thumbnails for thumbnails in view
 * 
 * For each page include a div with a set spacing between them
 * The component will then inject canvases into the pages depending on which ones are in view
 * A circular canvas buffer will be used initally but this could be optimised based on the nature of scrolling perhaps (or it might do just fine)
 * 
 * On scroll - update the current page as the one "mostly on screen"
 * 
 * On destroy - kill everything, burn it to the ground
 */

enum ViewMode {
	SINGLE,
	CONTINUOUS,
}
enum PageMode {
	SINGLE,
	DOUBLE,
}
enum Region {
	THUMB = 1 << 0,
	PAGE = 1 << 1,
	BOTH = Region.THUMB | Region.PAGE,
}
@Component({
	selector: 'app-pdf',
	templateUrl: './pdf.component.html',
	styleUrls: ['./pdf.component.scss'],
})
export class PdfComponent extends BaseAssetComponent implements OnChanges {

	private static instance:number = 0;
	private version:number = 0;
	private pathCache:string = null;

	@ViewChild('fitToggle') fitToggle : MatButtonToggleGroup;
	@ViewChild('canvas') canvas : ElementRef<HTMLCanvasElement>;
	@ViewChild("view") view:ElementRef<HTMLDivElement>;
	@ViewChild("pageContainer") pageContainer:ElementRef<HTMLDivElement>;
	//@ViewChild("menu") menu:ElementRef<HTMLDivElement>;
	private _menu:ElementRef<HTMLDivElement>;
	@ViewChild('menu') set menu(ref:ElementRef<HTMLDivElement>)
	{
		this._menu = ref;
		if(this._menu)
		{
			this.sub = fromEvent(this.menu.nativeElement, "scroll").subscribe(e =>{
				// we need to debounce this
				// check to see which thumbnails are visible, and if they have not been loaded, load em
				// we could also probably look at the scroll position and try to make a best guess at a good starting point rather than search all the pages
				//this.isElementVisible(this.menu.nativeElement, this.menu.nativeElement.firstElementChild);
				//console.log(this.isVisible2(this.menu.nativeElement, this.menu.nativeElement.firstElementChild));
				// we can loop through the	
				//this.checkThumbnails();
				this.thumbDebouncer.next(e);
			});
			this.checkThumbnails();
		}
	}	
	get menu(): ElementRef<HTMLDivElement> {
		return this._menu;
	}
	


	public SCALE:number = 96/72;  // 4/3  (https://pdfjs.express/documentation/viewer/coordinates.8)
	private paddingX = 5;
	private paddingY = 10;

	private mainScrollTop:number = 0;
	private mainScrollDirection:number = 0;
	private thumbScrollTop:number = 0;
	private thumbScrollDirection:number = 0;

	private debouncer: Subject<any> = new Subject();
	private mainDebouncer: Subject<any> = new Subject();
	private thumbDebouncer: Subject<any> = new Subject();

	// view settings
	public viewMode:ViewMode = ViewMode.CONTINUOUS;
	public pageMode:PageMode = PageMode.SINGLE;
	public coverMode:boolean = false;

	// cache canvases for reuse (max 6 pages?)
	private canvasCache:HTMLCanvasElement[];
	private maxCanvasCount:number = 6;
	private renderQueue:any[];
	pdf:any;

	pages:any[];
	thumbnails:any[];
	showThumbnails:boolean = false;

	page:any;
	fit:string[] = [''];

	numPages:number;
	_currentPage:number = 1;
	_requestedPage:number = 0;
	canScrollPages: boolean = false;

	renderingPage:boolean = false;
	renderTask:any;
	outline: any;

	set currentPage(value:number)
	{
		if(this._currentPage == value) return;
		this._currentPage = value;
		this.scrollToPage(this._currentPage - 1);
		//this.queueRenderPage(this._currentPage);
	}
	get currentPage():number{
		return this._currentPage;
	}

	firstZoom:boolean = true;
	_currentZoom:number = 1;
	set currentZoom(value:number)
	{
		if(this._currentZoom == value) return;
		this._currentZoom = value;
		//this.queueRenderPage(this.currentPage);
	}
	get currentZoom():number{
		return this._currentZoom;
	}
	observer:ResizeObserver;
	constructor(private _element:ElementRef)
	{
		super();
		PdfComponent.instance++;
		//pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
		// https://stackoverflow.com/questions/49822219/how-to-give-pdf-js-worker-in-angular-cli-application
		pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

		this.defaultWidth = 420;
		this.defaultHeight = 584;
		
		this.canvasCache = [];
		this.renderQueue = [];
		for (let i = 0; i < this.maxCanvasCount; i++) {
			var canvas = document.createElement('canvas');
			this.canvasCache[i] = canvas;
			this.renderQueue[i] = {};
		}


	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.observer?.unobserve(this.view.nativeElement);
	}
	ngOnChanges(changes: SimpleChanges = null): void {
		if(changes?.assetVO) {
			this.loadAsset();
		}
		// todo load pdf here (depending on what has changed)
	}
	// Approach to get path updates detected when changed from distant components
	ngDoCheck() {
		// would be nice to avoid this but the uri/path cache helps
		this.loadAsset();
	}
	ngOnInit(): void {
		//console.log("PDFJS NGINIT", this.getPath());

		//const loadingTask = pdfjsLib.getDocument('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf');

		this.loadAsset();
		//var canvas = document.getElementById('canvas') as HTMLCanvasElement;
		//canvas.addEventListener("scroll", this.onScroll);
		this.debouncer.pipe(debounceTime(250)).subscribe(event => {
			//this.functionToBeDebounced(event);
			this.currentPage = event;
		});

		this.mainDebouncer.pipe(debounceTime(64)).subscribe(event => {
			this.checkPages();
		});
		this.thumbDebouncer.pipe(debounceTime(64)).subscribe(event => {
			this.checkThumbnails();
		});
	}
	loadAsset()
	{
		let path:string = this.getPath();
		if(!path) return;
		if(this.pathCache == path) return;
		this.version++;
		//console.log("pdf: loading...",PdfComponent.instance, this.version, this.pathCache, path);
		this.pathCache = path;
		
		let origin = window.location.origin;
		const loadingTask = pdfjsLib.getDocument({
				url:path,
				httpHeaders:{
					'Origin':origin,
					'Accept':'application/json, application/pdf, text/plain, */*',
					'Content-Type':'application/json'
				},
				withCredentials:true,
			})
		loadingTask.promise.then(pdf => {
			//console.log("pdf loaded", pdf);
			this.pdf = pdf;
			this.numPages = pdf.numPages;			
			this.parsePDF();			
		});
	}
	parsePDF()
	{
		//console.log("parsePDF()", this.pdf, Date.now());
		
		this.pages = new Array(this.numPages);
		for (let i = 0; i < this.pages.length; i++) {
			this.pages[i] = {page:null};        
		}
		this.parsePDFPage(1);    
	}
	public parsed:boolean = false;
	parsePDFComplete()
	{
		
		//console.log("parsePDFComplete()", this.pdf, Date.now());
		this.thumbnails = new Array(this.numPages);
		for (let i = 0; i < this.thumbnails.length; i++) {
			this.thumbnails[i] = {pageNum:i, loading:false};        
		}
		this.currentPage = 1;
		//this.queueRenderPage(this.currentPage);
		this.checkThumbnails();

		this.renderPage2(0);

		this.doLayout();
		this.checkPages();


		this.pdf.getMetadata().then((info, meta) => {
			//console.log("info", info);
			//console.log("meta", meta);
		}, (reason) => {
			// PDF loading error
			console.error(reason);
		  });

		// mark info
		this.pdf.getMarkInfo().then((markinfo) => {
			//console.log("markinfo", markinfo);
		}, (reason) => {
			// PDF loading error
			console.error(reason);
		  });
		  // doc outline
		  this.pdf.getOutline().then((outline) => {
			//console.log("outline", outline);
			this.outline = outline;
			if (outline) {
			  for (let i = 0; i < outline.length; i++) {
				const dest = outline[i].dest;
			  }
			}
		}, (reason)=> {
			// PDF error loading outline
			console.error(reason);
		  });
		this.parsed = true;
		//console.log("PDF PARSED TRUE");
		
		if(this.wantsScroll)
		{
			this.scrollToPage(this.wantsScroll);
		}
	}

	parsePDFPage(pageNumber:number)
	{
		this.pdf.getPage(pageNumber).then(page =>{

			page.getAnnotations().then((huh) => {
				//console.log("annotation", huh);
			}, (reason) => {
				// PDF loading error
				console.error(reason);
			  });

			this.pages[pageNumber-1].page = page;
			if(pageNumber < this.numPages)
			 {
				this.parsePDFPage(pageNumber+1);
			 }else{
				 this.parsePDFComplete();
			 }
		})
	}
	ngAfterViewInit() {
		if(!this.view) return;
		//console.log("adding scroll listener to view");
		
		this.sub = fromEvent(this.view.nativeElement, "scroll").subscribe(e => {
			this.mainScrollDirection = this.view.nativeElement.scrollTop - this.mainScrollTop > 0 ? 1 : -1;
			this.mainScrollTop = this.view.nativeElement.scrollTop;
			//this.onMainViewScroll();
			this.mainDebouncer.next(1);
			this.layoutChange.emit(null);
		})

		this.observer = new ResizeObserver(this.debounce(entries => {
			if(this.autoFit) this.fitAuto();
		}, 250, () => this.layoutChange.emit(null)));
		this.observer.observe(this.view.nativeElement);
	}
	throttle(func, wait = 250) {
		let isWaiting = false;
		return (...args) => {
		  if (!isWaiting) {
			func.apply(this, args);
			isWaiting = true;
			setTimeout(() => isWaiting = false, wait);
		  }
		};
	}
	debounce(func, wait = 250, alwaysFunc = null) { // Default wait time of 250ms
		let timeout;
		return function executedFunction(...args) {
			if(alwaysFunc)	alwaysFunc.apply(this, args)
		  clearTimeout(timeout); // Clear any previous timeout
		  timeout = setTimeout(() => func.apply(this, args), wait);
		};
	  }
	toggleMenu()
	{
		this.showThumbnails = !this.showThumbnails;
	}
	pageModeToggle()
	{
		this.pageMode = this.pageMode == PageMode.SINGLE ? PageMode.DOUBLE : PageMode.SINGLE;
		this.doLayout();
	}
	pageModeSingle()
	{
		if(this.pageMode == PageMode.SINGLE) return;
		this.pageMode = PageMode.SINGLE;
		this.doLayout();
	}
	pageModeDouble()
	{
		if(this.pageMode == PageMode.DOUBLE) return;
		this.pageMode = PageMode.DOUBLE;
		this.doLayout();
	}
	coverModeToggle()
	{
		this.coverMode = !this.coverMode;
		this.doLayout();
	}
	doLayout()
	{
		// TODO get current top level and try and maintain it (if any - to keep better view on zoom change and mode change)
		let parent = this.pageContainer.nativeElement;
		let children = parent.children;

		// build rows
		let rows = [];
		if(this.pageMode == PageMode.SINGLE)
		{
			for (let i = 0; i < this.pages.length; i++) {
					rows.push([i]);
			}
		}else if(this.pageMode == PageMode.DOUBLE) {
			let i = 0;
			if(this.coverMode)
				rows.push([i++]);
			for(; i < this.pages.length; i+=2){
					i + 1 < this.pages.length ? rows.push([i, i+1]) : rows.push([i]) ;
			}
		}

		
		// we need to calculate the centerline for alignment
		// for single column this is 
		let maxWidths = []; // max widths for entire doc
		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			for (let index = 0; index < row.length; index++) {
				let id = row[index];
				let page = this.pages[id];
				let pageWidth = page.page.view[2] * this.SCALE * this._currentZoom;
				if(maxWidths[index] == undefined || pageWidth > maxWidths[index])
					maxWidths[index] = pageWidth;
			}
		}

		// layout pages
		let y = 0;
		let maxWidth = 0;

		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			
			let height = 0;
			let width = 0;
			for (let index = 0; index < row.length; index++) {
				let id = row[index];
				let child = children[id];
				let page = this.pages[id];
				let pageWidth = page.page.view[2] * this.SCALE * this._currentZoom;
				let pageHeight = page.page.view[3] * this.SCALE * this._currentZoom;
				let x; 
				if(this.pageMode == PageMode.SINGLE){
					// center on x axis
					//x = -(page.page.view[2] * this.SCALE * this._currentZoom) * 0.5;
					x = (maxWidths[index] - pageWidth) * 0.5; // center against biggest
					if(pageWidth > maxWidth)
						maxWidth = pageWidth;
				} else {
					//x = index == 0 ? -(page.page.view[2] * this.SCALE * this._currentZoom) - this.paddingX * 0.5 : this.paddingX * 0.5;
					x = index == 0 ? maxWidths[index] - pageWidth - this.paddingX * 0.5 : maxWidths[0] + this.paddingX * 0.5;
					// covermodeFix (slap that page on the right and side)
					if(i == 0 && this.coverMode) x = maxWidths[0] + this.paddingX * 0.5;
					width += pageWidth + this.paddingX;
					if(width > maxWidth)
						maxWidth = width;
				}
				child['style'].transform = 'translate(' + x + 'px, ' + y + 'px)';
				page.x = x;
				page.y = y;
				page.width = pageWidth;
				page.height = pageHeight;
				if(pageHeight > height) height = pageHeight;
			}
			y += height;
			if(i <= rows.length - 1) y +=this.paddingY;

			//this.pageContainer.nativeElement.style.width = maxWidth + 'px';
			this.pageContainer.nativeElement.style.width = (maxWidths.length == 1 ? maxWidths[0] : maxWidths[0] + maxWidths[1]) + 'px';
			this.pageContainer.nativeElement.style.height = y + 'px';
			this.pageContainer.nativeElement.style.overflow = 'hidden';
			
			//this.view.nativeElement.scrollLeft = 
		}
/*
			if(this.pageMode == PageMode.SINGLE)
			{
				
				y += page.page.view[3] + padding;
			}else{
				let prevPage = i > 0 ? this.pages[i - 1] : null;
				// if heights match then side by side

				if(prevPage && prevPage.page.view[3] == page.page.view[3])
				{

				}
				let x = 0;
				if(i % 2 == 1)
					x = this.pages[i-1].page.view[2] + padding;
				child['style'].transform = 'translate(' + x + 'px, ' + y + 'px)';
				if(i % 2 == 1)
					y += Math.max(this.pages[i-1].page.view[3], page.page.view[3]) + padding;
			}        
		}*/
		this.updateTextLayers();
	}
	getPageAtY(y:number)
	{
		if(!this.pages) return null;
		return this.pages.find(page => page.y <= y && page.y + page.height >= y);
	}
	updateTextLayers()
	{
		let textLayers = this.pageContainer.nativeElement.querySelectorAll(".text-layer");
		for (let i = 0; i < textLayers.length; i++) {
			const textLayer = textLayers[i] as HTMLElement;
			//textLayer.style.transform = `scale(${this._currentZoom})`;			
		}
		let textSelections = this.pageContainer.nativeElement.querySelectorAll(".text-selections");
		for (let i = 0; i < textSelections.length; i++) {
			const textSelection = textSelections[i] as HTMLElement;
			//textSelection.style.transform = `scale(${this._currentZoom})`;			
		}
	}
	/**
	 * 
	 * @param parent 
	 * @param threshold 
	 */
	getVisibleChildren(parent, children = null, threshold:number = 0)
	{
		children = children || parent.children;
		let firstVisible = 0;
		for (let i = 0; i < children.length; i++) {
			let child = children[i];
			let visible = this.isVis(parent, child);
			if(!firstVisible && visible) firstVisible = i + 1;
			// visibility opacity debug
			// child.style.opacity = visible ? 1 : 0.5;
		}
		return firstVisible;
	}
	// could exend this to x (horizontal too in future)
	isVis(parent:HTMLElement, child:HTMLElement, thresholdUp:number = 0, thresholdDown:number = 0) {
		const parentRect = parent.getBoundingClientRect();  // could cache this between child calls
		const childRect = child.getBoundingClientRect();
		return this.doRectsIntersect(parentRect, childRect);
	};
	isCompletelyVis(parent:HTMLElement, child:HTMLElement, thresholdUp:number = 0, thresholdDown:number = 0) {
		const parentRect = parent.getBoundingClientRect();  // could cache this between child calls
		const childRect = child.getBoundingClientRect();
		return this.doRectContain(parentRect, childRect);
	};
	doRectsIntersect(parentRect:DOMRect, childRect:DOMRect, thresholdUp:number = 0, thresholdDown:number = 0)
	{
		// most likely off the bottom then the top
		if(childRect.top > parentRect.bottom || childRect.bottom < parentRect.top) return false;
		return true;
	}
	doRectContain(parentRect:DOMRect, childRect:DOMRect, thresholdUp:number = 0, thresholdDown:number = 0)
	{
		if(childRect.top >= parentRect.top && childRect.bottom <= parentRect.bottom) return true;
		return false;
	}
	onMainViewScroll()
	{
		this.checkPages();
	}
	/**
	 * Update to check which pages are visible on screen
	 * If they are and do not have a canvas child - add it in
	 * If the canvas is the wrong page then clear and load and cache
	 * @returns void
	 */
	checkPages()
	{
		if(!this.pageContainer) return;
		let firstVisible = this.getVisibleChildren(this.view.nativeElement, this.pageContainer.nativeElement.children);
		if(firstVisible)
		{
				if(this._currentPage != firstVisible)
				{
					this._currentPage = firstVisible;
					if(this.showThumbnails) this.scrollToPage(this._currentPage - 1, Region.THUMB);
				}
		}
		// work out which pages need rendering then slap them into a render queue
		// we can also (depending on scroll direction preload a page or 2 in advance)
		// when a page comes into view we can decide if it needs an update/render
		// need update if no previous render or previous render was at incorrect zoom level
		// when we add a job to the queue we can ignore it if it is not unique, i.e. scrolling a page in and out of view should still only produce one task

		// render object can be attached to a page
		// {canvas, zoom, status}
		// canvas: will hold the canvas rendered to, zoom: the zoom level of the render, status: cleared/queued/rendering/rendered/done

		let firstVisibleIndex = -1;
		let lastVisibleIndex = -1;
		for (let i = 0; i < this.pages?.length; i++) {
			let child = this.pageContainer.nativeElement.children[i];
			if(!child) continue;
			let page= this.pages[i];
			/*
			if(page.page && !page.canvas)
			{
				if(this.isVisible2(this.view.nativeElement, child))
				{
						this.renderPage2(i);
				}else{
					page.canvas = null;
				}       
			}*/
			if(page.page)
			{
				
				if(this.isVisible2(this.view.nativeElement, child))
				{
					if(firstVisibleIndex == -1 ) firstVisibleIndex = i;
					lastVisibleIndex = i;
					this.attemptToQueuePage(page, i);
						//this.renderPage2(i);
				}else{

					if(firstVisibleIndex == -1 && this.mainScrollDirection > 0)
					{
						if(page.render?.status == "queued")
						{
							// remove from queue
							this.removeJobFromQueue(i);
						}else if (page.render?.status == "rendering")
						{
							// cancel render
							this.removeJobFromQueue(i);
						}
					}else if (firstVisibleIndex != -1 && this.mainScrollDirection < 0)	// && lastVisibleIndex < i 
					{
						if(page.render?.status == "queued")
						{
							// remove from queue
							this.removeJobFromQueue(i);
						}else if (page.render?.status == "rendering")
						{
							// cancel render
							this.removeJobFromQueue(i);
						}
					}					
				}						 
			}
		}
		

		/*					if(this.mainScrollDirection > 0)
					{
						if(Math.abs(lastVisibleIndex - i) < 3)
						{
							this.attemptToQueuePage(page, i);
						}
					}

		*/
	}
	attemptToQueuePage(page, i)
	{
		if(!page.render)
		{
			let render = {canvas:null, zoom:this._currentZoom, status:'queued', index:i};
			page.render = render;
			this.queuePage(i);
		}if(page.render && page.render.zoom != this._currentZoom )
		{
			if(page.render.status == 'queued')
			{
				// TODO 
				throw new Error("already queued");				
			}
			page.render.zoom = this._currentZoom;
			page.render.canvas = null;
			page.render.status = 'queued'
			this.queuePage(i);
		}
	}
	removeJobFromQueue(index)
	{
		//console.log("clearing queue item", index);
		this.pages[index].render = null;
		
		this._jobQueue
		for (let i = 0; i < this._jobQueue.length; i++) {
			const job = this._jobQueue[i];
			if(job.index == index)
			{
				job.renderTask.cancel();
				this._jobQueue.splice(i, 1);
				return;
			}
			
		}
	}
	checkThumbnails()
	{
		if(!this.menu) return;
		for (let i = 0; i < this.thumbnails?.length; i++) {
			let child = this.menu.nativeElement.children[i];
			if(!child) continue;
			let thumbnail = this.thumbnails[i];
			if(!thumbnail.loading && !thumbnail.url)
			{
				if(this.isVisible2(this.menu.nativeElement, child))
				{
					//console.log("child visible", i)
					this.generateThumbnail(i);
				}            
			}        
		}
	}

	// https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
	isVisible(container, ele) {
		const eleTop = ele.offsetTop;
		const eleBottom = eleTop + ele.clientHeight;

		const containerTop = container.scrollTop;
		const containerBottom = containerTop + container.clientHeight;

		// The element is fully visible in the container
		return (
				(eleTop >= containerTop && eleBottom <= containerBottom) ||
				// Some part of the element is visible in the container
				(eleTop < containerTop && containerTop < eleBottom) ||
				(eleTop < containerBottom && containerBottom < eleBottom)
		);
}
	isVisible2(container, ele) {
		const { bottom, height, top } = ele.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();

		return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
	};
	isElementVisible(parent:HTMLElement, child:Element)
	{
		let scrollTop = parent.scrollTop;
		let scrollBot = scrollTop + parent.clientHeight;
		let parentRect = parent.getBoundingClientRect();
		let childRect = child.getBoundingClientRect();
		
		//console.log("p", parentRect);
		//console.log("c", childRect);
		
	}
	// max width and max height per column (unscaled)
	getPageStats(visibleOnly = false)
	{
		if(!this.pages) return null;
		if(!this.parsed) return null;
		let stats = this.pageMode == PageMode.SINGLE ? [{maxWidth:0, maxHeight:0}] : [{maxWidth:0, maxHeight:0}, {maxWidth:0, maxHeight:0}];
		for (let i = 0; i < this.pages.length; i++) {
			const page = this.pages[i];
			const pageWidth = page.page.view[2];
			const pageHeight = page.page.view[3];
			let column = this.pageMode == PageMode.SINGLE ? 0 : (this.coverMode ? i + 1 : i) & 1;
			let stat = stats[column];
			if(pageWidth > stat.maxWidth) stat.maxWidth = pageWidth;
			if(pageHeight > stat.maxHeight) stat.maxHeight = pageHeight;
		}
		// scale
		for (let i = 0; i < stats.length; i++) {
			const stat = stats[i];
			stat.maxWidth *= this.SCALE;      
			stat.maxHeight *= this.SCALE;      
		}
		return stats;
	}
	autoFit:boolean = true;
	fitAuto()
	{
		this.autoFit = true;
		
		// only shrink - width based
		let stats = this.getPageStats();
		if(!stats) return;
		let targetWidth = this.view.nativeElement.clientWidth;

		// select a zoom value that will 
		if(this.viewMode == ViewMode.SINGLE)
		{
			// only dealing with a single page so easier
			// TODO
		}else{
			if(this.pageMode == PageMode.SINGLE)
			{
				// find the largest width (of all pages or only of in view pages?? hmm maybe just in view pages)
				this.zoomTo(Math.min(targetWidth / stats[0].maxWidth, 1), true);
			}else{
				this.zoomTo(Math.min(targetWidth / (stats[0].maxWidth + stats[1].maxWidth + this.paddingX), 1), true);
			}
		}

		//if(auto)
		//	{
		//		// only shink dont grow
		//		//console.log("auto zoom",this.pageMode == PageMode.SINGLE, targetWidth, stats[0].maxWidth, this._currentZoom)
		//		if(targetWidth > stats[0].maxWidth && this._currentZoom > 1) return;
		//	}
	}
	fitWidth()
	{
		let stats = this.getPageStats();
		if(!stats) return;
		let targetWidth = this.view.nativeElement.clientWidth;

		// select a zoom value that will 
		if(this.viewMode == ViewMode.SINGLE)
		{
			// only dealing with a single page so easier
			// TODO
		}else{
			if(this.pageMode == PageMode.SINGLE)
			{
				// find the largest width (of all pages or only of in view pages?? hmm maybe just in view pages)
				this.zoomTo(targetWidth / stats[0].maxWidth);
			}else{
				this.zoomTo(targetWidth / (stats[0].maxWidth + stats[1].maxWidth + this.paddingX));
			}
		}
	}
	fitHeight()
	{
		let stats = this.getPageStats();
		if(!stats) return;
		let targetHeight = this.view.nativeElement.clientHeight;

		// select a zoom value that will 
		if(this.viewMode == ViewMode.SINGLE)
		{
			// only dealing with a single page so easier
			// TODO
		}else{
			if(this.pageMode == PageMode.SINGLE)
			{
				// find the largest width (of all pages or only of in view pages?? hmm maybe just in view pages)
				this.zoomTo(targetHeight / stats[0].maxHeight);
			}else{
				this.zoomTo(targetHeight / Math.max(stats[0].maxHeight, stats[1].maxHeight));
			}
		}
	}
	fitBest(cover:boolean = false)
	{
		let stats = this.getPageStats();
		if(!stats) return;
		let targetWidth = this.view.nativeElement.clientWidth;
		let targetHeight = this.view.nativeElement.clientHeight;

		if(this.viewMode == ViewMode.SINGLE)
		{
			// only dealing with a single page so easier
			// TODO
		}else{
			if(this.pageMode == PageMode.SINGLE)
			{
				// find the largest width (of all pages or only of in view pages?? hmm maybe just in view pages)
				let widthRatio = targetWidth / stats[0].maxWidth;
				let heightRatio = targetHeight / stats[0].maxHeight;
				this.zoomTo(cover ? Math.max(widthRatio, heightRatio) : Math.min(widthRatio, heightRatio));
			}else{
				let widthRatio = targetWidth / (stats[0].maxWidth + stats[1].maxWidth + this.paddingX);
				let heightRatio = targetHeight / Math.max(stats[0].maxHeight, stats[1].maxHeight);
				this.zoomTo(cover ? Math.max(widthRatio, heightRatio) : Math.min(widthRatio, heightRatio));
			}
		}
	}
	zooms:number[] = [0.25, 0.333, 0.5, 0.667, 0.75, 1, 1.25, 1.5, 2, 3, 4, 5];
	zoomTo(zoom:number, auto:boolean = false)
	{
		if(!auto) this.autoFit = false;
		if(this.currentZoom == zoom) return;
		this._currentZoom = zoom;
		this.doLayout();
		this.checkPages();
		setTimeout( () => this.layoutChange.emit(), 32);
	}
	zoomIn(){
		if(this.currentZoom == this.zooms[this.zooms.length-1]) return; // already at max
		this.autoFit = false;
		let scrollTop = this.view.nativeElement.scrollTop;
		let activePage = this.getPageAtY(scrollTop);
		let delta = activePage.y - scrollTop;
		let ratio = delta / activePage.height;
		// find closest zoom in list then pick next highest
		for (let i = 0; i < this.zooms.length; i++) {
			const value = this.zooms[i];
			if(this._currentZoom < value)
			{
				this.currentZoom = this.zooms[i];
				break;
			}
		}
		this.fit = [''];
		//this.currentZoom += 0.1;
		// clear all canvases
		for (let i = 0; i < this.pages.length; i++) {
			const page = this.pages[i];
			page.canvas = null;
		}
		this.doLayout();
		this.checkPages();		
			
		if(activePage)
		{
			this.view.nativeElement.scrollTop = activePage.y - activePage.height * ratio;
		}

		setTimeout( () => this.layoutChange.emit(), 32);
	}
	zoomOut(){
		if(this.currentZoom == this.zooms[0]) return; // already at max
		this.autoFit = false;
		let scrollTop = this.view.nativeElement.scrollTop;
		let activePage = this.getPageAtY(scrollTop);
		let delta = activePage.y - scrollTop;
		let ratio = delta / activePage.height;

		for (let i = this.zooms.length-1; i >= 0; i--) {
			const value = this.zooms[i];
			if(this._currentZoom > value)
			{
				this.currentZoom = this.zooms[i];
				break;
			}
		}
		//this.currentZoom -= 0.1;
		this.fit = [''];
		// clear all canvases
		for (let i = 0; i < this.pages.length; i++) {
			const page = this.pages[i];
			page.canvas = null;
		}
		this.doLayout();
		this.checkPages();	

		if(activePage)
		{
			this.view.nativeElement.scrollTop = activePage.y - activePage.height * ratio;
		}


		this.layoutChange.emit();
	}
	private wantsScroll:number = 0
	scrollToPage(number, flag:number = Region.BOTH)
	{
		if(this.parsed)
		{
			if(this.wantsScroll)
			{
				this.wantsScroll = 0;
			}
			if(flag & Region.PAGE)  this.scrollElementToTop(this.view.nativeElement, this.pageContainer.nativeElement.children[number] as HTMLElement);
			if(flag & Region.THUMB && this.menu?.nativeElement) this.scrollElementIntoView(this.menu.nativeElement, this.menu.nativeElement.children[number] as HTMLElement);   
		}else{
			this.wantsScroll = number;
		}
	}
	scrollElementToTop(parent:HTMLElement, child:HTMLElement)
	{
		if(!parent || !child) return;
		let target = child.getBoundingClientRect().top - parent.getBoundingClientRect().top;
		let matches = child.style.transform.match(/(\d+\.*\d*)/g);
		target = Number(matches[1]);
		//console.log("TARGET TARGET", target,child.style.transform );
		//console.log("stt",child.getBoundingClientRect(), parent.getBoundingClientRect() )
		parent.scroll({behavior:'smooth', top:target});
	}
	scrollElementIntoView(parent:HTMLElement, child:HTMLElement, useBounds:boolean = false)
	{
		if(!parent || !child) return;
		//child.scrollIntoView();
		//parent.scrollTo(0, child.getBoundingClientRect().top - parent.getBoundingClientRect().top);
		//parent.scrollTop = child.getBoundingClientRect().top - parent.getBoundingClientRect().top;
		//parent.scrollTop = child.offsetTop - parent.offsetTop; // works great
		//child.scrollIntoView(false);
		//child.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
		//https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move
		// annoyingly this fails as it scrolls outer scroll contains first - which is odd!
		// if the child is completly visible do nothing otherwise scroll it into view
		if(!this.isCompletelyVis(parent, child))
		{
			let deltaTop;
			let deltaBottom;
			if(useBounds)
			{
				// this code does not work yet - but we dont need it yet so...
				deltaTop = child.getBoundingClientRect().top - parent.getBoundingClientRect().top;
				deltaBottom = child.getBoundingClientRect().bottom - parent.getBoundingClientRect().bottom;
			}else{
				deltaTop = child.offsetTop - parent.offsetTop;
				deltaBottom = (child.offsetTop + child.clientHeight) - (parent.offsetTop + parent.clientHeight);
			}
			let dt = parent.scrollTop - deltaTop;
			let db = parent.scrollTop - deltaBottom;
			if(Math.abs(dt) > Math.abs(db))
			{
				parent.scroll({behavior:'smooth', top:deltaBottom})
			}else{
				parent.scroll({behavior:'smooth', top:deltaTop})
			}
		}   
	}
	onScroll(e:WheelEvent)
	{
		//console.log("onscroolll")
		this.layoutChange.emit();
		if(this.renderingPage)
		{
			e.preventDefault();
			e.stopImmediatePropagation();
			return;
		}

		if(!this.canScrollPages && this.numPages > 1)
		{
			
			let view = this.canvas.nativeElement.parentElement;
			//console.log(view.scrollTop, view.scrollHeight, view.offsetHeight);
			if(e.deltaY > 0 && ( view.scrollTop >= (view.scrollHeight - view.offsetHeight)))
			{
					this.nextPage();
					view.scrollTop = 0;
					e.preventDefault();
					e.stopImmediatePropagation();
			}else if (e.deltaY < 0 && view.scrollTop <= 0)
			{
					this.prevPage();
					view.scrollTop = view.scrollHeight - view.offsetHeight;
					e.preventDefault();
					e.stopImmediatePropagation();
			}
			return;
		}
		if(this.canScrollPages)
		{
			e.preventDefault();
			e.stopImmediatePropagation();
			if(e.deltaY > 0)
			{
				this.nextPage();
			}else{
				this.prevPage();
			}
		}
	}
	override scrollMarkupIntoView(marker:IMarker)
	{
		//console.log("scrollMarkupIntoView", this.parsed);
		//console.log("scrollMarkupIntoView m", marker);
		//console.log("scrollMarkupIntoView pc", this.pageContainer);
		//console.log("scrollMarkupIntoView pcne", this.pageContainer?.nativeElement != null);
		
		this.scrollToPage(marker.metadata.page, Region.PAGE);
	}
	onSlide(e:MatSliderChange)
	{
		//console.log("on slide", e.value);
		this.debouncer.next(e.value);
	}
	fitChange(event:MatButtonToggleChange)
	{
		let toggle = event.source;
		if (toggle) {
			let group = toggle.buttonToggleGroup;
			if (event.value.some(item => item == toggle.value)) {
				group.value = [toggle.value];
			}
			if(!this.page) return;
			switch(group.value[0])
			{
				case "width":
					var view = this.page.view;
					var dpiFix = 96/72;
					var pageWidth:number = view[2] * dpiFix; 
					this.currentZoom = (this.assetVO.asset.width) / pageWidth;
					//this.currentZoom = (this.assetVO.asset.width * this.scale) / pageWidth;
					break;
				case "height":
					var view = this.page.view;
					var dpiFix = 96/72;
					var pageHeight:number = view[3] * dpiFix;    
					this.currentZoom = (this.assetVO.asset.height) / pageHeight;
					//this.currentZoom = (this.assetVO.asset.height* this.scale) / pageHeight;
					break;
				case "best":
					var view = this.page.view;
					var dpiFix = 96/72;
					var pageWidth:number = view[2] * dpiFix;
					var pageHeight:number = view[3] * dpiFix;    
					var dx:number = (this.assetVO.asset.width) / pageWidth;
					var dy:number = (this.assetVO.asset.height) / pageHeight;
					//var dx:number = (this.assetVO.asset.width * this.scale) / pageWidth;
					//var dy:number = (this.assetVO.asset.height * this.scale) / pageHeight;
					this.currentZoom = Math.min(dx, dy);
					break;
			}
		}else{
			// no value so back to 100
			this.currentZoom = 1;
		}
	}
	queueRenderPage(pageNum:number)
	{
			// stop any current rendering
			if(this.renderingPage && this.renderTask)
			{
				this.renderTask.cancel();
			}
			this.renderPage(pageNum);
			/*
			if(this.renderingPage)
			{
					this._requestedPage = pageNum;
			}else{
					this.renderPage(pageNum);
			}*/
	}
	generateThumbnail(pageNum:number)
	{
		this.thumbnails[pageNum].loading = true;
		this.pdf.getPage(pageNum + 1).then(page =>{
			var canvas = document.createElement('canvas');
			var canvasContext = canvas.getContext('2d');

			var desiredWidth = 75;
			var desiredHeight = 100;
			var viewport = page.getViewport({ scale:1 });
			var scale = Math.min(desiredWidth / viewport.width, desiredHeight / viewport.height);
			var scaledViewport = page.getViewport({scale});
			canvas.width = scaledViewport.width;
			canvas.height = scaledViewport.height;

			// Render PDF page into canvas context
			var renderContext = {
				canvasContext,
				viewport:scaledViewport,
				annotationMode:AnnotationMode.DISABLE,
			};


			let renderTask = page.render(renderContext);
			renderTask.promise.then(() => {
				this.thumbnails[pageNum].url = canvas.toDataURL();
				this.thumbnails[pageNum].loading = false;
				//console.log("bing bango", pageNum);
				
			}).catch(err => {
				console.error("pdf err", err);
				this.thumbnails[pageNum].loading = false;
			});
		})
	}
	renderPage(pageNum:number)
	{
		return;
		this.pdf.getPage(pageNum).then(page =>{
			this.page = page;
			console.log("page", page);
			var view = page.view;
			var dpiFix = 96/72;
			var pageWidth:number = view[2] * dpiFix;
			var pageHeight:number = view[3] * dpiFix;

			var width = (this.assetVO.asset.width * this.scale) || pageWidth;
			var height = (this.assetVO.asset.height* this.scale) || pageHeight;

			var dx:number = width / pageWidth;
			var dy:number = height / pageHeight;
			if(this.firstZoom){
				this._currentZoom = Math.min(dx, dy);
				this.firstZoom = false;
			}    
			
			var viewport = page.getViewport({scale: this._currentZoom * dpiFix});
			dx = width / viewport.width;
			dy = height / viewport.height;
			this.canScrollPages = (dx >= 1 && dy >= 1);

			// Prepare canvas using PDF page dimensions
			var canvas = this.canvas.nativeElement;
			var context = canvas.getContext('2d');
			//canvas.height = viewport.height;
			//canvas.width = viewport.width;
			if(dx > dy)
			{
				canvas.width = pageWidth * this._currentZoom;
				canvas.height = pageHeight * this._currentZoom;
			}else{
				canvas.width = pageWidth * this._currentZoom;
				canvas.height = pageHeight * this._currentZoom;
			}


			// Render PDF page into canvas context
			var renderContext = {
				canvasContext: context,
				viewport: viewport
			};

			this.renderingPage = true;



			this.renderTask = page.render(renderContext);
			this.renderTask.promise.then(() => {
				console.log('Page rendered');
				this.renderingPage = false;
				if(this._requestedPage)
				{
					this.renderPage(this._requestedPage);
					this._requestedPage = 0;
				}
			}).catch(err => {
				this.renderingPage = false;
				if(this._requestedPage)
				{
					this.renderPage(this._requestedPage);
					this._requestedPage = 0;
				}
				console.log(err)
			});
		});
	}
	canvasCacheIndex = 0;
	private _jobQueue:any[] = [];
	queuePage(index)
	{
		// check render first
		let render = this.pages[index].render;

		if(render.status == 'rendering' || render.status == 'rendered') return;	//render.status == 'queued' || 


		// queue page for rendering at current zoom level
		const canvasContext = this.getCanvasContext();
		const renderTaskVO = this.getRenderTask(index, canvasContext);

		const job = {index, renderTask:renderTaskVO.renderTask, viewport:renderTaskVO.viewport, canvasContext, render};
		this._jobQueue.push(job);

		this.checkQueue();
	}
	checkQueue()
	{
		let renderTextLayer = true;

		let maxConcurrent = 1;
		//console.log("check queue", this._jobQueue.length);
		
		for (let i = 0; i < this._jobQueue.length; i++) {
			const job = this._jobQueue[i];
			if(job.render.status == "queued")
			{
				job.render.status = 'rendering';
				if(renderTextLayer)
				{
					job.renderTask.promise.then((e) => {
			
						
						job.render.status = 'rendered';
						job.render.canvas = job.canvasContext.canvas;
			
						this._jobQueue.splice(this._jobQueue.indexOf(job), 1);
						this.checkQueue();

						// render text layer
						return this.pages[job.index].page.getTextContent();
			
					})
					.then((textContent) => {
						let textLayer = document.createElement('div');
						let page = this.pages[job.index];
						page.textLayer = textLayer;
						let textContainer = this.pageContainer.nativeElement.children[job.index].querySelector('.text-layer') as HTMLElement;
						// clear any previous content
						while(textContainer.firstChild) textContainer.removeChild(textContainer.firstChild);
						//textContainer.style.transform = `scale(${this._currentZoom})`;	
						//.appendChild(page.textLayer);						
						pdfjsLib.renderTextLayer({
							textContent: textContent,
							container: textContainer,
							//viewport: job.renderTask._internalRenderTask.params.viewport,	// TODO a bit gross - and has stopped working
							viewport:job.viewport,
							enhanceTextSelection: false,	// required to prevent selection jitters
							textDivs: []
						}).promise.then(() => {
							

							// group blocks into divs
							/*
							let children = textContainer.children;
							let div = null;
							for (let i = 0; i < children.length; i++) {
								const child = children[i];
								if(!div)
								{
									div = document.createElement('div');
									textContainer.insertBefore(div, child);
									i++;
								}
								div.appendChild(child);
								if(child.tagName == "BR")
								{
									div = null;
								}
							}*/

							let endOfContent = document.createElement('div');
							endOfContent.className = "endOfContent";
							textContainer.appendChild(endOfContent);
							//textContainer
						});
						
					})
					.catch(err => {
						// TODO retry? or status failed?
						console.log(err)
					});
				}else{
					
				}

				if(--maxConcurrent <= 0) return;
			}
		}
	}
	getCanvasContext()
	{
		const canvas = document.createElement("canvas");
		const context = canvas.getContext('2d');
		return context;
	}
	getRenderTask(index, canvasContext)
	{
		let page = this.pages[index];
		let pdfPage = page.page;

		var pageWidth:number = page.page.view[2] * this.SCALE;
		var pageHeight:number = page.page.view[3] * this.SCALE;

		let oringialViewport = pdfPage.getViewport({scale: 1});
		let scale = pageWidth / oringialViewport.width;
		let viewport = pdfPage.getViewport({scale: this._currentZoom * scale});
		canvasContext.canvas.width = viewport.width;
		canvasContext.canvas.height = viewport.height;
		let renderTask = pdfPage.render({
			canvasContext,
			viewport,
			annotationMode:AnnotationMode.DISABLE
		});
		return {renderTask, viewport};
	}
	renderPage2(index)
	{
		return;
		let page = this.pages[index];
		let pdfPage = page.page;
		// TODO if page already rendered then do nothing

		
		var pageWidth:number = page.page.view[2] * this.SCALE;
		var pageHeight:number = page.page.view[3] * this.SCALE;

		// grab canvas
		let canvas = this.canvasCache[this.canvasCacheIndex];

		var context = canvas.getContext('2d');

		let cacheIndex = this.canvasCacheIndex;
		if(this.renderQueue[cacheIndex].active)
		{
			if(this.renderQueue[cacheIndex].renderTask)
				this.renderQueue[cacheIndex].renderTask.cancel();
		}
		this.renderQueue[cacheIndex].active = true;
		

		
		canvas.width = pageWidth * this._currentZoom;
		canvas.height = pageHeight * this._currentZoom;
		var oringialViewport = pdfPage.getViewport({scale: 1});
		let scale = pageWidth / oringialViewport.width;
		var viewport = pdfPage.getViewport({scale: this._currentZoom * scale});
		canvas.width = viewport.width;
		canvas.height = viewport.height;
			// Render PDF page into canvas context
			var renderContext = {
				canvasContext: context,
				viewport: viewport
			};

			let renderTask = pdfPage.render(renderContext);      
			this.renderQueue[cacheIndex].renderTask = renderTask;
			


			renderTask.promise.then(() => {
			 
				// render complete
				page.canvas = canvas;
				this.renderQueue[cacheIndex].active = false;
				this.renderQueue[cacheIndex].renderTask = null;
				
				// render text layer
				return pdfPage.getTextContent();


			})
			.then((textContent) => {
				let textLayer = document.createElement('div');
				page.textLayer = textLayer;
				let textContainer = this.pageContainer.nativeElement.children[index].querySelector('.text-layer') as HTMLElement;
				textContainer.style.transform = `scale(${this._currentZoom})`;	
				//.appendChild(page.textLayer);
				pdfjsLib.renderTextLayer({
					textContent: textContent,
					container: textContainer,
					viewport: viewport,
					enhanceTextSelection: false,	// required to prevent selection jitters
					textDivs: []
				}).promise.then(() => {
					let endOfContent = document.createElement('div');
					endOfContent.className = "endOfContent";
					textContainer.appendChild(endOfContent);
				});
				
			})
			.catch(err => {
				/*
				this.renderingPage = false;
				if(this._requestedPage)
				{
					this.renderPage(this._requestedPage);
					this._requestedPage = 0;
				}*/
				console.log(err)
			});

			this.canvasCacheIndex++;
			this.canvasCacheIndex %= this.maxCanvasCount;
	}
	trigger()
	{
		// TODO remove me ASAP
		return this.assetVO.asset.deliverable;
	}
	prevPage() {
		if (this.currentPage <= 1) return;
		this.currentPage--;
	}
	nextPage() {
		if (this.currentPage >= this.numPages) return;
		this.currentPage++;
	}
	getPath() : string {
		let path = super.getPath();
		// these options disable some of the features of the pdf in the browser (not sure on support but chrome seems to work)
		// [see](https://stackoverflow.com/questions/2104608/hiding-the-toolbars-surrounding-an-embedded-pdf)
		// TODO look into using object/embed w/wo iframe (https://stackoverflow.com/questions/19654577/html-embedded-pdf-iframe) + (https://stackoverflow.com/questions/1244788/embed-vs-object)
		return path ? path + `#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0` : null;
	}

	/**
	 * Markers
	 */
	 canDropMarker(x:number, y:number, noSideEffect:boolean = false)
	 {
		 // loop through all pages visible pages and check...
		 for (let index = 0; index < this.pages.length; index++) {
			let child = this.pageContainer.nativeElement.children[index];
			if(!child) continue;
			 let bounds = child.getBoundingClientRect();
			 if(x > bounds.x && x < bounds.right &&
				y > bounds.y && y < bounds.bottom)
				return 1;
			 /*
			 const page = this.pages[index];
			 if(x > page.x && x < page.x + page.width &&
				y > page.y && y < page.y + page.height)
				return 1*/
		 }
		 return 0;
	 }
	 getMarkerRenderInfo(markerVO:MarkerVO)
		{
			// clone and modify
			//let markerUpdate = JSON.parse(JSON.stringify(marker));
			if(markerVO.marker?.metadata?.page === undefined) return;

			// page element might not exists yet so need to check
			let pageElement = this.pageContainer.nativeElement.children[markerVO.marker.metadata.page] as HTMLElement;
			if(!pageElement || !this.parsed) {
				markerVO.visible = false;
				return;
			}

			// is marker vision
			if(markerVO.marker.type == "target")
			{
				markerVO.visible = this.isVisible2(this.view.nativeElement, pageElement);
				if(true || markerVO.visible) // always get position
				{
					// convert coordinates
					let assetBounds = getRect(this._element.nativeElement);//.getBoundingClientRect();
					//let pageBounds = getRect(pageElement);//.getBoundingClientRect();
					let pageBounds = pageElement.getBoundingClientRect();
	
					let dx = pageBounds.left - assetBounds.left;
					let dy = pageBounds.top - assetBounds.top;
	
					//markerUpdate.x0 = dx + marker.x0 * this._currentZoom;
					//markerUpdate.y0 = dy + marker.y0 * this._currentZoom;
					markerVO.offsetX = (dx + markerVO.marker.x0 * this._currentZoom) - markerVO.marker.x0;
					markerVO.offsetY = (dy + markerVO.marker.y0 * this._currentZoom) - markerVO.marker.y0;
				}
			}else if(markerVO.marker.type == "text_change" || markerVO.marker.type == "text_delete")
			{
				markerVO.visible = this.isVisible2(this.view.nativeElement, pageElement);
				if(true || markerVO.visible)
				{
					// convert coordinates
					let assetBounds = getRect(this._element.nativeElement);//.getBoundingClientRect();
					//let pageBounds = getRect(pageElement);//.getBoundingClientRect();
					let pageBounds = pageElement?.getBoundingClientRect();
					if(!pageBounds?.width)
					{
						// page not parsed yet so no bounds info
						markerVO.visible = false;
					}else{
						let dx = pageBounds.left - assetBounds.left;
						let dy = pageBounds.top - assetBounds.top;					
		
						//markerUpdate.x0 = dx + marker.x0 * this._currentZoom;
						//markerUpdate.y0 = dy + marker.y0 * this._currentZoom;
						markerVO.marker.x0 = markerVO.marker.y0 = 0;
						markerVO.offsetX = (dx)// * (this._currentZoom));
						markerVO.offsetY = (dy)// * (this._currentZoom));
						markerVO.scale = this._currentZoom;
					}
				}
			}
		}

		pageCoordinatesToAssetCoordinate(){}
		globalCoordinatesToPageCoordinates(){}

		markerDrop(marker:IMarker, x:number, y:number):boolean
		{
			//console.log("pdf marker dtop", x, y);
			
			// could call super and then a secondary override function to get the offset
			marker.asset_uuid = this.assetVO.asset.uuid;
			//console.log("PDF MARKER DROP::",x,y);
			//console.log("PDF MARKER PAGE::",this.getPageUnderCoords(x, y));
			
			marker.metadata.page = this.getPageUnderCoords(x, y);

			// if there is no page then we kill it off
			if(marker.metadata.page == -1)
			{
				return false;
			}

			// convert coordinates
			let pageElement = this.pageContainer.nativeElement.children[marker.metadata.page] as HTMLElement;
			let windowPageBounds = pageElement.getBoundingClientRect();
			let assetPageBounds = this._element.nativeElement.getBoundingClientRect();

			let dx = windowPageBounds.left - assetPageBounds.left;
			let dy = windowPageBounds.top - assetPageBounds.top;

			//console.log("dx dy", dx, dy);
			
			//console.log("windowPageBounds",windowPageBounds.left, windowPageBounds.top);
			marker.x0 = (x - windowPageBounds.left) * (1/this._currentZoom);
			marker.y0 = (y - windowPageBounds.top) * (1/this._currentZoom);
			//marker.x0 = dx + marker.x0 * this._currentZoom;
			//marker.y0 = dy + marker.y0 * this._currentZoom;

			//console.log("x,yy", marker.x0, marker.y0);
			return true;
		}
		public isPointOutside(x:number, y:number): boolean
		{
			let page = this.getPageUnderCoords(x,y)
			return page == -1;
		}
		// get the page number under given global coordinates, -1 if no page hit
		getPageUnderCoords(x:number, y:number, boundsOut:DOMRect = null):number
		{
			let assetBounds = this._element.nativeElement.getBoundingClientRect();
			let dx = x - assetBounds.left;
			let dy = y - assetBounds.top;


			for (let i = 0; i < this.pages.length; i++) {
				let pageElement = this.pageContainer.nativeElement.children[i];
				let pageBounds = pageElement.getBoundingClientRect();
				if(x >= pageBounds.left && x <= pageBounds.right && y >= pageBounds.top && y <= pageBounds.bottom){
					if(boundsOut){
						boundsOut.x = pageBounds.x;
						boundsOut.y = pageBounds.y;
						boundsOut.width = pageBounds.width;
						boundsOut.height = pageBounds.height;
					}
					return i;
				}
			}
			return -1;
		}
		globalToLocal(point:Point):Point
		{
			// convert a global point to a point relative to the current page
			let page = this.getPageUnderCoords(point.x, point.y);
			if(page == -1)
			{
				return null;
			}

			// convert coordinates
			let pageElement = this.pageContainer.nativeElement.children[page] as HTMLElement;
			let assetBounds = getRect(this._element.nativeElement);//.getBoundingClientRect();
			let pageBounds = getRect(pageElement);//.getBoundingClientRect();
			//pageBounds = pageElement.getBoundingClientRect();
			//console.log("PAGE BOUNDS", pageBounds);
			//console.log("PAGE BOUNDS B", pageElement.getBoundingClientRect());
			//console.log("ASSET BOUNDS", assetBounds);
			//console.log("ASSET BOUNDS B", this._element.nativeElement.getBoundingClientRect());
			return new Point(
				(point.x - pageBounds.left) * (1 / this._currentZoom), 
				(point.y - pageBounds.top) * (1 / this._currentZoom));
		}
		rects = null;
		async getAmendOptions(x:number, y:number, specific:boolean):Promise<MarkerOption[]>
		{
			let pageBounds = new DOMRect();
			let page = this.getPageUnderCoords(x, y, pageBounds);
			let options = await super.getAmendOptions(x, y, specific);
			
			if(options.length)
			{
				// slap page metadata on marker amend option if it is there
				options[0].data = {page};
			}
			let selection = document.getSelection();
			if(selection?.type == "Range")
				{
				let selectionString = selection.toString();
				// a selection could span multiple pages so we need to associate each rectangle from its page
				let assetBounds = this._element.nativeElement.getBoundingClientRect();
				let assetBounds2 = getRect(this._element.nativeElement);

				//("BOUNDS", this._element.nativeElement);
				//console.log("BOUNDS 1", assetBounds);
				//console.log("BOUNDS 2", assetBounds2);
				let dx = pageBounds.left;
				let dy = pageBounds.top;

				//console.log(selection);
				let range = selection.getRangeAt(0);
				this.rects = [];

				// bounds for the whole selection
				let rangeBounds = range.getBoundingClientRect();
				// bounds of individual items in the selection
				let clientRects = Array.from(range.getClientRects());
				// filter bogus selection
				clientRects = clientRects.filter(rect => rect.width > 2 && rect.height > 2);
				if(this.contains(pageBounds, rangeBounds))
				{
					// all rects contained in page
				}else{
					for (let i = 0; i < clientRects.length; i++) {
						const rect = clientRects[i];
						if(!this.contains(pageBounds, rect))
						{
							clientRects.splice(i--, 1);
						}
					};
				}
				//let pageMin = 



				// to which page does each rect belong could get the rect for whole selection or use 
				let commonAncestor = range.commonAncestorContainer;
				// if the commonAncestor is under a page then we know the whole selection belogs to just 1 page.. but we could check them all anyway
				// offset
				for (let i = 0; i < clientRects.length; i++) {
					const rect = clientRects[i];
					rect.x -= dx;
					rect.y -= dy;
					// scale back to 100%
					rect.x *= 1/this._currentZoom;
					rect.y *= 1/this._currentZoom;
					rect.width *= 1/this._currentZoom;
					rect.height *= 1/this._currentZoom;
					this.rects.push(rect); 
				};

				// merge rects - they should? flow left to right, top to bottom (maybe)
				for (let i = 0; i < this.rects.length; i++) {
					const rectA:DOMRect = this.rects[i];
					for (let j = 0; j < this.rects.length; j++) {	//i+1
						const rectB:DOMRect = this.rects[j];
						if(rectA == rectB)	continue; 	// shouldnt happen
						if(this.intersects(rectA, rectB, 10, -rectB.height * 0.5))
						{
							this.merge(rectA, rectB);
							this.rects.splice(j--, 1);
						}else{

						}
						/*
						// overlap y
						if(Math.abs(rectA.top - rectB.top) < 2 && Math.abs(rectA.bottom - rectB.bottom) < 2)
						{
							// overlap X
							if(rectB.left <= rectA.right || Math.abs(rectA.right - rectB.left) < 2)
							{
								rectA.width = rectB.right - rectA.left;
								// todo merge heights
								//this.rects.splice(j, 1);
							}
						}*/
					}
				}
				// convert to Object from DOMRect
				for (let i = 0; i < this.rects.length; i++) {
					this.rects[i] = convertDOMRect(this.rects[i]);
				}
				// clear that selection
				//selection.removeAllRanges();
				
				let data = {page, rects:this.rects, text:selectionString};

				let message1 = specific ? 'Hilite selected text' : 'Hilite selected text';
				let message2 = specific ? 'Strikethrough selected text' : 'Strikethrough selected text';
				options.unshift(new MarkerOption(message1, MarkerOption.ICON_TEXT_CHANGE, MarkerOption.TYPE_TEXT_CHANGE, data));
				options.unshift(new MarkerOption(message2, MarkerOption.ICON_TEXT_DELETE, MarkerOption.TYPE_TEXT_DELETE, data));
			}else{
				options.unshift(null);
				options.unshift(new MarkerOption("Select text in PDF for more options.",null,null,null,true))
				
				this.rects = null;
			}
			this.rects = null;
			return new Promise((resolve, reject) => resolve(options));
		}
		intersects(a:DOMRect, b:DOMRect, thresholdX:number = 4, thresholdY:number = 0)
		{
			if(a.right < b.left - thresholdX) return false;
			if(a.left > b.right + thresholdX ) return false;
			if(a.bottom < b.top - thresholdY ) return false;
			if(a.top > b.bottom + thresholdY ) return false;
			return true;
		}
		/**
		 * Does the first rectangle contain the second rectangle
		 * @param a Container rect
		 * @param b Containee rect
		 */
		contains(a:DOMRect, b:DOMRect):boolean
		{
			return (a.x <= b.x  && a.right >= b.right && a.y <= b.y && a.bottom >= b.bottom);
		}
		merge(a:DOMRect, b:DOMRect, threshold:number = 2)
		{
			let x = Math.min(a.x, b.x);
			let width = Math.max(a.right, b.right) - x;
			// let y = Math.min(a.y, b.y);
			// let height = Math.max(a.bottom, b.bottom) - y
			// TODO merging y on max causes issues, prob just want to use the one with the biggest height
			let y, height;
			if(a.height > b.height)
			{
				y = a.y;
				height = a.height;
			}else{
				y = b.y;
				height = b.height;
			}
			
			let merged =new DOMRect(x, y, width, height);
			a.x = merged.x;
			a.y = merged.y;
			a.width = merged.width;
			a.height = merged.height;
			return a;
		}

		textFix(apply:boolean, evt, div)
		{
			if(apply)
			{
				this.pageContainer.nativeElement.classList.add("active");

				let end = div.querySelector('.endOfContent'); 
				if (!end) { 
				return; 
				} 
				if (true) { 
				// On non-Firefox browsers, the selection will feel better if the height 
				// of the `endOfContent` div is adjusted to start at mouse click 
				// location. This avoids flickering when the selection moves up. 
				// However it does not work when selection is started on empty space. 
				let adjustTop = evt.target !== div; 
				if (true) { 
					adjustTop = adjustTop && window.getComputedStyle(end). 
					getPropertyValue('-moz-user-select') !== 'none'; 
				} 
				if (adjustTop) { 
					let divBounds = div.getBoundingClientRect(); 
					let r = Math.max(0, (evt.pageY - divBounds.top) / divBounds.height); 
					end.style.top = (r * 100).toFixed(2) + '%'; 
				} 
				} 
			}else{
				this.pageContainer.nativeElement.classList.remove("active");
			}
		}
		getScrollOffset(): string {
			const scrollbarWidth = this.view.nativeElement.offsetWidth - this.view.nativeElement.clientWidth;
			return scrollbarWidth + 'px';
		}
}
