export class UserRoles
{
    public static ADMIN:number = 1 << 0;        // 1
    public static SUPER:number = 1 << 1;        // 2
    public static ARCHITECT:number = 1 << 2;    // 4

    public static ALL:number = UserRoles.ADMIN | UserRoles.SUPER | UserRoles.ARCHITECT;

    public static LOOKUP = {"admin":UserRoles.ADMIN, "super":UserRoles.SUPER, "architect":UserRoles.ARCHITECT};
    public static REVERSE_LOOKUP = {1:"admin", 2:"super", 4:"architect"};

    public static rolesToString(permissions:number)
	{
		let string = '';
		for(var key in UserRoles.REVERSE_LOOKUP)
		{
			if((parseInt(key) & permissions) != 0)
			{
				if(string.length) string += ', ';
				string += UserRoles.REVERSE_LOOKUP[key];
			}
		}
		return string;
	}
}
export interface IUser {
    id: number;
    uuid: string;
    name: string;
    email: string;
    created_at: string;
    updated_at: string;
    roles: any[];
    rolesMap: any;
    permissions: any[];
    rolez: number;
    scoped_permissions: number;
}
export class User implements IUser{
    id: number;
    uuid: string;
    name: string;
    email: string;
    created_at: string;
    updated_at: string;
    roles: any[];
    rolesMap: any;    
    permissions: any[];
    rolez: number;
    scoped_permissions: number;
}