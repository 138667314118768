import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { Project } from 'src/app/models/project.model';
import { ProjectChannel } from 'src/app/models/projectChannel.model';
import { Workflow } from 'src/app/models/workflow.model';

@Injectable({
    providedIn: 'root'
})
export class CreativeSelectiontService {

	constructor() { }


	private selection:any = {};
	private selectionSubject = new BehaviorSubject<any>(null);
	public selection$:Observable<any> = this.selectionSubject.asObservable();

	// lookups
	private workflowByChannel;
	private workflowByFormat;
	private workflowByUUID;
	private formatGroupByFormat;
	private projectChannelByWorkflowChannel;
	private creativeByUUID;
	private projectChannelByUUID;
	private workflowChannelByUUID;
	private formatByUUID;
	//private formatGroup;	// by "WORKFLOWUUID_FORMATGROUPNAME"

	private _workflows:Workflow[];
	public set workflows(workflows : Workflow[]) {
		this._workflows = workflows;
		this.updateLookups();
	}
	private _projectChannels:ProjectChannel[];
	public set projectChannels(projectChannelss : ProjectChannel[]) {
		this._projectChannels = projectChannelss;
		this.updateLookups();
	}
	private _project:Project;
	public set project(projectt : Project) {
		this._project = projectt;
		this.updateLookups();
	}

	public selectionMode:boolean;


	updateLookups()
	{
		if(!this._workflows || !this._projectChannels || !this._project) return;

		this.projectChannelByUUID = {};
		this._projectChannels.forEach(projectChannel => {
			this.projectChannelByUUID[projectChannel.uuid] = projectChannel;
		});

		this.workflowByUUID = {};
		this.creativeByUUID = {};
		this.workflowChannelByUUID = {};
		this.formatByUUID = {};
		this.workflowByChannel = {};
		this.workflowByFormat = {};
		this.formatGroupByFormat = {};
		this.projectChannelByWorkflowChannel = {};

		this._workflows.forEach(workflow => {
			this.workflowByUUID[workflow.uuid] = workflow;
			workflow.creatives?.forEach(creative => {
				this.creativeByUUID[creative.uuid] = creative;
			});
			workflow.channels?.forEach(channel => {
				this.workflowByChannel[channel.uuid] = workflow;
				this.workflowChannelByUUID[channel.uuid] = channel;
				this.projectChannelByWorkflowChannel[channel.uuid] = this.projectChannelByUUID[channel.project_channel_uuid];
			});
			workflow.formats?.forEach(format => {
				this.formatByUUID[format.uuid] = format;
				this.workflowByFormat[format.uuid] = workflow;
				this.formatGroupByFormat[format.uuid] = format.group;
			});
		});
		if(this.selectionMode) this.analyseSelection();
	}

	selectionChange(type, uuid)//event:MatCheckboxChange, 
	{
		//console.log("gridData",this.gridData);
		let isSelected = !(this.selection[type][uuid]) ? true : false;
		if(!Array.isArray(uuid)){
			if(isSelected) this.selection[type][uuid] = isSelected;
			else if(this.selection[type][uuid]) delete this.selection[type][uuid];
		}
			
			
		
		//So...turn (only) creative selection lookups on/off based on what was actioned
		if (type == "creative") { //single creative
			if(Array.isArray(uuid))
			{
				uuid.forEach(creative_uuid => {
					let isSelected = !(this.selection[type][creative_uuid]) ? true : false;
					if(isSelected) this.selection[type][creative_uuid] = isSelected;
					else if(this.selection[type][creative_uuid]) delete this.selection[type][creative_uuid];
				});
			} else {
				if(isSelected) this.selection[type][uuid] = isSelected;
				else if(this.selection[type][uuid]) delete this.selection[type][uuid];
			}
		}
		else if(type == "workflowChannel"){ //all creatives in workflow channel
			let workflow = this.workflowByChannel[uuid];
			workflow.creatives?.forEach(creative => {
				if(creative.channel_uuid == uuid) {
					this.selection.creative[creative.uuid] = isSelected;
				}
			});
		}
		else if(type == "format"){ //all creatives in format row
			let workflow = this.workflowByFormat[uuid];
			workflow.creatives?.forEach(creative => {
				if(creative.format_uuid == uuid) {
					this.selection.creative[creative.uuid] = isSelected;
				}
			});
		}
		else if(type == "formatGroup"){ //all creatives in format group
				// uuid = workflowUUID _ groupNAME
				let uuidArr = uuid.split("_");
				let workflow = this.workflowByUUID[uuidArr[0]];
				workflow.creatives?.forEach(creative => {
					if(this.formatGroupByFormat[creative.format.uuid] == uuidArr[1]){
						this.selection.creative[creative.uuid] = isSelected;
					}
				});
		}
		else if(type == "workflow"){
			let workflow = this.workflowByUUID[uuid];
			workflow.creatives?.forEach(creative => {
					this.selection.creative[creative.uuid] = isSelected;
			});
		}
		else if(type == "projectChannel"){ //all creatives in proj channel (workflow chanel per wf)
			this._workflows.forEach(workflow => {
				workflow.creatives?.forEach(creative => {
					if(this.projectChannelByWorkflowChannel[creative.channel.uuid].uuid == uuid){
						this.selection.creative[creative.uuid] = isSelected;
					}
				});
			});
		} else if (type == "project"){
			for(const creative_uuid in this.creativeByUUID){
				this.selection.creative[creative_uuid] = isSelected;
			}
		}

		this.analyseSelection();
		

		//this.selectionChanged.emit(null);
	}

	analyseSelection(){
		//loop through all creative and do counts
		var projectChannelCount = {};
		var projectCount = {};

		this.sanatiseSelection();

		this._workflows.forEach(workflow => {
			var workflowChannelCount = {};	
			var workflowFormatCount = {};
			var workflowFormatGroupCount = {};		
			var workflowCount = {};	
			

			workflow.creatives?.forEach(creative => {

				//----COUNT
				let result = this.selection.creative[creative.uuid] ? 'y' : 'n';
				//wf channels
				if(!workflowChannelCount[creative.channel_uuid]) workflowChannelCount[creative.channel_uuid] = {y:0, n:0};
				workflowChannelCount[creative.channel_uuid][result] += 1;
				//wf formats
				if(!workflowFormatCount[creative.format_uuid]) workflowFormatCount[creative.format_uuid] = {y:0, n:0};
				workflowFormatCount[creative.format_uuid][result] += 1;

				
				//wf formatGroups
				var groupName = this.formatGroupByFormat[creative.format.uuid];
				if(!workflowFormatGroupCount[groupName]) workflowFormatGroupCount[groupName] = {y:0, n:0};
				workflowFormatGroupCount[groupName][result] += 1;

				//project channels
				if(!projectChannelCount[this.projectChannelByWorkflowChannel[creative.channel_uuid].uuid]) projectChannelCount[this.projectChannelByWorkflowChannel[creative.channel_uuid].uuid] = {y:0, n:0};
				projectChannelCount[this.projectChannelByWorkflowChannel[creative.channel_uuid].uuid][result] += 1;
				//workflow
				if(!workflowCount[creative.workflow_uuid]) workflowCount[creative.workflow_uuid] = {y:0, n:0};
				workflowCount[creative.workflow_uuid][result] += 1;
				//project
				if(!projectCount[this._project.uuid]) projectCount[this._project.uuid] = {y:0, n:0};
				projectCount[this._project.uuid][result] += 1;

			});
			//---ANALYSE
			//now set selection for non-creative workflow checkboxes based on counts
			//wf channels
			for(const wfChannel in workflowChannelCount){
				let isSelected = (workflowChannelCount[wfChannel].y > 0 && workflowChannelCount[wfChannel].n == 0);
				if(isSelected) this.selection.workflowChannel[wfChannel] = true;
			}
			//wf formats
			for(const wfFormat in workflowFormatCount){
				let isSelected = (workflowFormatCount[wfFormat].y > 0 && workflowFormatCount[wfFormat].n == 0);
				if(isSelected) this.selection.format[wfFormat] = true;
			}
			//wf formatGroups
			for(const formatGroup in workflowFormatGroupCount){
				//var groupName = this.formatGroupByFormat[creative.format.uuid];
				let isSelected = (workflowFormatGroupCount[formatGroup].y > 0 && workflowFormatGroupCount[formatGroup].n == 0);
				if(isSelected) this.selection.formatGroup[workflow.uuid+'_'+formatGroup] = true;
			}
			//workflow
			for(const wf in workflowCount){
				let isSelected = (workflowCount[wf].y > 0 && workflowCount[wf].n == 0);
				if(isSelected) this.selection.workflow[wf] = true;
			}
		});
		//proj channels
		for(const projChannel in projectChannelCount){
			var currentProjCount = projectChannelCount[projChannel];
			let isSelected = (currentProjCount.y > 0 && currentProjCount.n == 0);
			if(isSelected) this.selection.projectChannel[projChannel] = true;

			projectChannelCount[projChannel].empty = ((currentProjCount.y == 0) && (currentProjCount.n == 0));
		}
		//project
		for(const proj in projectCount){
			let isSelected = (projectCount[proj].y > 0 && projectCount[proj].n == 0);
			if(isSelected) this.selection.project[proj] = true;
		}
		
		this.selectionSubject.next(this.selection)


	}

	sanatiseSelection()
	{
		//nuke everything except creatives
		for(var type in this.selection){
			for(var key in this.selection[type]){
				if(type != "creative") this.selection[type] = {};
			}
		}

		// loop through selection creatives
		for(var creative_uuid in this.selection.creative)
		{
			//remove any that are false or no longer in workflow
			if(!this.selection.creative[creative_uuid] || !this.creativeByUUID[creative_uuid]) {
				delete this.selection.creative[creative_uuid];
			}
		}


		//...not just creatives, need to sanitise the whole selection!
		// { workflow:{}, projectChannel:{}, workflowChannel:{}, format:{}, formatGroup:{}, creative:{}, project:{} };
		/*
		this.workflowByUUID = {};
		this.creativeByUUID = {};
		this.workflowByChannel = {};
		this.workflowByFormat = {};
		this.formatGroupByFormat = {};
		this.projectChannelByWorkflowChannel = {};
		*/
		/*
		for(var type in this.selection){
			for(var key in this.selection[type]){

				//check key still exists in each type lookup, remove if not
				if(type == "creative" && !this.creativeByUUID[key]) delete this.selection.creative[key];
				if(type == "workflow" && !this.workflowByUUID[key]) delete this.selection.workflow[key];
				if(type == "projectChannel" && !this.projectChannelByUUID[key]) delete this.selection.projectChannel[key];
				if(type == "workflowChannel" && !this.workflowChannelByUUID[key]) delete this.selection.workflowChannel[key];
				if(type == "format" && !this.formatByUUID[key]) delete this.selection.format[key];
				if(type == "project"){}//null;
				if(type == "formatGroup"){} //null;

				//remove anything that is false
				if(!this.selection[type][key]) delete this.selection[type][key];
			}
		}
		*/
	}

	resetSelectionLookup(){
		this.selection = { workflow:{}, projectChannel:{}, workflowChannel:{}, format:{}, formatGroup:{}, creative:{}, project:{} };
		this.selectionSubject.next(this.selection)
	}

}