import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Amend } from './amends.service';
import { CrudService } from './crud.service';
import { ICreative } from 'src/app/models/creative.model'
import { map } from 'rxjs/operators';
import { ApiResponse } from './OvBaseService';
import { Asset, IAsset } from '../models/asset.model';
import { ApprovalChain } from '../models/ApprovalChain.model';
import { IWorkflow } from '../models/workflow.model';
import { IChatMessage } from '../views/prototypes/creative/creativeDiscussion.service';

@Injectable({
	providedIn: 'root'
})
export class CreativeService extends CrudService<ICreative, string> {

  sendEmail(creative_uuid: string, emails: string[], code:string) {
    return this._post(`${this.base}/${creative_uuid}/email`, {emails, code});
  }
	getUserPermissions(creative_uuid: string, user_uuid: any) {
		return this._get(`${this.base}/${creative_uuid}/userPermissions/${user_uuid}`);
	}
	
	constructor(protected http: HttpClient) {
		super(http, 'creative2');
	}
	/*
	getUserAction(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/userAction`);
	}
	*/
	/*
	getUserActions(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/userActions`);
	}
	*/
	/*
	getUsers(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/users`);
	}
	*/
	getUserRole(creative_id: string, user_id: any):Observable<any>{
		console.warn("not in use?");
		return;
		//return this._get(`${this.base}/${creative_id}/userRole/${user_id}`);
	}
	reset(creative_id: string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/reset`);
	}
	getState(uuid:string):Observable<any>
	{
		return this._get(`${this.base}/${uuid}/state`);
	}
	
	private _logLoadingSubject = new BehaviorSubject<boolean>(false);
	public logLoading$ = this._logLoadingSubject.asObservable();
	private _logSubject = new BehaviorSubject<any[]>([]);
	public log$ = this._logSubject.asObservable();
	loadLog(uuid:string)
	{
		this._logLoadingSubject.next(true);
		this.getLog(uuid).subscribe((response:any) => {
			this._logLoadingSubject.next(false);
			this._logSubject.next(response.data);
		});
	}
	clearLog() {
		this._logLoadingSubject.next(false);
		this._logSubject.next(null);
	}
	private _discussionSubject = new BehaviorSubject<any[]>([]);
	public discussion$ = this._discussionSubject.asObservable();
	private _discussionLoadingSubject = new BehaviorSubject<boolean>(false);
	public discussionLoading$ = this._discussionLoadingSubject.asObservable();
	private _discussionMoreSubject = new BehaviorSubject<boolean>(false);
	public discussionMore$ = this._discussionMoreSubject.asObservable();
	private next_cursor:string = null;
	loadDiscussion(uuid:string, more:boolean = false)
	{
		if(more && !this.next_cursor) return;	// already all loaded
		this._discussionLoadingSubject.next(true);
		this.getDiscussion(uuid, more).subscribe((response:any) => {
			let paginationResponse = response.data[0];
			let newMessages = paginationResponse.data;
			let currentMessages = this._discussionSubject.value;
			// merge them if they have the same creative uuid otherwise fresh set
			if(more && currentMessages?.length)
			{
				let currentUUID = currentMessages[0].creative_uuid;
				let newUUID = newMessages[0].creative_uuid;
				if(currentUUID == newUUID) {
					newMessages = newMessages.concat(currentMessages)
				}
			}
			this.next_cursor = paginationResponse.next_cursor;
			this._discussionSubject.next(newMessages);
			this._discussionMoreSubject.next(!!this.next_cursor);
			this._discussionLoadingSubject.next(false);
		});
	}
	sendDiscussionMessage(uuid:string, text:string)
	{
		return this._post<any, IChatMessage>(`${this.base}/${uuid}/chatMessage`, {text});
	}
	deleteDiscussionMessage(uuid:string)
	{
		return this._delete(`${this.base}/${uuid}/chatMessage`);
	}
	deleteExistingMessages(newMessages:IChatMessage[]) {
		let currentMessages:IChatMessage[] = this._discussionSubject.value;
		if(currentMessages?.length)
		{
			let changed = false;
			newMessages.forEach(message => {
				let index = currentMessages.findIndex((currentMessage) => currentMessage.uuid == message.uuid );
				if(index != -1)
				{
					if(message.user_id == null)
					{
						currentMessages.splice(index, 1, message);
					} else {
						currentMessages.splice(index, 1);
					}
					if(!changed) changed = true;
				}
			});
			if(changed)	this._discussionSubject.next(currentMessages);
		}
	}
	// TODO uber function
	updateMessages(data:{new?:IChatMessage[], update?:IChatMessage[], delete?:IChatMessage[]})
	{
		// delete
		// replace
		// insert
	}
	replaceDiscussionMessages(newMessages:IChatMessage[])
	{
		// find the matches and swap them out
		// TODO how to handle a missmatch

		let currentMessages = this._discussionSubject.value;
		console.log("creative.service:mergeDiscussionMessages", {currentMessages, newMessages});
		
		// merge them if they have the same creative uuid otherwise fresh set
		if(currentMessages?.length)
		{
			newMessages.forEach(message => {
				const replacementIndex = currentMessages.findIndex(currentMessage => currentMessage.uuid == message.uuid);
				if(replacementIndex !== -1) {
					currentMessages[replacementIndex] = message;
				}
			});
			this._discussionSubject.next(currentMessages);
		}
	}
	mergeDiscussionMessages(newMessages:IChatMessage[])
	{
		let currentMessages = this._discussionSubject.value;
		console.log("creative.service:mergeDiscussionMessages", {currentMessages, newMessages});
		
		// merge them if they have the same creative uuid otherwise fresh set
		if(currentMessages?.length)
		{
			let currentUUID = currentMessages[0].creative_id;
			let newUUID = newMessages[0].creative_id
			console.log("merge c_ids", currentUUID, newUUID);
			if(currentUUID == newUUID) {
				//newMessages = newMessages.concat(currentMessages)
				this.mergeArrays<IChatMessage>(newMessages, currentMessages, (a, b) => a.uuid > b.uuid);
				//this.mergeArraysLast<IChatMessage>(newMessages, currentMessages, (a, b) => Date.parse(a.created_at) > Date.parse(b.created_at));
			}
			this._discussionSubject.next(currentMessages);
		}else {
			this._discussionSubject.next(newMessages);
		}
		console.log("creative.service:mergeDiscussionMessages", {newMessages});
	}
	mergeArrays<T>(from:T[], into:T[], callback:(itemFrom:T, itemInto:T)=>boolean) {
        from.forEach(itemFrom => {
            //for (let i = 0; i < into.length; i++) {
			for (let i = into.length - 1; i >= 0; i--) {	// must traverse in reverse
                const itemInto = into[i];
                if(callback(itemFrom, itemInto))
                {
                    into.splice(i+1, 0, itemFrom);
                    break;
                }
            }
        });
    }
	mergeArraysLast<T>(from:T[], into:T[], callback:(itemFrom:T, itemInto:T)=>boolean){
		from.forEach(itemFrom => {
			let ready = 0;
            //for (let i = 0; i < into.length; i++) {
			for (let i = into.length - 1; i >= 0; i--) {	// must traverse in reverse
                const itemInto = into[i];
				let mergable = callback(itemFrom, itemInto);
                if(!ready && mergable)
                {
					ready = i + 1;
                    //into.splice(i+1, 0, itemFrom);
                    //break;
                } else if (ready && !mergable) {
					into.splice(ready, 0, itemFrom);
                    break;
				}
            }
			// didnt break so add anyway
			into.push(itemFrom);
        });
    }
	getDiscussion(uuid:string, more:boolean = false):Observable<any>
	{
		let extra = more ? {cursor:this.next_cursor} : {};
		return this._get(`${this.base}/${uuid}/chatMessages`, extra);
	}
	clearDiscussion()
	{
		this._discussionSubject.next(null);
		this.next_cursor = null;
		this._discussionMoreSubject.next(false);
	}
	getLog(uuid:string):Observable<any>
	{
		return this._get(`${this.base}/${uuid}/log`).pipe(
			map((response) => {
				let logs:{time:any}[] = response["data"];
				for (let i = 0; i < logs.length; i++) {
					let log = logs[i];
					// convert backend log time strings to js Date and re-format to (dd-mm-yy hh:mm:ss)
					log.time = new Date(log.time).toLocaleString().replace(new RegExp('/', "g"),'-').replace(new RegExp(',', "g"),'');
				}
				return response; 
			})
		);
		/*return this._get(`${this.base}/${uuid}/log`).pipe(
			map((response) => {
				let logs:any[] = response["data"];
				let tasks:any = {};
				for (let i = 0; i < logs.length; i++) {
					let log = logs[i];
					// temp transform
					if(log.status) log.state = log.status;
					//if(log.state) tasks[log.uuid] = log;			// removed because state was sometimes null (could be legacy issue)
					if(!log.action)	tasks[log.uuid] = log;
				}
				for (let i = 0; i < logs.length; i++) {
					let log = logs[i];
					if(!log.state && log.task_uuid){
						log.task = tasks[log.task_uuid];					
					}
				}
				// todo sort
				response["data"] = logs.sort((a, b) =>{
					return new Date(a.created_at || a.updated_at ).getTime() - new Date(b.created_at || b.updated_at ).getTime();
				});
				//console.log("transformed log", response["data"]);
				return response; 
			})
		);*/
	}
	getWorkflow(creative_uuid: string)
	{
		return this._get<ApiResponse<IWorkflow>>(`${this.base}/${creative_uuid}/workflow`);
	}
	getChain(creative_uuid: string)
	{
		return this._get<ApiResponse<ApprovalChain>>(`${this.base}/${creative_uuid}/chain`);
	}
	getChain2(creative_uuid: string)
	{
		return this._get<ApiResponse<ApprovalChain>>(`${this.base}/${creative_uuid}/chain2`);
	}
	getApprovalGroups(creative_uuid: string)
	{
		return this._get<ApiResponse<ApprovalChain>>(`${this.base}/${creative_uuid}/approvalGroups`);
	}
	private _assetsSubject = new Subject<Asset[]>();
	public assetsObservable$ = this._assetsSubject.asObservable();
	loadAssets(creative_uuid : string, params:any = null)
	{
		return this.getAssets(creative_uuid, params).subscribe((res) => {
			this._assetsSubject.next(res.data);
		});
	}
	getAssets(creative_uuid: string,params:any = null)
	{		
		return this._get<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/assets`, params).pipe(
			map((response) => {
				/*
				let assets:IAsset[] = response["data"];
				assets.forEach(asset => {
					Asset.cacheBust(asset);
				})	*/			
				return response; 
			})
		);
	}
	deleteAssets(creative_uuid: string, assets:IAsset[])
	{
		let formData = new FormData();
		let count = 0;
		for (let i = 0; i < assets.length; i++) {
			const asset:IAsset = assets[i];
			if(!asset.uuid) continue;
			formData.append(`assets[${count++}][uuid]`, asset.uuid);
		}
		//return this._delete<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/assets`, formData); // you cannot send data to a delete, only in query
		// @ts-ignore
		return this._post<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/assetsDelete`, formData);
	}
	// TODO remove this testing functionality!!
	setUserRole(creative_id: string, user_id: any, role:string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/userRole/${user_id}/${role}`, null);
	}
	setLabel(creative_id: string, label:string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/label/${label}`, null);
	}
	setFlag(creative_id: string, flag:number):Observable<any>{
		return this._post(`${this.base}/${creative_id}/flag/${flag}`, null);
	}
	setColorInfo(creative_id: string, info:{bg_color:string, bg_transparent:boolean}):Observable<any>{
		return this._post(`${this.base}/${creative_id}/colorInfo`, info);
	}
	setViewOnly(creative_id: string, viewonly:boolean):Observable<any>{
		return this._post(`${this.base}/${creative_id}/viewOnly`, viewonly);
	}
	setLayout(creative_id: string, layout:string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/layout/${layout}`, null);
	}
	saveSpecs(creative_uuid: string, specs: string) {
		return this._post(`${this.base}/${creative_uuid}/specs`, specs);
	}
	getProduction(creative_uuid: string)
	{
		return this._get<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/production`);
	}
	setProduction(creative_uuid: string, user_uuid: string)
	{
		return this._get<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/production/${user_uuid}`);
	}
	updatePublic(creative_uuid: string, pub: boolean) {
		return this._post(`${this.base}/${creative_uuid}/public`, pub);
	}
	creativeComplete(creative_id: number):Observable<any>{
		return this._post(`${this.base}/${creative_id}/complete`, null, {params:{method:"complete"}});
	}
	creativeApprove(creative_id: number):Observable<any>{
		return this._post(`${this.base}/${creative_id}/approve`);
	}
	creativeSubmitRequests(creative_id: number, amends:Amend[]):Observable<any>{
		return this._post(`${this.base}/${creative_id}/submitRequests`, amends);
	}
	getAvailableChannels(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/availableChannels`);
	}
	copy(creative_id: string, copies:any[]):Observable<any>{
		return this._post(`${this.base}/${creative_id}/copy`, copies);
	}
	assetCheck(creative_id: string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/assetCheck`);
	}
}
