<p>Add Approval Group</p>
<mat-form-field style="width:100%" (keydown.enter)="triggerSubmit()"><!--class="example-full-width"-->
    <mat-label>Enter name</mat-label>
    <input [(ngModel)]="group.name" matInput value=""><!--placeholder="name"-->
</mat-form-field>
<!--TODO add order UI (old stuff below)
<div *ngIf="group.name.length > 0 && data.approval_groups.length">
    <p>Choose order</p>
    <mat-list role="list">
        <ng-contaimer *ngFor="let grp of data.approval_groups; index as index">
            <mat-list-item *ngIf="group.order == index" role="listitem">
                {{group.name}}
                <mat-icon>arrow_up</mat-icon>
                <mat-icon>arrow_down</mat-icon>
            </mat-list-item>
            <mat-list-item role="listitem">{{grp.name}}</mat-list-item>
        </ng-contaimer>
        </mat-list>
</div>
<div *ngIf="group">
    group:{{group?.name}}
</div>
-->
<div class="buttons">
    <button class="small" mat-button color="warn" (click)="this.dialogRef.close()">Cancel</button>
    <button class="samll" mat-flat-button color="primary" (click)="save()" [disabled]="!group || group.name.length < 1">Save</button>
</div>
