<h3>Flag:</h3>
<div style="display: flex;">
    <div [ngClass]="{'selected-flag':isSelected(i)}" *ngFor="let icon of icons; index as i">
        <button class="flag" mat-icon-button (click)="setFlag(i)" [matTooltip]="icon.label">
            <mat-icon>{{icon.icon}}</mat-icon>
        </button>
    </div>
    <!--

        <div><button (click)="onColourSelected(null)" class="colour-label" mat-icon-button><mat-icon>block</mat-icon></button></div>
        <div *ngFor="let label of labels" [style]="{color:'#'+label}" [ngClass]="{'selected-label':selectedColour==label}"><button (click)="onColourSelected(label)" class="colour-label" mat-icon-button><mat-icon >circle</mat-icon></button></div>
    -->
</div>
<!--
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Select a flag">
    <mat-icon>{{this.getIcon(selectedFlag)}}</mat-icon>
  </button>
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let icon of icons; index as i" (click)="setFlag(i)">
        <mat-icon>{{icon.icon}}</mat-icon>
        <span>{{icon.label}}</span>
    </button>
</mat-menu>
<div>

</div>
-->