import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-project-list',
	templateUrl: './project-list.component.html',
	styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	account_id:any = false;
	workgroup_id:any = false;
	user_id:any = false;
	
	accounts:any;
	workgroups:any;
	
	renderTree:any = false;
	renderTreeTimer:any = false;
	
	current_user:any = null;

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService) { }
	
	ngOnInit() {
		this.api.getallaccounts.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.accounts = res.data;
		})
		this.api.getAllAccounts();
		
		this.current_user = this.api.getCurrentUser();
		this.api.currentuser.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.current_user = res;
			if(!this.current_user.roles.architect && !this.current_user.roles.superadmin && !this.current_user.roles.admin){
				this.renderTreeComponent();
			}
		});
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	
	setAccountId(value:any){
		this.account_id = value;
		this.workgroup_id = false;
		this.workgroups = [];
		this.api.getworkgroupsforaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.workgroups = res.data;
		})
		this.api.getWorkgroupsForAccount({account_id:this.account_id}, this);
		this.renderTreeComponent();
	}
	
	setWorkgroupId(value:any){
		this.workgroup_id = value;
		this.renderTreeComponent();
	}
	
	setUserId(value:any){
		this.user_id = value;
	}
	
	renderTreeComponent(){
		this.renderTree = false;
		clearTimeout(this.renderTreeTimer);
		this.renderTreeTimer = setTimeout(() => {
			this.renderTree = true;
		}, 500);
	}
}