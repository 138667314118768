import { ModuleWithProviders, NgModule } from "@angular/core";

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatSliderModule } from "@angular/material/slider";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRadioModule } from "@angular/material/radio";


@NgModule({
	imports: [
		MatButtonModule, 
		MatCheckboxModule, 
		MatFormFieldModule, 
		MatCardModule, 
		MatInputModule, 
		MatTreeModule, 
		MatIconModule, 
		MatSelectModule, 
		MatTableModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatBottomSheetModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatBadgeModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatStepperModule,
		MatExpansionModule,
		MatTabsModule,
		MatListModule,
		MatChipsModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		MatProgressBarModule,
		MatSidenavModule,
		MatPaginatorModule,
		MatSliderModule,
		ClipboardModule,
		MatRadioModule,
	],
	exports: [
		MatButtonModule, 
		MatCheckboxModule, 
		MatFormFieldModule, 
		MatCardModule, 
		MatInputModule, 
		MatTreeModule, 
		MatIconModule, 
		MatSelectModule, 
		MatTableModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatBottomSheetModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatBadgeModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatStepperModule,
		MatExpansionModule,
		MatTabsModule,
		MatListModule,
		MatChipsModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		MatProgressBarModule,
		MatSidenavModule,
		MatPaginatorModule,
		MatSortModule,
		MatSliderModule,
		ClipboardModule,
		MatRadioModule,
	],
})

export class MaterialModule {
    constructor() { }

    static forRoot(): ModuleWithProviders<MaterialModule> {
        return {
            ngModule: MaterialModule,
            providers: []
        };
    }
}