import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

	constructor() { }

	@Input() name;

  switched:string = 'switched-off';
  
  ngOnInit() {
  	this.switched = 'switched-off';
  }

  toggleMe(){
  	this.switched == 'switched-off' ? this.switched = 'switched-on' : this.switched = 'switched-off';
  }

  toggleOff(){
    this.switched = 'switched-off';
  }
}
