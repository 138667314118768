// return a < b ? -1 : a == b ? 0 : 1;
export function compare(a, b) : number {
    if(!isNaN(a) && !isNaN(b))
    {
        return a - b;
    } else if(typeof a === 'string' && typeof b === 'string')
    {
        return a.localeCompare(b, undefined, {numeric:true});
    }
    return 0;
}