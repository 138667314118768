import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-my-tasks',
	templateUrl: './my-tasks.component.html',
	styleUrls: ['./my-tasks.component.scss']
})
export class MyTasksComponent implements OnInit {

	@Input() my_tasks:any;
	
	constructor() { }
	
	ngOnInit(): void {
	}

}
