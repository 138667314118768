import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, NavigationStart, NavigationEnd } from '@angular/router';
import { DialogService } from './../../services/dialog.service';
import { ApiService } from './../../api/api.service';
import { StringsService } from './../../services/strings.service';
import { AuthService } from './../../auth/auth.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PusherService } from 'src/app/services/pusher.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsComponent } from 'src/app/dialogs/notifications/notifications.component';
import { AppUser, AppUserService } from 'src/app/services/app-user.service';
import { ThemeService } from 'src/app/services/theme.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/api/admin.service';
import { IMessage } from 'src/app/models/message';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	isLoggedIn$: Observable<boolean>;
	
	//user:IUser;
	appUser:AppUser;

	constructor(private route: ActivatedRoute,
		private router: Router,
		public api:ApiService,
		public adminService:AdminService,
		public appUserService:AppUserService,
		private auth: AuthService,
		private ds: DialogService,
		private dialog: MatDialog,
		private themeService: ThemeService,
		public strings: StringsService,
		public matSnack: MatSnackBar,
		private pusher:PusherService) { }
	
	ngOnChanges() {
		
	}
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		//this.appUser = this.appUserService.appUser;
		//console.log("header init");
		this.appUserService.appUserSubject.pipe(takeUntil(this._unsubscribe)).subscribe(appUser => {
			//console.log("header init");
			this.appUser = appUser;
			if(this.appUser)
			{
				this.pusher.init(this.api.token);
				this.listen();
			}
		}, error =>
		{
			// failed
			//console.log("header user error!");			
			console.log(error);
			this.api.gotoPage('login');			
		});
		
		this.api.TOKENN.pipe(takeUntil(this._unsubscribe)).subscribe(token => {
			if(!token) return;
			// moved up
			//this.pusher.init(this.api.token);
			//this.listen();
		});
		

		//this.pusher.getPublicMessage().subscribe()
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.isLoggedIn$ = this.auth.isAuthenticated;
			}
			if (event instanceof NavigationEnd) {
				
			}
		});
	}
	stopImpersonation()
	{
		this.adminService.stopImpersonation().subscribe(res => {
			window.location.href = "/";
		});
	}
	listening = false;
	listen()
	{
		if(this.listening) return;
		if(!this.appUser || !this.pusher.initialized) return;
		this.listening = true;

		this.pusher.joinMyChat(this.appUser.uuid);
		this.pusher.myChat.messages.pipe(takeUntil(this._unsubscribe)).subscribe(
			(message:IMessage) => {
				if(message.type == "user")
				{
					if(message.metadata?.action == "role_updated")
					{
						// my role has changed.... now what?
						this.appUserService.getAppUser();
					}else{
						this.matSnack.open(message.metadata?.message, null, {duration:3000});
					}						
				}
			}
		);
	}
	/*
	ngDoCheck() {
		console.log("HEADER ngDoCheck");
	}
	ngAfterContentInit() {
		console.log("HEADER ngAfterContentInit");
	}
	ngAfterViewInit() {
		console.log("HEADER ngAfterViewInit");
	}
	ngAfterViewChecked() {
		console.log("HEADER ngAfterViewChecked");
	}
	*/
	toggleDarkTheme(checked: boolean) {
		this.themeService.setDarkTheme(checked);
	  }
	showNotifications()
	{
		const dialogRef = this.dialog.open(NotificationsComponent);
	}
	confirmLogOut(){
		 let confirm:any = {
			 title: 'Log out',
			 message: 'Are you sure?',
			 confirmAction: () => {
				 this.api.gotoPage('logout');
			 }
		 };
		 this.ds.openConfirm(confirm);
	}
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}