import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-assign-users-dialog',
	templateUrl: './assign-users-dialog.component.html',
	styleUrls: ['./assign-users-dialog.component.scss']
})
export class AssignUsersDialogComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	title:any = 'User access';
	dismiss_button_label:any = 'Save';
	all_users:any = false;
	assigned_users:any = false;
	container_id:any;
	container_type:any;
	selected_users:any = [];
	account_id:any;
	
	no_users_message:any = false;

	constructor(public dialogRef: MatDialogRef<AssignUsersDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api:ApiService, private router:Router) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		if(typeof this.data.title !== 'undefined'){
			this.title = this.data.title;
		}
		if(typeof this.data.dismiss_button_label !== 'undefined'){
			this.dismiss_button_label = this.data.dismiss_button_label;
		}
		if(typeof this.data.container_id !== 'undefined'){
			this.container_id = this.data.container_id;
		}
		if(typeof this.data.container_type !== 'undefined'){
			this.container_type = this.data.container_type;
		}
		if(typeof this.data.account_id !== 'undefined'){
			this.account_id = this.data.account_id;
		}
		
		//@todo - incorporate account here
		//console.log('account_id >>> ', this.account_id);
		
		if(typeof this.data.project == 'undefined' || !this.data.project){
			
			//console.log('this.container_type', this.container_type);
			
			if(this.container_type == '2'){
				this.subscription = this.api.getallusers.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					if(typeof res['caller'] == 'undefined' || res['caller'] !== this) return;
					this.all_users = [];
					for(let i in res.data.users){
						let user:any = res.data.users[i];
						user.checked = (typeof res.data.workgroup_access[user.uuid] !== 'undefined') ? true : false;
						this.all_users.push(user);
					}
					this.assigned_users = res.data.workgroup_access;
				});
				this.api.getAllUsers({workgroup_id: this.data.container_id}, this);
			}
		}else{
			this.subscription = this.api.getusersforworkgroup.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				if(typeof res['caller'] == 'undefined' || res['caller'] !== this) return;
				this.no_users_message = false;
				if(res.data.length == 0){
					this.no_users_message = 'No users assigned to workgroup';
				}else{
					this.all_users = [];
					for(let i in res.data.users){
						let user:any = res.data.users[i];
						user.checked = (typeof res.data.project_access[user.uuid] !== 'undefined') ? true : false;
						this.all_users.push(user);
					}
					this.assigned_users = res.data.project_access;
					//console.log('res', res);
					//console.log('this.all_users', this.all_users);
				}
				
			});
			this.api.getUsersForWorkgroup({workgroup_id:this.data.project.workgroup_id, project_id:this.data.project.uuid}, this);
		}
	}
	
	onSelection(e:any, arr:any){
		this.selected_users = [];
		for(let i in arr){
			this.selected_users.push(arr[i].value);
		}
	}
	
	dismissAction(){
		let user:any = this.api.getCurrentUser();
/*
		console.log('this.container_id', this.container_id);
		console.log('this.container_type', this.container_type);
*/
		if(this.container_id && this.container_type && this.selected_users.length > 0){
			this.subscription = this.api.assignmultipleusers.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				setTimeout(() => {
					$('body').removeClass('saving').removeClass('loading');
				}, 400);
				
				this.dialogRef.close();
			});
			
			
			let assigned:any = JSON.parse(JSON.stringify(this.assigned_users));
			for(let i in this.selected_users){
				let selected:any = this.selected_users[i];
				if(typeof assigned[selected] !== 'undefined'){
					delete assigned[selected];
				}
			}
			
			let remove:any = [];
			for(let uuid in assigned){
				remove.push(uuid);
			}
			if(remove.length > 0){
				this.api.assignMultipleUsers({container_id:this.container_id, container_type:this.container_type, users:this.selected_users, remove:remove});
			}else{
				this.api.assignMultipleUsers({container_id:this.container_id, container_type:this.container_type, users:this.selected_users});
			}
			
			$('body').addClass('saving').addClass('loading');
		}
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}