<div class="wrapper" *ngIf="project">
<div class="container">
	<div class="details wrap">
<!-- 		<h1>Project Detail View</h1> -->
		<h1>{{this.project.name}}</h1>
		<mat-form-field class="description">
			<mat-label>Project Description</mat-label>
			<textarea matInput
				readonly
			    cdkTextareaAutosize
			    cdkAutosizeMinRows="1"
			    cdkAutosizeMaxRows="0">{{project.description}}</textarea>
		</mat-form-field>
		<div class="download_wrapper">
		<button class="download" mat-raised-button>Brief.doc<mat-icon>description</mat-icon></button>
		</div>
		<div class="download_wrapper">
		<button class="download" mat-raised-button>Brand_guidelines.pdf<mat-icon>description</mat-icon></button>
		</div>
	</div>
	<div class="wrap">
	<mat-card class="status project">
		<h4>Current status</h4>
		<div class="row"><p>GLOBAL / <span class="project_total">{{project.total}}</span></p></div>
		<div class="row">
			<div class="col">
				<div class="project_health overview">
					<div class="bar" *ngFor="let bar of project.health">
						<div class="bar_total">{{bar.backward+bar.forward}}</div>
						<div class="bar_background">
							<div class="bar_inner forward" [attr.data-forward]="bar.forward"></div>
							<div class="bar_inner backward" [attr.data-backward]="bar.backward"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col1 segment_definition">{{segment_definition.plural}}</div>
			<div class="col2">TOTAL ASSETS</div>
			<div class="col3">STATUS</div>
			<div class="segment_status">
				<div class="row project" *ngFor="let segment of segment_status">
					<div class="col1">{{segment.name}}</div>
					<div class="col2 project_total">/{{segment.total}}</div>
					<div class="col3">
						<div class="project_health">
							<div class="bar" *ngFor="let bar of segment.health">
								<div class="bar_background">
									<div class="bar_inner forward" [attr.data-forward]="bar.forward"></div>
									<div class="bar_inner backward" [attr.data-backward]="bar.backward"></div>
								</div>
								<div class="bar_total">{{bar.backward+bar.forward}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
	</div>
	<div class="wrap">
		<mat-card class="notifications">
			<h4>Notifications</h4>
			<div class="notifications_list">
				<mat-card class="notification" *ngFor="let notification of notifications">
					<div class="text">{{notification.text}}</div>
				</mat-card>
			</div>
		</mat-card>
	</div>
</div>	
	
	
	<div class="clearfix"></div>
	
	<mat-card class="temp_grid">
	
		<h2>TEMP GRID</h2>
	
		<div class="select" *ngIf="segment_labels && segment_definition">
			<mat-form-field class="segment_label">
			  <mat-label>{{segment_definition.plural}}</mat-label>
			  <mat-select id="segment_label" [value]="segment_label_id" (selectionChange)="setSegmentLabelId($event.value)">
			    <mat-option *ngFor="let segment_label of segment_labels" [value]="segment_label.uuid">
			      {{segment_label.name}}
			    </mat-option>
			  </mat-select>
			</mat-form-field>
			<button mat-raised-button *ngIf="segment_label_id" (click)="addSegment(project.uuid)">Add {{segment_definition.single}}</button>
		</div>
		
		<div class="segments_outer">
			<div class="segment_wrapper" *ngFor="let segment of segments">
				<mat-card>
					<h3>{{segment.name}}<button mat-icon-button (click)="removeSegment(segment)"><mat-icon>delete</mat-icon></button></h3>
					<mat-card *ngIf="creatives[segment.uuid]">
						<mat-list role="list">
							<mat-list-item *ngFor="let creative of creatives[segment.uuid]; index as i" role="listitem" class="list-item">
								<div class="row creative" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
									<p class="name">{{creative.name}}</p>
									<button class="icon" mat-icon-button (click)="api.gotoPage('creative/'+creative.uuid)"><mat-icon>edit</mat-icon></button>
								</div>
							</mat-list-item>
						</mat-list>
					</mat-card>
					<mat-form-field>
						<input matInput #add_creative_name class="login_input" placeholder="Name" value="">
					</mat-form-field>
					<button mat-raised-button (click)="addCreative(segment.uuid, add_creative_name.value)">Add Creative</button>
				</mat-card>
			</div>
		</div>
	
	</mat-card>
	
</div>