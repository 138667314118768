<div class="email">
	<div class="button-bar">
		<div>
			<mat-button-toggle-group [(ngModel)]="mode" #group="matButtonToggleGroup">	<!-- [hideSingleSelectionIndicator]="true"-->
				<mat-button-toggle value="code" matTooltip="Code edit">
					<mat-icon>code_blocks</mat-icon>
				</mat-button-toggle>
				<mat-button-toggle value="preview" matTooltip="Preview">
					<mat-icon>preview</mat-icon>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div>
			<ng-container *ngIf="mode == 'code'">
				<button [disabled]="false" mat-icon-button color="primary" (click)="copyToClipboard()" matTooltip="Copy html to clipboard"><mat-icon>content_copy</mat-icon></button>
				<button [disabled]="false" mat-icon-button color="primary" (click)="download()" matTooltip="Download"><mat-icon>download</mat-icon></button>
				<button [disabled]="false" mat-icon-button color="primary" (click)="send()" matTooltip="Send a test email"><mat-icon>send</mat-icon></button>
			</ng-container>
			<ng-container *ngIf="mode == 'preview'">
				<mat-button-toggle-group [(ngModel)]="preview_mode" #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true">
					<mat-button-toggle value="desktop" matTooltip="Basic desktop preview">
					  <mat-icon>computer</mat-icon>
					</mat-button-toggle>
					<mat-button-toggle value="mobile" matTooltip="Basic mobile preview">
					  <mat-icon>smartphone</mat-icon>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</ng-container>
		</div>
	</div>
	<div class="content">
		<ng-container *ngIf="mode == 'code'">
			<div class="editor" >
				<div id="editor" #aceEditor></div>
				<!--<textarea class="code-editor" [(ngModel)]="code"></textarea> -->
				<!--<pre *ngIf="false" #editor contenteditable="true" spellcheck="false"  (keydown)="tabCatch($event)" (input)="onChange($event)"></pre> -->
			</div>
		</ng-container>
		<ng-container *ngIf="mode == 'preview'">
			<!--this.assetVO.preview || this.assetVO.asset.uri-->
			<div class="wrapper">
				<div class="mode" [ngClass]="preview_mode" resizeObserver (resize)="onPreviewResized($event)">
					<iframe #iframe class="preview" [src]="this.getSrc() | safe:'resourceUrl'" frameborder="0" width="100%" height="100%"></iframe>
					<div class="overlay" *ngIf="false && overlayActive">
						<span class="overlay-rect" [ngStyle]="getOverlayRectStyle()"></span>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<mat-tab-group #tabGroup (selectedTabChange)="onTabChange($event)" *ngIf="false">
		<mat-tab label="Code">
			<div class="content">
				<div class="button-bar">
					<button [disabled]="false" mat-icon-button color="primary" (click)="copyToClipboard()" matTooltip="Copy html to clipboard"><mat-icon>content_copy</mat-icon></button>
					<button [disabled]="false" mat-icon-button color="primary" (click)="download()" matTooltip="Download"><mat-icon>download</mat-icon></button>
					<button [disabled]="false" mat-icon-button color="primary" (click)="send()" matTooltip="Send a test email"><mat-icon>send</mat-icon></button>
				</div>
				<div class="editor">
					<!--<textarea class="code-editor" [(ngModel)]="code"></textarea> -->
					<pre #editor contenteditable="true" spellcheck="false"  (keydown)="tabCatch($event)" (input)="onChange($event)"></pre>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Preview"> 
			<ng-template matTabContent>
				<div class="content">
					
					<!--this.assetVO.preview || this.assetVO.asset.uri-->
					<div class="wrapper">
						<div class="mode" [ngClass]="preview_mode" resizeObserver (resize)="onPreviewResized($event)">
							<iframe #iframe class="preview" [src]="this.getSrc() | safe:'resourceUrl'" frameborder="0" width="100%" height="100%"></iframe>
							<div class="overlay">
								<span class="overlay-rect" [ngStyle]="getOverlayRectStyle()"></span>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</mat-tab>
	  </mat-tab-group>
</div>