import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreativeService } from 'src/app/api/creative.service';
import { IUser } from 'src/app/models/user.model';
import { AppUserService } from 'src/app/services/app-user.service';
export interface IChatMessage {
    uuid?:string;
    user?:IUser;
    user_id?:string;
    creative_id?:string;
    text?:string;
    created_at?:string;
    updataed_at?:string;
}
export class DiscussionMessage {
    public created:string;
    constructor(public message:IChatMessage, public mine:boolean, public fresh:boolean = false) {
        let date = new Date(this.message.created_at);
        let now = new Date();
        this.created = ((now.getTime() - date.getTime()) < 1000*60*60*24 && now.getDate() === date.getDate()) ? date.toLocaleTimeString() : date.toLocaleString();
    }
}

@Injectable({
	providedIn: 'root'
})
export class CreativeDiscussionService {
    deleteMessages(data: any) {
      throw new Error('Method not implemented.');
    }

    constructor(
        private appUserService:AppUserService,
        private creativeService:CreativeService,
    ){
        console.log("SERVICE CREATED");
        this.creativeService.discussion$.subscribe(messages => this.messages = messages);
    }
    private _messages:DiscussionMessage[];
    private _messagesSubject = new BehaviorSubject<DiscussionMessage[]>(null);
    public messages$ = this._messagesSubject.asObservable();
    private _groupedMessages:DiscussionMessage[][];
    private _groupedMessagesSubject = new BehaviorSubject<DiscussionMessage[][]>(null);
    public groupedMessages$ = this._groupedMessagesSubject.asObservable();
    private _loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this._loadingSubject.asObservable();
    public loading()
    {
        this._loadingSubject.next(true);
    }
    mergeMessage(message: IChatMessage) {
        throw new Error("Unsused remove");
        
        // if there are no messages already just add it.
        // otherwise search from the end to find the correct slot based on created_at timestamp
        let vo = new DiscussionMessage(message, this.appUserService.appUser.uuid == message.user_id, true);
        if(!this._messages.length)
        {
            this._messages.push(vo);
        } else {
            // find the correct slot (time wise) and insert it - most likely to be the last
            for (let i = this._messages.length-1; i >= 0; i--) {
                const testVO = this._messages[i];
                console.log("mergeing attempt, ",vo.message.created_at, testVO.message.created_at,  Date.parse(vo.message.created_at) >= Date.parse(testVO.message.created_at));
                
                if(Date.parse(vo.message.created_at) >= Date.parse(testVO.message.created_at))
                {
                    this._messages.splice(i + 1, 0, vo);
                    break;
                }
            }
            // TODO handle failure to insert
        }
        this._messagesSubject.next(this._messages);
        this.generateGroups();
	}
    public set messages(messages:IChatMessage[])
	{
        this._messages = []; // null?
        console.log("messages updated", messages?.length, messages);
        if(messages?.length)
        {
            // TODO group the message by author and then subgroup by time delta i.e. < 1 since last message
            messages.forEach(message => {
                this._messages.push(new DiscussionMessage(message, this.appUserService.appUser.uuid == message.user_id))
            })
            //console.log("GROUPED2", groupedByUser2);
        }
		//this._messages = messages.map(message => {
        //    return new DiscussionMessage(message, this.aooUserService.appUser.uuid == message.user_id);
        //});
        this._loadingSubject.next(false);
		this._messagesSubject.next(this._messages);
        this.generateGroups();
	}
    /**
     * Group all the messages by user then by time difference
     */
    generateGroups()
    {
        this._groupedMessages = [];
        if(this._messages?.length)
        {
            // let groupedByUser2 = this.groupBy(this._messages, (a:DiscussionMessage, b:DiscussionMessage)=>(a.message.user_id == b.message.user_id ? a.message.user : false ));
            let groupedByUser = this.groupBy(this._messages, (a:DiscussionMessage, b:DiscussionMessage)=>a.message.user_id == b.message.user_id);
            groupedByUser.forEach((groupByUser, index) => {
                groupedByUser[index] = this.groupBy(groupByUser, (a:DiscussionMessage, b:DiscussionMessage)=>Math.abs(Date.parse(b.message.created_at) - Date.parse(a.message.created_at)) < 1000*60)
            })

            this._groupedMessages = groupedByUser;
        }
        console.log("message groups generated", this._messages?.length, this._groupedMessages);
		this._groupedMessagesSubject.next(this._groupedMessages);
    }

    // no sorting, but group elements by a comparison function
    // if comparison function is not a boolean true but a value, then use this as a the key
    /*
        i.e. let items = [{name:"Apple", type:"fruit"}, {name:"Carrot", type:"vegetable"}, {name:"Pear", type:"fruit"} ]
        groupBy(items, (a, b) => a.type == b.type)
        groupBy(items, (a, b) => a.type == b.type ? a.type : false);
    */
    groupBy(items:any[], comparison:string|((a: any, b:any)=>any|false), key:string = null):any[]
    {
        let groups = [];
        let group;
        let resolve = key && key.indexOf(".") != -1;
        items.forEach((entry, index) => {
            if(index == 0)
            {
                //group = key ? {key:result, values:[]} : [];

                group = [entry];
                if(key)  group = {key:entry[key], values:group};
                groups.push(group);
            } else {
                if(typeof comparison === "string")
                {
                    let same = entry[comparison] == items[index-1][comparison];
                    if(same)
                    {
                        group.push(entry);
                    }else{
                        group = [entry];
                        groups.push(group);
                    }
                }else {
                    let same = comparison(entry, items[index-1])
                    if(same)
                    {
                        group.push(entry);
                    }else{
                        group = [entry];
                        if(key)  group = {key:entry[key], values:group};
                        groups.push(group);
                    }
                }
            }
        });
        return groups;
    }
    groupByOld(items:any[], callback:(a: any, b:any)=>any|false):any[]
    {
        let groups = [];
        let group;
        items.forEach((entry, index) => {
            var result;
            if(!index || !(result = callback(entry, items[index-1])))
            {
                group = result === true ? {key:result, values:[]} : [];
                groups.push(group);
                if(result === true) group=group.values;
            }
            group.push(entry);
        });
        return groups;
    }
    // https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
    resolve(path, obj=self, separator='.') {
        var properties = Array.isArray(path) ? path : path.split(separator)
        return properties.reduce((prev, curr) => prev?.[curr], obj)
    }


    private _typingUsersSubject = new BehaviorSubject<any[]>(null);
    public typingUsers$ = this._typingUsersSubject.asObservable();
    private _typingUsersStringSubject = new BehaviorSubject<string>(null);
    public typingUsersString$ = this._typingUsersStringSubject.asObservable();
    public someoneTyping(vo:{user, typing:boolean})
    {
        let users = this._typingUsersSubject.value ?? [];
        if(vo.typing)
        {
            // see if in there
            if(!users.find(user => user.uuid == vo.user.uuid))
            {
                users.push(vo.user);
            }
        }else{
            users = users.filter(user => user.uuid != vo.user.uuid);
        }
        this._typingUsersSubject.next(users);
        if(users?.length)
        {
            let usersString;
            users.map(user => user.name).toString();
            if(users.length == 1)
            {
                usersString = `${users[0].name} is typing...`;
            }else if (users.length == 2)
            {
                let name1 = users[0].name;
                let name2 = users[1].name;
                if(name1.length > 10) name1 = name1.split(" ")[0];
                if(name2.length > 10) name2 = name2.split(" ")[0];
                usersString = `${name1} and ${name2} are typing...`;
            }else {
                usersString = `${users.length} users are typing...`;
            }
            this._typingUsersStringSubject.next(usersString);
        } else {
            this._typingUsersStringSubject.next(null);
        }
    }
    clearTyping()
    {
        this._typingUsersSubject.next(null);
        this._typingUsersStringSubject.next(null);
    }
}