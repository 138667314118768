import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, take} from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { IAccount } from 'src/app/models/account.model';
import { AppUser, AppUserService } from 'src/app/services/app-user.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  constructor(
    private api:ApiService,
    private activatedRoute:ActivatedRoute,
    private _router: Router,
    public appUserService:AppUserService,
    private title:Title,
    )
  {
    //super();
  }
  
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  crumbs:any[] = [];
  accounts:any[];
  account:any;
  sub:Subscription;
  appUser:AppUser;

  ngOnDestroy(): void {
    //console.log("crumb destroy");
    this.sub.unsubscribe();
    //super.ngOnDestroy();
  }
  ngOnInit(): void {
    this.sub = this.appUserService.appUserSubject.pipe().subscribe(res => {
      this.appUser = res?.data;
    });

    this.sub = this.api.crumbs$
    .pipe()
  //  .pipe(takeUntil(this.destroyed))
    .subscribe(crumbsVO => {
      //console.log("crumbs subscribe", crumbsVO);
      
      if(!crumbsVO)
      {
        this.title.setTitle('Oceanview');
        return;
      }
      /*
      for (let i = 0; i < crumbs.length; i++) {
        const crumb = crumbs[i];
        crumb.link = decodeURIComponent(crumb.link);
        crumbs[i] = crumb;
      }*/
      let crumbs = crumbsVO.crumbs || [];
      let account_uuid = crumbsVO.account_uuid;
      if(account_uuid)
      {
        this.appUserService.setAccount(account_uuid);
      }
      // analyse the crumbs and open up active channel for creatives
      if(crumbs.length == 3)
      {
        // creative hit
        let creative_uuid = crumbs[2].uuid;
        let channels = crumbs[2].neighbours;
        if(channels)
        {
          let ownerChannel = channels.find(channel => channel.creatives.find(creative => creative.uuid == creative_uuid)); 
          if(ownerChannel) ownerChannel.open = true;
        }
      }

      if(crumbs.length)
      {
        let extra;
        if(crumbs.length == 3)
        {
          extra = crumbs[crumbs.length-2].name + ' / ' + crumbs[crumbs.length-1].name;
        }else{
          extra = crumbs[crumbs.length-1].name;
        }
        this.title.setTitle('Oceanview - ' + extra);
      } else {
        this.title.setTitle('Oceanview');
      }
			this.crumbs = crumbs;
	});

	this.sub = this.appUserService.appUserObservable$.pipe(take(1)).subscribe(res => {
		//console.log("login precheck", res);			
		if(res)
		{
			this.accounts = res.accounts;
		}else {
			// wait for them to login
		}
	}, error => {
		console.log("app user error", error);
		
	});

	this.sub = this.appUserService.account$.pipe(take(1)).subscribe(res => {
		//console.log("login precheck", res);			
		if(res)
		{
			this.account = res;
		}else {
			// wait for them to login
		}
	}, error => {
		console.log("app account error", error);
		
	});

    return;
    let url = this._router.url;
    if(url.indexOf("projects/") != -1){
      
      this.crumbs = ["projects"];
      if(url.indexOf("project/") != -1)
      {
        this.crumbs.push("project");
      }
    }else{
      if(url.indexOf("creative/") != -1){
        this.crumbs = ["projects", "project", "creative"];
      }
    }
    return;
    let routerUrl: string, routerList: Array<any>, target: any;
  this._router.events.subscribe((router: any) => {
        let routerUrl = router.urlAfterRedirects;
        if (routerUrl && typeof routerUrl === 'string') {

          this.crumbs.length = 0;
          // 取得目前routing url用/區格, [0]=第一層, [1]=第二層 ...etc
          routerList = routerUrl.slice(1).split('/');
          routerList.forEach((router, index) => {
            // 找到這一層在menu的路徑和目前routing相同的路徑
            target = target.find(page => page.path.slice(2) === router);
            // 存到crumbs到時後直接loop這個list就是麵包屑了
            this.crumbs.push({
              name: target.name,
              // 第二層開始路由要加上前一層的routing, 用相對位置會造成routing錯誤
              path: (index === 0) ? target.path : `${this.crumbs[index-1].path}/${target.path.slice(2)}`
            });
            
            // 下一層要比對的目標是這一層指定的子頁面
            if (index+1 !== routerList.length) {
              target = target.children;
            }
          });

          console.log(this.crumbs);
        }
      });
      this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => console.log(this.createBreadcrumbs(this.activatedRoute.root)));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: any[] = []): any[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbsComponent.ROUTE_DATA_BREADCRUMB];
      if (label === null || label === undefined) {
        breadcrumbs.push({label, url});
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
  changeAccount(account:IAccount)
  {
    //console.log("change account", account);
    localStorage.setItem("account_uuid", account.uuid);
    //this._router.navigate(['/']);
    window.location.href = window.location.origin;    
  }
  neighbourClick(crumb, neighbour)
  {
    let link = crumb.link.split(crumb.uuid).join(neighbour.uuid);
    this._router.navigate([link]);
  }
}
