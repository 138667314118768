import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-my-notifications',
	templateUrl: './my-notifications.component.html',
	styleUrls: ['./my-notifications.component.scss']
})
export class MyNotificationsComponent implements OnInit {

	@Input() my_notifications:any;
	
	constructor() { }
	
	ngOnInit(): void {
	}

}
