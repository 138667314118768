<mat-card class="add_user_form">
	<h3>Add User</h3>
	<form class="login-form">
		<div class="inputs">
			<mat-form-field>
				<input matInput id="name" class="login_input" placeholder="Name" value="">
			</mat-form-field>
			<mat-form-field>
				<input matInput id="email" class="login_input" placeholder="Email" value="">
			</mat-form-field>
		</div>
		<div class="inputs">
			<div class="select" *ngIf="accounts">
				<mat-form-field class="account">
				  <mat-label>Account</mat-label>
				  <mat-select id="account" [value]="account_id" (selectionChange)="setAccountId($event.value)">
				    <mat-option *ngFor="let account of accounts" [value]="account.uuid">
				      {{account.name}}
				    </mat-option>
				  </mat-select>
				</mat-form-field>
			</div>
			<div class="select" *ngIf="workgroups">
				<mat-form-field class="workgroup">
				  <mat-label>Workgroup</mat-label>
				  <mat-select id="workgroup" [value]="workgroup_id" (selectionChange)="setWorkgroupId($event.value)">
				    <mat-option *ngFor="let workgroup of workgroups" [value]="workgroup.uuid">
				      {{workgroup.name}}
				    </mat-option>
				  </mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="button_wrapper">
			<button (click)="addUser()" mat-raised-button>Add User</button>
		</div>
	</form>
</mat-card>
<mat-card class="all_users" *ngIf="users.length > 0">
	<h3>Users</h3>
	<div class="inputs">
		<div class="select" *ngIf="user_accounts">
			<mat-form-field class="account">
			  <mat-label>Account</mat-label>
			  <mat-select id="account" [value]="user_account_id" (selectionChange)="setUserAccountId($event.value)">
			    <mat-option *ngFor="let account of user_accounts" [value]="account.uuid">
			      {{account.name}}
			    </mat-option>
			  </mat-select>
			</mat-form-field>
		</div>
		<div class="select" *ngIf="user_workgroups">
			<mat-form-field class="workgroup">
			  <mat-label>Workgroup</mat-label>
			  <mat-select id="workgroup" [value]="user_workgroup_id" (selectionChange)="setUserWorkgroupId($event.value)">
			    <mat-option *ngFor="let workgroup of user_workgroups" [value]="workgroup.uuid">
			      {{workgroup.name}}
			    </mat-option>
			  </mat-select>
			</mat-form-field>
		</div>
	</div>
	<button mat-raised-button (click)="showAllUsers()">Show All</button>
	<mat-list role="list">
		<mat-list-item *ngFor="let user of users; index as i" role="listitem" class="list-item" [attr.data-user-uuid]="user.uuid">
			<div class="user" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
				<span class="isactive" [attr.data-active]="user.active"></span>
				<p class="name">{{user.name}}</p>
				<button [attr.data-tooltip-location]="'top'" [attr.data-tooltip-content]="strings.getTooltip('btn_profile')" class="icon profile grey" title="" (click)="api.gotoPage('user/'+user.uuid)"><mat-icon>assignment</mat-icon></button>
				<button [attr.data-tooltip-location]="'top'" [attr.data-tooltip-content]="strings.getTooltip('btn_delete')" class="icon delete red" (click)="confirmDeleteUser(user.uuid, user.name)"><mat-icon>delete</mat-icon></button>
			</div>
		</mat-list-item>
	</mat-list>
</mat-card>