<div class="pdf">
    <ng-container *ngIf="getPath()">
        <ng-container *ngIf="false">
            <iframe [src]="getPath() | safe:'resourceUrl'" frameborder="0" width="100%" height="100%"></iframe>
        </ng-container>
        <div *ngIf="true" class="pdfjs">
            <div class="controls">
                <div>
                    <button (click)="toggleMenu()" mat-icon-button><mat-icon>menu</mat-icon></button>
                    <button mat-icon-button [matMenuTriggerFor]="menuSettings"><mat-icon>settings</mat-icon></button>
                    <mat-menu #menuSettings="matMenu">
                        <button (click)="pageModeSingle()" mat-menu-item [disabled]="pageMode == 0">
                            <mat-icon>filter_1</mat-icon>
                            <span>Single page</span>
                        </button>
                        <button (click)="pageModeDouble()" mat-menu-item [disabled]="pageMode == 1">
                            <mat-icon>filter_2</mat-icon>
                            <span>Double page view</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button (click)="coverModeToggle()" mat-menu-item>
                            <mat-icon>auto_stories</mat-icon>
                            <span>Cover mode</span>
                        </button>
                    </mat-menu>
                </div>
                <!--
                    <button (click)="pageModeToggle()" mat-icon-button><mat-icon *ngIf="pageMode == 0">filter_1</mat-icon><mat-icon *ngIf="pageMode != 0">filter_2</mat-icon></button>
                -->
                <!-- page -->
                <div>
                    <button [disabled]="currentPage <= 1" (click)="currentPage = 1" mat-icon-button><mat-icon>first_page</mat-icon></button>
                    <button [disabled]="currentPage <= 1" (click)="prevPage()" mat-icon-button><mat-icon>arrow_left</mat-icon></button>
                    <span>
                        {{currentPage}}/{{numPages}}
                    </span>
                    <button [disabled]="currentPage >= numPages" (click)="nextPage()" mat-icon-button><mat-icon>arrow_right</mat-icon></button>
                    <button [disabled]="currentPage >= numPages" (click)="currentPage = numPages" mat-icon-button><mat-icon>last_page</mat-icon></button>
                </div>

                <!-- zoom -->
                <div class="zoom-controls">
                    <button (click)="zoomOut()" mat-icon-button><mat-icon>zoom_out</mat-icon></button>
                    <button class="ellipses" mat-button [matMenuTriggerFor]="menuZoom">{{ this.autoFit ? "Automatic fit" : ((currentZoom * 100).toFixed(0) + "%")}}</button>
                    <!--
                        <span>        
                            {{(currentZoom * 100).toFixed(0)}}%
                        </span>
                    -->
                    <button (click)="zoomIn()" mat-icon-button><mat-icon>zoom_in</mat-icon></button>
                    <mat-menu #menuZoom="matMenu">
                        <button mat-menu-item *ngFor="let zoom of zooms" (click)="zoomTo(zoom)" [ngClass]="{menuActive:zoom == currentZoom}">
                            <span>{{(zoom * 100).toFixed(0)}}%</span>
                            <mat-icon *ngIf="zoom == currentZoom">check</mat-icon>
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="fitAuto()">
                            <mat-icon>aspect_ratio</mat-icon>
                            Automatic fit
                        </button>
                        <button mat-menu-item (click)="fitWidth()">
                            <mat-icon style="transform: rotate(90deg);">expand</mat-icon>
                            Fit to width
                        </button>
                        <button mat-menu-item (click)="fitHeight()">
                            <mat-icon>expand</mat-icon>
                            Fit to height
                        </button>
                        <button mat-menu-item (click)="fitBest()">
                            <mat-icon>fit_screen</mat-icon>
                            Best fit
                        </button>
                        <button mat-menu-item (click)="fitBest(true)">
                            <mat-icon>open_in_full</mat-icon>
                            Cover
                        </button>
                    </mat-menu>
                </div>

                <mat-button-toggle-group [value]="fit" multiple (change)="fitChange($event)" *ngIf="false">
                    <mat-button-toggle value="height"><mat-icon>expand</mat-icon></mat-button-toggle><!--unfold_more-->
                    <mat-button-toggle value="width"><mat-icon style="transform: rotate(90deg);">expand</mat-icon></mat-button-toggle>
                    <mat-button-toggle value="best"><mat-icon>fit_screen</mat-icon></mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="pdf-main">
                <div #menu class="menu" *ngIf="showThumbnails">
                    <!--trackBy:('pageNum'|trackByProp);-->
                    <div class="thumbnail" [ngClass]="{selected:index == currentPage-1}" *ngFor="let thumbnail of thumbnails; index as index;" (click)="currentPage = index + 1">
                        <div class="thumbnail_image">
                            <div *ngIf="thumbnail.loading && !thumbnail.url" class="loop_wrapper"><mat-icon class="loop">loop</mat-icon></div>
                            <img *ngIf="thumbnail.url" [src]="thumbnail.url | safe:'resourceUrl'">
                        </div>
                        <span class="thumbnail_page">{{index + 1}}</span>
                    </div>
                </div>
                <div class="outline" *ngIf="false">
                    <ul *ngIf="outline">
                        <ng-template #recursiveList let-list>
                        <li *ngFor="let item of list">
                            {{item.title}}
                            <ul *ngIf="item.items.length > 0">
                            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.items }"></ng-container>
                            </ul>
                        </li>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: outline }"></ng-container>
                    </ul>
                </div>
                <div #view class="view">
                    <!--<canvas #canvas (mousewheel)="onScroll($event)" style="display: none;" ></canvas>-->
                    <div #pageContainer class="pages">
                        <div *ngFor="let page of pages; index as index;" class="page">
                            <div *ngIf="page.page" #pageDiv [ngStyle]="{'width.px':page.page.view[2] * 4/3 * _currentZoom, 'height.px':page.page.view[3] * 4/3 * _currentZoom}" style="position:relative; overflow: hidden; background-color:white">
                                <app-canvas *ngIf="page.render?.status == 'rendered' && page.render?.canvas" [canvas]="page.render?.canvas"></app-canvas>
                                <div class="text-layer" (mousedown)="textFix(true, $event, pageDiv)" (mouseup)="textFix(false, $event, pageDiv)"></div>
                                <div class="text-selections">
                                    <div class="rect" *ngFor="let rect of rects; index as i" [ngStyle]="{'left.px':rect.x, 'width.px':rect.width, 'top.px':rect.y, 'height.px':rect.height}">
                                        <span></span>
                                        <!--<span>{{i}}</span>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="slider" *ngIf="false">
                <mat-slider
                    class="example-margin"
                    (input)="onSlide($event)"
                    [disabled]="false"
                    [invert]="true"
                    [max]="numPages"
                    [min]="1"
                    [step]="1"
                    [thumbLabel]="true"
                    [tickInterval]="'auto'"
                    [(ngModel)]="currentPage"
                    [vertical]="true">
                </mat-slider>
            </div>
        </div>
    </ng-container>
	<div *ngIf="!this.getPath()" class="placeholder" [ngStyle]="getStyle()">
		<div class="center">
        	<mat-icon class="">picture_as_pdf</mat-icon>
			<span *ngIf="parentView != 'preview'">{{assetVO.asset.name}}</span>
		</div>
        <div *ngIf="parentView != 'preview'" class="message">no PDF file saved</div>
    </div>   
</div>
