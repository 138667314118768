import { Component, OnInit } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent extends BaseAssetComponent {

  constructor() {
    super();
	this.defaultWidth = 200;
	this.defaultHeight = 200;
  }

  ngOnInit(): void {
  }

}
