<div class="discussion flex flex-col">
    <div>
        <!--
            <h2 *ngIf="(creativeService.discussionMore$ | async)">more to load...</h2>
            <h2 *ngIf="!(creativeService.discussionMore$ | async)">no more to load...</h2>
            <h2>{{_scrolledToTop ? "stt" : "not stt"}}</h2>
            <h2>{{_scrolledToBottom ? "stb" : "not stb"}}</h2>
        -->
    </div>
    <ng-template #messageMenu let-message>
        <div class="flex message-menu icon-small" (mouseenter)="cancelClose(message)" (mouseleave)="close(message, 150)">
            <button (click)="editMessage(message)" mat-icon-button><mat-icon>edit</mat-icon></button>
            <button (click)="deleteMessage(message)" mat-icon-button><mat-icon>delete</mat-icon></button>
        </div>
      </ng-template>
    <div class="discussion-messages-wrapper relative grow flex flex-col justify-end overflow-hidden">
        <div *ngIf="!(creativeDiscussionService.loading$ | async)"
        class="discussion-messages overflow-y-auto flex flex-col items-start"
        #messageContainer (wheel)="onWheel($event)" (scroll)="onScroll($event)">
            <ng-container *ngFor="let userGrouped of messages; index as i; trackBy:trackByUUID2">
                <ng-container *ngFor="let timeGrouped of userGrouped; index as j; trackBy:trackByUUID1">
                    <div #messageRef *ngFor="let message of timeGrouped; index as k; first as first; last as last; trackBy:trackByUUID" class="message" [class.mine]="message.mine" [class.fresh]="message.fresh">
                        <div class="meta">
                            <span *ngIf="!message.mine && j == 0 && k == 0 && message.message.user" class="author">{{message.message.user.name}}</span>
                            <span *ngIf="k == 0" class="date">{{message.created}}</span>
                            <span *ngIf="message.message.user && message.message.created_at != message.message.updated_at" class="edited">*Edited</span>
                        </div>
                        <div #bubbleRef
                            class="bubble corner-piece" [class]="{first, last, middle:!(first || last)}"
                            (mouseenter)="open($event, bubbleRef, message)" (mouseleave)="close(message, 250)">                
                            <span>{{message.message.text}}</span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="loading absolute flex justify-center" *ngIf="(creativeService.discussionLoading$ | async)">
            <mat-progress-bar mode="query" ></mat-progress-bar>
        </div>
        <div class="jump absolute flex flex-col items-center justify-center" [class]="{'anim-shrink':_scrolledToBottom, 'anim-grow':!_scrolledToBottom}">    <!-- *ngIf="!_scrolledToBottom"-->
            <span *ngIf="newMessages" class="new-messages">New messages</span>
            <button mat-mini-fab (click)="scrollToBottom(true)" matTooltip="Jump to bottom">
                <mat-icon>vertical_align_bottom</mat-icon>
            </button>
        </div>
        <div class="shadows">
            <div class="shadow top absolute" [class]="{fadeOut:!(!_scrolledToTop && _scrollable), fadeIn:!_scrolledToTop && _scrollable}"></div>
            <div class="shadow bottom absolute" [class]="{fadeOut:_scrolledToBottom, fadeIn:!_scrolledToBottom}"></div>
        </div>
    </div>
    <div class="discussion-loading" *ngIf="(creativeDiscussionService.loading$ | async)">
        <mat-spinner  diameter="50"></mat-spinner>
    </div>
    <div class="discussion-controls" [formGroup]="discussionForm">
        <mat-form-field>
            <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                matInput
                placeholder="Message"
                formControlName="message"
                (input)="input($event)"
                (keydown)="keydown($event)"
                (keydown.enter)="submit($event)"
            ></textarea>
        <button
            *ngIf="false"
            mat-icon-button
            matSuffix
            color="primary"
            (click)="submit()"
            [disabled]="!canSubmit"
        >
            <mat-icon>send</mat-icon>
        </button>
        </mat-form-field>

        <button
            mat-mini-fab
            color="primary"
            (click)="submit()"
            [disabled]="!canSubmit"
    >
            <mat-icon>send</mat-icon>
        </button>
    </div>
    <div class="typers">
        <span>{{(creativeDiscussionService.typingUsersString$ | async)}}&nbsp;</span>
    </div>
</div>