import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, HostBinding, InjectionToken, Injector, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { ProjectService } from 'src/app/api/project.service';
import { Creative, ICreative, CreativeState } from 'src/app/models/creative.model';
import { Permission } from 'src/app/models/permissions.model';
import { Task } from 'src/app/models/task.model';
import { IProject } from 'src/app/models/project.model';
import { PusherService } from 'src/app/services/pusher.service';
import { AppUserService } from 'src/app/services/app-user.service';
import { arraySearch } from 'src/app/utils';
import { InfoComponent } from './info/info/info.component';
import { IWorkflow, Workflow } from 'src/app/models/workflow.model';
import { Format, IFormat } from 'src/app/models/format.model';
import { Channel, IChannel } from 'src/app/models/channel.model';
import { IProjectChannel, ProjectChannel } from 'src/app/models/projectChannel.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeliveryPackage, IDeliveryPackage } from 'src/app/models/deliveryPackage.model';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreativeService } from 'src/app/api/creative.service';
import { CreativeSelectiontService } from '../prototypes/project/creativeSelection.service';

export const INFO_DATA = new InjectionToken<{}>('INFO_DATA');

/**
 * The grid component...
 * 
 * Depends on the following data
 * Channels
 * Workflows
 * Formats
 * Creatives
 * 
 */
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

	private _unsubscribe = new Subject<boolean>();

	@Output() creativeDeleted : EventEmitter<any> =  new EventEmitter();
	@Output() add : EventEmitter<any> =  new EventEmitter();
	@Output() edit : EventEmitter<any> =  new EventEmitter();
	@Output() delete : EventEmitter<any> =  new EventEmitter();
	@Output() channelMenuChange : EventEmitter<any> =  new EventEmitter();
	@Output() packageChanged : EventEmitter<any> =  new EventEmitter();
	//@Output() selectionChanged : EventEmitter<any> =  new EventEmitter();
	@Output() openDeliveryPackage: EventEmitter<string> =  new EventEmitter();
	@Output() onResetApprovals: EventEmitter<any> = new EventEmitter();

	@Input() project : IProject;
	@Input() projectChannels : IProjectChannel[];
	@Input() workflows : IWorkflow[];
	@Input() deliveryPackages : IDeliveryPackage[];
	@Input() selectedChannel : string;
	public channels : string[];
	public availableChannels : ProjectChannel[];
	//@Input() approvalChains : ApprovalChain[];
	@Input() activeCreative : ICreative;
	@Input() selectionMode : boolean;
	@Input() packageMode : boolean;
	@Input() discussionDates: any;
	

	@ViewChild('container', { read: ViewContainerRef }) public containerRef: ViewContainerRef;
	//@ViewChild('table') public table: HTMLTableElement;
	@ViewChild('table') table;	//{static: true}
	@ViewChild('scroll') scroll:ElementRef<HTMLDivElement>;	//{static: true}
	//@ViewChild("isNewSlot") isNewSlot: ElementRef;

	@HostBinding('class.scrollable') get scrollable() {
		let el = this.scroll?.nativeElement;
		if(!el) return false;
		//return this.scroll?.nativeElement?.scrollLeft > 0;
		return (el.scrollWidth - el.scrollLeft - el.clientWidth) > 1.999;
	}

	// overlay
	public isOpen: boolean = false;
	// end overlay

	// search term to filter creatives
	public search:string;
	
	public isOpened = false;
	
	public gridData: any[]; // populates the grid
	public currentPipGroupLookup:any = [];
	public activePipGroupLookup:any = [];
	public channelWorkflowInfoLookup:any = [];
	public creativeLookup:any = {};
	public creatingLookup:any = {};	// a lookup for currently "creating" creatives
	public isMainGrid: boolean;

	public isProduction: boolean;
	public isAdmin: boolean;

	public projectChannelHasCreatives:any = {};
	private projectChannelReverseLookup:any = [];
	public selectionLookUp:any = "world";//{};
	public debug = "hello";
	workflowLookup: any = [];
	workflowLookupByWfchannel: any = [];
	workflowLookupByFormat: any = [];
	workflowLookupByFormatGroup: any = [];
	formatGroupLookupByFormat: any = [];
	workflowChannelCreativeCount: any = [];

	//public approvalTasks:Task[];

  constructor(	public dialog: MatDialog,
				public overlay: Overlay,
				private injector: Injector,
				private componentFactoryResolver: ComponentFactoryResolver,
				private apiService:ApiService,
				private appUserService:AppUserService,
				private projectService:ProjectService,
				private route:ActivatedRoute,
				private router:Router,
				private pusherService:PusherService,
				private ds:DialogService,
				private snackBar: MatSnackBar,
				private creativeService: CreativeService,
				private selectionService:CreativeSelectiontService,
				) { }

  @ViewChildren(TemplatePortal) templatePortals: QueryList<Portal<any>>;
  overlayRef: OverlayRef;

  recheckIfInMenu = false;
  overlayTimeout;
  openInfoPanel(creative:ICreative, event, graphic:HTMLElement) {
	if(graphic)	graphic.style.display = "block";

	  this.recheckIfInMenu = false;
	let config = new OverlayConfig();

	config.positionStrategy = this.getOverlayPosition(event.target);
	config.disposeOnNavigation = true;
//	config.hasBackdrop = true;
	//config.minWidth = '522px';
	//config.width = '60%';
	config.maxWidth = '512px';
	config.minWidth = '512px';
	config.maxHeight = '80vh';
	
	
	//this.overlay.position()
	 //   .global()
	  //  .centerHorizontally()
	   // .top(`${this.nextPosition}px`);

	//this.nextPosition += 30;
	// clear old overlay
	if(this.overlayRef)
	{
		this.overlayRef.dispose();
	}
	
	this.overlayRef = this.overlay.create(config);
	/*
	//this.overlay.position().global().width('522px');
	let ovSizeConfig: OverlaySizeConfig = {width:'66%',minWidth:'522px'}; 
	this.overlayRef.updateSize(ovSizeConfig);
	console.log("OVERLAY",this.overlayRef.getConfig());
	*/
	//this.overlayRef.updatePosition();
	/*this.overlayRef.updateSize({
		width: '66%',
		maxWidth: '522px'
	})*/

	// workflow
	let workflow = this.workflows.find(workflow => workflow.uuid == creative.workflow_uuid);
	//console.log("info panel workflow", workflow);
	workflow.channels = workflow.channels.sort((a, b) => a.order - b.order);

	let injection = Injector.create({
		parent: this.injector,
		providers: [
		  {
			provide: INFO_DATA,
			useValue: {
				creative,
				grid:this,
				workflow,
				delivery:this.creativeLookup ? this.creativeLookup[creative.uuid].deliveryPackages : null,
			}
			}//delivery:DeliveryPackage.getCreativeDeliveries(creative.uuid,this.deliveryPackages)
		]
	  })

	const componentPortal = new ComponentPortal(InfoComponent, null, injection, this.componentFactoryResolver);
	

	if(this.overlayTimeout)	clearTimeout(this.overlayTimeout);
	this.overlayTimeout = setTimeout(() => {
		let ref:ComponentRef<InfoComponent> = this.overlayRef.attach(componentPortal);//templatePortals.first);
		ref.instance.mouseEntered.subscribe(() => {
			this.recheckIfInMenu = true;
		});

		//TODO - this is dodgy - using component's in-built button response as a proxy delete!
		ref.instance.close.subscribe((creative:Creative) => {//creative_uuid:string
			this.closeInfoPanel(null, true);
			//if(msgObj.message && msgObj.message == InfoComponent.EVENT_CLOSE_DELETE)
			//{
				creative.deleting = true;
				this.snackBar.open("deleting creative...", '',{duration:5000}); 
				this.creativeService.delete(creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
					(response:any) => {
						this.snackBar.open("creative deleted...", '', {duration:5000});
						this.creativeDeleted.emit(response.data[0]);
						//this.close.emit(InfoComponent.EVENT_CLOSE_DELETE);
					}//,
					//error => this.errorMessage = <any>error
				);
				
				//old way
				// creative has been deleted - reload dash
				//this.creativeDeleted.emit(null);
				// how? maybe just remove that bad boy from the data and regen or bubble up..
			//}
		});
		ref.instance.openWorkflow.subscribe((id:number) => {
			this.closeInfoPanel(null, true);
			this.editWorkflow(id);
		});
		ref.instance.openDeliveryPackage.subscribe((uuid:string) => {
			this.closeInfoPanel(null, true);
			// 1. show panel if not open
			// 2. select deliveries tab if not selected
			// 3. find and select package base on uuid
			this.openDeliveryPackage.emit(uuid);
		});
		ref.instance.onResetApprovals.subscribe(() => {
			this.onResetApprovals.emit(null);
			this.closeInfoPanel(null, true);
		})
		// how best to inject content...?
		//https://stackoverflow.com/questions/47469844/angular-cdk-how-to-set-inputs-in-a-componentportal
		//ref.instance.creative = creative;
		//ref.changeDetectorRef.detectChanges();
	
		//https://stackblitz.com/edit/experimenting?file=src%2Fapp%2Fsidenav-fixed-example.ts
		// https://stackoverflow.com/questions/58674359/how-do-i-configure-a-material-cdk-overlay-position-strategy-that-works-great-bo	
	}, 500);
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
	const positionStrategy = this.overlay.position()
	  .flexibleConnectedTo(origin)
	  .withPositions(this.getPositions())
	  .withPush(true)
	  .withFlexibleDimensions(true)
	  .withViewportMargin(40)

	return positionStrategy;
  }
  // https://stackblitz.com/edit/netanel-popover?file=src%2Fapp%2Fpopover%2Fpopover.service.ts
  private getPositions(): ConnectionPositionPair[] {
	return [
	  {
		panelClass: 'infoPanelMargin',
		originX: 'center',
		originY: 'center',
		overlayX: 'start',
		overlayY: 'center',
//		offsetY: -250,
	  }
	]
  }
  
  closeInfoPanel(graphic:HTMLElement = null, now:Boolean = false) {
	if(graphic)	graphic.style.display = "none";
	if(this.overlayTimeout)	clearTimeout(this.overlayTimeout);
	if(now)
	{
		  this.overlayRef.dispose();
		}else{
			let currrentOverlay = this.overlayRef;
			setTimeout(() => {
				if (this.recheckIfInMenu === false) {
					currrentOverlay.dispose();
				}
			}, 250);
	}

  }
  mouseEnterInfo(event){
	  //console.log("mouse over info");
	  this.recheckIfInMenu = true;
  }

  public creativeUsers:any = {};
	ngOnInit(): void {

		this.isAdmin = this.isProjectAdmin();
		//this.resetSelectionLookup();
		this.generateCreatives();
		//console.log("Grid init");
		
		this.projectService.creativeUsers.pipe(takeUntil(this._unsubscribe)).subscribe(
			(result:any) => {
				//console.log("grid creative users", JSON.stringify(result));
				this.creativeUsers = result;
				//console.log("grid creative users", JSON.stringify(this.creativeUsers));
			},
		);
		this.selectionService.selection$.subscribe(selection => {
			//console.log("SELECTION CHANGE DETECTED IN PROJECT", selection);
		})
		/*
		this.projectService.getApprovalTasks(this.project.uuid).pipe().subscribe(result => {
			this.approvalTasks = result.data;
		});*/
		

	}
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	ngAfterViewInit() : void
	{
		return;
		this.table.nativeElement.addEventListener("mouseenter", this.onTableMouseEnter.bind(this));
		this.table.nativeElement.addEventListener("mouseleave", this.onTableMouseLeave.bind(this));
	}
	onScroll(e:Event)
	{
		//this.throttle(this.handleScroll, [e], 250)
		this.handleScroll(e);
	}
	handleScroll(e:Event)
	{
		// find all the sticky rows and sort them out
		let rows = this.scroll.nativeElement.querySelectorAll('.sticky-workflow');
		let offsets = [];
		// @ts-ignore
		rows.forEach(row => offsets.push(row.offsetTop - e.currentTarget.scrollTop));
		for (let i = 0; i < offsets.length-1; i++) {
			const offsetA = offsets[i];
			const offsetB = offsets[i + 1];
			if(offsetB < (41*2))
			{
				const diff = (41*2) - offsetB;
				// @ts-ignore
				rows[i].style.top = (41 - diff) + "px";
			}else{
				// @ts-ignore
				rows[i].style.top = "41px";
			}
		}

		// update more
		const fade = this.scroll.nativeElement.querySelector('.more');
		if(fade)
		{
			// @ts-ignore
			fade.style.top = e.currentTarget.scrollTop + 'px';
			// @ts-ignore
			fade.style.right = -e.currentTarget.scrollLeft + 'px';
		}
	}
	throttle(fn:Function, parans = null, delay = 250) { 
		let time = Date.now(); 
		return () => { 
			let newTime = Date.now();
			if((time + delay - newTime) <= 0) { 
				// Run the function we've passed to our throttler, and reset the `time` variable (so we can check again). 
				parans ? fn.apply(this, parans) : fn(); 
				time = newTime; 
			} 
		} 
	} 
	onTableMouseEnter(e:MouseEvent)
	{
		this.table.nativeElement.addEventListener("mouseover", this.onTableMouseOver.bind(this));
		this.table.nativeElement.addEventListener("mouseout", this.onTableMouseOut.bind(this));
	}
	onTableMouseLeave(e:MouseEvent)
	{
		this.table.nativeElement.removeEventListener("mouseover", this.onTableMouseOver.bind(this));
		this.table.nativeElement.removeEventListener("mouseout", this.onTableMouseOut.bind(this));
	}
	hoverChannelIndex:number;
	onMouseEnterCell(e:MouseEvent, creative:ICreative, index:number)
	{
		return;
		this.hoverChannelIndex = index;
		console.log("enter cell", this.channels[index]);
	}
	onMouseLeaveCell(e:MouseEvent, creative:ICreative, index:number)
	{
		return;
		this.hoverChannelIndex = -1;
		console.log("leave cell", this.channels[index]);
	}
	onTableMouseOver(e:MouseEvent)
	{
		return;
		console.log("mouseover", e)
	}
	onTableMouseOut(e:MouseEvent)
	{

	}
	ngOnChanges(changes: SimpleChanges) {
		// TODO assumes that creatives have indeed changed - could probably check
		//console.log("grid changes", changes);
		if(changes?.selectionMode){
			if(this.selectionMode == false){
				//this.resetSelectionLookup();
			}
		} else {
			this.generateCreatives();	
		}
		
		//TODO - scroll to newly created slot...maybe
		/*
		if(this.isNewSlot){
			console.log("isNEwSlot",this.isNewSlot);
			this.isNewSlot?.nativeElement.scrollIntoView({ behavior: "smooth"});
		}
		*/
	}
  public generateCreatives()
  {
	  //console.log("grid gen creatives");
	  //console.log(this.projectChannels);
	  //console.log(this.workflows);
	if(!this.workflows || !this.projectChannels) return;	// need both of these to render the grid
	/*
	let stateMap = {'new':0,
	'build':1,
	'approval':2,
	'amending':3,
	'checking':4,
	'done':5}
	for (let i = 0; i < this.creatives.length; i++) {
		const creative = this.creatives[i];
		creative.stateValue = stateMap[creative.state];
	}*/
	// generate chainMapping
	/*
	let creativeLookup = {};
	if(false)//this.approvalChains && this.creatives)
	{
		let chainFormatLookup = {};
		for (let i = 0; i < this.approvalChains.length; i++) {
			const chain = this.approvalChains[i];
			// chain progress
			for (let j = 0; j < chain.approval_groups.length; j++) {
				const approvalGroup = chain.approval_groups[j];
				// get the progress of this group from.. the users approval tasks.. hmm crapppppp I made a boo boo
				// this info is not relevent here, need the creative!
				// "sad face emoji"
			}			
			chainFormatLookup[chain.filter] = chain;
		}

		for (let i = 0; i < this.creatives.length; i++) {
			const creative = this.creatives[i];
			let chain = chainFormatLookup[creative.format.group] || chainFormatLookup["*"] || null;
			creative["chain"] = chain;
			creativeLookup[creative.uuid] = creative;
		}
	}
	*/
	/*
	if(false)//this.approvalTasks && this.creatives && this.approvalChains)
	{
		let approvalTaskLookup = {};
		for (let i = 0; i < this.approvalTasks.length; i++) {
			const task = this.approvalTasks[i]
			let data;
			if(data = approvalTaskLookup[task.creative_uuid])
			{
				data.push(task)
			}else{
				approvalTaskLookup[task.creative_uuid] = [task];
			}
		}
		for (let i = 0; i < this.creatives.length; i++) {
			const creative = this.creatives[i];
			const chain = creative["chain"];

			creative["group_approvers"] = new Array(chain.approval_groups.length);
			for (let j = 0; j < creative["group_approvers"].length; j++) {
				creative["group_approvers"][j] = 0;
			}
			const tasks = approvalTaskLookup[creative.uuid];
			if(!tasks){
				//creative["groups_approved"] = 0;
				//continue;
			}
			let groupsApproved = 0;
			let allApproved = true;
			for (let j = 0; j < chain.approval_groups.length; j++) {
				const approvalGroup = chain.approval_groups[j];
				const users = approvalGroup.users;
				if(users.length == 0)
				{
					allApproved = false;
					continue;
				}
				//creative["group_approvers"][j] = users.length;
				for (let k = 0; k < users.length; k++) {
					const user = users[k];
					if(!tasks){
						allApproved = false;
						continue;
					}
					for (let l = 0; l < tasks.length; l++) {
						const task = tasks[l];
						if(task.user_uuid == user.uuid)
						{
							creative["group_approvers"][j]++;
							if(task.state == "waiting")
							{
								//j = 999;
								//k = 999;
								//l = 999;
								//break;
								allApproved = false;
							}
						}
					}
				}
				if(allApproved && creative["group_approvers"][j] > 0) groupsApproved++;
			}
			creative["groups_approved"] = groupsApproved;
			//console.log("creative processed", creative.format.name, creative.channel.name);
			//console.log(creative["group_approvers"]);
			//console.log(creative["groups_approved"]);
		}
		*/
		/*
		for (let i = 0; i < this.approvalTasks.length; i++) {
			const task = this.approvalTasks[i]
			const creative = creativeLookup[task.creative_uuid];
			const chain = creative.chain;
			const taskUser = task.user_uuid;
			for (let j = 0; j < chain.approval_groups.length; j++) {
				const approvalGroup = chain.approval_groups[j];
				const users = approvalGroup.users
				
			}

		}
	}
	*/
	// generate channels on the flizzle from the workflows
	
	//this.channels = [];
	this.isMainGrid = !this.activeCreative;

	//sort the channels array by .order
	this.projectChannels.sort((a, b) => a.order - b.order);

	//console.log("project channels",this.projectChannels);
	if(this.activeCreative)
	{
		//console.log(this.activeCreative,this.selectedChannel);
		this.availableChannels = this.selectedChannel ? [this.projectChannels.find(channel => channel.uuid ==this.selectedChannel)] : [];
	}else {
		this.availableChannels = this.projectChannels;
	}
	//console.log("available channels",this.availableChannels);

	/*
	if(this.selectedChannel)
	{
		this.channels = [this.selectedChannel];
		this.availableChannels = [];
	}else{
		this.availableChannels = null;
	}*/
	/*
	let channelLookup = {};
	for (let i = 0; i < this.workflows.length; i++) {
		const workflow = this.workflows[i];
		const channels = workflow.channels;
		for (let j = 0; j < channels.length; j++) {
			const channel = channels[j];
			if(channelLookup[channel.name] == undefined)
			{
				channelLookup[channel.name] = true; 
				this.selectedChannel ? this.availableChannels.push(channel.name) : this.channels.push(channel.name);
			}			
		}
	}*/

	/**
	 * Grid structure is supplied in rows
	 * 1 row per workflow
	 * 1 row per group
	 * 1 row per format in group
	 * 
	 */
	this.gridData = [];
	this.workflowChannelCreativeCount = {};
	this.projectChannelHasCreatives = {};
	for (let h = 0; h < this.workflows.length; h++) {
		const workflow = this.workflows[h];
		this.workflowLookup[workflow.uuid] = workflow;
		const workflowChannels = workflow.channels;
		
		if(workflowChannels)
		{
			
			//sort the channels array by .order
			workflowChannels.sort((a, b) => a.order - b.order);
	
			//const channelLookup = {};	// channel name via id
			const projectChannelLookup = {};	// channel name via id
			for (let i = 0; i < workflowChannels.length; i++) {
				const workflowChannel = workflowChannels[i];
				this.workflowLookupByWfchannel[workflowChannel.uuid] = workflow;
				projectChannelLookup[workflowChannel.project_channel_uuid] = workflowChannel;
				this.projectChannelReverseLookup[workflowChannel.uuid] = workflowChannel.project_channel_uuid;
				//channelLookupById[channel.name] = channel;
			}
			const creatives = workflow.creatives;
			const formats = workflow.formats;
			const perChannelWorkflowInfo = [];
			for (let j = 0; j < this.availableChannels.length; j++) {
				const projectChannel = this.availableChannels[j];
				const workflowChannel = projectChannelLookup[projectChannel.uuid];
				if(workflowChannel)
				{
					let infoObj = {
						production:this.getWorkflowChannelProduction(workflow, workflowChannel),
						approval:this.getWorkflowChannelApproval(workflow, workflowChannel),
						warning:this.getWorkflowChannelWarning(workflow, workflowChannel),
						workflowChannel:workflowChannel,
					}
					perChannelWorkflowInfo.push(infoObj);
					//this.channelWorkflowInfoLookup[projectChannel.id] = infoObj; //TODO for warning symbol on cell see line 488
				}else{	
					perChannelWorkflowInfo.push(null);
				}
			}
			var wfData = {type:'workflow', workflow, perChannelWorkflowInfo, empty:true};
			this.gridData.push(wfData);
			for (let i = 0; i < formats.length; i++) {
				const format = formats[i];
				this.workflowLookupByFormat[format.uuid] = workflow;
				this.formatGroupLookupByFormat[format.uuid] = format.group;
				if(i == 0 || format.group != formats[i-1].group)
				{
					var group = {type:'group', format, workflow, empty:true};
					this.gridData.push(group);
				}
				const row = {type:'format', workflow, format, creatives:[], channels:[], empty:true};
				for (let j = 0; j < this.availableChannels.length; j++) {
					const projectChannel = this.availableChannels[j];
					const workflowChannel = projectChannelLookup[projectChannel.uuid];
					if(!workflowChannel)
					{
						row.creatives.push(null);
						row.channels.push(null);
						continue;
					}
					row.channels.push(workflowChannel);
					//const creatives = format.creatives;
					let found = false;
					if(!this.workflowChannelCreativeCount[workflowChannel.uuid]) this.workflowChannelCreativeCount[workflowChannel.uuid] = 0;
	
					for (let k = 0; k < creatives?.length; k++) {
						const creative = creatives[k];
	
						// remove from creating cache..
						if(this.creatingLookup[format.uuid + "_"+ creative.channel_uuid])
						{
							delete this.creatingLookup[format.uuid + "_"+ creative.channel_uuid];
						}
	
						if(creative.format_uuid == format.uuid && creative.channel_uuid == workflowChannel.uuid)
						{
							found = true;
							this.workflowChannelCreativeCount[workflowChannel.uuid] += 1;
							//TODO - trying to find a way to add meaningful warnings to individual grid cells using perChannelWorkflowInfo.warning - depends on type of warnign and creative status 
							let creativeChannel = workflow.channels.find(channel => channel.uuid == creative.channel_uuid); // TODO replace find with lookup
							let projectChannelId = creativeChannel?.project_channel_uuid || 0;
							let index = this.availableChannels.findIndex(channel => channel.id == projectChannelId);
							if (index != -1 && perChannelWorkflowInfo[index] && perChannelWorkflowInfo[index].warning) 
							{
								let warningStr = perChannelWorkflowInfo[index].warning.toLowerCase();
								//filter on creative state
								if((creative.state == CreativeState.NEW || creative.state == CreativeState.BUILD || creative.state == CreativeState.AMENDING) && perChannelWorkflowInfo[index].production == 0)
								{
									creative['warning'] = true;
								}
								/*this situation is handled by the main state 'check()' with craetive.state = 'warning' 
								if((creative.state == 'approval') && perChannelWorkflowInfo[index].approvers == 0)
								{
									creative['warning'] = true;
								}*/
								if((creative.state == CreativeState.QUALIFYING || creative.state == CreativeState.CONFIRMING) && warningStr.indexOf('moderator') != -1)
								{
									creative['warning'] = true;
								}
								//then per state search the warning string to decide to add a warnign flag to the creatuve if the correct state/text is present
								//creative['warning'] = true;				
							}
							
							//let infoObj = this.channelWorkflowInfoLookup[creative.channel_id];
							//if(infoObj && infoObj.warning) creative['warning'] = true;
						
							row.creatives.push(creative);
							if(row.empty) row.empty = false;
							if(group?.empty) group.empty = false;
							if(wfData?.empty) wfData.empty = false;
							this.projectChannelHasCreatives[projectChannel.uuid] = true;
							//break;
	
							/*NEW - determine creative's current group ========*/
	
							/*
							let currentPip = null;//this.currentPipGroupLookup[creative.uuid] = null;
							for (let g = 0; g < creative.groupInfo.groups.length; g++) {
								const info = creative.groupInfo.groups[g];
								if(this.canShowPips(creative.state))
								{
									if(!currentPip && (info.total==0 || (info.approved < info.total))){
										currentPip = g;
										break;
									} 
								}
							}*/
							this.currentPipGroupLookup[creative.uuid] = this.canShowPips(creative.state) && creative.groupInfo.num ? creative.groupInfo.groups[creative.groupInfo.current] : null;
	
							/* ================================================ */
	
							/*NEW - determine creative's ACTIVE groups ========*/
							let numToApprove = 0;
							this.activePipGroupLookup[creative.uuid] = [];
							for (let g = 0; g < creative.groupInfo.groups.length; g++) {
								const info = creative.groupInfo.groups[g];
								if(this.canShowPips(creative.state))
								{
									//if((g==0 && info.total==0) || (g>=1 && creative.groupInfo.groups[(g-1)].approved==creative.groupInfo.groups[(g-1)].total && info.total==0) || (info.approved < info.total)){
									if((g==0 && info.total==0) || info.approval>0 || info.submitted>0 || info.checking>0){
										this.activePipGroupLookup[creative.uuid][g] = true;
										numToApprove += info.approval;								
									} 
								}
							}
	
							if(creative.state == CreativeState.APPROVAL || creative.state == CreativeState.WARNING)
							{
								//overwrite the meta to account for all active groups
								// TODO FIXME why?
								//creative.meta = numToApprove;
							}
	
							if(creative.state == CreativeState.NEW)
								{
									//if a slot is < 10 seconds old, mark it as new for hiliting on grid
									let a = new Date(creative.created_at);
									let b = new Date();
									const utca = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes(), a.getSeconds());
									  const utcb = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds());
									let t_diff = (utcb - utca)/1000;
									if(t_diff < 10) creative['isNew'] = true;
								} 
	
							/* ================================================ */
	
						}
					}
					if(!found) row.creatives.push({});
				}
				// hide empty rows from non admins
				if(this.isProjectAdmin() || !row.empty || this.appUserService?.appUser.super)
				this.gridData.push(row);
				/*if(group){
					this.gridData.push(group);
					group = null;
				}*/
			}
		}
	}

	//console.log("GRID DATA", this.gridData);
	this.isProduction = false;
	this.creativeLookup = {};
	let userUuid = this.appUserService.appUser.uuid;
	this.workflows.forEach(workflow => {
		workflow.creatives?.forEach(creative => {
			this.creativeLookup[creative.uuid] = {creative, deliveryPackages:[]};
		});


		workflow.channels?.forEach(channel => {
			//let channel = this.workflow.channels.find(channel => channel.uuid == this.creative.channel_uuid);
			let user = channel['users'].find(user => user.uuid == userUuid);			
			if(!this.isProduction) this.isProduction = (user && (user["scoped_permissions"] & Permission.PRODUCTION) != 0) || false;
			//this.isApprover = (user && (user["scoped_permissions"] & Permission.APPROVAL) != 0);
		})
	});
	//console.log("IS PRODUCTION",this.isProduction);

	// TODO could only recompute grid on certain changes...
	if(!this.deliveryPackages) return;
	// computed delivery info
	// 1. iterate creatives to generate uuid lookup: 
	// 2. iterate delivery_packages to create a status for the delivery package
	// 3. loop through delivery_packages (could be at end of previous loop) and then injet the result into a new lookup against each creative_uuid
	// 4. for each creative loop through this info and compute a delivery "state"
	
	// 1
	//let creativeLookup = {};
	
	// 2.
	// THIS is the info about individual delivery packages
	let deliveryPackageLookup = {};
	this.deliveryPackages.forEach(deliveryPackage => {
		let vo = {uuid:deliveryPackage.uuid, name:deliveryPackage.name, actioned:deliveryPackage.actioned, total:deliveryPackage.delivery_items.length, count:0, package:deliveryPackage};
		deliveryPackageLookup[deliveryPackage.uuid] = vo;
		deliveryPackage.delivery_items.forEach(deliveryItem => {
			let creativeVO = this.creativeLookup[deliveryItem.creative_uuid];
			if(creativeVO){
				let creative = creativeVO.creative;
				if(creative.state == CreativeState.DONE)
					vo.count++;
				// when in Rome
				creativeVO.deliveryPackages.push(vo);
			}
		});
	});
	// This is from the creative's perspective where it needs to look at ALL the delivery packages it is in
	for (const uuid in this.creativeLookup) {
		const creativeVO = this.creativeLookup[uuid];
		let numPackages = creativeVO.deliveryPackages.length;
		let deliverableCount = 0;
		let actionedCount = 0;
		let noCreatives = true;
		creativeVO.deliveryPackages.forEach(deliveryPackageVO => {
			let count = deliveryPackageVO.count;
			let total = deliveryPackageVO.total;
			let actioned = deliveryPackageVO.actioned;
			if(noCreatives && count > 0) noCreatives = false;
			if(count > 0 && count == total) deliverableCount++;
			if(actioned) actionedCount++;
		});
		/*	 states:
			none									| nes pas dans le package 
			grey outline:		none-noneActive		|	In at least 1 package 		- none deliverable 				- none manually set to delivered
			amber outline:		some-noneActive		|	In at least 1 package 		- at least 1 deliverable  		- none manually set to delivered
			green outline:		all-noneActive		|	In at least 1 package 		- all  deliverable 				- none manually set to delivered
			red filled: 		blocked-someActive	|	In at least 1 package 		- at least 1 not deliverable 	- at least 1 manually set to delivered
			amber filled:		all-someActive		|	In at least 1 package 		- all  deliverable 				- at least 1 manually set to delivered
			green filled:		all-allActive		|	In at least 1 package 		- all  deliverable 				- ALL manually set to delivered 
	*/
		let creativeDeliveryStatus = DeliveryPackage.getCreativeStatus(numPackages, deliverableCount, actionedCount);
		creativeVO.creative.deliveryStatus = {className: creativeDeliveryStatus.className, description: creativeDeliveryStatus.description};

		/*let className;
		let description;
		if(!numPackages)
		{
			// in no packages - none
			className = 'none';
		}else if(numPackages == deliverableCount && numPackages == actionedCount) {
			// green filled: In at least 1 package - all  deliverable - ALL manually set to delivered
			className = 'allDeliverable allActive';
			description = [deliverableCount+' package'+(deliverableCount==1?'':'s')+' ready for delivery.',deliverableCount+' package'+(deliverableCount==1?'':'s')+' have been activated for download.'];
		}else if(numPackages == deliverableCount && actionedCount) {
			// amber filled: In at least 1 package - all  deliverable - at least 1 manually set to delivered 
			className = 'allDeliverable someActive';
			description = [deliverableCount+' package'+(deliverableCount==1?'':'s')+' ready for delivery.', (numPackages-actionedCount)+' waiting to be activated (for download).'];
		}else if(numPackages != deliverableCount && actionedCount) {
			// red filled: In at least 1 package - at least 1 not deliverable - at least 1 manually set to delivered 
			className = 'blocked someActive';
			description = [(numPackages-deliverableCount)+' package'+(numPackages-deliverableCount==1?'':'s')+' waiting for content approval.','DOWNLOADS BLOCKED while package content awaits approval.'];
		}else if(numPackages == deliverableCount && actionedCount == 0) {
			//green outline: In at least 1 package - all  deliverable - none manually set to delivered 
			className = 'allDeliverable noneActive';
			description = [deliverableCount+' package'+(deliverableCount==1?'':'s')+' ready for delivery.', numPackages+' waiting to be activated (for download).'];
		}else if(deliverableCount > 0 && numPackages != deliverableCount && actionedCount == 0) {
			// amber outline: In at least 1 package - at least 1 deliverable  - none manually set to delivered
			className = 'someDeliverable noneActive';
			description = [(numPackages-deliverableCount)+' package'+(numPackages-deliverableCount==1?'':'s')+' waiting for content approval.', numPackages+' waiting to be activated (for download).'];
		}else if(deliverableCount == 0 && actionedCount == 0) {
			// grey outline: In at least 1 package - none deliverable - none manually set to delivered
			className = 'noneDeliverable noneActive';
			description = [numPackages+' package'+(numPackages==1?'':'s')+' waiting for content approvals.', numPackages+' waiting to be activated (for download).'];
		}
		
		
		creativeVO.creative.deliveryStatus = {className,description};
		*/
	}

	//console.log("generateCraetives projectChannelLookup",projectChannelLookup);
	return;
	
	// first filter our creatives based on any search
	// TODO possibly cache filter for searches that are sequential


	// we need an array of creatives per format that line up with each channel
	/*
	this.gridData = [];
	for (let i = 0; i < this.formats.length; i++) {
		const format = this.formats[i];
		const row = {format:format, creatives:[], empty:true};
		for (let j = 0; j < this.channels.length; j++) {
			const channel = this.channels[j];
			let found = false;
			for (let k = 0; k < filteredCreatives.length; k++) {
				const creative = filteredCreatives[k];
				if(creative.format_uuid == format.uuid && creative.channel_id == channel.id)
				{
					found = true;
					if(row.empty) row.empty = false;
					row.creatives.push(creative);
				}
			}
			if(!found) row.creatives.push(null);
		}
		// hide empty rows from non admins
		if(this.isProjectAdmin() || !row.empty)
			this.gridData.push(row);
	}
	// sort in group order - TODO sort alphabetical within groups
	this.gridData = this.gridData.sort((a, b) => {
		if(a.format.group > b.format.group) return 1;
		else if(a.format.group < b.format.group) return -1;
		return a.format.name > b.format.name ? 1 : -1
	}
	);*/
  }
  // TODO send this to hell at some point
  counter(i: number) {
		return new Array(i);
	}
	getWarning(warnings:any[])
	{
		let warningMessage = '';
		for (let i = 0; i < warnings.length; i++) {
			if(warningMessage.length) warningMessage += "\n";
			const warning = warnings[i];
			switch (warning.type) {
				case 'approver':
					let approverNamePrefix = (warning.user?.uuid == this.appUserService.appUser.uuid) ? 'You need' :`${warning.user?.name} needs`;
					warningMessage += `${approverNamePrefix} to submit ${warning.value} amends`;
					break;
				case 'moderator':
					warningMessage += "A moderator needs to send amend responses!";
					break;
				case 'production':
					warningMessage += `A production user needs to submit ${warning.value} amends`;
					break;
				default:
					break;
			}
			
		}
		return warningMessage;
	}
  newCreative()
  {
	// open popup
	this.add.emit({type:"creative"});	
  }
  addCreative(workflow:Workflow, format:Format, channel:Channel)
  {
	//console.log("adding creative",workflow, format, channel);
	
	if(format && channel)
	{
		this.creatingLookup[format.uuid + "_"+ channel.uuid] = true;
	}
	this.add.emit({type:"creative", workflow, format, channel});
  }
  addWorkflow()
  {
	this.add.emit({type:"workflow"});	
  }
  addChannel()
  {
	this.add.emit({type:"project_channel"});	
  }
  addChannelToWorkflow(workflow:IWorkflow, projectChannel:IProjectChannel)
  {
	//console.log("add a project channel to a workflow", workflow.id, projectChannel.name);
	this.add.emit({type:"channel", projectChannel, workflows:[workflow]});
  }
  addFormat(workflow:IWorkflow = null, format:IFormat = null)
  {
	let group:string = null;
	if(format){
		group = format.group;
		workflow = this.workflows.find(workflow => workflow.uuid == format.workflow_uuid)
	}
	this.add.emit({type:"format", workflow, group});	
  }
  fillFormat(workflow:IWorkflow = null, format:IFormat = null)
  {
	this.add.emit({type:"fillformat", workflow, format});	
  }
  fillChannel(workflow:IWorkflow = null, workflowChannel)
  {
		this.add.emit({type:"fillChannel", workflow, channel:workflowChannel});	
  }

  getDiscClass(creative, index)
  {
	if(creative.stateValue >= index ) return ['prog-active', 'state-'+creative.state];
	else return null;
  }
  getIcon(state)
  {
	return Creative.getIcon(state);
	/*
	//this is duplicated in creative.component.ts
	switch (state) {
		case "new":
			return "highlight_alt";//"edit";
		case "build":
			return "settings";
		case "approval":
		case "reviewing":
			return "visibility";
		case "qualifying":
			return "policy";//"manage_search";
		case "amending":
			return "build";
		case "confirming":
			return "gpp_good";//"playlist_add_check";
		case "checking":
			return "search"; //"flaky"
		case "done":
		case "approved":
			return "check_circle";
		case "delivered":
			return "send";
		case "warning":
			return "warning";
		case "queried"://approver override
			return "question_mark";//"contact_support";
		case "submitted"://approver override
			return "feedback";		
		case "reviewed":
			return "done";		
		default:
			break;
	}
	*/
  }
  getCreativeClass(creative, i, channel:IChannel)
  {
	  let style = {'fao':this.needsAttention(creative, i, channel), 'admin':this.isAdmin};
	  style['state-'+(creative.warning ? 'warning' : creative.state)] = true;
	  style['isNew'] = creative['isNew'];
	  return style;
	}
  needsAttention(creative, index, channel:IChannel)
  {
	  // TODO this sucks
		const workflow = this.workflows.find(workflow => workflow.uuid == creative.workflow_uuid);
		if(!workflow["userChannels"])  return false;

		let user = this.appUserService.appUser;

	  //let channel = this.channels[index];
	  let userChannels = workflow["userChannels"].find(u => u.uuid == user.uuid);


	  if(this.isProjectAdmin() && creative.warning) return true;

	  /*added to APPROVAL flow below
	  if(creative.state == CreativeState.QUERIED)//"queried"
	  {
		return true;
	  }
	  */

	  if(creative.viewonly) return true;

	  //let channelPerms = 0;//userChannels ? userChannels.channels[channel.id] : 0;
	  if(creative.state == CreativeState.NEW || creative.state == CreativeState.BUILD || creative.state == CreativeState.AMENDING)//"new" || "build" || "amending"
	  {
		//if(Permission.hasPermission(channelPerms, Permission.PRODUCTION))
		if(workflow['productionChannels'][user.uuid]?.find(channel_id => channel_id == channel.uuid))
		{
			if(creative.production_task.user_uuid == user.uuid || !creative.production_task.user_uuid || creative.production_task.user_uuid == 'app') return true;
		}
	  }else if(creative.state == CreativeState.APPROVAL || creative.state == CreativeState.QUERIED)//"approval")
	  {
		//if(Permission.hasPermission(channelPerms, Permission.APPROVAL))
		if(workflow['approvalChannels'][user.uuid]?.[channel.uuid])
		{
			// TODO should the approval task always be there???
			if(creative.approval_task?.user_uuid == user.uuid)
			{
				if(creative.approval_task.state != "approval") return false;
				
				//TODO decide if we should actually turn off FAO if the creative's current group is not the same as the users group (as below)
				//this means it has gone past this users' approval group, probably due to their group being set to 'anyone' (can approve) and it already been approved by another user
				return creative.groupInfo.current == creative.groupInfo.myGroup;

				return true;
			}
			

			//if creative's curent approvl group != users's group

			//if current approval group is anyone and group is done retrun false 
		}
		// TODO validtator/ validatee distinction...
		// TODO admin view?
	  }else if(creative.state == CreativeState.QUALIFYING)//"qualifying"
	  {
		  /*
		  let chains = this.project["approvalChains"];
		  for(var i = 0; i < chains?.length; i++)
		  {
			  let chain = chains[i];
			  if(chain.filter == creative.format.group)
			  {
				  if(Permission.hasPermission(chain.scoped_permissions, Permission.VALIDATOR)) return true;
			  }
		  }*/
		  // old way
		  /*
		  for(var i = 0; i < this.workflows?.length; i++)
		  {
			  let workflow = this.workflows[i];
			  if(workflow.id == creative.workflow_id)
			  {
				  if(Permission.hasPermission(workflow["scoped_permissions"], Permission.VALIDATOR)) return true;
			  }
		  }*/
		  let workflowUser = workflow['users'].find(u => u.uuid == user.uuid);
		  if(Permission.hasPermission(workflowUser?.scoped_permissions, Permission.VALIDATOR)) return true;
		
		// TODO validtator/ validatee distinction...
		// TODO admin view?
	  } else if(creative.state == CreativeState.AWAITING_APPROVERS || creative.state == CreativeState.DONE)
	  {
		return this.isProjectAdmin(); 	
	  }
/*
	  let channel = this.channels[index];
	  if(creative.state == "new" || creative.state == "build" || creative.state == "amending")
	  {
		if(Permission.hasPermission(channel.permissions, Permission.PRODUCTION))
		{
			if(creative.production_task.user_uuid == user.uuid || !creative.production_task.user_uuid) return true;
		}
	  }else if(creative.state == "approval" || creative.state == "checking")
	  {
		if(Permission.hasPermission(channel.permissions, Permission.APPROVAL))
		{
			// TODO should the approval task always be there???
			if(creative.approval_task?.user_uuid == user.uuid)
			{
				if(!(creative.approval_task.state == "checking" || creative.approval_task.state == "approval")) return false;
				return true;
			}
		}
		// TODO validtator/ validatee distinction...
		// TODO admin view?
	  }else if(creative.state == "qualifying" || creative.state == "confirming")
	  {
		  let chains = this.project["approvalChains"];
		  for(var i = 0; i < chains?.length; i++)
		  {
			  let chain = chains[i];
			  if(chain.filter == creative.format.group)
			  {
				  if(Permission.hasPermission(chain.scoped_permissions, Permission.VALIDATOR)) return true;
			  }
		  }
		
		// TODO validtator/ validatee distinction...
		// TODO admin view?
	  }*/
	  return false;
  }
  getWorkflowChannelWarning(workflow:IWorkflow, channel:IChannel)
  {
		let countProduction = this.getWorkflowChannelProduction(workflow, channel).length;
		let countApprovers = this.getWorkflowChannelApproval(workflow, channel).length;
		let warning = null;
		if(!countProduction)
		{
			warning = 'No Production assigned to channel';
		}
		if(!countApprovers)
		{
			//if(!warning) warning = '\n';
			warning = (!warning) ? '' : warning+'\n';
			warning += 'No Approver assigned to channel';
		}
		/* think this is bogus... countApprovers() already checks if no approver in channel, so why check again in else{} ?
		else {
			// get groups and get users and ensure each group has at least one!
			let numGroups = workflow.approval_groups.length;
			let approvers = [];
			let approverLookup = [];
			for(var i = 0; i < channel['users'].length; i++)
			{
				const user = channel['users'][i];
				if(user.scoped_permissions & Permission.APPROVAL)
				{
					approvers.push(user);
					approverLookup[user.uuid] = user;
				}
			}
			let count = 0;
			let found = workflow.approval_groups.every(group =>{
				return group.users.find(user => approverLookup[user.uuid]);
			})
			for (let i = 0; i < approvers.length; i++) {
				const user = approvers[i];
				if(user.scoped_data == count + 1)
					count++;				
			}
			if(!found)//count != numGroups)
			{
				if(!warning) warning = '';
				warning += '\nApprover missing from group!';
			}
			
			
		}
		*/
		
		// TODO missing moderator if required
		let foundModeratedGroup = workflow.approval_groups.find(group =>{
			if(group.use_moderator) return true;
		})

		if(foundModeratedGroup)
			{
				let flag = 0;
				for (let i = 0; i < workflow['users'].length; i++) {
					const user = workflow['users'][i];
					if(user.scoped_permissions & Permission.VALIDATOR)
						flag |= user.scoped_permissions;
				}
				if((flag & Permission.VALIDATOR) == 0)
				{
					//if(!warning) warning = '\n';
					warning = (!warning) ? '' : warning+'\n';
					warning += 'Moderator missing from workflow!';
				}
			}
		
		return warning;
  }
  getWorkflowChannelProduction(workflow:IWorkflow, channel:IChannel)
  {
	  return channel['users'].filter(user => user.scoped_permissions & Permission.PRODUCTION);//.length;
	  let count = 0;
	  /*
	  for(var i = 0; i < workflow['users_from_secondary'].length; i++)
	  {
		  let item = workflow['users_from_secondary'][i];
		  if(item.scope_id == channel.id && item.scoped_permissions & Permission.PRODUCTION)
		  count++;
		}*/
		return count;
	}
	getWorkflowChannelApproval(workflow:IWorkflow, channel:IChannel)
	{
	  	return channel['users'].filter(user => user.scoped_permissions & Permission.APPROVAL);//.length;
		let count = 0;
		for(var i = 0; i < workflow['users_from_secondary'].length; i++)
		{
			let item = workflow['users_from_secondary'][i];
			if(item.scope_id == channel.id && item.scoped_permissions & Permission.APPROVAL)
				count++;
		}
		return count;
  }
  getWorkflowChannelUsers(workflow:IWorkflow, channel:IChannel)
  {
	  let prod = [];
	  let approver = [];
	  for(var i = 0; i < workflow['users_from_secondary'].length; i++)
	  {
		  let item = workflow['users_from_secondary'][i];
		  if(item.scope_id == channel.id && item.scoped_permissions & Permission.PRODUCTION)
		  {
			let initials = item.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
			prod.push(initials);
		}
		if(item.scope_id == channel.id && item.scoped_permissions & Permission.APPROVAL)
		{
			let initials = item.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
			approver.push(initials);
		  }
	  }
	  return JSON.stringify({prod, approver});
  }
  // could pass workflow in
  creativeClicked(creative:ICreative,e)
  {
		e.preventDefault();
		e.stopImmediatePropagation();
		if(!this.selectionMode && !this.packageMode){
			//normal grid mode click-thru to creative
			// TODO this sucks
			let user = this.appUserService.appUser;
			//if(user && user.user) user = user.user;

			let workflow = this.workflows.find(workflow => workflow.uuid == creative.workflow_uuid);
			/*
			let channelPermissions = workflow['userChannels'].find(u => u.uuid == user.uuid).channels;
			let currentChannelPermissions = channelPermissions[creative.channel_id] || 0;
			let isProduction = Permission.hasPermission(currentChannelPermissions, Permission.PRODUCTION);	//creative.channel.permissions
			//let isAdmin = this.appUserService.isAdmin();*/
			let isSuper = this.appUserService?.appUser.super;
			let isProjectAdmin = this.isProjectAdmin();
			let isProduction = workflow['productionChannels'][user.uuid]?.find(channel_id => channel_id == creative.channel_uuid) != undefined;
			let isProductionState = (creative.state == CreativeState.NEW || creative.state == CreativeState.BUILD || creative.state == CreativeState.AMENDING);
			if(!isProjectAdmin && !isProduction && !isSuper && (!creative.viewonly || (creative.viewonly && isProductionState)) )
			{
				// depending on user role in project and state of creative they can navigate into it
				let approvalTask:Task = creative["approval_task"];
				//console.log("approvalTask", approvalTask);
				if(approvalTask)
				{
					// if the creative has an approval_task attached I am an approver, so I can click this if the state of that task is acceptable
					if(approvalTask.state == "waiting")
					{
						this.ds.openAlert({
							title:"Not quite ready...",
							message:"This creative is still being crafted. The grid icon will be labelled as 'reviewing' when it's ready for you to take a look. ",
							dismiss_button_label: "OK",
							dismissAction: () => {
								//console.log("dismissAction");					
							},
							afterClosed: () => {
								//console.log("afterClosed");						
							}
						})
						return;
					}
				}else { 
					return;
				}
			}

			///prototypes/creative/
			this.router.navigate([`creative/${creative.uuid}`]);
		} else {
			this.selectionChange('creative', creative.uuid);
		}


		
  }
  isProjectAdmin()
  {
	  if(!this.project) return false;
	  return (this.project["scoped_permissions"] & Permission.ADMIN) != 0;
  }
  
  editProjectChannel(projectChannel:IProjectChannel)
  {
	this.edit.emit({type:"project_channel", data:projectChannel});
  }
  deleteProjectChannel(projectChannel:IProjectChannel)
  {
	this.delete.emit({type:"project_channel", data:projectChannel});
  }
  deleteWorkflowChannel(workflow:IWorkflow, workflowChannel){
	this.delete.emit({type:"channel", workflow, workflowChannel});
  }
  editFormat(format:IFormat)
  {
	  this.edit.emit({type:"format", data:format});
  }
  deleteFormat(format:IFormat)
  {
	  this.delete.emit({type:"format", data:format});
  }
  editWorkflow(workflowId:number)
  {
	  this.edit.emit({type:"workflow", data:workflowId});
  }
  deleteWorkflow(workflowId:number)
  {
	  this.delete.emit({type:"workflow", data:workflowId});
  }
  editGroupName(format:IFormat)
  {
	  this.edit.emit({type:"group", data:format});
  }
  deleteGroup(format:IFormat)
  {
	  this.delete.emit({type:"group", data:format});
  }
  // This is duplated - make it static or something
  getFlag(flag:number)
  {
	if(!flag) return "block";
	switch (flag) {
	case 1:
		return "warning";
		break;
	case 2:
		return "contact_support";
		break;
	default:
		break;
	}
  }
  getDeliveryIconClass(creative:Creative)
  {
	let packages = DeliveryPackage.getCreativeDeliveries(creative.uuid,this.deliveryPackages);
	let numPackages = packages.length;
	let deliveredPackages = packages.filter(deliveryPackage => deliveryPackage.actioned).length;
	if(!numPackages) return null;
	else if (numPackages == deliveredPackages) return 'delivered_all';
	else if (deliveredPackages > 0) return 'delivered_partial';
	return 'delivered_none';
  }
  
  public channelMenuClick(channel:IProjectChannel)
  {
	  this.channelMenuChange.emit(channel.uuid);
  }

  canShowPips(creativeState){
	  switch(creativeState){
		case 'approval':
		case 'reviewing':
		case 'qualifying':
		case 'amending':
		//case 'confirming':
		//case 'checking':
		case 'warning':	
			return true;					  
	  }
	  return false;
  }

	isCurrentPipGroup(index,creative){
		return this.currentPipGroupLookup[creative.uuid] == index;
		/*
		let creativeid = creative.uuid;
		let creativeName = creative.name;
		let pipNum = this.currentPipGroupLookup[creative.uuid];
		//if(!pipNum) this.currentPipGroupLookup[creative.uuid] = index+1;

			//if(!currentGrp && (grp.users.length == 0 || grp.users[0].state != 'waiting')) currentGrp = grp;
			//if ( (!pipNum || index < pipNum) && (info.total > 0 && info.users[0].state !='waiting' && info.total != info.approved) )//|| (pipNum && index < pipNum)
			if(!pipNum){
				if ( (index < pipNum) && ((index == 0 && info.total == 0) || (info.total > 0 && info.users[0].state !='waiting' && info.total != info.approved)) )//|| (pipNum && index < pipNum)	
				{
					this.currentPipGroupLookup[creative.uuid] = index;
					return true;
				}
			}
			return false;
		
		//console.log ("isCurrentPipGroup");
		*/
  	}

	//private _creativeSelection = new Subject<any>();
	//creativeSelection$ = this._creativeSelection.asObservable();

	selectionChange(type, uuid)//event:MatCheckboxChange, 
	{
		this.selectionService.selectionChange(type, uuid);
		return;

		//this.selectionChanged.emit(null);
	}


	trackByUUID(index: number, object:any)
	{
	  return object?.uuid;
	}

}
