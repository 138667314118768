import { HttpClient, HttpParams } from '@angular/common/http';//HttpHeaders,
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Globals } from '../global';
import { wrapper } from '../utils/wrapper';

let API_URL = Globals.BASE_API_URL;

@Injectable({
	providedIn: 'root'
})

export abstract class Crud2Service<TypeIn, TypeOut, ID>{

	private posts = {};
	private _postsSubject = new BehaviorSubject<any>(this.posts);
	public posts$ = this._postsSubject.asObservable();
	private deletes = {};
	private _deletesSubject = new BehaviorSubject<any>(this.deletes);
	public deletes$ = this._deletesSubject.asObservable();

	constructor(protected http: HttpClient, protected base: string){
		this.base = API_URL + this.base.replace(/\/+$/, "");	// trim any trailing slashes
	}
	findAll(params:any = null):Observable<TypeOut>{
		return this._get(this.base, params ? {params} : null)
	}	
	findOne(id:ID, params:any = null):Observable<TypeOut>{
		return this._get(`${this.base}/${id}`, params)
	}
	create(t:TypeIn, parent:string = null):Observable<TypeOut>{
		if(parent)
		{
			return this._post(`${this.base}/${parent}`, t);
		}else{
			return this._post(this.base, t);
		}
	}
	index(parent:string = null, params:any = null):Observable<TypeOut>{
		if(parent)
		{
			return this._get(`${this.base}/${parent}`, params ? {params} : null);
		}else{
			return this._get(this.base, params ? {params} : null);
		}
	}
	update(id:string, t:TypeIn):Observable<TypeOut>{
		return this.http.put<TypeOut>(`${this.base}/${id}`, t);
	}
	delete(id:string, params:HttpParams = null):Observable<TypeOut>{
		return this._delete(`${this.base}/${id}`, {params});
	}
	
	protected _get<TypeOutOverride = TypeOut>(path:string, params:any = null):Observable<TypeOut | TypeOutOverride>
	{
		return this.http.get<TypeOut>(path, {params});
	}
	protected _post<TypeInOverride>(path:string, t:TypeIn | TypeInOverride = null, params:HttpParams = null):Observable<TypeOut>	//:Observable<OUT>
	{
		
		let options = {params};
		if(t instanceof FormData){
			// httpHeaders are immutable (https://www.tektutorialshub.com/angular/angular-httpheaders/)
			/*
			options["headers"] = new HttpHeaders()
				.set('Accept', 'multipart/form-data')
				.set('content-type', undefined)
				.set('enctype', 'multipart/form-data');*/
				//options["responseType"] = 'text';	// https://javascript.plainenglish.io/making-more-complex-requests-with-the-angular-http-client-2ff621b5c61
		}
		let postVO = {path, t, params};
		return this.http.post<TypeOut>(path, t).pipe(wrapper(() => {
			// start
			this.posts[path] ? this.posts[path].push(postVO) : this.posts[path] = [postVO];
			this._postsSubject.next(this.posts);
		}, null,
		() => {
			// complete
			let posts = this.posts[path];
			if(posts){
				let index = posts.indexOf(postVO);
				if(index != -1) posts.splice(index, 1);
			}
			if(!posts.length) delete this.posts[path];
			this._postsSubject.next(this.posts);
		},() => {
			// error
			let posts = this.posts[path];
			if(posts){
				let index = posts.indexOf(postVO);
				if(index != -1) posts.splice(index, 1);
			}
			if(!posts.length) delete this.posts[path];
			this._postsSubject.next(this.posts);
		}));	// this.http.post<OUT>(path, t);
	}
	protected _put<TypeInOverride>(path:string, t:TypeIn | TypeInOverride = null, params:any = null):Observable<TypeOut>
	{
		return this.http.put<TypeOut>(path, t, {params});
	}
	protected _delete(path:string, params:any = null):Observable<TypeOut>
	{
		const deleteVO = {path, params};
		return this.http.delete<TypeOut>(path, {params}).pipe(wrapper(() => {
			// start
			this.deletes[path] ? this.deletes[path].push(deleteVO) : this.deletes[path] = [deleteVO];
			this._deletesSubject.next(this.deletes);
		}, null,
		() => {
			// complete
			let deletes = this.deletes[path];
			if(deletes){
				let index = deletes.indexOf(deleteVO);
				if(index != -1) deletes.splice(index, 1);
			}
			if(!deletes.length) delete this.deletes[path];
			this._deletesSubject.next(this.deletes);
		},() => {
			// error
			let deletes = this.deletes[path];
			if(deletes){
				let index = deletes.indexOf(deleteVO);
				if(index != -1) deletes.splice(index, 1);
			}
			if(!deletes.length) delete this.deletes[path];
			this._deletesSubject.next(this.deletes);
		}));
	}
}