import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'callback',
    pure: false
})
export class CallbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, ...params: any[]) => boolean, ...params: any[]): any {
        if (!items || !callback) {
            return items;
        }   
        return items.filter(item => {
            if(params.length)
            {
                let data = params.slice();
                data.unshift(item);
                return callback.apply(null, data);
            }else{
                return callback(item);
            }            
        });
    }
}