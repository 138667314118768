import { IApprovalGroup } from "./ApprovalGroup.model";
import { IChannel } from "./channel.model";
import { ICreative } from "./creative.model";
import { IFormat } from "./format.model";
import { Permission } from "./permissions.model";
import { IUser } from "./user.model";

export interface IWorkflow {
	id:number;
	uuid:string;
	project_uuid: string;
	project_id: number;
	name: string;
	channels: IChannel[];
	formats: IFormat[];
	creatives: ICreative[];
	approval_groups: IApprovalGroup[];
}
export class Workflow implements IWorkflow{
	id:number;
	uuid: string;
	project_uuid: string;
	project_id: number;
	name: string;
	channels: IChannel[];
	formats: IFormat[];
	creatives: ICreative[];
	approval_groups: IApprovalGroup[];

	public static decorate(workflow: IWorkflow){
		const productionChannels = {};
		const approvalChannels = {};
		const channels = workflow.channels;
		for (let j = 0; j < channels?.length; j++) {
			const channel = channels[j];
			const users = channel['users'];
			for (let k = 0; k < users.length; k++) {
				const user = users[k];
				if(user.scoped_permissions & Permission.PRODUCTION)
				{
					let productionChannelList = productionChannels[user.uuid];
					if(!productionChannelList)
					{
						productionChannels[user.uuid] = productionChannelList = [];
					}
					productionChannelList.push(channel.uuid);
				}
				if(user.scoped_permissions & Permission.APPROVAL)
				{
					let approvalChannelList = approvalChannels[user.uuid];
					if(!approvalChannelList)
					{
						approvalChannels[user.uuid] = approvalChannelList = {};
					}
					approvalChannelList[channel.uuid] = true;//workflow.approval_groups.find(group => group.users.find(u => u.uuid == user.uuid)).order;//user.scoped_data;
					//approvalChannelList[channel.id] = {group:user.scoped_data, moderated:false};
				}								
			}
		}
		workflow['productionChannels'] = productionChannels;
		workflow['approvalChannels'] = approvalChannels;
		workflow['moderators'] = [];
		
		const users = workflow['users'];
		for (let i = 0; i < users?.length; i++) {
			const user = users[i];
			if((user.scoped_permissions & Permission.VALIDATOR) != 0)
			{
				user.isModerator = (user.scoped_permissions & Permission.VALIDATOR) != 0;
				//workflow['moderators'][user.uuid] = true;
				workflow['moderators'].push(user);
			}
		}
		
		// moved from project decoration
		if(!workflow.channels || !workflow.formats || !workflow.creatives) return;
		let channelCache = {};
		let formatCache = {};
		workflow.channels.forEach(channel => channelCache[channel.uuid] = channel);
		workflow.formats.forEach(format => formatCache[format.uuid] = format);
		workflow.creatives.forEach(creative => {
			creative.channel = channelCache[creative.channel_uuid];
			creative.format = formatCache[creative.format_uuid];
			// generate warnings
			if(creative.meta)
			{
				// look for "_totals" in key if found, check key pair per role
				let warnings;
				for (const key in creative.meta) {
					if (Object.prototype.hasOwnProperty.call(creative.meta, key)) {
						
						let index = key.indexOf("_totals");
						if(index != -1)
						{
							const total = creative.meta[key];
							let prefix = key.substring(0, index);
							const actioned = creative.meta[`${prefix}_actioned`];
							if(total == actioned)
							{
								if(!warnings) warnings = [];											
								warnings.push({type:prefix, value:creative.meta[key]});
							}
						}
					}
				}
				if(warnings) {
					// find users
					warnings.forEach(warning => {
						let user;
						let type = warning.type;
						let parts = type.split("_");
						switch (parts[0]) {
							case "approver":
								let uuid = parts[1];
								warning.type = parts[0];
								creative.groupInfo.groups.some(group => {
									let groupUser = group.users.find(user => user.uuid == uuid);
									if(groupUser)
									{
										user = groupUser;
									}
									return user;
								});
								break;
							case "moderator":
								// TODO
								break;
								case "production":
								// TODO										
								break;
							default:
								break;
						}
						if(user) warning.user = user;
					})
					creative.warnings = warnings;
				}
			}
		});
		channelCache = formatCache = null;
	}

	static getChannelProductionUsers(creative_channel_id: string, workflow: IWorkflow, workflow_productionChannels:any = null) : IUser[]{

		if(!creative_channel_id || !workflow) return;

		let prodChannels = workflow_productionChannels || this.decorate(workflow);
		let productionUserLookup = [];

		for(const [k,v] of Object.entries(prodChannels))
		{
			if (prodChannels[k].find((id) => id == creative_channel_id)) 
			{
				productionUserLookup[k] = true;
			}
		}

		let channelUsers = workflow.channels.find((chan) => chan.uuid == creative_channel_id)['users'];
		let channelProductionUsers = channelUsers.filter((user) => productionUserLookup[user.uuid], this);

		return channelProductionUsers;
	}
}