import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { DialogService } from './../../services/dialog.service';
import { StringsService } from './../../services/strings.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Md5 } from 'ts-md5/dist/md5';

interface Node {
	name: string;
	uuid: string;
	is_locked: boolean;
	workgroup: boolean;
	folder: boolean;
	project: boolean;
	_children?: any;
	children?: any;
	parent?: any;
}

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-project-tree',
	templateUrl: './project-tree.component.html',
	styleUrls: ['./project-tree.component.scss']
})

export class ProjectTreeComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	treeControl = new NestedTreeControl<Node>(node => node.children);
	dataSource = new MatTreeNestedDataSource<Node>();
	
	assignPopupOpen:any = 'none';
	
	@Input() account_id:any = false;
	@Input() workgroup_id:any = false;
	@Input() user_id:any = false;
	
	
	hasChild(node: Node){
		if(typeof node['_children'] !== 'undefined' && node['_children'] && typeof node['children'] == 'undefined'){
			node['children'] = [];
			for(let uuid in node['_children']){
				node.children.push(node['_children'][uuid]);
			}
		}
		return !node.project && node.children && node.children.length > 0;
	}
	
	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, public ds:DialogService, public strings:StringsService) { }
  
	ngOnInit(): void {
		this.api.gettreeforaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.dataSource.data = res.data;
			
			//console.log('res.data', res.data);
			
			$('body').removeClass('saving').removeClass('loading');
			$(window).trigger('setup_tooltips');
		});
		this.api.gettreeforuser.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.dataSource.data = res.data;
			
			//console.log('res.data', res.data);
			
			$('body').removeClass('saving').removeClass('loading');
			$(window).trigger('setup_tooltips');
		});
		$('body').addClass('saving').addClass('loading');
		if(this.user_id){
			this.api.getTreeForUser(this.user_id);
		}else{
			this.api.getTreeForAccount(this.account_id, this.workgroup_id);
		}
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	
	getIcon(node: Node) {
		if(node.is_locked)  return 'lock';
		if(node.workgroup)  return 'group_work';
		if(node.folder)     return 'folder';
		if(node.project)    return 'web';
	}
	
	editNode(node: Node) {
		node.parent = this;
		this.ds.openEditContainerDialog(node);
	}
	
	addProject(node: Node) {
		let workgroup_id:any = 0;
		let parent_id:any = node.uuid;
		this.ds.openAddContainerDialog({account_id: this.account_id, owner_id: this.user_id, container_type: 4, workgroup_id:workgroup_id, parent_id: parent_id, saveAction: (_data) => {
			this.api.addproject.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				this.api.getTreeForAccount(this.account_id, this.workgroup_id);
			});
			this.api.addProject(_data);
		}});
	}
	
	addFolder(node: Node) {
		let workgroup_id:any = node.uuid;
		let parent_id:any = node.uuid;
		if(typeof node.workgroup !== 'undefined' && node.workgroup){
			parent_id = 0;
		}else{
			workgroup_id = 0;
		}
		this.ds.openAddContainerDialog({account_id: this.account_id, owner_id: this.user_id, container_type: 3, workgroup_id:workgroup_id, parent_id: parent_id, saveAction: (_data) => {
			this.api.addfolder.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				this.api.getTreeForAccount(this.account_id, this.workgroup_id);
			});
			this.api.addFolder(_data);
		}});
	}
	
	addWorkgroup() {
		// TODO
	}
	
	clickNode(node: Node) {
		//console.log('node', node);
		if(node.project) {
			this.api.gotoPage("project/"+node.uuid);
		}
	}
	
	openAssignUserPopup(uuid:any, type:any){
		if(type == 2){
			this.ds.openUserAssignDialog({account_id:this.account_id, project: false, container_id:uuid, container_type:type});
		}
		if(type == 4){
			this.api.getprojectbyid.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				if(typeof res['caller'] == 'undefined' || res['caller'] !== this) return;
				let _data:any = {account_id:this.account_id, project: res.data.project, container_id:uuid, container_type:type, afterClosed:() => {
					this.assignPopupOpen = 'none';
				}};
				let _data_hash = Md5.hashStr(JSON.stringify(_data));
				if(_data_hash !== this.assignPopupOpen){
					this.assignPopupOpen = _data_hash;
					this.ds.openUserAssignDialog(_data);
				}
			});
			this.api.getProjectById({project_id: uuid}, this);
		}
	}
	
	public openRemoveContainerDialog(label:any, asset:any, child:any, confirmAction:any){
		let confirm:any = {
			 title: 'Delete '+label,
			 message: 'Are you sure you want to delete "'+asset.name+'"?',
			 confirmAction: confirmAction(),
		 };
		 this.ds.openConfirm(confirm);
	}
}
