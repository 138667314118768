<h1>Amends</h1>
<div id="status">
	<div id="userSelection">
		<h3>Temp User Selection</h3>
		<mat-form-field>
			<mat-label>Select a user</mat-label>
			<mat-select [(value)]="currentUser">
				<mat-option *ngFor="let user of users" [value]="user">
					{{user.name + " (" + getUserRoles(user) + ")"}}
				</mat-option>
			</mat-select>
		  </mat-form-field>
		  <p>Selected: {{currentUser ? currentUser.email : ""}}</p>
		  <p>{{this.messageForUser()}}</p>
	</div>
	<div id="chainView">
		<h3>Creative State</h3>
		<h4>{{this.state === CreativeState.Production ? "Production" : this.state === CreativeState.Approval ? "Approval" : "Done" }}</h4>
		<ul>
			<li *ngFor="let message of this.messagesForAllUsers(); let index = index; trackBy:trackByIndex;">{{message.user.name + ':' + message.message}}</li>
		</ul>

	</div>
	<div id="creativeEvents">
		<h3>Creative Events</h3>
		<mat-list role="list">
			<mat-list-item class="creativeEventItem" role="listitem" *ngFor="let creativeEvent of creative.events; let index = index; trackBy:trackByIndex;">{{creativeEvent.toString()}}</mat-list-item>
		</mat-list>
	</div>
</div>
<mat-divider></mat-divider>
<div id="container">
	<div id="middle">
		<div id="creativeSection">
			<span id="creative">
				<!--<iframe [src]="urlSafe" allow-same-origin allow-scrips frameborder="0" width="728" height="90" scrolling="no"></iframe>-->
				<!--<img width="300" height="250" src="https://ov-staging.dev.fivebyfiveuk.com/bin/609201a791ca3805d5d98f39d72205b65e74a4cc030ba/territory_1/2e1982d19ff0686af7f46838e0e9d2cc5e81c8578974d/300x250_fbf_-_Copy.jpg">-->
				<img width="300" height="250" [src]="urlSafe">
			</span>
			<div id="bottom">
				<mat-card *ngIf="isStageActionsVisible()">
					<mat-card-header>
						<mat-card-title >Creative main actions</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<mat-card-actions *ngIf="this.state === CreativeState.Production">
							<button *ngIf="isCompleteCreativeVisible()" mat-raised-button color="primary" [disabled]="!canCompleteCreative()" (click)="this.completeCreative()">Build Complete</button>
						</mat-card-actions>
						<mat-card-actions *ngIf="this.state === CreativeState.Approval">
							<button mat-raised-button color="primary" [disabled]="!canApproveCreative()" (click)="this.approveCreative()">Approve Creative</button>
							<button mat-raised-button color="primary" [disabled]="!canSubmitAmendRequests()" (click)="this.submitAmendRequests()">Submit amend request(s)</button>
						</mat-card-actions>
						<hr>
						<div id="testing">
							<button mat-raised-button (click)="this.resetState()">reset state</button>
							<button *ngIf="testSelectedName" mat-raised-button (click)="this.nextStage()">next stage</button>
							<hr>
							<mat-form-field>
							  <mat-label>Choose test</mat-label>
							  <select [(ngModel)]="testSelectedName" matNativeControl required>
								<option *ngFor="let testName of testNames" [value]="testName">{{testName}}<option>
							  </select>
							</mat-form-field>
							<!--<p>You selected test: {{testSelectedName}}</p>-->
							<div *ngIf="testSelected">
								<mat-list dense>
									<mat-list-item *ngFor="let stage of testSelected.stages; let index = index;" [ngStyle]="getDebugRowStyle(stage, index)">
										{{stage}}
									</mat-list-item>
								</mat-list>
							</div>	
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div id="amends">
			<mat-card class="amends">
				<mat-card-header>
					<mat-card-title>Amend</mat-card-title>
				</mat-card-header>
				
				<div *ngFor="let batch of amendBatches; let index = index;trackBy:trackByIndex;">
					<mat-card *ngIf="batch.amends.length" class="amendBatch">
						<mat-card-header>
							<mat-card-title matBadge="{{batch.amends.length}}" matBadgePosition="above after" matBadgeOverlap="false">{{index}}: {{batch.name}}</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<div *ngFor="let amend of batch.amends; let index = index;trackBy:trackByIndex;">
								<mat-card class="amend" [ngClass]="{'open': amend.open,'closed': !amend.open}">
									<mat-card-header>
										<!--<mat-card-title>{{amend.author.name}}</mat-card-title>-->
										<mat-card-subtitle>{{amend.author.name + (amend.author === this.currentUser ? ' (me)' : '')}}</mat-card-subtitle>
										<!--<mat-card-subtitle matTooltip="{{amend.author.email}}" matTooltipPosition="above">Author:{{amend.author.name}}</mat-card-subtitle>-->
									</mat-card-header>
									<mat-card-content>
										<ng-container *ngIf="isAmendNew(amend) && amend.editable">
											<mat-form-field class="requestTextArea" [style.fontSize]="14">
												<mat-label>request..</mat-label>
												<!--<input [(ngModel)]="batch[index].request" matInput>-->
												<textarea class="nopad" [(ngModel)]="amend.request" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
											</mat-form-field>
										</ng-container>
										<ng-container *ngIf="!isAmendNew(amend)  || !amend.editable">
											<ng-container *ngIf="!amend.editable">
												<!-- TODO make this a textarea too if poss-->
												<span class="nameBadge multiline requestText" matBadge="{{amend.author.getInitials()}}" matBadgeOverlap="false" matBadgePosition="above before" matBadgeSize="large">{{amend.request}}</span>
											</ng-container>
										</ng-container>							
									</mat-card-content>
									<mat-divider *ngIf="amend.closed === true && amend.state === AmendState.DONE"></mat-divider>

									<!-- AMEND ACTIONS -->
									<mat-card-actions *ngIf="amend.open">	
										<!-- production-->
										<ng-container *ngIf="isUserRoleMatchState(CreativeState.Production)">
											<button *ngIf="amend.state === AmendState.TODO" class="positive" mat-stroked-button (click)="amendActionComplete(batch, amend)">complete</button>
											<button *ngIf="amend.state === AmendState.TODO" mat-stroked-button (click)="amendActionDeclineRequest(batch, amend)" color=warn>decline amend request</button>
											<mat-form-field *ngIf="amend.completed" class="full-width">
												<input matInput [(ngModel)]="amend.completedMessage" maxlength="256" placeholder="Additional optional information..."> <!--#message-->
											</mat-form-field>
											<mat-form-field *ngIf="amend.declined" class="full-width">
												<input matInput [(ngModel)]="amend.declinedMessage" maxlength="256" placeholder="Reason for decline...">
											</mat-form-field>
										</ng-container>
										<!-- approval -->
										<ng-container *ngIf="this.state === CreativeState.Approval">
											<!--section for amend athors-->
											<ng-container *ngIf="amend.author === this.currentUser">
												<button *ngIf="amend.open && (amend.state === AmendState.DONE || amend.state === AmendState.DONE_CONFIRMED)" class="positive" mat-stroked-button (click)="actionConfirmDone(batch, amend)">confirm done</button>
												<button *ngIf="amend.open && (amend.state === AmendState.DONE || amend.state === AmendState.DONE_CONFIRMED)" class="negative" mat-stroked-button (click)="actionRejectDone(batch, amend)">reject done</button>
												<button *ngIf="amend.open && (amend.state === AmendState.DECLINED_PRODUCTION || amend.state === AmendState.DECLINED || amend.state === AmendState.DECLINE_ACKNOWLEDGED )" mat-stroked-button (click)="actionAcknowledgeDeclined(batch, amend)">acknowledge declined</button>
												<button *ngIf="isAmendNew(amend) && amend.editable === true" mat-stroked-button (click)="amendActionSave(batch, amend)">save</button>
												<button *ngIf="isAmendNew(amend) && amend.editable === false" mat-stroked-button (click)="amendActionEdit(batch, amend)">edit</button>
												<button *ngIf="isAmendNew(amend)" class="negative" mat-stroked-button color=warn (click)="amendActionDelete(batch, amend)">delete</button>
												<!--<div *ngIf="amend.rejected" class="reject-form">-->
													<mat-form-field *ngIf="amend.rejected" class="full-width">
													<!--<mat-label>Message</mat-label>-->
													<input matInput [(ngModel)]="amend.rejectedMessage" maxlength="256" placeholder="Reason for rejection..."> <!--#message-->
													<mat-hint align="start"><strong>Be concise and to the point!</strong></mat-hint>
													<!--<mat-hint align="end">{{message.value.length}} / 256</mat-hint>-->
													</mat-form-field>
												<!--</div>-->										  
											</ng-container>
											<ng-container *ngIf="amend.author !== this.currentUser">
												<button *ngIf="amend.open && amend.state === AmendState.DECLINED_PRODUCTION && this.currentUser.hasRole(UserRoles.ApprovalManager)" class="positive" mat-stroked-button color=warn (click)="actionAcknowledgeDeclined(batch, amend)">acknowledge declined</button>
												<button *ngIf="amend.state === AmendState.REQUESTED && this.currentUser.hasRole(UserRoles.ApprovalManager)" class="positive" mat-stroked-button color=warn (click)="amendActionAcceptRequest(batch, amend)">accept amend request</button>
												<button *ngIf="amend.state === AmendState.REQUESTED && this.currentUser.hasRole(UserRoles.ApprovalManager)" class="negative" mat-stroked-button (click)="amendActionDeclineRequest(batch, amend)">decline amend request</button>
												<button *ngIf="amend.state === AmendState.DONE && amend.author.hasRole(UserRoles.ApprovalBasic) && this.currentUser.hasRole(UserRoles.ApprovalManager)" class="positive" mat-stroked-button (click)="actionConfirmDone(batch, amend)">confirm done</button>
												<button *ngIf="amend.state === AmendState.DONE && amend.author.hasRole(UserRoles.ApprovalBasic) && this.currentUser.hasRole(UserRoles.ApprovalManager)" class="negative" mat-stroked-button (click)="actionRejectDone(batch, amend)">reject done</button>
												<button *ngIf="amend.state === AmendState.REJECTED && amend.author.hasRole(UserRoles.ApprovalBasic) && this.currentUser.hasRole(UserRoles.ApprovalManager)" class="negative" mat-stroked-button (click)="actionAcknowledgeRejection(batch, amend)">acknowledge rejection</button>
												
												<mat-form-field *ngIf="amend.accepted" class="full-width">
													<input matInput [(ngModel)]="amend.acceptedMessage" maxlength="256" placeholder="Additional info...">
												</mat-form-field>
												<mat-form-field *ngIf="amend.declined" class="full-width">
													<input matInput [(ngModel)]="amend.declinedMessage" maxlength="256" placeholder="Reason for decline...">
												</mat-form-field>
												<mat-form-field *ngIf="amend.rejected" class="full-width">
													<input matInput [(ngModel)]="amend.rejectedMessage" maxlength="256" placeholder="Reason for rejecting...">
												</mat-form-field>
											</ng-container>
										</ng-container>
									</mat-card-actions>
									<!-- END AMEND ACTIONS -->

									<!-- AMEND ACTIONS APPROVER RECALL, TODO MAYBE ALSO ANYONE ELSE IN THIS STAGE -->
									<mat-card-actions *ngIf="false && currentUser === amend.author">
										<button *ngIf="amend.open && amend.state !== AmendState.NEW
										&& amend.state !== AmendState.RECALLED
										&& amend.state !== AmendState.DONE
										&& amend.state !== AmendState.REJECTED" mat-stroked-button (click)="amendActionRecall(batch, amend)">recall</button>
										<mat-form-field *ngIf="amend.recalled" class="full-width">
											<input matInput [(ngModel)]="amend.recalledMessage" maxlength="256" placeholder="Reason for recall...">
										</mat-form-field>
									</mat-card-actions>
									<mat-card-actions *ngIf="false">
										<button mat-stroked-button (click)="acceptAmendRequest(batch, amend)">accept</button>
										<button mat-stroked-button (click)="rejectAmendRequest(batch, amend)"color=warn>reject</button>
									</mat-card-actions>
									<mat-divider></mat-divider>
									<mat-chip-list aria-label="status">
										<mat-chip [ngClass]="{'openChip': amend.open,'closedChip': !amend.open}">{{amend.open ? 'open' : 'closed'}}</mat-chip>
										<mat-chip>
											{{AmendState[amend.state]}}	
											<mat-icon *ngIf="[AmendState.DECLINED, AmendState.DECLINE_CONFIRMED, AmendState.REJECTED, AmendState.REJECTION_CONFIRMED].includes(amend.state)">error_outline</mat-icon>	<!-- rejected -->
											<mat-icon *ngIf="amend.state === AmendState.REQUESTED">help_outline</mat-icon>	<!-- needs confirm -->
											<mat-icon *ngIf="amend.state === AmendState.DONE">check_circle_outline</mat-icon><!-- confirmed -->
										</mat-chip><!--.toLowerCase().split("_")[1]-->
										
									</mat-chip-list>
									<span class="date">{{amend.dateString()}}</span>
									<div class="amendEvents">
										<mat-expansion-panel>
											<mat-expansion-panel-header  collapsedHeight="32px">
												<mat-panel-title>Events</mat-panel-title>
												<!--<mat-panel-description></mat-panel-description>-->
											</mat-expansion-panel-header>
											<mat-list role="list">
												<mat-list-item class="creativeEventItem" role="listitem" *ngFor="let event of amend.events; let index = index; trackBy:trackByIndex;">{{event.toString()}}</mat-list-item>
											</mat-list>								  
										</mat-expansion-panel>
									</div>
									<!--<mat-card-footer></mat-card-footer>-->
								</mat-card>
							</div>
						</mat-card-content>
						<!--
						<button *ngIf="index === amendBatches.length - 1 && isAmendActionsVisible()" [disabled]="!canAddAmendRequest()"  (click)="this.addAmendRequest()" mat-mini-fab aria-label="Add new amend">
							<mat-icon>add</mat-icon>
						</button>
						-->
					</mat-card>
					
				</div>
				<div *ngIf="amendBatches.length === 0 || (amendBatches.length === 1 && amendBatches[0].amends.length === 0)">
					<p>There are no amends in the system for this creative</p>
				</div>
				<mat-card-actions *ngIf="isAmendActionsVisible()">
					<button mat-raised-button [disabled]="!canAddAmendRequest()" (click)="this.addAmendRequest()">request new amend</button>
					<button *ngIf="false" mat-raised-button  [disabled]="!canSubmitAmendRequests()" (click)="this.submitAmendRequests()">Submit amend(s)</button>
					<!--matTooltip="{{tooltipSubmitAmendRequest()}}"-->
				</mat-card-actions>
			</mat-card>
		</div>
	</div>

</div>



<div>
	<!-- user list-->
	<h2>User Roles</h2>
	<mat-list>
		<mat-list-item *ngFor="let user of users">
			<span (click)="this.editUserRoles(user)" >{{user.name}}</span>
			<span class="userActive"><mat-slide-toggle>active</mat-slide-toggle></span>
		</mat-list-item>
	</mat-list>
	<!-- user roles and setttings -->
	
	<ng-container *ngIf="this.selectedUser">
		<h2>{{"Roles for " + this.selectedUser.name}}</h2>
		<mat-selection-list #roles (selectionChange)="this.updateRole($event)">
			<mat-list-option *ngFor="let role of this.getAllRoles()" [value]="role" [selected]="this.selectedUser.hasRole(UserRoles[role])">
			  {{role}}
			</mat-list-option>
		</mat-selection-list>
		<ng-container *ngIf="this.selectedUser.hasRole(UserRoles.Approval) || this.selectedUser.hasRole(UserRoles.ApprovalBasic)">
			<form class="example-form">
				<mat-form-field>
				  <mat-label>Stage</mat-label>
				  <input [ngModelOptions]="{standalone: true}" matInput [(ngModel)]="this.selectedUser.approvalPriority" maxlength="2" type="number" placeholder="0-99" >
				</mat-form-field>
			  </form>
		</ng-container>
	</ng-container>
</div>

