import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { DialogService } from './../../services/dialog.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-project-detail',
	templateUrl: './project-detail.component.html',
	styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, public ds:DialogService) { }
	
	uuid:any;
	project:any = false;
	segments:any = false;
	creatives:any = false;
	segment_labels:any = false;
	segment_label_id:any = false;
	segment_labels_obj:any = {};
	segment_definition:any;
	
	segment_status:any = [];
	
	notifications:any = [
		{
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium lobortis mollis. Suspendisse lobortis convallis congue.'
			
		},
		{
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium lobortis mollis. Suspendisse lobortis convallis congue.'
			
		},
		{
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium lobortis mollis. Suspendisse lobortis convallis congue.'
			
		}
	]

	ngOnInit() {
/*
		// TODO @mike is it better to observe this rather than grab from snapshot?
		const id = this.route.snapshot.paramMap.get('id');
		this.api._tree.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0 || res.length === 0) return;
			this.project = res[0];
		})
		this.api.project(id);
*/
	
		this.segment_status = [
			{
				name: 'UK',
				total: 400,
				health: [
					{forward: 200, backward: 50},
					{forward: 90, backward: 0},
					{forward: 50, backward: 0},
					{forward: 10, backward: 0},
				]
			},
			{
				name: 'FR',
				total: 300,
				health: [
					{forward: 100, backward: 50},
					{forward: 90, backward: 0},
					{forward: 40, backward: 10},
					{forward: 10, backward: 0},
				]
			},
			{
				name: 'DE',
				total: 400,
				health: [
					{forward: 200, backward: 50},
					{forward: 90, backward: 0},
					{forward: 50, backward: 0},
					{forward: 10, backward: 0},
				]
			},
			{
				name: 'DK',
				total: 250,
				health: [
					{forward: 30, backward: 50},
					{forward: 50, backward: 0},
					{forward: 50, backward: 50},
					{forward: 15, backward: 5},
				]
			},
			{
				name: 'JP',
				total: 400,
				health: [
					{forward: 200, backward: 50},
					{forward: 90, backward: 0},
					{forward: 50, backward: 0},
					{forward: 10, backward: 0},
				]
			},
		];
		
		setTimeout(() => {
			$('.bar').each((index, element) => {
				let $bar = $(element);
				
				//console.log($bar);
				
				let p_tot:any = parseInt($bar.closest('.project').find('.project_total').html().replace('/', ''));
				let bar_height: any = $bar.closest('.project').hasClass('status') ? 230 : 30;
				let inc:any = bar_height/p_tot;
				
				//console.log('inc', inc);
				//console.log('p_tot', p_tot);
				
				let $fwd_bar:any = $bar.find('[data-forward]');
				let $bwd_bar:any = $bar.find('[data-backward]');
				
				let fwd:any = parseInt($fwd_bar.attr('data-forward'));
				let bwd:any = parseInt($bwd_bar.attr('data-backward'));
				
				$fwd_bar.css({
					position: 'absolute',
					bottom: 0,
					left: 0,
					width: '100%',
					height: fwd*inc,
					background: 'rgba(144, 198, 57)',
				});
				
				$bwd_bar.css({
					position: 'absolute',
					bottom: fwd*inc,
					left: 0,
					width: '100%',
					height: bwd*inc,
					background: 'red',
				});
				
			});
		}, 350);
		
		this.subscription = this.route.params.subscribe(params => {
			if(params['uuid']){
				this.uuid = params['uuid'];
				this.api.getProjectById({project_id: this.uuid});
			}
		});
		this.api.getprojectbyid.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.project = res.data.project;
			
			this.project['total'] = 0;
			
			this.project['health'] = [
				{forward: 0, backward: 0},
				{forward: 0, backward: 0},
				{forward: 0, backward: 0},
				{forward: 0, backward: 0},
			];
			
			for(let i in this.segment_status){
				let ss:any = this.segment_status[i];
				for(let h in ss.health){
					this.project['health'][h]['forward'] += ss.health[h].forward;
					this.project['health'][h]['backward'] += ss.health[h].backward;
					this.project['total'] += (ss.health[h].forward + ss.health[h].backward);
				}
			}
			
			//console.log('this.project[total]', this.project['total']);
			
			
			if(typeof this.project['description'] == 'undefined') {
				this.project['description'] = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu feugiat urna. Phasellus nec enim ex. Donec nec facilisis augue, sit amet feugiat elit. Etiam tincidunt a eros id semper. Nam quis egestas turpis. Vivamus vel bibendum risus. Suspendisse dapibus metus id tortor porta accumsan. Morbi mauris velit, molestie in dapibus sed, ullamcorper eget leo. Etiam dictum semper interdum. Pellentesque vitae convallis nibh. Morbi consequat faucibus nunc, at semper leo sodales nec. Sed ut ornare nulla. Nulla suscipit risus at congue accumsan. Phasellus quis augue nisi.';
			}
			
			this.api.getaccountconfig.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				//console.log('getaccountconfig', res);
				this.segment_definition = JSON.parse(res.config.segment_definition);
				//console.log('this.segment_definition', this.segment_definition);
			});
			this.api.getAccountConfig({account_id: this.project.account_id});
			
			this.creatives = res.data.creatives;
			this.segment_labels_obj = res.data.segment_labels;
			
			
			this.segments = res.data.segments;
			for(let i in this.segments){
				this.segments[i]['name'] = this.segment_labels_obj[this.segments[i]['segment_label_id']];
				delete this.segment_labels_obj[this.segments[i]['segment_label_id']];
			}
			
			this.segment_labels = [];
			for(let uuid in this.segment_labels_obj){
				this.segment_labels.push({uuid: uuid, name: this.segment_labels_obj[uuid]});
			}
			
			//console.log('res.data', res.data);
			//console.log('this.segments', this.segments);
			//console.log('this.segment_labels', this.segment_labels);
		});
	
	}
	
	setSegmentLabelId(uuid:any){
		//console.log('setSegmentLabelId', uuid);
		this.segment_label_id = uuid;
	}
	
	addSegment(project_id:any){
		
		this.api.addsegment.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//this.project = res.data;
			//console.log('addsegment', res);
			this.api.getProjectById({project_id: this.uuid});
		});
		this.api.addSegment({project_id:project_id, segment_label_id: this.segment_label_id});
		this.api.getProjectById({project_id: this.uuid});
		this.setSegmentLabelId(null);
	}
	
	removeSegment(segment:any){
		//console.log('remove', segment);
		let confirm:any = {
			 title: 'Delete '+this.segment_definition.single,
			 message: 'Are you sure you want to delete '+segment.name+'?',
			 confirmAction: () => {
				 this.api.removesegment.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					//console.log('removesegment', res);
					this.ds.closeDialog();
					//$('.mat-list-item[data-user-uuid='+uuid+']').remove();
					this.api.getProjectById({project_id: this.uuid});
				})
				this.api.removeSegment({uuid:segment.uuid});
			 }
		 };
		 this.ds.openConfirm(confirm);
	}	
	
	addCreative(segment_id:any, name:any){
		
		this.api.addcreative.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//this.project = res.data;
			//console.log('addcreative', res);
			this.api.getProjectById({project_id: this.uuid});
		});
		this.api.addCreative({segment_id:segment_id, name:name});
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	// temp to get project info to render
	getProject()
	{
		return JSON.stringify(this.project, null, 2);
	}
}