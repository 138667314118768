<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="project-tree">
  <mat-nested-tree-node *matTreeNodeDef="let node">
    <li>
      <div class="mat-tree-node">
        <!-- toggle button-->
        <button *ngIf="hasChild(node)" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>
        </button>
        <button *ngIf="!hasChild(node)" disabled mat-icon-button matTreeNodeToggle >
                <!-- dummy spacer button for leaf nodes-->
        </button>
        <!-- main icon -->
        <button mat-icon-button (click)="clickNode(node)">
            <mat-icon [ngClass]="{'disabled' : !node.project && !hasChild(node)}" class="mat-icon-rtl-mirror">{{getIcon(node)}}</mat-icon>
        </button>                
        <!-- name -->
        <span [ngClass]="{'pointer' : node.project}" (click)="clickNode(node)">{{node.name}}</span>

        <span *ngIf="hasChild(node)">&nbsp;<sup>({{node.children.length}})</sup></span>
        <!-- action buttons -->
        <div class="container-action-buttons" *ngIf="!user_id">
            <button *ngIf="!node.project" mat-icon-button>
                <mat-icon class="mat-icon-rtl-mirror" [matMenuTriggerFor]="appMenu">add_box</mat-icon>
            </button>
            <button [attr.data-tooltip-location]="'top'" [attr.data-tooltip-content]="strings.getTooltip('btn_assign_users')" *ngIf="node.workgroup || node.project" mat-icon-button (click)="openAssignUserPopup(node.uuid, (node.workgroup) ? '2' : '4')">
                <mat-icon class="mat-icon-rtl-mirror">face</mat-icon> <!--users-->
            </button>
            <button mat-icon-button [attr.data-tooltip-location]="'top'" [attr.data-tooltip-content]="strings.getTooltip('btn_edit')" (click)="editNode(node)">
                <mat-icon class="mat-icon-rtl-mirror">settings</mat-icon> <!--edit-->
            </button>
        </div>
        <!-- add menu -->
        <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="addFolder(node)">Add Folder</button>
            <button *ngIf="!node.workgroup" mat-menu-item (click)="addProject(node)">Add Project</button>
        </mat-menu>
      </div>
      <ul [class.project-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>