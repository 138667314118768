import { Component, OnInit } from '@angular/core';
import { PusherService } from 'src/app/services/pusher.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(public pusher:PusherService) { }

  ngOnInit(): void {

  }
  deletePublic(i)
  {
    this.pusher.deletePublic(i);
  }
  deletePrivate(i)
  {
    this.pusher.deletePrivate(i);
  }
}
