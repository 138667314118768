import { Component, Inject, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { WorkflowService } from 'src/app/api/workflow.service';
import { Channel, IChannel } from 'src/app/models/channel.model';
import { Creative } from 'src/app/models/creative.model';
import { Permission } from 'src/app/models/permissions.model';
import { IUser, User } from 'src/app/models/user.model';
import { Workflow } from 'src/app/models/workflow.model';


export interface RemoveWorkflowUserData {
	workflow: Workflow;
  user: User;
	channels: IChannel[];
  permissions:number;
}

export interface StringMap<ValueType>{ [key: string]: ValueType; }


export interface RemoveWorkflowActions {
  workflow_uuid:string,
	newAmendManager?:string | null,
  productionActions?:StringMap<StringMap<string>>,
  approvalActions?:StringMap<StringMap<string>>,
  amendManagerConfirmed?:boolean,
  productionActionsConfirmed?:StringMap<boolean>,
  approvalActionsConfirmed?:StringMap<boolean>,
}

@Component({
  selector: 'app-remove-workflow-user',
  templateUrl: './remove-workflow-user.component.html',
  styleUrls: ['./remove-workflow-user.component.scss']
})
export class RemoveWorkflowUserComponent implements OnInit, OnChanges {

  @Input() workflow:Workflow;
  @Input() numWorkflows:number;
  @Input() workflowIndex: number;
  @Input() user:IUser;
  @Input() actions:RemoveWorkflowActions;
  @Input() admins:IUser[];
  @Input() removeAdminOnly:boolean;
  @Output() complete = new EventEmitter<RemoveWorkflowActions>();
  


  @ViewChild('mainStepper') mainStepper: MatStepper;
  @ViewChild('productionChannelStepper') productionChannelStepper: MatStepper;
  @ViewChild('approvalChannelStepper') approvalChannelStepper: MatStepper;
  //productionActions:any[][]; // production actions to take per channel
  //approvalActions:any[][]; // approval actions to take per channel
  isUserAdmin:boolean;
  isUserAmendManager:boolean;


  otherAmendManagers:User[];
  otherProductionUsers:User[][];
  otherApprovalUsers:User[][];

  productionChannels:Channel[] = [];
  approvalChannels:Channel[] = [];

  // dummy form controls
  validAmendManager = new FormControl('')
  validProduction = new FormControl('')
  validApproval = new FormControl('')
  autoFinalise: boolean = true;
  canCompleteProductionStage: boolean;
  canCompleteApprovalStage: boolean;

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RemoveWorkflowUserComponent>,
    private workflowService:WorkflowService,
		@Inject(MAT_DIALOG_DATA) public data: RemoveWorkflowUserData
  ) {
    // if data not injected then go and fetch it?
  }

  ngOnInit(): void {
    this.workflow = this.data.workflow || this.workflow;





    // 
    
    // TODO generate list of channels per workflow that are filtered by permission if they are not already
    // TODO filter list of channels based on a custom user filter i.e. unchecking channel boxes (TODO) - to replace old system
  }
  ngOnChanges(changes:SimpleChanges): void {
    //console.log("changes", changes);
    if(changes.workflow)
    {

      // reset the steppers if they exist
      this.mainStepper?.reset();
      this.productionChannelStepper?.reset();
      this.approvalChannelStepper?.reset();

      // workflow changed lets update
      let wf = changes.workflow.currentValue;
      //this.calculateAlternativeUsers(wf);
      //this.updateActions(wf);
      this.doWorkflowDataSetup(wf);
      
    }
    if(changes.actions)
    {
      // do nothing?
    }
    this.validAmendManager.setErrors({ "required": true });
    this.validProduction.setErrors({ "required": true });
    this.validApproval.setErrors({ "required": true });
  }

  //calculateAlternativeUsers(workflow:Workflow)
  doWorkflowDataSetup(workflow:Workflow)
  {
    // we only care about channels that have at least one creative in them or we might get stuck
    let channels = workflow.channels.filter(channel => channel['creatives']?.length);

    if(!this.removeAdminOnly)
    {
      // channels that the user is in and has production permission on
      this.productionChannels = channels.filter(channel => {
        return channel['users'].find(channelUser => (channelUser.uuid == this.user.uuid) && channelUser.scoped_permissions & Permission.PRODUCTION);
      });
      // channels that the user is in and has approval permission on
      this.approvalChannels = channels.filter(channel => {
        return channel['users'].find(channelUser => (channelUser.uuid == this.user.uuid) && channelUser.scoped_permissions & Permission.APPROVAL);
      });
    }


    // approval managers - only bother if the user is an amend manager
    //console.log("isAmendManager");
    
    this.isUserAmendManager =  workflow['users'].find(workflowUser => {
      return workflowUser.uuid == this.user.uuid && ((workflowUser.scoped_permissions & Permission.VALIDATOR) != 0)
    }) != undefined;
    

    //bug out automatically if not an Amend Manager and no channel creatives to re-assign
    if(!this.isUserAmendManager && channels.length == 0)
    {
      this.done();
    } else {
      this.autoFinalise = false;
    }   

    if(this.isUserAmendManager)
    {
        // stick into otherAmendManagers all admins who are not amend managers already
        this.otherAmendManagers = this.admins.filter(adminUser => {
          return !workflow['users'].find(workflowUser => {
            return workflowUser.uuid == adminUser.uuid && ((workflowUser.scoped_permissions & Permission.VALIDATOR) != 0)
          })
        })
    }else {
      this.otherAmendManagers = [];
    }
    
    


    // production
    this.otherProductionUsers = [];
    this.productionChannels.forEach(channel => {
      let otherProductionUsers:User[] = [];
			for (let i = 0; i < channel['users'].length; i++) {
				const otherUser = channel['users'][i];
				// skip if same user
				if(otherUser.uuid == this.user.uuid) continue;
				// see if user has production in this channel
				if(otherUser.scoped_permissions & Permission.PRODUCTION) otherProductionUsers.push(otherUser);
			}
			this.otherProductionUsers.push(otherProductionUsers);
    });


    // approval - in @FEATURE in future we could also group these by approval group so the options in the list could be grouped also

    let approvaGroupLoookup = {};
    workflow.approval_groups.forEach((approvalGroup, index) => {
      approvalGroup.users.forEach(groupUser => approvaGroupLoookup[groupUser.uuid] = index);
    });
    let userApprovalGroupIndex = approvaGroupLoookup[this.user.uuid];
    this.otherApprovalUsers = [];

     this.approvalChannels.forEach(channel => {
			let otherApprovalUsers:User[] = [];
			for (let i = 0; i < channel['users'].length; i++) {
				const otherUser = channel['users'][i];
				// skip if same user
				if(otherUser.uuid == this.user.uuid) continue;
				// see if user has approval in this channel
				if((otherUser.scoped_permissions & Permission.APPROVAL) == 0) continue;
        // see if they are in an approval group <= the users
        if(approvaGroupLoookup[otherUser.uuid] <= userApprovalGroupIndex)
        {
          otherApprovalUsers.push(otherUser);
        }
			}
			this.otherApprovalUsers.push(otherApprovalUsers);
		});
    //console.log("othar approval usrs", approvaGroupLoookup, this.otherApprovalUsers);
 // }
 //
 ////// now combining these two functions into 1 setup function
 // updateActions(workflow:Workflow)
  //{
 
    if(!this.actions)
    {
      // configure the output
      this.actions = {workflow_uuid:workflow.uuid};
      this.actions.newAmendManager = 'unassign';
      this.actions.productionActions = {};
      this.actions.approvalActions = {};
      this.actions.amendManagerConfirmed = false;
      this.actions.productionActionsConfirmed = {};
      this.actions.approvalActionsConfirmed = {};
    }
      // pre-fill with defaults
      //production
      if(this.productionChannels.length && !this.actions.productionActions[this.productionChannels[0].id]){
        this.productionChannels.forEach(channel => {
          this.actions.productionActionsConfirmed[channel.id] = false;
          this.actions.productionActions[channel.id] = {};
          channel['creatives'].forEach(creative => {
            this.actions.productionActions[channel.id][creative.uuid] = 'unassign';
          });
        });
        //pre-confirm the first channel step (we confirm step when first seen)
        this.actions.productionActionsConfirmed[this.productionChannels[0].id] = true;
      }

      //approval
      if(this.approvalChannels.length && !this.actions.approvalActions[this.approvalChannels[0].id]){
        this.approvalChannels.forEach(channel => {
          this.actions.approvalActionsConfirmed[channel.id] = false;
          this.actions.approvalActions[channel.id] = {};
          channel['creatives'].forEach(creative => {
            this.actions.approvalActions[channel.id][creative.uuid] = 'close';
          });
        });
        //pre-confirm the first channel step (we confirm step when first seen)
        this.actions.approvalActionsConfirmed[this.approvalChannels[0].id] = true;
      }

      //old way... used all channels ?
      /*
      workflow.channels.forEach(channel => {

        this.actions.productionActionsConfirmed[channel.id] = false;
        this.actions.productionActions[channel.id] = {};
        channel['creatives'].forEach(creative => {
          this.actions.productionActions[channel.id][creative.uuid] = 'unassign';
        });

        this.actions.approvalActionsConfirmed[channel.id] = false;
        this.actions.approvalActions[channel.id] = {};
        channel['creatives'].forEach(creative => {
          this.actions.approvalActions[channel.id][creative.uuid] = 'close';
        });

      });
      */
    

      //we're going to set the first channel as completed, and then set each step as completed as soon as it is seen as we dont need them to take specific action, they can leave it as ssen and move on
      //allows us to set 'complete stage' button as soon as get to last step
      this.canCompleteProductionStage = this.productionChannels.length == 1;
      this.canCompleteApprovalStage = this.approvalChannels.length == 1;
    
  }
  completeAmendManagerStage()
  {
    //this.validAmendManager.reset();
    this.actions.amendManagerConfirmed = true;
    // this.mainStepper.next();
    setTimeout(() => {
      // https://stackoverflow.com/a/50064564
      this.mainStepper.next();
    }, 1);
  }
  previousProductionChannel()
  {
    this.productionChannelStepper.previous();
  }
  

  nextProductionChannel()
  {
    let index = this.productionChannelStepper.selectedIndex;
    //this.actions.productionActionsConfirmed[this.workflow.channels[index].id] = true;

    //console.log("prod stage completed:",this.canCompleteProductionStage());
    if(index < this.productionChannelStepper.steps.length - 1)
    {
      this.productionChannelStepper.next();

      //pre-complete this next channel stage
      this.actions.productionActionsConfirmed[this.productionChannels[index+1].id] = true;
      //this.actions.productionActionsConfirmed[this.workflow.channels[index+1].id] = true;

      this.canCompleteProductionStage = (this.productionChannels.every(channel => this.actions.productionActionsConfirmed[channel.id] == true)); 
    }else{
      setTimeout(() => {
        // https://stackoverflow.com/a/50064564
        this.mainStepper.next();
      }, 1);
    }
  }
  previousApprovalChannel()
  {
    this.approvalChannelStepper.previous();
  }
  nextApprovalChannel()
  {
    let index = this.approvalChannelStepper.selectedIndex;
    //this.actions.approvalActionsConfirmed[this.workflow.channels[index].id] = true;
    if(index < this.approvalChannelStepper.steps.length - 1)
    {
      this.approvalChannelStepper.next()
      this.actions.approvalActionsConfirmed[this.approvalChannels[index+1].id] = true;
      //this.actions.approvalActionsConfirmed[this.workflow.channels[index+1].id] = true;

      this.canCompleteApprovalStage = (this.approvalChannels.every(channel => this.actions.approvalActionsConfirmed[channel.id] == true)); 
    }else{
      setTimeout(() => {
        // https://stackoverflow.com/a/50064564
        this.mainStepper.next();
      }, 1);
    }
  }
  done()
  {
    this.complete.emit(this.actions);
  }
  copyProductionActionToOthers(creative:Creative, channel:IChannel)
	{
		// only for this channel
		let actions = this.actions.productionActions[channel.id];
    if(!actions) this.actions.productionActions[channel.id] = {};
    if(actions)
    {
      let action = actions[creative.uuid];
      if(!action) return;
      channel['creatives'].forEach((creative:Creative) => {
        actions[creative.uuid] = action;
      })
      /*
      for (const creative_uuid in actions) {
        actions[creative_uuid] = action;          
      }*/
    }
	}
  copyApprovalActionToOthers(creative:Creative, channel:IChannel)
	{
		// only for this channel
    let actions = this.actions.approvalActions[channel.id];
    if(!actions) this.actions.approvalActions[channel.id] = {};
    if(actions)
    {
      let action = actions[creative.uuid];
      if(!action) return;
      channel['creatives'].forEach((creative:Creative) => {
        actions[creative.uuid] = action;
      })
      /*
      for (const creative_uuid in actions) {
        actions[creative_uuid] = action;          
      }*/
    }
	}
  onProductionActionChange(action, channel, creative)
  {
    if(this.actions.productionActions[channel.id] == undefined)
    {
      this.actions.productionActions[channel.id] = {};
    }    
    this.actions.productionActions[channel.id][creative.uuid] = action;
    // check if all channel items resolved
    //this.workflow.channels
  }
  canCompleteProductionStagex()
  {
    let complete = (this.productionChannels.every(channel => this.actions.productionActionsConfirmed[channel.id] == true));// ||  (this.productionChannelStepper?.selectedIndex+1) == this.productionChannels?.length);
    //console.log(this.actions.productionActionsConfirmed);
    return complete;
  }
 
  canCompleteApprovalStagex()
  {
    let complete = this.approvalChannels.every(channel => this.actions.approvalActionsConfirmed[channel.id] == true);
    return complete;
  }
  checkAllCreativesProductionActioned(channel:Channel)
  {
    return this.actions.productionActionsConfirmed[channel.id];
    let id = channel.id;
    // old way
    let actions = this.actions.productionActions[channel.id];
    if(!actions) return false;
    // ensure all creatives are actioned
    let result = channel['creatives'].every((creative:Creative) => {
      return actions[creative.uuid];
    })
    if(result) return true;
    return false;
  }
  onApprovalActionChange(action, channel, creative)
  {
    if(this.actions.approvalActions[channel.id] == undefined)
    {
      this.actions.approvalActions[channel.id] = {};
    }    
    this.actions.approvalActions[channel.id][creative.uuid] = action;
    // check if all channel items resolved
    //this.workflow.channels
  }
  checkAllCreativesApprovalActioned(channel:Channel)
  {
    let actions = this.actions.approvalActions[channel.id];
    if(!actions) return false;
    // ensure all creatives are actioned
    let result = channel['creatives'].every((creative:Creative) => {
      return actions[creative.uuid];
    })
    if(result) return true;
    return false;
  }

}
