import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/api/project.service';

export interface ProjectCloneDialogData {
  project: Project;
  output: {
    name:string,
    channels:boolean,
    admins:boolean,
    workflows:boolean,
    users:boolean,
    formats:boolean,
    creatives:boolean,
    assets:boolean,
  }
}

@Component({
  selector: 'app-project-clone',
  templateUrl: './project-clone.component.html',
  styleUrls: ['./project-clone.component.scss']
})
export class ProjectCloneComponent implements OnInit, OnDestroy {

  private _unsubscribe = new Subject<boolean>();

  constructor(
    private projectService:ProjectService,
    private snackBar:MatSnackBar,
    public dialogRef: MatDialogRef<ProjectCloneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectCloneDialogData,
  ) {
      if(!data.output)
      {
        data.output = {
          name:data.project.name + " - copy",
          channels:true,
          admins:true,
          workflows:true,
          users:true,
          formats:false,
          creatives:false,
          assets:false,
        }
      }
    }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
		this._unsubscribe.next(true);
	}
  clone()
  {
    this.projectService.copy(this.data.project.uuid, this.data.output).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
      this.snackBar.open("project copied", "", {duration:2000});
      this.dialogRef.close(true);
    }, (error:HttpErrorResponse) => {
      if(error.status == 422)
      {
        this.snackBar.open("project copy failed: " + error.error.message, "", {duration:2000, panelClass:'snackBar-error'});
      }else {
        this.snackBar.open("project copy failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
      }							
    });
  }

}
