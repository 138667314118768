<button  (click)="testJob()" matTooltip="Don't spam!" mat-flat-button color="primary">Trigger test job</button>
<h2>Queued jobs ({{jobs?.length || 0}})</h2>
<div *ngIf="jobs">
    <div *ngFor="let job of jobs">
        <span>{{job.id}}</span>
        <span>{{job.queue}}</span>
        <span>{{job.attempts}}</span>
    </div>
</div>
<h2>Failed jobs ({{failed_jobs?.length || 0}})</h2>
<div *ngIf="false && failed_jobs" style="position:absolute; width:100%; height:100%;">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let job of failed_jobs">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="title">                    
                    <span>{{job.id}}</span>
                    <span>{{job.connection}}</span>
                    <span>{{job.queue}}</span>                
                </div>
            </mat-panel-title>
            <mat-panel-description>
                <span>{{job.payload.displayName}} | {{job.failed_at | dateAgo}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <pre>{{job.payload | json}}</pre>
          <pre>{{job.exception}}</pre>
        </mat-expansion-panel>
    </mat-accordion>
</div>
