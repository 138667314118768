import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-control-bar-view',
  templateUrl: './control-bar-view.component.html',
  styleUrls: ['./control-bar-view.component.scss']
})

export class ControlBarViewComponent implements OnInit {

  constructor() { }

  @Output() controlMode = new EventEmitter();
  @Output() addPanel = new EventEmitter();

  @Input() currentMode;


  ngOnInit() {
  }


  slideOut(panel){
    this.addPanel.emit(panel); 
  }
}
