import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum MoveTypes {
  Move = 'move',
  Resize = 'resize',
  Pinch = 'pinch'
}

@Component({
  selector: 'app-crop-widget',
  templateUrl: './crop-widget.component.html',
  styleUrls: ['./crop-widget.component.scss']
})
export class CropWidgetComponent implements OnInit {

  moveTypes = MoveTypes;
  cropper: any = {
      x1: 0,
      y1: 0,
      x2: 300,
      y2: 250
  };
  dragging:boolean;
  type:MoveTypes;
  moveStart:any;
  constructor(
    public dialogRef: MatDialogRef<CropWidgetComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any){
  
  }

  ngOnInit(): void {
  }
  startMove(event, type, constraints = null)
  {
    this.dragging = true;
    this.type = type;
    this.moveStart = {
      clientX: this.getClientX(event),
      clientY: this.getClientY(event),
      constraints,
      ...this.cropper
  };
  }
  @HostListener('document:mousemove', ['$event'])
    @HostListener('document:touchmove', ['$event'])
    moveImg(event: any): void {
        if (this.dragging) {
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (this.type === MoveTypes.Move) {
                this.move(event);
               // this.checkCropperPosition(true);
            } else if (this.type === MoveTypes.Resize) {
                this.resize(event);
               // if (!this.cropperStaticWidth && !this.cropperStaticHeight) {
              //      this.resize(event);
              //  }
               // this.checkCropperPosition(false);
            }
            //this.cd.detectChanges();
        }
    }
    @HostListener('document:mouseup')
    @HostListener('document:touchend')
    moveStop(): void {
        if (this.dragging) {
            this.dragging = false;
            //this.doAutoCrop();
        }
    }
    resize(event)
    {
        const diffX = this.getClientX(event) - this.moveStart.clientX;
        const diffY = this.getClientY(event) - this.moveStart.clientY;

        if(this.moveStart.constraints && this.moveStart.constraints.split(",").indexOf('top') != -1)
        {
            this.cropper.y1 = this.moveStart.y1 + diffY;
        }else if(this.moveStart.constraints && this.moveStart.constraints.split(",").indexOf('bottom') != -1)
        {
            this.cropper.y2 = this.moveStart.y2 + diffY;
        }
        if(this.moveStart.constraints && this.moveStart.constraints.split(",").indexOf('left') != -1)
        {
            this.cropper.x1 = this.moveStart.x1 + diffX;
        }else if(this.moveStart.constraints && this.moveStart.constraints.split(",").indexOf('right') != -1)
        {
            this.cropper.x2 = this.moveStart.x2 + diffX;
        }
        // TODO minimum size
        // TODO bounds checking
    }
    move(event)
    {
        const diffX = this.getClientX(event) - this.moveStart.clientX;
        const diffY = this.getClientY(event) - this.moveStart.clientY;

        this.cropper.x1 = this.moveStart.x1 + diffX;
        this.cropper.y1 = this.moveStart.y1 + diffY;
        this.cropper.x2 = this.moveStart.x2 + diffX;
        this.cropper.y2 = this.moveStart.y2 + diffY;
    }
    private getClientX(event: any): number {
      return (event.touches && event.touches[0] ? event.touches[0].clientX : event.clientX) || 0;
  }

  private getClientY(event: any): number {
      return (event.touches && event.touches[0] ? event.touches[0].clientY : event.clientY) || 0;
  }
}
