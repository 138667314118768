import { Component, Input, OnInit } from '@angular/core';
import { TaskAction } from 'src/app/models/task.model';



@Component({
	selector: 'app-task-action',
	templateUrl: './task-action.component.html',
	styleUrls: ['./task-action.component.scss']
})

export class TaskActionComponent implements OnInit {

	constructor() { }

	@Input() action:TaskAction;

	ngOnInit(): void {
	}

}
