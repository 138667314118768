export interface IProject {
	id: number;
	uuid: string;
	workgroup_uuid: string;
	account_uuid: string;
	name: string;
	archived: boolean;
	is_locked: boolean;
	overview: string;
}

export class Project implements IProject{
	public id: number;
	public uuid: string;
	public workgroup_uuid: string;
	public account_uuid: string;
	public name: string;
	public archived: boolean;
	public is_locked: boolean;
	public overview: string;
}

export class Project2 {
	public id: number;
	public uuid: string;
	//public workgroup_uuid:string;
	public workgroup_uuid: string;
	public account_uuid: string;
	public name: string;
	public archived: boolean;
	public is_locked: boolean;
	public overview: string;
	
	static hello(p: Project2) {
		return p.name.toUpperCase();
	}
}