import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from 'src/app/models/user.model';

@Component({
	selector: 'app-creative-production',
	templateUrl: './creative-production.component.html',
	styleUrls: ['./creative-production.component.scss']
})
export class CreativeProductionComponent implements OnInit {
	@Output() productionSelected : EventEmitter<IUser> =  new EventEmitter();	
	@Input() availableProductionUsers : IUser[];
	@Input() selectedProductionOwners : IUser[];
	//@Input() selectedProduction : IUser;
	//@Input() selectedProductions : any;
	//@Input() creativeProductionTask: any;

	constructor() { }

	ngOnInit(): void {
	}

	setProduction(user:IUser)
	{
		this.productionSelected.emit(user);
	}
	
	/*
	isSelected(user:IUser)
	{
		return this.selectedProductions ? this.selectedProductions[user.uuid] != undefined : false;
	}
	

	isProductionOwner = (user):boolean => {
		//console.log("INFO creative: "+this.creative);
		if(!this.creativeProductionTask || !user) return;
		return this.creativeProductionTask && this.creativeProductionTask.user_uuid == user.uuid;
	}
	*/
}
