<p>prototypes works</p>

<ul>
    <li>
        <a href="/prototypes/projects">projects</a>
    </li>
    <li>
        <a href="/prototypes/amends"><del>amends</del></a>
    </li>
    <li>
        <a href="/prototypes/tasks"><del>tasks</del></a>
    </li>
</ul>
