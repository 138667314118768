
<div *ngIf="workflow" class="workflow">
    <!--<h3>{{workflow.name}}</h3> -->
    <!--<ul *ngIf="moderators">
        <li *ngFor="let moderator of moderators">{{moderator.name}}</li>
    </ul>-->
    <!--<div class="subHeader">Approval groups:</div>-->
	<div class="container">
		<ng-container *ngFor="let group of groupInfo.groups; index as i; last as isLast">
           <!-- <div class="groups"> -->
		        <!-- <div class="group" [ngClass]="{'approval-group-done':group.total > 0 && group.total == group.approved, 'approval-group-current':group.total > 0 && group.users[0].state != 'waiting' && group.total != (group.approved + group.submitted)}"> --> <!--this.groupsApproved[i] || group.id == currentGroup?.id -->
					
				<div #grouptoscroll [class.scrolled]="group == currentGroup">
					
				<!-- <div class="approval-group-label" [ngClass]="{'approval-group-done':group.done, 'approval-group-current':group == currentGroup}">{{group.done ? 'all approved' : group == currentGroup ? 'current group'  : group.users && group.users.length && group.users[0].state != 'waiting' ? 'active' : 'pending'}}<mat-icon *ngIf="!group.users || (group.users && group.users.length == 0)" class="red">warning</mat-icon></div> -->	
					<div class="approval-group-label"
					[class.approval-group-done]="group.done"
					[class.approval-group-current] ="group == currentGroup"
					[class.approval-group-active] ="!group.done && group.users && group.users.length && group.users[0].state != 'waiting'"><!--this.groupsApproved[i] || group.id == currentGroup?.id --><!-- && group != currentGroup -->
						{{group.done ? 'approved' : group.users && group.users.length && group.users[0].state != 'waiting' ? 'active' : 'pending'}} <!-- group == currentGroup ? 'current group'  : -->
						<mat-icon *ngIf="!group.users || (group.users && group.users.length == 0)" class="red">warning</mat-icon>
					</div>
					<div class="group-content"
					[class.approval-group-done]="group.done"
					[class.approval-group-current] ="group == currentGroup"
					[class.approval-group-active] ="!group.done && group.users && group.users.length && group.users[0].state != 'waiting'"
					[approval-group-warning] ="!group.users || (group.users && group.users.length == 0)"
					>
					<!--this.groupsApproved[i] || group.id == currentGroup?.id --><!-- && group != currentGroup -->
					<!-- [ngClass]="{'approval-group-done':group.done, 'approval-group-current':group == currentGroup, 'approval-group-warning':!group.users || (group.users && group.users.length == 0)}"-->
						<div class="group-header" [matTooltip]="group.use_moderator ? 'amends moderated' : 'amends NOT moderated'" [ngClass]="(!group.done && group != currentGroup) && (group.users && group.users.length && group.users[0].state == 'waiting') ? 'deprioritise': ''"><div>{{group.name}}</div><span>{{group.mode}} <mat-icon [ngClass]="group.use_moderator ? '' : 'red'" [ngStyle]="group.use_moderator ? {} : {'font-size':'14px'}" style="vertical-align: middle; margin-right: -0.25em;" *ngIf="true || group.use_moderator">{{group.use_moderator ? 'gpp_good' : 'remove_moderator'}}</mat-icon></span></div>
						<div *ngIf="group.users && group.users.length" [ngClass]="(!group.done && group != currentGroup) && group.users[0].state == 'waiting' ? 'deprioritise': ''">
							<ng-container *ngFor="let user of group.users">
								<div class="user-row">
                                    <mat-icon [matTooltip]="isIndicatedUser(user.state) && group == currentGroup ? 'waiting for this user\'s response' : ''" class="user-indicator">{{isIndicatedUser(user.state) && group == currentGroup ? 'forward' : ''}}</mat-icon>
                                    <span>{{user.name}}<!-- ({{user.state}})--></span>
                                    <mat-icon [ngClass]="{'green': user.state == 'approved'}" [matTooltip]="getApproverIcon(user.state).iconTooltip" matTooltipPosition="right">{{getApproverIcon(user.state).icon}}</mat-icon>
                                    <div *ngIf="user.version" style="font-size: 0.8em; opacity: 0.5;">v{{user.version}}</div>
                                </div>
							</ng-container>							
						</div>
						<div >
							<div *ngIf="!group.users || (group.users && group.users.length == 0)" class="user-row instruction">No approvers assigned to group<br/>for channel: <strong>{{channel.name}}</strong></div>						
						</div>
					</div>
                </div>
                <div class="group-arrow" *ngIf="!isLast">
                    <mat-icon>navigate_next</mat-icon>
                </div>
            <!-- </div> -->
        </ng-container>
        <div *ngIf="!groupInfo?.groups?.length">
            <div class="instruction">No approval groups have been set up in this worklflow.</div>						
        </div>
        <!--        
        <ng-container *ngFor="let group of workflow.approval_groups; index as i; last as isLast">
            <div class="groups">
                <div class="group" [ngClass]="{current:this.groupsApproved[i] || group.id == currentGroup?.id }">
                    <div class="group-header">Group: {{i}} - {{group.name}}</div>
                    <div *ngIf="group.users && group.users.length">
                        <ng-container *ngFor="let user of group.users">
                            <div *ngIf="user && user.tasks.length" >{{user.name}} - {{user.tasks[0].state}}</div>
                        </ng-container>							
                    </div>
                    <div *ngIf="!group.users || group.users.length == 0">
                        <div class="warning">Warning: no approver in group for {{channel.name}}</div>						
                    </div>
                </div>
                <div *ngIf="!isLast">
                    <mat-icon>navigate_next</mat-icon>
                </div>
            </div>
        </ng-container>
        -->
    </div>
</div>