import { HttpClient } from '@angular/common/http'; //HttpParams
import { Injectable } from '@angular/core';
import { DeliveryPackage } from '../models/deliveryPackage.model';

import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class DeliveryPackageService extends OVBaseService<DeliveryPackage, string> {
	constructor(protected http: HttpClient) {
		super(http, 'delivery_package');
	}
}
