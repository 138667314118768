import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { MatDialogModule } from '@angular/material';
// import { MatInputModule } from '@angular/material/input';
import { ApiService } from './../../api/api.service';
import { DialogService } from './../../services/dialog.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-manage-accounts',
	templateUrl: './manage-accounts.component.html',
	styleUrls: ['./manage-accounts.component.scss']
})
export class ManageAccountsComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	accounts:any = [];

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, private ds:DialogService) { }
	
	addAccount(){
		$('body').addClass('saving').addClass('loading');
		let _name:any = $('#name').val();
		let _data:any = {
			name: _name, 
		};
		this.api.addAccount(_data);
	}
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		this.subscription = this.api.addaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			$('body').removeClass('saving').removeClass('loading');
			if(res.error){
				setTimeout(() => {
					this.ds.openSnackbarMessage({message:res.error.message});
					$('.login').addClass('error');
				}, 500);
				return;
			}
			if(res.status == 201){
				this.ds.openSnackbarMessage({message:'Account added'});
				this.api.getAllAccounts();
			}
		});
		this.subscription = this.api.getallaccounts.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//console.log('getallaccounts', res);
			this.accounts = res.data;
		});
		this.api.getAllAccounts();
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}