

<div class="copypanel">
    
    <div *ngIf="!availableChannels">
        <p>Retrieving channel info...</p>
    </div>
    <div *ngIf="availableChannels && copyToChannelCount == 0">
        <p class="instruction">There are no available channels to copy to in this workflow</p>
    </div>
    <div *ngIf="availableChannels && copyToChannelCount > 0">
        <p>Choose channels in this workflow to copy to:</p>
        <form *ngIf="form" [formGroup]="form">
            <mat-checkbox class="channelcheckbox" *ngFor="let channel of availableChannels" [formControl]="channel.control">{{channel.channel.name}}</mat-checkbox>
        </form>
        <mat-divider></mat-divider>
        <p>Choose properties to copy:</p>
        <div><mat-checkbox [(ngModel)]="copyAssets">Asset layer info (not files)</mat-checkbox></div>
        <div><mat-checkbox disabled="true">Asset files (coming soon)</mat-checkbox></div>
        <div><mat-checkbox [(ngModel)]="copyLabel">Tag colour</mat-checkbox></div>
        <div><mat-checkbox [(ngModel)]="copySpec">Spec notes</mat-checkbox></div>
        <mat-divider></mat-divider>
    </div>

    <div>
        <button *ngIf="availableChannels && copyToChannelCount > 0" mat-raised-button color="primary" [disabled]="!availableChannels || !form.valid" (click)="this.copy()">Copy creative</button>
        <button style="float:right" mat-stroked-button color="warn" (click)="this.close()">cancel</button>
    </div>
</div>
