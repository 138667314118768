<p>Select an approval group for this user or create a new one.</p>
<mat-form-field appearance="fill">
    <mat-label>Choose Group</mat-label>
    <mat-select [(ngModel)]="group">
        <mat-option [value]="new_group">New</mat-option>
        <mat-option *ngFor="let grp of data.approval_groups" [value]="grp">
            {{grp.name}}
        </mat-option>
    </mat-select>
</mat-form-field>
<div *ngIf="group == new_group">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>New Group Name</mat-label>
        <input [(ngModel)]="group.name" matInput placeholder="name" value="">
    </mat-form-field>
    <div *ngIf="group.name.length > 0 && data.approval_groups.length">
        <p>Choose order</p>
        <mat-list role="list">
            <ng-contaimer *ngFor="let grp of data.approval_groups; index as index">
                <mat-list-item *ngIf="group.order == index" role="listitem">
                    {{group.name}}
                    <mat-icon>arrow_up</mat-icon>
                    <mat-icon>arrow_down</mat-icon>
                </mat-list-item>
                <mat-list-item role="listitem">{{grp.name}}</mat-list-item>
            </ng-contaimer>
          </mat-list>
    </div>
</div>
<div *ngIf="group">
    group:{{group?.name}}
</div>
<div>
    <button mat-button (click)="this.dialogRef.close()">Cancel</button>
    <button mat-button (click)="save()" [disabled]="!group || group.name.length < 1" color="primary">Save</button>
</div>
