class BoolMap<T = string> extends Map<T, boolean> {
    set(key: T, value: boolean): this {
        if(value)   super.set(key, value);
        else if(this.has(key))  super.delete(key);
        return this;
    }
    toggle(key: T)
    {
        if(this.has(key))  super.delete(key);
        else super.set(key, true);
    }
}
export default BoolMap;