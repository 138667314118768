import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-audio-visualize-widget',
  templateUrl: './audio-visualize-widget.component.html',
  styleUrls: ['./audio-visualize-widget.component.scss']
})
export class AudioVisualizeWidgetComponent implements OnInit, OnDestroy, OnChanges {

  @Input() stream:MediaStream;
  @Input() colour:string = 'rgb(0, 0, 0)';

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  private audioCtx:AudioContext;
  private analyser:AnalyserNode;
  private bufferLength:number;
  private dataArray:Uint8Array;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("CHANGED", changes)
  }
  ngOnDestroy(): void {
    
  }

  ngOnInit(): void {
    if(!this.audioCtx) {
		  this.audioCtx = new AudioContext();
      this.visualize();
		}
  }
	visualize() {
		
	  
		const source = this.audioCtx.createMediaStreamSource(this.stream);
	  
		this.analyser = this.audioCtx.createAnalyser();
		this.analyser.fftSize = 2048;
		this.bufferLength = this.analyser.frequencyBinCount;
		this.dataArray = new Uint8Array(this.bufferLength);
	  
		source.connect(this.analyser);
		//analyser.connect(this.audioCtx.destination);
	  

		requestAnimationFrame(() => this.draw());
  }
  draw() {
    // redraw if stream is active
    if(this.stream?.active)
    {
      requestAnimationFrame(() => this.draw());
    }
    if(!this.stream) return;
    const canvas = this.canvas.nativeElement;

    // Make it visually fill the positioned parent
    canvas.style.width ='100%';
    //this.canvas.style.height='100%';
    // ...then set the internal size to match
    canvas.width  = canvas.offsetWidth;
    //this.canvas.height = this.canvas.offsetHeight;

    const canvasCtx = canvas.getContext('2d');
    const WIDTH = canvas.width
    const HEIGHT = canvas.height;
  
  
    this.analyser.getByteTimeDomainData(this.dataArray);
  
    canvasCtx.fillStyle = 'rgb(255, 255, 255)';
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
  
    canvasCtx.lineWidth = 2;
   // if(mediaRecorder?.state == 'recording')
   // 	canvasCtx.strokeStyle = 'rgb(255, 0, 0)';
   // else
      canvasCtx.strokeStyle = this.colour;
  
    canvasCtx.beginPath();
  
    let sliceWidth = WIDTH * 1.0 / this.bufferLength;
    let x = 0;	  
  
    for(let i = 0; i < this.bufferLength; i++) {
  
    let v = this.dataArray[i] / 128.0;
    let y = v * HEIGHT/2;
  
    if(i === 0) {
      canvasCtx.moveTo(x, y);
    } else {
      canvasCtx.lineTo(x, y);
    }
  
    x += sliceWidth;
    }
  
    canvasCtx.lineTo(canvas.width, canvas.height/2);
    canvasCtx.stroke();
  
  }
}
