<form [formGroup]="formGroup">
    <h2 *ngIf="title">{{title}}</h2>
    <div class="inputs">
        <mat-form-field>
            <input #inputPassword matInput autocomplete="new-password" [type]="hidePassword ? 'password' : 'text'" placeholder="Password" formControlName="password" (input)="onPasswordInput()">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="password.hasError('required')">Password is required</mat-error>
            <mat-error *ngIf="password.hasError('minlength')">Password must have at least {{minPasswordLength}} characters</mat-error>
          </mat-form-field>					
          <mat-form-field>
            <input #inputPasswordConfirm matInput autocomplete="new-password" [type]="hideConfirmPassword ? 'password' : 'text'" placeholder="Confirm password" formControlName="passwordConfirm" (input)="onPasswordInput()">
            <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="!password.errors && passwordConfirm.hasError('required')">Please confirm your password</mat-error>
            <mat-error *ngIf="!password.errors && passwordConfirm.invalid && !passwordConfirm.hasError('required')">Passwords don't match</mat-error>
          </mat-form-field>
    </div>
    <div class="buttons">
        <button [disabled]="!formGroup.valid" (click)="this.done.emit({password:inputPassword.value, password_confirmation:passwordConfirm.value})" mat-raised-button>{{submitMessage}}</button>
    </div>
</form>