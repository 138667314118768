<div class="login-container">
	<div class="login">
		<mat-card>
			<form class="login-form">
				<div class="logo"></div>
				<div class="inputs">
					<mat-form-field>
						<input type="text" id="email" class="login_input" matInput placeholder="Email" value="">
					</mat-form-field>
				</div>
				<div class="button_wrapper">
					<button (click)="requestPassword()" mat-raised-button>Send reset link</button>
				</div>
				<span *ngIf="error_message" class="error_message">{{error_message}}</span>
			</form>
		</mat-card>
	</div>
</div>