import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-trim-widget',
  templateUrl: './trim-widget.component.html',
  styleUrls: ['./trim-widget.component.scss']
})
export class TrimWidgetComponent implements OnInit {

  timeIn:any = '00:00';
  timeOut:any;
  constructor(
    public dialogRef: MatDialogRef<TrimWidgetComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any)
    {
        if(data.timeIn) this.timeIn = data.timeIn;
        if(data.timeOut) this.timeOut = data.timeOut;
    }
    ngOnInit(): void {
  }
}
