<p>admin-stats works!</p>

<div>
    <h2>disk stats:</h2>
    <h3>uk</h3>
    <div *ngIf="diskUseage.uk">
        <p>total: {{diskUseage.uk.total | formatBytes}} </p>
        <p>free: {{diskUseage.uk.free | formatBytes}} </p>
        <mat-progress-bar mode="determinate" [value]="diskUseage.uk.utilised"></mat-progress-bar>
        <svg height="50" width="50" viewBox="0 0 20 20">
            <circle r="10" cx="10" cy="10" fill="blue" />
            <circle r="5" cx="10" cy="10" fill="blue"
                    stroke="red"
                    stroke-width="10"
                    [attr.stroke-dasharray]="'calc(' + diskUseage.uk.utilised + ' * 31.4 / 100) 31.4'"
                    transform="rotate(-90) translate(-20)" />
            <circle r="10" cx="10" cy="10" fill="none" stroke="white" stroke-width="1" />
          </svg>
    </div>
    <h3>us</h3>
    <div *ngIf="diskUseage.us">
        <p>total: {{diskUseage.us.total | formatBytes}} </p>
        <p>free: {{diskUseage.us.free | formatBytes}} </p>
        <mat-progress-bar mode="determinate" [value]="diskUseage.us.utilised"></mat-progress-bar>
        <svg height="50" width="50" viewBox="0 0 20 20">
            <circle r="10" cx="10" cy="10" fill="blue" />
            <circle r="5" cx="10" cy="10" fill="blue"
                    stroke="red"
                    stroke-width="10"
                    [attr.stroke-dasharray]="'calc(' + diskUseage.us.utilised + ' * 31.4 / 100) 31.4'"
                    transform="rotate(-90) translate(-20)" />
            <circle r="10" cx="10" cy="10" fill="none" stroke="white" stroke-width="1" />
          </svg>
    </div>
    <h3>au</h3>
    <div *ngIf="diskUseage.au">
        <p>total: {{diskUseage.au.total | formatBytes}} </p>
        <p>free: {{diskUseage.au.free | formatBytes}} </p>
        <mat-progress-bar mode="determinate" [value]="diskUseage.au.utilised"></mat-progress-bar>
        <svg height="50" width="50" viewBox="0 0 20 20">
            <circle r="10" cx="10" cy="10" fill="blue" />
            <circle r="5" cx="10" cy="10" fill="blue"
                    stroke="red"
                    stroke-width="10"
                    [attr.stroke-dasharray]="'calc(' + diskUseage.au.utilised + ' * 31.4 / 100) 31.4'"
                    transform="rotate(-90) translate(-20)" />
            <circle r="10" cx="10" cy="10" fill="none" stroke="white" stroke-width="1" />
          </svg>
    </div>
</div>
<div>
    <h2>db stats:</h2>
    <p>useage</p>
    <mat-progress-bar mode="determinate" [value]="diskUseage"></mat-progress-bar>
</div>