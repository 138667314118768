import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/api/user.service';
import { IUser, User } from 'src/app/models/user.model';
import { PusherService } from 'src/app/services/pusher.service';
import { BaseComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.scss']
})
export class ActiveUsersComponent extends BaseComponent{

	@Input() chatUsers : IUser[];
	constructor(
    public pusherService:PusherService,
    public userService:UserService,

  ){
    super();
  }
  getTool(user:User)
  {
    let tool:string = user.name;
    if(user.permissions)
    {
      const keys = Object.keys(user.permissions);
      if(keys.length) tool += " - " + keys.join(", ");
    }
    return tool;
  }
  poke(event:MouseEvent, user:User)
  {
    if(event.ctrlKey)
    {
      this.sub = this.userService.poke(user.uuid).subscribe();
    }
  }
}
