<mat-card class="add_project_form">
	<h3>Add Project</h3>
	<form class="login-form">
		<div class="inputs">
			<mat-form-field>
				<input matInput id="name" class="login_input" placeholder="Name" value="">
			</mat-form-field>
		</div>
		<div class="button_wrapper">
			<button (click)="addProject()" mat-raised-button>Add Project</button>
		</div>
	</form>
</mat-card>
<mat-card class="all_projects" *ngIf="projects.length > 0">
	<h3>Projects</h3>
	<mat-list role="list">
		<mat-list-item *ngFor="let project of projects; index as i" role="listitem" class="list-item">
			<div class="project" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
				<p class="name" (click)="openAssignUserPopup(project.uuid)">{{project.name}}</p>
			</div>
		</mat-list-item>
	</mat-list>
</mat-card>