import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FileUtils } from 'src/app/utils/FileUtils';
import { CropWidgetComponent } from '../crop-widget/crop-widget.component';
import { TrimWidgetComponent } from '../trim-widget/trim-widget.component';

/**
 * hmm chek this out:
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API/Recording_a_media_element
 * particularly the "captureStream " function
 * https://www.geeksforgeeks.org/screen-recording-with-mic-audio-using-javascript/
 * 
 * 
 * Future improvements
 * - option to disable capture mouse.
 * - option to capture as gif
 * 
 * Issues
 * - cannot merge mic stream and audio from screen capture on chrome :(
 * 	might be solutions but for now just disable one or other
 * 
 */

@Component({
	selector: 'app-record-screen-widget',
	templateUrl: './record-screen-widget.component.html',
	styleUrls: ['./record-screen-widget.component.scss']
})
export class RecordScreenWidgetComponent implements OnInit, OnDestroy {

	@Input('captureMode') captureMode:boolean = false;
	@Output() onData:EventEmitter<{file:File, metadata?:any}> = new EventEmitter<{file:File, metadata?:any}>();	
	@Output() onRecordStartStop:EventEmitter<string> = new EventEmitter<string>();


	@ViewChildren('video') private videoList: QueryList<ElementRef<HTMLVideoElement>>;

	public preview		: string;

	public video		: ElementRef<HTMLVideoElement>;

	// screen
	public captureStream:MediaStream;
	public capturing:boolean;
	public systemAudio:boolean = false;

	// audio 
	public includeMic:boolean = false;
	public audioStream:MediaStream;
	public listening:boolean = false;

	// recording
	public combinedStream:MediaStream;
	public recording:boolean = false;

	private recordedChunks:Blob[];
	private data:File;
	private videoTrack:MediaStreamTrack;
	private width:number;
	private height:number;
	



	// @ts-ignore
	private mediaRecorder: MediaRecorder;
	
	//state_camera:string = ''
	state_microphone:string = ''
	state_capture:string = ''

	public recordingStartTime	: number;
	public timecode:string = '00:00';

	public trimResult:any;

	// TODO show cropped part of stream only using a canvas to draw the crop to instead of a video

	constructor(private dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) { }
	ngAfterViewInit() {
		this.video = this.videoList.first;
		this.videoList.changes.pipe().subscribe(res => {
			this.video = this.videoList.first;
		})
	}
	ngOnDestroy(): void {
		if(this.preview)
		{
			window.URL.revokeObjectURL(this.preview);
		}
		this.cancel();
	}
	cancel()
	{
		if(this.recording)
		{
			this.stopRecording();
			this.onRecordStartStop.emit("stop");
		}
		if(this.captureStream)
		{
			this.stopStream(this.captureStream);
		}
		if(this.audioStream)
		{
			this.stopStream(this.audioStream);
		}
		if(this.mediaRecorder?.stream)
		{
			this.stopStream(this.mediaRecorder.stream);
		}
	}
	stopStream(stream:MediaStream)
	{
		stream.getTracks().forEach(track => track.stop())
	}
	ngOnInit(): void {	
		
		// check permissions first then request
		//this.permissions();
		//this.capture();
	}
	async start()
	{
		await this.startCapture();
	}
	async permissions()
	{
		
		await this.startCapture();
		return;
		// https://github.com/microsoft/TypeScript/issues/33923
		const statusMicrophone = await navigator.permissions.query({name: 'microphone' as PermissionName});
		this.state_microphone = statusMicrophone.state;
		console.log("permissions mic", statusMicrophone);

		// const statusCamera = await navigator.permissions.query({name: 'camera' as PermissionName});
		// this.state_camera = statusCamera.state;
		// console.log("permissions camera", statusCamera);
		if(this.state_microphone == "granted")	// && this.state_camera == "granted"
		{
			// all good lets go
			this.startCapture();
		}else if(this.state_microphone == "prompt")	//|| this.state_camera == "prompt"
		{
			// all good lets go
			this.startCapture();
		} else {
			// at least one blocked so ask them to reset permissions and reload
		}

		// maybe able to request in code in the future
		//const requestStatus = await navigator.permissions.requestAll([{name: 'camera'},{name: 'microphone'}]).
	}

	changeIncludeMic(e:MatSlideToggleChange = null)
	{
		if(!this.includeMic)
		{
			this.includeMic = true;
			this.startListening();
			return;
		}else{
			this.includeMic = false;
			this.stopStream(this.audioStream);
			this.audioStream = null;	
		}
		this.mergeStreams();
	}
	deleteRecording()
	{
		this.preview = null;
		this.timecode = "00:00";
		this.clearCrop();
	}
	deleteSnapshot()
	{
		this.preview = null;
		this.clearCrop();
	}
	mergeStreams()
	{
		// TODO insure not recoring at this point

		// @ts-ignore - https://github.com/microsoft/TypeScript/issues/34728
		//let audioRecorder = new MediaRecorder(this.audioStream);
		//audioRecorder.start();
		// combine streams
		//const videoTracks = this.captureStream.getVideoTracks();
		if(this.includeMic)
		{
			// @ts-ignore
			this.combinedStream =  new MediaStream([...this.captureStream.getTracks(), ...this.audioStream.getTracks()]);
			// @ts-ignore
			this.mediaRecorder = new MediaRecorder(this.combinedStream);
		} else {
			// @ts-ignore
			this.mediaRecorder = new MediaRecorder(this.captureStream);
		}

		// able to record the combined stream with a MediaRecorder
		// @ts-ignore

		this.mediaRecorder.addEventListener('start', (ev)=>{
				this.recordedChunks = [];
				this.onRecordStartStop.emit("start");
		});

		this.mediaRecorder.addEventListener('stop', (e) => {
		
			// create a blob from the recorded chunks
			const blob = new Blob(this.recordedChunks, { 'type' : 'video/webm' });
			this.preview = window.URL.createObjectURL(blob);

			/*
			this.data = blob;
			this.data['lastModifiedDate'] = (new Date()).toISOString();
			this.data['name'] = 'screen-capture.webm';
			*/
			// convert blob to a "File"
			this.data = FileUtils.blobToFile(blob, 'screen-capture.webm');

			this.onRecordStartStop.emit("stop");
			this.onData.emit({file:this.data});
			//console.log("STOP");
			
		});        
		this.mediaRecorder.addEventListener('dataavailable', (e)  =>{
			this.recordedChunks.push(e.data);
		});

		this.mediaRecorder.stream.getVideoTracks()[0].addEventListener('ended', () => {

			// reset capture state
			this.state_capture = ''
			this.capturing = false;

			// clear the video source object
			if(this.video) this.video.nativeElement.srcObject = null;

			// currently recording, no need to do anything as can be handled by "stop"
			if(!this.recording)
			{
				// clear the streams
				this.cancel();
			} else {
				this.stopRecording();
			}
			// update view
			this.changeDetectorRef.markForCheck();
		});
	}
	startRecording()
	{
		//console.log("startRecording", this.mediaRecorder?.state);
		
		if(this.mediaRecorder?.state == 'inactive')
		{
			this.mediaRecorder.start();
			this.recording = true;

			this.recordingStartTime = Date.now();
			requestAnimationFrame(() => this.updateTimecode());
		}
	}
	stopRecording()
	{
		if(this.mediaRecorder?.state == 'recording')
		{
			this.mediaRecorder.stop();
		}
		this.recording = false;
	}
	startAudio()
	{

	}
	async startCapture()
	{

		//console.log("taking screenshot...");

		/*
			cursor: 'always' | 'motion' | 'never',
			displaySurface: 'application' | 'browser' | 'monitor' | 'window'
		*/
		const gdmOptions:any = {
			video: {
				displaySurface:'browser',
				cursor: "always"
			}//true,
			/*
			audio: {
				echoCancellation: true,
				noiseSuppression: true,
				sampleRate: 44100
				}*/
		};
		let captureAudio = false;
		if(captureAudio)
		{

			gdmOptions.audio = {
				echoCancellation: true,
				noiseSuppression: true,
				sampleRate: 44100,
				suppressLocalAudioPlayback:true,
				} as MediaTrackConstraints;
		}

		this.state_capture = "prompt";

		// https://www.w3.org/TR/mediacapture-region/
		// https://www.w3.org/TR/mediacapture-region/#sample-code
		const croppingFeature = false;
		// auto crop
		let cropTarget;
		if(croppingFeature && "CropTarget" in window)
		{
			const mainContentArea = document.getElementsByClassName('assetStage')[0];
			//console.log("mainContentArea", mainContentArea);
			//@ts-ignore
			cropTarget = await CropTarget.fromElement(mainContentArea);
		}
		
		// get the capture stream
		// @ts-ignore
		//navigator.mediaDevices.getDisplayMedia(gdmOptions).then(async(stream) =>	{
		const stream = await navigator.mediaDevices.getDisplayMedia(gdmOptions);
		if(!stream)
		{
			// user canceled the capture so update state
			this.state_capture = "";
			this.changeDetectorRef.markForCheck();
			return;
		}
			// permission to capture granted
			this.capturing = true;
			this.captureStream = stream;
			this.state_capture = 'granted';

			// set video src to the capture stream
			this.video.nativeElement.srcObject = this.captureStream;

			// capture video track
			this.videoTrack = this.captureStream.getVideoTracks()[0];
			if(croppingFeature && cropTarget)
			{
				// @ts-ignore
				if (!("cropTo" in this.videoTrack)) {//!!this.videoTrack.cropTo
					console.error("cropTo not supported");
					return;
				  }
				  console.log("video track", this.videoTrack);
				  // @ts-ignore
				  console.log("video track cropTo", this.videoTrack.cropTo);

				  // @ts-ignore
				  await this.videoTrack.cropTo(cropTarget);
			}
			let settings = this.videoTrack.getSettings();
			this.width = settings.width;
			this.height = settings.height;

			this.video.nativeElement.addEventListener("resize", (e) => {
				const { videoWidth, videoHeight } = this.video.nativeElement;
				this.width = videoWidth;
				this.height = videoHeight;			  
			}, false);

			//console.info("Track settings:");
			//console.info(JSON.stringify(settings, null, 2));
			//console.info("Track constraints:");
			//console.info(JSON.stringify(this.videoTrack.getConstraints(), null, 2));

			// capture audio track
			this.systemAudio = this.captureStream.getAudioTracks().length > 0;
			//console.log("AUDIO TRACKS", this.captureStream.getAudioTracks());


			// For recording the mic audio			
			if(this.includeMic)
			{
				this.startListening();
			}else{
				this.mergeStreams();
			}

		/*	
		}, (error) => {
			// user canceled the capture so update state
			this.state_capture = "";
			this.changeDetectorRef.markForCheck();
		}).finally(() => {
			// finally capturing completed
			this.changeDetectorRef.markForCheck();
		*/
		this.changeDetectorRef.markForCheck();
	}
	startListening()
	{
		navigator.mediaDevices.getUserMedia({audio: true, video: false })
		.then(stream => {
			this.audioStream = stream;
			this.listening = true;
			this.changeDetectorRef.markForCheck();
			this.mergeStreams();
		})
	}
	// the current recording is being rendered to a video so we can draw that to a canvas to get a snapshot
	snapshot()
	{

		//const canvas = document.createElement("canvas");
		const canvas = document.getElementById("canvas") as HTMLCanvasElement;
		canvas.width = this.width;
		canvas.height = this.height;
		const context = canvas.getContext("2d");

		context.drawImage(this.video.nativeElement, 0, 0);

		//this.preview = canvas.toDataURL('image/jpeg', 1.0);
		canvas.toBlob( blob => {
			this.data = FileUtils.blobToFile(blob, 'screen-capture.jpg');
			this.preview = URL.createObjectURL(blob);
		}, 'image/jpeg', 0.95);
		
		//captureStream.getTracks().forEach(track => track.stop());
		//clear any previous crop (also emits task file data for attachment)
		
		
		// ??? capture complete so give user chance to crop
		setTimeout(()=>{
			this.clearCrop();
		}, 250);
	}
	// trigger the cropping pop-up
	cropResult:any = null;
	crop(type:string)
	{
		const dialogRef = this.dialog.open(CropWidgetComponent,{
			data:{type:type, url: type == 'video' ? this.preview : this.preview}
		});
		dialogRef.afterClosed().subscribe(result => {
			if(result)
			{
				// we have a crop
				this.cropResult = result;
				this.onData.emit({file:this.data, metadata:this.cropResult});
			}
		});
	}
	clearCrop()
	{
		this.cropResult = null;
		this.onData.emit({file:this.data, metadata:null});
	}
	trim()
	{
		// TODO this is on hold until further notice..
		/*
		https://stackoverflow.com/questions/38062864/blob-video-duration-metadata		
		https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
		https://github.com/yusitnikov/fix-webm-duration/blob/dcc740d45a31d51f7c8af272a3a01d7e8a878fdd/fix-webm-duration.js#L417
		https://bugs.chromium.org/p/chromium/issues/detail?id=642012
		*/
		const dialogRef = this.dialog.open(TrimWidgetComponent,{
			data:{type:'video', url:this.preview, timeIn:'00:00'}//, timeOut:
		});
		dialogRef.afterClosed().subscribe(result => {
			if(result)
			{
				// we have a trim
				
			}
		});
	}
	clearTrim()
	{

	}
	updateTimecode()
	{
		if(!this.recording) return;
		let diff = Date.now() - this.recordingStartTime;
		diff *= 1/1000;
		diff |= 0;
		let seconds = diff % 60;
		diff *= 1/60;
		diff |= 0;
		let minutes = diff % 60;
		this.timecode = this.pad(minutes) + ':' + this.pad(seconds);
		this.changeDetectorRef.markForCheck();

		requestAnimationFrame(() => this.updateTimecode());
	}
	pad(value:number):string{
		return value <= 9 ? '0' + value.toString() : value.toString();
	}
}
