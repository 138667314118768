import { Injectable, HostListener } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { take, timeout, skipWhile, filter } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class PostMessageService {

  private messages:Subject<MessageEvent> = new Subject<MessageEvent>();
  public messages$:Observable<MessageEvent> = this.messages.asObservable();

  //@HostListener('message', ['$event'])
  //private onClick(event: MessageEvent) {
  //   console.log("WAAAA", event)
  //}
  constructor()
  {
    fromEvent(window, 'message').subscribe((e:MessageEvent) => {
      const { hostname } = new URL(e.origin);
      if(hostname.indexOf(window.location.hostname) === -1) return;
        this.messages.next(e);
    });
  }
  public async postMessage(target:Window, message:any):Promise<MessageEvent>
  {
    // 1. listen to messages observable...
    // 2. send message

    // 3. wait untill response for up 2 1 second.
    // 4. match response with message via id..

    // 5. on match unsub and continue
    const id = Math.random();
    message.__id = id;
    target.postMessage(message);

    /*
    return this.messages$.pipe(
      //timeout(1000),
      //filter(message => message.data.__id === id)
    ).subscribe(m => {
      console.log("m", m)
    })//.toPromise();
    */
    // for some reason the approach above doesn't work
    return new Promise((resolve, reject) => {
      this.messages$.pipe(
        timeout(333), // expect a response within a time
        filter(message => message.data.__id === id), // only capture target message
        take(1),  // probably not required but will only take 1
      ).subscribe(m => {
        resolve(m);
      }, e => {
        resolve(null);
      })
		});
  }

}
