import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
	MatAutocomplete,
	MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectionListChange } from "@angular/material/list";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { ApprovalGroupService } from "src/app/api/approval-group-service.service";
import { ApprovalChainService } from "src/app/api/approvalChain.service";
import { ProjectService } from "src/app/api/project.service";
import { GenericDialogComponent, GenericDialogData } from "src/app/dialogs/generic-dialog/generic-dialog.component";
import { RemoveApproverComponent, RemoveApproverData } from "src/app/dialogs/remove-approver/remove-approver.component";
import { ApprovalChain } from "src/app/models/ApprovalChain.model";
import { ApprovalGroup, IApprovalGroup } from "src/app/models/ApprovalGroup.model";
import { IChannel } from "src/app/models/channel.model";
import { IFormat } from "src/app/models/format.model";
import { Permission } from "src/app/models/permissions.model";
import { IUser } from "src/app/models/user.model";
import { StringsService } from "src/app/services/strings.service";

@Component({
	selector: "app-project-team",
	templateUrl: "./project-team.component.html",
	styleUrls: ["./project-team.component.scss"],
})
export class ProjectTeamComponent implements OnInit, OnDestroy {
	@Input() project_uuid: string;
	@Input() inPanel: boolean;
	@Input() channels: IChannel[];
	@Input() formats: IFormat[];

	@Output() onProductionUsers:EventEmitter<IUser[]> = new EventEmitter();

	private _unsubscribe = new Subject<boolean>();
	public users: IUser[]; // users associated with this project's workgroup
	public projectUsers: IUser[]; // users associated with this project
	public projectApprovalUsers: IUser[];
	public projectProductionUsers: IUser[];
	public projectAdminUsers: IUser[];
	public chainValidator:IUser;

	/* USER PERMISSIONS*/
	allPermissions: string[] = [
		"access",
		"production",
		"approval",
		"approval_manager",
	];
	filteredPermissions: Observable<string[]>;
	permissionSelectable = true;
	permissionRemovable = true;
	permissionSeparatorKeysCodes: number[] = [ENTER, COMMA];
	permissionAddOnBlur = true;
	permissionCtrl = new FormControl();

	@ViewChild("permissionInput") permissionInput: ElementRef<HTMLInputElement>;
	@ViewChild("auto") matAutocomplete: MatAutocomplete;

	/* chains/groups/roles */
	public chains: ApprovalChain[] = [];
	//public groups: ApprovalGroup[] = [];
	public selectedChain: ApprovalChain;
	public selectedGroup: ApprovalGroup;

	constructor(
		public projectService: ProjectService,
		private approvalChainService: ApprovalChainService,
		private approvalGroupService: ApprovalGroupService,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		public strings: StringsService,
	) { }
	ngOnInit(): void {
		this.permissionsInit();

		this.projectService.approvalChains.subscribe((chains)=>
		{
			if(this.selectedChain)
				this.selectedChain = chains.find(chain => chain.id == this.selectedChain.id);

			if(this.selectedChain)
			{
				this.chainValidator = this.selectedChain["validator"].length ? this.selectedChain["validator"][0] : null;
			}
		});

		this.load();
	}
	ngOnDestroy(): void {
		this._unsubscribe.next(true);
	}

	load(): void {
		this.loadUsers();
		this.loadApprovalChains();
	}
	/* USERS */
	loadUsers() {
		this.projectService
			.getTeam(this.project_uuid)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe((res) => {
				
				// we merge the users from the workgroup with the users that already have access
				// TODO move to backend?
				let currentUsers = res["users"];
				this.users = res["availableUsers"];

				let map = {};
				for (let i = 0; i < currentUsers.length; i++) {
					const currentUser = currentUsers[i];
					map[currentUser.uuid] = currentUser;					
				}	

				for (let i = 0; i < this.users.length; i++) {
					const user = this.users[i];
					const currentUser = map[user.uuid];
					if(currentUser)
					{
						this.users[i] = currentUser;
					}else{
						user.scoped_permissions = 0;
					}
				}				

				this.projectUsers = this.users.filter((user) =>
					user.scoped_permissions & Permission.ACCESS
				);
				this.projectApprovalUsers = this.projectUsers.filter((user) =>
					user.scoped_permissions & Permission.APPROVAL
				);
				this.projectProductionUsers = this.projectUsers.filter((user) =>
					user.scoped_permissions & Permission.PRODUCTION
				);
				this.projectAdminUsers = this.projectUsers.filter((user) =>
					user.scoped_permissions & Permission.ADMIN
				);
				this.onProductionUsers.emit(this.projectProductionUsers);

				this.determineValidator();
			});
	}

	onAvailableUserSelected(e: MatSelectionListChange) {
		const option = e.options[0];
		const user = option.value;
		const selected = option.selected;
		if (selected) this._addUserPermission(Permission.ACCESS, user);
		else this._removeUserPermission(Permission.ACCESS, user);
	}
	onGroupUserSelected(group:ApprovalGroup, e: MatSelectionListChange) {
		const option = e.options[0];
		const user = option.value;
		const selected = option.selected;
		if (selected) this.addUserToGroup(group, user);
		else this.removeUserFromGroup(group, user);
	}
	isAvailableUserSelected(user: any) {
		//return this.users.filter(u => {this["uuid"] == u.uuid}, user).length ? true : false;
		//return this.users.filter(this.userFilter, user).length ? true : false;
		return user.scoped_permissions & Permission.ACCESS;
	}
	isUserInGroup(userIn, group)
	{
		return group.users.find((user) => user.uuid == userIn.uuid);
	}
	isUserInChannel(user, channel)
	{
		// TODO this sucks ass
		let foundUser:any = this.users.find((u:any) => u.uuid == user.uuid);
		return foundUser.channels.find(userChannel => userChannel.id == channel.id);
	}
	isApproverInChannel(user, channel)
	{
		let channels = user.channels;
		if(!channels)
		{
			for (let i = 0; i < this.users.length; i++) {
				if(user.uuid == this.users[i].uuid)
				{
					channels = this.users[i]["channels"];
					break;
				}				
			}
		}
		let foundChannel = channels.find(userChannel => userChannel.id == channel.id);
		if(!foundChannel) return false;
		return foundChannel.permissions & Permission.APPROVAL;
		let foundUser:any = this.users.find((u:any) => u.uuid == user.uuid);
		return user.channels.find(userChannel => userChannel.permissions & Permission.APPROVAL);
	}
	// ensure the user is not in the group already OR any other group in this chain
	isNotInAnyGroup = (userIn, chain) =>
	{
		let found = false;
		let groups = chain.approval_groups;
		for (let i = 0; i < groups.length; i++) {
			const group = groups[i];
			if(group.chain_id != chain.id) continue;
			if(!group?.users) continue;
			if(group.users.find((user) => user.uuid == userIn.uuid))
			{
				found = true;
				break;
			}
		}
		return !found;
	}
	userFilter(currentUser, index) {
		return this["uuid"] == currentUser.uuid;
	}
	removeUserPermission(user, permission) {
		this._removeUserPermission(permission.name, user);
	}
	addUserPermission(user, event) {
		// Add permission
		this._addUserPermission(event.value, user);

		// Reset the input value
		const input = event.input;
		if (input) {
			input.value = "";
		}
	}
	_addUserPermission(permission: number, user) {
		this.snackBar.open("Adding permission from user...", "", {
			duration: 5000,
		});
		this.apiCall(
			this.projectService.grantUserPermission(
				this.project_uuid,
				user.uuid,
				permission
			),
			(value) => {
				this.snackBar.open("Permission added", "", { duration: 2000 });

				this.loadUsers();
			},
			(error) => console.log("error", error)
		);
	}
	_removeUserPermission(permission: number, user) {
		this.snackBar.open("Removing permission from user...", "", {
			duration: 5000,
		});
		this.apiCall(
			this.projectService.removeUserPermission(
				this.project_uuid,
				user.uuid,
				permission
			),
			(value) => {
				this.snackBar.open("Permission removed", "", { duration: 2000 });
				this.loadUsers();
			},
			(error) => console.log("error", error)
		);
	}

	apiCall(
		call: Observable<any>,
		next?: (value: any) => void,
		error?: (error: any) => void,
		complete?: () => void
	) {
		return call.pipe(takeUntil(this._unsubscribe)).subscribe(next, error);
	}
	loadApprovalChains() {

		// this.projectService.loadApprovalChains(this.project_uuid);
		/*
		this.projectService
			.getApprovalChains(this.project_uuid)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe((res) => {
				this.chains = res.data;
			});*/
	}

	permissionsInit() {
		this.filteredPermissions = this.permissionCtrl.valueChanges.pipe(
			startWith(null),
			map((permission: string | null) =>
				permission ? this._filter(permission) : this.allPermissions.slice()
			)
		);
	}
	permissionSelected(user, event: MatAutocompleteSelectedEvent): void {
		// removed as legacy approach
		/*
		this._addUserPermission(event.option.viewValue, user);
		this.permissionInput.nativeElement.value = "";
		this.permissionCtrl.setValue(null);*/
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.allPermissions.filter(
			(fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
		);
	}

	chainAdd() {
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title: "Add Chain",
				negative: "Cancel",
				positive: "Create",
				form: [
					{ name: "name", type: "text", placeholder: "Enter Name", value: "", validator: Validators.required },
					{ name: "filter", type: "text", placeholder: "Target format group", value: "", validator: Validators.required, autoComplete:this.getAllGroups() }
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
				this.snackBar.open("creating approval chain...", '', { duration: 5000 });
				this.projectService.approvalChainCreate(this.project_uuid, result.formGroup.value).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("approval chain created", "", { duration: 2000 });
					this.loadApprovalChains();
				});
			}
		});
	}
	getAllGroups(): string[]{
		if(!this.formats) return [];
		let keys = {"*":1};
		for (let i = 0; i < this.formats.length; i++) {
			const format = this.formats[i];
			keys[format.group] = 1;
		}
		return Object.keys(keys);
	}
	chainSelected(chain: ApprovalChain) {
		this.selectedChain = chain;
		//this.loadApprovalGroups();
		this.determineValidator();		
	}
	trackById(index, item:any)
	{
		return item.id;
	}
	trackByUUID(index, item:any)
	{
		return item.uuid;
	}
	determineValidator()
	{
		// work out the current validator
		this.chainValidator = null;
		if(!this.selectedChain) return;
		for (let i = 0; i < this.projectAdminUsers.length; i++) {
			const user = this.projectAdminUsers[i];
			if(this.isValidator(user, this.selectedChain))
			{
				this.chainValidator = user;
			}
		}
	}
	chainEdit(chain: ApprovalChain, e:Event) {
		e.stopPropagation();
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title: "Edit Chain",
				negative: "Cancel",
				positive: "Save",
				form: [
					{ name: "name", type: "text", placeholder: "Enter Name", field: "name", label: "name", validator: Validators.required },
					{ name: "filter", type: "text", placeholder: "Enter Filter", field: "filter", label: "filter", validator: Validators.required }
				],
				model: chain
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if (result) {
				// TODO could do tghis better with a clone or other approach to avoid modifing the local model
				//let group = result.formGroup.value;
				this.snackBar.open("saving chain...", '', { duration: 5000 });
				this.approvalChainService.update(chain.id.toString(), result.formGroup.value).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("chain saved", "", { duration: 2000 });
					this.loadApprovalChains();
				});
			}
		});
	}
	chainDelete(chain: ApprovalChain, event:Event) {
		event.stopPropagation();
		this.snackBar.open("deleting approval chain...", '', { duration: 5000 });
		this.projectService.approvalChainDelete(this.project_uuid, chain.id).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("approval chain deleted", "", { duration: 2000 });
			this.loadApprovalChains();
		});
	}
	/*
	loadApprovalGroups() {
		this.groups = null;
		this.approvalChainService.approvalGroupIndex(this.selectedChain.id).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.groups = res.data;
		});
	}*/
	onSelectGroup(e: MatSelectionListChange) {
		this.selectedGroup = e.options[0].value;
	}
	addGroup(chain, index:number) {
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title: "Add Group",
				negative: "Cancel",
				positive: "Create",
				form: [
					{ name: "name", type: "text", placeholder: "Enter Name", value: "", validator: Validators.required }
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
				let approvalGroup:IApprovalGroup = result.formGroup.value;
				approvalGroup.order = index;
				this.snackBar.open("creating group...", '', { duration: 5000 });
				this.approvalChainService.approvalGroupCreate(chain.id, approvalGroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("group created", "", { duration: 2000 });
					//this.loadApprovalGroups();
					this.loadApprovalChains();
				});
			}
		});
	}
	decreaseGroupOrder(group: ApprovalGroup): void {
		// TODO should tbis be a clone
		group.order--;
		this.snackBar.open("saving group...", '', { duration: 5000 });
		this.approvalGroupService.update(group.id.toString(), group).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("group saved", "", { duration: 2000 });
			//this.loadApprovalGroups();
			this.loadApprovalChains();
		});
	}
	increaseGroupOrder(group: ApprovalGroup): void {
		// TODO should tbis be a clone
		group.order++;
		this.snackBar.open("saving group...", '', { duration: 5000 });
		this.approvalGroupService.update(group.id.toString(), group).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("group saved", "", { duration: 2000 });
			//this.loadApprovalGroups();
			this.loadApprovalChains();
		});
	}
	editGroup(group: ApprovalGroup): void {
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title: "Edit Group",
				negative: "Cancel",
				positive: "Save",
				form: [
					{ name: "name", type: "text", placeholder: "Enter Name", field: "name", label: "name", validator: Validators.required },
					//{ name: "order", type: "text", placeholder: "Enter Order", field: "order", label: "order", validator: Validators.required }
				],
				model: group
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if (result) {
				let updatedGroup = result.formGroup.value;
				this.snackBar.open("saving group...", '', { duration: 5000 });
				this.approvalGroupService.update(group.id.toString(), updatedGroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("group saved", "", { duration: 2000 });
					//this.loadApprovalGroups();
					this.loadApprovalChains();
				});
			}
		});
	}
	deleteGroup(group: ApprovalGroup): void {
		this.snackBar.open("deleting group...", '', { duration: 5000 });
		this.approvalGroupService.delete(group.id.toString()).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("group deleted", "", { duration: 2000 });
			//this.loadApprovalGroups();
			this.loadApprovalChains();
		});
	}
	setUserValidator(user, chain)
	{
		this.snackBar.open("adding user as validator", "", {duration:5000});
			this.approvalChainService.setValidator(chain.id, user).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("user added as validator", "", {duration:2000});
			this.loadUsers();
		});
	}
	setUserValidator2(event:MatSelectChange, chain:ApprovalChain)
	{
		let user = event.value;
		let validator = chain["validator"];
		if(validator.length && validator[0].uuid == this.chainValidator.uuid)
		{
			this.snackBar.open("removing user as validator", "", {duration:5000});
			this.approvalChainService.removeValidator(this.selectedChain.id, this.chainValidator).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("user removed as validator", "", {duration:2000});
				this.load();
			});
		}else{
			this.snackBar.open("adding user as validator", "", {duration:5000});
			this.approvalChainService.setValidator(this.selectedChain.id, user).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("user added as validator", "", {duration:2000});
				this.load();
			});
		}

	}
	removeUserValidator(user, chain)
	{
		this.snackBar.open("removing user as validator", "", {duration:5000});
		this.approvalChainService.removeValidator(chain.id, user).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
		  this.snackBar.open("user removed as validator", "", {duration:2000});
		  this.loadUsers();
	  });
	}
	addUserToGroup(group, user)
	{
		this.snackBar.open("adding user to group", "", {duration:5000});
			this.approvalGroupService.grant(group.id, Permission.ACCESS, user).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("user added", "", {duration:2000});
			//this.loadApprovalGroups();
			this.loadApprovalChains();
		});
	}
	removeUserFromGroup(group, user)
	{

		this.snackBar.open("removing user from group", "", {duration:5000});
		this.approvalGroupService.revoke(group.id, Permission.ACCESS, user).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
		  this.snackBar.open("user removed", "", {duration:2000});
		  //this.loadApprovalGroups();
		this.loadApprovalChains();
	  });
	}

	//
	setAdmin(user)
	{
		this._addUserPermission(Permission.ADMIN, user);
	}
	setProduction(user)
	{
		this._addUserPermission(Permission.PRODUCTION, user);
	}
	setApprover(user)
	{
		this._addUserPermission(Permission.APPROVAL, user);
	}
	removeAdmin(user)
	{
		this._removeUserPermission(Permission.ADMIN, user);
	}
	removeProduction(user)
	{
		this._removeUserPermission(Permission.PRODUCTION, user);
	}
	removeApprover(user)
	{
		this._removeUserPermission(Permission.APPROVAL, user);
	}
	/*
	setValidator(user)
	{
		this._addUserPermission(Permission.VALIDATOR, user);
	}
	removeValidator(user)
	{
		this._removeUserPermission(Permission.VALIDATOR, user);
	}*/
	// user filters (arrow notation to keep this scope)
	isAdmin = (user):boolean => {
		return (user.scoped_permissions & Permission.LOOKUP["admin"]) != 0;
	}
	isNotAdmin = (user):boolean => {
		return (user.scoped_permissions & Permission.LOOKUP["admin"]) == 0;
	}
	isProduction = (user):boolean => {
		return (user.scoped_permissions & Permission.LOOKUP["production"]) != 0;
	}
	isNotProduction = (user):boolean => {
		return (user.scoped_permissions & Permission.LOOKUP["production"]) == 0;
	}
	isApprover = (user):boolean => {
		return (user.scoped_permissions & Permission.LOOKUP["approval"]) != 0;
	}
	isNotApprover = (user):boolean => {
		return (user.scoped_permissions & Permission.LOOKUP["approval"]) == 0;
	}
	isValidator = (user, chain):boolean => {
		if(!user.validatorFor) return false;
		for (let i = 0; i < user.validatorFor.length; i++) {
			if(user.validatorFor[i].id == chain.id) return true;
		}
		return false;
	}
	isNotValidator = (user, chain):boolean => {
		if(!this.isAdmin(user)) return false;
		for (let i = 0; i < user.validatorFor.length; i++) {
			if(user.validatorFor[i].id == chain.id) return false;
		}
		return true;
	}
	
	hasAccessToChannel(user:any, channel:IChannel, permission:string)
	{
		let channels = user.channels;
		if(!channels)
		{
			for (let i = 0; i < this.users.length; i++) {
				if(user.uuid == this.users[i].uuid)
				{
					channels = this.users[i]["channels"];
					break;
				}				
			}
		}
		let result = channels.find(chan => chan.id == channel.id && chan.permissions & Permission.LOOKUP[permission]);
		return result ? true : false;
	}
	hasAccessToAllChannels(user:any, permission:string)
	{
		let channels = user.channels;
		if(!channels)
		{
			for (let i = 0; i < this.users.length; i++) {
				if(user.uuid == this.users[i].uuid)
				{
					channels = this.users[i]["channels"];
					break;
				}				
			}
		}
		if(channels.length != this.channels.length) return false;
		let perm:number = Permission.LOOKUP[permission];
		for (let i = 0; i < channels.length; i++) {
			const channel = channels[i];
			if((channel.permissions & perm) == 0) return false;
		}
		return true;
	}
	toggleAccessToChannel(event:MatCheckboxChange, user:IUser, channel:IChannel, permission:string)
	{
		if(event.checked)
		{
			this.snackBar.open("granting access to channel", "", { duration: 4000 });
			this.projectService.grantUserChannel(this.project_uuid, user.uuid, channel.id.toString(), permission).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("access granted", "", { duration: 2000 });
				this.loadUsers();				
			});
		}else{
			
			this.dialog.open(RemoveApproverComponent, {
				data:{project_uuid:this.project_uuid, user_name:user.name, user_uuid:user.uuid, channels:[channel.id]}
			});
			/*
			this.snackBar.open("removing access to channel", "", { duration: 4000 });
			this.projectService.revokeUserChannel(this.project_uuid, user.uuid, channel.id.toString(), permission).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("access removed", "", { duration: 2000 });
				this.loadUsers();				
			});*/
		}
	}
	toggleAccessToAllChannels(event:MatCheckboxChange, user:IUser, permission:string)
	{
		if(event.checked)
		{
			this.snackBar.open("granting access to channel", "", { duration: 4000 });
			this.projectService.grantUserAllChannels(this.project_uuid, user.uuid, permission).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("access granted", "", { duration: 2000 });
				this.loadUsers();				
			});
		}else{
			this.snackBar.open("removing access to channel", "", { duration: 4000 });
			this.projectService.revokeUserAllChannels(this.project_uuid, user.uuid, permission).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("access removed", "", { duration: 2000 });
				this.loadUsers();				
			});
		}
	}

}
