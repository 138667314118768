import { Observable, Subject } from "rxjs";
import { Point } from "../utils/Point";
import { AssetComponent } from "../views/prototypes/assets/asset/asset.component";
import { IAsset } from "./asset.model";
import { ITask } from "./task.model";

/*
export interface IMarkerVO
{
	task:ITask;
	marker:IMarker;
	active:boolean;
	editable:boolean;
}
*/
export class MarkerVO
{
	private _changed:Subject<boolean> = new Subject<boolean>();
	public changed$:Observable<boolean> = this._changed.asObservable();
	public editable:boolean = false;

	public offsetX:number = 0;
	public offsetY:number = 0;
	public scale: number = 1;
	public visible:boolean = true;

	constructor(public task:ITask, public marker:IMarker, public active:boolean)
	{
	}
	public markChanged()
	{
		this._changed.next(true);
	}
}

/*
asset_uuid
created_at
id
metadata
page
task_uuid
type
updated_at
uuid
x0
y0
*/
export interface IMarker {
	id: number;
	uuid: string;
	task_uuid: string;
	asset_uuid: string;
	
	// time stamps
	created_at : string;
	updated_at : string;
	
	type: string;
	// TODO just x/y?
	x0: number;
	y0: number;
	offsetX:number;
	offsetY:number;
	scale: number;

	visible:boolean;
	timeIn:number;
	timeOut:number;

	metadata:any;
}
export class Marker implements IMarker{

	static idBase:number = -1;
	public id: number;
	public uuid: string;
	public task_uuid: string;
	public asset_uuid: string;
	
	// time stamps
	public created_at : string;
	public updated_at : string;
	
	public type: string;
	public x0: number;
	public y0: number;
	offsetX:number = 0;
	offsetY:number = 0;
	scale:number = 1;
	
	visible: boolean;
	timeIn: number;
	timeOut: number;
	metadata:any;

	constructor(){
		// careful...
		this.id = Marker.getTempId();
	}
	static getTempId()
	{
		return Marker.idBase--;
	}
}
export class MarkerOption {
	public static TYPE_DELETE:string = 'delete';
	public static TYPE_GENERAL:string = 'general';
	public static TYPE_MARKER:string = 'marker';
	public static TYPE_TARGET:string = 'target';
	public static TYPE_TEXT_CHANGE:string = 'text_change';
	public static TYPE_TEXT_DELETE:string = 'text_delete';
	public static TYPE_MARKER_HIDDEN: string = 'hidden';

	public static ICON_DELETE:string = 'delete';
	public static ICON_GENERAL:string = 'chat';
	public static ICON_MARKER:string = 'place';
	public static ICON_TIME:string = 'more_time';
	public static ICON_MARKER_TIME:string = 'place';	//'schedule_send';
	public static ICON_TEXT_CHANGE:string = 'border_color';
	public static ICON_TEXT_DELETE:string = 'strikethrough_s';
	public static ICON_ATTACH:string = 'play_for_work';



	public assetComponent:AssetComponent;
	public position:Point;

	//public disabled:boolean = false;
	public task:ITask;

	constructor(public label:string, public icon:string, public type:string, public data:any = null, public disabled:boolean = false) {
		
	}
}