import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-asset-list-import',
  templateUrl: './asset-list-import.component.html',
  styleUrls: ['./asset-list-import.component.scss']
})
export class AssetListImportComponent implements OnInit {

  public value = null;
  constructor(
    private snackBar:MatSnackBar,
    public dialogRef: MatDialogRef<AssetListImportComponent>,
  ) { }

  ngOnInit(): void {
  }
  import() {
    let data = JSON.parse(this.value);
    //console.log("importing", data);
    this.dialogRef.close(data);
  }
  // https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string
  valid():boolean
  {
    if(!this.value || this.value == '') return false;
    try {
      var result = JSON.parse(this.value);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object", 
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (result && typeof result === "object") {
          return true;
      }
    }
    catch (e) { }
    return false;
  }
}
