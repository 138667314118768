
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Crud2Service } from './crud2.service';

export class ApiResponse<T>{
	public status : number;
	public message : string;
	public error : string;
	public data: T[];
	public request: any;
}

@Injectable({
	providedIn: 'root'
})
export abstract class OVBaseService<T, ID> extends Crud2Service<T, ApiResponse<T>, ID> {
	constructor(protected http: HttpClient, base: string) {
		super(http, base);
	}
}
