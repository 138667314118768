import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { AccountService } from 'src/app/services/accounts.service';
import { IAccount } from 'src/app/models/account.model';

@Component({
  selector: 'app-admin-accounts',
  templateUrl: './admin-accounts.component.html',
  styleUrls: ['./admin-accounts.component.scss']
})
export class AdminAccountsComponent implements OnInit {
  private _unsubscribe = new Subject<boolean>();
  public search:string;
  public account:IAccount;
  constructor(
    public accountService:AccountService,
    public dialog:MatDialog,
    public snackBar:MatSnackBar,
    ) { }

  ngOnInit(): void {
    this.accountService.account$.pipe().subscribe(account => this.account = account);
    this.loadAccounts();
  }
  ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
  loadAccounts()
  {
    this.accountService.loadAccounts();    
  }
  createAccount()
  {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Create new Account",
				negative:"Cancel",
				positive:"Create",
				form:[
					{ name:"name", type:"text", placeholder:"Account name (min. 2 characters)", value:"", validator:Validators.required},
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				let value = result.formGroup.value;
        let account:IAccount = {name:value.name};
				//this.projectService.createFormat(this.project_uuid, value.workflow.id, value.name, value.grou

				//this.workflowService.createChannel(value.workflow.id, value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("creating account", "", {duration:2000});
				this.accountService.create(account).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("created", "", {duration:2000});					
					this.loadAccounts();
				}, error => {
					this.snackBar.open("error creating account", "", {duration:2000, panelClass:'snackBar-error'});
				});
			}
		});
  }
  selected(account:IAccount)
  {
    //this.selectedAccount = account;
    this.accountService.loadAccount(account.uuid);
  }
  edit(account:IAccount)
  {
    //console.log("edit account", account);    
  }
  delete(account:IAccount)
  {
	  // gather uuids and send them off to update their labels

	  let bodyText =  "<p style='color:#940d00;'><i><b>Warning this cannot be undone!</b><br/>All data, including files and amends for the selected account will be deleted.</i></p><br/><b>Are you sure you wish to delete account: "+account.name+"?</b><br/><br/>";
	  const dialogRef = this.dialog.open(GenericDialogComponent, {
		  data: {title:"Confirm delete", body:bodyText, positive:"Delete", negative:"Cancel",
			  form:[
				  {name:"confirm", placeholder:"Enter 'DELETE' (in CAPS) to confirm", type:"text", validator: [Validators.required, Validators.pattern(/DELETE/)] }
			  ],}
	  });
	  dialogRef.afterClosed().subscribe((result: any) => {
		  if(result)
		  {
			  this.snackBar.open("deleting selected account...", '', {duration:5000});
        this.accountService.delete(account.uuid).subscribe(res => {
          if(res.error)
          {
            this.snackBar.open(res.error, "", {duration:5000, panelClass:'snackBar-error'});
          } else {
            this.snackBar.open("account deleted", '', {duration:2000});
            this.account = null;
            this.loadAccounts();
          }
        }, (error) => {
				  this.snackBar.open("failed to delete", "", {duration:5000, panelClass:'snackBar-error'});
			  });
		  }else{
				// popup closed with no data
		  }
	  });
  }
}
