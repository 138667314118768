import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../api/api.service';

@Injectable()
export class AuthService {

	constructor(public router: Router, private api:ApiService) {}
	
	private loggedIn = new BehaviorSubject<boolean>(true); // {1}
	
	public userIsLoggedIn = false;
	
	public setLoggedIn(token:any =null){
		this.userIsLoggedIn = false;
		if(this.validateToken()){
			this.userIsLoggedIn = true;
		}
		return this.userIsLoggedIn;
	}
	
	get isAuthenticated() {
		this.loggedIn.next(this.validateToken());
		return this.loggedIn.asObservable();
	}
  
	private validateToken(){
		var token:any = this.api.getToken();
		if(token){
			return true;
		}else{
			return false;
		}
	}
  
	public logout(reload:any = true): boolean {
		
		sessionStorage.clear();
		
		this.loggedIn.next(false);
		if(reload){
			this.router.navigate(['/']);
			setTimeout(() => {
				window.location.reload();
			}, 300);
		}
		return true;
	}
  
}