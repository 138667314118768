/**
 * https://github.com/AndrewPoyntz/time-ago-pipe/blob/master/time-ago.pipe.ts
 * https://stackoverflow.com/questions/61341891/is-there-are-angular-date-ago-pipe
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateAgo',
	pure: true
})
export class DateAgoPipe implements PipeTransform {

	transform(value: string | number | Date, ...args: unknown[]): unknown {
		if(value)
		{
			var from = new Date(value);
			var to = new Date();
			var diff = to.getTime() - from.getTime();
	 

			var diff_seconds = diff / 1000;
			var diff_minutes, diff_hours, diff_days;


			if(diff_seconds < 60) return "just now";
			else if((diff_minutes = (diff_seconds / 60) | 0) < 60)  return diff_minutes + "m";// (diff_minutes > 1 ? " mins" : " min");
			else if((diff_hours = (diff_minutes / 60) | 0) < 24)    return diff_hours + "h";//(diff_hours > 1 ? " hours" : " hour");
			else if((diff_days = (diff_hours / 24) | 0) <= 28)     return diff_days + "d";//(diff_days > 1 ? " days" : " day");



			var years = to.getFullYear() - from.getFullYear();
			if(years > 0) return years + "y";//(years > 1 ? " years" : " year");
			var months = (to.getMonth() - from.getMonth()) + (12 * years);
			var days = to.getDate() - from.getDate();
			if(days < 0) months--;
			if(months > 0) return months + "M";//(months > 1 ? " months" : " month");
			if(months == 0) return diff_days + (diff_days > 1 ? " days" : " day");
		}
		return '';
	}
}
