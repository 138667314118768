import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs-compat/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { CreativeService } from 'src/app/api/creative.service';
import { GenericDialogComponent, GenericDialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { PackageSaveValidationDialogComponent } from 'src/app/dialogs/package-save-validation-dialog/package-save-validation-dialog.component';
import { Creative, CreativeState } from 'src/app/models/creative.model';
import { DeliveryFolderOptions, DeliveryPackage } from 'src/app/models/deliveryPackage.model';
import { IWorkflow } from 'src/app/models/workflow.model';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
/**
 * selected package might need to be emitted
 * same with saving
 */
export class PackagesComponent implements OnInit {
	@Input() packages : DeliveryPackage[];
	@Input() selectedPackage:DeliveryPackage;
	@Input() workflows : IWorkflow[];
	@Input() type : string; // 'delivery' or 'share'
	@Input() saving:boolean;

	@Output() packageSelected:EventEmitter<DeliveryPackage> =  new EventEmitter<DeliveryPackage>();
	@Output() newPackage:EventEmitter<DeliveryPackage> =  new EventEmitter<DeliveryPackage>();
	@Output() save:EventEmitter<DeliveryPackage[]> =  new EventEmitter<DeliveryPackage[]>();
	@Output() delete:EventEmitter<DeliveryPackage[]> =  new EventEmitter<DeliveryPackage[]>();
	@Output() packageChanged:EventEmitter<null> =  new EventEmitter<null>();


	usePasscode:boolean;
	search:string;
	newPackages:DeliveryPackage[];
	packageForm = new FormGroup({
		name: new FormControl('', [Validators.required]),
		actioned: new FormControl('', [Validators.required]),
		partialAllowed: new FormControl('', [Validators.required]),
		passcode: new FormControl('', [Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')])	 
	 });
	passcodeState: number;
	noteState: number;
	//passcodeStateLookup: any[] = [];
	//noteStateLookup: any[] = [];
	deliveryPackageApprovalsLookup: any[];
	delivOption: {label, actioned, partial};
	folderOption: {label, value} = {label: "not set", value:0};
	
	deliveryDownloadOptions = [
		{label: "Only when all items approved", actioned: true, partial: false},
		{label: "For any approved items", actioned: true, partial: true},
		{label: "Never", actioned: false, partial: false},
	]

	deliveryFolderOptions = [
		{label: "Flatten", value: DeliveryFolderOptions.FLAT},
		{label: "Channel / format", value: DeliveryFolderOptions.CHANNEL},
		{label: "Format / channel", value: DeliveryFolderOptions.FORMAT},
	]

	newPackageName: string;
	_unsubscribe = new Subject<boolean>();
	passcodeArr: string[];
	partpasscode1: string;

constructor(public snackBar:MatSnackBar,private dialog: MatDialog, private creativeService: CreativeService,) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges = null): void {
	//console.log("on change!", changes);
	if((changes.packages || changes.workflows)) 
	{
		this.analysePackageApprovals();

		if(changes.packages){
			//
		}

	}
	if(changes.selectedPackage){
		if(!this.selectedPackage) return;
		this.setDelivOption();
		this.setFolderOption();
	}
	//

  }
  createNewPackage(_copyPackage:DeliveryPackage = null)
  {
		let title = ((_copyPackage) ? 'Copy' : 'New') + ` ${this.type} package`;
		let name = (_copyPackage) ? _copyPackage.name + ' copy' : '';
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title: title,
				negative: "Cancel",
				positive: "Create",
				form:[
					{ name:"name", type:"text", placeholder:"Enter Name", value: name, validator:Validators.required},
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				if(!this.packageNameExists(result.formGroup.value.name)){
					if(_copyPackage){
						this.deliveryPackageCopy(_copyPackage, result.formGroup.value.name);
					} else {
						this.addNewPackage(result.formGroup.value.name);
					}
				} else {
					this.snackBar.open("Package name already exists", "", {duration:4000, panelClass:'snackBar-error'});
					this.createNewPackage(_copyPackage);
				}
			
			}else{
				// popup closed with no data
			}
		});
  }
  addNewPackage(name:string = null)
	{
		//console.log("adding package..."+this.type);
		let newPackage = new DeliveryPackage();
		newPackage.name = name || `New ${this.type} package`;
		newPackage.type = this.type;
		newPackage.passcode = '';
		newPackage.notes = '';
		newPackage.actioned = (this.type == 'share') ? true : false;
		newPackage.partialAllowed = false;
		newPackage.delivery_items = [];
		newPackage.delivery_items_add = [];
		newPackage.delivery_items_remove = [];
		newPackage.uuid = DeliveryPackage.getTempUuid();
		//console.log("packages comp - new package",newPackage.uuid);

		if(this.type == 'delivery'){
			newPackage.folderOption = DeliveryFolderOptions.CHANNEL;
		}
		/*
		if(type == 'delivery')
		{
			this.deliveryPackages.push(newPackage);
			this.deliveryPackages = [...this.deliveryPackages];
		}else if(type == 'share')
		{
			this.sharePackages.push(newPackage);
			this.sharePackages = [...this.sharePackages];
		}*/
		//this.newPackageName = newPackage.name;
		this.newPackage.emit(newPackage);
		//this.deliveryPackageSelected(newPackage);

		this.save.emit([newPackage]);
		//this.deliveryPackageSelected(newPackage);
	}

	packageNameExists(nameToCheck:string){
		return this.packages.find(_package => _package.name == nameToCheck) ? true : false;
	}

	isNewPackage(selectedPackage:DeliveryPackage){
		return (!this.saving && DeliveryPackage.isNew(selectedPackage));
	}

  deliveryPackageSelected(selectedPackage:DeliveryPackage):void {
	if(selectedPackage)	this.partpasscode1 = selectedPackage.passcode ? selectedPackage.passcode.charAt(0) : null;
    /*
	for(let p=0;p<this.packages.length;p++){
		let _package = this.packages[p];
		if(this.passcodeStateLookup[_package.uuid] == undefined)
		{
			let stateNum = this.hasPasscode(_package) ? 1 : 0
			this.recordStateInLookup(this.passcodeStateLookup,_package,stateNum);
		}
		if(this.noteStateLookup[_package.uuid] == undefined)
		{
			let stateNum = this.hasNotes(_package) ? 1 : 0
			this.recordStateInLookup(this.noteStateLookup,_package,stateNum);
		}	
	}
	
	if(selectedPackage)
	{
		this.passcodeState = this.passcodeStateLookup[selectedPackage.uuid]; 
		this.noteState = this.noteStateLookup[selectedPackage.uuid];
	}
	*/

	//this.selectedPackage update handled by @Input via ngOnChanges using the emitter below
	this.packageSelected.emit(selectedPackage);
    //this.selectedPackage = this.selectedPackage == _package ? null : _package;
	//console.log("selectedPackage",this.selectedPackage);
  }

  savePackages()
  {
	let valErrors = this.getValidationErrors();
	if(valErrors){
		this.showValidationDialog(valErrors);
	} else {
		this.save.emit(this.packages);
		//this.packageUpdated();
	}
  }
  deliveryPackageDelete(_package:DeliveryPackage)
  {
	  /*this.packages.forEach((deliveryPackage,index) => {
		  if(deliveryPackage == _package) {
			  this.packages.splice(index,1);
			  
			}
		});
		*/
		this.delete.emit([_package]);	
	}
  
  deleteAllPackages() {
	this.delete.emit(this.packages);
	this.deliveryPackageSelected(null);
  }
  deliveryPackageCopy(_package:DeliveryPackage, name:string)
  {
	// only existing ones? or only in orginal form not with changes...
	// i.e. only copy 
	let copy = new DeliveryPackage();
	copy.name = name;
	copy.type = _package.type;
	copy.passcode = '';	// shoud we copy the passcode?
	copy.delivery_items = [];
	copy.delivery_items_add = [];
	copy.delivery_items_remove = [];
	copy.actioned = false;
	copy.partialAllowed = _package.partialAllowed;
	// TODO strip off the to remove
	_package.delivery_items.forEach(delivery_item => {
		copy.delivery_items_add.push(delivery_item.creative_uuid)
	});
	this.newPackage.emit(copy);
	this.save.emit(this.packages);
  }
  deliveryPackageLink(_package:DeliveryPackage){
	let domain =  window.location.hostname;
	if(domain == 'localhost') domain += ':' + window.location.port;
 	let href = window.location.protocol + '//' + domain + '/' + _package.type +'/' + _package.uuid;
	window.open(href, (_package.type + '_' + _package.uuid) );
  }
  needsSave()
	{
		// no deliveryPackages
		if(!this.packages || !this.packages.length || this.saving) return false;

		// any new deliveryPackages?
		for (let i = 0; i < this.packages.length; i++) {
			const deliveryPackage = this.packages[i];
			//if(!deliveryPackage.uuid) return true; //now adding a temp uuid on new packages -> check for reference instead
			if(!deliveryPackage.reference){
				return true;
			} 			
		}

		// any changed deliveryPackages
		let anyDirty = false;
		for (let i = 0; i < this.packages.length; i++) {
			const deliveryPackage = this.packages[i];
			if(DeliveryPackage.isDirty(deliveryPackage) || deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length){
				anyDirty = true;
				break;
			}
		}
		return anyDirty;
	}
  hasChanged(property:string, deliveryPackage:DeliveryPackage = null)
	{
		if(!deliveryPackage) deliveryPackage = this.selectedPackage;
		if(property == "creatives")	//delivery_items
		{
			/*
			let uuids = this.getDeliveryPackageCreativeUUIDS();
			let selectedUuids = this.getSelectedCreatives();
			return !this.areArraysSame(uuids, selectedUuids);*/
			return deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length;
		}
		else{
			if(!deliveryPackage.reference) return false;		// no reference so never been saved before
			return DeliveryPackage.isPropDifferent(deliveryPackage, property);
		}
	}
  revertAllPackages()
	{
		let currentPackage = this.selectedPackage;

		// reset packages
		this.packages.forEach(deliveryPackage => {
			deliveryPackage.delivery_items_add.length = 0;
			deliveryPackage.delivery_items_remove.length = 0;

			//DeliveryPackage.resetProp(deliveryPackage, 'name');
			for(var prop in deliveryPackage.reference){
				deliveryPackage[prop] = deliveryPackage.reference[prop];
			}
			//trigger parent to deal with selections and package props
			this.deliveryPackageSelected(deliveryPackage);
		});
		
		//re-select the current package
		this.deliveryPackageSelected(currentPackage);
		/*
		// reset creatives
		this.workflows.forEach(workflow => {
			workflow.creatives.forEach(creative => {
				creative.package = 0;
			});
		});
		*/
	}
  copyDeliveryLink()
	{
		this.copyTextToClipboard(window.origin + "/delivery/" + this.selectedPackage.uuid);
		let snack_msg = (this.type == "delivery" ? 'Delivery' : 'Share') + " link copied to clipboard";
		this.snackBar.open(snack_msg, null, {duration: 2000});
	}
	copyPasscode()
	{
		this.copyTextToClipboard(this.selectedPackage.passcode);
		let snack_msg = (this.type == "delivery" ? 'Delivery' : 'Share') + " passcode copied to clipboard";
		this.snackBar.open(snack_msg, null, {duration: 2000});
	}
	randomPasscode()
	{
		var passcode = "";
		var possible = "0123456789";
		for (var i = 0; i < 6; i++) {
			passcode += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		this.selectedPackage.passcode = passcode;
	}

  getDeliveryPackageLabel(deliveryPackage:DeliveryPackage):string
	{
		return deliveryPackage.name;
	}

	getDeliveryPackageNumItems(deliveryPackage:DeliveryPackage):number
	{
		return (deliveryPackage.delivery_items.length + deliveryPackage.delivery_items_add.length - deliveryPackage.delivery_items_remove.length);
	}
	getPackageStyle(deliveryPackage:DeliveryPackage)
	{
		//TODO - this is bogus (hack to force a style using generic list component)
		if( DeliveryPackage.isNew(deliveryPackage) || DeliveryPackage.isDirty(deliveryPackage) || deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length ){
			return ({color: '#ba2400'});
		} else return ({});
	}

   copyLinkToClipboard(link:HTMLLinkElement)
	{
		//const link = e.target.parentElement.querySelector("a");
		const range = document.createRange();
		range.selectNode(link);
		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
		const successful = document.execCommand('copy');
		selection.removeAllRanges();
		//this.main.endSaveNotification("Link copied.");

		
	}
  deliveryPackageNameChange(e)
	{
		e.stopPropagation();
		e.stopImmediatePropagation();
		// TODO if name is no longer in original position then sort
		this.packages = [...this.packages];
		this.packageUpdated()
		
		//this.deliveryPackagesList.cdr.detectChanges();
	}
	copyTextToClipboard(text)
	{
		
		navigator.clipboard.writeText(text).then().catch(e => console.log(e));
		/*
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);*/
	}
  	// TODO be able to pass in a target
	resetValue(property:string)
	{
		if(property == 'creatives')
		{
			this.selectedPackage.delivery_items_add.length = 0;
			this.selectedPackage.delivery_items_remove.length = 0;

			this.workflows.forEach(workflow => {
				workflow.creatives.forEach(creative => {
					// TODO this is slow use lookup
					creative.package = this.selectedPackage.delivery_items.find(deliveryItem => deliveryItem.creative_uuid == creative.uuid) ? 1: 0;
				});
			});
		}else{
			DeliveryPackage.resetProp(this.selectedPackage, property);
		}

		if(property == 'passcode' && (this.passcodeState <=0 || (this.passcodeState == 1 && !this.hasPasscode(this.selectedPackage)))) this.passcodeState = this.hasPasscode(this.selectedPackage) ? 1 : 0; 
		if(property == 'notes' && (this.noteState <=0 || (this.noteState == 1 && !this.hasNotes(this.selectedPackage)))) this.noteState = this.hasNotes(this.selectedPackage) ? 1 : 0; 
		this.packageUpdated();
	}

	getPackageCreativeList(selPackage:DeliveryPackage) {
		//console.log("added to selected package", selPackage);
		// TODO speed up with creative_uuid lookup
		let creatives = [];
		let creativeLookup = {};
		this.workflows.forEach(workflow => {
			workflow.creatives?.forEach(creative => creativeLookup[creative.uuid] = creative);
		});
		selPackage.delivery_items.forEach(deliveryItem => {
			// add creatives that are not in the remove list
			if(!selPackage.delivery_items_remove.find(item => item == deliveryItem.creative_uuid))
			{
				creatives.push(creativeLookup[deliveryItem.creative_uuid]);
			}
		});
		selPackage.delivery_items_add.forEach(creative_uuid => {
			creatives.push(creativeLookup[creative_uuid]);
		});
		return creatives;
	}

	getPackageCreativeInfo(selPackage:DeliveryPackage){
		let numCreativesApproved = 0;
		let creatives = this.getPackageCreativeList(selPackage);
		creatives.forEach(creative => {
			if(creative?.state == CreativeState.DONE) numCreativesApproved++;
		});
		return 	numCreativesApproved;
	}

	analysePackageApprovals(){
		if(!this.packages || !this.workflows || this.type != 'delivery') return;
		this.deliveryPackageApprovalsLookup = [];
		this.packages.forEach(_package => {

			this.deliveryPackageApprovalsLookup[_package.uuid] = this.getPackageCreativeInfo(_package);
	
		});

		//console.log("package approvals",this.deliveryPackageApprovalsLookup);
	}

	//TODO - this needs to be stored on the package so front-end doesnt need to keep calling this function
	getDeliveryStatus = (item:DeliveryPackage) =>
	{
		
		if(!this.deliveryPackageApprovalsLookup) return "";
		let count = this.deliveryPackageApprovalsLookup[item.uuid];
		let total = item.delivery_items.length + item.delivery_items_add.length - item.delivery_items_remove.length;
		let status = DeliveryPackage.getStatus(item, count, total);
		let statusObj:any = {
			icon:DeliveryPackage.getIcon(status, total),
			class:DeliveryPackage.getClass(status),
			approvalMsg:DeliveryPackage.getApprovalMessage(status, total, count),
			downloadMsg:DeliveryPackage.getDownloadMessage(status, total, count, item.partialAllowed),
			warning:status == "actioned_notAllApproved" ? true : false
		};
		return statusObj;	
	}

	updateDownloadOption(e){
		//console.log("update to:",e);
		if(this.selectedPackage){
			this.selectedPackage.actioned = e.actioned;
			this.selectedPackage.partialAllowed = e.partial;
		}
		this.setDelivOption();
		this.packageUpdated();
	}

	updateFolderOption(e){
		//console.log("update to:",e);

		//console.log("delivery_items", this.selectedPackage.delivery_items);
		let creatives = this.getPackageCreativeList(this.selectedPackage);
		let duplicateFiles = false;
		let creativeFilenameLookup = [];
		creatives.forEach(creative => {
			//console.log("creative info",creative);
			//if(creative.state == CreativeState.DONE) numCreativesApproved++;
		});
		if(this.selectedPackage){
			this.selectedPackage.folderOption = e.value;
			//
		}
		this.setFolderOption();
		this.packageUpdated();
	}

	resetDownloadOptions(){
		this.resetValue('actioned');
		this.resetValue('partialAllowed');
		this.setDelivOption();
		this.packageUpdated();
	}

	resetFolderOptions(){
		this.resetValue('folderOption');
		this.packageUpdated();
		//this.folderOption = this.selectedPackage.folderOption;
	}

	setDelivOption(){
		if(!this.selectedPackage) return;

		this.delivOption = this.deliveryDownloadOptions.find(option => option.actioned == this.selectedPackage.actioned && option.partial == this.selectedPackage.partialAllowed);
		//console.log("DELIV OPTION:", this.delivOption);
		//console.log("ref:",this.selectedPackage.reference);
		//console.log(this.hasChanged('actioned'),this.selectedPackage.actioned);
		//console.log(this.hasChanged('partialAllowed'),this.selectedPackage.partialAllowed);
	}

	setFolderOption(){
		if(!this.selectedPackage) return;

		//legacy hack - convert old 'useFormatPath' to a useable folderOption value, without triggering a need to save
		//TODO - set up a migration to deal with this legacy conversion in the database
		if(!this.selectedPackage.folderOption){
			this.selectedPackage.folderOption = (this.selectedPackage.useFormatPath) ? DeliveryFolderOptions.FORMAT : DeliveryFolderOptions.CHANNEL;
			this.selectedPackage.reference.folderOption = this.selectedPackage.folderOption;
		}

		this.folderOption = this.deliveryFolderOptions.find(option => option.value == this.selectedPackage.folderOption);
	}

	clearProp(prop:string){
		this.selectedPackage[prop] = null;
		this.packageUpdated();
	}
	/*
	togglePasscode()
	{
		//this.usePasscode = !this.usePasscode;
		if(!this.passcodeState) this.passcodeState = 1;
		else if (this.passcodeState == -1){
			this.resetValue('passcode');
			this.passcodeState = 1;
		}
		else if (this.passcodeState == 1 && this.selectedPackage.passcode)
		{
			this.passcodeState = -1;
			this.selectedPackage.passcode = '';
		} 
		else if (this.passcodeState == 1 && !this.selectedPackage.passcode)
		{
			this.passcodeState = 0
		} 
		this.packageUpdated();
		this.recordStateInLookup(this.passcodeStateLookup,this.selectedPackage, this.passcodeState);
	}

	togglePartial(){
		if(this.selectedPackage){
			this.selectedPackage.partialAllowed = ! this.selectedPackage.partialAllowed;
		}
	}
	toggleNote()
	{
		console.log("toggleNote",this.noteState);
		//this.usePasscode = !this.usePasscode;
		if(!this.noteState) this.noteState = 1;
		else if (this.noteState == -1){
			this.resetValue('notes');
			this.noteState = 1;
		}
		else if (this.noteState == 1 && this.selectedPackage.notes)
		{
			this.noteState = -1;
			this.selectedPackage.notes = '';
		} 
		else if (this.noteState == 1 && !this.selectedPackage.notes)
		{
			this.noteState = 0
		} 
		this.recordStateInLookup(this.noteStateLookup,this.selectedPackage, this.noteState);
		//this.packageUpdated();
	}
	toggleFormatPath(){
		this.selectedPackage.useFormatPath = !this.selectedPackage.useFormatPath;// ? 0 : 1;
		this.packageUpdated();

	}
	*/
	/*
	recordPasscodeState(_package: DeliveryPackage,stateNum:number) {
		this.passcodeStateLookup[_package.uuid] = stateNum;  
	}*/

	hasPasscode(deliveryPackage:DeliveryPackage){
		return deliveryPackage.passcode;
		// && (deliveryPackage.passcode != '' && deliveryPackage.passcode !='0');
	}
	hasNotes(deliveryPackage:DeliveryPackage){
		return deliveryPackage.notes;
		// && (deliveryPackage.passcode != '' && deliveryPackage.passcode !='0');
	}


	recordStateInLookup(lookupArr,_package: DeliveryPackage,stateNum:number){
		lookupArr[_package.uuid] = stateNum; 
	}


	

	getValidationErrors(){
		let errorObj = null;
		let packageErrors = null;
		let numMissingNames = 0;
		for(let p=0; p<this.packages.length;p++){
			let _package = this.packages[p];
			let issuesArr = [];
			
			//name
			if(!_package.name || _package.name == ''){
				//console.log("MISSING NAME");
				numMissingNames ++;
			} 


			//passcode
			//console.log("passcode pkg uuid:",_package.uuid);
			//if(this.passcodeStateLookup[_package.uuid] == 1){
			//	if(_package.passcode == '' || _package.passcode == null){
			//		issuesArr.push('Missing passcode.');
			//	} else {
				if(_package.passcode != '' && _package.passcode != null){
					if(_package.passcode.length != 6) issuesArr.push('Passcode must be 6 digits (0-9)');
					if(isNaN(Number(_package.passcode))) issuesArr.push('Passcode can only contain digits (0-9).');
				}
			//} 

			if (issuesArr.length){
				if(!packageErrors) packageErrors = [];
				packageErrors[_package.uuid] = {name:_package.name, issues:issuesArr};
			}
		}
		if(numMissingNames || packageErrors){
			errorObj = {};
			if(numMissingNames) errorObj.numMissingNames = numMissingNames;
			if(packageErrors) errorObj.packageErrors = packageErrors;
		} 
		
		return (errorObj)
	}

	showValidationDialog(errorObj){
		const dialogRef = this.dialog.open(PackageSaveValidationDialogComponent, {
			data: {
				title: 'Packages not saved!',
				numMissingNames:errorObj.numMissingNames,
				packageErrors:errorObj.packageErrors
				}
		});
	}

	getCreativeAssets(creative:Creative)
	{
		this.creativeService.getAssets(creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				let assets = response.data;//.map(asset => new AssetVO(asset, uriMeta));
				//console.log("assets:", assets);
			}
			/*,
			error => this.errorMessage = <any>error*/
		);
	}

	packageUpdated(){
		this.packageChanged.emit();
	}

	onInput(){
		console.log("input updated");
	}
}

