import { Component, EventEmitter, Input, OnChanges, QueryList, ElementRef, ViewChild, TemplateRef, ViewChildren, ViewContainerRef, SimpleChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs'
import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { CreativeService } from 'src/app/api/creative.service';
import { AppUserService } from 'src/app/services/app-user.service';
import { isScrolledToBottom, isScrolledToTop, scrollable } from 'src/app/utils/ScrollUtils';
import { CreativeDiscussionService, DiscussionMessage, IChatMessage } from 'src/app/views/prototypes/creative/creativeDiscussion.service';
import { BaseComponent } from '../../base-component/base-component.component';
import { CreativeChatService } from 'src/app/api/creativeChat.service';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/services/localStorage.service';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() project_uuid:string;
  @Output() submitMessage: EventEmitter<string> = new EventEmitter();
  @Output() loadMore: EventEmitter<any> = new EventEmitter();
  @Output() typing: EventEmitter<boolean> = new EventEmitter();
  @Output() clearUnreadFlag: EventEmitter<any> = new EventEmitter();
  @ViewChildren('messageContainer', {read: ElementRef}) messageContainer : QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('messageRef') messagesList : QueryList<ElementRef<HTMLElement>>;
  // discussion
	messageControl: FormControl;
  discussionForm: FormGroup;

  // ui
  public _scrolledToBottom = true;
  public _scrolledToTop = false;
  public _scrollable = false;
  public _scrollCache = 0;  // value to be able to maintain scroll position for when new content is loaded, current scroll distance from bottom
  public messages;


  public canLoadMore:boolean;
  public scrollContainer:HTMLElement;
  public canSubmit:boolean;
  public newMessages:boolean;


  @ViewChild('messageMenu') messageMenu: TemplateRef<any>;
  private closeDelay;
  //private overlayRef: OverlayRef | null;
  private overlays:Map<string, {ref:OverlayRef, timeout?}> = new Map();

  constructor(
    private fb: FormBuilder,
    public creativeService:CreativeService,
    public creativeChatService:CreativeChatService,
    public creativeDiscussionService:CreativeDiscussionService,
    public appUserServer:AppUserService,
    public overlay: Overlay,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public lss: LocalStorageService,
  ) {
    super();
    this.messageControl = new FormControl();
    this.discussionForm = this.fb.group({ message: this.messageControl });
    this.sub = creativeService.discussionMore$.subscribe(canLoadMore => this.canLoadMore = canLoadMore);
    this.sub = creativeDiscussionService.groupedMessages$.subscribe((messages) => {
      // always reset to be safe
      this.newMessages = false;
      if(this.messages?.length && messages?.length)
      {
        let current_creative_uuid = this.messages[0][0][0].message.creative_id;
        let new_creative_uuid = messages[0][0][0].message.creative_id;

        if(current_creative_uuid == new_creative_uuid)
        {
          // see if new messages are old or new, if new we can set a flag
          // compare last message to see
          let first_current = this.messages.at(0).at(0).at(0);
          let last_new = this.messages.at(-1).at(-1).at(-1);

          if(Date.parse(last_new.message.created_at) > Date.parse(first_current.message.created_at))
          {
            this.newMessages = true;
          }

          // could load each batch into a new div? would this help
          let el = this.messageContainer.first?.nativeElement;
          if(el)
          {
            this._scrollCache = el.scrollHeight;
            console.log("scroll cache", this._scrollCache);
          }
        }
      }
      if(messages?.length)
      {
        const key = `discussion_dates_${this.project_uuid}`;
        let lastUserGroup:any[] = messages[messages.length-1];
        let lastDateGroup:any[] = lastUserGroup[lastUserGroup.length-1];
        let lastMessage = lastDateGroup[lastDateGroup.length-1].message;
        let created_at = Date.parse(lastMessage.created_at)
        // covert from db format YYYY-MM-DD HH:MM:SS to iso format yyyy-mm-ddThhZ
        // console.log("setting value", lastMessage.created_at, lastMessage.created_at.split(" ").join("T") + 'Z', Date.parse(lastMessage.created_at.split(" ").join("T") + 'Z'))
        //let created_at = Date.parse(lastMessage.created_at.split(" ").join("T") + 'Z');
        let localData = this.lss.get(key);
        if(!localData)
        {
          localData = {};
          localData[lastMessage.creative_id] = created_at;
          this.lss.set(key, localData);
        } else {
          let saved_date = localData[lastMessage.creative_id];
          if(!saved_date){
            localData[lastMessage.creative_id] = created_at;
          } else if(saved_date < created_at) {
            localData[lastMessage.creative_id] = created_at;
          }    
          this.lss.set(key, localData);
        }
        this.clearUnreadFlag.emit();
      }
      this.messages = messages;
      this.scrollToBottom();
    })

    this.discussionForm.get('message').valueChanges.subscribe(changes => {
      this.updateCanSubmit();
    })
  }
  ngAfterViewInit()
  {
    // scroll into bottom on first update
    this.sub = this.messageContainer.changes.subscribe((next: QueryList<ElementRef>) => {
        this.scrollContainer = next.first?.nativeElement;
	  });

    this.sub = this.messagesList.changes.subscribe(res => {
      console.log("new messages")
      this._scrollable = scrollable(this.scrollContainer);
      if(this._scrollCache)
         {
          let el = this.messageContainer.first.nativeElement;
          let height = el.scrollHeight;
          console.log("sorting da scrolll A", el.scrollTop, (height - this._scrollCache));
          el.scrollTop += (height - this._scrollCache);
          this._scrollCache = 0;
          console.log("sorting da scrolll B", el.scrollTop);
         } else {
           this.scrollToBottom();
         }
	  });
  }
  ngOnChanges(changes: SimpleChanges = null): void {
		//console.log("simple changes", changes);

	}
  /*

  -----
  |      |
  |      |
  |      |    \/ scrollTop
  =======
  |     ||    /\
  |     ||    \/ client height
  =======
  |      |
  |      |    \/ scrollHeight
  ------
  */
  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    super.ngOnDestroy();


    // cleanup/remove all overlays
    this.overlays.forEach((overlayVO, key) => {
      if(overlayVO.timeout) clearTimeout(overlayVO.timeout);
      overlayVO.ref.dispose();
      this.overlays.delete(key);
    });
  }
  cancelClose(messageVO):boolean
  {
    let overlayVO = this.overlays.get(messageVO.message.uuid);     
    if(overlayVO?.timeout){
      clearTimeout(overlayVO.timeout);
      return true;
    }
    return false;
  }
  private closeOverlay(message)
  {

  }
  close(messageVO, delay?:number)
  {
    let overlayVO = this.overlays.get(messageVO.message.uuid);
    if(!overlayVO?.ref) return;
    
    if(delay)
    {
      overlayVO.timeout = setTimeout(() => {
        overlayVO.ref.dispose()
        this.overlays.delete(messageVO.message.uuid);
      }, delay);
    }else{
      overlayVO.ref.dispose()
      this.overlays.delete(messageVO.message.uuid);
    }
    
  }
  open({ x, y }: MouseEvent, element, messageVO) {
    // no menu for messages that are not mine
    if(messageVO.message.user_id != this.appUserServer.appUser.uuid ) return;
    // already open
    if(this.overlays.has(messageVO.message.uuid))
    {
      // prevent close
      const closed = this.cancelClose(messageVO);
      if(closed)  return;
    } else {
      // close others
      this.overlays.forEach((overlayVO, key) => {
        if(overlayVO.timeout) clearTimeout(overlayVO.timeout);
        overlayVO.ref.dispose();
        this.overlays.delete(key);  // must remove from set
      });
    }
    
    // close any open overlay
    const positionStrategy = this.overlay.position()
      //.flexibleConnectedTo({ x, y })
      .flexibleConnectedTo(element)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        }
      ]);

      let overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close()
      });
      overlayRef.attach(new TemplatePortal(this.messageMenu, this.viewContainerRef, {
        $implicit: messageVO
      }));
      this.overlays.set(messageVO.message.uuid, {ref:overlayRef});
  }
  editMessage(messageVO)
  {
    console.log("edit message", messageVO);
    // close the menu
    this.close(messageVO);
    let form = [
			{ name: "Text", type: "textarea", placeholder:"Message", label: "Message", value: messageVO.message.text },
		];
    const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Edit message",
				subtitle: "Edit this message (an '*' will be displayed but the original content will be deleted)?",
				negative: "Cancel",
				positive: "Save",
        form,
			}
		});
    dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
        let text = result.form[0].control.value;
        let payload:IChatMessage = {text};
        this.creativeChatService.update(messageVO.message.uuid, payload).subscribe((res:any) => {
          this.creativeService.replaceDiscussionMessages(res.data as IChatMessage[]);
        });
      }
    });
  }
  deleteMessage(messageVO)
  {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Delete message",
				subtitle: "Delete this message for you and others (a placeholder will remain visible)?",
				negative: "Cancel",
				positive: "Delete",
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
        this.creativeChatService.delete(messageVO.message.uuid).subscribe((res:any) => {
          this.creativeService.deleteExistingMessages(res.data as IChatMessage[]);
        });
      }
    });
    // close the menu
    this.close(messageVO);
  }
  onEnterMenu()
  {

  }
  onScroll(event)
  {
    // check if scrolled to bottom
    const canScroll = scrollable(this.scrollContainer);
    this._scrolledToBottom = isScrolledToBottom(this.scrollContainer);
    const scrolledToTop = isScrolledToTop(this.scrollContainer);
    if(!this._scrolledToTop && canScroll && scrolledToTop)
    {
        this._scrolledToTop = true;
        console.log("scrolled to top");
        this.loadMore.emit("hello");
        
    } else if(!scrolledToTop)
    {
        this._scrolledToTop = false;
    }
    if(this.newMessages && this._scrolledToBottom)
    {
      this.newMessages = false;
    }
  }
  updateScrollFlags()
  {
    
  }
  onWheel(event)
  {
    if(!scrollable(this.scrollContainer) && isScrolledToTop(this.scrollContainer))
    {
      this.loadMore.emit();
    }
  }

  scrollToBottom(force:boolean = false)
  {
    console.log("scrollToBottom", this._scrolledToBottom);
    // can only auto scroll to bottom if already scrolled to bottom in past
    if(!force && !this._scrolledToBottom) return;
    if(this.messageContainer?.first?.nativeElement?.lastElementChild)
    {
      console.log("scrolling into view");
      this.messageContainer.first.nativeElement.lastElementChild.scrollIntoView();
    }
    // scrollIntoView(false);
  }

  isMe(uuid:string):boolean
  {
    return this.appUserServer.appUser.uuid === uuid;
  }
  keydown(event)
  {
    //console.log(event);
    // whisper that we are typing..
  }
  private _typing:boolean = false;
  input(event)
  {
    const length = this.messageControl?.value?.length || 0
      if(!this._typing && length)
      {
        this._typing = true;
        this.typing.emit(this._typing);        
      }else if(this._typing && !length){
        this._typing = false;
        this.typing.emit(this._typing);    
      }
  }
  updateCanSubmit()
  {
    this.canSubmit = this.messageControl?.value ? /\S+/.test(this.messageControl?.value) : false;
  }
  submit(event?:KeyboardEvent){
    if(event && event.ctrlKey)
    {
      return;
    } else if(event){
      event.preventDefault()
      event.stopImmediatePropagation();
    }
    console.log("submit", this.messageControl.value);
    // todo check from empty string
    if(this.canSubmit) ///^\s*$/
    {
      this.submitMessage.emit(this.messageControl.value);
      this.messageControl.reset();
      // clear typing flag
      this._typing = false;
      this.typing.emit(this._typing);
      this.updateCanSubmit();

      // always scroll to the bottom after a submit
      if(!this._scrolledToBottom) this.scrollToBottom(true);
    }
  }
  trackByUUID(index, item:DiscussionMessage):string
  {
    return item.message.uuid + "_" + item.message.updataed_at;
  }
  trackByUUID1(index, item):string
  {
    return item.length + "_" +item[0].message.uuid;
  }
  trackByUUID2(index, item):string
  {
    return item[0].length + "_" +item[0][0].message.uuid;
  }

}
