import { Directive, ElementRef, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
// import ResizeObserver from 'resize-observer-polyfill'; //not needed really since > Chrome 64
// https://stackoverflow.com/questions/44736209/mutationobserver-ignore-a-dom-action



@Directive({ selector: '[orderable]' })
export class OrderableDirective implements OnDestroy {

  private _observer:MutationObserver;
  private _disabled:boolean;
  
  constructor(private el: ElementRef) {
    const targetNode = this.el.nativeElement;

    
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: false };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      if(this._disabled) return;
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          if(mutation.addedNodes && mutation.addedNodes)
          {
            let elementAdded = false;
            for(var i = 0; i < mutation.addedNodes.length; i++)
            {
              if(mutation.addedNodes[i].nodeType == Node.ELEMENT_NODE)
              {
                elementAdded = true;
                break;
              }
            }
            if(elementAdded)
            {
              this._observer.disconnect();
              this._observer.takeRecords(); // deplete the queue
              [...mutation.addedNodes].forEach(node => {
                targetNode.insertBefore(node, targetNode.firstElementChild);
                //targetNode.appendChild(node);
              })
              this._observer.observe(targetNode, config);
              console.log("A child node has been added", mutation);
            }
          }
          if(mutation.removedNodes && mutation.removedNodes.length)
          {
            //[mutation.removedNodes].forEach
            console.log("A child node has been removed", mutation);
          }
          
          this.sort(targetNode);
        } else if (mutation.type === "attributes") {
          console.log(`The ${mutation.attributeName} attribute was modified.`);
        }
      }
    };

    // Create an observer instance linked to the callback function
    this._observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    this._observer.observe(targetNode, config);
  }
  sort(target)
  {

  }
  _resizeCallback(entry:ResizeObserverEntry) {
    //this.resize.emit(entry);
  }
  
  ngOnDestroy() {
    const target = this.el.nativeElement;
    this._observer.disconnect();
    //entriesMap.delete(target);
  }
}
