import { ICreative } from "./creative.model";

export interface IDeliveryItem {
	id: number;
	package_uuid: string;
	creative_uuid: string;

	creative?:ICreative;
}
export class DeliveryItem implements IDeliveryItem{
	public id: number;
	public package_uuid: string;
	public creative_uuid: string;
	public creative?: ICreative;
}
