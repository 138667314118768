import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-colour-label',
  templateUrl: './colour-label.component.html',
  styleUrls: ['./colour-label.component.scss']
})
export class ColourLabelComponent implements OnInit {

  @Output() colourSelected : EventEmitter<string> =  new EventEmitter();	
  @Input() selectedColour : any;
  @Input() selectedColours: any[];

  public labels:any[] = ['ff0000', '00ff00', '0000ff', 'ffff00', 'ff00ff', '00ffff'];
  constructor() { }

  ngOnInit(): void {
	if(!this.selectedColour && !this.selectedColours) this.selectedColour = 'null';
	if (this.selectedColour) {
		this.selectedColours = [];
		this.selectedColours[this.selectedColour] = 1;
		this.selectedColour = null;
	}
  }

  ngOnChanges(changes){
	  this.ngOnInit();
  }
  isSelected(colour:string)
  {
	 //console.log(colour);
	 if(colour == null) colour = 'null';
    //if( this.selectedColour) return this.selectedColour == colour;
	if( this.selectedColours) 
		return this.selectedColours[colour] > 0;
	else
		return false; 
	
  }
  onColourSelected(colour:string)
  {
	  //this.selectedColour = colour;
	  //if(colour == 'null' || colour == null) colour = '';
    this.colourSelected.emit(colour);
  }
}
