<mat-form-field class="input_field">
	<input matInput #add_asset_name class="login_input" placeholder="Name" value="{{data.asset.name}}" (keyup)="setAssetSaveDataDelayed('name', $event.target.value)" (change)="setAssetSaveData('name', $event.target.value)">
</mat-form-field>
<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_width id="_asset_w" class="login_input" placeholder="Width" value="{{data.asset.width}}" (keyup)="setAssetSaveDataDelayed('width', $event.target.value)" (change)="setAssetSaveData('width', $event.target.value)">
</mat-form-field>
<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_height id="_asset_h" class="login_input" placeholder="Height" value="{{data.asset.height}}" (keyup)="setAssetSaveDataDelayed('height', $event.target.value)" (change)="setAssetSaveData('height', $event.target.value)">
</mat-form-field>

<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_x id="_asset_x" class="login_input" placeholder="X" value="{{data.asset.x}}" (keyup)="setAssetSaveDataDelayed('x', $event.target.value)" (change)="setAssetSaveData('x', $event.target.value)">
</mat-form-field>
<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_y id="_asset_y" class="login_input" placeholder="Y" value="{{data.asset.y}}" (keyup)="setAssetSaveDataDelayed('y', $event.target.value)" (change)="setAssetSaveData('y', $event.target.value)">
</mat-form-field>
<mat-form-field class="input_field">
	<input matInput type="number" #add_asset_z id="_asset_z" class="login_input" placeholder="Z" value="{{data.asset.z}}" (keyup)="setAssetSaveDataDelayed('z', $event.target.value)" (change)="setAssetSaveData('z', $event.target.value)">
</mat-form-field>
<div>
<mat-checkbox (change)="updateIsVisible($event)" [checked]="data.asset.visible == '1'">Visible</mat-checkbox>
</div>
<div>
<mat-checkbox (change)="updateIsDeliverable($event)" [checked]="data.asset.deliverable == '1'">Deliverable</mat-checkbox>
</div>
<!--
<mat-form-field class="asset_type">
  <mat-label>Type</mat-label>
  <mat-select id="asset_type" [value]="asset_type_id" (selectionChange)="setAssetTypeId($event.value)">
    <mat-option *ngFor="let asset_type of asset_types" [value]="asset_type.uuid">
      {{asset_type.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
-->
<!--
<div *ngIf="asset_type_id == 1" class="image_type additional_attributes">
	<div class="upload_file">
		<h4>File</h4>
		<app-image-uploader [width]="asset_width" [height]="asset_height" [parent]="this"></app-image-uploader>
	</div>
</div>
-->
<div class="button_wrapper_delete">
	<button mat-raised-button class="red" (click)="deleteAsset()">Delete</button>
</div>
<div class="button_wrapper_save">
	<button mat-raised-button (click)="saveAsset({name: add_asset_name.value, width: add_asset_width.value, height: add_asset_height.value, x: add_asset_x.value, y: add_asset_y.value, z: add_asset_z.value})">Save</button>
</div>