import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { MatDialogModule } from '@angular/material';
// import { MatInputModule } from '@angular/material/input';
import { DialogService } from './../../services/dialog.service';
import { ApiService } from './../../api/api.service';
import { AuthService } from './../../auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil, take} from 'rxjs/operators';
import { StringsService } from './../../services/strings.service';
import { AppUserService } from 'src/app/services/app-user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { E } from '@angular/cdk/keycodes';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	redirect:any = '/';
	access_token:any = false;
	error_message:any = false;

	hidePassword = true;
	submitting = false;

	forgottonPassword:boolean = false;
	email = '';
	password = null;

	formControl:FormGroup;

	constructor(private auth: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		public api:ApiService,
		public appUserService:AppUserService,
		private ds:DialogService,
		public strings:StringsService, 
		private _snackbar:MatSnackBar) { }
	
	ngOnInit()
	{
		// create form control
		this.formControl = new FormGroup({
			email: new FormControl(this.email, [Validators.required, Validators.email]),
			password: new FormControl(this.password, [Validators.required, Validators.minLength(6)])
		});
			
		// temp email set after an activation for convenience
		let emailTemp = localStorage.getItem('emailtmp');
		if(emailTemp)
		{
			//this.email = emailTemp;
			this.formControl.controls['email'].setValue(emailTemp);
			localStorage.removeItem('emailtmp');
		}

		// poke me to see if logged in
		this.appUserService.appUserObservable$.pipe(take(1)).subscribe(res => {
			//console.log("login precheck", res);			
			if(res)
			{
				this.api.gotoPage("");
			}else {
				// wait for them to login
			}
		}, error => {
			console.log("app user error", error);
			
		});
		//console.log("login precheck getAppUser");
		
		this.appUserService.getAppUser();

		
		/*
		if(typeof sessionStorage.getItem('emailtmp') !== 'undefined' && sessionStorage.getItem('emailtmp')){
			$('#email').val(sessionStorage.getItem('emailtmp'));
			$('#password').focus();
			sessionStorage.removeItem('emailtmp');
		}else{
			$('#email').focus();
		}*/
		this.api.requestpassword.pipe(takeUntil(this._unsubscribe)).subscribe(res =>
		{
			this.submitting = false;		
			if (res.status == 422) {	// validation error
				this._snackbar.open("password reset failed: " + res.error.message, "", {duration:2000, panelClass:'snackBar-error'});
			} else if(res.status == 401){
				if(res.type == "activation_pending"){
					this.userPendingResponse();
				}
			}else if(res.error) {		// generic server error
				this._snackbar.open("password reset failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
			} else {					// ok
				this.forgottonPassword = false;
				//this._snackbar.open("Password reset link sent, please check inbox", null, {duration:5000});
				let confirm:any = {
					message: 'Password reset link sent.',
					message2: 'Please check your inbox.',
					dismiss_button_label: "OK",
				};
				this.ds.openAlert(confirm);
			}
		});

		this.api.reinvite.pipe(takeUntil(this._unsubscribe)).subscribe(res =>
		{
			this.submitting = false;	
			this._snackbar.dismiss();	
			if (res.status == 422) {	// validation error
				this._snackbar.open("request failed: " + res.error.message, "", {duration:2000, panelClass:'snackBar-error'});
			}else if(res.error) {		// generic server error
				this._snackbar.open("request failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
			} else {					// ok
				this.forgottonPassword = false;
				//this._snackbar.open("Password reset link sent, please check inbox", null, {duration:5000});
				let confirm:any = {
					message: 'Account activation link sent.',
					message2: 'Please check your inbox.',
					dismiss_button_label: "OK",
				};
				this.ds.openAlert(confirm);
			}
		});
			
		this.subscription = this.api.loguserinwithcreds.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//console.log("login res", res);
			if(res.error || res.status == 401){

				//console.log("res", res);
				this.submitting = false;
				if(res.status == 422)
				{
					this._snackbar.open("login failed: " + res.error.message, "", {duration:2000, panelClass:'snackBar-error'});
				} else if(res.status == 401)
				{
					if(res.type == "activation_pending"){
						this.userPendingResponse();
					} else {
						this._snackbar.open("login failed: " + this.strings.getError('login'), "", {duration:2000, panelClass:'snackBar-error'});
					}
					
					
				}else {
					this._snackbar.open("login failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
				}	
				return;
				setTimeout(() => {
					this.submitting = false;
					let message:any = this.strings.getError('login');
					this.ds.openSnackbarMessage({message:message});
					//this.ds.openSnackbarMessage({message:res.error.message});
					$('.login').addClass('error');
				}, 500);
				return;
			}
			//console.log("login::logged in");			
			if(typeof res.data !== 'undefined'){
				// this used to be stored in local storeage.. but not for now
				// this.appUserService.setUser(res.data_temp);

				/*
				sessionStorage.setItem('current_user', JSON.stringify(res.data.user));
				sessionStorage.setItem('current_user_meta', JSON.stringify(res.data.meta));
				sessionStorage.setItem('current_user_accounts', JSON.stringify(res.data.accounts));
				sessionStorage.setItem('current_user_roles', JSON.stringify(res.data.roles));*/
				// moved into api so can be watched for changes by observers				
				this.api.setCurrentUser(JSON.stringify(res.data));

				let token_data:any = this.api.getToken();
				this.access_token = token_data['token'];
				this.auth.setLoggedIn(this.access_token);
				
				let redirect = this.route.snapshot.queryParams['redirect'] ? decodeURIComponent(this.route.snapshot.queryParams['redirect']) : '';
				let fragment = this.route.snapshot.fragment;
				//console.log("login redirect", redirect, fragment);
				
				
				this.appUserService.appUserObservable$.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					//console.log("login:: app user subject pipe result", res);
					if(!res) return;
					this.api.gotoPage(redirect, false, {fragment});
					//console.log("app user::", res);
					/*
					console.log("exists?", this.doesHttpOnlyCookieExist('laravel_token'));

					let interval = setInterval(()=>
					{
						let cookieExists = this.doesHttpOnlyCookieExist('laravel_token');
						console.log("exists?", cookieExists);
						if(cookieExists)
						{
							clearInterval(interval);
							this.api.gotoPage(this.redirect);
						}
					}, 100)
					*/
					/*
					setTimeout(() => {
						console.log("exists?", this.doesHttpOnlyCookieExist('laravel_token'));
						this.api.gotoPage(this.redirect);
					}, 200);*/
				}, error => {
					console.log("app user error", error);
					
				});
				//
				//console.log("getting me... after login")
				//console.log("getting me... after login A")
				this.appUserService.getAppUser();
				//console.log("getting me... after login B")
			}
		}, error => {
			this.submitting = false;
			console.log("message", error.error.message);
			
			if(error.error?.message)this.ds.openSnackbarMessage({message:error.error.message});	//errors[keys[0]].join(' ')
		});

		//TODO what's this for?
		this.subscription.add(this.api.resetpassword.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//console.log("res", res);
		}));
	}

	get emailControl() { return this.formControl.get('email'); }
	get passwordControl() { return this.formControl.get('password'); }

	doesHttpOnlyCookieExist(cookiename) {
		var d = new Date();
		d.setTime(d.getTime() + (1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cookiename + "=new_value;path=/;" + expires;
		return document.cookie.indexOf(cookiename + '=') == -1;
	}
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	
	logUserIn(){
		this.submitting = true;
		this.error_message = false;
		$('.login').removeClass('error');
		//console.log("login::logUserIn");
		
		this.api.logIn({name: $('#email').val(), pass: $('#password').val(), remember_me: $('#remember_me').val()});
	}
	forgotPassword()
	{
		this.forgottonPassword = true;
	}
	notForgotPassword()
	{
		this.forgottonPassword = false;
	}
	submitForgottonPassword()
	{
		//TODO
		let email:any = $('#email').val();		
		let data:any = {
			email
		};
		//this._snackbar.open("requesting password reset...", null, {duration:4000});
		this.submitting = true;
		this.api.requestPassword(data);
	}

	userPendingResponse(){
		//console.log ("pending user");
		let confirm:any = {
			title: 'Activation pending',
			message: 'It looks like you still need to activate your account.',
			message2: 'Check your emails and accept the invitation that was previously sent.',
			confirm_button_label: "OK",
			dismiss_button_label: "Re-send invite",
			confirmAction: () => {
				// do nothing
			},
			dismissAction: () => {
				//TODO
				let email:any = $('#email').val();		
				let data:any = {
					email
				};
				this._snackbar.open("requesting password reset...", null, {duration:2000});
				this.submitting = true;
				this.api.reInvite(data);
			}
		};
		this.ds.openConfirm(confirm);
	}
}