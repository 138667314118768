import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Task } from '../models/task.model';
import { AppUserService } from '../services/app-user.service';
import { ApiResponse, OVBaseService } from './OvBaseService';
import { Globals } from '../global';

@Injectable({
	providedIn: 'root'
})
export class TasksService extends OVBaseService<Task, string> {
	//appUserService: AppUserService;
	constructor(protected http: HttpClient,
		private snackBar:MatSnackBar,
		private appUserService:AppUserService) {
		super(http, 'tasks');
	}

	private _tasksLoadingSubject = new BehaviorSubject<boolean>(false);
	public tasksLoadingObservable = this._tasksLoadingSubject.asObservable();
	private _tasksSubject = new Subject<Task[]>();
	public tasksObservable$ = this._tasksSubject.asObservable();
	loadTasks(creative_uuid : string)
	{
		return this.getTasks(creative_uuid).pipe().subscribe((res) => {
			this._tasksSubject.next(res.data);
		});
	}

	getTasks(creative_uuid : string):Observable<ApiResponse<Task>>{
		//return this._get(`${this.base}/creative/${creative_uuid}`);
		let user = this.appUserService.appUser;
		this._tasksLoadingSubject.next(true);
		return this._get(`${this.base}/creative/${creative_uuid}`).pipe(
			map((response) => {
				let tasks:any[] = response["data"];
				for (let i = 0; i < tasks.length; i++) {
					let task = tasks[i];
					Task.decorateTask(task, user);	
				}
				return response; 
			}),
			finalize(() => this._tasksLoadingSubject.next(false))
		);
	}
	taskAction(creative_uuid : string, task_uuid : string, action : string, message : string = null, task:Task = null):Observable<ApiResponse<Task>>{
		let showSnackBar = false;
		if(showSnackBar)	this.snackBar.open("task "+action, null, {duration: 4000});
		let data:any = {action, message};
		if(task)
		{
			// YUK - merge both data sets together - keys will be overridden
			data = {...data, ...task};
		}
		//@ts-ignore
		return this._post(`${this.base}/${creative_uuid}/${task_uuid}`, data).pipe(
			tap(response =>
				{
					if(showSnackBar) this.snackBar.open("task "+action+" submitted", null, {duration: 2000});
				}
			)
		);
	}
	taskSubmit(task:Task, creative_uuid: string):Observable<ApiResponse<Task>>
	{
		//let formData = FormUtils.objectToFormData(task);
		return this._post(`${this.base}/${creative_uuid}`, {action:'store', task});
	}
	taskUpdate(task_uuid:string, creative_uuid: string, task:Task):Observable<ApiResponse<Task>>
	{
		//let formData = FormUtils.objectToFormData(task);
		return this._put(`${this.base}/${creative_uuid}/${task_uuid}`, task);
	}
	addAttachment(creative_uuid, task_uuid, attachment):Observable<ApiResponse<Task>>
	{
		return this._post(`${this.base}/${creative_uuid}/${task_uuid}/attachment`, attachment);
	}
	// notify api that task file upload is complete
	complete(uri:string, region:string, metadata:any)
	{
		return this._post(Globals.BASE_API_URL + `worker/uploadComplete/${uri}?region=${region}`, metadata);
		//this._post(`${this.base}/${creative_uuid}/${task_uuid}/${taskfile_uuid}/complete`);
	}

	resetApprovals(approversArr,creativesToReset){
		let approversToReset = approversArr.map(user => user.uuid);
		let data = {approversToReset,creativesToReset};
		return this._post(`${this.base}/resetapprovals`, data);
	}
}