import { Component, ElementRef, Input, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/global';

type Mode = "source" | "preview";

/**
 * A component for displaying text captions.
 * The caption is plain text to be store in a file
 * 
 */
@Component({
	selector: 'app-caption',
	templateUrl: './caption.component.html',
	styleUrls: ['./caption.component.scss'],

})
export class CaptionComponent extends BaseAssetComponent {
	@Input() editable : boolean = true;
	@ViewChildren('editor') private editorList: QueryList<ElementRef<HTMLElement>>;
	private _editor:ElementRef<HTMLElement>;
	
	public get editor() : ElementRef<HTMLElement> {
		return this._editor;
	}
	public set editor(editor:ElementRef<HTMLElement>) {
		this._editor = editor;
		if(this.editor)
		{
			this.editor.nativeElement.textContent = this.text || this.fileContents;
		}
	}
	private _mode:Mode = "source";
	private fileContents:string = null;
	public text:string = null;
	public get mode() : Mode {
	  return this._mode;
	}
	public set mode(mode:Mode) {
	  this._mode = mode;
	}
	constructor(
		private httpClient:HttpClient,
	) {
		super();
		this.defaultWidth = 400;
		this.defaultHeight = 150;
	}
	ngOnInit(): void {
		if(!this.editable)
		{
			this.mode = "preview";
		}
		this.loadFile();
	}
	ngAfterViewInit(): void {
		this.editorList.changes.subscribe(res => this.editor = this.editorList.first);
		this.editor = this.editorList.first;
	  }
	// load the contents on the file
	loadFile()
	{
		if(!this.assetVO?.asset.uri) return;
		let url = Globals.WORKER_URL + 'serve/' + this.uri();
		this.httpClient.get(url, {responseType: 'text'}).subscribe((res:string) => {
		  this.fileContents = res;
		  this.text = this.fileContents;
		  if(this.editor.nativeElement)	this.editor.nativeElement.textContent = this.text || this.fileContents;
		});
	}
	/**
	 * A change has been made to the contents
	 * Need to generate a file to save
	 */
	onChange(e){
		this.text = e.target.textContent;

		if(this.fileContents !== this.text)
		{
			const blob = new Blob([this.text], { type: "text/plain" });
    		const file = new File([blob], 'caption.html', {type: "text/plain"});
			this.assetVO.file = file;
			this.assetVO.fileMetadata = {size:this.assetVO.file.size, length:this.text.length};
      		this.assetVO.updateMetaView();
		} else {
			// the contents match.. so clear the saved file, otherwise update
			this.assetVO.file = null;
			this.assetVO.fileMetadata = null;
      		this.assetVO.updateMetaView();
		}
	}
	align(alignment:string)
	{
		this.assetVO.asset.metadata.align = alignment;
	}
}
