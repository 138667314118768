<div class="uploads" *ngIf="(this.uploadsService.uploads$ | async).length">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
            <mat-panel-title>
                <!--uploads... ({{uploads ? uploads.length : 0}})-->
                completed: {{(this.uploadsService.uploads$ | async | callback: filterInActive).length}} / {{(this.uploadsService.uploads$ | async).length}}
            </mat-panel-title>
            <mat-panel-description>
                <div class="stats" *ngIf="true || (this.uploadsService.uploads$ | async | callback: filterActive)?.length">
                    <div>
                        <span *ngIf="percentage > 0">uploaded: {{this.uploadsService.globalProgress$ | async | percentage}}%</span>
                    </div>
                    <div>
                        {{this.uploadsService.speed$ | async | formatBytes : '/sec'}}
                    </div>
                    <!--
                        <div>
                            ({{this.uploadsService.activeUploads$ | async}})
                        </div>
                    -->
                </div>      
            </mat-panel-description>
            </mat-expansion-panel-header>
            <!--
                <div *ngIf="!(this.uploadsService.activeUploads$ | async)?.length">
                    <span>
                        No active uploads.
                    </span>
                </div>
            -->
            <div *ngFor="let upload of this.uploadsService.uploads$ | async">
                <div class="upload">
                    <!-- top -->
                    <div class="top">
                        <div class="details">
                            <div>
                                <span class="filename" *ngIf="upload.link">
                                    <a [routerLink]="upload.link">{{ upload.file.name }}</a>
                                </span>
                                <span class="filename" *ngIf="!upload.link">
                                    {{ upload.file.name }}
                                </span>
                            </div>
                            <div class="filesize">
                                {{upload.file.size | formatBytes}}
                            </div>
                        </div>
                        <div class="buttons">
                            <button *ngIf="upload.state == 'ready' || upload.state == 'started'" [matTooltip]="'Cancel upload'" mat-icon-button (click)="cancel(upload)"><mat-icon>cancel</mat-icon></button>
                            <button *ngIf="upload.state == 'uploading'" [matTooltip]="'Cancel upload'" mat-icon-button (click)="cancel(upload)"><mat-icon>cancel</mat-icon></button>
                            <button *ngIf="upload.state == 'canceled'" [matTooltip]="'Clear upload'" mat-icon-button (click)="remove(upload)"><mat-icon>delete</mat-icon></button>
                            <button *ngIf="upload.state == 'failed'" [matTooltip]="'Clear upload'" mat-icon-button (click)="remove(upload)"><mat-icon>delete</mat-icon></button>
                            <button *ngIf="upload.state == 'complete'" [matTooltip]="'Clear upload'" mat-icon-button (click)="remove(upload)"><mat-icon style="color: #008900;">done</mat-icon></button>
                        </div>
                    </div>


                    
                    <!--<button *ngIf="upload.state == 'failed'" mat-icon-button (click)="retry(upload)"><mat-icon>replay</mat-icon></button>-->
                    <!-- bottom -->
                    <div class="progress">
                        <!--

                            <div>total: {{upload.total$ | async}}</div>
                            <div>loaded: {{upload.loaded$ | async}}</div>
                            <div>prog: {{upload.progress$ | async | percentage}}</div>
                        -->
                        <mat-progress-bar [mode]="(upload.progress == 1 && upload.state == 'started' ) ? 'query' : 'determinate'" [color]="(upload.state == 'failed' || upload.state == 'canceled') ? 'warn' : 'primary'" [value]="upload.progress$ | async | percentage"></mat-progress-bar>    
                    </div>   
                </div>     
            </div>
            <hr>
            <div *ngFor="let upload of uploads">
                <div class="upload">
                    <!-- top -->
                    <div class="top">
                        <div class="details">
                            <div>
                                <span class="filename" *ngIf="upload.link">
                                    <a [routerLink]="upload.link">{{ upload.file.name }}</a>
                                </span>
                                <span class="filename" *ngIf="!upload.link">
                                    {{ upload.file.name }}
                                </span>
                            </div>
                            <div class="filesize">
                                {{upload.file.size | formatBytes}}
                            </div>
                        </div>
                        <div class="buttons">
                            <button *ngIf="upload.state == 'uploading'" [matTooltip]="'Cancel upload'" mat-icon-button (click)="cancel(upload)"><mat-icon>cancel</mat-icon></button>
                            <button *ngIf="upload.state == 'canceled'" [matTooltip]="'Clear upload'" mat-icon-button (click)="remove(upload)"><mat-icon>delete</mat-icon></button>
                            <button *ngIf="upload.state == 'failed'" [matTooltip]="'Clear upload'" mat-icon-button (click)="remove(upload)"><mat-icon>delete</mat-icon></button>
                            <button *ngIf="upload.state == 'complete'" [matTooltip]="'Clear upload'" mat-icon-button (click)="remove(upload)"><mat-icon style="color: #008900;">done</mat-icon></button>
                        </div>
                    </div>


                    
                    <!--<button *ngIf="upload.state == 'failed'" mat-icon-button (click)="retry(upload)"><mat-icon>replay</mat-icon></button>-->
                    <!-- bottom -->
                    <div class="progress">
                        <mat-progress-bar mode="determinate" [color]="(upload.state == 'failed' || upload.state == 'canceled') ? 'warn' : 'primary'" [value]="upload.progress"></mat-progress-bar>    
                    </div>   
                </div>           
            </div>
        </mat-expansion-panel>
    </mat-accordion>       
</div>
