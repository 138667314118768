<div class="outer">
	<mat-card class="my_notifications" *ngIf="my_notifications">
		<h4>My Notifications</h4>
		<mat-list role="list">
			<mat-list-item *ngFor="let notification of my_notifications; index as i" role="listitem" class="list-item" [attr.data-notification-uuid]="notification.uuid" (click)="api.gotoPage('notification/'+notification.uuid)" >
				
			</mat-list-item>
		</mat-list>
	</mat-card>
	<mat-card class="my_notifications" *ngIf="!my_notifications">
		<h4>My Notifications</h4>
		<p>You have no notifications</p>
	</mat-card>
</div>