<div class="login-container">
	<div class="login">
		<mat-card>
			<app-password-pair [title]="'Set password'" (done)="activateAccount($event)"></app-password-pair>
			<form *ngIf="false" class="login-form" [formGroup]="formGroup">
				<div class="logo"></div>
				<div class="inputs">
					<p>Set a password</p>
					<!--

						<mat-form-field>
							<input type="password" id="password1" class="login_input" matInput placeholder="Password" value="">
						</mat-form-field>
						<mat-form-field>
							<input type="password" id="password2" class="login_input" matInput placeholder="Confirm Password" value="">
						</mat-form-field>
					-->
					<mat-form-field>
						<input #inputPassword matInput autocomplete="new-password" [type]="hidePassword ? 'password' : 'text'" placeholder="Password" formControlName="password" (input)="onPasswordInput()">
						<mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
						<mat-error *ngIf="password.hasError('required')">Password is required</mat-error>
						<mat-error *ngIf="password.hasError('minlength')">Password must have at least {{minPasswordLength}} characters</mat-error>
					  </mat-form-field>					
					  <mat-form-field>
						<input #inputPasswordConfirm matInput autocomplete="new-password" [type]="hideConfirmPassword ? 'password' : 'text'" placeholder="Confirm password" formControlName="passwordConfirm" (input)="onPasswordInput()">
						<mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
						<mat-error *ngIf="!password.errors && passwordConfirm.hasError('required')">Please confirm your password</mat-error>
						<mat-error *ngIf="!password.errors && passwordConfirm.invalid && !passwordConfirm.hasError('required')">Passwords don't match</mat-error>
					  </mat-form-field>
				</div>
				<div class="button_wrapper">
					<button [disabled]="!formGroup.valid" (click)="activateAccount()" mat-raised-button>Activate Account</button>
				</div>
			</form>
		</mat-card>
	</div>
</div>