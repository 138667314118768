import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-package-save-validation-dialog',
  templateUrl: './package-save-validation-dialog.component.html',
  styleUrls: ['./package-save-validation-dialog.component.scss']
})
export class PackageSaveValidationDialogComponent implements OnInit {

	title:any = false;
	confirm_button_label:any = 'Close';
	numMissingNames:number = 0;
	packageErrors:any[] = [];

	constructor(public dialogRef: MatDialogRef<PackageSaveValidationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {

		if(typeof this.data.title !== 'undefined'){
			this.title = this.data.title;
		}
		if(typeof this.data.confirm_button_label !== 'undefined'){
			this.confirm_button_label = this.data.confirm_button_label;
		}
		if(typeof this.data.numMissingNames !== 'undefined'){
			this.numMissingNames = this.data.numMissingNames;
		}
		if(typeof this.data.packageErrors !== 'undefined'){
			this.packageErrors = this.data.packageErrors;
		}
	}

	confirmAction(){
		if(typeof this.data.dismissAction !== 'undefined'){
			this.data.dismissAction();
			this.dialogRef.close();
		}else{
			this.dialogRef.close();
		}
	}

}
