<!-- <div class="link">
    <div>
        <a [href]="asset.uri" target="_blank">{{asset.name}}</a>
    </div>
    <div *ngIf="asset?.metadata?.path">
        <p>Path: {{asset.metadata.path}}</p>
        <p>Link: <a target="_blank" [href]="asset.metadata.links[0].url">Click here</a></p>
        <p>Password: {{asset.metadata.password}}</p>
        <p>Expires: {{asset.metadata.expiry_date}}</p>
    </div>
</div> -->
<!--<pre>{{getMetadata()}}</pre>-->
<div class="placeholder" [ngClass]="{'saved' : this.getPath()}" [ngStyle]="getStyle()">
	<div class="inline">
		<mat-icon class="icon">filter_drama</mat-icon><!-- settings_system_daydream-->
		<div *ngIf="!this.getPath()" class="message">no Egnyte link saved</div>

		<div *ngIf="assetVO.asset?.metadata?.path && parentView != 'preview'">
			<p>Path: {{assetVO.asset.metadata.path}}</p>
			<p>Link: <a target="_blank" [href]="assetVO.asset.metadata.links[0].url">Click here</a></p>
			<p>Password: {{assetVO.asset.metadata.password}}</p>
			<p>Expires: {{assetVO.asset.metadata.expiry_date}}</p>
		</div>
	</div>
</div>  
