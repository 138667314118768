<div class="placeholder" [ngClass]="{'saved' : this.getPath()}" [ngStyle]="getStyle()">
	<div class="inline">
		<mat-icon class="icon">link</mat-icon>
		<ng-container *ngIf="(this.getPath() && parentView != 'preview'); else noPath">
			<ng-container *ngIf="this.isValid(); else notValid">
				<a *ngIf="parentView != 'preview'" [href]="assetVO.asset.uri" [matTooltip]="assetVO.asset.uri" target="_blank">{{assetVO.asset.name}}</a>
			</ng-container>
			<ng-template #notValid>
				<div *ngIf="parentView != 'preview'" class="message">URL not valid</div>
			</ng-template>
		</ng-container>
		<ng-template #noPath>
			<div *ngIf="parentView != 'preview'" class="message">no URL saved</div>
		</ng-template>
	</div>
</div>  