<mat-tab-group animationDuration="0ms">
    <mat-tab label="projects {{projects ? '(' + projects.length + ')' : ''}}">
        <div class="row">
            <div class="col-6" *ngIf="projects">
                <h2>projects</h2>
                <mat-selection-list [multiple]="false" (selectionChange)="onProjectSelected($event)">
                    <mat-list-option *ngFor="let project of projects" [value]="project">{{project.name}}</mat-list-option>
                </mat-selection-list>
                <hr>
                <table mat-table [dataSource]="projects" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let project"> {{project.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef> Created </th>
                        <td mat-cell *matCellDef="let project"> {{project.created_at}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['name', 'created']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['name', 'created'];"></tr>       
                </table>
                   
            </div>
            <div class="col-6" *ngIf="users">
                <h2>project users</h2>
                <mat-selection-list [multiple]="false" (selectionChange)="onUserSelected($event)">
                    <mat-list-option *ngFor="let user of users" [value]="user">{{user.name}}</mat-list-option>
                </mat-selection-list> 
                <br>
                <ng-container *ngIf="selectedUser">
                    <mat-form-field class="permission-chip-list">
                        <mat-label>{{selectedUser.name}}&nbsp;permissions</mat-label>
                        <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let permission of selectedUserPermissions"
                                [selectable]="permissionSelectable"
                                [removable]="permissionRemovable"
                                (removed)="removeUserPermission(permission)">
                                {{permission.name}}
                            <mat-icon matChipRemove *ngIf="permissionRemovable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Grant permission..."
                                #permissionInput
                                [formControl]="permissionCtrl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="permissionSeparatorKeysCodes"
                                [matChipInputAddOnBlur]="permissionAddOnBlur"
                                (matChipInputTokenEnd)="addUserPermission($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="permissionSelected($event)">
                            <mat-option *ngFor="let permission of filteredPermissions | async" [value]="permission">
                              {{permission}}
                            </mat-option>
                          </mat-autocomplete>
                      </mat-form-field>                      
                </ng-container> 
            </div>
        </div>        
    </mat-tab>
    <!--
    <mat-tab [disabled]="!users" label="users {{users ? '(' + users.length + ')' : ''}}">            
    </mat-tab>
    -->
    <mat-tab [disabled]="!creatives" label="creatives {{creatives ? '(' + creatives.length + ')' : ''}}">
        <div class="row">
            <div class="col-3">
                <mat-form-field class="example-form-field">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" [(ngModel)]="creativeSearch">
                    <button mat-button *ngIf="creativeSearch" matSuffix mat-icon-button aria-label="Clear" (click)="creativeSearch=''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <br>              
                <mat-form-field>
                    <mat-label>{{creatives ? 'Select a creative' : 'Loading...'}}</mat-label>
                    <mat-select (selectionChange)="onCreativeSelected($event)"> <!--([value])="currentCreative"-->
                        <mat-option [value]="creative" *ngFor="let creative of getCreatives()"> <!--(onSelectionChange)="onCreativeSelected($event)" -->
                            {{creative.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!this.currentCreative" label="creative {{this.currentCreative ? '(' + this.currentCreative.state + ')' : ''}}">
        <mat-card class="creative">
            <mat-card-header>
                <mat-card-title>Creative</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div *ngIf="currentCreative" class="col-3">
                        <p>name: {{currentCreative.name}}</p>
                        <p>version: {{currentCreative.version}}</p>        
                        <p>date: {{currentCreative.created_at}}</p>                    
                        <p>state: {{currentCreative.state}}</p>
     
                    </div>
                    <div *ngIf="user" class="col-3">
                        <p>name: {{user.name}}</p>
                        <p>permissions:&nbsp;
                        <span *ngFor="let permission of userPermissions">
                            {{permission.name}},&nbsp;
                        </span></p>
                        <!--
                        <mat-form-field>
                            <mat-label>Change your role!</mat-label>
                            <mat-select ([value])="userRole" (selectionChange)="onRoleChanged($event)"> //([value])="currentCreative"//
                                <mat-option [value]="'production'">production</mat-option> 
                                <mat-option [value]="'approval'">approval</mat-option> 
                            </mat-select>
                        </mat-form-field>                    
                        -->
                    </div> 
                    <div *ngIf="userActions && userActions.length" class="col-3">
                        <p>TODOs:</p>
                        <div style="display: table; width: 100%;">                        
                            <div *ngFor="let userAction of userActions" style="display: table-row;">
                                <div style="display: table-cell;">
                                    {{userAction.user.name}}
                                </div>
                                <div style="display: table-cell;">
                                    {{userAction.message}} 
                                </div>
                                <div style="display: table-cell;">
                                    {{["&#9746;","&#9744;","&#9745;"][userAction.action+1]}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentCreative" class="col-3">
                        <p>Creative actions:</p>
                        <div style="display: table; width: 100%;">                        
                            <div *ngFor="let action of currentCreative.actions" style="display: table-row;">
                                <div style="display: table-cell;">
                                    {{formatUser(action.user)}}
                                </div>
                                <div style="display: table-cell;">
                                    {{formatDate(action.created_at)}} 
                                </div>
                                <div style="display: table-cell;">
                                    {{action.action}} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="this.reloadCreative()">Reaload creative</button>
            </mat-card-actions>
        </mat-card>
    
    
    <!--
    <form>
        <mat-form-field>
          <mat-label>creative uuid</mat-label>
          <input matInput [(ngModel)]="creative_id" name="creative_id">
        </mat-form-field>
        <button mat-stroked-button (click)="update()">update creative</button>
    </form>
    -->
    <div *ngIf="currentCreative" class="row">
        <div *ngIf="false" class="col-3">
            <button *ngIf="currentCreative.state == 'production'" mat-raised-button color="primary" [disabled]="!creativeCanComplete()" (click)="this.creativeComplete()">Build Complete</button>
            <button *ngIf="currentCreative.state == 'approval'" mat-raised-button color="primary" [disabled]="!creativeCanApprove()" (click)="this.creativeApprove()">Approve Creative</button>
            <button *ngIf="currentCreative.state == 'approval'" mat-raised-button color="primary" [disabled]="!creativeCanSubmitAmends()" (click)="this.creativeSubmitRequests()">Submit amend request(s)</button>
        </div>
        <div class="col-8">
            <div class="dummy"></div>
        </div>
        <div class="col-4">
            <div id="amends">
                <mat-card class="amends">
                    <mat-card-header>
                        <mat-card-title>Tasks</mat-card-title>
                    </mat-card-header>
                    <div class="taskSearchFilter">
                        <mat-form-field>
                            <mat-label>Search/Filter</mat-label>
                            <input matInput type="text" [(ngModel)]="taskSearch">
                            <button mat-button *ngIf="taskSearch" matSuffix mat-icon-button aria-label="Clear" (click)="taskSearch=''">
                              <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>  
                        <mat-slide-toggle>Hide closed</mat-slide-toggle>
                    </div>                                
                    <mat-card-content>
                        <div class>
                            <app-task class="task" *ngFor="let task of tasks" [task]='task' [selectedTask]="selectedTask" (onTaskSelected)="onTaskSelected($event)" [user]='this.user' [creativeState]="this.currentCreative.state" (amendAction)="test($event)"></app-task>
                        </div>
                    </mat-card-content>
            
                    <mat-card-actions *ngIf="true"> <!--isAmendActionsVisible() -->
                        <!--<button mat-raised-button [disabled]="!amendCanRequest()" (click)="this.amendRequestCreate()">request new amend</button>-->
            
                        <!--matTooltip="{{tooltipSubmitAmendRequest()}}"-->
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
    
    
    <!--<button mat-raised-button (click)="getAmends()">get amends</button>-->
    
    <!-- creative actions 
    <div id="bottom">
        <mat-card *ngIf="true || isStageActionsVisible()">
            <mat-card-header>
                <mat-card-title >Creative main actions</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-card-actions *ngIf="this.state === CreativeState.Production">
                    <button *ngIf="isCompleteCreativeVisible()" mat-raised-button color="primary" [disabled]="!canCompleteCreative()" (click)="this.completeCreative()">Build Complete</button>
                </mat-card-actions>
                <mat-card-actions *ngIf="this.state === CreativeState.Approval">
                    <button mat-raised-button color="primary" [disabled]="!canApproveCreative()" (click)="this.approveCreative()">Approve Creative</button>
                    <button mat-raised-button color="primary" [disabled]="!canSubmitAmendRequests()" (click)="this.submitAmendRequests()">Submit amend request(s)</button>
                </mat-card-actions>
                <hr>
                <div id="testing">
                    <button mat-raised-button (click)="this.resetState()">reset state</button>
                    <button *ngIf="testSelectedName" mat-raised-button (click)="this.nextStage()">next stage</button>
                    <hr>
                    <mat-form-field>
                      <mat-label>Choose test</mat-label>
                      <select [(ngModel)]="testSelectedName" matNativeControl required>
                        <option *ngFor="let testName of testNames" [value]="testName">{{testName}}<option>
                      </select>
                    </mat-form-field>
                   <p>You selected test: {{testSelectedName}}</p>
                    <div *ngIf="testSelected">
                        <mat-list dense>
                            <mat-list-item *ngFor="let stage of testSelected.stages; let index = index;" [ngStyle]="getDebugRowStyle(stage, index)">
                                {{stage}}
                            </mat-list-item>
                        </mat-list>
                    </div>	
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    -->
    </mat-tab>

</mat-tab-group>
