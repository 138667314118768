import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { DialogService } from './../../services/dialog.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, private ds:DialogService) { }
	
	error_message:any = false;
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		this.subscription = this.api.requestpassword.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//console.log(res);
			if(res.error){
				setTimeout(() => {
					this.ds.openSnackbarMessage({message:res.error.message});
					//this.error_message = res.error.message;
					$('.login').addClass('error');
				}, 500);
				$('body').removeClass('saving').removeClass('loading');
				return;
			}
			if(res.status == 200){
				let alert:any = {
					title: 'Check your email',
					message: res.message,
					dismiss_button_label: 'OK',
					dismissAction: () => {
						this.api.gotoPage('login');
					}
				};
				this.ds.openAlert(alert);
			}
			$('body').removeClass('saving').removeClass('loading');
		});
	}
	
	requestPassword(){
		$('.login').removeClass('error');
		this.error_message = false;
		
		$('body').addClass('saving').addClass('loading');

		let _email:any = $('#email').val();
		
		let _data:any = {
			email: _email,
		};
		
		this.api.requestPassword(_data);
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}