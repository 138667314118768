<div class="banner" (window:resize)="onResize($event)" [style.transform]="'scale('+(galleryMode ? scale : 1)+')'" [style.width.px]="assetVO?.asset.width" [style.height.px]="assetVO?.asset.height">
    <ng-container *ngIf="this.getPath(); else noPath">
        <div *ngIf="this.hasPreview()" class="preview">
            <img [src]="this.assetVO.preview | safe:'resourceUrl'"/>
        </div>
        <div class="loader" *ngIf="!loaded && !this.hasFlag(getProcessingFlags())"></div>
        <ng-container *ngIf="this.hasFlag(getProcessingFlags()); else noFlag">
            <div>Zip unpacking...</div>
        </ng-container>
        <ng-template #noFlag>
            <iframe #iframe [src]="getPath() | safe:'resourceUrl'" frameborder="0" width="100%" height="100%" scrolling="no"></iframe>
        </ng-template>
    </ng-container>
    <ng-template #noPath>
        <div class="placeholder" [ngStyle]="getStyle()">
            <div class="center">
                <mat-icon class="">html</mat-icon><!--integration_instructions-->
                <span *ngIf="parentView != 'preview'">{{assetVO.asset.name}}</span>
            </div>
            <div *ngIf="parentView != 'preview'" class="message">upload a HTML banner file (.zip)</div>
        </div> 
    </ng-template>
    <!--<div #capture class="captured"></div>  -->
</div>
