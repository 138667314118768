import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OVBaseService } from './OvBaseService';
import { IChatMessage } from '../views/prototypes/creative/creativeDiscussion.service';



@Injectable({
	providedIn: 'root'
})
export class CreativeChatService extends OVBaseService<IChatMessage, string> {
	
	constructor(protected http: HttpClient) {
		super(http, 'chatMessage');
	}
}
