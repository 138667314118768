import { L } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/api/project.service';
import { WorkflowService } from 'src/app/api/workflow.service';
import { Permission } from 'src/app/models/permissions.model';
import { Project } from 'src/app/models/project.model';
import { IUser, User } from 'src/app/models/user.model';
import { Workflow } from 'src/app/models/workflow.model';
import { RemoveWorkflowActions } from '../remove-workflow-user/remove-workflow-user.component';
import { NoRemoveProjectadminComponent } from '../no-remove-projectadmin/no-remove-projectadmin.component';

export interface RemoveProjectUserData {
	user: User,
	project: Project,
  workflows: Workflow[],
  removeAdminOnly:boolean
}

@Component({
  selector: 'app-remove-project-user',
  templateUrl: './remove-project-user.component.html',
  styleUrls: ['./remove-project-user.component.scss']
})
export class RemoveProjectUserComponent implements OnInit {

  user:IUser;
  workflows:Workflow[];
  workflowActions:RemoveWorkflowActions[] = [];
  admins:IUser[];
  index:number = 0;
  activeWorkflow:Workflow;
  removeAdminOnly:boolean = false;
  numApprovalRoles: number;
  numProductionRoles: number;
  rolesStr: string;
  rolesArr: string[] = [];
  userIsAdmin: boolean;
  userisAmendManager: boolean;
  numUsersChannelCreatives: number = 0;
  showSummary:boolean = false;
  showSteppers:boolean = false;

  //this is bogus?
  autoFinalise: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: RemoveProjectUserData,
    public projectService: ProjectService,
    private snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<RemoveProjectUserComponent>,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    //console.log("DATA:",this.data);

    //this.dialogRef.updateSize('0px','0px');
    //this.dialogRef.addPanelClass('hideMe');
    // TODO confirm we have workflows
    this.user = this.data.user;
    this.workflows = this.data.project['workflows'];
    this.admins = this.data.project['users'].filter(projecUser => projecUser.scoped_permissions & Permission.ADMIN);
    this.removeAdminOnly = this.data.removeAdminOnly;
    this.activeWorkflow = this.workflows[0] ?? null;

    this.userIsAdmin = (this.user.scoped_permissions & Permission.ADMIN) != 0;
    this.userisAmendManager = this.user['moderatorFor']?.length > 0;
    
  
    //dont allow removal if user is the only admin in the project - this should already have been caught in project-workflows coponent, but just in case
    if(this.admins.length == 1 && this.userIsAdmin){
      this.onRemoveSoleAdminPopup(this.user,this.data.project);
      return;
    }

    //if no workflows to remove from, then just remove the user and close
    if(this.workflows.length == 0){
      this.finalise();
      return;
    }

    this.userisAmendManager = false;
    this.numUsersChannelCreatives = 0;
  
    this.workflows.forEach(workflow => {
      //confirm user is AM in any workflow
      if(!this.userisAmendManager){
        const permissions = workflow['perms'].filter(perm => perm.user_id == this.user.uuid);
        this.userisAmendManager = permissions?.length ? ((permissions[0].permissions & Permission.VALIDATOR) != 0) : false;
      }
      
      // we only care about channels that have at least one creative in them or we might get stuck
      //this is too crude.... might have lots of creatives in channels but maybe none that are owned or amended by the user
      this.numUsersChannelCreatives += (workflow.channels.filter(channel => channel['creatives']?.length)).length;
      
      //initialise workflowActions with defaults
      this.workflowActions.push(
        {workflow_uuid:workflow.uuid,
          newAmendManager:'unassign',
          productionActions:{},
          approvalActions:{},
          amendManagerConfirmed:false,
          productionActionsConfirmed:{},
          approvalActionsConfirmed:{},
        });
    })

    //only initialise steppers if user is Amend Manager or has potential creative tasks to re-assign
    if(this.userisAmendManager || this.numUsersChannelCreatives > 0){
      //found user roles to deal with, show summary first
      //get user role stats for summary
      this.numApprovalRoles = 0;
      this.numProductionRoles = 0;
      this.data.workflows.forEach(workflow => {
        //determine which roles overall
        if(workflow['approvalChannels'] && workflow['approvalChannels'][this.user.uuid]) this.numApprovalRoles ++;
        if(workflow['productionChannels'] && workflow['productionChannels'][this.user.uuid]) this.numProductionRoles ++;
      })

      //this.rolesArr = [];
      //if (this.userIsAdmin) this.rolesArr.push('Admin');
      if (this.numApprovalRoles >0) this.rolesArr.push('Approval');
      if (this.numProductionRoles >0) this.rolesArr.push('Production');
      this.rolesStr = this.rolesArr.join(", ");

      this.showSummary = true;
      
      //this.autoFinalise = false;
      
    } else {
      //not an admin and no creatives to deal with, no need for channel steppers... maybe
      //this.showSteppers = true;
      this.finalise();
      return;
    }

    

    
  }

  startSteppers(){
    this.showSummary = false;
    this.showSteppers = true;
    this.dialogRef.updatePosition({top:'10px'});
  }

  //TODO - we could just emit this back up the project-workflows panel to deal with using same comp
  onRemoveSoleAdminPopup(user,project){
		const dialogRef = this.dialog.open(NoRemoveProjectadminComponent, {
			data:{user, project}
		});
    this.close();
	}
  
  workflowActionsComplete(actions:RemoveWorkflowActions)
  {
    this.workflowActions[this.index] = actions;

    //hack - automatically finalise if not and amned manager and no channel creatives to re-assign 
    if(this.canFinalise() && !this.userisAmendManager && this.numUsersChannelCreatives == 0){
      this.finalise();
      return;
     } else if(this.index < this.workflowActions.length-1){
      this.nextWorkflow();
     }
  }
  canPreviousWorkflow()
  {
    // can we always go previous or only when current one is sorted
    if(this.index > 0) return true;
    return false;
  }
  canNextWorkflow()
  {
    if(this.index >= this.workflows.length - 1) return false;
    if(this.workflowActions[this.index] != null) return true;
    return false;
  }
  previousWorkflow()
  {
    //let index = this.data.project['workflows'].indexOf(this.activeWorkflow);
    if(this.index == -1) return;
    if(this.index == 0) return;
    this.index--;
    //this.activeWorkflow = this.data.project['workflows'][index] ?? null;
  }
  nextWorkflow()
  {
    //let index = this.data.project['workflows'].indexOf(this.activeWorkflow);
    if(this.index == -1) return;
    if(this.index == this.workflows.length - 1) return;
    this.index++;
    //this.activeWorkflow = this.data.project['workflows'][index] ?? null;
  }
  // can finialse if all workflows have been actioned (last element in actions array is not null)
  canFinalise()
  {
    return this.workflowActions[this.workflowActions.length-1] != null;
  }
  finalise()
  {
    //console.log("finalise!");
    //remove the user and close panel
    this.projectService.removeUser(this.data.project.uuid, this.user, this.workflowActions).pipe().subscribe(res => {
			//console.log(res);
			this.snackBar.open("user removed", null, {duration: 2000});
			this.close();
		});
  }

  close(){
    this.dialogRef.close();
  }

}
