<h2 mat-dialog-title>New Creative</h2>
<div mat-dialog-content>
    <form autocompleteOff>
        <mat-form-field *ngIf="false">
            <input type="text" matInput [formControl]="formControlName" placeholder="Enter Name" [(ngModel)]="this.data.name" autocompleteOff>
            <mat-error *ngIf="formControlName.hasError('required')">
                Name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Channel</mat-label>
            <mat-select [(value)]="this.data.channel">
              <mat-option *ngFor="let channel of data.channels" [value]="channel">
                {{channel.name}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="false">
            <input type="text" matInput [formControl]="formControlChannel" [matAutocomplete]="autoChannel" placeholder="Enter Channel" [(ngModel)]="this.data.channel" autocompleteOff>
            <mat-error *ngIf="formControlChannel.hasError('required')">
                Channel is <strong>required</strong>
            </mat-error>
            <mat-autocomplete #autoChannel="matAutocomplete">
                <mat-option *ngFor="let option of filteredChannels | async" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field *ngIf="false">
            <input type="text" matInput [formControl]="formControlFormat" [matAutocomplete]="autoFormat" placeholder="Enter Format" [(ngModel)]="this.data.format" autocompleteOff>
            <mat-error *ngIf="formControlFormat.hasError('required')">
                Format is <strong>required</strong>... or is it though?
            </mat-error>
            <mat-autocomplete #autoFormat="matAutocomplete">
                <mat-option *ngFor="let option of filteredFormats | async" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Format</mat-label>
            <mat-select [(value)]="this.data.format">
              <mat-option *ngFor="let format of data.formats" [value]="format">
                {{format.name}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
	<button mat-raised-button [mat-dialog-close]="data" color="primary" [disabled]="!isValid()">Done</button> <!-- (click)="done()"-->
</mat-dialog-actions>

