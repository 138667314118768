
<div id="user-wrapper" class="mat-elevation-z8">
    <h2>User Admin Area</h2>
    <p>Add, edit and remove users</p>
    <p>TODO</p>
    <ul>
        <li>Display more info i.e. roles</li>
        <li>add filter section (dropdowns?) to filter on things like roles</li>
    </ul>
    <mat-card *ngIf="false">
        <!-- TODO filter drop downs in here -->
    </mat-card>
    <mat-card>
        <div class="search-add">
            <mat-form-field appearance="standard">
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="search" (keyup)="applyFilter($event)" #input>
                <button mat-icon-button matSuffix (click)="clearSearch()" [attr.aria-label]="'Clear search'">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="addUser()">Add User</button>
        </div>
    </mat-card>
    <mat-card>
        <table mat-table [dataSource]="dataSource" matSort  matSortActive="name" matSortDirection="asc">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>
            <!-- Roles Column -->
            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                <td mat-cell *matCellDef="let element"> <mat-checkbox disabled [checked]="element.active"></mat-checkbox> </td>
            </ng-container>
            <!-- Roles Column -->
            <ng-container matColumnDef="roles">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Roles </th>
                <td mat-cell *matCellDef="let element"> {{getRoles(element)}} </td>
            </ng-container>
            <!-- Options Column -->
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef> Options </th>
                <td mat-cell *matCellDef="let user">
                <button mat-icon-button>
                    <mat-icon (click)="editUser(user)" matTooltip="Edit User">edit</mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon (click)="deleteUser(user)" matTooltip="Delete User">delete</mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon (click)="impersonateUser(user)" matTooltip="Impersonate">supervisor_account</mat-icon>
                </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
             <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="2">No users matching the search: "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25, 50, 100]"
                    showFirstLastButtons 
                    aria-label="Select page of users">
        </mat-paginator>
    </mat-card>
</div>