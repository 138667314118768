/*
\(•_•)
 (  (>
 /   \

\(•_•)
  )  )>
 /   \

 (•_•)/
 <)  )__
  \  

 (._.)/
 <)  )__
  \ 

 (o_o)/
 <)  )__
  \ 

  \(^▼^)/
 	)  )
	/  \  

  \(^▲^)/
 	)  )
	/  \ 

	\(^■^)/
 	)  )
	/  \ 

\(^°^)/
 )  )
/  \ 


  \(^@^)/
 	)  )
	/  \  
	
*/

/*
submit all amends to -> commit amends

approve creative - only show if no amends from me
unapprove creative - or automatic if amend added

"notify each user of the action they need to take"

Automatic moving between states

Batch numbers


accept/decline - requests
confirm/reject - done

*/
import { Component, OnInit, OnDestroy, SimpleChanges, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AmendState, Amend, AmendEvent, AmendBatch, Creative, CreativeEvent, UserRoles, User, Bit} from './amend-code';
import { tests } from './test';
import { MatSelectionListChange } from '@angular/material/list';

declare var jquery:any;
declare var $ :any;
declare var window :any;

export enum CreativeState{
	Production,
	Approval,
	Done
}

// util (https://stackoverflow.com/questions/38554562/how-can-i-use-ngfor-to-iterate-over-typescript-enum-as-an-array-of-strings)
@Pipe({name: 'enumToArray'})
export class EnumToArrayPipe implements PipeTransform{
	transform(value) : Object {
		return Object.keys(value).filter(e => !isNaN(+e)).map(o => { return {index: +o, name: value[o]}});
	}
}  
@Component({
	selector: 'amend-view',
	templateUrl: './amend-view.component.html',
	styleUrls: ['./amend-view.component.scss'],
})
export class AmendViewComponent implements OnInit, OnDestroy {
	//url: string = "https://oceanview.fivebyfivedigital.com/php/mmpreview.php?type=rsb&w=300&h=250&adid=33656510&x=0&y=0&vid=";
	url: string = "https://ov-staging.dev.fivebyfiveuk.com/bin/609201a791ca3805d5d98f39d72205b65e74a4cc030ba/territory_1/2e1982d19ff0686af7f46838e0e9d2cc5e81c8578974d/300x250_fbf_-_Copy.jpg"
  	urlSafe: SafeResourceUrl;

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	private _testing:boolean = false;
	private _testlog:Array<string> = [];
	
	/* CREATIVE EMULATION */
	public creative:Creative;

	/* USER EMULATION */
	private user_benw:User 		= new User("Ben Whiting", 	"ben.whiting@fivebyfiveglobal.com", 	"dev", [UserRoles.Production]);
	private user_ryanw:User 	= new User("Ryan Walls", 	"ryan.walls@fivebyfiveglobal.com", 		"dev", [UserRoles.Production]);
	private user_alecm:User 	= new User("Alec Moors", 	"alec.moors@fivebyfiveglobal.com", 		"approver", [UserRoles.Approval]);
	private user_adaml:User 	= new User("Adam Lawrance", "adam.lawrance@fivebyfiveglobal.com", 	"approver", [UserRoles.Approval]);
	private user_alexj:User 	= new User("Alex James", 	"alex.james@fivebyfiveglobal.com", 		"pm", [UserRoles.Approval, UserRoles.ApprovalManager]);
	private user_elliec:User 	= new User("Ellie Crew", 	"ellie.crew@fivebyfiveglobal.com", 		"pm", [UserRoles.Approval, UserRoles.ApprovalManager]);
	private user_clintc:User 	= new User("Clint Client", 	"clint.client@client.com", 				"approver basic", [UserRoles.ApprovalBasic], 1);
	
	public users:User[] = [
		this.user_ryanw,
		this.user_alecm,
		this.user_elliec,
		this.user_clintc,
	]
	public currentUser:User;

	
	// expose enums to html template
	get CreativeState() { return CreativeState; }
	get UserRoles() { return UserRoles; }
	get AmendState() { return AmendState; }
	get Bit() { return Bit; }
	get testNames() { return Object.keys(tests)};
	getUserRoles(user:User):string
	{
		return user.roles.map(function(value){return UserRoles[value]}).join(",");
	}
	getAllRoles()
	{
		return Object.keys(UserRoles).filter(e => !isNaN(+e)).map(i => {return UserRoles[i]});//.filter(e => !isNaN(+e)).map(o => { return {index: +o, name: UserRoles[o]}});
	}
	

	// default state is production
	private _state:CreativeState = CreativeState.Production;
	get state():CreativeState
	{
		return this.getStateForUser(this.currentUser);		
	}
	set state(value:CreativeState)
	{
		//console.info(`setting state: old - ${CreativeState[this._state]} new - ${CreativeState[value]}`);
		if(this._state === value) return;
		if(this._state === CreativeState.Approval && value === CreativeState.Production)
		{
			// mark all new and requested amends as STATE_TODO
			let newAmends = this.searchAllAmends({state:state => state == AmendState.NEW || state == AmendState.REQUESTED});
			for (let i = 0; i < newAmends.length; i++) {
				const amend = newAmends[i];
				amend.state = AmendState.TODO;			
			}
		}
		if(this._state === CreativeState.Production && value === CreativeState.Approval)
		{
			// increment the creative version
			// FIXME TODO only bump if file has changed i.e. new upload
			this.version++;
		}

		this.clearApprovalsAndCommits();
		this._state = value;
	}
	private getStateForUser(user:User):CreativeState
	{
		if(user.hasRole(UserRoles.ApprovalBasic))
		{
			if(this._state === CreativeState.Approval)
			{
				// only bother check if we have an priority above 0 otherwise no need to check
				if(user.approvalPriority > 0)
				{
					// has everyone else at higher priorites commited/approved
					let allReady:boolean = true;
					let numAvailable:number = 0;	// need this to be at least 1
					for (let i = 0; i < this.users.length; i++) {
						const otherUser:User = this.users[i];
						// no need to compare those with dev roles
						if(!otherUser.hasRole(UserRoles.Approval) && !otherUser.hasRole(UserRoles.ApprovalManager) && !otherUser.hasRole(UserRoles.ApprovalBasic)) continue;
						// no need to compare with self
						if(otherUser === user) continue;
						// no need to check with those of equal or lower standing
						if(otherUser.approvalPriority >= user.approvalPriority) continue;
						numAvailable++;
						//only if all other available users have approved can the user se approval state
						if(!this.hasUserApproved(otherUser))	//&& !this.hasUserCommitted(otherUser)
						{
							allReady = false;
							break;
						}
					}
					if(numAvailable > 0 && !allReady) return CreativeState.Production;
				}

				// can the user see it yet.. not if there are any unapproved ammends
				let amends = this.searchAllAmends({author:user, state:AmendState.DONE})
				if(amends.length) return CreativeState.Production;
				
				amends = this.searchAllAmends({author:user, state:AmendState.TODO})
				if(amends.length) return CreativeState.Production;

				amends = this.searchAllAmends({author:user, state:AmendState.DECLINED_PRODUCTION})
				if(amends.length) return CreativeState.Production;

				return this._state;

			}else{
				return this._state;
			}			
		}else{
			return this._state;
		}
	}
	// amend batches
	amendBatches:AmendBatch[] = [];
	currentAmendBatch:AmendBatch;

	// version
	version:number = 0;

	// approvals
	approvals = [];
	// commits
	commits = [];
	
	//public sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, public api:ApiService,  public ds:DialogService
	constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, public api:ApiService,  public ds:DialogService) { }
	
	ngOnInit() {
		//url

		this.urlSafe = this.sanitizer ? this.sanitizer.bypassSecurityTrustResourceUrl(this.url) : "";
		// setup creative
		this.creative = new Creative("mpu");

		// setup user
		this.currentUser = this.users[0];
		

		window.search = this.searchAllAmends.bind(this);
		window.debugAmends = this;

		this.runAllTests();
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	ngOnChanges(changes: SimpleChanges) {
		for (let propName in changes) {
		  let chng = changes[propName];
		  let cur  = JSON.stringify(chng.currentValue);
		  let prev = JSON.stringify(chng.previousValue);
		}
	  }
	/**
	 * Does the user have role matching state
	 */
	isUserRoleMatchState(state:CreativeState = this.state, user:User = this.currentUser):boolean
	{
		if(state != this.state) return false;
		switch (state) {
			case CreativeState.Production:
					return user.hasRole(UserRoles.Production)
				break;
			case CreativeState.Approval:
					return user.hasRole(UserRoles.Approval) || user.hasRole(UserRoles.ApprovalBasic) || user.hasRole(UserRoles.ApprovalManager)
				break;
			default:
				return false;
				break;
		}
	}
/*
	 _____               _            _   _             
	|  __ \             | |          | | (_)            
	| |__) | __ ___   __| |_   _  ___| |_ _  ___  _ __  
	|  ___/ '__/ _ \ / _` | | | |/ __| __| |/ _ \| '_ \ 
	| |   | | | (_) | (_| | |_| | (__| |_| | (_) | | | |
	|_|   |_|  \___/ \__,_|\__,_|\___|\__|_|\___/|_| |_|                                          
*/

	/**
	 * Is the complete button visible
	 * TODO this could be derived from the current stage. i.e. currentStage.mainAction or something/
	 */
	isCompleteCreativeVisible()
	{
		// is the user the right kind of user
		return this.isUserRoleMatchState(CreativeState.Production);
	}

	/**
	 * Can the creative be completed?
	 * State should be in production.
	 * User should have proudction role.
	 * 
	 * . i.e. are there no amends or are all amends complete or rejected
	 */								  
	canCompleteCreative():boolean
	{
		// is the state and user role correct
		
		if(this.isUserRoleMatchState(CreativeState.Production))
		{
			// are any amends in any of the batches not completed or 
			// we could probably optimise this by having the ability to close/lock a batch once it is "complete"
			let unactionedAmends = this.searchAllAmends({open:true, state:AmendState.TODO})
			/*
			for (let i = 0; i < this.amendBatches.length; i++) {
				const amendBatch:AmendBatch = this.amendBatches[i];
				for (let j = 0; j < amendBatch.amends.length; j++) {
					const amend:Amend = amendBatch.amends[j];
					if(amend.open)
					{
						if(	amend.state !== AmendState.DONE &&
							amend.state !== AmendState.DONE_CONFIRMED &&
							amend.state !== AmendState.REJECTED && 
							amend.state !== AmendState.RECALLED) return false;
					}
				}				
			}*/
			// no amends or all amends are ok
			return unactionedAmends.length === 0;
		}
		return false;
	}
	/**
	 * Mark the creative as complete and move it to the next stage of the chain
	 */
	completeCreative():boolean
	{
		if(!this.canCompleteCreative())
		{
			return false;
		}

		this.creative.addEvent(new CreativeEvent(CreativeEvent.TYPE_COMPLETED, this.currentUser));

		// move creative into approval state
		this.state = CreativeState.Approval;
		
		// add the new amend batch
		// TODO should this be added on first amend automatically? - does it matter?
		this.currentAmendBatch = new AmendBatch(`version: ${this.version}`, []);
		this.amendBatches.push(this.currentAmendBatch);

		return true;
	}
	clearApprovalsAndCommits()
	{
		// clear all approvals and commits
		this.approvals.length = 0;
		this.commits.length = 0;
	}
	amendActionAcceptRequest(batch:AmendBatch, amend:Amend)
	{
		// from requested to new
		if(amend.rejected)	amend.rejected = false;
		if(!amend.accepted)
		{
			amend.accepted = true;
		}else{
			amend.addEvent(new AmendEvent(AmendEvent.TYPE_REQUEST_ACCEPTED, this.currentUser, amend.acceptedMessage));
			amend.state = AmendState.NEW;
			// clear state
			amend.accepted = false;
			this.isCreativeApproved();
		}
		
	}
	amendActionDeclineRequest(batch:AmendBatch, amend:Amend)
	{
		// from requested to declined

		// clear any existing state
		if(amend.accepted){
			amend.accepted = false;
			amend.acceptedMessage = null;
		}
		if(!amend.declined)
		{
			amend.declined = true;
		}else{
			if(amend.declinedMessage != null && amend.declinedMessage.length > 0)
			{
				amend.addEvent(new AmendEvent(AmendEvent.TYPE_DECLINED, this.currentUser, amend.declinedMessage));
				amend.state = this.state === CreativeState.Production ? AmendState.DECLINED_PRODUCTION : AmendState.DECLINED;
				
				// clear state
				amend.declined = false;

				// automatically uncommit the amend author if needs be
				// this should be true
				if(this.hasUserCommitted(amend.author))
				{
					//this.removeUserComit(amend.author);
				}
				this.isCreativeApproved();
			}			
		}
	}
		// clear other s
	/**
	 * If in production this will mark an amend in the TODO state as DONE with an optional message
	 * If current user is an approve and the amend has been marked as DONE then this will confirm it (might make this own function)
	 * 
	 * @param batch 
	 * @param amend 
	 */
	amendActionComplete(batch:AmendBatch, amend:Amend)
	{
		// clear other state
		if(amend.rejected)	amend.rejected = false;
		if(!amend.completed)
		{
			amend.completed = true;
		}else{
			amend.addEvent(new AmendEvent(AmendEvent.TYPE_COMPLETED, this.currentUser, amend.completedMessage));
			amend.state = AmendState.DONE;
			// clear state
			amend.completed = false;
			amend.completedMessage = null;
		}
	}

/*
                                           _ 
     /\                                   | |
    /  \   _ __  _ __  _ __ _____   ____ _| |
   / /\ \ | '_ \| '_ \| '__/ _ \ \ / / _` | |
  / ____ \| |_) | |_) | | | (_) \ V / (_| | |
 /_/    \_\ .__/| .__/|_|  \___/ \_/ \__,_|_|
          | |   | |                          
          |_|   |_|                          
*/

	/**
	 * Approve the creative and advance the workflow chain
	 */
	approveCreative():boolean
	{
		if(!this.canApproveCreative()) return false;
		if(this.hasUserCommitted()) this.removeUserComit();
		// TODO ensure user has not already approved of this version
		this.creative.addEvent(new CreativeEvent(CreativeEvent.TYPE_APPROVED, this.currentUser));
		this.approvals.push({version:this.version, user:this.currentUser});
		this.isCreativeApproved();
		return true;
	}
	/**
	 * Has the given user approved this version of the creative
	 * @param user 
	 */
	hasUserApproved(user:User = this.currentUser):boolean
	{
		return this.approvals.find(approval => approval.version === this.version && approval.user === user) !== undefined;
	}
	removeUserApproval(user:User = this.currentUser)
	{
		let approval = this.approvals.find(approval => approval.version === this.version && approval.user === user);
		const index =  this.approvals.indexOf(approval);
		if(index != -1) this.approvals.splice(index, 1);
	}
	/**
	 * Has the given user commited this version of the creative
	 * TODO could we 
	 * @param user 
	 */
	hasUserCommitted(user:User = this.currentUser)
	{
		return this.commits.find(commit => commit.version === this.version && commit.user === user) !== undefined;
	}
	removeUserComit(user:User = this.currentUser)
	{
		let commit = this.commits.find(commit => commit.version === this.version && commit.user === user);
		const index =  this.commits.indexOf(commit);
		if(index != -1) this.commits.splice(index, 1);
	}
	/**
	 * Check if the creative is approved by everyone and has no ammends
	 */
	isCreativeApproved():boolean
	{
		if(this.state !== CreativeState.Approval) return false;

		// cannot be fully approved if any are rejected and not acknowledged
		let rejectedAmends = this.searchAllAmends({open:true, state:AmendState.REJECTED});
		if(rejectedAmends.length) return false;

		// cannot be fully approved if any are declined and not acknowledged
		let declinedAmends = this.searchAllAmends({open:true, state:AmendState.DECLINED});
		if(declinedAmends.length) return false;

		// loop through all approver users and see if they have approved
		let numApprovers = 0;
		let numApprovals = 0;
		let numCommits = 0;
		for (let i = 0; i < this.users.length; i++) {
			const user:User = this.users[i];
			// TODO make these bitwise so can be both
			if(user.hasRole(UserRoles.Approval) ||user.hasRole(UserRoles.ApprovalBasic) )
			{
				// check if this user is able to approve
				if(this.getStateForUser(user) === CreativeState.Production) continue;
				numApprovers++;
				if(this.hasUserApproved(user))  numApprovals++;
				if(this.hasUserCommitted(user))  numCommits++;
			}
		}

		// no approvers so cannot approve
		if(numApprovers === 0) return false;
		
		// Everyone has approved this version
		if(numApprovals === numApprovers)	// TODO do we need a minimum number of approvers?
		{
			// check if we have any amends todo if so go back to production,
			// if not we are done
			let amends = this.searchAllAmends({state:AmendState.TODO});
			if(amends.length)
			{
				this.state = CreativeState.Production;
				return;
			}
			// we are approved
			this.state = CreativeState.Done;
		}
		// Everyone has either approved or committed their amend requests
		if(numCommits > 0 && (numApprovals + numCommits === numApprovers))
		{

			// are their any amends that need to be request approved, TODO add version to filter
			let amendsToBeApproved = this.searchAllAmends({state:AmendState.REQUESTED});
			if(amendsToBeApproved && amendsToBeApproved.length)
			{
				// cannot proceed
				return;
			}

			// back to production we go only if there are amends that needs doing
			let amendsNeedingProuction = this.searchAllAmends({state:state => state === AmendState.NEW || state === AmendState.REQUESTED || state === AmendState.TODO})
			if(amendsNeedingProuction.length)
			{
				this.state = CreativeState.Production;
			}
		}
	}

	/**
	 * Can the creative be approved given the current state
	 */
	canApproveCreative():boolean
	{
		// is the user the right kind of user
		if(this.isUserRoleMatchState(CreativeState.Approval))
		{
			// have they approved of this version already?
			if(this.hasUserApproved()) return false;

			if(this.currentUser.hasRole(UserRoles.ApprovalManager))
			{
				// any done amends authored by basic users that need confirmation
				let doneAmends = this.searchAllAmends({state:AmendState.DONE, author:user => user.hasRole(UserRoles.ApprovalBasic)});
				if(doneAmends.length)
				{
					return false;
				}
			}


			// old - have they added any amends to this version
			// new - have they got any open amends against any version?
			let openAmends = this.searchAllAmends({author:this.currentUser, open:true});
			return openAmends.length ? false : true;
		}
		return false;
	}

	/**
	 * Delete an amend request
	 * Cannot delete amends that are open/published
	 */
	amendActionDelete(batch:AmendBatch, amend:Amend)
	{
		batch.remove(amend);
	}
	/**
	 * Edit an amend request
	 */
	amendActionEdit(batch:AmendBatch, amend:Amend)
	{
		amend.editable = true;
	}
	/**
	 * Save an amend request
	 */
	amendActionSave(batch:AmendBatch, amend:Amend)
	{
		amend.editable = false;
	}
	/**
	 * Recall an amend, @adam can this happen at any stage once submitted? I think so.
	 * @param batch 
	 * @param amend 
	 */
	amendActionRecall(batch:AmendBatch, amend:Amend)
	{
		// only the amend author can recall the amend
		if(amend.author !== this.currentUser) return false;

		// Can only recall if not new + (TODO) not already fully complete and approved by all parties
		if(amend.state !== AmendState.NEW)
		{
			if(!amend.recalled)
			{
				amend.recalled = true;
			}else{
				if(amend.recalledMessage != null && amend.recalledMessage.length > 0)
				{
					amend.addEvent(new AmendEvent(AmendEvent.TYPE_RECALLED, this.currentUser, amend.recalledMessage));
					amend.state = AmendState.RECALLED;
					amend.open = false;	//TODO do we need to rely on this?
					// reset state
					amend.recalled = false;
					amend.recalledMessage = null;
				}				
			}			
		}
	}
	actionAcknowledgeRejection(batch:AmendBatch, amend:Amend){
		
		if(!this.isUserRoleMatchState()) return;
		//if(this.currentUser !== amend.author) return;

		amend.addEvent(new AmendEvent(AmendEvent.TYPE_REJECTION_ACKNOWLEDGED, this.currentUser));
		// update the state
		amend.state = AmendState.TODO;

		this.isCreativeApproved();
	}
	actionAcknowledgeDeclined(batch:AmendBatch, amend:Amend):boolean{
		// TODO could do better and esure this is possible with same logic from html template or move that into a function
		if(!this.isUserRoleMatchState(CreativeState.Approval)) return false;

		if(this.currentUser === amend.author)
		{
			amend.addEvent(new AmendEvent(AmendEvent.TYPE_DECLINE_ACKNOWLEDGED, this.currentUser));
			amend.state = AmendState.DECLINE_ACKNOWLEDGED;
			amend.open = false;
			this.isCreativeApproved();
			return true;
		}else if(this.currentUser.hasRole(UserRoles.ApprovalManager) && amend.author.hasRole(UserRoles.ApprovalBasic))
		{
			amend.addEvent(new AmendEvent(AmendEvent.TYPE_DECLINE_ACKNOWLEDGED, this.currentUser));
			amend.state = AmendState.DECLINE_ACKNOWLEDGED;
			return true;
		}
		return false;			
	}
	actionConfirmDone(batch:AmendBatch, amend:Amend):boolean{
		// safety first
		if(!this.isUserRoleMatchState()) return false;

		// is the amend to be confirmed by the approval manager first
		if(this.currentUser.hasRole(UserRoles.ApprovalManager) && amend.author.hasRole(UserRoles.ApprovalBasic))
		{
			amend.addEvent(new AmendEvent(AmendEvent.TYPE_CONFIRMED, this.currentUser));
			amend.state = AmendState.DONE_CONFIRMED;
			// this does not close the amend
			return true;
		}
		// only the author can confirm (TODO ...for now)
		if(this.currentUser !== amend.author) return false;

		amend.addEvent(new AmendEvent(AmendEvent.TYPE_CONFIRMED, this.currentUser));
		amend.state = AmendState.DONE_CONFIRMED;
		amend.open = false;
		return true;
	}
	actionRejectDone(batch:AmendBatch, amend:Amend){

		// safety first
		if(!this.isUserRoleMatchState()) return;

		// only the author or amend managers can reject (TODO ...for now)
		if(this.currentUser !== amend.author && !(this.currentUser.hasRole(UserRoles.ApprovalManager) && amend.author.hasRole(UserRoles.ApprovalBasic))) return;

		if(!amend.rejected)
		{
			amend.rejected = true;
		}else{
			if(amend.rejectedMessage)
			{
				amend.addEvent(new AmendEvent(AmendEvent.TYPE_DONE_REJECTED, this.currentUser, amend.rejectedMessage));
				// Not done so goes back to TODO
				if(this.currentUser.hasRole(UserRoles.Approval))
				{
					amend.state = AmendState.TODO;
				}else if(this.currentUser.hasRole(UserRoles.ApprovalBasic))
				{
					amend.state = AmendState.REJECTED;	//AmendState.TODO;
				}
				
				// reset state
				amend.rejected = false;
				amend.rejectedMessage = null;
				this.isCreativeApproved();
			}else{

			}
		}
		
	}

	/*
	recall an amend (maybe only if not yet started i.e.) - with comment!

	confirm done
	reject done, with comment required
	accept rejection
	reject rejection - END GAME*/
/*
                                    _   __  __                                   
     /\                            | | |  \/  |                                  
    /  \   _ __ ___   ___ _ __   __| | | \  / | __ _ _ __   __ _  __ _  ___ _ __ 
   / /\ \ | '_ ` _ \ / _ \ '_ \ / _` | | |\/| |/ _` | '_ \ / _` |/ _` |/ _ \ '__|
  / ____ \| | | | | |  __/ | | | (_| | | |  | | (_| | | | | (_| | (_| |  __/ |   
 /_/    \_\_| |_| |_|\___|_| |_|\__,_| |_|  |_|\__,_|_| |_|\__,_|\__, |\___|_|   
                                                                  __/ |          
                                                                 |___/           
*/
//------------------------------
	isAmendNew(amend:Amend):boolean
	{
		return amend.state === AmendState.NEW || amend.state === AmendState.REQUESTED;
	}

	addAmendRequest():Amend
	{
		if(this.canAddAmendRequest())
		{
			if(this.hasUserApproved())	this.removeUserApproval();
			if(this.hasUserCommitted()) this.removeUserComit();
			const amend = new Amend(this.currentUser);
			amend.editable = true;
			amend.open = true;
			amend.version = this.version;
			// does this amend need managing?
			if(this.currentUser.hasRole(UserRoles.ApprovalBasic))
			{
				amend.state = AmendState.REQUESTED;
			}
			this.currentAmendBatch.add(amend);
			return amend;
		}
		return null;
	}
	
	updateAmendRequest(event:any)
	{
		//console.log("update", event.target.value);
	}

	/**
	 * TODO improve or remove
	 */
	isAmendActionsVisible():boolean
	{
		// is the user the right kind of user
		return this.isUserRoleMatchState(CreativeState.Approval);
		/*
		// is the user the right kind of user
		if(this.chain.currentStage.users.indexOf(this.currentUser) === -1) return false;

		// are we at a request or manage stage
		if(	this.chain.currentStage.type === ChainStage.TYPE_APPROVAL || 
			this.chain.currentStage.type === ChainStage.TYPE_APPROVAL_REQUEST ||
			this.chain.currentStage.type === ChainStage.TYPE_MANAGE ) return true;

		// we at a build stage in chain
		return false;
		*/
	}
	/**
	 * If the user is an approver they can ALWAYS add and amend if the creative is in an approval state
	 */
	canAddAmendRequest():boolean
	{
		// is the user the right kind of user
		return this.isUserRoleMatchState(CreativeState.Approval);
	}

	/**
	 * Submit a batch of amend requests
	 */
	submitAmendRequests():boolean
	{
		if(!this.canSubmitAmendRequests()) return false;
		if(this.isUserRoleMatchState(CreativeState.Approval))
		{
			if(this.hasUserApproved()) this.removeUserApproval();

			this.commits.push({version:this.version, user:this.currentUser});
			this.isCreativeApproved();
		}
		return true;
		/*
		// TODO should we double check they are ok for submitting? - maybe not as backend will check
		for (let index = 0; index < this.amendRequests.amends.length; index++) {
			const amend:Amend = this.amendRequests.amends[index];
			// TODO - better approach for this
			if(this.chain.currentStage.type === ChainStage.TYPE_APPROVAL_REQUEST)
			{
				amend.addEvent(new AmendEvent(AmendEvent.TYPE_REQUESTED, this.currentUser));
				amend.state = AmendState.REQUESTED;
			}else if(this.chain.currentStage.type === ChainStage.TYPE_APPROVAL)
			{
				amend.addEvent(new AmendEvent(AmendEvent.TYPE_STARTED, this.currentUser));
				amend.state = AmendState.TODO;
			}else{
				//error
				console.warn("problem with amend request");
			}
			
		}


		// only create a new request batch when submit pressed for a new set
		if(this.amendRequests.amends.length)
		{
			this.amendRequests = new AmendBatch("new requests", []);
			this.amendBatches.push(this.amendRequests);
		}
		

		this.chain.prevStage();
*/



	}
	/*
	
		TODO improve this drastically

		canSubmitNewBatch - approval
		canSubmitRequestBatch - amend manager 
		canSubmitCompletedBatch
		canSubmitRejectedBatch
		canSubmitConfirmedBatch( no? is this different to approve button?)
	*/
	/**
	 * Can the current user submit an ammend request
	 * false if
	 * - state is not approval or user is not an approver
	 * - they have already submitted (commited)
	 * - there are declined amends to acknowledge first
	 * 
	 * true if
	 * - there are any amends that are ???!!!
	 */
	canSubmitAmendRequests():boolean
	{
		if(this.isUserRoleMatchState(CreativeState.Approval))
		{
			// have we already committed? If so then can't submit(commit again)
			if(this.hasUserCommitted()){
				if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, user already commited");
				return false;
			}
			if(this.hasUserApproved()){
				if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, user already approved");
				return false;
			}

			//TODO declined_aack...
			//let openValidAmends = this.currentUser.hasRole(UserRoles.ApprovalBasic) ? 
			//						this.searchAllAmends({author:this.currentUser, open:true, state:(state) => (state != AmendState.NEW && state != AmendState.DECLINED && state != AmendState.DECLINED_PRODUCTION )}) :
			//						this.searchAllAmends({author:this.currentUser, open:true, state:(state) => (state != AmendState.DECLINED_PRODUCTION )});
			let openValidAmends = this.currentUser.hasRole(UserRoles.ApprovalBasic) ? 
									this.searchAllAmends({author:this.currentUser, open:true, state:(state) => (state == AmendState.REQUESTED || state == AmendState.REJECTED || state == AmendState.TODO )}) :
									this.searchAllAmends({author:this.currentUser, open:true, state:(state) => (state == AmendState.NEW || state == AmendState.REJECTED || state == AmendState.TODO )});
			/*let openValidAmends2 = this.currentUser.hasRole(UserRoles.ApprovalManager) ? 
									this.searchAllAmends({author:!this.currentUser, open:true, state:(state) => (state == AmendState.NEW || state == AmendState.TODO )}) :
									[];
			debugger;
			openValidAmends = openValidAmends.concat(openValidAmends2);*/
			if(openValidAmends.length === 0){
				if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, no open valid amends");
				return false;
			}
			let notReadyAmends = this.currentUser.hasRole(UserRoles.ApprovalBasic) ? 
									this.searchAllAmends({author:this.currentUser, open:true, state:AmendState.REQUESTED}) :
									this.searchAllAmends({author:this.currentUser, open:true, state:AmendState.NEW});
			for (let i = 0; i < notReadyAmends.length; i++) {
				const amend = notReadyAmends[i];
				if(!amend.request || amend.request === "" || amend.editable){
					if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, amend not ready to submit");
					return false; 
				}
			}
			// check if there actually anything required if not return false..
			return true;
			/*
			if(this.currentUser.hasRole(UserRoles.ApprovalBasic))
			{
				let declinedAmends = this.searchAllAmends({author:this.currentUser, open:false, state:AmendState.DECLINED});
				if(declinedAmends.length){
					if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, there are declined amends to resolve first");
					return false;
				}
				// TODO does this mattter now? are any of my amends rejected and open that need attention
				let rejectedAmends = this.searchAllAmends({author:this.currentUser, open:true, state:AmendState.REJECTED});
				if(rejectedAmends.length){
					if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, there are rejected amends to resolve first");
					return false;
				}
			}

			// check for any open amends that are todo
			//let amends = this.searchAllAmends({user:this.currentUser, open:true, state:state => state != AmendState.NEW});
			let amends = this.searchAllAmends({author:this.currentUser, open:true, state:AmendState.TODO});
			let anyOpenAmends = amends.length ? true : false;

			// have we requested any amends for this version
			let allRequestsSubmittable:boolean = true;
			let anyAmendsToSubmit:boolean = false;
			for (let i = 0; i < this.currentAmendBatch.amends.length; i++) {
				const amend = this.currentAmendBatch.amends[i];
				// is it my amend
				if(amend.author === this.currentUser)
				{
					anyAmendsToSubmit = true;
					if(!amend.request || amend.request === "" || amend.editable) allRequestsSubmittable = false; 
				}				
			}
			let canSubmit = anyOpenAmends == true || (anyAmendsToSubmit && allRequestsSubmittable);
			if(canSubmit && this._testing) this._testlog.push(`canSubmitAmendRequests()->true, anyOpenAmends:${anyOpenAmends}, anyAmendsToSubmit:${anyAmendsToSubmit}, allRequestsSubmittable:${allRequestsSubmittable}`);
			return canSubmit;
			*/
		}
		if(this._testing) this._testlog.push("canSubmitAmendRequests()->false, user and role not matching state");
		return false;
/*
		// only a user in this stage an do this
		if(!this.chain.currentStage.isUserInStage(this.currentUser))	return false;

		// no amend batches so cannot submit anything (this should never happen)
		if(this.amendBatches.length == 0) return false;

		// if the stage is an approval stage
		if(this.chain.currentStage.type === ChainStage.TYPE_APPROVAL || this.chain.currentStage.type === ChainStage.TYPE_APPROVAL_REQUEST)
		{
			// if there are any amends in the request batch are we dealing with a new amend batch
			// check each amend to be sure it can be submitted
			if(this.amendRequests.amends.length)
			{
				//return this.amendRequests.canSubmit();
				let allRequestsSubmittable:boolean = true;
				for (let i:number = 0; i < this.amendRequests.amends.length; i++) {
					const amend:Amend = this.amendRequests.amends[i];					
					if(!amend.request || amend.request === "" || amend.editable) allRequestsSubmittable = false; 
				}
				return allRequestsSubmittable;
			}
			// this could be improved but it ensure that there is at least one submitted batch
			if(this.amendBatches.length > 1)
			{
				// are there at least 1 rejected amends to be submitted back
				const currentBatch:AmendBatch = this.amendBatches[this.amendBatches.length-2];
				let allAmendsActioned:boolean = true;
				let isOneOrMoreRejected:boolean = false;
				for (let i:number = 0; i < currentBatch.amends.length; i++) {
					const amend:Amend = currentBatch.amends[i];
					console.log("amend", amend);
					// is an amend rejected (and therefore state TODO)
					if(amend.state === AmendState.TODO) isOneOrMoreRejected = true;
					//else if(amend.state)
				}
				return allAmendsActioned && isOneOrMoreRejected;
			}else{
				return false;
			}

		}
		if(this.chain.currentStage.type === ChainStage.TYPE_MANAGE)
		{
			// TODO improved grab of last amends
			const currentBatch = this.amendBatches[this.amendBatches.length-2];
			for (let i = 0; i < currentBatch.amends.length; i++) {
				const amend = currentBatch.amends[i];
				if(amend.state !== AmendState.TODO)	return false;
			}
			return true;
		}
		return false;
		if(this.chain.currentStage.type === ChainStage.TYPE_MANAGE)
		{
			// TODO improved grab of last amends
			const currentBatch = this.amendBatches[this.amendBatches.length-2];
			for (let i = 0; i < currentBatch.amends.length; i++) {
				const amend = currentBatch.amends[i];
				if(amend.state !== AmendState.TODO)	return false;
			}
			return true;
		}else if(this.chain.currentStage.type === ChainStage.TYPE_APPROVAL){
			// are we dealing with a new amend batch
			if(this.amendRequests.amends.length)
			{
				return this.amendRequests.canSubmit();
			}else{

				const batch = this.amendBatches[this.amendBatches.length-1];
				for (let i = 0; i < batch.amends.length; i++) {
					const amend = batch.amends[i];
					if(amend.state !== AmendState.REJECTION_ACKNOWLEDGED)	return false;
				}
				return true;
			}
		}else{
			return this.amendRequests.canSubmit();
		}
		*/
	}

	/**
	 * Reject a requested amend, done by amend managers (should also have a message)
	 * 
	 * @param batch 
	 * @param amend 
	 */
	rejectAmendRequest(batch:AmendBatch, amend:Amend)
	{
		// FIXME - can we deleted
		throw "should not ever be called";
		
		amend.state = AmendState.REJECTED;
		amend.addEvent(new AmendEvent(AmendEvent.TYPE_REJECTED, this.currentUser, amend.rejectedMessage));
		amend.open = false;	// TODO remove this?
	}

	/**
	 * UTILS
	 */
	messageForUser(user:User = this.currentUser):string
	{
		let messages = [];
		let state = this.getStateForUser(user);
		if(state === CreativeState.Production)
		{
			if(user.hasRole(UserRoles.Production))
			{
				let openAmends:Amend[] = this.searchAllAmends({open:true, state:AmendState.TODO});
				if(openAmends.length)
				{
					messages.push(`${openAmends.length} amends to be completed`);
				}else{
					messages.push('Need to complete the creative');
				}				
			}else{
				messages.push('Creative is in production');
			}
		}else if(state === CreativeState.Approval)
		{
			if(user.hasRole(UserRoles.Approval) || user.hasRole(UserRoles.ApprovalBasic))
			{
				if(this.hasUserApproved(user))
				{
					messages.push('You have approved.');
				}else if(this.hasUserCommitted(user))
				{
					messages.push('You have submitted.');
				}else{
					// have they got any amends to action?
					let openAmends = this.searchAllAmends({author:user, open:true});
					if(openAmends.length)
					{
						// TODO could get granular here and specify done count and rejected count
						messages.push(`you have ${openAmends.length} amend(s) to deal with.`);
					}else{
						// have they got any uncommited (new) amends?
						let newAmends = this.searchAllAmends({author:user, state:AmendState.NEW});
						if(newAmends.length)
						{
							messages.push(`you have ${newAmends.length} unsubmitted amends so please submit them or add more first.`);
						}else{
							messages.push("Approve this creative or add any amend requests and submit.");
						}
					}

				}
			}
			if(user.hasRole(UserRoles.ApprovalBasic))
			{
				// are there any amends that I have requested that have been rejected and I need to acknowledge
				let rejectedAmends = this.searchAllAmends({version:this.version, state:AmendState.REJECTED});
				if(rejectedAmends.length)
				{
					messages.push(`You have ${rejectedAmends.length} rejected amend(s) to acknowledge.`);
				}
			}
			if(user.hasRole(UserRoles.ApprovalManager))
			{
				// are there any amends that require approval from the manager
				let requestedAmends = this.searchAllAmends({version:this.version, state:AmendState.REQUESTED});
				if(requestedAmends.length)
				{
					messages.push(`You have ${requestedAmends.length} amend(s) to approve/reject.`);
				}
				// any done amends authored by basic users that need confirmation, NB version is previous version now!!
				let doneAmends = this.searchAllAmends({state:AmendState.DONE, author:user => user.hasRole(UserRoles.ApprovalBasic)});
				if(doneAmends.length)
				{
					messages.push(`You have ${doneAmends.length} done amend(s) to confirm/reject.`);
				}
			}
			if(user.hasRole(UserRoles.Production)){
				messages.push("No action required as creative is out for approval.");
			}
			
		}else if(state === CreativeState.Done)
		{
			messages.push('No action required as creative is done');
		}
		return messages.join("\n");
	}
	messagesForAllUsers():object[]
	{
		let messages = [];
		for (let i = 0; i < this.users.length; i++) {
			const user = this.users[i];
			messages.push({user:user, message:this.messageForUser(user)});
		}
		return messages;
	}
	//https://stackoverflow.com/questions/31831651/javascript-filter-array-multiple-conditions
	searchAllAmends(filter:object):Amend[]
	{
		let results:Amend[] = [];
		for (let i = 0; i < this.amendBatches.length; i++) {
			const batch = this.amendBatches[i];
			let amendResults = batch.amends.filter(this.filterArray, filter);
			results = results.concat(amendResults);
			//results.push(...amendResults);			
		}
		return results;
	}
	//https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
	filterArray(item) {
		return Object.keys(this).every((key) => {
			if (typeof this[key] === 'function')
			{
				return this[key](item[key]);
			}else if (Array.isArray(this[key])){
				return this[key].indexOf(item[key]) !== -1;
			}else{
				return item[key] === this[key];
			}		
		});
		// simpler comparisson approach
		// return Object.keys(this).every((key) => amend[key] === this[key]);
	  }
	/* CHAIN SECTION*/
	isStageActionsVisible()
	{
		// TODO
		return true;
		//return this.chain.currentStage.users.indexOf(this.currentUser) !== -1;
	}


	
	utilTestLastAmendBatch(test:Function):boolean
	{
		/*
		const batch = this.amendBatches[this.amendBatches.length-1];
		for (let i = 0; i < batch.amends.length; i++) {
			const amend = batch.amends[i];
			if(!test(amend))return false;
		}
		return true;
		*/
		return false;
	}


	
	/* END CHAIN SECTION */
	trackByIndex(index: number, obj: any): any {
		return index;
	}

	tooltipSubmitAmendRequest(){
		//console.log("boom");
		return "hello";
	}



	/* TESTING */
	private assert(condition:any, value:any = true)
	{
		return condition == value;
	}
	private runAllTests()
	{
		this._testing = true;
		//this.runTest(tests.test_0all_approve);
		//this.runTest(tests.test_1directAmend_approve);
		//this.runTest(tests.test_2directAmend_devDecline);
		///this.runTest(tests.test_3directAmend_directReject);
		//this.runTest(tests.test_4directAmend_x2);
		//this.runTest(tests.test_5directAmend_x2_directReject_x1);
		//this.runTest(tests.test_6directAmend_x2_directReject_x1_devDecline_x1);
		//this.runTest(tests.test_7directAmend_x2_directReject_x2_devDecline_x2);
		//this.runTest(tests.test_8managed_AllApprove);
		//this.runTest(tests.test_9managed_1xApproverAmend);
		//this.runTest(tests.test_10managed_1xApproverAmend_1xAMdecline);
		//this.runTest(tests.test_11managed_1xApproverAmend_1xDevDecline);
		//this.runTest(tests.test_12managed_1xApproverAmend_1xAMreject);
		//this.runTest(tests.test_13managed_1xApproverAmend_1xApproverReject);
		//this.runTest(tests.test_14managed_1xApproverAmend_1xApproverReject_1xDEVdecline);
		//this.runTest(tests.test_15combine_AllApprove);
		//this.runTest(tests.test_16combine_AMapprove_clientApprove_x2DirectAmend);
		//this.runTest(tests.test_17combine_AMapprove_clientApprove_x2DirectAmend_x1DevDecline);
		//this.runTest(tests.test_18combine_AMapprove_clientApprove_x2DirectAmend_x1DirectReject);
		//this.runTest(tests.test_19combine_x1AMapprove_x2clientAmend_x2DirectAmend_x1DirectReject_x1AMreject_x1clientReject);
		//this.runTest(tests.test__ben_bug_1);
		
		//this.resetState();
	}
	public resetState()
	{
		this.commits = [];
		this.approvals = [];
		this.amendBatches = [];
		this.creative.events = [];
		this._testlog = [];
		if(this.users && this.users.length) this.currentUser = this.users[0];
		this.state = CreativeState.Production;
		this.testCurrentStage = 0;
	}
	public testSelectedName:string;
	private _testSelected:any;
	public testCurrentStage:number = 0;
	get testSelected() : any {
		return tests[this.testSelectedName] ? tests[this.testSelectedName] : null;
	}
	public nextStage()
	{
		this._testSelected = this.testSelected;
		this.runTest(this._testSelected, false, this.testCurrentStage);
		this.testCurrentStage++;
	}
	private runStage(results:any)
	{
		/*
		// always 1 stage;
		let stage = this._testSelected.stages[0];
		//this.currentUser = stage.user;
		for (let j = 0; j < stage.length; j++) {
			const query:string = stage[j];
			if(query == "stop") return;
			const parts:Array<string> = query.split(" ");
			
			for (let k = 0; k < parts.length; k++) {
				let part = parts[k];
				if(this[part] != undefined)
				{
					let type = typeof this[part];
					part = "this."+part;
					if(type === "function")
					{
						part += "(";
						if(k == 0 && parts.length > 1 && parts[k+1].indexOf("=") == -1)
						{
							while(parts.length > 1)
							{
								// only handles 1 param
								part += "'" + parts[k + 1] + "'";
								parts.splice(k + 1, 1);
							}
						}
						part += ")";
					}
					parts[k] = part;
				}
				
			}
			let code = parts.join(" ");
			let result;
			try{
				result = eval(code);
			}catch(e){
				console.warn("code parse error: " + code);
			}
			
			//we are just assigning a value so not a test
			if(code.indexOf(" = ") != -1) continue;
			if(result === undefined) result = true;	// fix for functions that are void
			if(!result){
				console.log(`%ctest failed code: ${code} (stage:${j})`, 'color:red');
				console.log(this._testlog);
				this._testlog.length = 0;
				if(returnOnFail) return;
			}
			total++;
			result ? pass++ : fail++;
		}*/
	}
	private runTest(test:any, returnOnFail:boolean = false, targetStage:number = -1)
	{
		for(const key of Object.keys(tests))
		{
			if(tests[key] == test) console.log(`running test: ${key}`);
		}
		// reset everything
		if((targetStage != -1 && targetStage == 0) || targetStage == -1) this.resetState();

		let total = 0;
		let pass = 0;
		let fail = 0;
		let users = [];
		for (let i = 0; i < test.users.length; i++) {
			users.push(this[test.users[i]]);
		}
		this.users = users;
		//console.log("current user", this.currentUser.name);
		//this.currentUser = stage.user;
		for (let j = 0; j < test.stages.length; j++) {
			if(targetStage != -1 && targetStage != j) continue;
			const query:string = test.stages[j];
			//console.log("query "+ query);
			if(query == "stop") return;
			const parts:Array<string> = query.split(" ");
			
			for (let k = 0; k < parts.length; k++) {
				let part = parts[k];
				if(this[part] != undefined)
				{
					let type = typeof this[part];
					part = "this."+part;
					if(type === "function")
					{
						part += "(";
						if(k == 0 && parts.length > 1 && parts[k+1].indexOf("=") == -1)
						{
							while(parts.length > 1)
							{
								// only handles 1 param
								part += "'" + parts[k + 1] + "'";
								parts.splice(k + 1, 1);
							}
						}
						part += ")";
					}
					parts[k] = part;
				}
				
			}
			let code = parts.join(" ");
			let result;
			try{
				result = eval(code);
			}catch(e){
				console.warn("code parse error: " + code);
			}
			
			//we are just assigning a value so not a test
			if(code.indexOf(" = ") != -1) continue;
			if(result === undefined) result = true;	// fix for functions that are void
			if(!result){
				console.log(`%ctest failed code: ${code} (stage:${j})`, 'color:red');
				console.log(this._testlog);
				this._testlog.length = 0;
				debugger;
				if(returnOnFail) return;
			}
			total++;
			result ? pass++ : fail++;
		}
		console.log(`%ctest complete! total ${total}, pass ${pass}, fail ${fail}`, `color:${pass === total ? 'green' : 'red' }`);	
	}
	private test_getAmend(name:string):Amend
	{
		let amends = this.searchAllAmends({request:name});
		if(amends.length == 0) return null;
		if(amends.length > 1) return null;
		let amend = amends[0];
		return amend;
	}
	private testAmendAdd(name:string)
	{
		const amend = this.addAmendRequest();
		amend.editable = false;
		amend.request = name;
	}
	private testAmendComplete(name:string)
	{
		let amend = this.test_getAmend(name);
		this.amendActionComplete(null, amend);
		this.amendActionComplete(null, amend);
	}
	private testAmendDecline(name:string)
	{
		let amend = this.test_getAmend(name);
		amend.declinedMessage = "amend declined";
		this.amendActionDeclineRequest(null, amend);
		this.amendActionDeclineRequest(null, amend);
	}
	private testAmendConfirmDone(name:string)
	{
		let amend = this.test_getAmend(name);
		this.actionConfirmDone(null, amend);
	}
	private testAmendRejectDone(name:string)
	{
		let amend = this.test_getAmend(name);
		amend.rejectedMessage = "amend rejected";
		this.actionRejectDone(null, amend);
		this.actionRejectDone(null, amend);
	}
	private testAmendAcknowledgeDecline(name:string):boolean
	{
		let amend = this.test_getAmend(name);
		return this.actionAcknowledgeDeclined(null, amend);
	}
	private testAmendAcknowledgeRejection(name:string)
	{
		let amend = this.test_getAmend(name);
		this.actionAcknowledgeRejection(null, amend);
	}
	private testAmendAccept(name:string)
	{
		let amend = this.test_getAmend(name);
		this.amendActionAcceptRequest(null, amend);
		this.amendActionAcceptRequest(null, amend);
	}
	
	public getDebugRowStyle(stage, index)
	{
		let style:any = {height:"10px"};
		if(stage.indexOf("currentUser") == -1) style.marginLeft = "10px";
		if(index == this.testCurrentStage) style.color = 'green';
		return style;
	}
	private canClickButton(btn)
	{
		
	}


	// user role section
	selectedUser:User = null;
	editUserRoles(user:User)
	{
		this.selectedUser = user;
		//console.log("editing user");
	}
	updateRole(evt: MatSelectionListChange)
	{
		let role:string = evt.option.value;
		if(evt.option.selected)
		{
			this.selectedUser.addRole(UserRoles[role]);
		}else{
			this.selectedUser.removeRole(UserRoles[role]);
		}
	}
}