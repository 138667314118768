import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

  @Output() flagSelected : EventEmitter<number> =  new EventEmitter();	
	@Input() selectedFlag : any;

  public icons:any[] = [
                          {icon:this.getIcon(0), label:this.getLabel(0)},
                          {icon:this.getIcon(1), label:this.getLabel(1)},
                          {icon:this.getIcon(2), label:this.getLabel(2)},
                        ];
  constructor() { }

  ngOnInit(): void {

  }
  setFlag(flag:number)
  {
    this.flagSelected.emit(flag);
  }
  isSelected(value:any)
  {
    return value > 0 && this.selectedFlag == value;
  }
  getIcon(value:any)
  {
    return FlagComponent.getIconFromValue(value);
  }
  static getIconFromValue(value)
  {
    if(!value) return "block";
    switch (value) {
      case 1:
         return "warning";
        break;
      case 2:
        return "contact_support";
        break;
      default:
        break;
    }
  }
  getLabel(value:any)
  {
    if(!value) return "Clear";
    switch (value) {
      case 1:
         return "Issue";
        break;
      case 2:
        return "Query";
        break;
      default:
        break;
    }
  }

}
