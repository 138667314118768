import { Component, OnInit } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
  selector: 'app-egnyte',
  templateUrl: './egnyte.component.html',
  styleUrls: ['./egnyte.component.scss']
})
export class EgnyteComponent extends BaseAssetComponent {

	constructor() { 
		super(); 
		this.defaultWidth = 256;
		this.defaultHeight = 32
	}

  ngOnInit(): void {
  }
  getMetadata()
  {
    return JSON.stringify(this.assetVO.asset?.metadata || 'nothing');
  }
}
