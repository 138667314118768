import { Component, OnInit } from '@angular/core';
import { FileUtils } from 'src/app/utils/FileUtils';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent extends BaseAssetComponent {

  constructor() {
    super();
	  this.defaultWidth = 256;
	  this.defaultHeight = 32;
  }

  ngOnInit(): void {
  }
  getTooltip():string
  {
    let uri = this.getPath();
    return FileUtils.baseName(uri);
  }
}
