<h2 mat-dialog-title>New Asset</h2>
<mat-dialog-content>
	<div *ngIf="false && !data.single">
		<mat-form-field class="input_field">
			<input matInput placeholder="Enter Name" [(ngModel)]="name">
			<!--<input matInput placeholder="Enter Name" value="" (keyup)="setAssetSaveDataDelayed('name', $event.target.value)" (change)="setAssetSaveData('name', $event.target.value)">-->
		</mat-form-field>
		<div class="or">or</div>
	</div>
	<div>
		<app-file-uploader (onFilesDragged)="onFilesDragged($event)" [parent]="this" ></app-file-uploader>
		<div>
			<mat-list dense>
				<mat-list-item *ngFor="let file of files">
					{{file.name}}
					<button mat-icon-button><mat-icon matListIcon>delete</mat-icon></button>
				</mat-list-item>
			</mat-list>			   
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button (click)="done()">done</button>
</mat-dialog-actions>

