import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkflowService } from 'src/app/api/workflow.service';
import { Channel, IChannel } from 'src/app/models/channel.model';
import { ICreative } from 'src/app/models/creative.model';
import { IUser } from 'src/app/models/user.model';

export interface RemoveProductionData {
	workflow_id: string;
	user_name: string;
	user_uuid: string;
  otherProductionUsers:IUser[][];
	channels: IChannel[];
}
export interface CreativeAction {
	creative:ICreative;
	action:string;
}
@Component({
  selector: 'app-remove-production',
  templateUrl: './remove-production.component.html',
  styleUrls: ['./remove-production.component.scss']
})
export class RemoveProductionComponent implements OnInit {

  creatives:ICreative[];
  creativeActions:CreativeAction[];
  creativeActionsByChannel:CreativeAction[][];
  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RemoveProductionComponent>,
    private workflowService:WorkflowService,
		@Inject(MAT_DIALOG_DATA) public data: RemoveProductionData
  ) { }

  ngOnInit(): void {
   
    this.creativeActionsByChannel = [];
    for (let index = 0; index < this.data.channels.length; index++) {
      this.creativeActionsByChannel[index] = [];
    }
    // load the user info and build a wizzard
		this.workflowService.getProductionInfo(this.data.workflow_id, this.data.user_uuid, Channel.getChannelIds(this.data.channels)).pipe().subscribe(res => {
			this.creatives = res.data;
      //no need to go thru everythign if removed user is not production owner of any creative
      if(this.creatives.length == 0) this.submit();

      //this.creativeActions = [];
      for (let i = 0; i < this.creatives.length; i++) {
        let creative = this.creatives[i];
        let channelIndex = this.data.channels.findIndex(channel => channel.uuid == creative.channel_uuid);
        if(channelIndex != -1)
          this.creativeActionsByChannel[channelIndex].push({creative, action:'none'});
        else
          console.warn(`channel ${creative?.channel.name} not found`);
        //this.creativeActions.push({creative:this.creatives[i], action:'none'});
      }
		});
		//console.log("remove approver", this.data);
  }
  interacted(e)
  {
    // ignore
  }
  copyActionToOthers(creativeAction:CreativeAction, index:number)
	{
		// only for this channel
		let actions = this.creativeActionsByChannel[index]
		for (let i = 0; i < actions.length; i++) {
			actions[i].action = creativeAction.action;
			if(actions[i].creative.uuid != creativeAction.creative.uuid)
			{

			}			
		}
	}
  submit()
	{
    // collate creative actions
    this.snackBar.open("removing production", null, {duration: 4000});
    this.creativeActions = this.creativeActionsByChannel.reduce((acc, val) => acc.concat(val), []);
    this.workflowService.revokeUserProduction(this.data.workflow_id, this.data.user_uuid, Channel.getChannelIds(this.data.channels), this.creativeActions).pipe().subscribe(res => {
			//console.log(res);
      this.snackBar.open("production removed", null, {duration: 4000});
			this.dialogRef.close({msg:"hello"});
		});
	}

}
