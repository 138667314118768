import { Component, OnDestroy, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FileUtils } from 'src/app/utils/FileUtils';


/**
 * Future features:
 * Pause while recording?
 */
@Component({
  selector: 'app-record-audio-widget',
  templateUrl: './record-audio-widget.component.html',
  styleUrls: ['./record-audio-widget.component.scss']
})
export class RecordAudioWidgetComponent implements OnInit, OnDestroy {

	public supported : boolean;
	// @ts-ignore
	public mediaRecorder: MediaRecorder;
	public listening	: boolean;
	public recording	: boolean;
	
	public state = '';
	public recordingStartTime	: number;
	public timecode:string = '00:00';
	
	public audioData:File;
	public preview:string;

  	@Output() onAudioData:EventEmitter<{file:File}> = new EventEmitter<{file:File}>();
	@Output() onRecordStartStop:EventEmitter<string> = new EventEmitter<string>();	

  constructor( private changeDetectorRef: ChangeDetectorRef)
  {
	this.supported = navigator.mediaDevices?.getUserMedia ? true : false;
  }
  ngOnInit(): void {
	if(!this.supported) return;
	// check permissions first
	// @ts-ignore
	navigator.permissions.query({name:'microphone'}).then(result => {
		//console.log(result)
		this.state = result.state;
		if(this.state == "granted")
		{
			// all good start listening
			this.recordMic();
		}else if(this.state == "prompt"){
			// this will auto prompt anyway
			this.recordMic();
		}else if(this.state == "denied"){
			//
		}
	});

  }
  ngOnDestroy(): void {
    this.cancel();
	this.onRecordStartStop.emit("stop");
  }
  cancel()
  {
	// stop recording
	if(this.recording)
	{
		this.stopRecording();
	}
	
	// stop listening
	// improve these
	if(this.listening)
	{
		// TODO should this be in the function above? hmm
		this.mediaRecorder.stream.getTracks().forEach(track => track.stop())
		this.listening = false;
	}
	// clear the preview
	this.preview = null;

	// reset audio data
	if(this.audioData){
		this.audioData = null;
		this.onAudioData.emit(null);
	}
  }

  	updateTimecode()
	{
		if(!this.recording) return;
		let diff = Date.now() - this.recordingStartTime;
		diff *= 1/1000;
		diff |= 0;
		let seconds = diff % 60;
		diff *= 1/60;
		diff |= 0;
		let minutes = diff % 60;
		this.timecode = this.pad(minutes) + ':' + this.pad(seconds);
		this.changeDetectorRef.markForCheck();

		requestAnimationFrame(() => this.updateTimecode());
	}
	pad(value:number):string{
		return value <= 9 ? '0' + value.toString() : value.toString();
	}
	startRecording()
	{
		//console.log(this.mediaRecorder.state);
		//console.log("recorder started");
		
		if(this.mediaRecorder.state == 'inactive')
		{
			this.mediaRecorder.start();
			this.recording = true;
			this.onRecordStartStop.emit("start");
			this.recordingStartTime = Date.now();
			requestAnimationFrame(() => this.updateTimecode());
		}
		//console.log(this.mediaRecorder.state);
	}
	stopRecording()
	{
		//console.log(this.mediaRecorder.state);
		if(this.mediaRecorder.state == 'recording')
		{
			this.mediaRecorder.stop();
			this.recording = false;
			this.onRecordStartStop.emit("stop");
		}

		//console.log(this.mediaRecorder.state);
		//console.log("recorder stopped");
		// this.mediaRecorder.requestData();  
	}
	recordMic()
	{
		//console.log('getUserMedia supported.');
		
		const constraints = {
			audio: {noiseSuppression:true},			
		};
		let chunks = [];
		//let soundClips = [];		
		let onSuccess = (stream) => {
			this.state = 'granted';
			this.listening = true;
			// @ts-ignore -https://github.com/microsoft/TypeScript/issues/34728
			this.mediaRecorder = new MediaRecorder(stream);
			
			this.visualize(this.mediaRecorder.stream);

			this.mediaRecorder.onstop = (e) => {
				//console.log("data available after MediaRecorder.stop() called.");
			
				const blob = new Blob(chunks, { 'type' : 'audio/ogg; codecs=opus' });
				this.audioData = FileUtils.blobToFile(blob, 'audioFile.ogg');
				this.preview = URL.createObjectURL(this.audioData);
				//this.recordingSource = window.URL.createObjectURL(blob);
				
				//this.audioData = blob;
				//this.audioData['lastModifiedDate'] = (new Date()).toISOString();
				//this.audioData['name'] = 'audioFile.ogg';

				
				chunks = [];
				//console.log("recorder stopped");
				this.onAudioData.emit({file:this.audioData});

			}		
			this.mediaRecorder.ondataavailable = function(e) {
				chunks.push(e.data);
			}

			this.changeDetectorRef.markForCheck();
		}
		
		let onError = (err) => {
			this.state = 'denied';
			console.log('The following error occured: ' + err);
		}
		
		navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);

	}
	deleteRecording()
	{
		// clear the preview
		if(this.preview){
			URL.revokeObjectURL(this.preview);
			this.preview = null;
		}
		// reset the timecode
		this.timecode = '00:00';
	}
	audioCtx:AudioContext;
	visualize(stream:MediaStream) {
		if(!this.audioCtx) {
		  this.audioCtx = new AudioContext();
		}
	  
		const source = this.audioCtx.createMediaStreamSource(stream);
	  
		const analyser = this.audioCtx.createAnalyser();
		analyser.fftSize = 2048;
		const bufferLength = analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);
	  
		source.connect(analyser);
		//analyser.connect(this.audioCtx.destination);
	  
		const mediaRecorder = this.mediaRecorder;
		requestAnimationFrame(draw);
	  
		function draw() {
			// redraw if stream is active
			if(stream.active)
			{
				requestAnimationFrame(draw);
			}
			const canvas = document.getElementById("audio_canvas") as HTMLCanvasElement;
		
			if(!canvas) return;

			// Make it visually fill the positioned parent
			canvas.style.width ='100%';
			//canvas.style.height='100%';
			// ...then set the internal size to match
			canvas.width  = canvas.offsetWidth;
			//canvas.height = canvas.offsetHeight;

			const canvasCtx = canvas.getContext('2d');
		  const WIDTH = canvas.width
		  const HEIGHT = canvas.height;
	  
	  
		  analyser.getByteTimeDomainData(dataArray);
	  
		  canvasCtx.fillStyle = 'rgb(255, 255, 255)';
		  canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
	  
		  canvasCtx.lineWidth = 2;
		  if(mediaRecorder?.state == 'recording')
		  	canvasCtx.strokeStyle = 'rgb(255, 0, 0)';
		  else
		  	canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
	  
		  canvasCtx.beginPath();
	  
		  let sliceWidth = WIDTH * 1.0 / bufferLength;
		  let x = 0;	  
	  
		  for(let i = 0; i < bufferLength; i++) {
	  
			let v = dataArray[i] / 128.0;
			let y = v * HEIGHT/2;
	  
			if(i === 0) {
			  canvasCtx.moveTo(x, y);
			} else {
			  canvasCtx.lineTo(x, y);
			}
	  
			x += sliceWidth;
		  }
	  
		  canvasCtx.lineTo(canvas.width, canvas.height/2);
		  canvasCtx.stroke();
	  
		}
	  }
}
