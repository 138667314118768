import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren, SimpleChanges  } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from 'src/app/global';
import { DialogService } from 'src/app/services/dialog.service';
import { PostMessageService } from 'src/app/services/postmessage.service';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { AssetService } from 'src/app/api/assets.service';
import { CreativeService } from 'src/app/api/creative.service';
import { IMarker, MarkerOption, MarkerVO } from 'src/app/models/marker.model';
import { Observable, fromEvent } from 'rxjs';
import { convertDOMRect } from 'src/app/utils/dom';
import script from './injectscript';
/*
TODO
- resize contents
- read from file
- preview and save file as blobbo

- if range contains point then stop search right away.
- otherwise search until distance starts growing again for BOTH axis not just distance alone
- possible to inject a sneaky overlay showing the box selection.
- enable text selection as per pdf

- investigate srcdoc
- https://stackoverflow.com/questions/19739001/what-is-the-difference-between-srcdoc-and-src-datatext-html-in-an
https://www.reddit.com/r/javascript/comments/19f42b9/askjs_html_to_png_alternative/
https://www.npmjs.com/package/html-to-image
https://www.npmjs.com/package/modern-screenshot

 https://htmlemail.io/inline/
 https://www.litmus.com/blog/mobile-responsive-email-stacking
 https://templates.mailchimp.com/concepts/email-clients/

 // editor lib loading:
 https://gist.github.com/zainzafar90/1f58a4a04ac17fcf8e7424a053620822
 https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
 https://ace.c9.io/#nav=embedding
 https://stackoverflow.com/questions/51484076/angular-include-cdn-in-component-usage

*/
type Mode = "code" | "preview";
type PreviewMode = "desktop" | "mobile";
declare let ace: any; //declare ace
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent extends BaseAssetComponent{

  @Input() editable : boolean = true;
  @ViewChildren('aceEditor') private editorList: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('iframe') private iframes: QueryList<ElementRef<HTMLIFrameElement>>;
  private _iframe:ElementRef<HTMLIFrameElement>;
  private set iframe(iframe:ElementRef<HTMLIFrameElement>)
  {
    if(iframe == this._iframe) return;
    this._iframe = iframe;
    this.unsub();
    if(this._iframe)
    {
      this.tempSub = fromEvent(this.iframe.nativeElement, 'scroll').subscribe(e => {
        //console.log("scrollage", e);
      });
      this.tempSub = fromEvent(this.iframe.nativeElement, 'resize').subscribe(e => {
        //console.log("iframe resize", e);
      });
    }
  }
  public get iframe() : ElementRef<HTMLIFrameElement> {
    return this._iframe;
  }
  

  //@ViewChild('tabGroup') tabGroup:MatTabGroup;
  private _mode:Mode = "code";
  public get mode() : Mode {
    return this._mode;
  }
  
  public set mode(mode : Mode) {
    this._mode = mode;
    if(this._mode == "preview")
    {
      this.generateSrc();
      if(this.changed)
        {
          if(this.assetVO.preview) URL.revokeObjectURL(this.assetVO.preview);
          //this.codeURL = URL.createObjectURL(new Blob([this.code], { type: "text/html" }));
          
          this.assetVO.preview = URL.createObjectURL(this.assetVO.file);//new Blob([this.code], { type: "text/html" }));
          this.changed = false;
  
          // TODO put this back!
          //const errors = this.getHtmlError(this.code);
          // console.log("code check", errors);
        }
        this.updateSrc();
    }
    this.callback.emit({type:'marker_update'});
  }
  
  public preview_mode:string = "desktop";
  public code:string;
  public savedCode:string;
  public changed:boolean = false;
  private uriCache:string;
  public src:string;
  private scroll:any;
  private editor2:any;
  private ignoreNextChange:boolean = false;
 // public codeURL:any;
  // https://stackoverflow.com/questions/46240293/how-to-lazyload-library-in-angular-4-module
  // https://www.npmjs.com/package/@ngstack/code-editor
  // https://stackoverflow.com/questions/76510764/how-i-create-a-code-editor-with-custom-rules-in-angular-13
  constructor(
    private readonly elementRef: ElementRef,
    private clipboard: Clipboard,
    private httpClient:HttpClient,
    private dialogService:MatDialog,
    private creativeService:CreativeService,
    private postMessageService:PostMessageService,
  ) {
    super();
	  this.defaultWidth = 640;
	  this.defaultHeight = 360;
  }
  ngOnDestroy(): void {
    if(this.assetVO.preview) URL.revokeObjectURL(this.assetVO.preview);
  }
  ngOnInit(): void {
    // default to preview mode if not editable
    if(!this.editable)
    {
      this.mode = "preview";
    }
    if(this.assetVO.asset.uri)
      {
        //this.src = Globals.WORKER_URL + 'serve/' + this.assetVO.asset.uri;
        this.loadFile();
      }

      this.sub = fromEvent(window, 'message').subscribe((e:MessageEvent) => {
        const { hostname } = new URL(e.origin);
        if(hostname.indexOf(window.location.hostname) == -1) return;
        const creative_uuid = this.getCreativeUUID();
        
        //if(e.data.uuid) console.log("MEEAGE", e.data.uuid, e.data.type);
        if(e.data.uuid  !== creative_uuid) return;
        if(e.data.type == "load")
        {
          this.callback.emit({type:'marker_update'})
          return;
        }
        if(e.data.type == "right_click")
          {
            let element = this.iframe?.nativeElement || this.elementRef.nativeElement;
            let bounds = element.getBoundingClientRect();
            var ev3 = new MouseEvent("contextmenu", {
              bubbles: true,
              cancelable: true,
              view: window,
              button: 2,
              buttons: 0,
              clientX: bounds.x + e.data.contextmenu.x,
              clientY: bounds.y + e.data.contextmenu.y
          });
            element.dispatchEvent(ev3);
            return;
          }
        if(e.data.scroll)
          {
            //console.log("scroll message", e.data.scroll);
            this.scroll = e.data.scroll;
            this.layoutChange.emit(null);
            return;
          }
          if(e.data.type == 'query')
          {
            if(e.data.rect)
            {
              //console.log("rect message", e.data);
              
              // this works due to a common parent having a relative position set!
              this.overlayActive = true;
              this.overlayStyle['left.px'] = e.data.rect.left;// + rect.x;
              this.overlayStyle['top.px'] = e.data.rect.top;// + rect.y;
              this.overlayStyle['width.px'] = e.data.rect.width;
              this.overlayStyle['height.px'] = e.data.rect.height;
            }else {
              this.overlayActive = false;
            }
            // see if there is a stacked request to handle
            if(this.dropQuery)
            {
              this.processDropQuery();
              this.dropQuery = null;
            }
          }
          
      });

      
  }
  ngAfterViewInit(): void {
    this.sub = this.editorList.changes.subscribe(res => {
      this.updateEditor();
    });
    this.updateEditor();

    this.iframe = this.iframes.first;
    this.sub = this.iframes.changes.subscribe(r => this.iframe = this.iframes.first);
  }
  getCreativeUUID():string
  {
    // this is a total hack for now as the asset might not have a uuid
    return this.assetVO.asset.uri ? this.assetVO.asset.uri.split("/")[1] : this.assetVO.asset.id.toString();
  }
  private overlayStyle:any = {};
  public overlayActive:boolean = false;
  getOverlayRectStyle()
  {
    return this.overlayStyle;
  }
  ngOnChanges(changes?: SimpleChanges): void {
    //console.log("CHANGED", changes);
    if(changes?.editable)
    {
     // this.editor2?.setReadOnly(!this.editable);
    }
  }
  ngDoCheck(): void {
     
    // this is gross but what we gonna do!
    if(this.code && !this.assetVO.file)
    {
     //this.code = null;
     //this.savedCode = null;
     //this.setContent();
    }
    
    if(this.assetVO.asset.uri && this.uriCache != this.uri())
    {
      this.loadFile();
    }
  }
  getModifiedCode()
  {
    let code = this.code || this.savedCode;
    //https://stackoverflow.com/questions/62165638/angular-9-how-to-import-html-file-as-a-string-with-typescript

    let style = `
    <style>
      .over {
        outline: 1px solid green !important; 
        box-sizing:border-box;
      }
    </style>
    `;

    const creative_uuid = this.getCreativeUUID();
    let javascript = script;
    javascript = javascript.split("$UUID").join(`'${creative_uuid}'`);
    return code + javascript + style;
  }
  // src is for the preview
  updateSrc():void
  {
    return;
    console.log("updateSrc", this.assetVO.preview)
    if(this.assetVO.preview)
    {
        this.src = this.assetVO.preview;
    } else if(this.assetVO.asset.uri) {
        this.src = Globals.WORKER_URL + 'serve/' + this.uri();
    }
  }
  getCode(inject:boolean = true)
  {
    const editorCode = this.editor2?.session.getValue()
    let code = editorCode || this.savedCode || '';
    if(inject)
    {
      let style = `
      <style>
        .over {
          outline: 1px solid green !important; 
          box-sizing:border-box;
        }
      </style>
      `;
  
      const creative_uuid = this.getCreativeUUID();
      let javascript = script;
      javascript = javascript.split("$UUID").join(`'${creative_uuid}'`);
      return code + javascript + style;
    } else{ 
      return code;
    }
  }
  generateSrc()
  {
    //console.log("getting src");
    
    // return either custom code or loaded code wrapped in a file/blob
    const code = this.getCode();
    const blob = new Blob([code], { type: "text/html" });
    const file = new File([blob], 'index.html', {type: "text/html"});
    if(this.src) URL.revokeObjectURL(this.src)
    this.src = URL.createObjectURL(blob);
  }
  getSrc()
  {
    if(!this.src) this.generateSrc();
    return this.src;

    if(this.assetVO.preview)
      {
         return this.assetVO.preview;
      } else if(this.assetVO.asset.uri) {
        return Globals.WORKER_URL + 'serve/' + this.uri();
      }
      return null;
  }
  loadFile()
  {
    this.uriCache = this.uri();
    let url = Globals.WORKER_URL + 'serve/' + this.uriCache;
    this.httpClient.get(url, {responseType: 'text'}).subscribe((res:string) => {
      //console.log("uri loaded", res);
      
      this.savedCode = res;
      this.generateSrc();
      this.ignoreNextChange = true;
      this.setContent();
    });
  }
  onPreviewResized(e)
  {
    //console.log("on resize observer..",e)
    this.layoutChange.emit(null);
    this.callback.emit({type:'marker_update'})
  }
  updateEditor()
  {
    //console.log("update editor");
    
    let container = this.editorList.first;
    if(!container) return;
    this.editor2 = ace.edit("editor", {
      autoScrollEditorIntoView: false,
      maxLines: Infinity,
      wrap: true,
    });
    //this.editor2.setReadOnly(!this.editable);
    this.editor2.getSession().on('change', (e) => {
      this.ignoreNextChange ? this.ignoreNextChange = false : this.handleCodeChange()
    });
    this.editor2.setKeyboardHandler("ace/keyboard/vscode");
    this.editor2.setTheme("ace/theme/nord_dark"); // https://cdnjs.com/libraries/ace/1.9.6
    this.editor2.session.setMode("ace/mode/html");
    this.setContent()
  }
  /**
   * Update the content of the editor to the correct code, current or saved or empty in that order
   */
  setContent()
  {
    if(this.editor2)
      {
        this.editor2.setValue(this.code || this.savedCode || '');
      }
    if(this.editorList?.first){
      //this.editorList.first.nativeElement.textContent = this.code || this.savedCode;
    }
  }
  isValid()
  {
    let uri = this.uri();
    if(!uri) return false;
    let urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    let valid = urlRegex.test(uri);
    return valid;
  }
  // https://stackoverflow.com/questions/10026626/check-if-html-snippet-is-valid-with-javascript
  // https://stackoverflow.com/questions/11563554/how-do-i-detect-xml-parsing-errors-when-using-javascripts-domparser-in-a-cross
  getHtmlError(html:string)
  {
    const parser = new DOMParser();
    const htmlForParser = `<xml>${html}</xml>`
      .replace(/\\n/g, '')
      .replace(/(src|href)=".*?&.*?"/g, '$1="OMITTED"')
      .replace(/<script[\s\S]+?<\/script>/gm, '<script>OMITTED</script>')
      .replace(/<style[\s\S]+?<\/style>/gm, '<style>OMITTED</style>')
      .replace(/<pre[\s\S]+?<\/pre>/gm, '<pre>OMITTED</pre>')
      .replace(/&nbsp;/g, '&#160;');
  
      try {
        const doc = parser.parseFromString(htmlForParser, 'text/xml');
        if (doc.documentElement.querySelector('parsererror')) {
          //console.error(htmlForParser.split(/\n/).map( (el, ndx) => `${ndx+1}: ${el}`).join('\n'));
          return doc.documentElement.querySelector('parsererror');
        }
      } catch (e) {
        return e;
      }
  }
  onTabChange(e:MatTabChangeEvent)
  {
    if(e.index == 1)
    {
      
      if( this.changed)
      {
        if(this.assetVO.preview) URL.revokeObjectURL(this.assetVO.preview);
        //this.codeURL = URL.createObjectURL(new Blob([this.code], { type: "text/html" }));
        
        this.assetVO.preview = URL.createObjectURL(this.assetVO.file);//new Blob([this.code], { type: "text/html" }));
        this.changed = false;

        // TODO put this back!
        //const errors = this.getHtmlError(this.code);
        // console.log("code check", errors);
      }
      this.updateSrc();
    }

  }
  // https://stackoverflow.com/questions/2237497/make-the-tab-key-insert-a-tab-character-in-a-contenteditable-div-and-not-blur
  tabCatch(e)
  {
    //detect 'tab' key
    if(e.keyCode == 9){
      //add tab
      //document.execCommand('insertHTML', false, '&#009');
      var sel          = document.getSelection(),
        range        = sel.getRangeAt(0),
        tabNodeValue = '\u0009', // tab; with 4 spaces use: Array(4).join('\u00a0')
        tabNode      = document.createTextNode(tabNodeValue);

      range.insertNode(tabNode)
      range.setStartAfter(tabNode)
      range.setEndAfter(tabNode)
      //prevent focusing on next element
      e.preventDefault()   
    }
  }
  /**
   * Editor code changed, need to then create a file if different to saved code
   */
  handleCodeChange()
  {
    const code = this.editor2.getValue();
    this.code = code;
    //console.log("code change", this.code?.length);
    
    if(!this.savedCode || this.savedCode != code)
    {
      const blob = new Blob([code], { type: "text/html" }); //this.code // this.getModifiedCode()
      this.assetVO.file = new File([blob], 'index.html', {type: "text/html"});
      this.assetVO.fileMetadata = {size:this.assetVO.file.size};
      this.assetVO.updateMetaView();
    }else if((!code?.length) || (this.savedCode && this.savedCode == code))
    {
      this.assetVO.file = null;
      this.assetVO.fileMetadata = null;
      this.assetVO.updateMetaView();
    }
  }

  onChange(e)
  {

    
    const code = e.target.textContent;
    this.code = code;
    //console.log("code changed", this.code.length);
    if(!this.savedCode || this.savedCode != code)
    {
      this.changed = true;
      const blob = new Blob([code], { type: "text/html" }); //this.code // this.getModifiedCode()
      this.assetVO.file = new File([blob], 'index.html', {type: "text/html"});
      this.assetVO.fileMetadata = {size:this.assetVO.file.size};
      this.assetVO.updateMetaView();
      // could update preview here too
    }else if( this.savedCode && this.savedCode == code){
      this.assetVO.file = null;
      this.assetVO.fileMetadata = null;
      this.assetVO.updateMetaView();
    }
  }
  copyToClipboard()
  {
    //this.clipboard.copy(this.editorList.first.nativeElement.textContent);
    const copyText = this.editorList.first.nativeElement.textContent;
    const textArea = document.createElement('textarea');
    textArea.textContent = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    //return URL.createObjectURL(new Blob([this.code], { type: "text/html" }));
  }
  download()
  {
    // there are 6 billion ways to do this - TODO research the one that has best support
    const blob = new Blob([this.code || this.savedCode], { type: "text/html" });
    const file = new File([blob], 'index.html');

    const link = document.createElement('a')
    const url = URL.createObjectURL(file)

    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  send(){
    // popup to ask for email addresses
    // TODO remove dependancy on asset uri (only to get creative uuid)
    if(!this.assetVO.asset?.uri) return;
    const creative_uuid = this.getCreativeUUID();
    const dialogRef = this.dialogService.open(GenericDialogComponent, {
			data: {
				title:`Enter test email recipient(s):`,
				
				negative:"Cancel",
				positive:"Send",
				form:[
					{name:"emails", label:"Email(s)", type:"text"}
				],
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result){
        let emailsString:string = result.formGroup.value.emails;
        let emails = emailsString.split(',').filter(email => email.length > 3);
        emails.forEach(email => email = email.trim());
        this.creativeService.sendEmail(creative_uuid, emails, this.code || this.savedCode).subscribe(res => {
          // emails sent...
          // TODO snackbar
        });
      }
    });
  }
  isEmailValid()
  {

  }
  getPath()
  {
    let uri = this.uri();
    if(!uri) return null;
    //if(uri.startsWith("http://" || ) 
    return uri;
  }
  dropQuery:any;
  canDropMarker(x:number, y:number, noSideEffect:boolean = false)
  {
    if(!this.iframe) return 0;
    let rect = this.iframe.nativeElement.getBoundingClientRect();

    if(x < rect.left || x > rect.right || y < rect.top || y > rect.bottom) return 0; //  
    x -= rect.x;
    y -= rect.y;

    //x -= this.assetVO.asset.x;
    //y -= this.assetVO.asset.y;
    //x -= this.iframe.nativeElement.offsetLeft;
    //y -= this.iframe.nativeElement.offsetTop;
    //y -= this.iframe.nativeElement.parentElement.offsetTop; // parent offset

    // TODO only fire if x/y changed

    // push the message into a queue, only call if queue has a length of 1, otherwise call in the response but only react to latest message
    if(!noSideEffect)
      {
        const message = {x,y, time:Date.now()};
        if(!this.dropQuery)
        {
          this.dropQuery = message;
          this.processDropQuery();
        }else{
          this.dropQuery = message;
        }
      }


    return 1;
  }
  processDropQuery()
  {
    if(!this.dropQuery) return;
    this.iframe.nativeElement.contentWindow.postMessage({type:"check", position:this.dropQuery});
  }
	getMarkerRenderInfo(markerVO:MarkerVO)
	{
		super.getMarkerRenderInfo(markerVO);
		markerVO.visible = this.mode == "preview";
		if(this.scroll)
		{
			markerVO.offsetX = -this.scroll.x;
			markerVO.offsetY = -this.scroll.y;
		}else{
			markerVO.offsetX = 0;
			markerVO.offsetY = 0;
		}
		let rect = this.iframe?.nativeElement.getBoundingClientRect();
		let rect2 = this.elementRef?.nativeElement.getBoundingClientRect();
		if(rect)
		{
			markerVO.offsetX += (rect.x - rect2.x);
			markerVO.offsetY += (rect.y - rect2.y);
		}
		// set x and y for non target markers
		if(isNaN(markerVO.marker.x0)) markerVO.marker.x0 = 0;
		if(isNaN(markerVO.marker.y0)) markerVO.marker.y0 = 0;
		markerVO.scale = 1;
	}
  async updateMarker2(markerVO:MarkerVO)
  {
    let marker = markerVO.marker;
    // hacky
    this.getMarkerRenderInfo(markerVO);
    if(!marker.metadata?.startDom) return;
    if(!this.iframe) return;
      let message = {
        type:"selection_update",
        startDom:marker.metadata.startDom,
        endDom:marker.metadata.endDom,
        startOffset:marker.metadata.startOffset,
        endOffset:marker.metadata.endOffset,
      }
      const response = await this.postMessageService.postMessage(this.iframe.nativeElement.contentWindow, {type:"selection_update", message});
      if(response)
      {
        //console.log("response!", response)
        marker.metadata.rects = response.data.response.rects;
        this.layoutChange.emit(null);
        //this.callback.emit({type:'marker', data:response.data})
      }
  }
  async getAmendOptions(x:number, y:number, specific:boolean):Promise<MarkerOption[]>
  {
    // we need to set what amend options are available
    // is there a text selection?
    // if so we can query for that i
    if(this.overlayActive)
      {
        // overlay type not text selection
        
        this.overlayActive = false;
      }

    //console.log("sending message...")
    const response = await this.postMessageService.postMessage(this.iframe.nativeElement.contentWindow, {type:"selection"});
    //console.log("response...", response)
    
    let message1 = specific ? 'Hilite selected text' : 'Hilite selected text';
		let message2 = specific ? 'Strikethrough selected text' : 'Strikethrough selected text';
    let options = [];//await super.getAmendOptions(x, y, specific);

    if(response && response.data.response)
    {
      let domRects = response.data.response.rects;
      let startOffset = response.data.response.startOffset;
      let startDom = response.data.response.startDom;
      let endOffset = response.data.response.endOffset;
      let endDom = response.data.response.endDom;
      let rects = domRects.map(rect => convertDOMRect(rect));
      let data = {rects, startOffset, startDom, endOffset, endDom, text:response.data.response.selectionString};
      options.unshift(new MarkerOption(message1, MarkerOption.ICON_TEXT_CHANGE, MarkerOption.TYPE_TEXT_CHANGE, data));
      options.unshift(new MarkerOption(message2, MarkerOption.ICON_TEXT_DELETE, MarkerOption.TYPE_TEXT_DELETE, data));
    }else{
      options.unshift(null);
      options.unshift(new MarkerOption("Select text in Email for more options.",null,null,null,true))
    }
    return new Promise((resolve, reject) => {
      resolve(options)
		});
  }
}
