// https://pusher.com/tutorials/angular-realtime/#adding-pusher-to-your-application
// https://pusher.com/tutorials/web-notifications-laravel-pusher-channels/#setting-up-your-pusher-application
declare const Pusher: any;

import { HttpClient } from '@angular/common/http';
import { Injectable, ApplicationRef } from '@angular/core';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
//import Echo from 'laravel-echo';
import Echo  from 'node_modules/laravel-echo/dist/echo';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { Chat } from '../Chat';
import { Globals } from '../global';
import { AppUserService } from './app-user.service';
import { buildDate, version } from 'src/environments/version';
import { DialogService } from './dialog.service';
import { IMessage } from './../models/message';

let API_URL = Globals.BASE_API_URL;


/**
 * This service will collect realtime messages from the server and store them locally for a time
 */
@Injectable({
	providedIn: 'root'
})
export class PusherService {

			public sessionID:number;
			pusher: any;
			channel: any;
				/*
			echo:Echo;*/

			private _initialized:boolean = false;
			public get initialized():boolean
			{
				return this._initialized;
			}
			
			public appChat: Chat;
			public accountChat:Chat;
			public workgroupChat:Chat;
			public projectChat:Chat;
			public creativeChat:Chat;

			private _privateMessage : Subject<string>  = new Subject<string>();
			private _publicMessage : Subject<string>  = new Subject<string>();

			private _projectMessage : Subject<string>  = new Subject<string>();
			public projectMessage = this._projectMessage.asObservable();
			private _creativeMessage : Subject<string>  = new Subject<string>();
			public creativeMessage = this._creativeMessage.asObservable();

			public publicMessages:string[] = [];
			public privateMessages:string[] = [];

			private _projectUsers:any[] = [];
			private _projectUsersSubject:Subject<any[]> = new Subject<any[]>();//new BehaviorSubject<any[]>(null);
			public projectUsers = this._projectUsersSubject.asObservable();

			private _users:any[] = [];
			private _usersSubject:Subject<any[]> = new Subject<any[]>();//new BehaviorSubject<any[]>(null);
			public users = this._usersSubject.asObservable();// new Observable<any[]>();//from(this._users);//this._users.asObservable();

			// what users are currently viewing a creative
			private _creativeUsers:any = {};
			public creativeUsersSubject:BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this._creativeUsers);
			public creativeUsers = this.creativeUsersSubject.asObservable();
			constructor(
				private http: HttpClient,
				private appUserService:AppUserService,
				private dialogService:DialogService,
				private activatedRoute:ActivatedRoute,
				private appRef: ApplicationRef,
				private router: Router,
			) {
				//console.log("appComponentRef", this.activatedRoute)

					//appRef.registerBootstrapListener(appComponentRef => {
						
					  //this._appElementRef = appComponentRef.location;
					//});
				
				/*
				this.pusher = new Pusher("websocketkey", {  //environment.pusher.key
					cluster: "mt1",//environment.pusher.cluster,
					encrypted: false
				});
				this.channel = this.pusher.subscribe('events');
*/
			}
			init(token:string)
			{
				/*
				if(!token){
					console.warn("no token available for pusher");
					return;
				}*/
				if(!this._initialized) this._initialized = true;
				else return;

				this.publicMessages = JSON.parse(localStorage.getItem('publicMessages')) || [];
				this.privateMessages = JSON.parse(localStorage.getItem('privateMessages')) || [];
				// can't get pusher to work this way - tries to connect to: wss://ws-mt1.pusher.com/app/websocketkey?protocol=7&client=js&version=7.0.3&flash=false
				/*
				this.pusher = new Pusher("websocketkey", {  //environment.pusher.key
					cluster: "mt1",//environment.pusher.cluster,
					encrypted: false,
					authEndpoint: window.location.hostname,
					auth: {
						headers: {
							//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
							Authorization: 'Bearer ' + token,// BEARER_TOKEN,
						},
					},
				});
				this.channel = this.pusher.subscribe('events');
				this.channel.bind("App\\Events\\RealTimeMessage", data => {
					console.log("message", data);
				});
				this.channel.bind("RealTimeMessage", data => {
					console.log("message", data);
				});*/

				let local = window.location.hostname.indexOf('localhost') === 0;
				let config = {
					broadcaster: 'pusher',
					key: Globals.APP_KEY, // "oceanview-key",//process.env.MIX_PUSHER_APP_KEY,
					//app_key: "oceanview-key", // temp
					cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
					forceTLS: false,
					wsHost: window.location.hostname == "localhost" ? window.location.hostname : 'api.' + window.location.hostname,
					wsPort: 8443,
					wssPort: 8443,
					enabledTransports: ["ws", "wss"],
					auth: {
						headers: {
							//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
							//Authorization: 'Bearer ' + token,// BEARER_TOKEN,
						},
					},
				}
				if(!local)
				{
					//wsPath: 'api/',
					config["authEndpoint"] = API_URL + 'broadcasting/auth';
					config["encrypted"] = true;
					config["forceTLS"] = true;
					config["disableStats"] = true;          
				}

				// @ts-ignore
				window.Pusher = require('pusher-js');

				// auth with credentials over token... https://github.com/pusher/pusher-js/issues/471
				// @ts-ignore
				window.Pusher.Runtime.createXHR = function () {
					if (this.getXHRAPI()) {
						let xhr = this.createXMLHttpRequest();
						xhr.withCredentials = true;
						return xhr;
					  } else {
						let xhr = this.createMicrosoftXHR();
						xhr.withCredentials = true;
						return xhr;
					}
				  };

				// @ts-ignore
				window.Echo = new Echo(config);
				// @ts-ignore
				this.sessionID = window.Echo.connector.pusher.sessionID;
				// @ts-ignore
				window.Echo.channel('events').listen('RealTimeMessage', (e) =>{
					let message = e.message;
					this.publicMessages.push(message);
					localStorage.setItem("publicMessages", JSON.stringify(this.publicMessages));
					this._publicMessage.next(e.message);
				});
				// @ts-ignore
				window.Echo.private('events').listen('RealTimeMessage', (e) =>{
					let message = e.message;
					this.privateMessages.push(message);
					localStorage.setItem("privateMessages", JSON.stringify(this.privateMessages));
					this._privateMessage.next(message);
			});

				// creative events
				// @ts-ignore
				window.Echo.channel('creative').listen('RealTimeMessage', (e) =>{
					this._creativeMessage.next(e);
				});
				// @ts-ignore
				window.Echo.channel('creative').listen('RealTimeMessageNow', (e) =>{
					this._creativeMessage.next(e);
				});

			}
			getMessageCount()
			{
				return this.privateMessages.length + this.publicMessages.length;
			}
			deletePublic(index:number)
			{
				this.publicMessages.splice(index, 1);
				localStorage.setItem("publicMessages", JSON.stringify(this.publicMessages));
			}
			deletePrivate(index:number)
			{
				this.privateMessages.splice(index, 1);
				localStorage.setItem("privateMessages", JSON.stringify(this.privateMessages));
			}
			storePrivateMessage()
			{

			}
			storePublicMessage()
			{

			}
			getPublicMessage(): Observable<string> {
				return this._publicMessage.asObservable();
			}
			getPrivateMessage(): Observable<string> {
				return this._privateMessage.asObservable();
			}
			getCreativeMessage(): Observable<string> {
				return this._creativeMessage.asObservable();
			}
			currentCreativeChat:string;
			currentProjectChat:string;
			_currentProjectChat:any;
			//joinProjectChat(uuid:string, listenToMessages:boolean = true, creative_uuid:string = null)
			/*
			projectChatWhisper(message:string)
			{
				if(this._currentProjectChat)
				{
					let me = this._currentProjectChat.subscription.members.me?.info;
					if(me)	this._currentProjectChat.whisper("creative", {user:me.uuid, message});
				}
			}*/
			myChat:Chat;
			joinMyChat(uuid: string) {
				if(!this.myChat)
				{
					this.myChat = new Chat();
					/*
					this.myChat.messages.subscribe(message =>{
						console.log("my -> message", message);
					})*/
				}
				this.myChat.join("App.User." + uuid);
				this.myChat.messages.subscribe((message:IMessage) => {
					if(message.type == "status")
					{
						// TODO get this check back somehow
						//if((message.author.rolez & 2) == 0) return;

						if(message.data['message'] == "reload")
						{
							// TODO get this check back somehow
							//if((message.author.rolez & 2) == 0) return;
							// if a session is set but is not my session then bail
							if(message.data["session"] && message.data["session"] != this.sessionID) return;
							if(message.data["force"])
							{
								window.location.reload();
							}else{
								this.dialogService.openGenericConfirm({
									title:"Reload requested",
									body:"Your application is out of date, press ok to reload the page.",
									positive:"ok",
									negative:"cancel",
								}).subscribe(res => {
									if(res)	window.location.reload();
								});
								//let confirmed = window.confirm("Please can you reload the page?");
								//if(confirmed) window.location.reload();
							}
							return;
						}

						let stats = this.getStats();

						/*
							// TODO in future implement a way of seeing if the current active component can deactivate 
							//const children = this.activatedRoute?.firstChild.snapshot.routeConfig.children;
							const canDeactivate = this.activatedRoute?.firstChild.snapshot.routeConfig.canDeactivate;
							if(canDeactivate)
							{
								
								//let component = children[children.length-1];
								console.log("component", this.activatedRoute.firstChild.snapshot.routeConfig.component)
								//console.log("canDeactivate call", this.activatedRoute.firstChild.snapshot.routeConfig.component.canDeactivate(null, null))
							}
						*/
						let status = {
							"api-version":localStorage.getItem("api-version"),
							buildDate,
							version,
							...stats,
						};
						this.appUserService.message(status).subscribe(res => {
							// done;
						})
					}

				})
			}
			getStats()
			{
				let os = "Unknown OS";
				if (navigator.appVersion.indexOf("Win") != -1) os = "Windows";
				else if (navigator.appVersion.indexOf("Mac") != -1) os = "MacOS";
				else if (navigator.appVersion.indexOf("Linux") != -1) os = "Linux";
				else if (navigator.appVersion.indexOf("X11") != -1) os = "UNIX";

				const browserVersion = navigator.appVersion;
				const browserName  = navigator.appName;
				const screenWidth  = screen.width;
				const screenHeight  = screen.height;
				const { userAgent } = navigator;
				let browser = 'unknown';
				let version = null;
				const browsers = [["Firefox"],["Edg","Edge"],["Chrome"],["Safari"]];
				browsers.some(browserData => {
					if(userAgent.includes(`${browserData[0]}/`)){
						browser = browserData[browserData.length - 1];
						const match = userAgent.match(new RegExp(`${browserData[0]}\\/(\\S+)\\b`,'i'));
						if(match) version = match[1];
						return true;
					}
					return false;
				})
				const route = window.location.pathname;//this.activatedRoute.snapshot._routerState.url;
				//const session = this.myChat.me.info.session ?? null;
				const session = this.sessionID;//window.Echo.connector.pusher.sessionID
				console.log("chat", this.myChat.chat);
				console.log("session", session);
				return {session, route,  os, browser, version, userAgent, browserName, browserVersion, screenWidth, screenHeight};
			}
			joinProjectChat(uuid:string, onJoin:Function = null, onHere:Function = null, onLeave:Function = null)
			{
				let channelName = "App.Project." + uuid;
				if(!this.projectChat)
				{
					this.projectChat = new Chat(onJoin, onHere, onLeave);
						
						/*(leaver) => {
						//console.log("chat leaving", leaver);
						for (const key in this._creativeUsers) {
							if (Object.prototype.hasOwnProperty.call(this._creativeUsers, key)) {
								let users = this._creativeUsers[key] as Array<any>;
								if(users){
									// TODO - find first? - this was essential in chrome!! splice (-1, 1) will delete from an array!!!
									let index = users.findIndex((u) => u.uuid == leaver.uuid && u.session == leaver.session )
									if(index != -1) users.splice(index, 1);
								}
							}
						}
					});*/
					this.projectChat.whispers.subscribe(whisper => {
						return;
						let event = whisper.event;
						whisper = whisper.whisper;
						let user = whisper.user;
						let users = this.projectChat.usersSubject.getValue();
						let userFound = users.find((u) => u.uuid == user.uuid);

						/*
						if(event == 'ping')
						{
							this.projectChat.whisper('pong');
							return;
						}*/
						/*
						if(event == 'pong')
						{
							if(userFound){
								//userFound.lastContact = Date.now();
								//this.projectChat.usersSubject.next(users);
								for (const creative_uuid in this._creativeUsers) {
									if (Object.prototype.hasOwnProperty.call(this._creativeUsers, creative_uuid)) {
										let users = this._creativeUsers[creative_uuid] as Array<any>;
										let creativeUser = users.find((u) => u.uuid == user.uuid && u.session == user.session)
										if(creativeUser)
										{
											creativeUser.lastContact = Date.now();
										}
									}
								}
								console.log("CREATIVE USERS PONG", this._creativeUsers);
								this.creativeUsersSubject.next(this._creativeUsers);
							}
							return;
						}else{
							// creative
						}
						*/
						let creative = whisper.message;
						let leaving = whisper.leaving;

						if(!userFound) return;
						if(!leaving)
						{
							user.lastContact = Date.now();
							if(!this._creativeUsers[creative])
							{
								this._creativeUsers[creative] = [user];
							}else{
								let users = this._creativeUsers[creative] as Array<any>;
								if(!users.find((u) => u.uuid == user.uuid && u.session == user.session))
								{
									// only push if not in already!
									users.push(user);
								}
								//this._creativeUsers[creative].push(user);
							}
						}else{
								let users = this._creativeUsers[creative] as Array<any>;
								console.log("leaving user", user);
								console.log("leaving users", users);
								
								if(users) users.splice(users.findIndex((u) => u.uuid == user.uuid && u.session == user.session ), 1);
								else{
									// todo why?
								}
						}
						console.log("CREATIVE, USERS", this._creativeUsers);
						
						this.creativeUsersSubject.next(this._creativeUsers);

						/*
						if(!user.creatives) user.creatives = [];
						if(!user.creatives.find((uuid) => uuid == creative))
						{
							if(leaving && user.session == user_session )
							{
								user.creatives.splice(user.creatives.indexOf(creative), 1)
							} else{
								user.creatives.push(creative);
							} 
						}*/
					});
				}else{
					// are we already in this chat?
					if(this.projectChat.channelName == channelName)
					{
						return;
					}
					this.projectChat.leave();
				}
				this.projectChat.join(channelName);
				return;
				if(this.currentProjectChat)
					this.leaveProjectChat();

				this.currentProjectChat = "App.Project." + uuid;
				// @ts-ignore
				this._currentProjectChat = window.Echo.join(this.currentProjectChat)
				.here((users) => {
					// we are in the chat and here are the current users already in...
					this._projectUsers = users;
					for (let i = 0; i < this._projectUsers.length; i++) {

						const user = this._projectUsers[i];
						user.initials = user.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
						if(false)//if(element.uuid == this.api.currentuser)
						{
							this._projectUsers.splice(i, 1);
							break;
						}					
					}
					this._projectUsersSubject.next(this._projectUsers);

					// can only whisper once we are in the channel.. 
					// need to message when we join and when others join incase they come later
					var here_whisper = false;
					if(here_whisper){
						let me = this._currentProjectChat.subscription.members.me.info;
						this._currentProjectChat.whisper("creative", {user:me.uuid, message:here_whisper});
					}
				})
				.joining((user) => {
					let alreadyJoined = this._projectUsers.some((element) => { return element.uuid == user.uuid});
					if(!alreadyJoined){
						user.initials = user.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
						this._projectUsers.push(user);
						this._projectUsersSubject.next(this._projectUsers);
					}

					// can only whisper once we are in the channel.. 
					// need to message when we join and when others join incase they come later
					var join_whisper = false;
					if(join_whisper){
						let me = this._currentProjectChat.subscription.members.me.info;
						this._currentProjectChat.whisper("creative", {user:me.uuid, message:join_whisper});
					}
				})
				.leaving((user) => {
					for (let i = 0; i < this._projectUsers.length; i++) {
						const element = this._projectUsers[i];
						if(element.uuid == user.uuid)
						{
							this._projectUsers.splice(i, 1);
							break;
						}					
					}
					// if they are leaving the project users, clear any of their creatives too
					for(let creative_uuid in this._creativeUsers)
					{
							let users = this._creativeUsers[creative_uuid] as Array<any>;
							if(users){
								let index = users.findIndex((user) => user.uuid == user.uuid);
								if(index != -1) users.splice(index, 1);
							}
					}
					this.creativeUsersSubject.next(this._creativeUsers);
					this._projectUsersSubject.next(this._projectUsers);
				}).error((error) => {
					console.error(error);
				})
				var listenToMessages = false;
				if(listenToMessages)
				{
					this._currentProjectChat.listen('RealTimeMessageNow', (e) =>{
						this._projectMessage.next(e);
					})
				}
				this._currentProjectChat.listenForWhisper('creative', (e) => {
					console.log("whisper creative...", e);
					let user_uuid = e.user;
					let creative = e.message;
					let leaving = e.leaving;

					let user = this._projectUsers.find((user) => user.uuid == user_uuid);
					if(!user) return;
					if(!leaving)
					{
							if(!this._creativeUsers[creative])
							{
								this._creativeUsers[creative] = [user];
							}else{
								let users = this._creativeUsers[creative] as Array<any>;
								if(!users.find((user) => user.uuid == user_uuid))
								{
									// only push if not in already!
									users.push(user);
								}
								//this._creativeUsers[creative].push(user);
							}
					}else{
							let users = this._creativeUsers[creative] as Array<any>;
							if(users)users.splice(users.findIndex((user) => user.uuid == user_uuid), 1);
							else{
								// todo why?
							}
					}
					this.creativeUsersSubject.next(this._creativeUsers);
					if(user)
					{
						if(!user.creatives) user.creatives = [];
						if(!user.creatives.find((uuid) => uuid == creative))
						{
							if(leaving)
							{
								user.creatives.splice(user.creatives.indexOf(creative), 1)
							} else{
								user.creatives.push(creative);
							} 
						}
						this._projectUsersSubject.next(this._projectUsers);
					}
				})
				// whisper
			}
			leaveProjectChat(uuid:string = null, creative_uuid:string = null)
			{
				if(this.projectChat)
				{
					this.projectChat.leave();
				}				
				return;
					// TODO accept uuid?
					if(creative_uuid)
					{
						// @ts-ignore
						let channel = window.Echo.connector.channels["presence-" + this.currentProjectChat];
						let me = channel.subscription.members.me?.info;
						if(me)
							channel.whisper("creative", {user:me.uuid, message:creative_uuid, leaving:true});
					}


					// @ts-ignore
					window.Echo.leave(this.currentProjectChat);
					this.currentProjectChat = null;
			}
			leaveCreativeChat(uuid:string = null)
			{
				if(this.creativeChat)
					this.creativeChat.leave();
				
				return;
					// TODO accept uuid?
					
					// @ts-ignore
					window.Echo.leave(this.currentCreativeChat);
					this.currentCreativeChat = null;
			}
			joinCreativeChat(uuid:string)
			{
				if(!this.creativeChat)
				{
					this.creativeChat = new Chat();
				}
				this.creativeChat.join("App.Creative." + uuid);
				return;
				if(this.currentCreativeChat)
					this.leaveCreativeChat();

				this.currentCreativeChat = "App.Creative." + uuid;
				// @ts-ignore
				let chat = window.Echo.join(this.currentCreativeChat)
				.here((users) => {
					this._users = users;
					console.log("here", this._users);
					for (let i = 0; i < this._users.length; i++) {

						const user = this._users[i];
						user.initials = user.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
						if(false)//if(element.uuid == this.api.currentuser)
						{
							this._users.splice(i, 1);
							break;
						}					
					}
					this._usersSubject.next(this._users);
				})
				.joining((user) => {
					console.log("joining", user);
					let alreadyJoined = this._users.some((element) => { return element.uuid == user.uuid});
					if(!alreadyJoined){
						user.initials = user.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
						this._users.push(user);
					}
					this._usersSubject.next(this._users);
				})
				.leaving((user) => {
					console.log("leaving", user);
					for (let i = 0; i < this._users.length; i++) {
						const element = this._users[i];
						if(element.uuid == user.uuid)
						{
							this._users.splice(i, 1);
							break;
						}					
					}
					this._usersSubject.next(this._users);
				})
				.listen('RealTimeMessageNow', (e) =>{
					this._creativeMessage.next(e);
				})
				.error((error) => {
					console.error(error);
				});
			}
			
			// workgroup
			leaveAccountChat()
			{
				if(this.accountChat)
					this.accountChat.leave();
			}
			joinAccountChat(uuid:string)
			{
				if(!this.accountChat)
					this.accountChat = new Chat();

				this.accountChat.join(`App.Account.${uuid}`);
			}
			leaveWorkgroupChat()
			{
				if(this.workgroupChat)
					this.workgroupChat.leave();
			}
			joinWorkgroupChat(uuid:string = null)
			{
				let channelName = `App.Workgroup.${uuid}`;
				if(!this.workgroupChat)
				{
					this.workgroupChat = new Chat();
				}else{
					// are we already in this chat?
					if(this.workgroupChat.channelName == channelName)
					{
						return;
					}
					this.workgroupChat.leave();
				}
				this.workgroupChat.join(`App.Workgroup.${uuid}`);
			}
			joinAppChat()
			{
				if(!this.appChat)
				{
					this.appChat = new Chat((e) => {
						//console.log("on join", e);
						
					}, (e) => {
						//console.log("on here", e);
						
					}, (leaver) => {
						//console.log("chat leaving", leaver);
						for (const key in this._creativeUsers) {
							if (Object.prototype.hasOwnProperty.call(this._creativeUsers, key)) {
								let users = this._creativeUsers[key] as Array<any>;
								// TODO - find first?
								if(users) users.splice(users.findIndex((u) => u.uuid == leaver.uuid && u.session == leaver.session ), 1);
							}
						}
					});
					this.appChat.whispers.subscribe(whisper => {
						let event = whisper.event;
						//console.log("whistper", whisper);
						
					});

				}

				this.appChat.join("App");
			}
			leaveAppChat()
			{
				if(this.appChat)
					this.appChat.leave();
			}
}

/*

// @ts-ignore
			window.Pusher = require('pusher-js');
			console.log("TOKEM", this.api.token);
			// @ts-ignore
			window.Echo = new Echo({
				broadcaster: 'pusher',
				key: "websocketkey",//process.env.MIX_PUSHER_APP_KEY,
				cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
				forceTLS: false,
				wsHost: window.location.hostname,
				wsPort: 8443,
				auth: {
					headers: {
						//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
						Authorization: 'Bearer ' + this.api.token,// BEARER_TOKEN,
					},
				},
			});
			// @ts-ignore
			window.Echo.channel('events').listen('RealTimeMessage', (e) =>{
				this._snackBar.open(e.message, null, {duration: 2000});
			});
			// @ts-ignore
			window.Echo.private('events').listen('RealTimeMessage', (e) =>{
			this._snackBar.open("private: " + e.message, null, {duration: 2000});
		});
*/