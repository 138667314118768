import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/api/admin.service';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';

@Component({
  selector: 'app-admin-jobs',
  templateUrl: './admin-jobs.component.html',
  styleUrls: ['./admin-jobs.component.scss']
})
export class AdminJobsComponent extends BaseComponent implements OnInit {

  public jobs:any[];
  public failed_jobs:any[];
  
  constructor(public adminService:AdminService,
    ) {
      super();
    }

  ngOnInit(): void {
    this.sub = this.adminService.jobs().subscribe(res => {
      this.jobs = res.data["jobs"];      
      this.failed_jobs = res.data["failed_jobs"];      
    });
  }
  testJob()
  {
    this.sub = this.adminService.testJob().subscribe();
  }
}
