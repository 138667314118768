export const tests = {
	test__ben_bug_1:
	{
		users:["user_ryanw", "user_elliec", "user_clintc"],
		stages:
		[
			"currentUser = user_ryanw",
			"completeCreative",

			"currentUser = user_clintc",
			"testAmendAdd c1",
			"submitAmendRequests",

			"currentUser = user_elliec",
			"testAmendDecline c1",
			
			"currentUser = user_clintc",
			"testAmendAcknowledgeDecline c1",
			"canSubmitAmendRequests == false"

		]
	},
	test_0all_approve:
	{
		users:["user_ryanw", "user_alecm", "user_elliec"],
		stages:
		[
			"currentUser = user_ryanw",
			"state == CreativeState.Production",
			"canAddAmendRequest == false",
			"canApproveCreative == false",
			"canSubmitAmendRequests == false",
			"canCompleteCreative == true",
			"completeCreative",
			"canCompleteCreative == false",

			"currentUser = user_alecm",
			"state == CreativeState.Approval",
			"canCompleteCreative == false",
			"canSubmitAmendRequests == false",
			"canAddAmendRequest == true",
			"canApproveCreative == true",
			"approveCreative",
			"canApproveCreative == false",

			"currentUser = user_elliec",
			"state == CreativeState.Approval",
			"canCompleteCreative == false",
			"canSubmitAmendRequests == false",
			"canAddAmendRequest == true",
			"canApproveCreative == true",
			"approveCreative",
			"canApproveCreative == false",
			"canAddAmendRequest == false",
			"canSubmitAmendRequests == false"
		]
	},
	test_1directAmend_approve :{
		users:["user_ryanw", "user_alecm", "user_elliec"],
		stages:
		[
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"approveCreative",

			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_alecm",
					"testAmendConfirmDone a1",
					"approveCreative",
		
				"currentUser = user_elliec",
					"approveCreative",

			"state == CreativeState.Done"
		]
	},
	test_2directAmend_devDecline:{
		users:["user_ryanw", "user_alecm", "user_elliec"],
		stages:
		[
			"state == CreativeState.Production",
			
				"currentUser = user_ryanw",
					"completeCreative",
			
			"state == CreativeState.Approval",
			
				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
			
				"currentUser = user_elliec",
					"approveCreative",
			
			"state == CreativeState.Production",
			
				"currentUser = user_ryanw",
					"testAmendDecline a1",
					"completeCreative",
			
			"state == CreativeState.Approval",
			
				"currentUser = user_alecm",
					"testAmendAcknowledgeDecline a1",
					"approveCreative",
			
				"currentUser = user_elliec",
					"approveCreative",	
			
			"state == CreativeState.Done"
		]
	},
	test_3directAmend_directReject:{
		users:["user_ryanw", "user_alecm", "user_elliec"],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"approveCreative",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendRejectDone a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"approveCreative",	
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendConfirmDone a1",
					"approveCreative",
		
				"currentUser = user_elliec",
					"approveCreative",	
		
			"state == CreativeState.Done"
		]
	},
	test_4directAmend_x2:{
		users:["user_ryanw", "user_alecm", "user_elliec"],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendAdd e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"testAmendComplete e1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendConfirmDone a1",
					"approveCreative",
		
				"currentUser = user_elliec",
					"testAmendConfirmDone e1",
					"approveCreative",
		
			"state == CreativeState.Done"
		]},
		test_5directAmend_x2_directReject_x1:{
		users:['user_ryanw', 'user_alecm', 'user_elliec'],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendAdd e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"testAmendComplete e1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendConfirmDone a1",
					"approveCreative",
		
				"currentUser = user_elliec",
					"testAmendRejectDone e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete e1",
					"completeCreative",
			
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"approveCreative",
		
				"currentUser = user_elliec",
					"testAmendConfirmDone e1",
					"approveCreative",
		
			"state == CreativeState.Done"
		]},
		test_6directAmend_x2_directReject_x1_devDecline_x1:{
		users:['user_ryanw', 'user_alecm', 'user_elliec'],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendAdd e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"testAmendComplete e1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendConfirmDone a1",
					"approveCreative",
			
				"currentUser = user_elliec",
					"testAmendRejectDone e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendDecline e1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"approveCreative",
			
				"currentUser = user_elliec",
					"testAmendAcknowledgeDecline e1",
					"approveCreative",
		
			"state == CreativeState.Done"
		]},
		test_7directAmend_x2_directReject_x2_devDecline_x2:{
		users:["user_ryanw", "user_alecm", "user_elliec"],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendAdd a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendAdd e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendComplete a1",
					"testAmendComplete e1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendRejectDone a1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendRejectDone e1",
					"submitAmendRequests",
		
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"testAmendDecline a1",
					"testAmendDecline e1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_alecm",
					"testAmendAcknowledgeDecline a1",
					"approveCreative",
			
				"currentUser = user_elliec",
					"testAmendAcknowledgeDecline e1",
					"approveCreative",
		
			"state == CreativeState.Done"
		]},
		//AUTOMATED TESTS - 1x MANAGED APPROVER
		test_8managed_AllApprove:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"approveCreative",
		
				"currentUser = user_clintc",
					"approveCreative",
		
			"state == CreativeState.Done" 
        ]},
        test_9managed_1xApproverAmend:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:
		[
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"approveCreative",

				"currentUser = user_clintc",
					"testAmendAdd c1",
					"submitAmendRequests",

				"currentUser = user_elliec",
					"testAmendAccept c1",
					//"submitAmendRequests",//breaks on CreativeState.Production if not on but works manually

			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",

				//test state for client
				"currentUser = user_clintc",
					"state == CreativeState.Production",

				//test state for manager
				"currentUser = user_elliec",
			"state == CreativeState.Approval",
					"testAmendConfirmDone c1",
					"approveCreative",

				"currentUser = user_clintc",
			"state == CreativeState.Approval",
					"testAmendConfirmDone c1",
					"approveCreative",

			"state == CreativeState.Done"

        ]},

		test_10managed_1xApproverAmend_1xAMdecline:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:
		[
			
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"approveCreative",

				"currentUser = user_clintc",
					"testAmendAdd c1",
					"submitAmendRequests",

				"currentUser = user_elliec",
					"testAmendDecline c1",

				"currentUser = user_clintc",
			"state == CreativeState.Approval",
					"testAmendAcknowledgeDecline c1",//problem recognising this (action message is wrong for this user: says 'you have submitted' from previous addAmend step)
					"approveCreative",

			"state == CreativeState.Done"
		]},

		test_11managed_1xApproverAmend_1xDevDecline:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:
		[
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"approveCreative",

				"currentUser = user_clintc",
					"testAmendAdd c1",
					"submitAmendRequests",

				"currentUser = user_elliec",
					"testAmendAccept c1",
					//"submitAmendRequests",

			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"testAmendDecline c1",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"testAmendAcknowledgeDecline c1",
					"approveCreative",//doesnt flag a test fail if this is commented out...but flags it at CreativeState.Done

				"currentUser = user_clintc",
			"state == CreativeState.Approval",
					"testAmendAcknowledgeDecline c1",
					"approveCreative",//doesnt flag a test fail if this is commented out...but flags it at CreativeState.Done

			"state == CreativeState.Done"
			

		]},

		test_12managed_1xApproverAmend_1xAMreject:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:
		[
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"approveCreative",

				"currentUser = user_clintc",
					"testAmendAdd c1",
					"submitAmendRequests",

				"currentUser = user_elliec",
					"testAmendAccept c1",
					//"submitAmendRequests",

			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",


			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"testAmendRejectDone c1",
					"stop",
					"approveCreative",// why approve here? Needs a better mechanism when rejecting

			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",

			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"testAmendConfirmDone c1",
					"approveCreative",

				"currentUser = user_clintc",
					"testAmendConfirmDone c1",
					"approveCreative",

			"state == CreativeState.Done"


		]},
		test_13managed_1xApproverAmend_1xApproverReject:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:	
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"approveCreative",
		
				"currentUser = user_clintc",
					"testAmendAdd c1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendAccept c1",
					//"submitAmendRequests",
		
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",
		
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"testAmendRejectDone c1",
					"approveCreative",//doesnt flag a test fail if this is commented out...but flags it at CreativeState.Done
		
			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"testAmendConfirmDone c1",
					"approveCreative",
		
				"currentUser = user_clintc",
					"state == CreativeState.Approval",
					"testAmendRejectDone c1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"state == CreativeState.Approval",
					"testAmendAcknowledgeRejection c1",
					//"submitAmendRequests",
			
			"state == CreativeState.Production",

				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",
		
			"state == CreativeState.Approval",

				"currentUser = user_elliec",
					"testAmendConfirmDone c1",
					"approveCreative",
			
				"currentUser = user_clintc",
					"testAmendConfirmDone c1",
					"approveCreative",
			
			"state == CreativeState.Done"
		
		]},
		test_14managed_1xApproverAmend_1xApproverReject_1xDEVdecline:{
		users:['user_ryanw', 'user_elliec', 'user_clintc'],
		stages:
		[
			"state == CreativeState.Production",
		
				"currentUser = user_ryanw",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"approveCreative",
		
				"currentUser = user_clintc",
					"testAmendAdd c1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
					"testAmendAccept c1",
					//"submitAmendRequests",
		
			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",
		
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"testAmendRejectDone c1",
					"approveCreative",
			
			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendComplete c1",
					"completeCreative",
		
			"state == CreativeState.Approval",
		
				"currentUser = user_elliec",
					"testAmendConfirmDone c1",
					"approveCreative",
		
				"currentUser = user_clintc",
				"state == CreativeState.Approval",//test again
					"testAmendRejectDone c1",
					"submitAmendRequests",
		
				"currentUser = user_elliec",
				"state == CreativeState.Approval",//test again
					"testAmendAcknowledgeRejection c1",
					//"submitAmendRequests",
		
			"state == CreativeState.Production",
				"currentUser = user_ryanw",
					"testAmendDecline c1",
					"completeCreative",
		
			"state == CreativeState.Approval",
				"currentUser = user_elliec",
					"testAmendAcknowledgeDecline c1",
					"approveCreative",
					//"submitAmendRequests",
		
				"currentUser = user_clintc",
					"testAmendAcknowledgeDecline c1",
					//"submitAmendRequests",
					"approveCreative",
		
			"state == CreativeState.Done"
		
		]},
		//AUTOMATED TESTS - COMBINED - 1x MANAGED APPROVER, 1x DIRECT APPROVER
		
		test_15combine_AllApprove:{
			users:['user_ryanw', 'user_alecm', 'user_elliec', 'user_clintc'],
			stages:
			[
				"state == CreativeState.Production",
			
					"currentUser = user_ryanw",
						"completeCreative",
			
				"state == CreativeState.Approval",
			
					"currentUser = user_elliec",
						"approveCreative",
			
					"currentUser = user_alecm",
						"approveCreative",

					"currentUser = user_clintc",
						"approveCreative",

				"state == CreativeState.Done"
			
			]},

		test_16combine_AMapprove_clientApprove_x2DirectAmend:{
			users:['user_ryanw', 'user_alecm', 'user_elliec', 'user_clintc'],
			stages:
			[
				"state == CreativeState.Production",
			
					"currentUser = user_ryanw",
						"completeCreative",
			
				"state == CreativeState.Approval",
			
					"currentUser = user_alecm",
						"testAmendAdd a1",
						"testAmendAdd a2",
						"submitAmendRequests",
						
					"currentUser = user_elliec",
						"approveCreative",
			
					"currentUser = user_clintc",
						"approveCreative",
	
				"state == CreativeState.Production",

					"currentUser = user_ryanw",
						"testAmendComplete a1",
						"testAmendComplete a2",
						"completeCreative",
	
				"state == CreativeState.Approval",
			
					"currentUser = user_alecm",
						"testAmendConfirmDone a1",
						"testAmendConfirmDone a2",
						"approveCreative",
						
					"currentUser = user_elliec",
						"approveCreative",
			
					"currentUser = user_clintc",
						"approveCreative",

				"state == CreativeState.Done"
			
			]},
		
		test_17combine_AMapprove_clientApprove_x2DirectAmend_x1DevDecline:{
			users:['user_ryanw', 'user_alecm', 'user_elliec', 'user_clintc'],
			stages:
			[
				"state == CreativeState.Production",
				
					"currentUser = user_ryanw",
						"completeCreative",
				
				"state == CreativeState.Approval",
				
					"currentUser = user_alecm",
						"testAmendAdd a1",
						"testAmendAdd a2",
						"submitAmendRequests",
							
					"currentUser = user_elliec",
						"approveCreative",
				
					"currentUser = user_clintc",
						"approveCreative",
		
				"state == CreativeState.Production",

					"currentUser = user_ryanw",
						"testAmendComplete a1",
						"testAmendDecline a2",
						"completeCreative",
	
				"state == CreativeState.Approval",
				
					"currentUser = user_alecm",
						"testAmendConfirmDone a1",
						"testAmendAcknowledgeDecline a2",
						"approveCreative",
							
					"currentUser = user_elliec",
						"approveCreative",
				
					"currentUser = user_clintc",
						"approveCreative",
				"state == CreativeState.Done"
				
			]},
		
		test_18combine_AMapprove_clientApprove_x2DirectAmend_x1DirectReject:{
			users:['user_ryanw', 'user_alecm', 'user_elliec', 'user_clintc'],
			stages:
			[
				"state == CreativeState.Production",
				
					"currentUser = user_ryanw",
						"completeCreative",
				
				"state == CreativeState.Approval",
				
					"currentUser = user_alecm",
						"testAmendAdd a1",
						"testAmendAdd a2",
						"submitAmendRequests",
						
					"currentUser = user_elliec",
						"approveCreative",
			
					"currentUser = user_clintc",
						"approveCreative",
		
				"state == CreativeState.Production",
					"currentUser = user_ryanw",
						"testAmendComplete a1",
						"testAmendComplete a2",
						"completeCreative",
	
				"state == CreativeState.Approval",
				
					"currentUser = user_alecm",
						"testAmendConfirmDone a1",
						"testAmendRejectDone a2",
						"submitAmendRequests",
						
						"currentUser = user_elliec",
							"approveCreative",
			
					"currentUser = user_clintc",
						"approveCreative",

				"state == CreativeState.Production",
					"currentUser = user_ryanw",
						//"testAmendComplete a1",
						"testAmendComplete a2",
						"completeCreative",
	
				"state == CreativeState.Approval",
				
					"currentUser = user_alecm",
						//"testAmendConfirmDone a1",
						"testAmendConfirmDone a2",
						"approveCreative",
							
					"currentUser = user_elliec",
						"approveCreative",
				
					"currentUser = user_clintc",
						"approveCreative",
				"state == CreativeState.Done"
				
			]},
		
		test_19combine_x1AMapprove_x2clientAmend_x2DirectAmend_x1DirectReject_x1AMreject_x1clientReject:{
				users:['user_ryanw', 'user_alecm', 'user_elliec', 'user_clintc'],
				stages:
				[
					"state == CreativeState.Production",
				
						"currentUser = user_ryanw",
							"completeCreative",
				
					"state == CreativeState.Approval",
					//1 AM amend
					//3 client amends
					//2 direct amends
						"currentUser = user_alecm",
							"testAmendAdd a1",
							"testAmendAdd a2",
							"submitAmendRequests",
							
						"currentUser = user_elliec",
							"testAmendAdd e1",
							"submitAmendRequests",
				
						"currentUser = user_clintc",
							"testAmendAdd c1",
							"testAmendAdd c2",
							"testAmendAdd c3",
							"submitAmendRequests",
						
						//1AM decline c1
						"currentUser = user_elliec",
							"testAmendDecline c1",
							"testAmendAccept c2",
							"testAmendAccept c3",
								//this goes back to CreativeState.Production at this point.... but shouldn't it stay in CreativeState.Approval for client to have to acknowledge decline c1 before it goes back to production?
						"currentUser = user_clintc",
							"testAmendAcknowledgeDecline c1",//problem recognising this (action message is wrong for this user - see test_10)
							
					"state == CreativeState.Production",
					//1 dev decline client c2
					//1 dev decline direct a1

						"currentUser = user_ryanw",
							"testAmendComplete e1",
							"testAmendComplete c3",
							"testAmendDecline c2",
							"testAmendDecline a1",
							"testAmendComplete a2",
							"completeCreative",
		
					"state == CreativeState.Approval",
					//1 AM reject done c3
					//1 client reject done c3
					//1 direct reject a2
						"currentUser = user_alecm",
							"testAmendAcknowledgeDecline a1",
							"testAmendRejectDone a2",
							"submitAmendRequests",

						"currentUser = user_elliec",
							"testAmendConfirmDone e1",
							"testAmendAcknowledgeDecline c2",
							"testAmendRejectDone c3",
							//"submitAmendRequests",
							"approveCreative",//front end expects this, but semantically this is not right - because there is a rejection in the respoinses

					"state == CreativeState.Production",
					//DEV complete c3
					//DEV complete a2
						"currentUser = user_ryanw",
							"testAmendComplete c3",
							"testAmendComplete a2",
							"completeCreative",

					"state == CreativeState.Approval",
					//AM confirm c3
					//direct reject a2 (again)

						"currentUser = user_clintc",
						"state == CreativeState.Production", //confirm production state

						"currentUser = user_elliec",
						"state == CreativeState.Approval", //confirm back to approval
							"testAmendConfirmDone c3",
							"approveCreative",

						"currentUser = user_alecm",
							"testAmendRejectDone a2",
							"submitAmendRequests",

						"currentUser = user_clintc",
							"state == CreativeState.Approval", //confirm approval state
								"testAmendAcknowledgeDecline c2",
								"testAmendConfirmDone c3",	
								"approveCreative",
					
					"state == CreativeState.Production",
					//DEV complete a2 (again)
						"currentUser = user_ryanw",
							"testAmendComplete a2",
							"completeCreative",

					"state == CreativeState.Approval",

						"currentUser = user_clintc",
						"state == CreativeState.Approval", //confirm production state

						"currentUser = user_elliec",
						"state == CreativeState.Approval", //confirm back to approval
							"approveCreative",
						
						"currentUser = user_clintc",
						"state == CreativeState.Approval", //confirm approval state
							"approveCreative",

						"currentUser = user_alecm",
							"testAmendConfirmDone a2",
							"approveCreative",

					"state == CreativeState.Done"
				
				]},
				//AUTOMATED TESTS - MULTI-STAGE
				
				test_15combine_AllApprove2:{
					users:['user_ryanw', 'user_alecm', 'user_elliec', 'user_clintc'],
					stages:
					[
						"state == CreativeState.Production",
					
							"currentUser = user_ryanw",
								"completeCreative",
					
						"state == CreativeState.Approval",
					
							"currentUser = user_elliec",
								"approveCreative",
					
							"currentUser = user_alecm",
								"approveCreative",
		
							"currentUser = user_clintc",
								"approveCreative",
		
						"state == CreativeState.Done"
					
					]},
			
};