import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovalGroup } from '../models/ApprovalGroup.model';
import { IUser } from '../models/user.model';
import { OVBaseService } from './OvBaseService';

@Injectable({
  providedIn: 'root'
})
export class ApprovalGroupService extends OVBaseService<ApprovalGroup, string> {
	constructor(protected http: HttpClient) {
		super(http, 'approvalGroup');
	}
  grant(id:number, permissions:number, user:IUser)
  {
    return this._post(`${this.base}/${id}/grant/${user.uuid}/${permissions}`);
  }
  revoke(id:number, permissions:number, user:IUser)
  {
    return this._post(`${this.base}/${id}/revoke/${user.uuid}/${permissions}`);
  }
}
