import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IMarker, MarkerOption } from 'src/app/models/marker.model';
import { BaseAssetComponent } from '../baseAsset.component';
import { AssetMetadataService } from 'src/app/services/assetMetadata.service';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent extends BaseAssetComponent {

  @ViewChildren('audio') private audioList: QueryList<ElementRef<HTMLAudioElement>>;
  private defaultTimeGap = 2;

  constructor(protected assetMetadataService: AssetMetadataService) {
    super();
	  this.defaultWidth = 300;
	  this.defaultHeight = 108;
  }

  ngOnInit(): void {

  }
  markerDrop(marker:IMarker, x:number, y:number):boolean
	{
    //console.log("MARKER DROP!")
    marker.type = MarkerOption.TYPE_MARKER_HIDDEN;
    marker.x0 = 0;
    marker.y0 = 0;
		return true;
	}
  async getAmendOptions(x:number, y:number, specific:boolean):Promise<MarkerOption[]>
	{
    let data = {in:0, out:0};
    if(this.audioList?.first)
    {
      let audio = this.audioList.first.nativeElement;
      data.in = audio.currentTime;
      data.out = Math.min(data.in + this.defaultTimeGap, audio.duration);
    }
    let message = specific ? 'Link current time to amend' : 'Add amend at current time to this audio clip';
		return new Promise((resolve, reject) => resolve([new MarkerOption(message, MarkerOption.ICON_TIME, MarkerOption.TYPE_GENERAL, data)]));
	}
  onMetaData(event:Event)
  {
    if(this.assetVO.preview)
    {
      let media:HTMLMediaElement = event.target as HTMLMediaElement;
      if(!this.assetVO.fileMetadata)  this.assetVO.fileMetadata = {};
      this.assetVO.fileMetadata.duration = media.duration;
      this.assetMetadataService.assetMetaSubject.next(this.assetVO);
    }
  }
  updateMarker(marker:IMarker, message:string)
  {
    let element = this.audioList?.first.nativeElement;
    //console.log("audio update marker..");
    
    if(!element) return;
     if(message == "in"){
      marker.metadata.in = element.currentTime;
      if(marker.metadata.in >= marker.metadata.out)
      {
        marker.metadata.out = Math.min(marker.metadata.in + this.defaultTimeGap, element.duration)
      }
     }else if(message == "out"){
      marker.metadata.out = element.currentTime;
      if(marker.metadata.out <= marker.metadata.in)
      {
        marker.metadata.in = Math.max(0,  marker.metadata.out - this.defaultTimeGap)
      }
     }
     marker.metadata.in = marker.metadata.in;
     marker.metadata.out = marker.metadata.out;
  }
  seek(time:number)
  {
    let element = this.audioList?.first.nativeElement;
    if(!element) return;
    if(element && element.duration)
    {
      if(!element.paused)
      {
        element.pause();
      }
      element.currentTime = time;
    }else{
      // store and wait... TODO
      console.warn("TODO implement a wait");
     
    }    
  }
}
