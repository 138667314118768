export class Permission {
	 
	public static ACCESS: number        = 1 << 0;	// 1
    public static PRODUCTION: number    = 1 << 1;	// 2
    public static APPROVAL: number      = 1 << 2;	// 4
    public static ADMIN: number         = 1 << 3;	// 8
    //public static VALIDATEE: number     = 1 << 4;	// 16
    public static VALIDATOR: number     = 1 << 5;	// 32

    public static LOOKUP = {	"access":Permission.ACCESS,
								"admin":Permission.ADMIN,
								"production":Permission.PRODUCTION,
								"approval":Permission.APPROVAL,
								//"approval_basic":Permission.VALIDATEE,
								"approval_manager":Permission.VALIDATOR};

	public static REVERSE_LOOKUP = {1:"access", 8:"admin", 2:"production", 4:"approval"};

	public static hasPermission(permissions:number, permission:number):boolean
	{
		return (permissions & permission) === permission;
	}
	public static hasAnyPermission(permissions:number, permission:number):boolean
	{
		return (permissions & permission) != 0;
	}
	public static permissionsToString(permissions:number)
	{
		let string = '';
		for(var key in Permission.REVERSE_LOOKUP)
		{
			if((parseInt(key) & permissions) != 0)
			{
				if(string.length) string += ', ';
				string += Permission.REVERSE_LOOKUP[key];
			}
		}
		return string;
	}
}