import { AppUser } from "../services/app-user.service";
import { IHasReference, ModelUtils } from "../utils/ModelUtils";
import { IAsset } from "./asset.model";
import { IMarker, MarkerVO } from "./marker.model";
import { User } from "./user.model";

export interface ITaskMessage {
	id:number;
	uuid:string;
	task_uuid:string;
	user_uuid:string;
	creative_version:number;
	role:string;
	state:string;
	action:string;
	message:string;
	created_at : string;
	updated_at : string;
	user:User;
}

export interface ITask {
	id: number;
	uuid: string;
	project_uuid: string;
	creative_uuid: string;
	user_uuid: string;
	author: string;
	content: string;
	open: boolean;
	type: string;
	priority: number;
	state: string;
	role: string;
	actioned: boolean;
	flag: number;
	creative_version: number;
	creative_version_max: number;
	colour: string;

	// time stamps
	created_at : string;
	updated_at : string;

	assets : IAsset[];
	actions : any[];
	messages : ITaskMessage[];
	buttons : any[];

	marker: IMarker;	
	markers: IMarker[];	

	responses:{message:string, user:string, user_uuid:string}[];
	files:any[];
}

export class Task implements ITask, IHasReference<Task>{
	
	static decorateTask(task: Task, user:AppUser = null) {

		if(task.marker)
		{
			// convert metadata from string to object
			if(typeof task.marker.metadata == "string")
			{
				task.marker.metadata = JSON.parse(task.marker.metadata);
			}
		}
		if(task.type == TaskType.AMEND)
		{
			task.responses = [];
			task.messages?.forEach((message:ITaskMessage) => {
				let mine = message.user.uuid == user.uuid;
				let userName = mine ? "You" : message.user.name;
				task.responses.push({message:message.message, user:userName + " - " + message.action, user_uuid:message.user.uuid + " v"+ message.creative_version});
				//console.log("response msg:",message.message);
			})
			//console.log("msgs",task.responses.length,task.messages.length);
			/*
			// old way using actions
			task.actions?.forEach((action:TaskAction) => {
				if(action.action == TaskActionAction.DECLINED || 
					action.action == TaskActionAction.REJECTED ||
					action.action == TaskActionAction.QUERIED ||
					action.action == TaskActionAction.ANSWERED ||
					action.action == TaskActionAction.DECLINE_PUSHED_BACK ||
					action.action == TaskActionAction.DENY_REJECTED ||
					action.action == TaskActionAction.RECALLED ||
					action.action == TaskActionAction.DENIED)
				{
					let userName = (action.user.uuid == user.uuid) ? "You" : action.user.name;
					task.responses.push({message:action.message, user:userName + " - " + action.action, user_uuid:action.user.uuid});
				}
			})
			*/
		}
		task.reference = ModelUtils.createReference(task);
	}
    public id: number;
    public uuid: string;
    public project_uuid: string;
    public creative_uuid: string;
    public user_uuid: string;
    public author: string;
    public content: string;
    public open: boolean;
    public type: string;
    public priority: number;
    public state: string;
    public role: string;
    public actioned: boolean;
    public flag: number;
    public created_at: string;
    public updated_at: string;
	public creative_version: number;
	public creative_version_max: number;
	public colour: string;
    public assets: any[];
    public actions: any[];
    public messages: ITaskMessage[];
    public marker: IMarker;
    public markerVO: MarkerVO;
    public markers: IMarker[];
	public responses:{message:string, user:string, user_uuid:string}[];


	reference:Task;

    public buttons: any[];
	public files:any[];

	static TYPE_PRODUCTION = "production";	//
	static TYPE_APPROVAL = "approval";		//
	static TYPE_AMEND = "amend";				//
	static TYPE_MODERATION = "moderation";				//

	// PRODUCTION
	static PRODUCTION_NEW = "new";	
	static PRODUCTION_STARTED = "started";
	static PRODUCTION_COMPLETED = "completed";
	static PRODUCTION_RECALLED = "recalled";

	// APPROVAL
	static APPROVAL_WAITING = "waiting";		// creative not yet built by production
	static APPROVAL_APPROVAL = "approval";	// approver is allowed to approver the creative or request and submit amends
	static APPROVAL_SUBMITTED = "submitted";	// my amends have been submitted and am waiting for any other approvers to make descisions
	static APPROVAL_AMENDING = "amending";
	static APPROVAL_APPROVED = "approved";

    // crude merge of b into a TODO just make this a util or make a base model for use to use throughout app
	static merge(a:Task, b:Task){
		for (const key in b) {
			if (Object.prototype.hasOwnProperty.call(b, key)) {
				const element = b[key];
				if(element != null && element != undefined){
					a[key] = element;
				}
			}
		}
	}

	static isBlue(task:ITask, user_uuid:string):boolean
	{
		if(	task.role 		== TaskRole.AMEND_ROLE_APPROVER && 
			task.state 		== TaskState.AMEND_TODO && 
			task.actioned 	== true &&
			task.user_uuid 	== user_uuid
		) return true;
		
		if( task.role 		== TaskRole.AMEND_ROLE_MODERATOR &&
			task.state 		== TaskState.AMEND_TODO && 
			task.actioned 	== true
		) return true;
		
		if( task.role 		== TaskRole.AMEND_ROLE_PRODUCTION && 
			task.state 		== TaskState.AMEND_DONE
		) return true;

		return false;
	}

	static isGreen(task:ITask, user_uuid:string):boolean
	{
		if( task.role 		== TaskRole.AMEND_ROLE_MODERATOR &&
			task.state 		== TaskState.AMEND_DONE
		) return true;
		
		if( task.role 		== TaskRole.AMEND_ROLE_APPROVER && 
			task.state 		== TaskState.AMEND_DONE &&
			task.user_uuid 	== user_uuid 
		) return true;
		
		return false;
	}
	static isRed(task:ITask, user_uuid:string):boolean
	{
		if( task.role 		== TaskRole.AMEND_ROLE_APPROVER && 
			task.state 		== TaskState.AMEND_LOCAL
		) return true;

		if( task.role == TaskRole.AMEND_ROLE_PRODUCTION && 
			task.state == TaskState.AMEND_TODO
		) return true;

		return false;
	}

	static getColour(task:ITask, user_uuid:string):string
	{
		if(Task.isRed(task, user_uuid))	return 'red';
		else if(Task.isBlue(task, user_uuid)) return 'blue';
		else if(Task.isGreen(task, user_uuid)) return 'green';
		return '';
	}
}

export interface ITaskAction {
	id:number;
	created_at : Date;
	updated_at : Date;
	task_uuid:string;
	user_uuid:string;
	action:string;
	creative_version:string;
	message:string;
}
export class TaskAction implements ITaskAction
{
	id: number;
	created_at: Date;
	updated_at: Date;
	task_uuid: string;
	user_uuid: string;
	action: string;
	creative_version: string;
	message: string;
	user : User;
}
export enum TaskActionAction
{
	ACCEPTED 			= 'Accepted',
	DECLINED 			= 'Declined',
	DECLINE_PUSHED_BACK = 'Decline pushed back',
	SUBMITTED 			= 'Submitted',
	DELTED 				= 'Delted',
	UPDATED 			= 'Updated',
	STARTED 			= 'Started',
	COMPLETED 			= 'Completed',
	DONE 				= 'Done',
	CONFIRMED_DONE 		= 'Confirmed done',
	REASSIGNED 			= 'Reassigned',
	PRODUCTION_CHANGE 	= 'Production change',
	REJECTED 			= 'Rejected',
	QUERIED 			= 'Queried',
	ANSWERED 			= 'Answered',
	DENY_REJECTED 		= "Deny rejected",
	RECALLED 			= "Recalled",
	DENIED 				= "Denied",
}

export enum TaskType
{
	PRODUCTION			= 'production',
	APPROVAL 			= 'approval',
	AMEND 				= 'amend',
	MODERATOR 			= 'moderation',
}

export enum TaskState
{
	AMEND_TODO 			= "todo",		// incoming amend
	AMEND_DONE 			= "done",		// outgoing amend
	AMEND_RECALLED		= "recalled",	// recalled by author before actioned
	AMEND_LOCAL			= "local",		// new and not yet saved
}

export enum TaskRole
{
	AMEND_ROLE_PRODUCTION 	= "production",	
	AMEND_ROLE_MODERATOR 	= "moderator",	
	AMEND_ROLE_APPROVER 	= "approver",	
}

export enum TaskFlag
{
	FLAG_AMEND_REJECTED 			= 1 << 0,
	FLAG_AMEND_LATE 				= 1 << 1,
	FLAG_AMEND_MANAGER 				= 1 << 2,
	FLAG_AMEND_DECLINED 			= 1 << 3,
	FLAG_AMEND_DECLINED_PUSHBACK 	= 1 << 4,	
	FLAG_AMEND_REJECTED_PUSHBACK	= 1 << 5,	
	FLAG_AMEND_QUERIED				= 1 << 6,		
	FLAG_AMEND_DENIED				= 1 << 7,		
}