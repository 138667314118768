<div *ngIf="current_user && (current_user.roles.architect || current_user.roles.superadmin || current_user.roles.admin)">
	<h1>Projects</h1>
	<!--
	<h2>TODO</h2>
	<ul>
	    <li>- view list/tree of all projects which can link direct to the project + menu stuff</li>
	    <li>- search and filter functionality</li>
	</ul> 
	<pre>
	    {{this.getTreeString()}}
	</pre>
	-->
	<div class="inputs">
		<div class="select" *ngIf="accounts">
			<mat-form-field class="account">
			  <mat-label>Account</mat-label>
			  <mat-select id="account" [value]="account_id" (selectionChange)="setAccountId($event.value)">
			    <mat-option *ngFor="let account of accounts" [value]="account.uuid">
			      {{account.name}}
			    </mat-option>
			  </mat-select>
			</mat-form-field>
		</div>
		<div class="select" *ngIf="workgroups">
			<mat-form-field class="workgroup">
			  <mat-label>Workgroup</mat-label>
			  <mat-select id="workgroup" [value]="workgroup_id" (selectionChange)="setWorkgroupId($event.value)">
			    <mat-option *ngFor="let workgroup of workgroups" [value]="workgroup.uuid">
			      {{workgroup.name}}
			    </mat-option>
			  </mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="tree" *ngIf="renderTree">
		<app-project-tree [account_id]="account_id" [workgroup_id]="workgroup_id"></app-project-tree>
	</div>
</div>
<div *ngIf="current_user && (!current_user.roles.architect && !current_user.roles.superadmin && !current_user.roles.admin)">
	<h1>My Projects</h1>
	<div class="tree" *ngIf="renderTree">
		<app-project-tree [user_id]="current_user.uuid"></app-project-tree>
	</div>
</div>