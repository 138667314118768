<div class="middle">
	<app-control-bar-view [currentMode]=controlMode (addPanel)="getPanel($event)" (controlMode)="getControlMode($event)"></app-control-bar-view>
	<div class="upload_bar">
		<button class="upload_button" (click)="clickUploadAssetButton()" mat-raised-button><mat-icon>add</mat-icon><span>Images</span></button>
	</div>
	<div class="left_panel">
		<app-asset-embed *ngFor="let asset of assets; index as i" [asset]="asset" [creative]="this"></app-asset-embed>
	</div>
	<div class="right_panel" #creativePropertiesPanel>
		<app-creative-properties #creativeProperties [parent]="creativePropertiesPanel"></app-creative-properties>
	</div>
</div>
<!--
<div class="right_panel">
	<mat-card>
		<h1>{{name}}</h1><button mat-icon-button (click)="removeCreative()"><mat-icon>delete</mat-icon></button>
		<mat-card class="add_asset">
			<h2>Upload Asset</h2>
			<mat-form-field class="input_field">
				<input matInput #add_asset_name class="login_input" placeholder="Name" value="" (keyup)="setAssetSaveDataDelayed('name', $event.target.value)" (change)="setAssetSaveData('name', $event.target.value)">
			</mat-form-field>
			<mat-form-field class="input_field">
				<input matInput type="number" #add_asset_width id="_asset_w" class="login_input" placeholder="Width" value="{{asset_width}}" (keyup)="setAssetSaveDataDelayed('width', $event.target.value)" (change)="setAssetSaveData('width', $event.target.value)">
			</mat-form-field>
			<mat-form-field class="input_field">
				<input matInput type="number" #add_asset_height id="_asset_h" class="login_input" placeholder="Height" value="{{asset_height}}" (keyup)="setAssetSaveDataDelayed('height', $event.target.value)" (change)="setAssetSaveData('height', $event.target.value)">
			</mat-form-field>
			<mat-form-field class="asset_type">
			  <mat-label>Type</mat-label>
			  <mat-select id="asset_type" [value]="asset_type_id" (selectionChange)="setAssetTypeId($event.value)">
			    <mat-option *ngFor="let asset_type of asset_types" [value]="asset_type.uuid">
			      {{asset_type.name}}
			    </mat-option>
			  </mat-select>
			</mat-form-field>
			<div *ngIf="asset_type_id == 1" class="image_type additional_attributes">
				<div class="upload_file">
					<h4>File</h4>
					<app-image-uploader [width]="asset_width" [height]="asset_height" [parent]="this"></app-image-uploader>
				</div>
			</div>
			<div class="button_wrapper_save">
				<button *ngIf="required_fields_filled" mat-raised-button (click)="uploadAsset({name: add_asset_name.value, width: add_asset_width.value, height: add_asset_height.value})">Save</button>
			</div>
		</mat-card>
		
		<mat-card *ngIf="assets">
			<h2>Assets</h2>
			<mat-list role="list">
				<mat-list-item *ngFor="let asset of assets; index as i" role="listitem" class="list-item">
					<div class="row asset" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
						<p class="name">{{asset.name}} ({{asset.width}}x{{asset.height}})</p>
						<button class="icon" mat-icon-button (click)="ds.openEditAssetDialog({parent:this, asset:asset})"><mat-icon>edit</mat-icon></button>
					</div>
				</mat-list-item>
			</mat-list>
		</mat-card>
	</mat-card>
</div>
-->
<!--
<div class="specifications">
	<app-asset-specifications></app-asset-specifications>
</div>
-->