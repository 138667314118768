<div class="dialog">
	<h2 *ngIf="title">{{title}}</h2>
	<div class="error" *ngIf="no_users_message"><p>{{no_users_message}}</p></div>
	<div class="block-list" *ngIf="all_users && !no_users_message">
		<mat-selection-list #selected_users (selectionChange)="onSelection($event, selected_users.selectedOptions.selected)">
			<mat-list-option *ngFor="let user of all_users" [value]="user.uuid" [selected]="user.checked">
				<div class="user">
					<div class="details">
						<div class="name">{{user.name}}</div>
					</div>
				</div>
			</mat-list-option>
		</mat-selection-list>
	</div>
	<div *ngIf="dismiss_button_label && !no_users_message" class="button_wrapper">
		<button mat-raised-button (click)="dismissAction()">{{dismiss_button_label}}</button>
	</div>
</div>