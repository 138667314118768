import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppUserService } from 'src/app/services/app-user.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	constructor(private route: ActivatedRoute, private router: Router, private api:ApiService, private appUserService:AppUserService) { }
	
	ngOnInit() {
		this.api.logoutObservable.pipe(takeUntil(this._unsubscribe)).subscribe(res =>
		{
			//console.log("LOG OUT RESPONSE", res);
			if(res)
			{
				this.appUserService.appUser = null;
				this.appUserService.appUserSubject.next(this.appUserService.appUser);
				this.router.navigate(['login']);
			}
		});
		this.api.logOut();
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}