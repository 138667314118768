export function dateToAge(date : string | Date) : string {
    return dateDiff(typeof date == 'object' ? date : new Date(date));
    //return (new Date((item || this.task).created_at)).toLocaleString();
}
// [what a can o worms](https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript)
function dateDiff(from, to = new Date())
{
    //var inverse = from > to ? 1 : -1;
    var diff = to.getTime() - from.getTime();
    //console.log("diff", diff);

    var diff_seconds = diff / 1000;
    var diff_minutes, diff_hours, diff_days;
    //var diff_hours = diff_minutes / 60;
    //var diff_days = diff_hours / 24;

    if(diff_seconds < 60) // less than 60 seconds
    {
        return "just now";
    }else if((diff_minutes = (diff_seconds / 60) | 0) < 60){
        return diff_minutes + (diff_minutes > 1 ? " mins" : " min");
    }else if((diff_hours = (diff_minutes / 60) | 0) < 24){
        return diff_hours + (diff_hours > 1 ? " hours" : " hour");
    }else if((diff_days = (diff_hours / 24) | 0) <= 28){
        return diff_days + (diff_days > 1 ? " days" : " day");
    }


    var years = to.getFullYear() - from.getFullYear();
    if(years > 0) return years + (years > 1 ? " years" : " year");
    var months = (to.getMonth() - from.getMonth()) + (12 * years);
    var days = to.getDate() - from.getDate();
    if(days < 0) months--;
    if(months > 0) return months + (months > 1 ? " months" : " month");
    if(months == 0) return diff_days + (diff_days > 1 ? " days" : " day");

    var message = "";
/*
    var diff = Math.floor(to.getTime() - from.getTime());
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff/day);
    var months = Math.floor(days/31);
    var years = Math.floor(months/12);	
    var message;// = from.toDateString();*/
    /*
    if(years)
    {
        message = years + "y"
    }else if(months)
    {
        message = months + "m"
    }else if(days)
    {
        message = days + "d"
    }
    message += " was "
    message += days + " days " 
    message += months + " months "
    message += years + " years ago \n"
*/
    return message
}