<header *ngIf="appUserService.appUserObservable$ | async" >
	<div class="header-top" [class.super]="appUser.super" [class.impersonated]="appUser.impersonated">
		<div class="button-group group-left">
			<!--<button [matTooltip]="strings.getTooltip('btn_dashboard')" class="icon_button dashboard" (click)="api.gotoPage('dashboard')"><mat-icon>dashboard</mat-icon></button>-->
			<div class="oceanview" (click)="api.gotoPage('/')">
				<!--<span>OCEANVIEW</span>-->
				<img src="../../../assets/img/OV_master_black_app.png" />
			</div>
			<!--<button [matTooltip]="strings.getTooltip('btn_home')" class="icon_button home" (click)="api.gotoPage('/')"><mat-icon>home</mat-icon></button>-->
		</div>
		<!--<div class="logo"></div>-->
		<div class="button-group group-right" *ngIf="appUser">
			<span [matTooltip]="appUser.email" class="username">{{appUser.name}}</span>
			<button *ngIf="appUser.impersonated" mat-raised-button color="accent" (click)="stopImpersonation()">Stop impersonation</button>
			<button *ngIf="false" [matTooltip]="strings.getTooltip('btn_notifications')" class="icon_button notifications" (click)="showNotifications()"><mat-icon>notifications</mat-icon></button>	<!--matBadge="{{pusher.getMessageCount()}}" matBadgeSize="small"-->
			<button *ngIf="false" [matTooltip]="strings.getTooltip('btn_settings')" class="icon_button settings" (click)="api.gotoPage('settings')"><mat-icon>settings</mat-icon></button>
			<button *ngIf="appUser.super && appUser.email.indexOf('+us') == -1" [matTooltip]="strings.getTooltip('btn_admin')" class="icon_button admin" (click)="api.gotoPage('admin')"><mat-icon>admin_panel_settings</mat-icon></button>
			<!--<button [matTooltip]="'help'" class="icon_button admin"><mat-icon>help</mat-icon></button>-->
			<button [matTooltip]="strings.getTooltip('btn_logout')" mat-icon-button (click)="confirmLogOut()"><mat-icon>logout</mat-icon></button>
			<mat-slide-toggle *ngIf="false" [checked]="themeService.isDarkTheme | async" (change)="toggleDarkTheme($event.checked)">Dark theme</mat-slide-toggle>
			<!--<button class="log_out" mat-raised-button (click)="confirmLogOut()">Log Out</button>-->
		</div>
	</div>
	<div  class="header-bottom">
		<app-breadcrumbs></app-breadcrumbs>
		<app-active-users [chatUsers]="pusher.projectChat?.users"></app-active-users>
		<!--  ..todo people.. -->
	</div>	
</header>
