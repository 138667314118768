<div class="outer">
	<mat-card class="my_projects" *ngIf="my_projects">
		<h4>My Projects</h4>
<!-- 		<mat-list role="list"> -->
<!-- 			<mat-list-item *ngFor="let project of my_projects; index as i" role="listitem" class="list-item" [attr.data-project-uuid]="project.uuid" (click)="api.gotoPage('project/'+project.uuid)" > -->
			<div *ngFor="let project of my_projects; index as i" role="listitem" class="list-item" [attr.data-project-uuid]="project.uuid" (click)="api.gotoPage('project/'+project.uuid)" >
				<div class="project" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
					<div class="project_name">
						<p class="name"><mat-icon *ngIf="project.is_locked" class="lock">lock</mat-icon><mat-icon *ngIf="!project.is_locked && project.show_unlockicon" class="lock">lock_open</mat-icon>{{project.name}}</p>
					</div>
					<div class="project_total">{{project.total}}</div>
					<div class="project_health">
						<div class="bar" *ngFor="let bar of project.health">
							<div class="bar_background">
								<div class="bar_inner forward" [attr.data-forward]="bar.forward"></div>
								<div class="bar_inner backward" [attr.data-backward]="bar.backward"></div>
								<div *ngIf="bar.todo" class="todo"><span>{{bar.todo}}</span></div>
							</div>
							<div class="bar_total">{{bar.backward+bar.forward}}</div>
						</div>
						
					</div>
				</div>
				</div>
<!-- 			</mat-list-item> -->
<!-- 		</mat-list> -->
	</mat-card>
	<mat-card class="my_projects" *ngIf="!my_projects">
		<h4>My Projects</h4>
		<p>You have no projects assigned to you</p>
	</mat-card>
</div>