import { Component, OnInit } from '@angular/core';
//import { TranscoderService } from 'src/app/api/transcoder.service';

@Component({
  selector: 'app-transcoder',
  templateUrl: './transcoder.component.html',
  styleUrls: ['./transcoder.component.scss']
})
export class TranscoderComponent implements OnInit {

  file:File;
  constructor(
    //public transcoderService:TranscoderService
  ) { } //upload service

  ngOnInit(): void {
  }
  onFilesDragged(files:FileList)
  {
    
    this.file = files[0];
  }
  stat()
  {
   
    // send video to be transcoded on backend..
    /*
    this.transcoderService.stat(this.file).subscribe((res) => {
      console.log("stat", res);      
    });
    */
  }
  transcode()
  {
   
    // send video to be transcoded on backend..
    /*
    this.transcoderService.transcode(this.file).subscribe((res) => {
      console.log("transcode", res);      
    });
    */
  }
  state()
  {
   
    // send video to be transcoded on backend..
    /*
    this.transcoderService.state().subscribe((res) => {
      console.log("state", res);      
    });
    */
  }
}
