import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	user:any;
	user_access:any;
	my_projects:any = false;
	my_tasks:any = false;
	my_notifications:any = false;

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		
		this.user = this.api.getCurrentUser();
		
		this.api.getcurrentuseraccess.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res == 0) return;
			this.user_access = res;
			if(this.user_access.containers.projects.length > 0){
				this.my_projects = this.user_access.containers.projects;
				//console.log('this.my_projects', this.my_projects);
			}
			$('body').removeClass('saving').removeClass('loading');
		});
		
		this.api.getCurrentUserAccess(this.user.uuid);
		$('body').addClass('saving').addClass('loading');
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}