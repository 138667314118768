<mat-tab-group #tabs animationDuration="0ms">
	<mat-tab label="Workgroup Projects">
        <app-projects #projects *ngIf="workgroup" [workgroup]="workgroup" class="center"></app-projects>
    </mat-tab>
    <mat-tab label="Workgroup Team">
        <div class="center">
            <mat-card class="card">
                <mat-card-header class="shadow-underline">
                  <mat-card-title><div>Team</div></mat-card-title>
                 <!-- <mat-card-subtitle><div style="text-align: right;">List of available team members and those assigned to this workgroup</div></mat-card-subtitle>--> 
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field class="search">
                        <mat-label>Search</mat-label>
                        <input matInput type="text" [(ngModel)]="search">
                        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
                          <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <!--
                        <mat-selection-list *ngIf="this.appUserService.isAny(); else notAdmin" class="list" (selectionChange)="onAvailableUserSelected($event)">
                            <mat-list-option *ngFor="let user of availableUsers | filter : search" [ngClass]="{'inactive':!isAvailableUserSelected(user)}" [value]="user" [selected]="isAvailableUserSelected(user)">{{user.name}}</mat-list-option>
                        </mat-selection-list> 

                    -->
                    <mat-list *ngIf="(appUserService.appSuperOrAccountAdmin$ | async); else notAdmin" class="list" (selectionChange)="onAvailableUserSelected($event)" style="padding: 1em;">
                        <div class="user-list">
                            <div class="col">
                                <span>Available Users</span>
                                <div>
                                    <mat-list-item *ngFor="let user of nonActiveUsers | filter : search | sort : 'name' : false"
                                        [selected]="isAvailableUserSelected(user)"
                                        [ngClass]="{'inactive':!isAvailableUserSelected(user)}"        
                                        > <!-- [disabled]="true" - set this while api calls are happening -->
                                        <span mat-line [matTooltip]="user.email">{{user.name}}</span> 
                                        <button mat-icon-button color="primary" matTooltip="Add user to workgroup" (click)="activate(user)"><mat-icon>person_add</mat-icon></button>       
                                    </mat-list-item>
                                </div>
                            </div>
                            <div class="col">
                                <span>Activated Users</span>
                                <div>
                                    <mat-list-item *ngFor="let user of users | filter : search | sort : 'name' : false"
                                        [selected]="isAvailableUserSelected(user)"
                                        [ngClass]="{'inactive':!isAvailableUserSelected(user)}"                  
                                        > <!-- [disabled]="true" - set this while api calls are happening -->
                                        <span mat-line [matTooltip]="user.email">{{user.name}}</span>  
                                        <button mat-icon-button color="warn" matTooltip="Remove user from workgroup" (click)="deactivate(user)"><mat-icon>person_remove</mat-icon></button>          
                                    </mat-list-item>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="false">

                                <mat-list-item *ngFor="let user of availableUsers | filter : search"
                                [selected]="isAvailableUserSelected(user)"
                                [ngClass]="{'inactive':!isAvailableUserSelected(user)}"                            
                                > <!-- [disabled]="true" - set this while api calls are happening -->
                                <span mat-line>{{user.name}}</span>
                                <mat-slide-toggle [checked]="isAvailableUserSelected(user)" [matTooltip]="isAvailableUserSelected(user) ? 'Remove user from team' : 'Add user to team'" (change)="onAvailableUserToggled($event, user)">
                                </mat-slide-toggle>
                            </mat-list-item>
                        </div>
                    </mat-list> 

                    <ng-template #notAdmin>
                        <mat-list class="list">
                            <mat-list-item *ngFor="let user of users | filter : search">{{user.name}}</mat-list-item>
                        </mat-list> 
                    </ng-template>
                </mat-card-content>
               <!-- <mat-card-actions *ngIf="true">
                    <button *ngIf="(appUserService.appSuperOrAccountAdmin$ | async)" (click)="addUser()" matTooltip="Add new user" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
                </mat-card-actions> -->
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>
