import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HelpService {

	constructor() { }
	
	tooltips:any = {
		'approver': 'Find out more about approvers',		
	};
	content:any = {
		'wf-approver':{title: 'Approver role', body: '<p>Approvers are assigned per workflow to individual channels within a single group in the workflow’s approval chain and are responsible for:</p><li>Adding their own amend requests</li><li>Signing off completed amend requests</li><li>Approving individual creative slots as per the configured workflow approval sequence.</li><p>Approvers must be assigned to an Approval Group and granted access to specific channels</p>'},
		'wf-admins':{title: 'Project Admin role', body: '<p>This role manages the project and allows the user to:</p><li>add/remove workflows, formats, channels, and creative slots via the project grid</li><li>activate workflow channels</li><li>add/remove users and assign their roles and access via the project workflow panel</li><li>configure the approval sequence for each workflow</li>'},
		'wf-amendmanagers':{title: 'Amend Manager role', body: '<p>Amend Managers are assigned per workflow and are responsible for:</p><li>Accepting/declining amend requests from Approvers</li><li>Adding any additional amend requests of their own</li><li>Signing off completed amends before they are sent back to Approvers</li>'},
		'wf-production':{title: 'Production role', body: '<p>Production users are assigned per workflow to individual channels and are responsible for:</p><li>Uploading content to creative slots</li><li>completing amend requests</li>'},
		'wf-channels': {title: 'Channels', body: '<p>Channels form the vertical columns on the project grid and allow you to organise your formats across the project. Approver and Production users must be given permission to access individual channels when they are assigned to a Workflow in the admin panel.</p><p>Channels must be \'activated\' in each worklfow to be able to add creative slots and grant user access to it. To activate/de-activate a workfow channel, click the required channel button and select the relevant option from the dropdown menu in the workflow admin panel. Alternatively, you can click the channel\'s \'activate\' button in the header bar of the relevant workflow on the project grid view. </p>'},
		'wf-projectteam': {title: 'Project Team', body: '<p>The Project Team section shows the current list of users who have been granted access to the project and are available for any of the project or worklflow roles within it.</p><p>Add users to the project by clicking on the <i>Assigned people</i> label.</p><p><b>Note: granting access to the project alone wont allow users to see or do anything wothin the project, they must also be given a role.</b></p><p>If a user isnt in the add dropdown list when you try to assign them to the project, you\'ll need to go back up a level and grant the user access to the Workgroup first.</p>'},
		'wf-approvalgroups': {title: 'Approval Groups', body: '<p>Oceanview utilises a user-defined sequence of approval groups to keep track of who is required to do what next, for every asset within that workflow.</p><p>These approval chains are configured per workflow.</p><p>Each stage in a chain is defined by an ‘Approval Group’.</p><p>One or many Approvers must be assigned to each group as needed for that stage.</p><p>Each group’s Approval mode can be set individually to either  ‘Everybody’ or ‘Anybody’. The mode determines whether the whole group or just one approver in it must submit their approval before the asset is sent to the next group (or stage) in the chain.</p>'},
		
	}
	
	public getTooltip(key:any){
		if(typeof this.tooltips[key] !== 'undefined'){
			return this.tooltips[key];
		}
		return '';
	}	
	public getContent(key:any){
		if(typeof this.content[key] !== 'undefined'){
			return this.content[key];
		}
		return '';
	}
}
