// https://stackoverflow.com/questions/23088900/how-do-i-convert-global-coordinates-to-local
// https://stackoverflow.com/questions/25630035/javascript-getboundingclientrect-changes-while-scrolling
export function getRect(element:HTMLElement):DOMRect {
    let rect = element.getBoundingClientRect();
    let left = window.pageXOffset || document.documentElement.scrollLeft;
	let top = window.pageYOffset || document.documentElement.scrollTop;
    rect.x += left;
    rect.y += top;
    do {
        //rect.x += element.scrollLeft || 0;
        //rect.y += element.scrollTop || 0;
    }
    while (element = element.parentNode as HTMLElement);
    return rect;
}