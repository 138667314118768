export interface IChannel {
	id: number;
	uuid: string;
	workflow_uuid: string;
	project_channel_uuid: string;
	created_at: string;
	updated_at: string;
	name: string;
	order: number;
}
export class Channel implements IChannel{
	id: number;
	uuid: string;
	workflow_uuid: string;
	project_channel_uuid: string;
	created_at: string;
	updated_at: string;
	name: string;
	order: number;

	static getChannelIds(channels:IChannel[]):string[]
	{
	  return channels.map(channel => channel.uuid);
	}
}