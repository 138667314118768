import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';
//import * as THREE from 'three';
import * as THREE from "three";
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { AssetVO } from 'src/app/models/asset.model';

// https://threejs.org/docs/#examples/en/loaders/GLTFLoader
// https://threejs.org/examples/#webgl_loader_gltf
// https://github.com/mrdoob/three.js/blob/master/examples/webgl_loader_gltf.html
// https://github.com/JohnnyDevNull/ng-three-template/blob/master/src/app/engine/engine.service.ts
@Component({
  selector: 'app-threed',
  templateUrl: './threed.component.html',
  styleUrls: ['./threed.component.scss']
})
export class ThreedComponent extends BaseAssetComponent implements OnInit, OnChanges {
  @ViewChild('rendererCanvas') 
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  /*
  private renderer: THREE.WebGLRenderer;
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private light: THREE.AmbientLight;
*/
private loaderGLTF = new GLTFLoader();
private renderer: THREE.WebGLRenderer;

private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;

  private controls: OrbitControls;

  @Input() public fieldOfView: number = 1;
  @Input('nearClipping') public nearClippingPane: number = 1;
  @Input('farClipping') public farClippingPane: number = 1000;

  private model: any;
  constructor() { super() }

  ngOnInit(): void {

    
  }
  ngOnChanges(changes?: SimpleChanges): void {
    //console.log("thre3 changes", changes);
    if(changes?.assetVO)
    {
      let assetVO:AssetVO = changes.assetVO.currentValue;
      //console.log("asset change found", assetVO.file);
      
      if(assetVO.file)
      {
        let reader = new FileReader();

        reader.onload = () =>  {
            //console.log("loading file");
          
            this.loaderGLTF.parse( reader.result, '', ( gltf ) => {

            this.scene.add( gltf.scene );
        
        } );
        }

        reader.readAsArrayBuffer(assetVO.file);

      }
    }
  }

  private get canvas(): HTMLCanvasElement {
    return this.rendererCanvas.nativeElement;
  }
  private getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }
  ngAfterViewInit(): void {
    //console.log("ngAfterViewInit", this.rendererCanvas);
    // setup
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xd4d4d8)
    
    let aspectRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPane,
      this.farClippingPane
    )

    this.startRenderingLoop();
  }
  private startRenderingLoop() {
    //* Renderer
    // Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    let component: ThreedComponent = this;
    (function render() {
      component.renderer.render(component.scene, component.camera);
      //component.animateModel();
      requestAnimationFrame(render);
    }());
  }
}
