import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CrudService } from './crud.service';

// https://angular.io/guide/http#typed-response

/**
 * A class to handle syncing with a database
 */
class Model{
	protected name:string;
	protected fields:string[];
	protected _source:Model;
	constructor(name:string)
	{
		this.name = name;
	}
	setSource(source:Model)
	{
		this._source = source;
	}
	toData():Object
	{
		let data = {};
		if(this.fields && this.fields.length)
		{
			for(let i = 0; i < this.fields.length; i++)
			{
				let field = this.fields[i];
				data[field] = this[field];
			}
		}else{
			// just return this?
			for (let field in this) {
				if (this.hasOwnProperty(field))
				{
					data[field as string] = this[field];
				}
			}	
		}
		return data;
	}
	appendToForm(formData:FormData, index:number = -1)
	{
		// DO we need this?
		if(index != -1)
		{
			let key = this.name
		}else{
			let key = this.name + "s";
			if(this.fields && this.fields.length)
			{
				for(let i = 0; i < this.fields.length; i++)
				{
					let field = this.fields[i];
					formData.append(`${key}[${index}][${field}]`, this[field]);
				}
			}
		}
	}
	// is the model dirty
	isDirty(field:string = null):boolean
	{
		if(!this._source) return true;

		if(field)
		{
			if(this._source[field] != this[field])	return false;
		}else{
			for(let i = 0; i < this.fields.length; i++)
			{
				field = this.fields[i];
				if(this._source[field] != this[field])	return false;
			}	
		}	
		return true;
	}
	save(service:AmendsService):Observable<IAmend[]>
	{
		//console.log("saving...");
		//return service.http.post<IAmend[]>(API_URL+'api/amend/store/', this.toData());
		return null;
	}
}

/**
 * Model collection
 * a
 */
class ModelCollection {
	models: Model[];


}

export class User extends Model{
	fields = ["name", "email"];
	constructor(public name:string, public email:string)
	{
		super("user");
	}
	clone():User
	{
		return new User(this.name, this.email);
	}
}

export class Amend extends Model implements IAmend {
	id: number;
	user_id: number;
	open: boolean;
	state: string;
	request: string;
	creative_id: string;
	project_id: string;
	uuid: string;
	created_by: string;
	updated_at: Date;
	created_at: Date;
	version: number;
	events: IAmendEvent[]

	fields = ["id", "request"];
	user: any;

	// temp - TODO could prob combine all these into one bad-boy
	completed: boolean;
	completedMessage: string;
	declined: boolean;
	declinedMessage: string;
	accepted: boolean;
	acceptedMessage: string;
	rejected: boolean;
	rejectedMessage: string;

	constructor()
	{
		super("amend");
	}
	/* crude merge of b into a */
	static merge(a:Amend, b:Amend){
		for (const key in b) {
			if (Object.prototype.hasOwnProperty.call(b, key)) {
				const element = b[key];
				if(element != null && element != undefined){
					a[key] = element;
				}
			}
		}
	}
}
export interface IAmend {
	id: number,
	user_id: number,
	request: string,
	events: IAmendEvent[]
	updated_at: Date;
	created_at: Date;
	//date() : string
}
export interface IAmendEvent
{
	user_id: number,
	type: string,
	time: string,
	message: string
}

export enum AmendState{
	/*
	REQUESTED				= 1 << 0,
	REQUEST_ACCEPTED		= 1 << 1,
	REQUEST_REJECTED		= 1 << 2,
	REJECTED				= 1 << 3,
	REJECTION_ACKNOWLEDGED	= 1 << 4,
	*/
	/*
		accept/decline - requests
		confirm/reject - done
	*/
	REQUESTED				= "requested",
	NEW						= "new",
	TODO					= "todo",
	DONE					= "done",
	DONE_CONFIRMED 			= "done_confirmed",
	DECLINED 				= "declined",
	DECLINED_PRODUCTION 	= "declined_production",
	DECLINE_ACKNOWLEDGED 	= "decline_acknowledged",
	REJECTED 				= "rejected",
	REJECTION_ACKNOWLEDGED 	= "rejection_acknowledged",
	RECALLED 				= "recalled",
}

export interface APIResponse
{
	api: string;
	status: number;
	message: string;
	data: any;
}



@Injectable({
	providedIn: 'root'
})
export class AmendsService extends CrudService<Amend, string> {
	constructor(protected http: HttpClient) {
		super(http, 'amend');
	}
	complete(amend:Amend)
	{
		return this._post(`${this.base}/${amend.uuid}/complete/${amend.completedMessage}`);
	}
	confirm(amend:Amend)
	{
		return this._post(`${this.base}/${amend.uuid}/confirm`);
	}
	reject(amend:Amend)
	{
		return this._post(`${this.base}/${amend.uuid}/reject/${amend.rejectedMessage}`);
	}
	accept(amend:Amend)
	{
		return this._post(`${this.base}/${amend.uuid}/accept/${amend.acceptedMessage}`);
	}
	decline(amend:Amend)
	{
		return this._post(`${this.base}/${amend.uuid}/decline/${amend.declinedMessage}`);
	}
	declineAcknowledged(amend:Amend)
	{
		return this._post(`${this.base}/${amend.uuid}/declineAcknowledged`);
	}
}
