import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-breadcrumbs-view',
	templateUrl: './breadcrumbs-view.component.html',
	styleUrls: ['./breadcrumbs-view.component.scss']
})
export class BreadcrumbsViewComponent implements OnInit {


	crumbs:any;

	constructor() { }

	ngOnInit() {
		this.crumbs = [
		{
			'label': 'Activision',
			'url': ''
		},
		{
			'label': 'Europe', 
			'url': ''
		},
		{
			'label': 'Call of Duty', 
			'url': ''
		},
		{
			'label': 'Beta',
			'url': ''
		}
		];
		
	}

}
