export interface IApprovalGroup {
	id: number;
	uuid: string;
	chain_id: number;
	workflow_id: number;
	name: string;
	order: number;
	mode: string;
	use_moderator: boolean;
	users: any[];
}
export class ApprovalGroup implements IApprovalGroup {
	id: number;
	uuid: string;
	chain_id: number;
	workflow_id: number;
	name: string;
	order: number;
	mode: string;
	use_moderator: boolean;
	users: any[];
}
