import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Globals } from 'src/app/global';

declare var jquery:any;
declare var $ :any;
declare var window :any;

let API_URL = Globals.BASE_API_URL;

@Component({
	selector: 'app-asset-view',
	templateUrl: './asset-view.component.html',
	styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	uuid:any;
	asset_data_string:any;
	asset_data:any;
	
	image_src:any = false;
	image_width:any = false;
	image_height:any = false;
	

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService) { }
	
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		this.subscription = this.route.params.subscribe(params => {
			if(params['uuid']){
				this.uuid = params['uuid'];
				this.api.getAssetMeta(this.uuid);
			}
		});
		this.api.getassetmeta.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//console.log('getassetmeta', res.data);
			this.image_width = res.data.asset.width;
			this.image_height = res.data.asset.height;
			this.image_src = API_URL+'api/asset/'+this.uuid+'/image';
			this.asset_data = res.data;
			this.asset_data_string = JSON.stringify(this.asset_data, null, 2);
		});
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}