import { Component, OnDestroy, OnInit } from '@angular/core';
import { empty, EMPTY, Subject, Subscription, Observable } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';

//https://levelup.gitconnected.com/auto-unsubscribing-in-angular-components-like-a-pro-742220b01d0c
@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.scss']
})
export class BaseComponent implements OnDestroy {
  protected destroyed = new Subject<boolean>();
  protected _subs = new Subscription();
  protected _tempSubs = new Subscription();


  public set sub(subscription:Subscription)
  {
    this._subs.add(subscription);
  }
  public set tempSub(subscription:Subscription)
  {
    this._tempSubs.add(subscription);
  }
  ngOnDestroy(): void {   
    this.destroyed.next(true);
    this.destroyed.complete();
    this.destroyed.unsubscribe();
    this._subs.unsubscribe();
    this._tempSubs.unsubscribe();
  }

  unsub()
  {
    this._tempSubs.unsubscribe(); 
    this._tempSubs = new Subscription();
  }
  apiCall(call : Observable<any>, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void, final?: () => void){
		this.sub = call.pipe(finalize(final)).subscribe(next,error, complete);
	}
  apiCallOnce(call : Observable<any>, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void, final?: () => void){
		return call.pipe(
      take(1),
      takeUntil(this.destroyed),
      finalize(final)
    )
    .subscribe(next,error, complete);
	}
}
