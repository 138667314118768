import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { StringsService } from './../../services/strings.service';
import { Subscription, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-add-container-dialog',
	templateUrl: './add-container-dialog.component.html',
	styleUrls: ['./add-container-dialog.component.scss']
})
export class AddContainerDialogComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	title:any = false;
	save_label:any = 'Add';
	save_data:any = {};
	show_locked_check:any = false;
	is_locked:any = 0;

	constructor(public dialogRef: MatDialogRef<AddContainerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api:ApiService, private router:Router, private strings:StringsService) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		
		switch(this.data.container_type){
			case 1:
				this.title = this.strings.getHeading('add_account');
				break;
			case 2:
				this.title = this.strings.getHeading('add_workgroup');
				break;
			case 3:
				this.title = this.strings.getHeading('add_folder');
				break;
			case 4:
				this.title = this.strings.getHeading('add_project');
				this.show_locked_check = true;
				break;
		}
		
		if(typeof this.data.title !== 'undefined'){
			this.title = this.data.title;
		}
		
		if(typeof this.data.save_label !== 'undefined'){
			this.save_label = this.data.save_label;
		}
		
		this.save_data['container_type'] = this.data.container_type;
		this.save_data['account_id'] = (typeof this.data.account_id !== 'undefined') ? this.data.account_id : false;
		this.save_data['workgroup_id'] = (typeof this.data.workgroup_id !== 'undefined') ? this.data.workgroup_id : false;
		this.save_data['parent_id'] = (typeof this.data.parent_id !== 'undefined') ? this.data.parent_id : false;
		this.save_data['owner_id'] = (typeof this.data.owner_id !== 'undefined') ? this.data.owner_id : false;
		
	}
	
	updateIsLocked(event:any){
		this.is_locked = (event.checked) ? 1 : 0;
	}
	
	saveAction(name:any, is_locked:any = false){
		this.save_data['name'] = name;
		if(this.save_data.container_type == 4){
			this.save_data['is_locked'] = this.is_locked;
		}
		if(typeof this.data.saveAction !== 'undefined'){
			this.data.saveAction(this.save_data);
			this.dialogRef.close();
		}else{
			this.dialogRef.close();
		}
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}