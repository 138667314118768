import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
//import { Amend } from './amends.service';
//import { CrudService } from './crud.service';
import { ApiResponse, OVBaseService } from './OvBaseService';
import { map } from 'rxjs/operators';
import { Asset, IAsset} from '../models/asset.model';
import { NonNullAssert } from '@angular/compiler';
import { wrapper } from '../utils/wrapper';
import { Globals } from '../global';

let API_URL = Globals.BASE_API_URL;

@Injectable({
	providedIn: 'root'
})
export class AssetService extends OVBaseService<Asset, string> {
	constructor(protected http: HttpClient) {
		super(http, 'assets2');
	}
	getAssets(creative_uuid:string): Observable<ApiResponse<Asset>>
	{
		return this._get(`${this.base}/${creative_uuid}`).pipe(
			map((response) => {
				let assets:IAsset[] = response["data"];
				assets.forEach(asset => {
					Asset.cacheBust(asset);
				})				
				return response; 
			})
		);
	}

	private saving = {};
	private _savingSubject = new BehaviorSubject<any>(this.saving);
	public saving$ = this._savingSubject.asObservable();
	save(creative_uuid:string, assets: Asset[]): Observable<ApiResponse<Asset>>
	{
		return this._post(`${this.base}/${creative_uuid}`, {assets}).pipe(
		wrapper(() => {
			// start
			if(!this.saving[creative_uuid])
			{
				this.saving[creative_uuid] = 1;
			}else{
				this.saving[creative_uuid]++;
			}
			this._savingSubject.next({...this.saving});
		}, null,
		() => {
			// complete
			if(this.saving[creative_uuid] !== undefined)
			{
				this.saving[creative_uuid]--;
				if(this.saving[creative_uuid] <= 0)
				{
					delete this.saving[creative_uuid];
				}
			}
			this._savingSubject.next({...this.saving});
		},() => {
			// error
			if(this.saving[creative_uuid] !== undefined)
			{
				this.saving[creative_uuid]--;
				if(this.saving[creative_uuid] <= 0)
				{
					delete this.saving[creative_uuid];
				}
			}
			this._savingSubject.next({...this.saving});
		}));
		
		let formData = new FormData();
		for (let i = 0; i < assets.length; i++) {
			const asset:Asset = assets[i];
			for (var prop in asset) {
                if (asset.hasOwnProperty(prop))
                {
					if(prop == 'file') continue;	// defer file uploads to later call!
					if(prop == "reference") continue;
					let value = asset[prop];
					if(prop == "uri")
					{
						value = value.split("?")[0];	// trim any querystrings...
					}
					//console.log("appending", `assets[${i}][${prop}] :: ${value}`);
					if(prop === 'metadata' && typeof value != "string") value = JSON.stringify(value);
					if(prop === 'fileMetadata' && typeof value != "string") value = JSON.stringify(value);
					if(value === null) value = '';
                    formData.append(`assets[${i}][${prop}]`, value);
                    /*
					if(prop === "file")
                    {
                        formData.append(`assets[${i}][filename]`, asset.file.name);
                        formData.append(`assets[${i}][filetype]`, asset.file.type);
						formData.append('file', asset[prop], asset[prop].name);
                    }*/
                }
            }
		}
		//@ts-ignore
		return this._post(`${this.base}/${creative_uuid}`, formData);
		//return this._post<FormData, ApiResponse<Asset>>(`${this.base}/${creative_uuid}`, formData);
	}
	complete(uri:string, region:string, metadata:any)
	{
		return this._post(API_URL + `worker/uploadComplete/${uri}?region=${region}`, metadata);
	}
	getUserPermissions(project_id: string, user_id:string) {
		return this._get(`${this.base}/${project_id}/userPermissions/${user_id}`);
	}
	grantUserPermission(project_id : string, user_id:string, permission : string) {
		return this._post(`${this.base}/${project_id}/grantUserPermission/${user_id}/${permission}`);
	}
	removeUserPermission(project_id : string, user_id:string, permission : string) {
		return this._post(`${this.base}/${project_id}/revokeUserPermission/${user_id}/${permission}`);
	}
	getUsers(project_id: string):Observable<any>{
		return this._get(`${this.base}/${project_id}/users`);
	}
	renameAssetFile(asset:IAsset, renamedFile:string)
	{
		let asset_uuid = asset.uuid;
		return this._post(`${this.base}/${asset_uuid}/renameFile`, {renamedFile});
	}
}
