<div class="container">
	<div class="top">
		<div class="title">
			<mat-icon class="red">warning</mat-icon><span *ngIf="title">{{title}}</span>
		</div>
		<div class="summary">Fix the following errors and re-save:</div>
		<mat-divider></mat-divider>
	</div>
	<div class="content">
		<!-- packageErrors[_package.uuid] = {name:_package.name, issues:issuesArr}; -->
		<div class="section instruction" *ngIf="numMissingNames">{{numMissingNames}} package{{numMissingNames>1 ? 's':''}} {{numMissingNames==1 ? 'has a':'have'}} missing name{{numMissingNames>1 ? 's':''}}.</div>
		<div *ngIf="packageErrors">
			<div class="section" *ngFor="let package of packageErrors | keyvalue">
				<div class="header">Package: <span>{{package.value.name || "(missing name)"}}</span></div>
				<div class="subsection instruction" *ngFor="let issue of package.value.issues">{{issue}}</div>
			</div>
		</div>	
	</div>
	<div class="buttonRow">
		<button mat-raised-button color="primary" (click)="confirmAction()">{{confirm_button_label}}</button>
	</div>
	
</div>