export class FileUtils {
    
    //https://stackoverflow.com/questions/423376/how-to-get-the-file-name-from-a-full-path-using-javascript
    static baseName(path:string):string {
      let name = path ? path.replace(/^.*[\\\/]/, '') : null;
      if(name) name = name.split('?')[0]; // remove any querystring
        return name;
    }
    static blobToFile(blob: Blob, fileName:string):File
    {
        return new File(
			[blob as any], // cast as any
			fileName, 
			{
				lastModified: new Date().getTime(),	//(new Date()).toISOString();
				type: blob.type 
			}
		)
    }
}
 // not checked the math here (https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c)
export function formatBytes(bytes:number, decimals:number = 2):string {
  if(bytes == 0) return '0 Bytes';
  let k = 1024;
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}
export function formatBitrate(bytes:number, decimals:number = 2):string
{
  if(bytes == 0) return '0 kbps';
  let k = 1024;
  let sizes = ['bps', 'kbps', 'mbps'];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}
export function formatDuration(totalSeconds:number):string
{
  totalSeconds = Math.round(totalSeconds);
  const hh = pad(Math.floor(totalSeconds / 3600).toString());
  totalSeconds %= 3600;
  const mm = pad(Math.floor(totalSeconds / 60).toString());
  const ss = pad((totalSeconds % 60).toString());
  return hh != '00' ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
}
function pad(string:string, length:number = 2, char:string = '0')
{
  while(string.length < length) string = char + string;
  return string;
}
