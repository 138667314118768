import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


export interface CanComponentDeactivate {
  canDeactivate: (currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) => Observable<boolean> | Promise<boolean> | boolean;
}

/*
https://angular.io/guide/router-tutorial-toh#candeactivate-handling-unsaved-changes
https://github.com/angular/angular/issues/28730

*/

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //console.log("CAN DEACTIVE GUARD");
      return component.canDeactivate ? component.canDeactivate(currentState, nextState) : true;
  }
}
