<h2>Degbug info for websocket connections</h2>
<button  (click)="redisCheck()" mat-flat-button color="primary">Redis Check</button>
<button  (click)="redisPurge()" mat-flat-button color="primary">Redis Purge</button>


<button  (click)="loadActiveUsers()" mat-flat-button color="primary">Reload active users</button>
<fieldset>
    <legend>active users...</legend>
        <div *ngFor="let user of activeUsers">
            <div>
                <span>{{user.name}}</span>
                <button mat-stroked-button color="primary" (click)="getUserStatus(user)" matTooltip="Ping user for status">status?</button>
            </div>
            <div class="userinfo" *ngIf="user['status'] && user['status'] == 'requested'">
                <span>User info requested</span>
            </div>
            <div class="userinfo" *ngIf="user['status'] && user['status'] != 'requested'">
                <div class="status" *ngFor="let status of user['status']">
                    <span *ngIf="status['session']">Session: {{status['session']}}</span>
                    <span *ngIf="status['api-version']">API version: {{status['api-version']}}</span>
                    <span *ngIf="status['route']">Route: {{status['route']}}</span>
                    <div>
                        <span *ngIf="status['browser']">Browser: {{status['browser']}}</span><span *ngIf="status['version']">&nbsp;{{status['version']}}</span>
                    </div>
                    <span *ngIf="status['buildDate']">Build date: {{status['buildDate']}} {{(status['buildDate'] < buildDate) ? "(out of date)" : "(in date)"}}</span>
                    <div>
                        <button mat-stroked-button color="accent" (click)="userReload(user, false, status['session'])" matTooltip="Request page reload for user">reload (request)</button>
                        <button mat-stroked-button color="warn" (click)="userReload(user, true, status['session'])" matTooltip="Force page reload for user">reload (force)</button>
                    </div>
                </div>

            </div>
        </div>
</fieldset>
