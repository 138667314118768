import { ViewportRuler } from '@angular/cdk/scrolling';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseAssetComponent } from '../baseAsset.component';
import { Observable, fromEvent } from 'rxjs';

@Component({
  selector: 'app-html-banner',
  templateUrl: './html-banner.component.html',
  styleUrls: ['./html-banner.component.scss']
})
export class HtmlBannerComponent extends BaseAssetComponent {

  @ViewChild('capture') capture:ElementRef<HTMLDivElement>;
  //@ViewChild('iframe') iframe:ElementRef<HTMLIFrameElement>;
  @ViewChildren('iframe') private iframes: QueryList<ElementRef<HTMLIFrameElement>>;
  private _iframe:ElementRef<HTMLIFrameElement>;
  loaded: boolean = false;
  private set iframe(value:ElementRef<HTMLIFrameElement>)
  {
    if(this._iframe){
      // TODO cleanup of any existing listeners if old one is present
    }
    this._iframe = value;
    let doc = this._iframe.nativeElement.contentWindow.document;
    
    //this.sub = fromEvent(this._iframe.nativeElement, "error").subscribe(e => {
    //  console.warn("html iframe error", e);
    //});

    // TODO - research if we always need this listener
    this._iframe.nativeElement.addEventListener("load", (e)=>
      {
        this.loaded = true;
        //console.log("loaded", e);
        return;
        let doc = this._iframe.nativeElement.contentWindow.document;
        //console.log("DOC", doc);
        doc.addEventListener('mousedown', (e) => {
        //console.log("iframe click", e);
        //console.log("iframe click", e.button);
        // if(e.button == 1)
        },true);
        // https://stackoverflow.com/questions/7914684/trigger-right-click-using-pure-javascript
        doc.addEventListener('contextmenu', (e) => {
          //console.log("contextmenu aA", e);
          e.stopPropagation();
          e.preventDefault();
          e.cancelBubble = true;
          let element = this.elementRef.nativeElement;
          let bounds = element.getBoundingClientRect();
          var ev3 = new MouseEvent("contextmenu", {
            bubbles: true,
            cancelable: true,
            view: window,
            button: 2,
            buttons: 0,
            clientX: bounds.x + e.x,
            clientY: bounds.y + e.y
        });
        //console.log("dispatching3");
        element.dispatchEvent(ev3);
          /*
          if (window.CustomEvent) {
              element.dispatchEvent(new CustomEvent('contextmenu'));
          } else if (document.createEvent) {
              var ev = document.createEvent('HTMLEvents');
              ev.initEvent('contextmenu', true, false);
              element.dispatchEvent(ev);
          } else { // Internet Explorer
              // @ts-ignore
              element.fireEvent('oncontextmenu');
          }*/
          
          //e.preventDefault();
        // if(e.button == 1)
        },true);
        doc.addEventListener('contextmenu', (e) => {
          e.stopPropagation();
          e.preventDefault();
          //console.log("contextmenu B", e);
        // if(e.button == 1)
        },false);
      });
  }

  constructor(
    private readonly elementRef: ElementRef
    ,private readonly viewportRuler: ViewportRuler
    ) { 
		super();
		this.defaultWidth = 300;
		this.defaultHeight = 250;
    this.sub = this.viewportRuler.change(100).subscribe((e) =>
    {
      this.onResize(e);
    })
/*
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((e) => console.log('Window resizing', e));*/

    


	}
  getProcessingFlags()
  {
    // uploading to r2, unzipping
    return (1 << 3) | (1 << 4);
  }
  ngOnInit(): void {
    //@ts-ignore
    //document.domain = window.location.hostname;
    
    this.sub = fromEvent(window, 'message').subscribe((e:MessageEvent) => {
        const { hostname } = new URL(e.origin);
        if(hostname.indexOf(window.location.hostname) == -1) return;
        if(e.data.message === "404")
        {
          // we got a 404 - let em know!
          console.warn("banner 404d", this.getPath())
          return;
          let uri = this.assetVO.asset.uri;
          //this.assetVO.asset.uri = null;
          // then it will trigger a reload when set again
          this.assetVO.asset.flag = (1 << 4)
          setTimeout(() => {
            //this.assetVO.asset.uri = uri.split("xx").join('x');
            this.assetVO.asset.flag = 0;
          }, 1000);
          // trigger a attempt to restore zip content from gcs...
          return;
        }
        if(e.data.uuid && (e.data.uuid == this.assetVO.asset.uuid))
        {
          let element = this.elementRef.nativeElement;
          let bounds = element.getBoundingClientRect();
          var ev3 = new MouseEvent("contextmenu", {
            bubbles: true,
            cancelable: true,
            view: window,
            button: 2,
            buttons: 0,
            clientX: bounds.x + e.data.contextmenu.x,
            clientY: bounds.y + e.data.contextmenu.y
        });
          element.dispatchEvent(ev3);
        }      
    });
  }
  ngAfterViewInit()
  {
    if(this.iframes.first) this.iframe = this.iframes.first;
    this.sub = this.iframes.changes.subscribe((r) => {
      this.iframe = this.iframes.first;
    });
     return;
    //this.onResize();
    //https://stackoverflow.com/questions/6452502/adding-click-event-handler-to-iframe
    console.log("html init", this.iframes.first)
    this.iframes.changes.subscribe((r) => {
			
		});
    

    /*
    console.log("BANNER FOUND", this.capture);
    this.capture.nativeElement.addEventListener('mousedown', (e) => {
      console.log("capture click", e);
      console.log("capture click", e.button);
     // if(e.button == 1)
    },{capture:true});
    this.capture.nativeElement.addEventListener('contextmenu', (e) => {
      console.log("contextmenu", e);
     // if(e.button == 1)
    },{capture:true});*/
  }
 // @HostListener('window:resize', ['$event'])
  onResize(event = null) {
    return;
    if(this.assetVO && this.galleryMode)
    {
      //let rect = this.elRef.nativeElement.getBoundingClientRect();
      
      let rect =  this.elementRef.nativeElement.parentElement.parentElement.parentElement.getBoundingClientRect();
      let scaleX = Math.min(rect.width / this.assetVO.asset.width, 1);
      let scaleY = Math.min(rect.height / this.assetVO.asset.height, 1);
      this.scale = Math.min(scaleX, scaleY);
      this.layoutChange.emit();
    }else if(this.scale != 1)
    {
      this.scale = 1;
      this.layoutChange.emit();
    }
  }
  /*
  getMarkerRenderInfo(marker:IMarker)
	{
    // abusing the offset feature of the marker
    if(this.galleryMode)
    {
      let scaledX = marker.x0 * this.scale;
      let scaledY = marker.y0 * this.scale;
      marker.offsetX = (scaledX - marker.x0);
      marker.offsetY = (scaledY - marker.y0);    
      //marker.x0 = marker.y0 = 0;
    }else{
      marker.offsetX = 0;
      marker.offsetY = 0;
    }
	return marker;
	}*/
  getPath():string
  {
    let path = super.getPath(true);
    if(!path) return null;
    if(path.indexOf(".zip"))
    {
        
      let pathArr = path.split("?");
      //path = path.split("?")[0];  // remove any querystring

        path = pathArr[0] + ".dir/index.html" + "?" +  pathArr[1];
    }
    return path;
  }
}
