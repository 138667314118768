import { Component, ElementRef, Inject, OnInit, Pipe, PipeTransform, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


export interface GenericDialogData {
	title: string;
  titleIcon: string;
	subtitle: string;
	body: string;
  positive: string;
  negative: string;
  form: any[];
  model:any;
  formGroup: FormGroup;
  ignoreEnterSubmit: boolean;
  paginate: string;
  
}
@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {


  //@ViewChild("form") form: Form;
  public formGroup:FormGroup;
  public ignoreEnterSubmit:boolean = false;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('form') form: NgForm;  // https://gist.github.com/corneil/4a75adec82b157a6bce3e9f5dff31c7b
  @ViewChildren('element') elements: QueryList<ElementRef<HTMLElement>>;

  constructor(public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogData) { }
  ngOnInit(): void {
    if(this.data.form)
    {
      this.formGroup = new FormGroup({});
      this.data.formGroup = this.formGroup;

	  this.ignoreEnterSubmit = this.data.ignoreEnterSubmit || false;

      for (let i = 0; i < this.data.form.length; i++) {
        const element = this.data.form[i];
        //let control =  new FormControl(element.value !== undefined ? element.value : this.data.model[element.field], element.validator);
        let control = new FormControl(this.getValue(element), element.validator);
        element.control = control;
        this.formGroup.addControl(element.name, control);
        if(element.autoComplete)
        {
          element.autoCompleteObservable = this.getObservable(element.autoComplete, element.control);
        }
        
      }
    }
  }
  triggerSubmit() {
	if(!this.ignoreEnterSubmit){
		if (!this.form) {
			console.warn('form not assigned a value');
		} else {
			if (this.form.valid) {
				this.form.ngSubmit.emit();
			}
		}
	}
  }
  ngAfterViewInit()
  {
    if(!this.data.form) return;
    let focusIndex = this.data.form.findIndex(el => el.focus);
      if(focusIndex != -1)
      {
        this.elements.forEach((item, index) => {
          if(index == focusIndex){
            setTimeout(() => {
              item.nativeElement.querySelector('input').focus();
            }, 16)
          }
        })
      }
    this.elements.changes.pipe().subscribe(res => {
        
    });
  }
  isValid():boolean
  {
    if(this.formGroup) return this.formGroup.valid;
    else return true;
  }
  onSubmit()
  {   
    if(this.isValid())
    {
      this.dialogRef.close(this.data);
    }
  }
	
/*
	permissionsInit()
	{
		this.filteredPermissions = this.permissionCtrl.valueChanges.pipe(
			startWith(null),
			map((permission: string | null) => permission ? this._filter(permission) : this.allPermissions.slice()));
	}*/
  getObservable(autoComplete:string[], ctrl:FormControl)
  {
    //console.log("get obs");
    let filteredAutoComplete:Observable<string[]> = ctrl.valueChanges.pipe(
      startWith(null),
			map((value: string | null) => value ? this._filter(value, autoComplete) : autoComplete.slice())
    );
    //console.log("getObservable", filteredAutoComplete);
    return filteredAutoComplete;
  }
  private _filter(value: string, values:string[]): string[] {
		const filterValue = value.toLowerCase();
   // console.log("filter", value);
		return values.filter(val => val.toLowerCase().indexOf(filterValue) === 0);
	}
  getValue(entry)
  {
    if(entry.value !== undefined)
    {
      return entry.value;
    }else if(entry.field){//Adam, added this as entry doesnt always contain .field (e.g. 'copy project' dialog items)
      if(entry.field.indexOf('.') !== -1)
      {
        let parts = entry.field.split('.');
        return this.data.model[parts[0]][parts[1]];
      }else{
        return this.data.model[entry.field];
      }
    }
	return null;
    //return entry.value !== undefined ? entry.value : this.data.model[entry.field];
  }
  /*
  getControls()
  {
    let controls = [];
    Object.keys(this.data.formGroup.controls).forEach(key => {
      console.log("Key", key);
      let thing = this.data.formGroup.get(key);
      console.log(thing);
      controls.push(this.data.formGroup.get(key));
    });
    return controls;
  }*/

}
