import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from 'src/app/models/project.model';
import { IUser, User } from 'src/app/models/user.model';

export interface NoRemoveAdminUserData {
	user: User,
	project: Project
}

@Component({
  selector: 'app-no-remove-projectadmin',
  templateUrl: './no-remove-projectadmin.component.html',
  styleUrls: ['./no-remove-projectadmin.component.scss']
})
export class NoRemoveProjectadminComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: NoRemoveAdminUserData,
		public dialogRef: MatDialogRef<NoRemoveProjectadminComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
}

