import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { MatDialogModule } from '@angular/material';
// import { MatInputModule } from '@angular/material/input';
import { ApiService } from './../../api/api.service';
import { DialogService } from './../../services/dialog.service';
import { StringsService } from './../../services/strings.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	users:any = [];
	
	account_id:any = false;
	workgroup_id:any = false;
	
	user_account_id:any = false;
	user_workgroup_id:any = false;
	
	accounts:any;
	workgroups:any;
	user_accounts:any;
	user_workgroups:any;

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, private ds:DialogService, private strings:StringsService) { }
	
	addUser(){
		
		$('body').addClass('saving').addClass('loading');

		let _name:any = $('#name').val();
		let _email:any = $('#email').val();
		let gen_pass:any = Md5.hashStr(_email+Math.round((new Date()).getTime() / 1000));
		
		let _data:any = {
			name: _name, 
			email: _email,
			password: gen_pass,
			password_confirmation: gen_pass,
			account_id: this.account_id,
			workgroup_id: this.workgroup_id,
		};
		
		this.api.signUp(_data);
	}
	
	setAccountId(value:any){
		this.account_id = value;
		this.workgroup_id = false;
		this.workgroups = [];
		this.api.getworkgroupsforaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.workgroups = res.data;
		})
		this.api.getWorkgroupsForAccount({account_id:this.account_id}, this);
	}
	
	setWorkgroupId(value:any){
		this.workgroup_id = value;
	}
	
	setUserAccountId(value:any){
		this.user_account_id = value;
		this.user_workgroup_id = false;
		this.user_workgroups = [];
		this.api.getusersforaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.users = res.data;
		})
		this.api.getUsersForAccount({account_id:this.user_account_id}, this);
		
		this.api.getworkgroupsforaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.user_workgroups = res.data;
		})
		this.api.getWorkgroupsForAccount({account_id:this.user_account_id}, this);
		
	}
	
	setUserWorkgroupId(value:any){
		this.user_workgroup_id = value;
		this.api.getusersforworkgroup.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			if(typeof res['caller'] == 'undefined' || res['caller'] !== this) return;
			this.users = res.data.users;
		})
		this.api.getUsersForWorkgroup({workgroup_id:this.user_workgroup_id}, this);
	}
	
	showAllUsers(){
		this.api.getAllUsers(null, this);
	}
	
	confirmDeleteUser(uuid:any, name:any){
		 let confirm:any = {
			 title: 'Delete User',
			 message: 'Are you sure you want to delete '+name+'?',
			 confirmAction: () => {
				 this.api.deleteuser.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					this.ds.closeDialog();
					$('.mat-list-item[data-user-uuid='+uuid+']').remove();
				})
				this.api.deleteUser({uuid:uuid});
			 }
		 };
		 this.ds.openConfirm(confirm);
	}
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		this.subscription = this.api.signup.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			$('body').removeClass('saving').removeClass('loading');
			if(res.error){
				setTimeout(() => {
					this.ds.openSnackbarMessage({message:res.error.message});
					$('.login').addClass('error');
				}, 500);
				return;
			}
			if(res.status == 201){
				this.ds.openSnackbarMessage({message:'User added'});
				this.api.getAllUsers(null, this);
			}
		});
		this.subscription = this.api.getallusers.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.users = res['data']['users'];
		});
		this.api.getAllUsers(null, this);
		
		this.api.getallaccounts.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.accounts = res.data;
			this.user_accounts = res.data;
		})
		this.api.getAllAccounts();
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}