import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// [TODO check this out](https://medium.com/@matsal.dev/angular-how-to-pass-template-with-component-as-input-using-ngtemplateoutlet-1d7ba9eeadd6)

@Component({
  selector: 'app-genericlist',
  templateUrl: './genericlist.component.html',
  styleUrls: ['./genericlist.component.scss']
})
export class GenericlistComponent implements OnInit {

  @Input() listType:string; 
  @Input() items:any[]; 
  @Input() itemMeta:string | Function ="";
  @Input() itemStyleOverride:boolean | Function = false;
  @Input() itemIcon:Function;
  @Input() selectedItem:any; 
  @Input() emptyListMessage:string;
  @Input() editMessage:string = "edit";
  @Input() infotMessage:string = "info";
  @Input() linkMessage:string = "go to link";
  @Input() copyMessage:string = "copy";
  @Input() deleteMessage:string = "delete";
  @Input() labelProp:string | Function = 'name';
  @Input() search: string;
  @Input() searchInclude: string|string[];
  @Input() searchExclude: string|string[];
  @Input() sort: string;
  @Input() sortReverse: boolean;
  @Output() clickItem = new EventEmitter<any>();
  @Output() copyItem = new EventEmitter<any>();
  @Output() editItem = new EventEmitter<any>();
  @Output() infoItem = new EventEmitter<any>();
  @Output() linkItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  @Output() toggleItem = new EventEmitter<any>();


  canClick:boolean; 
  canLink:boolean;
  canEdit:boolean; 
  canInfo:boolean; 
  canCopy:boolean; 
  canDelete:boolean; 
  canToggle:boolean; 

  constructor(public cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.canClick = this.clickItem.observers.length > 0;
    this.canLink = this.linkItem.observers.length > 0;
    this.canEdit = this.editItem.observers.length > 0;
    this.canInfo = this.infoItem.observers.length > 0;
    this.canDelete = this.deleteItem.observers.length > 0;
    this.canToggle = this.toggleItem.observers.length > 0;
    this.canCopy = this.copyItem.observers.length > 0;
  }
  getLabel(item:any) : string
  {
    if(typeof this.labelProp === 'string') return item[this.labelProp];
    if(typeof this.labelProp === 'function') return this.labelProp(item);
    return item;
  }
  getMeta(item:any) : string
  {
    if(typeof this.itemMeta === 'string') return item[this.itemMeta];
    if(typeof this.itemMeta === 'function') return this.itemMeta(item);
    return item;
  }
  getOverrideStyle(item:any){
    if(typeof this.itemStyleOverride === 'function') return this.itemStyleOverride(item);
	return item;
  }

  click(item:any, event:MouseEvent)
  {
    event.stopPropagation();
    this.clickItem.emit(item);
  }
  copy(item:any, event:MouseEvent)
  {
    event.stopPropagation();
    this.copyItem.emit(item);
  }
  link(item:any, event:MouseEvent)
  {
    event.stopPropagation();
    this.linkItem.emit(item);
  }
  edit(item:any, event:MouseEvent)
  {
    event.stopPropagation();
    this.editItem.emit(item);
  }
  info(item:any, event:MouseEvent)
  {
    event.stopPropagation();
    this.infoItem.emit(item);
  }
  delete(item:any, event:MouseEvent)
  {
    event.stopPropagation();
    this.deleteItem.emit(item);
  }
  toggle(item:any, checked:boolean)
  {
    item.selected = checked;
    this.toggleItem.emit(item);
  }
}
