import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly localStorage:Storage = inject(DOCUMENT)?.defaultView?.localStorage;

  get<T>(key: string, defaultValue:any = undefined): T | string | undefined {
    const item = this.localStorage?.getItem(key);
	if(item === undefined) return defaultValue;
	try {
		return JSON.parse(item);
	} catch (error) {
		console.warn(`Error parsing json for key ${key}`);
		return undefined;
	}
  }

  set(key: string, value: string | unknown): void {
	try {
		const encoded = JSON.stringify(value);
		this.localStorage?.setItem(key, encoded);
	} catch (error) {
		console.warn(`Error encoding json for key ${key}`);
	}
  }
  remove(key: string): void {
    this.localStorage?.removeItem(key);
  }
  removeKeys(keys: string[]): void {
    keys.forEach(key => this.localStorage?.removeItem(key));
  }
  clear(): void {
    this.localStorage?.clear();
  }
}