// https://stackoverflow.com/questions/7563169/detect-which-word-has-been-clicked-on-within-a-text
// https://brooknovak.wordpress.com/2013/06/11/find-the-character-position-using-javascript-fast-big-pages-all-browsers-no-preprocessing/
// https://developer.mozilla.org/en-US/docs/Web/API/Selection/modify
// https://stackoverflow.com/questions/7563169/detect-which-word-has-been-clicked-on-within-a-text
// https://jsfiddle.net/Vap7C/1/
// https://jsfiddle.net/Vap7C/80/

const script = `
   <script>
   // load
   if(document.readyState === "complete" || document.readyState === "interactive") {
      window.parent.postMessage({'uuid':$UUID,'type':'load'}, '*');
   } else {
      // Loading still in progress.
      window.addEventListener("load", () => {
        window.parent.postMessage({'uuid':$UUID,'type':'load'}, '*');
      });
    }
   // click block
   document.addEventListener('contextmenu', e => {
    e.preventDefault();
    var data = {x:e.x, y:e.y};
    window.parent.postMessage({uuid:$UUID, type:'right_click', 'contextmenu':data}, '*');
    return;
      var selection = document.getSelection();
			if(selection?.type == "Range")
      {
        var selectionString = selection.toString();
        let range = selection.getRangeAt(0);
        window.parent.postMessage({uuid:$UUID, type:'right_click', range}, '*');
      }
    },true);
    window.addEventListener("message", onMessage);
    function domString(child)
    {
      let path = "";
      let parent = child.parentNode;
      let max = 100;
      while(parent && max--)
      {
        var index = Array.prototype.indexOf.call(parent.childNodes, child);
        path = (path.length ? (index + '.') : index) + path;
        child = parent;
        parent = parent.parentNode;
      }
      return path;
    }
    function domStringToChild(ds)
    {
      let indices = ds.split(".");
      let parent = window.document;
      let child = null;
      while(indices.length)
      {
        let childIndex = indices.shift();
        child = Array.from(parent.childNodes)[parseInt(childIndex)];
        parent = child;
      }
      return child;
    }
    function getSelectionData()
    {
      var selection = document.getSelection();
      console.log("getSelectionData", selection);
      if(selection?.type == "Range")
      {
        var selectionString = selection.toString();
        let range = selection.getRangeAt(0);
        let clientRects = range.getClientRects();
        let rects = [];
        for(var i = 0; i < clientRects.length; i++)
        {
          let rect = clientRects[i];
          rects.push({
            top: rect.top + window.scrollY,
            right: rect.right,
            bottom: rect.bottom + window.scrollY,
            left: rect.left,
            x:rect.x,
            y:rect.y + window.scrollY,
            width:rect.width,
            height:rect.height,
          });
        }
        let startOffset = range.startOffset;
        let endOffset = range.endOffset;
        let startDom = domString(range.startContainer)
        let endDom = domString(range.endContainer)
        let response = {startOffset, endOffset, startDom, endDom, selectionString, rects, rect:range.getBoundingClientRect()}
        if(selection) selection.removeAllRanges();
        return response;
      }
      return null;
    }
    function onMessage(e){
        const { hostname } = new URL(e.origin);
        //console.log("iframe message",e.data)
        if(e.data.type == "selection_clear")
          {
            var selection = document.getSelection();
            if(selection) selection.removeAllRanges();
            return;
          }
        if(e.data.type == "selection_update")
          {
            var selectionIn = e.data.message;
            var range = new Range();
            var startNode = domStringToChild(selectionIn.startDom);
            var endNode = domStringToChild(selectionIn.endDom);


            var selection = window.getSelection();
            selection.removeAllRanges();
            var range = document.createRange();
            range.setStart(startNode, selectionIn.startOffset);
            range.setEnd(endNode, selectionIn.endOffset);
            selection.addRange(range);

            let response = getSelectionData()
            window.parent.postMessage({uuid:$UUID, __id:e.data.__id, type:'selection_update', response}, '*');
            return;
          }
        if(e.data.type == "selection"){
          
            let response = getSelectionData()
            window.parent.postMessage({uuid:$UUID, __id:e.data.__id, type:'selection', response}, '*');
          return;
        }
        if(e.data.type == "check" && e.data.position){
            query(e.data.position.x, e.data.position.y);
        }
        if(hostname.indexOf(window.location.hostname) == -1) return;
    }
        window.addEventListener("scroll", onScroll);
        function onScroll(e)
        {
            var data = {x:window.scrollX, y:window.scrollY};
            window.parent.postMessage({'uuid':$UUID,'scroll':data}, '*');
        }
      //window.addEventListener("click", onMove);
      let oldElement;
      function onMove(e)
      {
        let x = e.clientX;
        let y = e.clientY;
        query(x, y);
      }
      function query(x, y)
      {
        let element = document.elementFromPoint(x, y);
        // check if coords are in element
        console.log('query', x, y, element ? true : false);
        if(element && oldElement != element){
          element.classList.add('over');
        } else if(!element) {
            if(oldElement) oldElement.classList.remove('over');
            oldElement = null;
            // still need to respond so caller knows we acted
            window.parent.postMessage({uuid:$UUID, type:'query', rect:null}, '*');
            return;
        }
        if(oldElement != element)
        {
          if(oldElement) oldElement.classList.remove('over');
          oldElement = element;
        }
          var sel = window.getSelection();
          sel.removeAllRanges();
          var range = document.createRange();
          range.selectNodeContents(element);
          sel.addRange(range);
          //var anchor = sel.anchorNode;
          // if no anchor then no text content to search
          //if(!anchor) return;
          var length = element.textContent ? element.textContent.length : undefined;//anchor.length;
          if(isNaN(length)) return;
          //console.log("len", length, element);
          sel.collapse(element);
         // sel.modify("extend", "right", "word");
          //let rect = range.getBoundingClientRect();

          function getDist(rect)
          {
            // early out if contained (0)
            if(x >= rect.x && x <= rect.right && y >= rect.y && y <= rect.bottom) return 0;
            let cx = rect.x + rect.width * 0.5;
            let cy = rect.y + rect.height * 0.5;
            let dx = x - cx;
            let dy = y - cy;
            let dist = Math.sqrt(dx * dx + dy * dy);
            return dist;
          }
          let maxDist = Number.MAX_VALUE;//getDist(rect);
          let closestRange = null;
          //console.log("max dist", maxDist);

          //https://developer.mozilla.org/en-US/docs/Web/API/Selection/modify
          for(var i = 0; i < 95; i++)
            {
             if(i > 0) sel.modify('move', 'forward', 'character');
             sel.modify('extend', 'forward', 'word');
                
              
              for (let j = 0; j < sel.rangeCount; j++) {
                let range =  sel.getRangeAt(j);

                let rect = range.getBoundingClientRect();
                let dist = getDist(rect);
                
                if(dist < maxDist) {
                    closestRange = range;
                    maxDist = dist;
                    if(dist == 0) {
                        i = j = 9999999;
                        break;
                    }
                }
               // console.log("dist", i, j, dist);
               // TODO see if we can track range element and make sure it stays the same
               //console.log("range", range.startOffset, range.endOffset,  length);
               if(range.endOffset >= length)   // range.startOffset >= length || 
               {
                   i = 999999;
                   break;
               }
              }
              
            }
            
            //console.log("closestRange", closestRange?.toString());
            window.getSelection().removeAllRanges();
            if(closestRange)
            {
                var rect = closestRange.getBoundingClientRect();
                window.parent.postMessage({uuid:$UUID, type:'query', copy:closestRange.toString(), rect:rect}, '*');
            } else {
                window.parent.postMessage({uuid:$UUID, type:'query', rect:null}, '*');
            }
           
        }
    </script>
`;

    export default script;