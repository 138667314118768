import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	title:any = false;
	message:any = false;
	message2:any = false;
	dismiss_button_label:any = 'Close';

	constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api:ApiService, private router:Router) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		if(typeof this.data.title !== 'undefined'){
			this.title = this.data.title;
		}
		if(typeof this.data.message !== 'undefined'){
			this.message = this.data.message;
		}
		if(typeof this.data.message2 !== 'undefined'){
			this.message2 = this.data.message2;
		}
		if(typeof this.data.dismiss_button_label !== 'undefined'){
			this.dismiss_button_label = this.data.dismiss_button_label;
		}
	}
	
	dismissAction(){
		if(typeof this.data.dismissAction !== 'undefined'){
			this.data.dismissAction();
			this.dialogRef.close();
		}else{
			this.dialogRef.close();
		}
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}