import { Injectable } from '@angular/core';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Injectable({
	providedIn: 'root'
})
export class PanelService {

	constructor() { }
	
	public openCreativeProperties(_data:any){
		//console.log('openCreativeProperties', _data);
		//console.log('creativeProperties', _data.creative.creativeProperties);
		_data.creative.creativeProperties.configure(_data.asset, _data.creative.assets);
		
		if(!$('.middle').hasClass('panel_show')){
			$('.middle').addClass('panel_show');
		}
	}
}
