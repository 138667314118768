<div [ngClass]="{'in-page':!data}" class="prjwf-container">
<mat-dialog-content >	
	<!-- [[ TEAM ]] -->
	<fieldset *ngIf="true" class="projectContainer"><!--workflow-->
		<legend>Project Team</legend>
		
		<!-- ALL USERS LIST-->
		<div *ngIf="userAdmins" class="admins"><!--users-->
			<span [matTooltip]="appUserIsAdmin ? disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : (!availableUsers) ? 'Not enough users assigned to this Workgroup' : 'Add a new admin' : ''">
				<div class="subHeader" [matMenuTriggerFor]="menuProjUsers" [ngClass]="{'greyout':disabledEditing}">
					<button mat-mini-fab class="newBtnSmall" [disabled]="!availableUsers" color="primary" ><mat-icon>add</mat-icon></button>
					Assigned people
					<!--<app-help [key]="'wf-admins'"></app-help>-->
					<mat-menu #menuProjUsers="matMenu">
						<button *ngFor="let user of availableUsers" [disabled]="isProjectUser(user)" (click)="addProjectUser(user)" mat-menu-item>{{user.name}}</button>
					</mat-menu>
				</div>
			</span>
			<!-- <div class="instruction" *ngIf="true || availableUsers &&  availableUsers.length==userAdmins.length">Add more users to the Workgroup to be able to assign more Project Admins</div> -->
			<mat-list>
				<mat-list-item *ngFor="let user of projectUsers" class="user">
					<div class="userListItem" [ngClass]="{'greyout':disabledEditing}">
						<span>{{user.name}}</span>
						<span [matTooltip]="'remove user from project'"><button mat-icon-button [disabled]="disabledEditing" (click)="removeProjectUser(user)" color="warn"><mat-icon>remove_circle_outline</mat-icon></button></span>
					</div>
				</mat-list-item>
			</mat-list>
			<!--<div style="margin-left:0.75em" [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new admin'">
				<button mat-mini-fab class="newBtnSmall" [disabled]="!availableUsers || availableUsers.length==userAdmins.length || disabledEditing" color="primary" [matMenuTriggerFor]="menuAdmin"><mat-icon>add</mat-icon></button>
			</div>-->
		</div>
	</fieldset>
	<!-- [[ PROJECT ]] -->
    <fieldset *ngIf="workflows" class="projectContainer"><!--workflow-->
        <legend>Project </legend>
		
		<!-- ADMINS LIST-->
        <div *ngIf="userAdmins" class="admins"><!--users-->
			<span [matTooltip]="appUserIsAdmin ? disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : (!availableUsers || availableUsers.length==userAdmins.length) ? 'Not enough users assigned to this Workgroup' : 'Add a new admin' : ''">
				<div class="subHeader" [matMenuTriggerFor]="menuAdmin" [ngClass]="{'greyout':disabledEditing}">
					<button mat-mini-fab class="newBtnSmall" [disabled]="!availableUsers || availableUsers.length==userAdmins?.length || disabledEditing" color="primary" ><mat-icon>add</mat-icon></button>
					Admins
					<!--<app-help [key]="'wf-admins'"></app-help>-->
					<mat-menu #menuAdmin="matMenu">
						<button *ngFor="let user of projectUsers | callback: canBeAdmin" (click)="setAdmin(user)" mat-menu-item>{{user.name}}</button>
					</mat-menu>
				</div>
			</span>
			<!-- <div class="instruction" *ngIf="true || availableUsers &&  availableUsers.length==userAdmins.length">Add more users to the Workgroup to be able to assign more Project Admins</div> -->
			<mat-list>
				<mat-list-item *ngFor="let user of userAdmins" class="user">
					<div class="userListItem" [ngClass]="{'greyout':disabledEditing}">
						<span>{{user.name}}</span>
						<span [matTooltip]="'remove user from admin role'"><button mat-icon-button [disabled]="disabledEditing || userAdmins?.length == 1" (click)="removeAdmin(user)" color="warn"><mat-icon>remove_circle_outline</mat-icon></button></span><!--(click)="removeAdmin(user)"-->
					</div>
				</mat-list-item>
			</mat-list>
			<!--<div style="margin-left:0.75em" [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new admin'">
				<button mat-mini-fab class="newBtnSmall" [disabled]="!availableUsers || availableUsers.length==userAdmins.length || disabledEditing" color="primary" [matMenuTriggerFor]="menuAdmin"><mat-icon>add</mat-icon></button>
			</div>-->
        </div>
		<mat-divider></mat-divider>

		<!-- PROJECT CHANNELS -->
		<div class="projChannels">
            <span [matTooltip]="appUserIsAdmin ? disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new channel to the project' : ''">
                <div class="subHeader"(click)="addProjectChannel()" [ngClass]="{'greyout':disabledEditing}"><!-- style="display: flex;flex-grow: 1;justify-content: space-between;"-->
                	<!-- <div class="buttonRow"> -->
                	<button class="newBtnSmall" mat-mini-fab color="primary" [disabled]="disabledEditing" ><mat-icon>add</mat-icon></button>
                    Channels 
					<!---->
				</div>
			</span>
				<!--<app-help [key]="'wf-channels'"></app-help>	-->
                <!-- </div> -->
                <!-- <button class="showBtn" mat-stroked-button color="primary" matTooltip="Show all available channels in the Project" (click)="showChannels = !showChannels" >Show channels</button> -->
            
			<!-- <div style="font-weight: normal;">({{workflow.channels.length}}/{{numProjectChannels}} activated)</div> -->
            <div class="note" *ngIf="workflow"><b>{{workflow.channels?.length}}/{{projectChannels?.length}} activated</b> - Click a channel below for options:</div>
            <div class="projectChannelContainer"><!-- *ngIf="showChannels" -->
                <div class="no-message" *ngIf="projectChannels?.length == 0">
					<div class="instruction"><mat-icon>warning</mat-icon>No channels have been set up for this project.</div>
					<!--<div><button class="newBtn" mat-mini-fab color="primary" (click)="addWorkflow()" matTooltip="Add a new workflow"><mat-icon>add</mat-icon></button></div>			-->
				</div>
				<div *ngFor="let projectChannel of projectChannels">
					
						<button class="projectChannelBtn linked" *ngIf="workflow && isProjectChannelLinked(projectChannel)" [disabled]="disabledEditing" mat-flat-button color="primary" small matTooltip="" [matMenuTriggerFor]="menuSettings" >{{projectChannel.name}}</button>
						<button class="projectChannelBtn" *ngIf="!workflow || !isProjectChannelLinked(projectChannel)" [disabled]="disabledEditing" mat-stroked-button color="primary" small matTooltip="" [matMenuTriggerFor]="menuSettings" >{{projectChannel.name}}</button>
						<!-- <div class="project-channel" [ngClass]="{linked:isProjectChannelLinked(projectChannel)}" [matMenuTriggerFor]="menuSettings">{{projectChannel.name}}</div> -->
						<mat-menu #menuSettings="matMenu">
							<div class="projectChannelMenuHeader"><mat-label>{{projectChannel.name}}</mat-label></div>
							<mat-divider></mat-divider>
							<button (click)="editProjectChannel(projectChannel)" mat-menu-item>
								<mat-icon>edit</mat-icon>
								<span>Edit Channel</span>
							</button>
							<button (click)="deleteProjectChannel(projectChannel)" mat-menu-item>
								<mat-icon>delete</mat-icon>
								<span>Delete Channel</span>
							</button>
							<mat-divider></mat-divider>
							<button *ngIf="workflow" (click)="linkProjectChannel(projectChannel)" mat-menu-item [disabled]="isProjectChannelLinked(projectChannel)">
								<mat-icon>add_link</mat-icon>
								<span>Activate Channel</span>
							</button>
							<button *ngIf="workflow" (click)="unlinkProjectChannel(projectChannel)" mat-menu-item [disabled]="!isProjectChannelLinked(projectChannel)">
								<mat-icon>link_off</mat-icon>
								<span>De-activate Channel</span>
							</button>
						</mat-menu>
                </div>
            </div>
			<!--<div style="margin-left:0.75em" [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new channel to the project'">
				<button class="newBtnSmall" mat-mini-fab color="primary" [disabled]="disabledEditing" (click)="addProjectChannel()"><mat-icon>add</mat-icon></button>
			</div>-->
		</div>
		
    </fieldset>
    

	<!-- [[ WORKFLOW ]] -->
	 <div class="workflowContainer">
		<fieldset style="overflow-x:auto">
			<legend class="wfHeader"><div class="title">Workflow</div>
				
			</legend>
			<div class="wfNameHeader">
				<ngContainer *ngIf="!editWorkflow">
					<div class="name-field">
						<mat-form-field *ngIf="workflows?.length > 0" appearance="fill">
							<!-- <mat-label>selected Workflow</mat-label>-->
							<!-- <mat-hint align="end">select a different workflow using the dropdown arrow</mat-hint>-->
							<mat-select [(ngModel)]="workflow" placeholder="Select a workflow..." (valueChange)="update($event)"  ><!-- [disabled]="disabledEditing" -->
								<mat-option *ngFor="let wf of workflows; trackBy:trackByID" [value]="wf">
									{{wf.name}}
								</mat-option>
							</mat-select>
							
						</mat-form-field>
						<span *ngIf="workflow" [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Edit Workflow name'">
							<button mat-icon-button 
							[disabled]="!workflow || disabledEditing" 
							(click)="editWorkflowName(workflow)"
							>
								<mat-icon>mode_edit</mat-icon>
							</button>
						</span>	
					</div>
					<span *ngIf="workflow" [matTooltip]="'not implemented'" class="small"><!--</span>>disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Delete Workflow + \'workflow.name\''">-->
						<button mat-stroked-button 
							[disabled]="!workflow || disabledEditing" 
							[color]="warn"
							(click)="deleteWorkflow(workflow)"
							style="margin-left: -1em;"
							>
							delete workflow
						</button>
					</span>
					
				</ngContainer>
				<!--<ngContainer *ngIf="workflow && editWorkflow">
					<form class="editNameForm">
						<mat-form-field class="example-full-width" appearance="fill">
							<mat-label>Name</mat-label>
							<input #workflowName type="text" [value]="workflow.name" matInput placeholder="Enter name">
						</mat-form-field>
					</form>
					<button mat-icon-button (click)="saveWorkflowEdit(workflowName.value)"><mat-icon>save</mat-icon></button>
					<button mat-icon-button (click)="editWorkflow = false"><mat-icon>cancel</mat-icon></button>
				</ngContainer>-->
			</div>
			<div  class="workflowContent">
				<div class="no-message" *ngIf="workflows?.length == 0">
					<div class="instruction"><mat-icon>warning</mat-icon>No workflows have been set up for this project.</div>
					<!-- <div><button class="newBtn" mat-mini-fab color="primary" (click)="addWorkflow()" matTooltip="Add a new workflow"><mat-icon>add</mat-icon></button></div> -->
					<button class="small" mat-stroked-button color="primary" (click)="addWorkflow()" matTooltip="Add a new workflow">Add workflow</button>
				</div>
				<!-- <div >
						<span>formats: {{workflow.formats.length}}</span>
						<span>creatives: {{workflow.creatives.length}}</span>
						<span>users: {{workflow.users.length}}</span>
				</div>
				-->
				<ngContainer *ngIf="workflow">
					<div *ngIf="workflow" class="users">
					<!-- role | user | toggle | channel -->
					<table>
						<thead>
						<tr>
							<th><!--Role / User -->
								<div class="sticky">
									<div>
			
									</div>
									<div *ngIf="nonApprUserNum" class="all"><!-- workflow.moderators.length && productionUsers && approverUsers-->
										all
									</div>
								</div>
							</th>
							<ng-container *ngIf="nonApprUserNum" >
								<td [ngClass]="isLast ? '':'roleChannel'" *ngFor="let channel of workflow.channels; last as isLast ">
									<div class="channelCellContainer" [matTooltip]="channel.name" *ngIf="nonApprUserNum" >
										<div class="channelCellContent">{{channel.name}}</div></div>
								</td>
							</ng-container>
						</tr>
						</thead>
						<tbody>
							<!-- Amend managers -->
							<tr class="role">
								<th>
									<div class="sticky">
										<div style="margin-right:0.25em;">
											<span [matTooltip]="appUserIsAdmin ? disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : (!availableUsers || !userAdmins?.length) ? 'No Admins available in this Project' : 'Add a new moderator' : ''" >
												<div class="subHeader first" [matMenuTriggerFor]="menuAmendManager" [ngClass]="{'greyout':disabledEditing}">
													
														<button mat-mini-fab class="newBtnSmall" color="primary" [disabled]="!availableUsers || !userAdmins?.length || disabledEditing"><mat-icon>add</mat-icon></button>
														Amend Moderators
													<!--<button mat-icon-button (click)="addProduction()"><mat-icon>add_circle_outline</mat-icon></button>-->
													<!---->
													
												<!--<app-help [key]="'wf-amendmanager'"></app-help>-->
												</div>
											</span>
											<mat-menu #menuAmendManager="matMenu">
												<button mat-menu-item *ngFor="let user of projectUsers | callback: canBeAmendManager" (click)="saveAmendManagerUser(user)" >{{user.name}}</button> <!-- (click)="setModerator(user)"-->
											</mat-menu>
											<!-- TODO maybe pre make this (and other user lists) so we can show a hint if none available-->
										</div>
										<div *ngIf="nonApprUserNum" class="all"></div>
									</div>
								</th>
								<td [attr-colspan]="projectChannels?.length"><!-- workflow.channels?.length-->
									<!--empty-->			
								</td>
							</tr>
							<!-- not used -----------   -->
							<tr *ngIf="false && moderatorUser" class="user">
								<th>
									<div class="sticky">
										<div class="userListItem">
											<span>{{moderatorUser.name}}</span>
											<div>
												<button class="btn-save" mat-flat-button color="primary" (click)="saveAmendManagerUser()">Save</button>
												<button class="btn-cancel" mat-icon-button color="warn" (click)="cancelEditors()"><mat-icon>cancel</mat-icon></button>
											</div>
										</div>
										<div *ngIf="nonApprUserNum" class="all" >
											<mat-icon>check</mat-icon>  
										</div>
									</div>
								</th>
								<td *ngFor="let channel of workflow.channels">
									<mat-icon>check</mat-icon>
								</td>
							</tr>
							<!--  ---------------------   -->
							<ng-container *ngFor="let user of workflow.moderators"><!--let user of users | callback: isModerator2-->
								<!-- user is not in edit mode -->
								<tr class="user" > <!--  *ngIf="editProductionUser != user"-->
									<th>
										<div class="sticky">
											<div class="userListItem clickable" [ngClass]="{'greyout':disabledEditing}" [matMenuTriggerFor]="menuModeratorUser" [matMenuTriggerData]="{user}">
												<span>{{user.name}}</span>			
												<div>
													<!--
														<button mat-icon-button color="warn" [disabled]="disabledEditing" [matTooltip]="'remove user from moderator role'" (click)="removeModerator(user)"><mat-icon>remove_circle_outline</mat-icon></button>
													-->
													<!--<button mat-icon-button [matMenuTriggerFor]="menuModeratorUser" [matMenuTriggerData]="{user}">
														<mat-icon>more_vert</mat-icon>
													</button>-->
													<mat-icon>more_vert</mat-icon>
												</div>
											</div>
											<div *ngIf="nonApprUserNum" class="all">
												<div>
													<mat-icon [ngClass]="{'greyout':disabledEditing}">check</mat-icon>                                  
												</div>
											</div>
										</div>
									</th>
									<td *ngFor="let channel of workflow.channels">
										<div>
											<!--empty--> <!-- <mat-icon [ngClass]="{'greyout':disabledEditing}">check</mat-icon> -->
										</div>
									</td>
								</tr>
							</ng-container>
							<!--<tr>
								<div [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new moderator'" >
									<button mat-icon-button color="primary" [disabled]="disabledEditing" [matMenuTriggerFor]="menuAmendManager"><mat-icon>add_circle_outline</mat-icon></button>
								</div>
							</tr>-->
							
							<tr class="lastTR">
								<th>
									<div class="sticky">
										<div class="userListItem">
											<!--empty-->
										</div>
										<div *ngIf="nonApprUserNum" class="all">
											<!--empty-->
										</div>
									</div>
								</th>
							</tr>

							<!-- PRODUCTION -->
							<tr class="role">
								<th>
									<div class="sticky">
										<div style="margin-right:0.25em;">
											<span [matTooltipPositionAtOrigin]="true" [matTooltipPosition] ="'below'" [matTooltip]="appUserIsAdmin ? !workflow.channels?.length ? 'No active channels to assign production users to - activate a channel first' : disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new production user' : ''" >
												<div class="subHeader" [ngStyle]="{'pointer-events':(!workflow.channels?.length || disabledEditing) ? 'none' : ''}" [matMenuTriggerFor]="menuProduction" [ngClass]="{'greyout':disabledEditing}"><!--[ngStyle]="(!workflow.channels.length || disabledEditing) ? {'pointer-events':none} : {}"-->
													<button mat-mini-fab class="newBtnSmall" color="primary" [disabled]="!workflow.channels?.length || disabledEditing"><mat-icon>add</mat-icon></button>
													Production
													<!--<button mat-icon-button (click)="addProduction()"><mat-icon>add_circle_outline</mat-icon></button>-->
													<!--<app-help [key]="'wf-production'"></app-help>-->
												</div>
											</span>
											<mat-menu #menuProduction="matMenu">
												<button mat-menu-item *ngFor="let user of projectUsers | callback: canBeProduction" (click)="setProduction(user)">{{user.name}}</button>
											</mat-menu>
										</div>
										<div *ngIf="nonApprUserNum || productionUser" class="all">{{productionUser ? 'all' : ''}}</div>
									</div>
								</th>
								<td *ngFor="let channel of workflow.channels">
									<div [matTooltip]="productionUser ? channel.name : ''" class="channelCellContainer">
										<div class="channelCellContent">{{productionUser ? channel.name : ''}}</div>
									</div>				
								</td>
							</tr>

							<!-- NEW PRODUCTION USER -->
							<tr *ngIf="productionUser" class="user" [formGroup]="channelFormGroup">
								<th>
									<div class="sticky">
										<div class="userListItem isEditing">
											<span>{{productionUser.name}}</span>
											<div>											
												<button class="btn-save" mat-flat-button color="primary" [disabled]="!canSaveProductionUser()" (click)="saveProductionUser()">Save</button>
												<button class="btn-cancel" mat-icon-button color="warn" (click)="cancelEditors()"><mat-icon>cancel</mat-icon></button>
											</div>
										</div>
										<div class="all">
											<mat-checkbox color="primary" [checked]="isChannelFormAllTrue()" (change)="toggleEditProductionAccessToAllChannels()"></mat-checkbox>
										</div>
									</div>
								</th>
								<td *ngFor="let channel of workflow.channels" formGroupName="channels">
									<mat-checkbox color="primary" [formControlName]="channel.id"></mat-checkbox>
								</td>
							</tr>
							<ng-container *ngFor="let user of productionUsers">
								<!-- user is not in edit mode -->
								<tr class="user" *ngIf="editProductionUser != user">
									<th>
										<div class="sticky">
											<div class="userListItem clickable" [ngClass]="{'greyout':disabledEditing}" [matMenuTriggerFor]="menuProductionUser" [matMenuTriggerData]="{user}">
												<div>
													<span>{{user.name}}</span>
												</div>
												<div>
													<!--

														<button mat-icon-button color="primary" [disabled]="disabledEditing" [matTooltip]="'edit user access'" (click)="editProduction(user)"><mat-icon>edit</mat-icon></button>
														<button mat-icon-button color="warn" [disabled]="disabledEditing" [matTooltip]="'remove all user from production role'" (click)="removeProduction(user)"><mat-icon>remove_circle_outline</mat-icon></button>
													-->
													<!--<button mat-icon-button [matMenuTriggerFor]="menuProduction" [matMenuTriggerData]="{user}">
														<mat-icon>more_vert</mat-icon>
													</button>-->
													<mat-icon>more_vert</mat-icon>
												</div>
											</div>
											<div *ngIf="nonApprUserNum" class="all">
												<div>
													<mat-icon *ngIf="hasAccessToAllChannels(user, 'production')" [ngClass]="{'greyout':disabledEditing}">check</mat-icon>                                  
												</div>
											</div>
										</div>
									</th>
									<td *ngFor="let channel of workflow.channels">
										<div>
											<mat-icon *ngIf="hasAccessToChannel(user, channel, 'production')" [ngClass]="{'greyout':disabledEditing}">check</mat-icon>
										</div>
									</td>
								</tr>
								<!-- user is in edit mode -->
								<tr class="user" *ngIf="editProductionUser == user" [formGroup]="channelFormGroup">
									<th>
										<div class="sticky">
											<div class="userListItem isEditing">
												<div>
													<span>{{user.name}}</span>
												</div>
												
												<!--<button mat-icon-button (click)="saveEditProduction()"><mat-icon>save</mat-icon></button>-->
												<button class="btn-save" mat-flat-button color="primary" [disabled]="!canSaveEditProduction()" (click)="saveEditProduction()">Save</button>
												<button class="btn-cancel" mat-icon-button color="warn" (click)="cancelEditors()"><mat-icon>cancel</mat-icon></button>
												
											</div>
											<div class="all">
												<div>
													<mat-checkbox color="primary" [checked]="isChannelFormAllTrue()" (change)="toggleEditProductionAccessToAllChannels()"></mat-checkbox>                                  
												</div>
											</div>
										</div>
									</th>
									<td *ngFor="let channel of workflow.channels" formGroupName="channels">
										<div >
											<!--<mat-checkbox [checked]="hasAccessToChannel(user, channel, 'production')" (change)="toggleAccessToChannel($event, user, channel, 'production')"></mat-checkbox>-->
											<mat-checkbox color="primary" [formControlName]="channel.id"></mat-checkbox>
										</div>
									</td>
								</tr>
							</ng-container> 
							
							<!--<tr class="buttonTR">
								<th>
									<div class="sticky">
										<div class="userListItem">
											<span [matTooltip]="!workflow.channels.length ? 'no channels!' : disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'Add a new production user'" >
												<button mat-mini-fab class="newBtnSmall" color="primary" [disabled]="!workflow.channels.length || disabledEditing"  [matMenuTriggerFor]="menuProduction"><mat-icon>add</mat-icon></button>
											</span>
										</div>
										<div class="all">
											
										</div>
									</div>
								</th>
							</tr> -->
							<tr class="lastTR">
								<th>
									<div class="sticky">
										<div class="userListItem">
											<!--empty-->
										</div>
										<div *ngIf="nonApprUserNum" class="all">
											<!--empty-->
										</div>
									</div>
								</th>
							</tr>
								             
							<!-- APPROVERS -->
							<tr class="role">
								<th>
									<div class="sticky">
										<div style="margin-right:0.25em;  margin-bottom:0.5em;">
											<span [matTooltipPositionAtOrigin]="true" [matTooltipPosition] ="'below'" [matTooltip]="appUserIsAdmin ? !workflow.channels?.length ? 'No active channels to assign approvers to - activate a channel first' : disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'add a new approver or group ' : ''" >
												<div class="subHeader" [ngStyle]="{'pointer-events':(!workflow.channels?.length || disabledEditing) ? 'none' : ''}" [matMenuTriggerFor]="menuApprovalChoice" [ngClass]="{'greyout':disabledEditing}">
													<button mat-mini-fab class="newBtnSmall" color="primary" [disabled]="!workflow.channels?.length || disabledEditing"><mat-icon>add</mat-icon></button>
													Approvers
													<!--<button mat-icon-button (click)="addApprover()"><mat-icon>add_circle_outline</mat-icon></button>-->
													<!--<app-help [key]="'wf-approver'"></app-help>-->
												</div>
											</span>
											<mat-menu (closed)="approverMenuClosed($event)" #menuApprovalChoice="matMenu">
												<button mat-menu-item [matMenuTriggerFor]="menuApprovalUsers">Assign user</button>
												<button mat-menu-item (click)="newGroup()">New Group</button>
											</mat-menu>
											<mat-menu #menuApprovalUsers="matMenu">
												<button *ngFor="let user of projectUsers | callback: canBeApprover" [matMenuTriggerFor]="menuApprovalGroups" [matMenuTriggerData]="{user}" mat-menu-item>{{user.name}}</button>
											</mat-menu>
											<mat-menu #menuApprovalGroups="matMenu">
												<ng-template matMenuContent let-user="user">
													<!--<button mat-menu-item>You selected: {{category}}</button>-->
													<button *ngFor="let group of workflow.approval_groups" mat-menu-item (click)="assignApprover(group, user)">{{group.name}}</button>
													<button mat-menu-item (click)="newGroup(user)">New Group</button>
												</ng-template>
											</mat-menu>
											
										</div>
										<!--<div class="all"></div>-->
									</div>
								</th>
								<td *ngFor="let channel of workflow.channels"><!--<empty> --></td>
							</tr>
							<!-- not used -------
							<ng-container *ngIf="false && approvalGroup">
								<tr>
									<th class="group">
										<div class="sticky">
											<span>{{approvalGroup.name}}</span>
											<div class="all"></div>
										</div>
									</th>
									<td class="group" [colSpan]="workflow.channels.length"></td>
								</tr>
								<ng-container *ngIf="approvalUser">
									<tr class="user" [formGroup]="channelFormGroup">
										<th>
											<div class="sticky">
												<div class="userListItem">
													<span>{{approvalUser.name}}</span>
													<div>
														<button class="btn-save" color="primary" mat-flat-button (click)="saveNewApproval()">Save</button>
														<button mat-icon-button color="warn" (click)="cancelNewApproval()"><mat-icon>cancel</mat-icon></button>
													</div>
												</div>
												<div class="all">
													<mat-checkbox color="primary" [checked]="isChannelFormAllTrue()" (change)="toggleEditProductionAccessToAllChannels()"></mat-checkbox>                                  
												</div>
											</div>
										</th>
										<td *ngFor="let channel of workflow.channels" formGroupName="channels">
											<mat-checkbox color="primary" [formControlName]="channel.id"></mat-checkbox>
										</td>
									</tr>
								</ng-container>
							</ng-container>
							-->
							
							<ng-container *ngFor="let group of workflow.approval_groups; trackBy:trackByID; first as isFirstGroup; last as isLastGroup;">
								<tr [ngClass]="{'greyout':disabledEditing}" >
									<th class="group">
										<div class="sticky tl">
											<div class="groupHeader">
												<div>group {{group.order + 1}}: 
													<!-- <span class="groupName">({{group.order}})</span>-->
													<span class="groupName">{{group.name}}</span>
													
												</div><!--  ({{group.users.length}}) -->
												<!--

													<div class="middle">
														<span [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : group.users.length == 0 ? 'Delete this approval group' : 'Cannot delete with users in it'">
															<button mat-icon-button color="warn" [disabled]="group.users.length > 0 || disabledEditing" (click)="deleteApprovalGroup(group)"><mat-icon>remove_circle_outline</mat-icon></button>
														</span>
													</div>
												-->
												<div>
													<button mat-icon-button [matMenuTriggerFor]="menuGroup" [matMenuTriggerData]="{group}">
														<mat-icon>more_vert</mat-icon>
													</button>
													<mat-menu #menuGroup="matMenu">
														<ng-template matMenuContent let-group="group">
															<div [matTooltipPositionAtOrigin]="true" [matTooltipPosition] ="'below'" [matTooltip]="!workflow.channels.length ? 'No active channels to assign approvers to - activate a channel first' : disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'add a new approver to group \''+group.name+'\''">
																<button mat-menu-item [ngStyle]="{'pointer-events':(!workflow.channels.length || disabledEditing) ? 'none' : ''}" [disabled]="!workflow.channels.length || disabledEditing" [matMenuTriggerFor]="menuApprovalUsersGroup">
																	<mat-icon class="medium">add_circle_outline</mat-icon><span>Add approver to this group</span>
																</button>
															</div>
															<button mat-menu-item (click)="editApprovalGroup(group)">
																<mat-icon class="medium">edit</mat-icon><span>Edit this group</span>
															</button>
															<button mat-menu-item [matMenuTriggerFor]="menuApprovalGroupBefore" [matMenuTriggerData]="{group}">
																<mat-icon class="medium">expand_less</mat-icon><span>Move group before</span>
															</button>
															<button mat-menu-item [matMenuTriggerFor]="menuApprovalGroupAfter" [matMenuTriggerData]="{group}">
																<mat-icon class="medium">expand_more</mat-icon><span>Move group after</span>
															</button>
															<div [matTooltip]="disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : group.users.length == 0 ? 'Delete this approval group' : 'Cannot delete with users in it'">
																<button mat-menu-item [disabled]="group.users.length > 0 || disabledEditing" (click)="deleteApprovalGroup(group)" class="delete">
																	<mat-icon class="medium">delete</mat-icon><span>Delete this group</span>
																</button>
															</div>
														</ng-template>
													</mat-menu>
													<mat-menu #menuApprovalUsersGroup="matMenu">
														<button *ngFor="let user of projectUsers | callback: canBeApprover" (click)="assignApprover(group, user)" mat-menu-item>{{user.name}}</button>
													</mat-menu>
													<mat-menu #menuApprovalGroupBefore="matMenu">
														<ng-template matMenuContent let-group="group">
															<button *ngFor="let g of workflow.approval_groups" mat-menu-item (click)="moveApprovalGroup('before', group, g)" [disabled]="(g.order - 1 == group.order) || g==group">{{g.name}}</button>
														</ng-template>
													</mat-menu>
													<mat-menu #menuApprovalGroupAfter="matMenu">
														<ng-template matMenuContent let-group="group">
															<button *ngFor="let g of workflow.approval_groups" mat-menu-item (click)="moveApprovalGroup('after', group, g)" [disabled]="(g.order + 1 == group.order) || g==group">{{g.name}}</button>
														</ng-template>
													</mat-menu>
													<!--

														<span [matTooltip]="!workflow.channels.length ? 'no channels!' : disabledEditing ? 'Whoah! Go back and save or cancel the user role you\'re currently editing.' : 'add a new approver to group \''+group.name+'\''" >
															<button mat-icon-button color="primary" [disabled]="!workflow.channels.length || disabledEditing" [matMenuTriggerFor]="menuApprovalUsersGroup"><mat-icon>person_add</mat-icon></button>
														</span>
													-->
												</div>
											</div>
											
											<div class="all" style=" background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 10%) !important">
								
											</div> 
										</div>
									</th>
									<!--<td class="group" *ngFor="let channel of workflow.channels; last as isLast">
										<div class="groupChannel" [ngClass]="isLast ? 'tr':'t'">{{channel.name}}</div>
									</td>-->
									<td class="group" [colSpan]="workflow.channels.length">
										<div class="tr" ></div>
									</td>
								</tr>
								<tr style="border-bottom: 1px solid lightgrey">
									<th><!-- [attr.colspan]="workflow.channels.length+1"-->
										<div class="sticky l">
											<div style="width:100%">
												<div class="approval-option" [ngClass]="{'greyout':disabledEditing}">
													<div>Approval mode:</div>
													<div class="option-select" [matMenuTriggerFor]="menuApprovalMode" [matMenuTriggerData]="{group}">{{group.mode}}<mat-icon>arrow_drop_down</mat-icon></div>
												</div>
												<div class="approval-option" [ngClass]="{'greyout':disabledEditing}">
													<div>Moderate amends:</div>
													<mat-checkbox xclass="option-select" [checked]="group.use_moderator" (change)="toggleUseModerator(group)" color="primary" style="margin-right: 0.75em;"></mat-checkbox>
													<!-- <div class="option-select" [matMenuTriggerFor]="menuApprovalMode" [matMenuTriggerData]="{group}">{{group.mode}}<mat-icon>arrow_drop_down</mat-icon></div> -->
												</div>
											</div>
											<div class="all" style="height: 48px !important;">all</div>
										</div>
									</th>
									<td *ngFor="let channel of workflow.channels; last as isLastChannel">
										<div class="channelCellContainer" style="height: 48px !important; display:flex; justify-content:center; align-items:center;" [matTooltip]="channel.name" [ngClass]="{r:isLastChannel}">
											<div class="channelCellContent">{{channel.name}}</div>
										</div>
									</td>
								</tr>
								<!--<tr>
									<th>
										<div class="sticky l">
											<div></div>
											<div class="all">all</div>
										</div>
									</th>
									<td *ngFor="let channel of workflow.channels; last as isLastChannel">
										<div [ngClass]="{r:isLastChannel}">
											{{channel.name}}
										</div>
									</td>
								</tr>-->
								<ng-container *ngIf="group == approvalGroup">
									<tr class="user" [formGroup]="channelFormGroup">
										<th>
											<div class="sticky l"  >   <!--[ngClass]="{bl:isLastUser, l:!isLastUser}"-->
												<div class="userListItem">
													<div>
														<span>{{approvalUser.name}}</span>
													</div>
													<div>
														<!--<button mat-icon-button (click)="saveNewApproval()"><mat-icon>save</mat-icon></button>-->
														<button class="btn-save" mat-flat-button color="primary" [disabled]="!canSaveNewApproval()" (click)="saveNewApproval()">Save</button>
														<button class="btn-cancel" mat-icon-button color="warn" (click)="cancelEditors()"><mat-icon>cancel</mat-icon></button>
													</div>
												</div>
												<div class="all">
													<mat-checkbox [checked]="isChannelFormAllTrue()" (change)="toggleEditProductionAccessToAllChannels()"></mat-checkbox>                                  
												</div>
											</div>
										</th>
										<td *ngFor="let channel of workflow.channels; last as isLastChannel" formGroupName="channels">
											<div [ngClass]="{r:isLastChannel}">
												<mat-checkbox [formControlName]="channel.id"></mat-checkbox>
											</div>
										</td>
									</tr>
								</ng-container>
								<ng-container *ngIf="!group.users.length && !approvalUser">
									<tr class="user">
										<th>
											<div class="sticky l">
												<div class="instruction">
													No Approvers in this group.
												</div>
												<div class="all"></div>
											</div>
										</th>
										<td [colSpan]="workflow.channels.length">
											<div class="r"></div>
										</td>
									</tr>
								</ng-container>
								<ng-container *ngFor="let user of group.users; last as isLastUser;">
									<!-- user is not in edit mode -->
									<tr class="user" *ngIf="editApprovalUser != user">
										<th>
											<div class="sticky l">
												<div class="userListItem clickable" [ngClass]="{'greyout':disabledEditing}" [matMenuTriggerFor]="menuApproval" [matMenuTriggerData]="{user}">
													<div>
														<span>{{user.name}}</span>
													</div>
													<div>
														<!--

															<button mat-icon-button color="primary" [disabled]="disabledEditing" [matTooltip]="'edit user access'" (click)="editApproval(user)"><mat-icon>edit</mat-icon></button>
															<button mat-icon-button color="warn" [disabled]="disabledEditing" [matTooltip]="'remove all user access'" (click)="removeApprover(user)"><mat-icon>remove_circle_outline</mat-icon></button>
														-->
															<button mat-icon-button [disabled]="disabledEditing" >
															<mat-icon>more_vert</mat-icon>
														</button>
														<mat-menu #menuApproval="matMenu">
															<ng-template matMenuContent let-user="user">
																<button  (click)="editApproval(user)" mat-menu-item><mat-icon class="small">edit</mat-icon>Edit user channels</button>
																<button  mat-menu-item [matMenuTriggerFor]="menuApprovalGroupMove" [matMenuTriggerData]="{group}"><mat-icon class="small">move</mat-icon>Move user group</button>
																<button  (click)="removeApprover(user)" mat-menu-item class="delete"><mat-icon class="small">delete</mat-icon>Revoke Approver role</button>
															</ng-template>
														</mat-menu>
														<mat-menu #menuApprovalGroupMove="matMenu">
														<ng-template matMenuContent let-group="group">
															<button *ngFor="let g of workflow.approval_groups" mat-menu-item (click)="moveUserApprovalGroup(user, g)" [disabled]="g == group">{{g.name}}</button>
														</ng-template>
													</mat-menu>
													</div>
													
												</div>
												<div class="all">
													<mat-icon *ngIf="hasAccessToAllChannels(user, 'approval')" [ngClass]="{'greyout':disabledEditing}">check</mat-icon>
												</div>
											</div>
										</th>
										<td *ngFor="let channel of workflow.channels; last as isLastChannel">
											<div [ngClass]="{r:isLastChannel}">
												<mat-icon *ngIf="hasAccessToChannel(user, channel, 'approval')" [ngClass]="{'greyout':disabledEditing}">check</mat-icon>
											</div>
										</td>
									</tr>
									<!-- user is in edit mode -->
									<tr class="user" *ngIf="editApprovalUser == user" [formGroup]="channelFormGroup">
										<th>
											<div class="sticky l">
												<div class="userListItem isEditing">
													<div>
														<span>{{user.name}}</span>
													</div>
													<div>
														<!--<button mat-icon-button (click)="saveEditApproval(group)"><mat-icon>save</mat-icon></button>-->
														<button class="btn-save" mat-flat-button color="primary" [disabled]="!canSaveEditApproval(group)" (click)="saveEditApproval(group)">Save</button>
														<button class="btn-cancel" mat-icon-button color="warn" (click)="cancelEditors()"><mat-icon>cancel</mat-icon></button>
													</div>
												</div>
												<div class="all">
													<mat-checkbox color="primary" [checked]="isChannelFormAllTrue()" (change)="toggleEditProductionAccessToAllChannels()"></mat-checkbox>                                  
												</div>
											</div>
										</th>
										<td *ngFor="let channel of workflow.channels; last as isLastChannel" formGroupName="channels">
											<div [ngClass]="{r:isLastChannel}">
												<mat-checkbox color="primary" [formControlName]="channel.id"></mat-checkbox>
											</div>
										</td>
									</tr>
								</ng-container>

								<tr class="dummy-bottom">
									<th>
										<div class="sticky bl">
											<span></span>                        
											<div class="all"></div>
										</div>
									</th>
									<td *ngFor="let channel of workflow.channels; last as isLastChannel">
										<div [ngClass]="{b:!isLastChannel, br:isLastChannel}"></div>
									</td>
								</tr>
								<tr *ngIf="!isLastGroup"></tr>
							</ng-container>
							
							<!--<tr>	
								<th>
									<button class="newBtn" mat-mini-fab color="primary" (click)="newGroup()" matTooltip="Add approval group"><mat-icon>add</mat-icon></button>
								</th>
							</tr>-->
							<tr></tr>
						</tbody>
					</table>
					</div>
					<!--
					<div *ngIf="addingProduction">
						<mat-form-field appearance="fill">
							<mat-label>Available Users</mat-label>
							<mat-select [(value)]="productionUser" (valueChange)="updateProductionUser()">
								<mat-option *ngFor="let user of availableUsers | callback: canBeProduction" [value]="user">
									{{user.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<div *ngIf="productionUser">
							<section [formGroup]="channelFormGroup">
								<table>
									<tr>
										<td *ngFor="let channel of workflow.channels">{{channel.name}}</td>
									</tr>
									<tr>
										<td *ngFor="let channel of workflow.channels"><mat-checkbox [formControlName]="channel.id"></mat-checkbox></td>
									</tr>
								</table>
							</section>
							<button (click)="saveProductionUser()">Save Production User</button>
						</div>
					</div>
					-->
					<!--
					<div *ngIf="addingApprover">
						<mat-form-field appearance="fill">
							<mat-label>Available Users</mat-label>
							<mat-select [(value)]="approvalUser" (valueChange)="updateApprovalUser()">
								<mat-option *ngFor="let user of availableUserss | callback: canBeApprover" [value]="user">
									{{user.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<div *ngIf="approvalUser">
							<section [formGroup]="channelFormGroup">
								<table>
									<tr>
										<td *ngFor="let channel of workflow.channels">{{channel.name}}</td>
									</tr>
									<tr>
										<td *ngFor="let channel of workflow.channels"><mat-checkbox [formControlName]="channel.id"></mat-checkbox></td>
									</tr>
								</table>
							</section>
							<mat-form-field appearance="fill">
								<mat-label>Approval Group</mat-label>
								<mat-select [formControl]="groupControl">
									<mat-option *ngFor="let group of workflow.approval_groups" [value]="group">
										{{group.name}}
									</mat-option>
									<mat-option [value]="'new'">
										New Group
									</mat-option>
								</mat-select>
							</mat-form-field>
							<button (click)="saveApprover()">Save Approval User</button>
						</div>                
					</div>
				-->
				</ngContainer>
				

			</div>
		</fieldset>
	</div>
</mat-dialog-content>

<div class="footer">
	<div><button class="newBtn" mat-mini-fab color="primary" (click)="addWorkflow()" matTooltip="Add a new workflow"><mat-icon>add</mat-icon></button><!--<span>Add workflow</span>--></div>			
	<div class="help" [matMenuTriggerFor]="menuHelp">
		<span>Help menu</span><mat-icon>help_outline</mat-icon>
	</div>
</div>

<!-- triggered menus-->
<mat-menu #menuProductionUser="matMenu">
	<ng-template matMenuContent let-user="user">
		<span mat-menu-item>{{user.name}}:</span>
		<mat-divider></mat-divider>
		<button [disabled]="disabledEditing" (click)="editProduction(user)" mat-menu-item><mat-icon class="small">edit</mat-icon>Edit user channels</button>
		<button [disabled]="disabledEditing" (click)="removeProduction(user)" mat-menu-item class="delete"><mat-icon class="small">delete</mat-icon>Revoke Production role</button>
	</ng-template>
</mat-menu>

<mat-menu #menuModeratorUser="matMenu">
	<ng-template matMenuContent let-user="user">
		<span mat-menu-item>{{user.name}}:</span>
		<mat-divider></mat-divider>
		<button [disabled]="disabledEditing" (click)="removeModerator(user)" mat-menu-item class="delete"><mat-icon class="small">remove_circle_outline</mat-icon>Revoke Amend Moderator role</button>
	</ng-template>
</mat-menu>

<mat-menu #menuHelp="matMenu">
	<ng-template matMenuContent>
		<span mat-menu-item disabled>Workflow structure:</span>
		<mat-divider></mat-divider>	
		<div mat-menu-item><app-help [key]="'wf-channels'" 			[type]="'label'" [label]="'Channels'" ></app-help></div>
		<div mat-menu-item><app-help [key]="'wf-approvalgroups'"	[type]="'label'" [label]="'Approval Groups'" ></app-help></div>
		<mat-divider></mat-divider>
		<span mat-menu-item disabled>User access and roles:</span>
		<div mat-menu-item><app-help [key]="'wf-projectteam'" 		[type]="'label'" [label]="'Project Team'" ></app-help></div>
		<div mat-menu-item><app-help [key]="'wf-admins'" 			[type]="'label'" [label]="'Admins'" ></app-help></div>
		<div mat-menu-item><app-help [key]="'wf-amendmanagers'" 	[type]="'label'" [label]="'Amend Moderators'" ></app-help></div>
		<div mat-menu-item><app-help [key]="'wf-production'" 		[type]="'label'" [label]="'Production'" ></app-help></div>
		<div mat-menu-item><app-help [key]="'wf-approver'" 			[type]="'label'" [label]="'Approvers'" ></app-help></div>
		
	</ng-template>
</mat-menu>

<mat-menu #menuApprovalMode="matMenu">
	<ng-template matMenuContent let-group="group">
		<div mat-menu-item (click)="setApprovalMode('everyone',group)">everyone</div>
		<div mat-menu-item (click)="setApprovalMode('anyone',group)">anyone</div>
	</ng-template>
</mat-menu>
	
</div>
