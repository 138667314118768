import { Component, OnInit } from '@angular/core';
import { BaseAssetComponent } from '../baseAsset.component';

@Component({
  selector: 'app-html-link',
  templateUrl: './html-link.component.html',
  styleUrls: ['./html-link.component.scss']
})
export class HtmlLinkComponent extends BaseAssetComponent {

  constructor() {
    super();
	this.defaultWidth = 256;
	this.defaultHeight = 32
  }
  isValid()
  {
    let uri = this.uri();
    if(!uri) return false;
	uri = decodeURIComponent(uri);
	
    let urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    let valid = urlRegex.test(uri);
    return valid;
  }
  ngOnInit(): void {
  }

}
