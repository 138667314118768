<div class="wrapper" *ngIf="user">
	<div class="top">
		<div class="profile_pic">
			<div *ngIf="!photo_url" class="inner"></div>
			<div *ngIf="photo_url" [attr.data-profile-url]="photo_url" class="inner"></div>
			<button class="change_button" mat-raised-button>Change</button>
		</div>
		<div class="details">
			<h1 class="heading">{{heading}}</h1>
			<p class="job">{{user.job_description}}</p>
			<p class="email"><a [attr.href]="'mailto:'+user.email">{{user.email}}</a></p>
		</div>
	</div>
	<div class="bottom">
		<mat-tab-group>
			<mat-tab label="Details">
				<div class="details_inputs">
					<mat-form-field class="col100">
						<input matInput id="name" class="login_input" placeholder="Name" value="{{user.name}}">
					</mat-form-field>
					<mat-form-field class="col100">
						<input matInput id="email" class="login_input" placeholder="Email" value="{{user.email}}">
					</mat-form-field>
					<mat-form-field class="col100">
						<input matInput id="company" class="login_input" placeholder="Company" value="{{user.company}}">
					</mat-form-field>
					<mat-form-field class="col100">
						<input matInput id="job" class="login_input" placeholder="Job Description" value="{{user.job_description}}">
					</mat-form-field>
				</div>
			</mat-tab>
			<mat-tab label="Access">
				<h2 *ngIf="current_user.roles.architect || current_user.roles.superadmin || current_user.roles.admin">Workgroups</h2>
				<mat-form-field class="workgroup-chip-list" *ngIf="current_user.roles.architect || current_user.roles.superadmin || current_user.roles.admin">
				  <mat-chip-list #chipList aria-label="Workgroups">
				    <mat-chip
				      *ngFor="let workgroup of workgroups"
				      [selectable]="selectable"
				      [removable]="removable"
				      (removed)="remove(workgroup.uuid)">
				      {{workgroup.name}}
				      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
				    </mat-chip>
				    <input
				      matInput
				      placeholder="Add workgroup..."
				      #workgroupInput
				      [formControl]="workgroupCtrl"
				      [matAutocomplete]="auto"
				      [matChipInputFor]="chipList"
				      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				      (matChipInputTokenEnd)="add($event)">
				  </mat-chip-list>
				  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
				      {{option.name}}
				    </mat-option>
				  </mat-autocomplete>
				</mat-form-field>
				<hr>
				<h2>Tree</h2>
				<app-project-tree [user_id]="user.uuid"></app-project-tree>
<!-- 				<div class="access" *ngIf="user_access"><pre>{{user_access}}</pre></div> -->
			</mat-tab>
		</mat-tab-group>
		<div class="button_wrapper">
			<button (click)="saveUserProfile()" mat-raised-button>Save</button>
			<button *ngIf="user.active == 1 && !my_profile" (click)="deactivateUser()" mat-raised-button class="deactivate">Deactivate User</button>
			<button *ngIf="user.active == 0 && !my_profile" (click)="activateUser()" mat-raised-button class="activate">Activate User</button>
		</div>
	</div>
</div>