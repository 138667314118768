<mat-dialog-content>
<div class="wrapper">
    <div *ngIf="this.data?.type == 'video'">
        <video id="video" controls [src]="this.data.url | safe:'url'"></video>
    </div>
    <div *ngIf="this.data?.type == 'image'">
        <img id="image" controls [src]="this.data.url | safe:'url'" />
    </div>
    <div class="cropper"
        [style.top.px]="cropper.y1"
         [style.left.px]="cropper.x1"
         [style.width.px]="cropper.x2 - cropper.x1"
         [style.height.px]="cropper.y2 - cropper.y1"
    >
        
        <div
            (mousedown)="startMove($event, moveTypes.Move)"
            (touchstart)="startMove($event, moveTypes.Move)"
            class="move">
            <div *ngIf="dragging">
                <span>{{cropper.x1}}, {{cropper.y1}}</span>
                <span>{{cropper.x2 - cropper.x1}}, {{cropper.y2 - cropper.y1}}</span>
            </div>
            
        </div>
        <span class="resize topleft"
                  (mousedown)="startMove($event, moveTypes.Resize, 'top,left')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'top,left')">
                <span class="square"></span>
            </span>
            <span class="resize top">
                <span class="square"></span>
            </span>
            <span class="resize topright"
                  (mousedown)="startMove($event, moveTypes.Resize, 'top,right')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'top,right')">
                <span class="square"></span>
            </span>
            <span class="resize right">
                <span class="square"></span>
            </span>
            <span class="resize bottomright"
                  (mousedown)="startMove($event, moveTypes.Resize, 'bottom,right')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'bottom,right')">
                <span class="square"></span>
            </span>
            <span class="resize bottom">
                <span class="square"></span>
            </span>
            <span class="resize bottomleft"
                  (mousedown)="startMove($event, moveTypes.Resize, 'bottom,left')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'bottom,left')">
                <span class="square"></span>
            </span>
            <span class="resize left">
                <span class="square"></span>
            </span>
            <span class="resize-bar top"
                  (mousedown)="startMove($event, moveTypes.Resize, 'top')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'top')">
            </span>
            <span class="resize-bar right"
                  (mousedown)="startMove($event, moveTypes.Resize, 'right')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'right')">
            </span>
            <span class="resize-bar bottom"
                  (mousedown)="startMove($event, moveTypes.Resize, 'bottom')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'bottom')">
            </span>
            <span class="resize-bar left"
                  (mousedown)="startMove($event, moveTypes.Resize, 'left')"
                  (touchstart)="startMove($event, moveTypes.Resize, 'left')">
            </span>            
    </div>
    
</div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button [mat-dialog-close]="cropper" color="primary">Done</button>
</mat-dialog-actions>
