import { Component, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreativeService } from 'src/app/api/creative.service';
import { DeliveryPackageService } from 'src/app/api/delivery_package.service';
import { Globals } from 'src/app/global';
import { AssetVO } from 'src/app/models/asset.model';
import { Creative, CreativeState } from 'src/app/models/creative.model';
import { DeliveryItem, IDeliveryItem } from 'src/app/models/deliveryItem.model';
import { DeliveryFolderOptions, IDeliveryPackage } from 'src/app/models/deliveryPackage.model';
import { IWorkflow } from 'src/app/models/workflow.model';

@Component({
	selector: 'app-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

	_unsubscribe = new Subject<boolean>();
	uuid:string;
	deliveryPackage:IDeliveryPackage;
	needsPassword: boolean;
	password: string = '';
	selectedItem: Creative;//DeliveryItem
	assets:AssetVO[];

	selectedAsset:AssetVO; // dunny
	index:number = 0
	galleryIndex:number = 0
	layout:string = 'custom';
	sortedItems: any;
	workflows: IWorkflow[];
	downloadStatus: {label, msg, disabled, icon, color};
	bg_color: string;
	bg_transparent: boolean;
	folderOptionLabel: any;
	totalAssetCount: number;
  
	constructor(private route:ActivatedRoute, private deliveryPackageService:DeliveryPackageService,public snackBar:MatSnackBar,public creativeService:CreativeService) { }

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.uuid = params["uuid"];
			this.loadData()
		});
	}

	loadData(){
		// check if this delivery needs a password first
		this.deliveryPackageService.findOne(this.uuid, {extra:true, password:this.password}).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//this.loadDeliveryItems();
			//console.log(res);
			if(typeof res.data[0] == 'string')
			{
				this.needsPassword = true;
				// reset password if wrong
				if(this.password != '') this.password = '';
			}else{
				this.needsPassword = false;
				this.deliveryPackage = res.data[0];

				this.sortedItems = this.formatPackageItems(this.deliveryPackage.delivery_items, this.deliveryPackage.workflows);
				/*
				if(this.deliveryPackage.type == "delivery")
				{
						this.sortedItems = this.formatPackageItems(this.deliveryPackage.delivery_items);
				}
				if(this.deliveryPackage.type == "share")
				{
					// sort
					this.deliveryPackage.delivery_items = this.deliveryPackage.delivery_items.sort(this.sort);
				}
				*/
				if(this.deliveryPackage.type == "delivery")
				{
					//console.log(this.deliveryPackage);
					this.deliveryPackage.partialAllowed = (this.deliveryPackage.partialAllowed) ? true : false;
					this.downloadStatus = this.getDownloadStatus();
					this.folderOptionLabel = this.getFolderOptionLabel();
				}
			}
		});
	}
	
	formatPackageItems(_items:IDeliveryItem[],_workflows){

		let workflows:any = {};
    	if(!_items) _items = [];  // items not always sent by backend
		this.totalAssetCount = 0;
		//if(this.deliveryPackage.useFormatPath == false) //channel priority
		//{
			_items.forEach(item => {
				let workflow_uuid = item.creative.workflow_uuid;
				let workflow_name = _workflows.find(wf => wf.uuid == workflow_uuid).name;
				if(!workflows[workflow_uuid]) workflows[workflow_uuid] = {name:workflow_name, channels:{}};
				let workflow = workflows[workflow_uuid]
				if(!workflow.channels[item.creative.channel_uuid]) workflow.channels[item.creative.channel_uuid] = {channel:item.creative.channel, creatives:[]};
				let channel = workflow.channels[item.creative.channel_uuid]
				item.creative.workflow_name = workflow_name;
				channel.creatives.push(item.creative);
				this.totalAssetCount += item.creative['deliverableCount'];
			});

			workflows = Object.values(workflows);
			workflows.sort((a, b) => a.name.localeCompare(b.name));

			// workflows.sort((a, b) => )
			workflows.forEach(workflow => {
				workflow.channels = Object.values(workflow.channels);
				// sort channels
				//workflow.channels.sort((a, b) => a.order - b.order);
				workflow.channels.sort((a, b) => a.channel.name.localeCompare(b.channel.name));
				// sort creatives
				workflow.channels.forEach(channel => {
				channel.creatives.sort((a, b) => {
					if(a.format.group != b.format.group)
					{
					return a.format.group.localeCompare(b.format.group);
					}else{
					return a.format.name.localeCompare(b.format.name);
					}
				})
				});
			});
		/*} else { //format priority
			_items.forEach(item => {
				let workflow_uuid = item.creative.workflow_uuid;
				let workflow_name = _workflows.find(wf => wf.uuid == workflow_uuid).name;
				if(!workflows[workflow_uuid]) workflows[workflow_uuid] = {name:workflow_name, formats:{}};
				let workflow = workflows[workflow_uuid]
				if(!workflow.formats[item.creative.format_uuid]) workflow.formats[item.creative.format_uuid] = {format:item.creative.format, creatives:[]};
				let format = workflow.formats[item.creative.format_uuid]
				//item.creative.workflow_name = workflow_name;
				format.creatives.push(item.creative);
			});

			workflows = Object.values(workflows);
			workflows.sort((a, b) => a.name.localeCompare(b.name));

			workflows.forEach(workflow => {
				workflow.formats = Object.values(workflow.formats);
				workflow.formats.sort((a, b) => {
					if(a.format.group != b.format.group)
					{
						return a.format.group.localeCompare(b.format.group);
					}else{
						return a.format.name.localeCompare(b.format.name);
					}
				});

				// sort creatives
				workflow.formats.forEach(format => {
					format.creatives.sort((a, b) => a.channel.name.localeCompare(b.channel.name));
				});
				
			});
		}*/
		
		//console.log("workflows", workflows);
		return workflows;
		// for item of _items:
		//   determine workflow name (id for now) from 	item.creative.format.workflow_uuid
		//   determine channel name (name) from			item.creative.channel.name
		//   determine format group name (name) from 		item.creative.format.group
		//   detemrine creative name (name) from			item.creative.format.name
		//   determine num assets in creative from		item.creative.assets.length
		//   determine creative status from				item.creative.state (=="done")

		// workflow (arr) -> channel (arr) -> creative {props}

		// need to add to response data:
		//    project name (only have project_uuid)
		//    client name
		//    workflow name(s)


		//sort	
  	}
	sort(a:DeliveryItem, b:DeliveryItem) {

		if(a.creative.format.group != b.creative.format.group)
		{
			return a.creative.format.group.localeCompare(b.creative.format.group);
		}else{
			return a.creative.format.name.localeCompare(b.creative.format.name);
		}
	}

	onItemSelected(event:MatSelectionListChange)
	{
		this.assets = [];
		//console.log("selected", event);
		this.selectedItem = event.options[0].value;
		this.layout = this.selectedItem.layout || 'custom';
		this.bg_color = '#'+this.selectedItem.bg_color;
		this.bg_transparent = this.selectedItem.bg_transparent;
		this.getCreativeAssets(this.selectedItem);
	}

	getCreativeAssets(creative:Creative) //for share view only when select an item to view
	{
		this.creativeService.getAssets(creative.uuid, {share_uuid:this.uuid}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				let uriMeta = 'share_uuid='+this.uuid;
				this.assets = response.data.map(asset => new AssetVO(asset, uriMeta));
				//console.log("assets:", this.assets);
			}
			/*,
			error => this.errorMessage = <any>error*/
		);
	}
	galleryMove(dir){
		this.galleryIndex += dir;
		this.galleryIndex = ((this.galleryIndex % this.assets.length) + this.assets.length) % this.assets.length;
	}
	
	getAssetCount()
	{
		//console.log("function not used");
		return;
		if(!this.deliveryPackage || !this.deliveryPackage.delivery_items.length) return 0;
		let assetCount = 0;
		for (let i = 0; i < this.deliveryPackage.delivery_items.length; i++) {
			const delivery_item:IDeliveryItem = this.deliveryPackage.delivery_items[i];
			//assetCount += delivery_item.creative.assets.length;
		}
		return 1;//assetCount;
	}

	getUnapprovedCreativeCount(){
		let totalItems = this.deliveryPackage.delivery_items.length;
		if(!this.deliveryPackage || !totalItems) return -1;
		let creativeCount = 0;
		for (let i = 0; i < totalItems; i++) {
			const delivery_item:IDeliveryItem = this.deliveryPackage.delivery_items[i];
			if(delivery_item.creative.state != CreativeState.DONE) creativeCount ++;
		}
		return creativeCount;
	}

	getFolderOptionLabel(): any {
		let folderOptionLabel = 'not set';
		//legacy
		if(!(this.deliveryPackage.folderOption)){
			this.deliveryPackage.folderOption = this.deliveryPackage.useFormatPath ? DeliveryFolderOptions.FORMAT : DeliveryFolderOptions.CHANNEL;
		}

		//convert to label
		switch(this.deliveryPackage.folderOption)
		{
			case DeliveryFolderOptions.FLAT : folderOptionLabel = 'All unique filenames in top folder '; break;
			case DeliveryFolderOptions.CHANNEL : folderOptionLabel = 'Workflow -> Channel -> Format -> file'; break;
			case DeliveryFolderOptions.FORMAT : folderOptionLabel = 'Workflow -> Format -> Channel -> file'; break;
				
		}

		return folderOptionLabel;
	}


	getDownloadStatus(){
		let dlObj = {label:'', msg:'', disabled:true, icon:'warning', color:'red'};
		

		dlObj.label = (this.deliveryPackage.partialAllowed) ? 'Download all APPROVED items' : 'Download complete package'
		
		if(!this.deliveryPackage.actioned){
			dlObj.msg = "This package has not yet been released.";
		} else {
			let totalItems = this.deliveryPackage.delivery_items.length;
			if(totalItems == 0){
				dlObj.msg = "No items have been added to this package.";
			} else {
				//all approved
				let unapprovedCount = this.getUnapprovedCreativeCount()
				if (unapprovedCount == 0){
					dlObj.msg = "All items approved and available for download";
					dlObj.color = "green";
					dlObj.icon = "done";
					dlObj.disabled = false;
				} else {
					//only some approved
					if(this.deliveryPackage.partialAllowed){
						if(unapprovedCount == totalItems){//none approved
							dlObj.msg = "Download will be enabled as items become approved.";
						} else {
							dlObj.label = "Download " + (totalItems - unapprovedCount) + " of " + totalItems + " approved items"
							dlObj.msg = "Only partial download available - " + unapprovedCount +" items still to be approved.";
							dlObj.disabled = false;
						}
					} else {
						dlObj.msg = "Download will be enabled when all items have been approved.";
					}
				}
			}
		} 
		return dlObj;
	}

	download()
	{
		//whole or partial package in one
    let downloadPath = Globals.BASE_API_URL + 'delivery_package/' + this.deliveryPackage.uuid + '/download';
		window.location.href = downloadPath;
	}
	
	isCreativeDownloadDisabled(creative:Creative)
	{
		// TODO check the state of everything!!!
		//single creative (when in partial mode)
		if(!this.deliveryPackage.actioned || !this.deliveryPackage.partialAllowed) return true;
		if(this.deliveryPackage.partialAllowed && creative.state != CreativeState.DONE) {return true;}
		return false;
	}

	downloadCreative(creative:Creative)
	{
		//download a single ceative
		window.location.href = Globals.BASE_API_URL + `delivery_package/${this.deliveryPackage.uuid}/${creative.uuid}/download`;
	}

  	copTextToClipboard(str:string,label:string=null)
	{	
		navigator.clipboard.writeText(str).then().catch(e => console.log(e));
		let snack_msg = (label != null ? (label + ' ') : '') + "copied to clipboard";
		this.snackBar.open(snack_msg, null, {duration: 2000});
	}

}
