import { MaterialModule } from './material/material-module';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { ObjNgForPipe } from './pipes/obj-ng-for.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HasFlagsPipe } from './pipes/hasFlags.pipe';
import { FilterKeyPipe } from './pipes/filterKey.pipe';
import { FilterUniquePipe } from './pipes/filterUnique.pipe';
import { TrackByPropPipe } from './pipes/trackByProp.pipe';


import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { ApiService } from './api/api.service';
import { StringsService } from './services/strings.service';
import { HelpService } from './services/help.service';
import { DialogService } from './services/dialog.service';
import { LocalStorageService } from './services/localStorage.service';

import { PreloaderComponent } from './common/preloader/preloader.component';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';

/* VIEWS */
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SignupValidateComponent } from './views/signup-validate/signup-validate.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ProjectListComponent } from './views/project-list/project-list.component';
import { ProjectDetailComponent } from './views/project-detail/project-detail.component';
import { SettingsSystemComponent } from './views/settings-system/settings-system.component';
import { SettingsUserComponent } from './views/settings-user/settings-user.component';
import { SettingsProjectComponent } from './views/settings-project/settings-project.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { ManageUsersComponent } from './views/manage-users/manage-users.component';
import { ManageProjectsComponent } from './views/manage-projects/manage-projects.component';
import { ManageAccountsComponent } from './views/manage-accounts/manage-accounts.component';
import { ManageWorkgroupsComponent } from './views/manage-workgroups/manage-workgroups.component';
import { ManageFoldersComponent } from './views/manage-folders/manage-folders.component';
import { CreativeViewComponent } from './views/creative-view/creative-view.component';
//import { AmendViewComponent } from './views/amend-view/amend-view.component';
import { AmendViewComponent as AmendRequestViewComponent } from './views/amend-request-view/amend-view.component';

/* WIDGETS */
import { MyProjectsComponent } from './widgets/my-projects/my-projects.component';
import { MyTasksComponent } from './widgets/my-tasks/my-tasks.component';
import { MyNotificationsComponent } from './widgets/my-notifications/my-notifications.component';

/* COMPONENTS */
import { ProjectTreeComponent } from './components/project-tree/project-tree.component';

/* DIALOGS */
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { SnackbarMessageComponent } from './dialogs/snackbar-message/snackbar-message.component';
import { SnackbarWithCloseComponent } from './dialogs/snackbar-with-close/snackbar-with-close.component';
import { AssignUsersDialogComponent } from './dialogs/assign-users-dialog/assign-users-dialog.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { AddContainerDialogComponent } from './dialogs/add-container-dialog/add-container-dialog.component';
import { AssetViewComponent } from './views/asset-view/asset-view.component';
import { AssetEmbedComponent } from './components/asset-embed/asset-embed.component';
import { EditAssetDialogComponent } from './dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { EditContainerDialogComponent } from './dialogs/edit-container-dialog/edit-container-dialog.component';
import { AssetSpecificationsComponent } from './components/asset-specifications/asset-specifications.component';

import { ControlBarViewComponent } from './components/control-bar/control-bar-view.component';
import { SettingsButtonViewComponent } from './components/settings-button/settings-button-view.component';
import { BreadcrumbsViewComponent } from './components/breadcrumbs/breadcrumbs-view.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UploadAssetDialogComponent } from './dialogs/upload-asset-dialog/upload-asset-dialog.component';
import { NewAssetDialogComponent } from './dialogs/new-asset-dialog/new-asset-dialog.component';
import { CreativePropertiesComponent } from './panels/creative-properties/creative-properties.component';
import { PrototypesComponent } from './views/prototypes/prototypes/prototypes.component';
//import { AmendsComponent } from './views/prototypes/amends/amends.component';
import { AmendComponent } from './views/prototypes/amends/amend/amend.component';
import { TasksComponent } from './views/prototypes/tasks/tasks.component';
import { TaskComponent } from './views/prototypes/tasks/task/task.component';

import { ProjectsComponent } from './views/prototypes/projects/projects.component';
import { ProjectComponent } from './views/prototypes/project/project.component';
import { CreativeComponent } from './views/prototypes/creative/creative.component';
import { AssetsComponent } from './views/prototypes/assets/assets.component';
import { AssetComponent } from './views/prototypes/assets/asset/asset.component';
import { DndDirective } from './directives/dnd.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { OrderableDirective } from './directives/orderable.directive';
import { SafePipe } from './pipes/safe.pipe';
import { ImageComponent } from './asset/image/image.component';
import { CaptionComponent } from './asset/caption/caption.component';
import { VideoComponent } from './asset/video/video.component';
import { AudioComponent } from './asset/audio/audio.component';
import { PdfComponent } from './asset/pdf/pdf.component';
import { IframeComponent } from './asset/iframe/iframe.component';
import { HtmlBannerComponent } from './asset/html-banner/html-banner.component';
import { EmailComponent } from './asset/email/email.component';
import { SizmekComponent } from './asset/sizmek/sizmek.component';
import { DoubleclickComponent } from './asset/doubleclick/doubleclick.component';
import { FlashtalkingComponent } from './asset/flashtalking/flashtalking.component';
import { TaskActionComponent } from './views/prototypes/tasks/task/task-action/task-action.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule, OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { AdminViewComponent } from './views/admin-view/admin-view.component';
import { GridComponent } from './views/grid/grid.component';
import { PreviewComponent } from './views/grid/preview/preview.component';
import { NewCreativeDialogComponent } from './dialogs/new-creative-dialog/new-creative-dialog.component';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';
import { TooltipComponent, TooltipContainerDirective } from './views/grid/tooltip/tooltip.component';
import { TooltipDirective } from './views/grid/tooltip/tooltip.directive';
import { InfoComponent } from './views/grid/info/info/info.component';
import { BreadcrumbsComponent } from './common/header/breadcrumbs/breadcrumbs.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { NewProjectDialogComponent } from './dialogs/new-project-dialog/new-project-dialog.component';
import { AccountComponent } from './views/account/account.component';
import { NewWorkgroupDialogComponent } from './dialogs/new-workgroup-dialog/new-workgroup-dialog.component';
import { GenericDialogComponent } from './dialogs/generic-dialog/generic-dialog.component';
import { WorkgroupComponent } from './views/prototypes/workgroup/workgroup.component';
import { PagenotfoundComponent } from './views/pagenotfound/pagenotfound.component';
import { GenericlistComponent } from './components/genericlist/genericlist.component';
import { NotificationsComponent } from './dialogs/notifications/notifications.component';
import { ThreedComponent } from './asset/threed/threed.component';
import { SortPipe } from './pipes/sort.pipe';
import { AdminUsersComponent } from './views/admin-users/admin-users.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { HtmlLinkComponent } from './asset/html-link/html-link.component';
import { EmptyComponent } from './asset/empty/empty.component';
import { FileComponent } from './asset/file/file.component';
import { EgnyteComponent } from './asset/egnyte/egnyte.component';
import { DeliveryComponent } from './views/delivery/delivery.component';
import { UploadsComponent } from './common/uploads/uploads.component';
import { ProjectTeamComponent } from './views/project-team/project-team.component';
import { CopyCreativeDialogComponent } from './dialogs/copy-creative-dialog/copy-creative-dialog.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { ColourLabelComponent } from './components/widgets/colour-label/colour-label.component';
import { FlagComponent } from './components/widgets/flag/flag.component';
import { CreativeProductionComponent } from './components/widgets/creative-production/creative-production.component';
import { CreativeApprovalGroupsComponent } from './components/widgets/creative-approval-groups/creative-approval-groups.component';
import { CreativeChainComponent } from './components/widgets/creative-chain/creative-chain.component';
import { ActiveUsersComponent } from './components/widgets/active-users/active-users.component';
import { RemoveApproverComponent } from './dialogs/remove-approver/remove-approver.component';
import { DialogChooseGroup, DialogNewGroup, ProjectWorkflowsComponent } from './views/project-workflows/project-workflows.component';
import { RemoveProductionComponent } from './dialogs/remove-production/remove-production.component';
import { WorkflowWidgetComponent } from './components/widgets/workflow-widget/workflow-widget.component';
import { RecordAudioWidgetComponent } from './components/widgets/record-audio-widget/record-audio-widget.component';
import { RecordScreenWidgetComponent } from './components/widgets/record-screen-widget/record-screen-widget.component';
import { CropWidgetComponent } from './components/widgets/crop-widget/crop-widget.component';
import { AudioVisualizeWidgetComponent } from './components/widgets/audio-visualize-widget/audio-visualize-widget.component';
import { CanvasComponent } from './asset/pdf/canvas/canvas.component';
import { BaseAssetComponent } from './asset/baseAsset.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { BaseComponent } from './components/base-component/base-component.component';
import { PackagesComponent } from './components/packages/packages.component';
import { PauseCreativeWidgetComponent } from './components/widgets/pause-creative-widget/pause-creative-widget.component';
import { CreativeViewerComponent } from './views/creative/creative-viewer/creative-viewer.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { PackageSaveValidationDialogComponent } from './dialogs/package-save-validation-dialog/package-save-validation-dialog.component';
import { TrimWidgetComponent } from './components/widgets/trim-widget/trim-widget.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { ToStringPipe } from './pipes/toString.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { HelpComponent } from './components/help/help.component';
import { ChatComponent } from './views/chat/chat.component';
import { ProjectCloneComponent } from './views/prototypes/project/project-clone/project-clone.component';
import { RemoveWorkgroupUserComponent } from './dialogs/remove-workgroup-user/remove-workgroup-user.component';
import { RemoveProjectUserComponent } from './dialogs/remove-project-user/remove-project-user.component';
import { RemoveWorkflowUserComponent } from './dialogs/remove-workflow-user/remove-workflow-user.component';
import { AdminAccountsComponent } from './views/admin-accounts/admin-accounts.component';
import { ProjectHomeComponent } from './views/project-home/project-home.component';
import { PasswordPairComponent } from './components/password-pair/password-pair.component';
import { ReferenceComponent } from './asset/reference/reference.component';
import { NoRemoveProjectadminComponent } from './dialogs/no-remove-projectadmin/no-remove-projectadmin.component';
import { TranscoderComponent } from './views/transcoder/transcoder.component';
import { DetailedAssetListComponent } from './dialogs/detailed-asset-list/detailed-asset-list.component';
import { AssetListExportComponent } from './dialogs/asset-list-export/asset-list-export.component';
import { AssetListImportComponent } from './dialogs/asset-list-import/asset-list-import.component';
import { PercentagePipe } from './pipes/percentage.pipe';
import { MarkerComponent } from './components/marker/marker.component';
import { AdminJobsComponent } from './views/admin-jobs/admin-jobs.component';
import { AdminStatsComponent } from './views/admin-stats/admin-stats.component';
import { AdminWebsocketsComponent } from './views/admin-websockets/admin-websockets.component';
import { EventLogComponent } from './components/widgets/event-log/event-log.component';
import { DiscussionComponent } from './components/widgets/chat/discussion.component';

@NgModule({
	declarations: [
		AppComponent,
		ObjNgForPipe,
		FilterPipe,
		HasFlagsPipe,
		FilterKeyPipe,
		FilterUniquePipe,
		TrackByPropPipe,
		LoginComponent,
		LogoutComponent,
		DashboardComponent,
		SignupValidateComponent,
		PreloaderComponent,
		FooterComponent,
		HeaderComponent,
		ForgotPasswordComponent,
		ProjectListComponent,
		ProjectDetailComponent,
		SettingsSystemComponent,
		SettingsUserComponent,
		SettingsProjectComponent,
		ResetPasswordComponent,
		AlertDialogComponent,
		ConfirmDialogComponent,
		SnackbarMessageComponent,
		SnackbarWithCloseComponent,
		UserProfileComponent,
		ManageUsersComponent,
		ManageProjectsComponent,
		ManageAccountsComponent,
		ManageWorkgroupsComponent,
		ManageFoldersComponent,
		CreativeViewComponent,
		ProjectTreeComponent,
		AssignUsersDialogComponent,
		ImageUploaderComponent,
		FileUploaderComponent,
		AddContainerDialogComponent,
		AssetViewComponent,
		AssetEmbedComponent,
		EditAssetDialogComponent,
		EditContainerDialogComponent,
		AssetSpecificationsComponent,
		ControlBarViewComponent,
		SettingsButtonViewComponent,
		BreadcrumbsViewComponent,
		HamburgerComponent,
		ToggleSwitchComponent,
		UploadAssetDialogComponent,
		NewAssetDialogComponent,
		CreativePropertiesComponent,
		MyProjectsComponent,
		MyTasksComponent,
		MyNotificationsComponent,
//		AmendViewComponent,
		AmendRequestViewComponent,
		PrototypesComponent,
//		AmendsComponent,
		AmendComponent,
		TasksComponent,
		TaskComponent,
		ProjectsComponent,
		ProjectComponent,
		CreativeComponent,
		AssetsComponent,
		AssetComponent,
		DndDirective,
		DraggableDirective,
		ResizeObserverDirective,
		OrderableDirective,
		SafePipe,
		BaseAssetComponent,
		ImageComponent,
		CaptionComponent,
		VideoComponent,
		AudioComponent,
		PdfComponent,
		IframeComponent,
		HtmlBannerComponent,
		EmailComponent,
		HtmlLinkComponent,
		EmptyComponent,
		SizmekComponent,
		DoubleclickComponent,
		FlashtalkingComponent,
		TaskActionComponent,
		AdminViewComponent,
		GridComponent,
		PreviewComponent,
		NewCreativeDialogComponent,
		AutocompleteOffDirective,
		TooltipComponent,
		TooltipContainerDirective,
		TooltipDirective,
		InfoComponent,
		BreadcrumbsComponent,
		ClickStopPropagationDirective,
		NewProjectDialogComponent,
		AccountComponent,
		NewWorkgroupDialogComponent,
		GenericDialogComponent,
		WorkgroupComponent,
		PagenotfoundComponent,
		GenericlistComponent,
		NotificationsComponent,
		ThreedComponent,
		SortPipe,
		AdminUsersComponent,
		FileComponent,
		EgnyteComponent,
		DeliveryComponent,
		UploadsComponent,
		ProjectTeamComponent,
		CopyCreativeDialogComponent,
		CallbackPipe,
		ColourLabelComponent,
		FlagComponent,
		CreativeProductionComponent,
		CreativeApprovalGroupsComponent,
		CreativeChainComponent,
		ActiveUsersComponent,
  RemoveApproverComponent,
  ProjectWorkflowsComponent,
  RemoveProductionComponent,
  WorkflowWidgetComponent,
  DialogChooseGroup,
  DialogNewGroup,
  RecordAudioWidgetComponent,
  RecordScreenWidgetComponent,
  CropWidgetComponent,
  AudioVisualizeWidgetComponent,
  CanvasComponent,
  DateAgoPipe,
  BaseComponent,
  PackagesComponent,
  PauseCreativeWidgetComponent,
  CreativeViewerComponent,
  PackageSaveValidationDialogComponent,
  TrimWidgetComponent,
  WarningMessageComponent,
  FormatBytesPipe,
  ToStringPipe,
  FileNamePipe,
  PercentagePipe,
  SecondsToTimePipe,
  HelpComponent,
  ChatComponent,
  DiscussionComponent,
  ProjectCloneComponent,
  RemoveWorkgroupUserComponent,
  RemoveProjectUserComponent,
  RemoveWorkflowUserComponent,
  AdminAccountsComponent,
  ProjectHomeComponent,
  PasswordPairComponent,
  ReferenceComponent,
  NoRemoveProjectadminComponent,
  TranscoderComponent,
  DetailedAssetListComponent,
  AssetListExportComponent,
  AssetListImportComponent,
  MarkerComponent,
  AdminJobsComponent,
  AdminStatsComponent,
  AdminWebsocketsComponent,
  EventLogComponent,
	],
	imports: [
		BrowserAnimationsModule,
		MaterialModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		DragDropModule,
		OverlayModule,
		PortalModule,
		ColorChromeModule
	],
	providers: [
		MaterialModule,
		AuthService,
		AuthGuardService,
		CanDeactivateGuard,
		ApiService,
		StringsService,
		HelpService,
		DialogService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: OverlayContainer,
			useClass: FullscreenOverlayContainer
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule { }