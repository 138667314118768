import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProject } from '../models/project.model';
import { IUser } from '../models/user.model';
import { Workgroup } from '../models/workgroup.model';
import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class WorkgroupService extends OVBaseService<Workgroup, string> {
	
	private _usersSubject = new BehaviorSubject<IUser[]>(null);
	public users = this._usersSubject.asObservable();

	constructor(protected http: HttpClient) {
		super(http, 'workgroup');
	}
	loadUsers(uuid :string)
	{
		this.getUsers(uuid).pipe(
			map(response => {
				response.data = response.data.sort((a: IUser, b: IUser) => a.name.localeCompare(b.name, undefined, {numeric:true}))
				return response;
			})
		).subscribe(res => this._usersSubject.next(res.data));
	}
	getUsers(uuid: string):Observable<any>{
		return this._get(`${this.base}/${uuid}/users`);
	}
	getUserPermissions(uuid: string, user_uuid:string):Observable<any>{
		return this._get(`${this.base}/${uuid}/user/${user_uuid}/permissions`);
	}
	grantUserAccess(uuid: string, user_uuid:string):Observable<any>{
		return this._get(`${this.base}/${uuid}/user/${user_uuid}/grantAccess`);
	}
	removeUserAccess(uuid: string, user_uuid:string):Observable<any>{
		return this._get(`${this.base}/${uuid}/user/${user_uuid}/removeAccess`);
	}
	project(uuid: string, project:IProject):Observable<any> {
		return this._post(`${this.base}/${uuid}/project`, project);
	}
	private _projects:Subject<IProject[]> = new Subject<IProject[]>();
	public projects$:Observable<IProject[]> = this._projects.asObservable();
	private _projectsLoadingSubject = new BehaviorSubject<boolean>(false);
	public projectsLoading$:Observable<boolean> = this._projectsLoadingSubject.asObservable();
	loadProjects(uuid: string):Subscription {
		this._projectsLoadingSubject.next(true);
		return this._get(`${this.base}/${uuid}/projects`).pipe().subscribe(
			res => this._projects.next(res.data as IProject[]),
			() => null, 
			() => this._projectsLoadingSubject.next(false)
		);
	}


}
