<div>
    

    <ul class="breadcrumb">
        <li>
            <a [routerLink]="'/'">{{(appUserService.account$ | async)?.name}}</a>
            <span class="dropdown" [matMenuTriggerFor]="menu">
                <mat-icon>arrow_drop_down</mat-icon>
            </span>
              <mat-menu #menu="matMenu">
				<ng-container *ngFor="let account of (this.appUserService.appUserObservable$ | async)?.accounts">
					<button	*ngIf="(appUser.email.indexOf('+us') == -1 || (appUser.email.indexOf('+us') != -1 && account.name == 'Five by Five'))" mat-menu-item (click)="changeAccount(account)">
					  <span>{{account.name}}</span>
					</button>
				</ng-container>
              </mat-menu>
        </li>
        <ng-container *ngIf="crumbs">

            <li *ngFor="let crumb of crumbs; index as index">
                <div [ngClass]="{'crumbAlign':crumb.creativeStats>0}"><a [routerLink]="crumb.link" [fragment]="crumb.fragment">{{crumb.name}}<span *ngIf="crumb.creativeStats"> ({{crumb.creativeStats}})</span></a></div>
                <ng-container *ngIf="crumb.neighbours?.length > 1">
                    <!--<button mat-icon-button >-->
                        <span class="dropdown">
                            <mat-icon [matMenuTriggerFor]="menu2">arrow_drop_down</mat-icon>
                        </span>
                      <!--</button>-->
                      <mat-menu #menu2="matMenu">
                        <ng-container *ngFor="let neighbour of crumb.neighbours">
                            <ng-container *ngIf="!neighbour.creatives">
                                <div [matTooltip]="neighbour.name.length > 30 ? neighbour.name : ''" [matTooltipPosition]="'left'">
                                    <button 
                                    mat-menu-item      
                                    [disabled]="neighbour.uuid == crumb.uuid"
                                    (click)="neighbourClick(crumb, neighbour)"
                                    >
                                        <span>{{neighbour.name}}</span>
                                    </button>
                                </div>
                            </ng-container>
                                <ng-container *ngIf="neighbour.creatives">
                                    <div [matTooltip]="neighbour.name.length > 30 ? neighbour.name : ''"  [matTooltipPosition]="'left'">
                                        <button 
                                        mat-menu-item
                                        [matMenuTriggerFor]="menu3"
                                        >
                                            <span>{{neighbour.name}}</span>
                                        </button>
                                    </div>
                                <mat-menu #menu3="matMenu">
                                    <div [matTooltip]="neighbour.name.length > 30 ? neighbour.name : ''"  [matTooltipPosition]="'right'" *ngFor="let neighbour of neighbour.creatives">
                                        <button 
                                            mat-menu-item
                                            [disabled]="neighbour.uuid == crumb.uuid"
                                            (click)="neighbourClick(crumb, neighbour)"
                                        >
                                            <span>{{neighbour.name}}</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </ng-container>



                        </ng-container>

                      </mat-menu>
                </ng-container>
            </li>
        </ng-container>
    </ul>
</div>
