import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';
import { IUpload, UploadState, UploadsService } from 'src/app/api/uploads.service';
import { BaseAssetComponent } from 'src/app/asset/baseAsset.component';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { DraggableEvent } from 'src/app/directives/draggable.directive';
import { Asset, AssetFlag, AssetType, AssetVO, IAsset } from 'src/app/models/asset.model';
import { IMarker, MarkerOption, MarkerVO } from 'src/app/models/marker.model';
import { ITask, Task, TaskType } from 'src/app/models/task.model';
import { AppUserService } from 'src/app/services/app-user.service';
import { getRect } from 'src/app/utils-html';
import { Point } from 'src/app/utils/Point';


@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent extends BaseComponent implements OnInit, OnChanges {

  public AssetFlag = AssetFlag;
	@Input() assetVO : AssetVO;
	@Input() assets : AssetVO[];
	@Input() index : number;
	@Input() selected : boolean;
	@Input() assetEditMode : boolean;  
	@Input() tasks : ITask[];
	//@Input() selectedTask : ITask;
	@Input() showMarkup : boolean;
	@Input() layout : string;
	@Input() galleryMode : boolean;
	@Input() previewMode : boolean;
  @Input() markerVOs$:Observable<MarkerVO[]>;
  //protected markerVOs:MarkerVO[];
  
	@Output() markerMouseEvent:EventEmitter<{task:ITask, event:string}> = new EventEmitter<{task:ITask, event:string}>();
	@ViewChild('ass')
  set _baseAsset(ass: BaseAssetComponent) {
    // only changes whehn the asset component changes (will not change for 2 different assets if they are the same type!)
    this.baseAsset = ass;
    this.baseAsset.galleryMode = this.galleryMode;    
    setTimeout(() => {
      this.onResize();
    }, 0);
  }
  baseAsset:BaseAssetComponent;
	@ViewChild('markup') markup : ElementRef<HTMLElement>;
	@ViewChild('markupItem') markupItem : ElementRef<HTMLElement>;
  
  @Output() layoutChange:EventEmitter<void> = new EventEmitter<void>();
  markerOver:boolean;
  fileOver:boolean;
  
  selectedAsset:IAsset;
  galleryIndex:number = -1;
  upload:IUpload;
  

  version = 1;// TODO remove this hack
  constructor(  public element: ElementRef,
                private userService:AppUserService,
                private uploadsService:UploadsService,
                private readonly viewportRuler: ViewportRuler) {
          super();
    this.sub = this.viewportRuler.change(50).subscribe((e) =>
          {
            this.onResize(e);
          })
          
  }
  scale:number = 1;
  getStyle()
  {
    let asset = this.assetVO.asset;
    if(this.galleryMode && (asset.type == AssetType.PDF || asset.type == AssetType.EMAIL ))
    {
      let rect = this.getContainerRect();
      return {
        'width.px' : rect.width,
        'height.px' : rect.height,
      };
     // return {width:'100%', height:'100%'};
    }
    if(asset && this.baseAsset)
    {
		  let w = asset.width * this.baseAsset.scale;
		  let h = asset.height * this.baseAsset.scale;
      // gallery mode is also set to true for asset info preview mode
      if(this.previewMode){
        return {
          //'postion' : 'absolute',
          'width':'100%',
          'height':'100%',
          //'max-width.px' : asset.width,
          //'max-height.px' : asset.height,
        }
      }
      return{
        'postion' : 'absolute',
        'width.px' : w,
        'height.px' : h,
      };
    }
    return {}
  }
 notNull<T extends {}>(x: T | null): x is T {
    return x !== null;
  }
  async assetCallback(event:any)
  {
    if(event?.type == "marker_update")
      {
        //console.log("marker update callback", this.markerVOs$);
        // https://www.reddit.com/r/Angular2/comments/wzlgix/rxjs_optional_null_handling/
        this.markerVOs$.pipe(
          filter(this.notNull),
          take(1)
        ).subscribe(markerVOS => {
          let targets = markerVOS.filter(markerVO => markerVO.marker.asset_uuid == this.assetVO.asset.uuid);
          targets.forEach(markerVO => this.baseAsset.updateMarker2(markerVO));
        });
        
      }
    if(event?.type == "marker")
      {
        //console.log("marker callback", this.markerVOs$);
      }
  }
  getContainerRect()
  {
    return this.element.nativeElement?.parentElement?.parentElement.getBoundingClientRect();
  }
  onAssetResized(entry:ResizeObserverEntry)
  {
    // TODO this could work as an alternative if the div is width:100% height:100% over pixel sizes and the app-asset is: width: 100%; overflow: hidden;
    //console.log("asset resize!!", entry.contentRect.width.toFixed(0), entry.contentRect.height.toFixed(0));
    
  }
  onResize(event = null) {  
    let asset = this.assetVO.asset;
    let rect = this.getContainerRect();
    if(this.galleryMode && this.baseAsset && rect)
    {
      //console.log("onresize!!", rect.width.toFixed(0), rect.height.toFixed(0));
      //let rect = this.elRef.nativeElement.getBoundingClientRect();
      let scaleX = Math.min(rect.width / asset.width, 1);
      let scaleY = Math.min(rect.height / asset.height, 1);
      this.scale = Math.min(scaleX, scaleY);
      this.baseAsset.scale = this.scale;
      this.notifyLayoutChange();
    }else if(this.scale != 1)
    {
      this.scale = 1;
      this.baseAsset.scale = this.scale;
      this.notifyLayoutChange();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.galleryMode && this.baseAsset)
    {
      this.baseAsset.galleryMode = this.galleryMode;
      this.onResize();
    }
    if(changes.asset)
    {
      setTimeout(() => {
        this.onResize();
      }, 0);
    }
  }
  notifyLayoutChange()
  {
    //this.layoutChange.emit();
    setTimeout(() => this.layoutChange.emit(), 16);
  }
  ngOnInit(): void {

    // try and find an upload for me
    this.sub = this.uploadsService.uploads$.subscribe((uploads:IUpload[]) => {
      let found = false;
      uploads.forEach(upload => {
        if((upload.state == UploadState.READY || upload.state == UploadState.STARTED ) && (upload.data?.asset == this.assetVO.asset.uuid))
        {
          this.upload = upload;
          found = true;
        }
      })
      if(!found) this.upload = null;
		})

    // debugging marker vo changes
    /*this.sub = this.markerVOs$.subscribe(markerVOs => 
      {
        console.log("asset markers changed!!!");
      });*/
  }
  markerFilter = (markerVO:MarkerVO)=>{
    return  markerVO.marker.asset_uuid == this.assetVO?.asset.uuid;
  };
  ngAfterViewInit() {
    // viewChildren is set
    this.baseAsset.galleryMode = this.galleryMode;
    this.sub = this.baseAsset.layoutChange.subscribe(e =>{
      //console.log("passing on event", e);      
      this.layoutChange.emit(e);
    });
    this.onResize();
  }

  /**
   * Seek this asset to a point in time if possible (audio and video assets only)
   * @param time 
   */
  seek(time: number)
  {
		if(this.baseAsset)  this.baseAsset.seek(time);
	}
  /**
   * Is a global point over this asset
   * @param x a global x coordinate
   * @param y a global y coordinate
   * @param out 
   * @returns a number
   */
  isPointOver(x:number, y:number, out:{x:number, y:number} = null):number
  {
    if(!this.assetVO.asset.visible) return 0;
    if(!this.element.nativeElement) return 0;
    let rect = this.element.nativeElement.getBoundingClientRect();
    let over;
    if(x < rect.x || x > rect.right) over = 0;
    else if(y < rect.y || y > rect.bottom) over = 0;
    else over = this.canMarkerDrop(x, y, true);
    this.markerOver = over != 0;

    // if out sent in then populate with local coordinate
    if(out)
    {
       let local = this.globalToLocal(new Point(x, y));
       out.x = local.x;
       out.y = local.y;
    }
    return over;
  }
  /**
   * Returns weather a marker can be dropped at this location on the asset
   * @param x 
   * @param y 
   * @returns 0 if not 1 if can
   */
  canMarkerDrop(x:number, y:number, noSideEffect:boolean = false):number
  {
    // could this be combined?
    return this.baseAsset.canDropMarker(x, y, noSideEffect);
  }
  /**
   * Apply any additional rendering info required to the marker
   * (i.e. additional offsets based on a scrolled pdf)
   * @param marker
   */
  getMarkerRenderInfo(marker:MarkerVO)
  {
    if(!this.baseAsset) return;
    // could we detect a change in here and trigger a re-render? 
    this.baseAsset.getMarkerRenderInfo(marker);   
  }

  markerDrop(marker:IMarker, x:number, y:number)
  {
    marker.asset_uuid = this.assetVO.asset.uuid;

    let modified:boolean = this.baseAsset.markerDrop(marker,x, y)
    //console.log("marker dropped", modified);
    
    if(!modified)
    {
      let rect = this.element.nativeElement.getBoundingClientRect();
      marker.x0 = (x - rect.left) / this.scale;
      marker.y0 = (y - rect.top) / this.scale;
    }
  }
  scrollMarkerIntoView(marker: IMarker) {
    // first scroll self into view
    this.element.nativeElement.scrollIntoView({behavior:'smooth', block:'nearest'});
    // markup may not be rendered but we still need to scroll to it
		if(true || this.markupItem){ 
      //this.markupItem.nativeElement.scrollIntoView({behavior:'smooth'});
      this.baseAsset.scrollMarkupIntoView(marker);
    }
	}
  /**
   * Get available amend options for this asset based on the location supplied
   * @param x 
   * @param y 
   * @returns 
   */
  getAmendOptions(x:number, y:number, specific:boolean):Promise<MarkerOption[]> | MarkerOption[]
  {
		return this.baseAsset.getAmendOptions(x, y, specific);
	}
  localToGlobal(point:Point):Point
  {
    let rect = getRect(this.element.nativeElement);
    return new Point(rect.x + (point.x / this.scale), rect.y + (point.y / this.scale));
  }
  globalToLocal(point:Point):Point
  {
    let baseResult = this.baseAsset.globalToLocal(point);
    if(baseResult) return baseResult;
    let rect = getRect(this.element.nativeElement);
    return new Point((point.x - rect.left) / this.scale, (point.y - rect.top) / this.scale);
  }
  onAssetSelected(event:MouseEvent)
  {

  }
  onSnappingUpdate(e: object){
		//this.snapping = e;	// copy props not object
	}

  /**
   * Does this asset have a marker
   * @param asset 
   * @returns 
   */
   hasMarker(asset:IAsset)
   {
     if(!asset.uuid) return false;  // new asset therfore no markers
     if(!this.tasks || this.tasks.length == 0) return false;
     for (let i = 0; i < this.tasks.length; i++) {
       const task = this.tasks[i];
 
       // single marker
       if(task.marker?.asset_uuid === asset.uuid) return true;
 
       // multiple marker
       if(task.markers?.length){
         for (let j = 0; j < task.markers.length; j++) {
           const marker = task.markers[j];
           if(marker.asset_uuid === asset.uuid) return true;
         }
       }
     }
     return false;
   }
   /**
	 * Finds any markup that is assigned against this asset that matches the current version
	 * @param asset 
	 * @returns 
	 */
	getMarkers(asset:IAsset):any[]
	{
    throw new Error("No longer used.. maybe");
//    return;
//		let markers:Array<any> = [];
//      this.tasks?.forEach(task => {
//      // only amend tasks do not have markup
//      if(task.type != TaskType.AMEND) return;
//      // skip if no marker
//      if(!task.marker)  return;
//      // for amend markup we only show it for the original version is was created on (or if new/unsaved)
//      if(task.marker.uuid && this.assetVO.asset.version != task.creative_version) return;
//
//      //single marker
//      if(task.marker && task.marker.asset_uuid == asset.uuid){
//        // markers.push({task, marker:task.marker});
//        let marker = this.getMarkerRenderInfo(task.marker);
//        let colour = Task.getColour(task, this.userService.appUser.uuid);
//        if(marker.visible !== false)  markers.push({task, marker, colour});
//      }
//
//      // multiple markers
//      task.markers?.forEach(marker => {
//        if(marker.asset_uuid == asset.uuid && task.creative_version == this.version){
//          markers.push({task, marker:this.getMarkerRenderInfo(marker)});
//        }
//      })
//    })
//
//    
//    // TODO fix this hackup
//    if(this.markup){
//      // this stops markup overlapping the scrollbars in pdfs
//      this.markup.nativeElement.style.right = this.baseAsset.getScrollOffset();
//    }
//		return markers;
	}
  getMakerTransform(markerVO:MarkerVO)
  {
    let x = (markerVO.marker.x0 + (markerVO.offsetX || 0));
    let y = (markerVO.marker.y0 + (markerVO.offsetY || 0));
    return `translate(${x}px, ${y}px)`;
  }
  dragging = false;
  dragTarget = {x:0, y:0, startX:0, startY:0};
  dragStart;
  onMarkerMouseEnter(e:MouseEvent, task:ITask)
  {
    this.markerMouseEvent.emit({task, event:'enter'});
  }
  onMarkerMouseLeave(e:MouseEvent, task:ITask)
  {
    this.markerMouseEvent.emit({task, event:'leave'});
  }
  onMarkerClick(e:MouseEvent, task:ITask)
	{
		//this.selectedTask = task;
    // bring this task into view
    //console.log("marker clicked");
    this.markerMouseEvent.emit({task, event:'click'});
	}
  /**
   * Handle marker drag update events
   * @param e 
   */
  onDragUpdate(e:DraggableEvent)
  {
    if(!e.metadata.markerVO.editable) return;
    
    let globalX = e.data.event.clientX;
    let globalY = e.data.event.clientY;
    if(e.type == DraggableEvent.START)
    {
      this.dragTarget.x = 0;
      this.dragTarget.y = 0;
      let localStart = this.globalToLocal(new Point(globalX, globalY));
      this.dragStart = {
        x:localStart.x - e.metadata.markerVO.marker.x0,
        y:localStart.y - e.metadata.markerVO.marker.y0
      };
      this.markerMouseEvent.emit({task:e.metadata.markerVO.task, event:'click'});
    }
    if(e.type == DraggableEvent.DRAG)
    {
      // get previous transform
		  let transform:string = e.metadata.markerRef.style.transform || '';
      if(transform) transform = transform.replace(/translate3d\(.*\)/, '');
      e.metadata.markerRef.style.transform = transform + " translate3d("+this.dragTarget.x+"px,"+this.dragTarget.y+"px,0px)";

      e.metadata.markerVO.marker.drag = this.dragTarget;

      e.metadata.markerVO.markChanged();
    }
    if(e.type == DraggableEvent.STOP)
    {
        // clear the 3d transform on drop
        let transform:string = e.metadata.markerRef.style.transform || '';
        if(transform) transform = transform.replace(/translate3d\(.*\)/, '');
        e.metadata.markerRef.style.transform = transform;

      //this.markerDrop(e.metadata.markerVO.marker, e.metadata.markerVO.marker.x0, e.metadata.markerVO.marker.y0);
      //e.metadata.markerVO.marker.x0 += this.dragTarget.x;
      //e.metadata.markerVO.marker.y0 += this.dragTarget.y;
      // x and y need to be global
      let x = e.metadata.markerVO.marker.x0 + (e.metadata.markerVO.marker.offsetX || 0);
      let y = e.metadata.markerVO.marker.y0 + (e.metadata.markerVO.marker.offsetY || 0);
      x += this.dragTarget.x;
      y += this.dragTarget.y;
      let p0 = this.localToGlobal(new Point(0,0));

      // must apply scale to prevent wee jumps
      globalX -= this.dragStart.x * this.scale;
      globalY -= this.dragStart.y * this.scale;
      
      let p = this.localToGlobal(new Point(x, y));
      //this.markerDrop(e.metadata.markerVO.marker, p.x + this.dragTarget.x, p.y + this.dragTarget.y);

      e.metadata.markerVO.marker.drag = null;
      this.dragTarget.x = 0;
      this.dragTarget.y = 0;

      

      
      // was the marker draggout outside of this asset? 
      let canDrop = this.baseAsset.canDropMarker(globalX, globalY);
      if(!canDrop)
      {
        // clear the marker
        //e.metadata.markerVO.task.marker = null;
        this.markerMouseEvent.emit({task:e.metadata.markerVO.task, event:'clear'});
        return;
      }
      
      if(this.assetVO.asset.type != AssetType.PDF && this.baseAsset.isPointOutside(x, y))
      {
        // clear the marker
        //e.metadata.markerVO.task.marker = null;
        this.markerMouseEvent.emit({task:e.metadata.markerVO.task, event:'clear'}); 
      }
      this.markerDrop(e.metadata.markerVO.marker, globalX, globalY);


      //console.log("marker dragged", x.toFixed(2), y.toFixed(2), p, e, this);
      e.metadata.markerVO.markChanged();
    }
    // trigger a connection update..  
    this.layoutChange.emit();
  }

  // store value
	onMarkerDragStarted(e:CdkDragStart)
	{
		//console.log("marker drag start", e);
    	this.dragging =true;
	}
	onMarkerDragMoved(e:CdkDragMove, marker)
	{
		//console.log("marker drag move", e);
    
    //marker.x0 += e.delta.x;
		//marker.y0 += e.delta.y;
    //if(marker)this.markerDrop(marker, e.pointerPosition.x, e.pointerPosition.y);
	}
	onMarkerDragged(e:CdkDragEnd, marker, task)
	{
		//console.log("marker dragged", e);
    	this.dragging = false;
		/*
		let position = e.source.getFreeDragPosition();
		marker.x0 += position.x;
		marker.y0 += position.y;
		console.log(position);*/
		marker.x0 += e.distance.x;
		marker.y0 += e.distance.y;
	}
  	markerTrackBy(index, markerVO:any) {
		return markerVO.marker.id;
	}
}
