<mat-card class="add_workgroup_form">
	<h3>Add Workgroup</h3>
	<form class="login-form">
		<div class="inputs">
			<mat-form-field>
				<input matInput id="name" class="login_input" placeholder="Name" value="">
			</mat-form-field>
		</div>
		<div class="button_wrapper">
			<button (click)="addWorkgroup()" mat-raised-button>Add Workgroup</button>
		</div>
	</form>
</mat-card>
<mat-card class="all_workgroups" *ngIf="workgroups.length > 0">
	<h3>Workgroups</h3>
	<mat-list role="list">
		<mat-list-item *ngFor="let workgroup of workgroups; index as i" role="listitem" class="list-item">
			<div class="workgroup" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
				<p class="name">{{workgroup.name}}</p>
			</div>
		</mat-list-item>
	</mat-list>
</mat-card>