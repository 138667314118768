<mat-tab-group>
  <mat-tab label="User roles">
	  <div class="userPermissions">
		<h4>Project roles and access</h4>
		<div *ngIf="users" class="users">
			<!-- role | user | toggle | channel -->
			<table>
				<thead>
				  <tr>
					  <th><!--Role / User -->
						  <div class="sticky">
							  <div>
  
							  </div>
							  <div class="all">
								  all
							  </div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels">{{channel.name}}</td>
				  </tr>
				</thead>
			  <tbody>
				  <!-- Admin rows -->
				  <tr class="role">
					  <th>
						  <div class="sticky">
							  <div>
								  <span>Admin</span>
								  <button mat-icon-button [matMenuTriggerFor]="menuAdmin"><mat-icon>add_circle_outline</mat-icon></button>
								  <mat-menu #menuAdmin="matMenu">
									  <button *ngFor="let user of users | callback: isNotAdmin" (click)="setAdmin(user)" mat-menu-item>{{user.name}}</button>
								  </mat-menu>
							  </div>
							  <div class="all"></div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels"> <!-- could use colspan for empties-->
						  <!--empty-->			
					  </td>
				  </tr>
				  <tr *ngFor="let user of users | callback: isAdmin" class="user">
					  <th>
						  <div class="sticky">
							  <div class="userListItem">
								  <span>{{user.name}}</span>
								  <button mat-icon-button (click)="removeAdmin(user)"><mat-icon>remove_circle_outline</mat-icon></button>
							  </div>
							  <div class="all">
								  <mat-checkbox [disabled]="true" [checked]="true"></mat-checkbox>
							  </div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels">
						  <mat-checkbox [disabled]="true" [checked]="true"></mat-checkbox>
						  <!--
							  <mat-checkbox [checked]="hasAccessToChannel(user, channel)" (change)="toggleAccessToChannel($event, user,channel)"></mat-checkbox>
  
						  -->
					  </td>
				  </tr>
				  <!-- Production rows -->
				  <tr class="role">
					  <th>
						  <div class="sticky">
							  <div>
								  <span>Production</span>
								  <button mat-icon-button [matMenuTriggerFor]="menuProduction"><mat-icon>add_circle_outline</mat-icon></button>
								  <mat-menu #menuProduction="matMenu">
									  <button *ngFor="let user of users | callback: isNotProduction" (click)="setProduction(user)" mat-menu-item>{{user.name}}</button>
								  </mat-menu>
							  </div>
							  <div class="all"></div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels">
						  <!--empty-->			
					  </td>
				  </tr>
				  <tr *ngFor="let user of users | callback: isProduction" class="user">
					  <th>
						  <div class="sticky">
							  <div class="userListItem">
								  <span>{{user.name}}</span>
								  <button mat-icon-button (click)="removeProduction(user)"><mat-icon>remove_circle_outline</mat-icon></button>
							  </div>
							  <div class="all">
								  <mat-checkbox [checked]="hasAccessToAllChannels(user, 'production')" (change)="toggleAccessToAllChannels($event, user, 'production')"></mat-checkbox>
							  </div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels">
						  <mat-checkbox [checked]="hasAccessToChannel(user, channel, 'production')" (change)="toggleAccessToChannel($event, user, channel, 'production')"></mat-checkbox>
					  </td>
				  </tr>
				  <!-- Approver rows -->
				  <tr class="role">
					  <th>
						  <div class="sticky">
							  <div>
								  <span>Approvers</span>
								  <button mat-icon-button [matMenuTriggerFor]="menuApprover"><mat-icon>add_circle_outline</mat-icon></button>
								  <mat-menu #menuApprover="matMenu">
									  <button *ngFor="let user of users | callback: isNotApprover" (click)="setApprover(user)" mat-menu-item>{{user.name}}</button>
								  </mat-menu>
							  </div>
							  <div class="all"></div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels">
						  <!--empty-->			
					  </td>
				  </tr>
				  <tr *ngFor="let user of users | callback: isApprover" class="user">
					  <th>
						  <div class="sticky">
							  <div class="userListItem">
								  <span>{{user.name}}</span>
								  <button mat-icon-button (click)="removeApprover(user)"><mat-icon>remove_circle_outline</mat-icon></button>
							  </div>
							  <div class="all">
								  <mat-checkbox [checked]="hasAccessToAllChannels(user, 'approval')" (change)="toggleAccessToAllChannels($event, user, 'approval')"></mat-checkbox>
							  </div>
						  </div>
					  </th>
					  <td *ngFor="let channel of channels">
						  <mat-checkbox [checked]="hasAccessToChannel(user, channel, 'approval')" (change)="toggleAccessToChannel($event, user, channel, 'approval')"></mat-checkbox>
					  </td>
				  </tr>
			  </tbody>
			</table>
			<!--
		  <div class="roleRow">
			<div></div>
			<div>
			  <div class="userRow">
				<div>                
				</div>
				<div>
				  <span *ngFor="let channel of channels">{{channel.name}}</span>
				</div>
			  </div>
			</div>
			</div>
			<div class="roleRow">
			  <div>
				<h3>Project Admin:</h3>
			  </div>
			  <div>
				<button mat-icon-button [matMenuTriggerFor]="menuAdmin"><mat-icon>add</mat-icon></button>
				<mat-menu #menuAdmin="matMenu">
					<button *ngFor="let user of users | callback: isNotAdmin" (click)="setAdmin(user)" mat-menu-item>{{user.name}}</button>
				</mat-menu>
				<div *ngFor="let user of users | callback: isAdmin" class="userRow">
				  <div>
					<span>{{user.name}}</span><button mat-icon-button (click)="removeAdmin(user)"><mat-icon>remove</mat-icon></button>
				  </div>
				  <div>
					<mat-checkbox *ngFor="let channel of channels"></mat-checkbox>
				  </div>
				</div>
			  </div>
			</div>
			<div>
			  <h3>Production:</h3>
			  <button mat-icon-button [matMenuTriggerFor]="menuProduction"><mat-icon>add</mat-icon></button>
			  <mat-menu #menuProduction="matMenu">
				  <button *ngFor="let user of users | callback: isNotProduction" (click)="setProduction(user)" mat-menu-item>{{user.name}}</button>
			  </mat-menu>
			  <div *ngFor="let user of users | callback: isProduction">
				<span>{{user.name}}</span><button mat-icon-button (click)="removeProduction(user)"><mat-icon>remove</mat-icon></button>
			  </div>
			</div>
			<div>
			  <h3>Approvers:</h3>
			  <button mat-icon-button [matMenuTriggerFor]="menuApprover"><mat-icon>add</mat-icon></button>
			  <mat-menu #menuApprover="matMenu">
				  <button *ngFor="let user of users | callback: isNotApprover" (click)="setApprover(user)" mat-menu-item>{{user.name}}</button>
			  </mat-menu>
			  <div *ngFor="let user of users | callback: isApprover">
				<span>{{user.name}}</span><button mat-icon-button (click)="removeApprover(user)"><mat-icon>remove</mat-icon></button>
			  </div>
			</div>
			<div>
			  <h3>View Only:</h3>
			  <button mat-icon-button><mat-icon>add</mat-icon></button>
			</div>
			-->
		</div>
	  </div>
  </mat-tab>
  <mat-tab label="Approval chains" *ngIf="false">
	<div class="chains">
		<div class="chains-header">
			<h4>Approval sequences</h4>
			<button (click)="chainAdd()" [matTooltip]="strings.getTooltip('btn_add_chain')" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>	
		</div>
		<mat-accordion *ngIf="this.projectService.approvalChains | async" class="chain">         
			<mat-expansion-panel *ngFor="let chain of this.projectService.approvalChains | async; trackBy:trackById;" (opened)="chainSelected(chain)">
			  <mat-expansion-panel-header>
				<mat-panel-title>
					{{chain.name}} - ({{chain.filter}})
				</mat-panel-title>
				<mat-panel-description>
					<button mat-icon-button (click)="chainEdit(chain, $event)"><mat-icon>edit</mat-icon></button>
					<button mat-icon-button (click)="chainDelete(chain, $event)"><mat-icon>delete</mat-icon></button>
				</mat-panel-description>
			  </mat-expansion-panel-header>
			  <!--content-->
			  <div>
				<form *ngIf="false">                 
				<mat-form-field>
					<mat-label>Format group filter</mat-label>
						<input matInput placeholder="filter..."
							#filterInput
							
							
							[value]="chain.filter"
						>
						<!--[matAutocomplete]="autoFilter"
							[formControl]="permissionCtrl"-->
							<!--
					<mat-autocomplete #autoFilter="matAutocomplete" (optionSelected)="test(chain, $event)">
						<mat-option *ngFor="let permission of filteredPermissions | async" [value]="permission">
							{{permission}}
						</mat-option>
					</mat-autocomplete>-->
				</mat-form-field>
				</form>
				<h4>Amend Validator</h4>
				<mat-form-field appearance="fill">
					<mat-label>Choose a validator</mat-label>
					<mat-select [(value)]="chainValidator" (selectionChange)="setUserValidator2($event, chain)">
					  <mat-option *ngIf="chain.validator.length" [value]="chain.validator[0]">Clear validator</mat-option>
					  <mat-option *ngFor="let user of projectAdminUsers; trackBy:trackByUUID" [disabled]="chain.validator?.length > 0 && (chain.validator[0].uuid == user.uuid)"  [value]="user">{{user.name}}</mat-option>
					</mat-select>
				  </mat-form-field>
				<table *ngIf="false">
				  <tbody>
					  <tr>
						  <th>Validator:</th>
						  <td>
							  <button mat-icon-button [matMenuTriggerFor]="menuValidator"><mat-icon>add_circle_outline</mat-icon></button>
							  <mat-menu #menuValidator="matMenu">
								  <button *ngFor="let user of users | callback: isNotValidator : chain" (click)="setUserValidator(user, chain)" mat-menu-item>{{user.name}}</button>
							  </mat-menu>
						  </td>
						  <td><!--toggle--></td>
						  <td *ngFor="let channel of channels"> <!-- could use colspan for empties-->
							  <!--empty-->			
						  </td>
					  </tr>
					  <tr *ngFor="let user of users | callback: isValidator : chain">
						  <th></th>
						  <td>
							  <div>
								  <span>{{user.name}}</span><button mat-icon-button (click)="removeUserValidator(user, chain)"><mat-icon>remove_circle_outline</mat-icon></button>
							  </div>
						  </td>
						  <td><!--toggle--></td>
						  <td *ngFor="let channel of channels">
							  <!--
								  <mat-checkbox [checked]="hasAccessToChannel(user, channel)" (change)="toggleAccessToChannel($event, user,channel)"></mat-checkbox>
	  
							  -->
						  </td>
					  </tr>
				  </tbody>
				</table>
				<div class="groups">
					<div class="groups-header">Groups</div>
					<div *ngIf="selectedChain?.approval_groups">
						<div *ngFor="let group of selectedChain.approval_groups; trackBy:trackById; index as i; first as isFirst; last as isLast">
							<div class="group">
								<div class="group-header">
									<span class="label">
										<span class="circle">{{i+1}}</span>
										<span class="grp">Group</span>
									</span>
								  <span class="title">
									  {{group.name}}
								  </span>
								  <span class="controls">
									  <button mat-icon-button (click)="decreaseGroupOrder(group)" [disabled]="isFirst"><mat-icon>keyboard_arrow_up</mat-icon></button>
									  <button mat-icon-button (click)="increaseGroupOrder(group)" [disabled]="isLast"><mat-icon>keyboard_arrow_down</mat-icon></button>
									  <button mat-icon-button (click)="editGroup(group)"><mat-icon>edit</mat-icon></button>
									  <button mat-icon-button (click)="deleteGroup(group)"><mat-icon>delete</mat-icon></button>
								  </span>
								</div>
								<!--content-->
								<div class="group-content">
								  <table *ngIf="projectApprovalUsers" class="users">
									  <thead>
										<tr>
											<th>
												<div class="role">
													<span>Approvers</span>
													<button mat-icon-button [matMenuTriggerFor]="menuGroup"><mat-icon>add_circle_outline</mat-icon></button>
													<mat-menu #menuGroup="matMenu">
														<button *ngFor="let user of projectApprovalUsers | callback: isNotInAnyGroup : chain" (click)="addUserToGroup(group, user)" mat-menu-item>{{user.name}}</button>
													</mat-menu>
												</div>	
											</th>
											<td>Validatee:</td>
											<td *ngFor="let channel of channels">{{channel.name}}</td>
										</tr>
									  </thead>
									<tbody>
										<!-- Group user rows -->
										<!-- old way
										<tr *ngFor="let user of projectApprovalUsers | callback: isUserInGroup : group" class="user">
										  <th>
											  <div class="userListItem">
												  {{user.name}}
												  <button mat-icon-button (click)="removeUserFromGroup(group, user)"><mat-icon>remove_circle_outline</mat-icon></button>
											  </div>
										  </th>
										  <td>
											  <mat-slide-toggle (toggleChange)="toggleValidatee(user, group)" [checked]="isValidatee(user, group)" >Validatee</mat-slide-toggle>
										  </td>
										  <td *ngFor="let channel of channels">
											  <mat-checkbox disabled [checked]="isUserInChannel(user, channel)" ></mat-checkbox>
											  <mat-icon *ngIf="isApproverInChannel(user, channel)">check</mat-icon>
										  </td>
									  </tr>
										-->
										<tr *ngFor="let user of group.users" class="user">
											<th>
												<div class="userListItem">
													{{user.name}}
													<button mat-icon-button (click)="removeUserFromGroup(group, user)"><mat-icon>remove_circle_outline</mat-icon></button>
												</div>
											</th>
											<td>
												<!--<mat-slide-toggle (toggleChange)="toggleValidatee(user, group)" [checked]="isValidatee(user, group)" >Validatee</mat-slide-toggle> -->
											</td>
											<td *ngFor="let channel of channels">
												<mat-icon *ngIf="isApproverInChannel(user, channel)">check</mat-icon>
											</td>
										</tr>
									</tbody>
								  </table>
								</div>
							</div>
							<div class="groupButtons">
								<button (click)="addGroup(chain, i+1)" [matTooltip]="strings.getTooltip('btn_add_group')" mat-mini-fab color="accent"><mat-icon>add</mat-icon></button>
								<mat-icon>expand_more</mat-icon>
							</div>

						<mat-selection-list *ngIf="false" (selectionChange)="onGroupUserSelected(group, $event)">
							<mat-list-option *ngFor="let user of projectApprovalUsers" [value]="user" [selected]="isUserInGroup(user, group)">{{user.name}}</mat-list-option>
						</mat-selection-list> 
						</div>
					</div>
					<div *ngIf="selectedChain?.approval_groups && selectedChain?.approval_groups.length == 0">
						<div class="groupButtons">
							<button (click)="addGroup(chain, 0)" [matTooltip]="strings.getTooltip('btn_add_group')" mat-mini-fab color="accent"><mat-icon>add</mat-icon></button>
							<mat-icon>expand_more</mat-icon>
					</div>
					</div>
				</div>
				
				
			  </div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</mat-tab>
	<mat-tab label="users" *ngIf="false">
		<mat-selection-list *ngIf="users" (selectionChange)="onAvailableUserSelected($event)">
			<mat-list-option *ngFor="let user of users" [value]="user" [selected]="isAvailableUserSelected(user)">{{user.name}}</mat-list-option>
		</mat-selection-list> 
	</mat-tab>
	<mat-tab label="team" *ngIf="false">
		<mat-accordion *ngIf="users" class="example-headers-align" multi>         
			<mat-expansion-panel *ngFor="let user of projectUsers" [value]="user">
			  <mat-expansion-panel-header>
				<mat-panel-title>
					{{user.name}}
				</mat-panel-title>
				<mat-panel-description>
				</mat-panel-description>
			  </mat-expansion-panel-header>
			  <!--content-->
				<mat-form-field class="permission-chip-list">
					<mat-label>{{user.name}}&nbsp;permissions</mat-label>
					<mat-chip-list #chipList aria-label="Fruit selection">
						<mat-chip *ngFor="let permission of user.permissions"
							[selectable]="permissionSelectable"
							[removable]="permissionRemovable"
							(removed)="removeUserPermission(user, permission)">
							{{permission.name}}
						<mat-icon matChipRemove *ngIf="permissionRemovable">cancel</mat-icon>
						</mat-chip>
						<input placeholder="Grant permission..."
							#permissionInput
							[formControl]="permissionCtrl"
							[matAutocomplete]="auto"
							[matChipInputFor]="chipList"
							[matChipInputSeparatorKeyCodes]="permissionSeparatorKeysCodes"
							[matChipInputAddOnBlur]="permissionAddOnBlur"
							(matChipInputTokenEnd)="addUserPermission(user, $event)">
					</mat-chip-list>
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="permissionSelected(user, $event)">
						<mat-option *ngFor="let permission of filteredPermissions | async" [value]="permission">
							{{permission}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-tab>
</mat-tab-group>