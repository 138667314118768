import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { StringsService } from './../../services/strings.service';
import { Subscription, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface NewAssetData {
	name: string;
	single: boolean;
	files: File[];
}

@Component({
	selector: 'app-new-asset-dialog',
	templateUrl: './new-asset-dialog.component.html',
	styleUrls: ['./new-asset-dialog.component.scss']
})
export class NewAssetDialogComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	

	
	public name : string;
	public files : File[];
	

	constructor(public dialogRef: MatDialogRef<NewAssetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: NewAssetData, private api:ApiService, private router:Router, private strings:StringsService) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
	}
	

	onFilesDragged(files:FileList){
		this.name = null;
		this.files = [];
		let max = this.data.single ? 1 : files.length
		for (let i = 0; i < max; i++) {
			this.files.push(files[i]);
		}
	}
	done() : void {
		this.data.files = this.files;
		this.data.name = this.name;
		this.dialogRef.close(this.data);
	}
	setAssetSaveDataDelayed(name, event){
		//console.log("setAssetSaveDataDelayed", name, event);
	}
	setAssetSaveData(name, event){
		//console.log("setAssetSaveData", name, event);
	}
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}