<div style="display:flex; align-items: flex-start; justify-content: start; font-size: 0.7rem; line-height: 0.9rem;">
	<div>Production members assigned to selection:</div> 
	<div style="flex-grow: 1; min-width: 60%;">
		<div class="panel-section" style="padding:0.5rem; min-height: 33px; max-height: 120px; font-size: 1rem; overflow-y: auto; margin: 0 0 0.5rem 0.5rem;">
			<span *ngIf="selectedProductionOwners.length == 0" class="instruction">None.</span>
			<mat-list *ngIf="selectedProductionOwners.length > 0" dense>
				<mat-list-item *ngFor="let user of selectedProductionOwners">{{user.name}}</mat-list-item>		
			</mat-list>
		</div>	
	</div>
</div>
<!-- <div style="display:flex; align-items: end; justify-content: space-between;">
	<span>Available*:</span>
	<span *ngIf="availableProductionUsers.length == 0" class="instruction">No common Production users for current workflow/channel selection.</span>
	<span *ngIf="availableProductionUsers.length > 0">{{availableProductionUsers.length}} Production users available.*</span>
</div> 
-->
<div style="padding: 0.5rem 0 0 0;">
	<button *ngIf="availableProductionUsers.length > 0" 	style="margin-left: -2.3rem" class="small" mat-stroked-button color="primary" [matMenuTriggerFor]="availableProdList">Re-assign: ({{availableProductionUsers.length}} common users available)</button>
	<button *ngIf="availableProductionUsers.length == 0"  	style="margin-left: -0.8rem" class="small" mat-stroked-button color="warn" [disabled]="selectedProductionOwners.length == 0" (click)="setProduction(null)">{{(selectedProductionOwners.length > 0) ? 'Unassign' : 'Re-assign'}}</button>
</div>
<div [ngStyle]="{'visibility':(availableProductionUsers.length > 0) ? 'hidden' : ''}" class="instruction" style="font-size: 0.6rem; height: 9px;">No common Production members available for current selection.</div>
<!--<button *ngIf="availableProductionUsers.length == 0 && selectedProductionOwners.length == 0" style="margin-left: -1.8rem" class="small" mat-stroked-button color="primary" [matMenuTriggerFor]="availableProdList">Re-assign</button>-->

<mat-menu dense #availableProdList="matMenu">
	<button mat-menu-item (click)="setProduction(null)" [disabled]="false">unassign</button>
	<mat-divider></mat-divider>
	<button mat-menu-item *ngFor="let user of availableProductionUsers" (click)="setProduction(user)" [disabled]="false">{{user.name}}</button>		
</mat-menu>
	<!-- <span class="selectionMsg">*based on current selection</span> -->

<!--
<span>remove this....</span>
<span>Selected users</span>
<mat-chip-list>
    <ng-container *ngFor="let user of availableProduction">
        <mat-chip *ngIf="isSelected(user)">
            {{user.name}}
          </mat-chip>
    </ng-container>
</mat-chip-list>
<button mat-button [matMenuTriggerFor]="menu">Assign user</button>
<mat-menu #menu="matMenu">
    <button (click)="setProduction(null)" [disabled]="false" mat-menu-item>unassign</button>
    <button *ngFor="let productionUser of channelAvailableProduction" (click)="setProduction(productionUser)" [disabled]="false" mat-menu-item>{{productionUser.name}}</button>
</mat-menu>
<div>
    <span>TODO: perhaps explain a bit more of show users as disabled perhaps if not in a channel..</span>
</div>

-->
<!--

    <button mat-button [matMenuTriggerFor]="menu">{{selectedProduction ? selectedProduction.name : "app"}}</button>
    <mat-menu #menu="matMenu">
        <button (click)="setProduction(null)" [disabled]="!selectedProduction" mat-menu-item>unassign</button>
        <button *ngFor="let productionUser of availableProduction" (click)="setProduction(productionUser)" [disabled]="productionUser.uuid == selectedProduction?.uuid" mat-menu-item>{{productionUser.name}}</button>
    </mat-menu>
-->