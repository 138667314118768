export interface IWorkgroup {
	id: number;
	uuid: string;
	name: string;
	account_uuid: string;
}
export class Workgroup implements IWorkgroup{
	public id: number;
	public uuid: string;
	public name: string;
	public account_uuid: string;
}