<mat-action-list class="list" [ngClass]="listType">
    <mat-list-item *ngFor="let item of items | filter : search : searchInclude : searchExclude | sort : sort : sortReverse" 
        [value]="item" 
        (click)="click(item, $event)" 
		[ngStyle]="getOverrideStyle(item)"
		[class.selected] = "item == selectedItem"
        [matTooltip]="getLabel(item).length > 45 ? getLabel(item) + ' ('+getMeta(item)+')' : ''"
        [matTooltipPosition]="'left'"
        >
        <ng-container mat-list-icon *ngIf="itemIcon">
            <ng-container *ngIf="itemIcon(item) as icon">
                <mat-icon class="prefix-icon" [ngClass]="icon.class">{{icon.icon}}</mat-icon>            
            </ng-container>
        </ng-container>
        <span matLine>{{getLabel(item)}}<span *ngIf="itemMeta" class="meta">{{' ('+getMeta(item)+')'}}</span></span>
		<span class="actions">
            <button *ngIf="canLink" mat-icon-button [matTooltip]="linkMessage" (click)="link(item, $event)"><mat-icon>exit_to_app</mat-icon></button>
            <button *ngIf="canInfo" mat-icon-button [matTooltip]="infoMessage" (click)="info(item, $event)" style="font-size: 1.2em;"><mat-icon>info_outline</mat-icon></button>
            <button *ngIf="canEdit" mat-icon-button [matTooltip]="editMessage" (click)="edit(item, $event)"><mat-icon>edit</mat-icon></button>
            <button *ngIf="canCopy" mat-icon-button [matTooltip]="copyMessage" (click)="copy(item, $event)"><mat-icon>content_copy</mat-icon></button>
            <button *ngIf="canDelete" mat-icon-button [matTooltip]="deleteMessage" (click)="delete(item, $event)"><mat-icon>delete</mat-icon></button>
        </span>
        <mat-checkbox *ngIf="canToggle" [checked]="item.selected" (change)="toggle(item, $event.checked)"></mat-checkbox>
    </mat-list-item>
    <div class="list-message" *ngIf="!items"><mat-spinner diameter="50"></mat-spinner></div>
    <div class="list-message" *ngIf="emptyListMessage && !items && items.length == 0">{{emptyMessage}}</div> 
</mat-action-list>