import { Directive, HostListener, HostBinding, Output, Input, EventEmitter } from '@angular/core';
// https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
// https://stackoverflow.com/questions/8459838/changing-mouse-cursor-for-html5-drag-drop-files-gmail-drag-drop
export class DndResult
{
	constructor(public files:FileList, public event:DragEvent){}
}

@Directive({
	selector: '[appDnd]'
})
export class DndDirective {
	private preventBodyDrop = true;
	private _disabled = false;
	private _triggerTarget;
	@Input() set disabled(disabled:boolean){
		this._disabled = disabled;
	}
	@HostBinding('attr.draggable')  draggable = 'false';
	@HostBinding('class.dragover') dragover: boolean;
	@HostBinding('class.nodragover') nodragover: boolean;
	@Output() fileDropped:EventEmitter<DndResult> = new EventEmitter<DndResult>();
	@Output() fileOver:EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
	@Output() dragLeave:EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
	//@Output() fileOut:EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
	@HostListener('dragstart', ['$event'])
	onDragStart(event) {
		event.preventDefault();
		event.stopPropagation();
	}
	@HostListener('dragover', ['$event'])
	onDragOver(event) {
		//console.log("drag over");
		event.preventDefault();
		event.stopPropagation();
		event.dataTransfer.dropEffect = this._disabled ? 'none' : 'copy';
		if(this._disabled)	return;
		this.fileOver.emit(event);
	}
	@HostListener('dragenter', ['$event'])
	onDragEnter(event) {
		
		event.dataTransfer.dropEffect = this._disabled ? 'none' : 'copy';
		if(this._disabled){
			this.nodragover = true;
			return;
		}
		this._triggerTarget = event.target;
		//console.log("drag enter");
	
		this.dragover = true;
	}
	@HostListener('dragleave', ['$event'])
	onDragLeave(event) {
		event.dataTransfer.dropEffect = this._disabled ? 'none' : 'copy';
		//console.log("drag leave");
		if(this._disabled){
			this.nodragover = false;
			return;
		}
		event.preventDefault();
		event.stopPropagation();
		if(this._triggerTarget != event.target)
		{
			return;
		}
		this.dragover = false;
		this._triggerTarget = null;
		this.dragLeave.emit(event);
	}
	@HostListener('drop', ['$event'])
	ondrop(event : DragEvent) {
		event.dataTransfer.dropEffect = this._disabled ? 'none' : 'copy';
		if(this._disabled) return;
		//console.log("drag drop");
		event.preventDefault();
		event.stopPropagation();
		this.dragover = false;
		this._triggerTarget = null;
		
		let files = event.dataTransfer.files;
		// TODO use advances approach below to handle other content types such as text or links
		// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
		// https://stackoverflow.com/questions/60565421/angular-8-drag-drop-dragover-dragleave-events-not-firing
		/*
		if (event.dataTransfer.items) {
			// Use DataTransferItemList interface to access the file(s)
			// @ts-ignore
			[...event.dataTransfer.items].forEach((item, i) => {
			  // If dropped items aren't files, reject them
			  if (item.kind === "file") {
				const file = item.getAsFile();
				files.push(file);
				console.log(`… file[${i}].name = ${file.name}`);
			  }
			});
		  } else {
			// Use DataTransfer interface to access the file(s)
			
			[...event.dataTransfer.files].forEach((file, i) => {
				files.push(file);
			  	console.log(`… file[${i}].name = ${file.name}`);
			});
		  }*/
		if (files.length) this.fileDropped.emit(new DndResult(files, event));
	}
	  // disable native browser file drop on <body>
	  @HostListener('body:dragover', ['$event'])
	  onBodyDragOver(event: DragEvent) {
		if (this.preventBodyDrop) {
		event.dataTransfer.dropEffect = 'none';
		  event.preventDefault();
		  event.stopPropagation();
		}
	  }
	
	  @HostListener('body:drop', ['$event'])
	  onBodyDrop(event: DragEvent) {
		if (this.preventBodyDrop) {
		event.dataTransfer.dropEffect = 'none';
		  event.preventDefault();
		}
	  }
}
