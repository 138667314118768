import { Component, OnInit, Input } from '@angular/core';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-my-projects',
	templateUrl: './my-projects.component.html',
	styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent implements OnInit {

	@Input() my_projects:any;
	
	constructor() { }
	
	ngOnInit(): void {
		if(!this.my_projects || this.my_projects.length < 1){
			this.my_projects = [
				{
					name: 'Test Project 1',
					is_locked: false,
					task_total: 0,
					health: [
						{forward: 20, backward: 10},
						{forward: 15, backward: 0},
						{forward: 0, backward: 0, todo: 2},
						{forward: 55, backward: 0},
					]
				},
				{
					name: 'Test Project 2',
					is_locked: false,
					task_total: 0,
					health: [
						{forward: 5, backward: 15},
						{forward: 10, backward: 0, todo: 5},
						{forward: 0, backward: 0},
						{forward: 55, backward: 0},
					]
				},
				{
					name: 'Test Project 3',
					is_locked: false,
					task_total: 0,
					health: [
						{forward: 20, backward: 30},
						{forward: 5, backward: 0, todo: 3},
						{forward: 0, backward: 0},
						{forward: 55, backward: 0},
					]
				},
				{
					name: 'Test Project 4',
					is_locked: false,
					task_total: 0,
					health: [
						{forward: 6, backward: 2},
						{forward: 0, backward: 4},
						{forward: 0, backward: 0, todo: 5},
						{forward: 7, backward: 0},
					]
				},
				{
					name: 'Test Project 5',
					is_locked: false,
					task_total: 0,
					health: [
						{forward: 20, backward: 10},
						{forward: 15, backward: 0},
						{forward: 0, backward: 0},
						{forward: 55, backward: 0},
					]
				},
				{
					name: 'Test Project 6',
					is_locked: false,
					task_total: 0,
					health: [
						{forward: 3, backward: 18},
						{forward: 10, backward: 0},
						{forward: 0, backward: 0},
						{forward: 2, backward: 40},
					]
				},
				{
					name: 'Test Project 7',
					is_locked: true,
					task_total: 0,
					health: [
						{forward: 20, backward: 0},
						{forward: 35, backward: 0},
						{forward: 10, backward: 0},
						{forward: 55, backward: 0},
					]
				},
			];
			
			for(let i in this.my_projects){
				let project:any = this.my_projects[i];
				project['total'] = 0;
				for(let h in project.health){
					project['total'] += (project.health[h].forward + project.health[h].backward)
				}
			}
			
			setTimeout(() => {
				$('.bar').each((index, element) => {
					let $bar = $(element);
					let p_tot:any = $bar.closest('.project').find('.project_total').html();
					let bar_height: any = 30;
					let inc:any = bar_height/p_tot;
					
					let $fwd_bar:any = $bar.find('[data-forward]');
					let $bwd_bar:any = $bar.find('[data-backward]');
					
					let fwd:any = parseInt($fwd_bar.attr('data-forward'));
					let bwd:any = parseInt($bwd_bar.attr('data-backward'));
					
					$fwd_bar.css({
						position: 'absolute',
						bottom: 0,
						left: 0,
						width: '100%',
						height: fwd*inc,
						background: 'rgba(144, 198, 57)',
					});
					
					$bwd_bar.css({
						position: 'absolute',
						bottom: fwd*inc,
						left: 0,
						width: '100%',
						height: bwd*inc,
						background: 'red',
					});
					
				});
			}, 350);
			
			
			
/*
			144, 198, 57
			
			0, 179, 254
*/
		}
	}

}
