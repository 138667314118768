<div class="outer">
	<mat-card class="my_tasks" *ngIf="my_tasks">
		<h4>My Tasks</h4>
		<mat-list role="list">
			<mat-list-item *ngFor="let task of my_tasks; index as i" role="listitem" class="list-item" [attr.data-task-uuid]="task.uuid" (click)="api.gotoPage('task/'+task.uuid)" >
				
			</mat-list-item>
		</mat-list>
	</mat-card>
	<mat-card class="my_tasks" *ngIf="!my_tasks">
		<h4>My Tasks</h4>
		<p>You have no tasks assigned to you</p>
	</mat-card>
</div>