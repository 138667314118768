<ng-container *ngIf="!autoFinalise">
    <p><b>Workflow: {{workflow.name}} ({{workflowIndex+1}} of {{numWorkflows}})</b></p>
    
    <!-- 
        finalise button fix
        scroll bars
        custom tick icons for completed steps (maybe?)
        'processing...' tag for blank popup hack
        admin role removal (only) needs to ignore other workflows...until the very end to check if needs to delete from project team as a whole or not
     -->
    
    <mat-stepper #mainStepper [linear]="true">
        <!-- amend manager step -->
        <mat-step *ngIf="isUserAmendManager" label="Reassign amend moderator" [completed]="actions?.amendManagerConfirmed">
            <!--
                <p>completed: {{actions?.amendManagerConfirmed ? 'true' : 'false'}}</p>
            <p>This step applies if the user is an amend manager.</p>
            <p>We might want to reassign that role to another project admin if possible</p>
            <p>is amend manager {{isUserAmendManager ? 'true' : ' false'}}</p>
            -->
            <mat-divider></mat-divider>
            <div class="note"><div class="note-title">Revoke Amend Moderator role: </div>This is an optional stage where you can assign the workflow Amend Moderator to another available team member.</div>
            <mat-form-field appearance="fill" color="primary">
                <mat-label>Re-assign to:</mat-label>
                <mat-select [(ngModel)]="actions.newAmendManager">
                    <mat-option [value]="'unassign'">No-one</mat-option>
                    <mat-optgroup *ngIf="otherAmendManagers.length > 0" [label]="'Available users:'"
                    [disabled]="false">
                        <mat-option *ngFor="let user of otherAmendManagers" [value]="user.uuid">
                            {{user.name}}
                        </mat-option>
                    </mat-optgroup>
                    <mat-option *ngIf="otherAmendManagers.length == 0" [disabled]="true">There are no available Admin users</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="button-group">
                <div></div>
                <button class="green" [disabled]="false" mat-raised-button (click)="completeAmendManagerStage()">Complete Stage</button>
            </div>
        </mat-step>

        <!-- production step -->
        <mat-step *ngIf="this.productionChannels?.length" label="Reassign production" [completed]="canCompleteProductionStage">
            <mat-divider></mat-divider>
            <div class="note"><div class="note-title">Revoke Production role: </div>This stage can be used to re-assign production ownership of each creative to any other available production role users, per channel.</div>
            <mat-stepper #productionChannelStepper *ngIf="this.productionChannels" linear>
                <mat-step *ngFor="let channel of this.productionChannels; last as isLast; first as isFirst; index as channelIndex" [label]="channel.name" state="phone">
                    <div class="button-group">
                        <p><b>Production creatives list ('{{channel.name}}'):</b></p>
                        <div>
                            <button class="small" color="primary" *ngIf="true || !isFirst"[disabled]="isFirst" mat-stroked-button (click)="previousProductionChannel()">Previous Channel</button>
                            <button class="small" color="primary" *ngIf="true || !isLast" [disabled]="isLast" mat-raised-button (click)="nextProductionChannel()">Next Channel</button>
                        </div>
                    </div>
                    <mat-list>
                        <mat-list-item class="creative-item" *ngFor="let creative of channel.creatives">
                            <span mat-line>{{creative.format.group}} - {{creative.format.name}}</span>
                            <mat-form-field appearance="fill">
                                <mat-label>Re-assign to:</mat-label>
                                <mat-select [value]="actions.productionActions[channel.id]?.[creative.uuid] || 'unassign'" (valueChange)="onProductionActionChange($event, channel, creative)">
                                    <mat-option [value]="'unassign'">No-one</mat-option>
                                    <mat-optgroup *ngIf="otherProductionUsers[channelIndex].length > 0" [label]="'Available production users'"
                                    [disabled]="false">
                                        <mat-option *ngFor="let user of otherProductionUsers[channelIndex]" [value]="user.uuid" [disabled]="user.uuid == this.data.user_uuid">
                                            {{user.name}}
                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-option *ngIf="otherProductionUsers[channelIndex].length == 0" [disabled]="true">There are no available Production users in this channel</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn-copy" (click)="copyProductionActionToOthers(creative, channel)" [matTooltip]="'Copy this action to all creatives'" mat-icon-button><mat-icon>content_copy</mat-icon></button>
                    </mat-list-item>
                    </mat-list>
                    
                </mat-step>
            </mat-stepper>
            <div class="button-group">
                <div>
                </div>
                <button class="green" *ngIf="true || isLast" [disabled]="!canCompleteProductionStage" mat-raised-button (click)="nextProductionChannel()">Complete Stage</button>
            </div>
        </mat-step>

        <!-- approer step -->
        <mat-step *ngIf="this.approvalChannels?.length" label="Reassign approval" [completed]="canCompleteApprovalStage">
            <mat-divider></mat-divider>
            <div class="note"><div class="note-title">Revoke Approver role: </div>This stage can be used to <b>re-assign</b> or <b>close</b> this Approver's amends in each of their assigend creatives, per channel, where relevant.</div>

            <mat-stepper #approvalChannelStepper *ngIf="this.approvalChannels" linear [stepControl]="validApproval">
                <mat-step *ngFor="let channel of this.approvalChannels; last as isLast; first as isFirst; index as channelIndex" [label]="channel.name" state="phone">
                    <div class="button-group">
                        <p><b>Approver creatives list ('{{channel.name}}'):</b></p>
                        <div>
                            <button class="small" color="primary" *ngIf="true || !isFirst"[disabled]="isFirst" mat-stroked-button (click)="previousApprovalChannel()">Previous Channel</button>
                            <button class="small" color="primary" *ngIf="true || !isLast" [disabled]="isLast" mat-raised-button (click)="nextApprovalChannel()">Next Channel</button>
                        </div>
                    </div>
                    <mat-list>
                        <mat-list-item class="creative-item" *ngFor="let creative of channel.creatives">
                            <span mat-line>{{creative.format.group}} - {{creative.format.name}}</span>
                            <mat-form-field appearance="fill">
                                <mat-label>Amend ownership:</mat-label>
                                <mat-select [value]="actions.approvalActions[channel.id]?.[creative.uuid] || 'close'"  (valueChange)="onApprovalActionChange($event, channel, creative)">
                                    <mat-option [value]="'close'">Close amends</mat-option>
                                    <mat-optgroup *ngIf="otherApprovalUsers[channelIndex].length > 0" [label]="'Or reassign to:'"
                                    [disabled]="false">
                                        <mat-option *ngFor="let user of otherApprovalUsers[channelIndex]" [value]="user.uuid" [disabled]="user.uuid == this.data.user_uuid">
                                            {{user.name}}
                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-option *ngIf="otherApprovalUsers[channelIndex].length == 0" [disabled]="true">There are no available Approvers in this channel</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn-copy" (click)="copyApprovalActionToOthers(creative, channel)" [matTooltip]="'Copy this action to all creatives'" mat-icon-button><mat-icon>content_copy</mat-icon></button>
                    </mat-list-item>
                    </mat-list>
                    
                </mat-step>
            </mat-stepper>
            <div class="button-group">
                <!--

                    <button *ngIf="!isFirst" mat-stroked-button (click)="approvalChannelStepper.previous()">Previous Channel</button>
                    <button *ngIf="!isLast" [disabled]="!checkAllCreativesApprovalActioned(channel)" mat-stroked-button (click)="approvalChannelStepper.next()">Next Channel</button>
                    <button *ngIf="isLast" [disabled]="!checkAllCreativesApprovalActioned(channel)" mat-stroked-button (click)="mainStepper.next()">Complete Stage</button>
                -->
                <div></div>
                <button class="green" *ngIf="true || isLast" [disabled]="!canCompleteApprovalStage" mat-raised-button (click)="nextApprovalChannel()">Complete Stage</button>
            </div>
        </mat-step>
        <mat-step label="Finalise">
            <p>If you are happy with everything press the button:</p>
            <button color="primary" mat-stroked-button (click)="done()">Workflow removal complete</button>
        </mat-step>
    </mat-stepper>
</ng-container>
