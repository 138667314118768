import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { Subscription, Subject } from 'rxjs';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'app-snackbar-with-close',
	templateUrl: './snackbar-with-close.component.html',
	styleUrls: ['./snackbar-with-close.component.scss']
})
export class SnackbarWithCloseComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	message:any = '';
	dismiss_button_label:any = 'Dismiss';

	constructor(public snackbarRef: MatSnackBarRef<SnackbarWithCloseComponent>, private route: ActivatedRoute, private router: Router, public api:ApiService, @Inject(MAT_SNACK_BAR_DATA) public data: any) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		if(typeof this.data.message !== 'undefined'){
			this.message = this.data.message;
		}
	}
	
	dismissAction(){
		if(typeof this.data.dismissAction !== 'undefined'){
			this.data.dismissAction();
			this.snackbarRef.dismiss();
		}else{
			this.snackbarRef.dismiss();
		}
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}