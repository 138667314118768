import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { AuthService } from './../../auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PasswordPair } from 'src/app/components/password-pair/password-pair.component';
import { AppUserService } from 'src/app/services/app-user.service';

declare var jquery:any;
declare var $ :any;
declare var window :any;

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
	if (formGroup.get('password').value === formGroup.get('passwordConfirm').value)
		return null;
	else
		return {passwordMismatch: true};
};

@Component({
	selector: 'app-signup-validate',
	templateUrl: './signup-validate.component.html',
	styleUrls: ['./signup-validate.component.scss']
})
export class SignupValidateComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	// https://stackoverflow.com/questions/50728460/password-confirm-angular-material
	formGroup:FormGroup;
	minPasswordLength:number = 6;
	hidePassword = true;
	hideConfirmPassword = true;

	constructor(private auth: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		public api:ApiService,
		private ds:DialogService,
		private formBuilder:FormBuilder,
		private appUserService:AppUserService,
	) { }
	
	token:any = false;
	
	ngOnInit() {

		this.api.logOut();

		this.formGroup = this.formBuilder.group({
			password: ['', [Validators.required, Validators.minLength(this.minPasswordLength)]],
			passwordConfirm: ['', [Validators.required]]
		},{ validators: passwordMatchValidator });

		this.subscription = this.api.activateaccount.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;

			this.appUserService.appUser = null;
			//console.log(res);
			localStorage.setItem('emailtmp', res.email);
			this.api.gotoPage('login');
		});
		this.subscription = this.route.params.subscribe(params => {
			if(params['token']){
				this.token = params['token'];
				// check if token is ok
				this.api.validateSignupObservable.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					if(res.message == "This activation token is invalid.")
					{
						// token already used...
						this.ds.openAlert({
							title:'Token invalid',
							message:"Back to home",
							dismiss_button_label:'ok',
							afterClosed:() => {
								this.api.gotoPage('login');
						}});
					}
					// all good lets go on
				}, err => {
					console.log(err)
					// bad					
				});
				this.api.validateSignup(this.token);
			}
		});
	}
	  /* Shorthands for form controls (used from within template) */
	  get password() { return this.formGroup.get('password'); }
	  get passwordConfirm() { return this.formGroup.get('passwordConfirm'); }

	  /* Called on each input in either password field */
	onPasswordInput() {
		
		if (this.formGroup.hasError('passwordMismatch'))
		this.passwordConfirm.setErrors([{'passwordMismatch': true}]);
		else
		this.passwordConfirm.setErrors(null);
	}
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}	
	activateAccount(passwordPair:PasswordPair) {
		this.api.activateAccount({...passwordPair, token: this.token});		
	}
}