import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFormat } from '../models/format.model';
import { OVBaseService } from './OvBaseService';

@Injectable({
	providedIn: 'root'
})
export class FormatService extends OVBaseService<IFormat, number> {
	constructor(protected http: HttpClient) {
		super(http, 'format');
	}
}
