import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AssetVO } from '../models/asset.model';

@Injectable({
	providedIn: 'root'
})
export class AssetMetadataService {

	public assetMetaSubject = new Subject<AssetVO>();
	public assetMeta = this.assetMetaSubject.asObservable();


	constructor() { }
	
	public assetMetaUpdate$: Observable<AssetVO>;

	
}
