import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
	if (formGroup.get('password').value === formGroup.get('passwordConfirm').value)
		return null;
	else
		return {passwordMismatch: true};
};

export interface PasswordPair
{
  password:string;
  password_confirmation:string;
}

@Component({
  selector: 'app-password-pair',
  templateUrl: './password-pair.component.html',
  styleUrls: ['./password-pair.component.scss']
})
export class PasswordPairComponent implements OnInit {

  @Input() title:string = "Set password";
  @Input() submitMessage:string = "Submit";
  @Output() done:EventEmitter<PasswordPair> = new EventEmitter<PasswordPair>();

	// https://stackoverflow.com/questions/50728460/password-confirm-angular-material
	formGroup:FormGroup;
	minPasswordLength:number = 6;
	hidePassword = true;
	hideConfirmPassword = true;
  constructor(private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
			password: ['', [Validators.required, Validators.minLength(this.minPasswordLength)]],
			passwordConfirm: ['', [Validators.required]]
		},{ validators: passwordMatchValidator });
  }
	  /* Shorthands for form controls (used from within template) */
	  get password() { return this.formGroup.get('password'); }
	  get passwordConfirm() { return this.formGroup.get('passwordConfirm'); }

	  /* Called on each input in either password field */
	onPasswordInput() {	
		if (this.formGroup.hasError('passwordMismatch'))
		this.passwordConfirm.setErrors([{'passwordMismatch': true}]);
		else
		this.passwordConfirm.setErrors(null);
	}
}
