import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApprovalGroup } from '../models/ApprovalGroup.model';
import { ApiResponse, OVBaseService } from './OvBaseService';


@Injectable({
	providedIn: 'root'
})
export class ApprovalChainService extends OVBaseService<ApprovalGroup, string> {
	constructor(protected http: HttpClient) {
		super(http, 'approvalChain');
	}
	// approval chains
	/*
	approvalChainIndex(chain_id: string):Observable<ApiResponse<ApprovalGroup>>{
		return this._get(`${this.base}/${chain_id}/approvalChains`);
	}*/
	approvalGroupIndex(chain_id: number):Observable<ApiResponse<ApprovalGroup>>{
		return this._get(`${this.base}/${chain_id}/approvalGroups`);
	}
	approvalGroupCreate(chain_id: number, group:ApprovalGroup):Observable<ApiResponse<ApprovalGroup>>{
		return this._post(`${this.base}/${chain_id}/approvalGroup`, group);
	}
	setValidator(chain_id:number, user:any)
	{
		return this._post(`${this.base}/${chain_id}/setValidator/${user.uuid}`);
	}
	removeValidator(chain_id:number, user:any)
	{
		return this._post(`${this.base}/${chain_id}/removeValidator/${user.uuid}`);
	}
	/*
	approvalGroupUpdate(chain_id: number, group:ApprovalGroup):Observable<ApiResponse<ApprovalGroup>>{
		return this._post(`${this.base}/${chain_id}/approvalGroupUpdate/${group.id}`, group);
	}
	approvalGroupDelete(chain_id: number, group_id: number):Observable<any>{
		return this._post(`${this.base}/${chain_id}/approvalGroupDelete/${group_id}`);
	}
	approvalGroupAddUser(chain_id: number, group_id: number, user:any):Observable<ApiResponse<ApprovalGroup>>{
		return this._post(`${this.base}/${chain_id}/approvalGroupsAddUser/${group_id}`, user);
	}
	approvalGroupRemoveUser(chain_id: number, group_id: number, user:any):Observable<ApiResponse<ApprovalGroup>>{
		return this._post(`${this.base}/${chain_id}/approvalGroupsRemoveUser/${group_id}`, user);
	}*/

}
