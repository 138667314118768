import { Injectable } from '@angular/core';
import { UploadAssetDialogComponent } from './../dialogs/upload-asset-dialog/upload-asset-dialog.component';
import { EditContainerDialogComponent } from './../dialogs/edit-container-dialog/edit-container-dialog.component';
import { EditAssetDialogComponent } from './../dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { AddContainerDialogComponent } from './../dialogs/add-container-dialog/add-container-dialog.component';
import { AssignUsersDialogComponent } from './../dialogs/assign-users-dialog/assign-users-dialog.component';
import { AlertDialogComponent } from './../dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../dialogs/confirm-dialog/confirm-dialog.component';
import { SnackbarMessageComponent } from './../dialogs/snackbar-message/snackbar-message.component';
import { SnackbarWithCloseComponent } from './../dialogs/snackbar-with-close/snackbar-with-close.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericDialogComponent } from '../dialogs/generic-dialog/generic-dialog.component';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Injectable({
	providedIn: 'root'
})
export class DialogService {
	
	dialogRef:any;
	userAssignOpen:any = false;

	constructor(public _dialog: MatDialog, public _snackbar: MatSnackBar) { }
	
	public openGenericConfirm(data:{title:string, subtitle?:string, body?:string, positive?:string, negative?:string})
	{
		data.title ||= "Are you sure";
		data.positive ||= "Yes";
		data.negative ||= "No";
		const dialogRef = this._dialog.open(GenericDialogComponent, {
			data
		});
		return dialogRef.afterClosed();	
	}

	/*
	 * Open confirm dialog
	 * Params:
	 *   width: integer
	 *   afterClosed: function
	 *   title: string
	 *   message: string
	 *   confirm_button_label: string
	 *   dismiss_button_label: string
	 *   dismissAction: function
	 *   confirmAction: function
	 */
	 
	public openConfirm(_data:any){
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		this.dialogRef = this._dialog.open(ConfirmDialogComponent, {
			width: width+'px',
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
			}
	    });
	}
	
	/*
	 * Open alert dialog
	 * Params:
	 *   width: integer
	 *   afterClosed: function
	 *   title: string
	 *   message: string
	 *   dismiss_button_label: string
	 *   dismissAction: function
	 */
	 
	public openAlert(_data:any){
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		if (typeof width == 'number') width = width + 'px'; 
		this.dialogRef = this._dialog.open(AlertDialogComponent, {
			width,
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
			}
	    });
	}
	
	public closeDialog(){
		this.dialogRef.close();
	}
	
	
	//TEMP
	public openUserAssignDialog(_data:any){
		//if(this.userAssignOpen) return;
		this.userAssignOpen = true;
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		this.dialogRef = this._dialog.open(AssignUsersDialogComponent, {
			width: width+'px',
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
				this.userAssignOpen = false;
			}
	    });
	}
	
	
	//TEMP
	public openEditContainerDialog(_data:any){
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		this.dialogRef = this._dialog.open(EditContainerDialogComponent, {
			width: width+'px',
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
			}
	    });
	}
	
	//TEMP
	public openEditAssetDialog(_data:any){
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		this.dialogRef = this._dialog.open(EditAssetDialogComponent, {
			width: width+'px',
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
			}
	    });
	}
	
	//TEMP
	public openAddContainerDialog(_data:any){
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		this.dialogRef = this._dialog.open(AddContainerDialogComponent, {
			width: width+'px',
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
			}
	    });
	}
	
	//TEMP
	public openUploadAssetDialog(_data:any){
		let width:any = (typeof _data.width !== 'undefined') ? _data.width : 300;
		this.dialogRef = this._dialog.open(UploadAssetDialogComponent, {
			width: width+'px',
			data: _data
	    });
	    this.dialogRef.afterClosed().subscribe(result => {
			if(typeof _data.afterClosed !== 'undefined'){
				_data.afterClosed();
			}
	    });
	}
	
	
	/*
	 * Open snackbar with message
	 * Params:
	 *   duration: integer
	 *   message: string
	 */
	 
	public openSnackbarMessage(_data:any){
		let durationInSeconds:any = (typeof _data.duration !== 'undefined') ? _data.duration : 2;
		this._snackbar.openFromComponent(SnackbarMessageComponent, {
			duration: durationInSeconds * 1000,
			data: _data
	    });
	}
	
	
	/*
	 * Open snackbar with message + close button
	 * Params:
	 *   duration: integer
	 *   message: string
	 */
	 
	public openSnackbarWithClose(_data:any){
		let durationInSeconds:any = (typeof _data.duration !== 'undefined') ? _data.duration : 2;
		this._snackbar.openFromComponent(SnackbarWithCloseComponent, {
			duration: 0,
			data: _data
	    });
	}
	
}
