<div *ngIf="!workflows" class="projectContainer">
	Retrieving project info...
</div>
<!-- [[ PROJECT ]] -->

<div *ngIf="workflows" class="projectContainer">
	<!-- <fieldset *ngIf="workflows" class="projectContainer"> --><!--workflow-->
	<!-- header -->
	<div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 1em;">
		<div style="font-size: 1.5em">{{project?.name}}</div>
		<button *ngIf="isAdmin" class="small" mat-stroked-button color="primary" style="right: 0;" (click)="copyProject(project,$event)">Copy project</button>
	</div>
	<mat-divider></mat-divider>
	<!-- overview -->
	<div style="margin: 0 0em 1em 0.5em;">
		<div class="subHeader" style="display: flex; align-items: center; justify-content: space-between;">
			<span>Overview:</span><button *ngIf="isAdmin" class="small" mat-stroked-button color="primary" style="right: 0;" (click)="editProjectOverview()">Edit overview</button><!--<button mat-icon-button><mat-icon>edit</mat-icon></button>-->
		</div>
		<div *ngIf="!project?.overview" class="instruction">No project overview.</div>
		<div *ngIf="project?.overview">{{project.overview}}</div>
	</div>
	
	<fieldset>
		<legend>Status</legend>
		<!-- metrics -->
		<div class="metric-label">Total approvals: 
			<ng-container *ngIf="creativesTotal > 0">
				<span *ngIf="approversTotal > 0" class="metric-percent">{{percentApprovals +'%'}}</span>
				<span *ngIf="approversTotal == 0" class="instruction">No Approvers assigned to project.</span>
			</ng-container>
			<span *ngIf="creativesTotal == 0" class="instruction">No creative slots in project.</span>
		</div>
	</fieldset>
	
	<!-- [[ TEAM ]] -->
	<fieldset *ngIf="true" ><!--workflow-->
		<legend>Project Team</legend>
		<div *ngIf="projectUsers && availableUsers" style="display: flex; justify-content: space-between; align-items: start;">
			<div>
				<div class="note">{{projectUsers.length}} users assigned to project. (From a total of {{availableUsers.length}} available in Workgroup)</div>
				<div *ngFor="let user of projectUsers">
					<div class="project-user"><div>{{user.name}}</div><span *ngIf="userIsAdmin(user)" matTooltip="Admin"><mat-icon style="margin-left: 0.5em">local_police</mat-icon></span></div>
				</div>
			</div>
			<button *ngIf="isAdmin" class="small" mat-stroked-button color="primary" style="margin-right: -1em;" (click)="setTabIndex(2)">Edit roles</button>
		</div>
<!--
		<div *ngFor="let user of workgroupService.users | async">
			<span>{{user.name}}</span>
		</div>
-->				
	</fieldset>
</div>	
