export function clamp(value:number, min:number = 0, max:number = 1):number {
  if(value < min) return min;
  if(value > max) return max;
  return value;
}
export function round(value:number, decimals:number = 2)
{
  let factor = Math.pow(10, decimals);
  return Math.round((value + Number.EPSILON) * factor) / factor;
}
export function lerp(t, a, b)
{
  return (b - a) * t + a;
}
export function unlerp(t, a, b)
{
  return (t - a) / (b - a)
}
export function mapRange(value, min, max, newMin, newMax)
{
  return lerp(unlerp(value, min, max), newMin, newMax)
}