<mat-dialog-content>

  <p>Removing {{data.user_name}} as project approver</p>

  <mat-stepper *ngIf="creatives && creativesWithAmends > 0" #mainStepper>
      <mat-step label="Info" state="phone">
        <p>Approver info:</p>
        <p>Approver for {{creatives?.length}} creatives</p>
        <p>Author of {{amendTasks?.length}} active amends on {{creativesWithAmends}} creatives</p>
        <div class="button-group">
            <button mat-stroked-button color="warn" matDialogClose>Cancel</button>
            <button mat-stroked-button color="primary" matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step label="Reassign amends" *ngIf="amendTasks?.length" state="chat" (interacted)="interacted($event)">
        <mat-stepper *ngIf="data.channels">
          <mat-step *ngFor="let channel of data.channels; last as isLast; first as isFirst; index as channelIndex" [label]="channel.name">
            <p>List of creatives with active amends, what are you going to do with each one.</p>
            <mat-list>
              <mat-list-item class="creative-item" *ngFor="let creativeAction of creativesWithAmendsByChannel[channelIndex]">
                  <span mat-line>{{creativeAction.creative.format.group}} - {{creativeAction.creative.format.name}}</span>
                  <mat-form-field appearance="fill">
                      <mat-label>Action</mat-label>
                      <mat-select [(ngModel)]="creativeAction.action">
                          <mat-option default [value]="'close'">- Close amends -</mat-option>
                        <mat-optgroup [label]="'Or reassign to:'"
                                      [disabled]="false">
                          <ng-container *ngFor="let group of data.workflow.approval_groups">
                              <mat-option *ngFor="let user of group.users" [value]="user.uuid" [disabled]="user.uuid == this.data.user_uuid || !data.workflow['approvalChannels'][user.uuid][channel.id]">
                                  {{user.name}}
                              </mat-option>   
                          </ng-container>
                          
                      </mat-optgroup>
                  </mat-select>
              </mat-form-field>
              <button class="btn-copy" (click)="copyActionToOthers(creativeAction, channelIndex)" [matTooltip]="'Copy this action to all creatives'" mat-icon-button><mat-icon>content_copy</mat-icon></button>
                  <!--
      
                      <mat-radio-group aria-label="Select an option">
                          <mat-radio-button value="0">Delete</mat-radio-button>
                          <mat-radio-button value="1">Reassign</mat-radio-button>
                      </mat-radio-group>
                  -->
              </mat-list-item>
            </mat-list>
            
            <div class="button-group">
              <button *ngIf="!isFirst" mat-stroked-button matStepperPrevious>Back</button>
              <button *ngIf="!isLast" mat-stroked-button matStepperNext>Next</button>
              <button *ngIf="isLast" mat-stroked-button (click)="mainStepper.next()">Done</button>
          </div>
          </mat-step>
        </mat-stepper>
      
      </mat-step>
      <mat-step label="Step 3">
        <p>You're welcome.</p>
        <div class="button-group">
          <button mat-stroked-button matStepperPrevious>Back</button>
          <button (click)="submit()" mat-stroked-button color="primary" mat-stroked-button>Submit</button>
        </div>
      </mat-step>
    
      <!-- Icon overrides. -->
      <ng-template matStepperIcon="phone">
        <mat-icon>call_end</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="chat">
        <mat-icon>forum</mat-icon>
      </ng-template>
    </mat-stepper>
  <!--

      <mat-stepper [linear]="true" #stepper>
          <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Fill out your name</ng-template>
          <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
            <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
          </mat-form-field>
          <div>
            <button  mat-stroked-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
        <form [formGroup]="secondFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                  required>
          </mat-form-field>
          <div>
            <button  mat-stroked-button matStepperPrevious>Back</button>
            <button  mat-stroked-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>You are now done.</p>
        <div>
          <button  mat-stroked-button matStepperPrevious>Back</button>
          <button  mat-stroked-button (click)="stepper.reset()">Reset</button>
        </div>
      </mat-step>
    </mat-stepper>
      -->
</mat-dialog-content>