<mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>{{data.edit ? "Edit" : "New"}} Project</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form autocompleteOff>
            <mat-form-field>
                <input type="text" matInput [formControl]="formControlName" placeholder="Enter Name" [(ngModel)]="this.data.name" autocompleteOff>
                <mat-error *ngIf="formControlName.hasError('required')">Name is <strong>required</strong></mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>
  </mat-card>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
	<button mat-raised-button [mat-dialog-close]="data" color="primary" [disabled]="!isValid()">{{data.edit ? "Update" : "Create"}}</button> <!-- (click)="done()"-->
</mat-dialog-actions>