import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { ProjectService } from 'src/app/api/project.service';
import { Project2Service } from 'src/app/api/project2.service';
import { WorkgroupService } from 'src/app/api/workgroup.service';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { GenericDialogComponent, GenericDialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { NewProjectData, NewProjectDialogComponent } from 'src/app/dialogs/new-project-dialog/new-project-dialog.component';
import { IProject, Project, Project2 } from 'src/app/models/project.model';
import { IWorkgroup } from 'src/app/models/workgroup.model';
import { AccountService } from 'src/app/services/accounts.service';
import { AppUserService } from 'src/app/services/app-user.service';
import { StringsService } from 'src/app/services/strings.service';
import { ProjectCloneComponent } from '../project/project-clone/project-clone.component';


@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends BaseComponent implements OnInit, OnChanges {

	private _instanceID:number = 0;
	@Input() workgroup : IWorkgroup;
	@ViewChild('searchInput') searchInput: ElementRef;

	private _unsubscribe = new Subject<boolean>();

	public projects:Project[];
	public showArchived:boolean = false;

	private errorMessage;
	
	public sortOptions: { label: string; sort: string; sortReverse: boolean; }[];
	public sort: string = "created_at";
	public sortReverse: boolean = true;
	public search: string;
	public showSearch: boolean = false;

	constructor(
		private project2Service : Project2Service,
		public api : ApiService,
		private projectService : ProjectService,
		public  workgroupService : WorkgroupService,
		public  accountService : AccountService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public appUserService: AppUserService,
		public strings: StringsService,
	) {
		super();
	}

	ngOnInit(): void {
		this._instanceID++;
		this.sortOptions = [
			{label:"name (A > Z)", sort:"name", sortReverse:false},
			{label:"name (Z > A)", sort:"name", sortReverse:true},
			{label:"newest", sort:"created_at", sortReverse:true},	
			{label:"oldest", sort:"created_at", sortReverse:false},	
		]
		//console.log("projects innit", this._instanceID)
		this.sub = this.workgroupService.projects$.subscribe(projects => this.projects = projects);
	}
	ngOnDestroy(): void {
		this._instanceID--;
		super.ngOnDestroy();
		//console.log("projects destroy", this._instanceID)
	}
	ngOnChanges(changes:SimpleChanges)
	{
		if(changes.workgroup?.currentValue != undefined)
		{
			this.unsub();
			this.loadProjects(changes.workgroup.currentValue.uuid);
		}
	}
	addProject(e = null) : void
	{
		if(!e)
		{
			//const dialogRef = this.dialog.open(NewProjectDialogComponent, {
			const dialogRef = this.dialog.open(GenericDialogComponent, {

				data: {
					title:"Add Project",
					negative:"Cancel",
					positive:"Create",
					form:[
						{ name:"name", type:"text", placeholder:"Enter Name", value:"", validator:Validators.required},
					]
				}//{territories:this.groups_vert, groups:this.groups_horiz}
			  });
			  dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
				  if(result)
				  {
					  let project = new Project2();
					  project.name = result.formGroup.value.name;
					  //project.name = result.va;
					  this.snackBar.open("creating project...");					 
					  this.workgroupService.project(this.workgroup.uuid, project).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
							this.snackBar.open("project created", "", {duration:2000});
							this.loadProjects();
						}, (error:HttpErrorResponse) => {
							if(error.status == 422)
							{
								this.snackBar.open("project creation failed: " + error.error.message, "", {duration:2000, panelClass:'snackBar-error'});
							}else {
								this.snackBar.open("project creation failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
							}							
						});
				  }else{
					  // popup closed with no data
				  }
			  });
			}else{
				//console.log("CREATE", e)
			}

	}
	copy(project:Project, event:MouseEvent = null)
	{
		if(event)event.stopPropagation();
		const dialogRef2 = this.dialog.open(ProjectCloneComponent, {
			data:{project}
		});
		dialogRef2.afterClosed().subscribe((result) => {
			if(result)
			{
				this.loadProjects();
			}
		});
		return;
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Copy Project",
					negative:"Cancel",
					positive:"Copy",
					form:[
						{ name:"name", type:"text", placeholder:"Enter Name", field:"name", label:"name", validator:Validators.required},
						{ name:"copyFormats", type:"checkbox", label:"Copy formats"},
						{ name:"copyChannels", type:"checkbox", label:"Copy channels"},
						{ name:"copyCreatives", type:"checkbox", label:"Copy creatives"},
						{ name:"copyAssets", type:"checkbox", label:"Copy assets"},
						//{ name:"copyFiles", type:"checkbox", label:"Copy file(s)"}, <- danger zone
					],
					model:project
				}
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let value = result.formGroup.value;			
				this.snackBar.open("copying project...");
				this.projectService.copy(project.uuid, value).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("project copied", "", {duration:2000});
					this.loadProjects();
				}, (error:HttpErrorResponse) => {
					if(error.status == 422)
					{
						this.snackBar.open("project copy failed: " + error.error.message, "", {duration:2000, panelClass:'error'});
					}else {
						this.snackBar.open("project copy failed: server error", "", {duration:2000, panelClass:'error'});
					}							
				});
			}
		});	

	}
	edit(project:Project, event:MouseEvent = null)
	{
		if(event)event.stopPropagation();
  
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Edit Project",
					negative:"Cancel",
					positive:"Save",
					form:[
						{ name:"name", type:"text", placeholder:"Enter Name", field:"name", label:"name", validator:Validators.required},
						{ name:"archived", type:"checkbox", field:"archived", label:"Archived?"},
						{ name:"is_locked", type:"checkbox", field:"is_locked", label:"Locked?"},
					],
					model:{
						name:project.name,
						archived:project.archived,
						is_locked:project.is_locked,
						uuid:project.uuid,
					}
				}
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let value = result.formGroup.value;
				let project = result.model;
				project.name = value.name;
				project.archived = value.archived;
				project.is_locked = value.is_locked;
				this.snackBar.open("saving project...");
				this.project2Service.update(project.uuid, project as Project2).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("project saved", "", {duration:2000});
					this.loadProjects();
				}, (error:HttpErrorResponse) => {
					if(error.status == 422)
					{
						this.snackBar.open("project edit failed: " + error.error.message, "", {duration:2000, panelClass:'snackBar-error'});
					}else {
						this.snackBar.open("project edit failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
					}							
				});
				/*
				let channel = result.model;
				channel.name = result.formGroup.value.name;
				this.snackBar.open("saving format...", '', {duration:5000});
				let value = result.formGroup.value;
				result.model.name = value.name;
				result.model.group = value.group;
				this.projectService.updateFormat(this.project_uuid, result.model).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("format saved", "", {duration:2000});
					this.loadFormats();
				});*/
			}
		});	
		
		return;
	  const dialogRefOld = this.dialog.open(NewProjectDialogComponent, {
		data: {name:project.name,edit:true}
	  });
	  dialogRefOld.afterClosed().subscribe((result: NewProjectData) => {
		if(result)
		{
		  project.name = result.name;
		  this.snackBar.open("saving project...");
		  this.project2Service.update(project.uuid, project as Project2).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("project saved", "", {duration:2000});
		  	this.loadProjects();
		  });
		}else{
		  // popup closed with no data
		}
	  });
	}
	delete(project:Project, event:MouseEvent = null)
	{
	  if(event)event.stopPropagation();
	  const dialogRef = this.dialog.open(GenericDialogComponent, {
		data: {title:"Delete Project", subtitle:project.name, body:"Warning this cannot be undone! Are you sure you wish to delete this project?", positive:"Delete", negative:"Cancel"}
	  });
	  dialogRef.afterClosed().subscribe((result: NewProjectData) => {
		if(result)
		{
			this.snackBar.open("deleting project...");
			this.project2Service.delete(project.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("project deleted", "", {duration:2000});
				this.loadProjects();
			}, (error) => 
			{
				this.snackBar.open("project not deleted", "", {duration:2000, panelClass:'snackBar-error'});
			});
		}else{
		  	// popup closed with no data
		}
	  });
	}
	loadProjects(uuid:string = null)
	{
		uuid ??= this.workgroup.uuid;
		this.workgroupService.loadProjects(uuid);
		/*
		this.loading = true;
		this.workgroupService.projects(this.workgroup.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.projects = response["data"];
				this.loading = false;
			},
			error => this.errorMessage = <any>error
		);*/
		/*
		this.projectService.findAll({workgroup_uuid:this.workgroup.uuid}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.projects = response["data"];
				this.loading = false;
			},
			error => this.errorMessage = <any>error
		);*/
		/*
		this.project2Service.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				const projs:Project2[] = response.data as Project2[];
			},
			error => this.errorMessage = <any>error
		);*/
	}
	selected(project:Project)
	{
		this.api.gotoPage(`/project/${project.uuid}`, false, {fragment:'Grid'});
	}
	onProjectSelected(e)
	{
		const project:Project = e.option.value as Project;
		this.api.gotoPage(`/project/${project.uuid}`, false, {fragment:'Grid'});
	}
	reload()
	{
		this.loadProjects(this.workgroup.uuid);
	}
	filterProject = (project: IProject) => {
		return !this.showArchived ? !project.archived : true;
		return this.showArchived ? true : !project.archived;
	}
	flipSort(e:MouseEvent)
	{
		e.stopPropagation();
		this.sortReverse = !this.sortReverse;
	}

	selectSort(option){
		this.sort = option.sort;
		this.sortReverse = option.sortReverse;
	}
	getSortLabel(){
		return this.sortOptions.find(option => option.sort == this.sort && option.sortReverse == this.sortReverse).label;
	}

	toggleSearch(){
		this.showSearch = !this.showSearch; 
		this.search='';
		if(this.showSearch){
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			  }, 16)
		}
	}
}
