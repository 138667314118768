
export interface IHasReference<TypeIn>
{
    reference:TypeIn;
}
export class ModelUtils {
	
	public static createReference(target:any, method:'json' | 'assign' = 'json', ignore:string[] = null ):any {
		if(method == 'json')
        {
            return JSON.parse(JSON.stringify(target));
        }else if(method == 'assign'){
            return Object.assign({}, target);
        }
	}
    // https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
	public static isPropDifferent(target:any, reference:any, prop: string, filter:{include?:string[], exclude?:string[]} = null): boolean {
        // TODO handle objects | complex properties i.e. arrays
        // if object, pass right back to is dirty and go again
        if(typeof target[prop] == 'object' && target[prop] != null && !Array.isArray(target[prop]) && reference[prop] != null)     // typeof null == 'object' == true
        {
            let newFilter;
            if(filter?.exclude)
            {
                newFilter = {};
                newFilter.exclude = filter.exclude.filter(item => {
                    let split = item.split('.');
                    return split.findIndex(val => val == prop) != -1;
                });
                newFilter.exclude = newFilter.exclude.map(item => {
                    return item.replace(prop + '.', '');
                });
            }
            let changes = ModelUtils.isDirty(target[prop], reference[prop], newFilter);
            return changes != undefined;
        }
		return JSON.stringify(target[prop]) !== JSON.stringify(reference[prop]);
	}
	public static isDirty(target:any, reference:any, filter:{include?:string[], exclude?:string[]} = null): string[]
	{
        // doesnt detect new properties
		let changes: string[];
        let props = filter?.include || Object.keys(target);
        for (let i = 0; i < props.length; i++) {
            const prop = props[i];
            if(filter?.exclude && filter.exclude.indexOf(prop) != -1){
                continue;
            }
			if(target[prop] == reference) continue; // skip if a prop of target IS the reference
			if (ModelUtils.isPropDifferent(target, reference, prop, filter)) {
                if(!changes) changes = [];
                changes.push(prop)
            };
        }
		return changes;
	}
	public static resetProp(target:any, reference:any,  prop: string) {
        // TODO handle complex types
		//return target[prop] = reference[prop];
        if(!reference || reference[prop] === undefined) return;
		target[prop] = JSON.parse(JSON.stringify(reference[prop]));
	}
    public static resetFromReference(target:any, reference:any, filter:{include?:string[], exclude?:string[]} = null)
    {
        for (let prop in target) {
			if(filter?.exclude && filter.exclude.indexOf(prop) != -1) continue;
            if(target[prop] == reference) continue; // skip if a prop of target IS the reference
			if (ModelUtils.isPropDifferent(target, reference, prop)) {
                ModelUtils.resetProp(target, reference, prop);
            };
		}
    }
    // this is only changes not deletes
    public static buildFromChanges<Type>(target:any, reference:any, changes:string[]):Type
    {
        let out:Type = {} as Type;
        for (let i = 0; i < changes.length; i++) {
            const prop = changes[i];
            out[prop] = target[prop];
        }
        return out;
    }
}