<div class="image" [ngStyle]="getImage()">
    <div *ngIf="!loaded" class="loader"></div>
    <div *ngIf="!this.getPath()" class="placeholder" [ngStyle]="getStyle()">
		<div class="center">
        	<mat-icon class="">photo</mat-icon>
			<span *ngIf="parentView != 'preview'">{{assetVO.asset.name}}</span>
		</div>
        <div *ngIf="parentView != 'preview'" class="message">upload an image file</div>
    </div>
	<div *ngIf="false">
		<h2 style="color: red;">{{assetVO?.preview ? 'has preview' : 'no preview'}}</h2>
		<pre style="color: red;">{{assetVO?.asset?.metadata ? (assetVO?.asset?.metadata | json) : ''}}</pre>
	</div>
</div>

