/*
interface Amend {
	author: string;
	request: string;
}
*/
export enum UserRoles{
	Production,
	Approval,
	ApprovalBasic,
	ApprovalManager,
}

export class User{
	//public roles:UserRoles[] = [];
	constructor(public name:string, public email: string, public type: string, public roles:UserRoles[] = [], public approvalPriority:number = 0){}
	addRole(role:UserRoles)
	{
		if(this.hasRole(role) === false) this.roles.push(role);
	}
	removeRole(role:UserRoles)
	{
		if(this.hasRole(role) === true) this.roles.splice(this.roles.indexOf(role), 1);
	}
	hasRole(role:UserRoles):boolean
	{
		return this.roles.indexOf(role) !== -1;
	}
	getInitials()
	{
		const words = this.name.split(" ");
		let initials = words[0].substr(0,1) + "." + words.pop().substr(0,1);
		return initials;
	}
}

export class Bit{
	static check(value:any, flag:number)
	{
		if(typeof value === "number")
		{
			return (flag & value);// === (value + 0);
		}if(Array.isArray(value))
		{
			let val = value.reduce((accumulator, currentValue) => accumulator | currentValue);
			return (flag & val);
		}
		// throw error?
		return false;
	}
}


export class Creative{
	public amendBatches:AmendBatch[] = [];
	public events:CreativeEvent[] = [];
	constructor(public name:string){}
	addEvent(e:CreativeEvent)
	{
		this.events.push(e);
	}
}
export class CreativeEvent{
	// QUERY @adaml do we need created? probably can be infered from the timestamp of the creative
	public static TYPE_UPLOADED:string 			= "TYPE_UPLOADED";
	public static TYPE_COMPLETED:string 		= "TYPE_COMPLETED";
	public static TYPE_APPROVED:string 			= "TYPE_APPROVED";
	public static TYPE_AMENDS_REQUESTED:string 	= "TYPE_AMENDS_REQUESTED";
	public date:number;
	constructor(public type:string, public who:User)
	{
		this.date = Date.now();
	}
	toString():string
	{
		const d = new Date(this.date);
		const dateString = d.toLocaleDateString() + ", " + d.toLocaleTimeString();
		return dateString + " - " + this.type.split("_").pop().toLowerCase() + " (" + this.who.name + ")";
	}
}

// TODO do we use enums - with maybe bitflags?
export enum AmendState{
	/*
	REQUESTED				= 1 << 0,
	REQUEST_ACCEPTED		= 1 << 1,
	REQUEST_REJECTED		= 1 << 2,
	REJECTED				= 1 << 3,
	REJECTION_ACKNOWLEDGED	= 1 << 4,
	*/
	/*
		accept/decline - requests
		confirm/reject - done
	*/
	REQUESTED,
	NEW,
	TODO,
	DONE,
	DONE_CONFIRMED,
	DECLINED,
	DECLINED_PRODUCTION,
	DECLINE_ACKNOWLEDGED,
	REJECTED,
	REJECTION_ACKNOWLEDGED,
	RECALLED,
}
export class Amend{

	public events:AmendEvent[] = [];
	// can the amend request be modified (can only be true during creation)
	public editable:boolean = false;
	// has a done amend been checked by a user in its current stage
	public checked:boolean = false;

	// could the below share a generic message field?

	public accepted:boolean = false;
	public acceptedMessage:string = null;

	// when declined the declined input will show
	public declined:boolean = false;
	public declinedMessage:string = null;

	// when rejected the rejected input will show
	public rejected:boolean = false;
	public rejectedMessage:string = null;

	// when completed an optional input will show
	public completed:boolean = false;
	public completedMessage:string = null;
	
	// when recalled an input will show
	public recalled:boolean = false;
	public recalledMessage:string = null;
	public version: number;
	
	
	
	constructor(public author:User, public request:string = null, public open:boolean = false, public state:AmendState = AmendState.NEW, public date:number = Date.now()){}
	dateString():string
	{
		const d = new Date(this.date);
		return d.toLocaleDateString() + " @ " + d.toLocaleTimeString();
    }
    addEvent(e:AmendEvent)
	{
		this.events.push(e);
	}
}
export class AmendEvent{
	public static TYPE_REQUESTED:string 				= "TYPE_REQUESTED";
	public static TYPE_REQUEST_ACCEPTED:string 			= "TYPE_REQUEST_ACCEPTED";
	public static TYPE_REQUEST_REJECTED:string 			= "TYPE_REQUEST_REJECTED";
	public static TYPE_DECLINED:string					= "TYPE_DECLINED";
	public static TYPE_REJECTED:string 					= "TYPE_REJECTED";	// a dev reject
	public static TYPE_REJECTION_ACKNOWLEDGED: string	= "TYPE_REJECTION_ACKNOWLEDGED";
	public static TYPE_RECALLED: string					= "TYPE_RECALLED";

	public static TYPE_DONE_REJECTED: string			= "TYPE_DONE_REJECTED";
	public static TYPE_DECLINE_ACKNOWLEDGED: string 	= "TYPE_DECLINE_ACKNOWLEDGED";

	// QUERY @adaml do we need a started button/event
	public static TYPE_STARTED:string 			= "TYPE_STARTED";
	public static TYPE_COMPLETED:string 		= "TYPE_COMPLETED";
	public static TYPE_CONFIRMED:string 		= "TYPE_CONFIRMED";
	public static TYPE_CLOSED:string 			= "TYPE_CLOSED";

	public date:number;
	
	
	
	
	constructor(public type:string, public who:User, public comment:string = null)
	{
		this.date = Date.now();
	}
    toString():string
	{
		const d = new Date(this.date);
		const dateString = d.toLocaleDateString() + ", " + d.toLocaleTimeString();
		return dateString + " - " + this.type.split("_").pop().toLowerCase() + " (" + this.who.name + ")" + (this.comment ? this.comment : '');
	}
}
/**
 * Represents a group of amends
 */
export class AmendBatch{

	constructor(public name:string, public amends: Amend[]){}
	/**
	 * Add an amend to the batch
	 */
	public add(amend:Amend) {
		this.amends.push(amend);
	}
	/**
	 * remove an amend from this batch
	 */
	public remove(amend:Amend):boolean {
		const index = 		this.amends.indexOf(amend);
		if(index !== -1){
			this.amends.splice(index, 1);
			return true;
		}
		return false;
	}
	/**
	 * Can an amend be added to the batch
	 */
	public canAdd():boolean {
		// TODO more thourough check of all amend requests
		return !this.amends.length || this.amends[this.amends.length-1].request != null;
	}
	/**
	 * Can an all the amends be submitted
	 */
	canSubmit():boolean {
		// no amend requests to submit
		if(!this.amends.length) return false;
		// are any amends requests invalid
		let allRequestsValid = true;
		for (let index = 0; index < this.amends.length; index++) {
			const amend = this.amends[index];
			if(!amend.request || amend.request === "" || amend.editable) allRequestsValid = false; 
		}
		return allRequestsValid;
	}
}

//TODO use enums?
enum ChainStageType
{
	BUILD,
	APPROVAL,
	MANAGE,
}
export class ChainStage{
	// creative upload
	public static TYPE_BUILD:string 	= "TYPE_BUILD";
	// approve or request direct (back to build) amends
	public static TYPE_APPROVAL:string 	= "TYPE_APPROVAL";
	// approve or request amends
	public static TYPE_APPROVAL_REQUEST:string 	= "TYPE_APPROVAL_REQUEST";
	// amends needs opening or rejecting (close?)
	public static TYPE_MANAGE:string 	= "TYPE_MANAGE";
	// all done
	public static TYPE_DONE: string		= "TYPE_DONE";

	// TODO handle concept of flow
	public previous:ChainStage = null;
	public next:ChainStage = null;
	

	constructor(public name:string, public users: User[], public type: string){}

	getUserList()
	{
		return this.users.map(user => user.name).join(", ");
	}
	isUserInStage(user:User)
	{
		return this.users.indexOf(user) !== -1;
	}
}
export class Chain{
	
	private _currentStage:ChainStage;
	public stages = {
		head:null,
		*[Symbol.iterator]() {
		for (var current = this.head; current !== null; current = current.next) {
		  yield current;
		}
	  }};	// used as an itterator
	constructor(public name:string, public firstStage: ChainStage)
	{
		this._currentStage = firstStage;
		this.stages.head = firstStage;
/*
		this.stages[Symbol.iterator] = function*() {
			var current = this._currentStage;
				while (current) {
					yield current;
					current = current.next;
				}
			};
*/
		for (const x of this.stages) {
			//console.log("stage", x);
		  }
	}

	get stage():number
	{
		//console.warn("this is not ideal");
		let currentIndex:number = 0;
		let stage:ChainStage = this.firstStage;
		while(stage)
		{
			currentIndex++;
			stage = stage.next;
		}
		return currentIndex;
	}
	get currentStage():ChainStage
	{
		return this._currentStage;
	}
	isUserInStage(user:User, stageIndex:number)
	{
		console.warn("this is not ideal");
		let currentIndex:number = 0;
		let stage:ChainStage = this.firstStage;
		while(currentIndex++ < stageIndex)
		{
			stage = stage.next;
		}
		return stage.isUserInStage(user);
	}
	isUserInCurrentStage(user:User)
	{
		return this.currentStage.isUserInStage(user);
	}
	nextStage() {
		this._currentStage = this._currentStage.next ? this._currentStage.next : this.currentStage;
	}
	prevStage() {
		this._currentStage = this._currentStage.previous ? this._currentStage.previous : this.currentStage;
	}



}