import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { StringsService } from './../../services/strings.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-edit-container-dialog',
	templateUrl: './edit-container-dialog.component.html',
	styleUrls: ['./edit-container-dialog.component.scss']
})
export class EditContainerDialogComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	title:any = false;
	save_label:any = 'Save';
	delete_label:any = 'Delete';
	save_data:any = {};
	show_locked_check:any = false;
	saveDataTimer:any = false;
	is_locked:any = 0;
	parent:any;

	constructor(public dialogRef: MatDialogRef<EditContainerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api:ApiService, private router:Router, private strings:StringsService) { }
	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		
		this.parent = this.data.parent;
		
		this.data.container_type = 4;
		if(this.data.workgroup) this.data.container_type = 2;
		if(this.data.folder) this.data.container_type = 3;
		
		switch(this.data.container_type){
			case 1:
				this.title = this.strings.getHeading('edit_account');
				break;
			case 2:
				this.title = this.strings.getHeading('edit_workgroup');
				break;
			case 3:
				this.title = this.strings.getHeading('edit_folder');
				break;
			case 4:
				this.title = this.strings.getHeading('edit_project');
				this.show_locked_check = true;
				break;
		}
		
		if(typeof this.data.title !== 'undefined'){
			this.title = this.data.title;
		}
		
		if(typeof this.data.save_label !== 'undefined'){
			this.save_label = this.data.save_label;
		}
		
		if(typeof this.data.delete_label !== 'undefined'){
			this.delete_label = this.data.delete_label;
		}
		
		this.save_data['container_type'] = this.data.container_type;
		this.save_data['account_id'] = (typeof this.data.account_id !== 'undefined') ? this.data.account_id : false;
		this.save_data['workgroup_id'] = (typeof this.data.workgroup_id !== 'undefined') ? this.data.workgroup_id : false;
		this.save_data['parent_id'] = (typeof this.data.parent_id !== 'undefined') ? this.data.parent_id : false;
		this.save_data['owner_id'] = (typeof this.data.owner_id !== 'undefined') ? this.data.owner_id : false;
		
	}
	
	setSaveDataDelayed(key:any, value:any){
		clearTimeout(this.saveDataTimer);
		this.saveDataTimer = setTimeout(() => {
			this.setSaveData(key, value);
		}, 100);
	}
	
	public setSaveData(key:any, value:any){
		this.data[key] = value;
		$(window).trigger('update_containers');
	}
	
	updateIsLocked(event:any){
		this.is_locked = (event.checked) ? 1 : 0;
	}
	
	updateAction(uuid:any){
		switch(this.data.container_type){
			case 2:
				this.api.updateworkgroup.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					window.location.reload();
				});
				this.api.updateWorkgroup({uuid:this.data.uuid, name:this.data.name});
				break;
			case 3:
				this.api.updatefolder.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					window.location.reload();
				});
				this.api.updateFolder({uuid:this.data.uuid, name:this.data.name});
				break;
			case 4:
				this.api.updateproject.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					window.location.reload();
				});
				this.api.updateProject({uuid:this.data.uuid, name:this.data.name});
				break;
		}
	}
	
	deleteAction(uuid:any){
		if(this.data.children.length > 0){
			alert('Not empty');
			return;
		}
		switch(this.data.container_type){
			case 2:
				this.parent.openRemoveContainerDialog('Workgroup', this.data, this, () => {
					this.api.deleteworkgroup.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						if(!res || res === 0) return;
						window.location.reload();
					});
					this.api.deleteWorkgroup({uuid:uuid});
				});
				break;
			case 3:
				this.parent.openRemoveContainerDialog('Folder', this.data, this, () => {
					this.api.deletefolder.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						if(!res || res === 0) return;
						window.location.reload();
					});
					this.api.deleteFolder({uuid:uuid});
				});
				break;
			case 4:
				this.parent.openRemoveContainerDialog('Project', this.data, this, () => {
					this.api.deleteproject.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						if(!res || res === 0) return;
						window.location.reload();
					});
					this.api.deleteProject({uuid:uuid});
				});
				break;
		}
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}