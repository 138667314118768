import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api/api.service';
import { AppUser, AppUserService } from 'src/app/services/app-user.service';
import Echo from 'node_modules/laravel-echo/dist/echo';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	private _unsubscribe = new Subject<boolean>();

	public title:string = 'launchctrl-app-core';
	private current_user:any;

	constructor(private api:ApiService,
		public appUserService:AppUserService,
		public themeService: ThemeService
		){}  
   
    ngOnInit(): void {
		this.themeService.init();
		// TODO remove this
		this.api.TOKENN.pipe(takeUntil(this._unsubscribe)).subscribe(token => {
			if(!token) return;
			this.joinChat(token);
		});
    }
	/**
	 * Once the user has been initialized we can join the main application "chat"
	 */
	private joinChat(token:string)
	{
		return;
		//console.log("TOKEN", token);
		// @ts-ignore
		window.Pusher = require('pusher-js');
		// @ts-ignore
		window.Echo = new Echo({
			broadcaster: 'pusher',
			key: "websocketkey",//process.env.MIX_PUSHER_APP_KEY,
			cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
			forceTLS: false,
			wsHost: window.location.hostname,
			wsPort: 8443,
			auth: {
				headers: {
					Authorization: 'Bearer ' + token,// BEARER_TOKEN,
				},
			},
		});

		// @ts-ignore
		window.Echo.join('chat')
		.error((error) => {
			console.error("error joinging chat", error);
		});
		// @ts-ignore
		window.Echo.channel('events').listen('RealTimeMessage', (e) =>{
			//console.log("WHYYY", e);  
			//this.snackBar.open(e.message, null, {duration: 2000});
		  });
		  // @ts-ignore
		  window.Echo.private('events').listen('RealTimeMessage', (e) =>{
			//console.log("WHYYY priv", e);  
			//this.snackBar.open("private: " + e.message, null, {duration: 2000});
		});
	}
}
