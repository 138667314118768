import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'src/app/api/admin.service';
import { ApiService } from 'src/app/api/api.service';
import Echo from 'node_modules/laravel-echo/dist/echo';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Globals } from 'src/app/global';

@Component({
	selector: 'app-admin-view',
	templateUrl: './admin-view.component.html',
	styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {

	private _unsubscribe = new Subject<boolean>();
	constructor(
		private api:ApiService,
		private adminService:AdminService,
		private route: ActivatedRoute,
		private router: Router,
		private title:Title,
	) {
		this.title.setTitle("Oceanview - admin");
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd){
				// udpate title based on route
				const url = event.url;
				if(url === "/admin")
				{
					this.title.setTitle("Oceanview - admin");
				} else {
					const subsection = event.url.split("/").pop();
					this.title.setTitle(`Oceanview - admin (${subsection})`);
				}
			}
		});
	}

	public users:any[];

	loadAccounts()
	{
		this.router.navigate(['accounts'], {relativeTo:this.route});
	}
	loadUsers()
	{
		this.router.navigate(['users'], {relativeTo:this.route});
	}
	loadWebsockets()
	{
		this.router.navigate(['websockets'], {relativeTo:this.route});
	}
	loadJobs()
	{
		this.router.navigate(['jobs'], {relativeTo:this.route});
	}
	loadStats(){
		this.router.navigate(['stats'], {relativeTo:this.route});		
	}
	ngOnInit(): void {

		// TODO remove this
		return;
		this.api.TOKENN.pipe(takeUntil(this._unsubscribe)).subscribe(token => {
			if(!token) return;
			// @ts-ignore
			window.Pusher = require('pusher-js');
			//console.log("TOKEN", this.api.token);
			 // @ts-ignore
			 window.Echo = new Echo({
				broadcaster: 'pusher',
				key: Globals.APP_KEY,//process.env.MIX_PUSHER_APP_KEY,
				cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
				forceTLS: false,
				wsHost: window.location.hostname,
				wsPort: 8443,
				auth: {
					headers: {
						//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
						Authorization: 'Bearer ' + this.api.token,// BEARER_TOKEN,
					},
				},
			});

			// @ts-ignore
			window.Echo.join('chat')
			.here((users) => {
				this.users = users;
				//console.log("here", this.users);
				for (let i = 0; i < this.users.length; i++) {
					const element = this.users[i];
					if(false)//if(element.uuid == this.api.currentuser)
					{
						this.users.splice(i, 1);
						break;
					}					
				}
			})
			.joining((user) => {
				//console.log("joining", user);
				let alreadyJoined = this.users.some((element) => { return element.uuid == user.uuid});
				if(!alreadyJoined) this.users.push(user);
				//console.log("here", this.users);
			})
			.leaving((user) => {
				//console.log("leaving", user);
				for (let i = 0; i < this.users.length; i++) {
					const element = this.users[i];
					if(element.uuid == user.uuid)
					{
						this.users.splice(i, 1);
						break;
					}					
				}
				//console.log("here", this.users);
			})
			.error((error) => {
				console.error(error);
			});
		});
		
		/*
		// @ts-ignore
		window.Echo.join('chat')
		.joining((user) => {
			this.api.userOnline();
		})
		.leaving((user) => {
			this.api.userOffline();
		})
		.listen('UserOnline', (e) => {
			console.log("USER ONLINE", e);
		})
		.listen('UserOffline', (e) => {
			console.log("USER OFFLINE", e);
		})*/
	}
}
