<p>Select asset to export</p>
<mat-form-field>
    <mat-label>Assets</mat-label>
    <mat-select multiple [formControl]="assetsControl" > <!---->
        <mat-option *ngFor="let assetVO of data.assets" [value]="assetVO">{{assetVO.asset.name}}</mat-option>
    </mat-select>
</mat-form-field>
<p>Select features to export</p>
<mat-form-field>
    <mat-label>Settings</mat-label>
    <mat-select multiple [formControl]="dataControl"> <!--[formControl]="assetsControl" -->
        <mat-option [value]="'name'" [disabled]="true">Name</mat-option>
        <mat-option [value]="'order'">Order</mat-option>
        <mat-option [value]="'dimensions'">Dimensions</mat-option>
        <mat-option [value]="'position'">Position</mat-option>
        <mat-option [value]="'visible'">Visibility</mat-option>
        <mat-option [value]="'deliverable'">Deliverability</mat-option>
        <mat-option [value]="'spec'">Spec</mat-option>
    </mat-select>
</mat-form-field>
<div>
    <button [disabled]="false" mat-raised-button color="primary" (click)="export()" matTooltip="Copy asset json to clipboard">Copy to clipboard</button>
</div>
