import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from './../../services/dialog.service';
import { ApiService } from './../../api/api.service';
import { AuthService } from './../../auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordPair } from 'src/app/components/password-pair/password-pair.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppUserService } from 'src/app/services/app-user.service';

declare var jquery:any;
declare var $ :any;
declare var window :any;


@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

	resetPasswordForm: FormGroup;
	hidePassword1: boolean = true;
	hidePassword2: boolean = true;
	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();

	constructor(private auth: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		public api:ApiService,
		private ds:DialogService,
		private snackBar:MatSnackBar,
		private appUserService:AppUserService,
	) { }
	
	email:any = false;
	token:any = false;

	createCompareValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
		return () => {
		if (controlOne.value != controlTwo.value)
		  return { mustMatch: "Passwords must match" };
		return null;
	  };
	
	}
	ngOnInit() {
		this.api.logOut();
		
		this.resetPasswordForm = new FormGroup({
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(3),				
			]),
			confirm: new FormControl('', [Validators.required]),
		});
		this.resetPasswordForm.get('confirm').addValidators(
			this.createCompareValidator(
			  this.resetPasswordForm.get('password'),
			  this.resetPasswordForm.get('confirm')
			)
		   );

		this.api.resetpassword.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//console.log("res reset password", res);
			$('body').removeClass('saving').removeClass('loading');
			if (res.status == 422) {	// validation error
				this.snackBar.open("password reset failed: " + res.error.message, "", {duration:2000, panelClass:'snackBar-error'});
			} else if(res.error) {		// generic server error
				this.snackBar.open("password reset failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
			} else {
				this.snackBar.open("password reset success", "", {duration:2000});

				// clear current user if any
				this.appUserService.appUser = null;
				localStorage.setItem('emailtmp', res.user.email); // check in firefox?
				//setTimeout(()=>{this.api.gotoPage('login'), 500});
				this.api.gotoPage('login');
			}
		});
		this.route.params.subscribe(params => {
			if(params['token']){
				$('body').addClass('saving').addClass('loading');
				this.token = params['token'];
				this.subscription = this.api.validateresetpassword.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					//console.log("validateresetpassword", res);
					
					if(!res || res === 0) return;
					//console.log(res);
					$('body').removeClass('saving').removeClass('loading');
					if(res.error){
						// token already used...
						this.ds.openAlert({
							title:'Token invalid',
							message:"Back to home",
							dismiss_button_label:'ok',
							afterClosed:() => {
								this.api.gotoPage('login');
						}});
						/*
						this.ds.openSnackbarMessage({message:'Invalid request'});
						setTimeout(() => {
							this.ds.openSnackbarMessage({message:'Invalid request'});
							this.api.gotoPage('login');
						}, 500);
						return;
						*/
					}else{
						this.email = res.email;
					}
				});
				this.api.validateResetPassword(this.token);
			}
		});
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
	resetPassword(passwordPair:PasswordPair)
	{
		if(!this.token || !this.email || !passwordPair) return;
		$('body').addClass('saving').addClass('loading');
		this.api.resetPassword({...passwordPair, token: this.token, email: this.email});
	}
	resetPasswordOld(){
		if(!this.token || !this.email) return;
		$('.login').removeClass('error');
		if($('#password1').val() == $('#password2').val()){
			this.subscription = this.api.resetpassword.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				if(!res || res === 0) return;
				sessionStorage.setItem('emailtmp', res.user.email);
				this.api.gotoPage('login');
				$('body').removeClass('saving').removeClass('loading');
			});
			$('body').addClass('saving').addClass('loading');
			this.api.resetPassword({password: $('#password1').val(), password_confirmation: $('#password2').val(), token: this.token, email: this.email});
		}else{
			setTimeout(() => {
				$('.login').addClass('error');
			}, 150);
		}
	}
}