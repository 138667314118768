import { CdkStep } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkflowService } from 'src/app/api/workflow.service';
import { Channel, IChannel } from 'src/app/models/channel.model';
import { ICreative } from 'src/app/models/creative.model';
import { ITask } from 'src/app/models/task.model';
import { IWorkflow } from 'src/app/models/workflow.model';

export interface RemoveApproverData {
	workflow: IWorkflow;
	user_name: string;
	user_uuid: string;
	channels: IChannel[];
	andGroup: boolean
}

export interface CreativeAction {
	creative:ICreative;
	action:string;
}

/**
 * Show info (continue or cancel), choose options, confirm
 * Outcomes
 * 1. delete all approval tasks
 * 2. delete or reassign amends or combination if present
 */
@Component({
	selector: 'app-remove-approver',
	templateUrl: './remove-approver.component.html',
	styleUrls: ['./remove-approver.component.scss']
})
export class RemoveApproverComponent implements OnInit {

	public creatives:ICreative[];
	public creativesWithAmendsByChannel:CreativeAction[][];
	public creativesWithAmends:number = 0;
	public approvalTasks:ITask[];
	public amendTasks:ITask[];
	public approvalStates:any;
	constructor(
		private snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<RemoveApproverComponent>,
		public workflowService: WorkflowService,
		@Inject(MAT_DIALOG_DATA) public data: RemoveApproverData
	) { }

	ngOnInit(): void {
		// load the user info and build a wizzard
		this.workflowService.getApproverInfo(this.data.workflow.uuid, this.data.user_uuid, Channel.getChannelIds(this.data.channels)).pipe().subscribe(res => {
			//console.log(res);
			let possibleCreatives = res.data;
			this.approvalTasks = [];
			this.amendTasks = [];

			this.creativesWithAmends = 0;
			this.creativesWithAmendsByChannel = [];
			for (let index = 0; index < this.data.channels.length; index++) {
				this.creativesWithAmendsByChannel[index] = [];
			}

			this.approvalStates = {};
			this.creatives = possibleCreatives.filter(creative => {
				if(creative.amend_tasks?.length)
				{
					this.amendTasks = this.amendTasks.concat(creative.amend_tasks);
					//this.creativesWithAmendsByChannel.push({creative, action:'close'});
					let channelIndex = this.data.channels.findIndex(channel => channel.uuid == creative.channel_uuid);
					if(channelIndex != -1)
					  this.creativesWithAmendsByChannel[channelIndex].push({creative, action:'close'});
					else
					  console.warn(`channel ${creative?.channel.name} not found`);
				}
				let approvalTask = creative.approval_tasks.length ? creative.approval_tasks[0] : null;
				if (approvalTask) {
					this.approvalTasks.push(approvalTask);
					if (this.approvalStates[approvalTask.state]) {
						this.approvalStates[approvalTask.state]++;
					} else {
						this.approvalStates[approvalTask.state] = 1;
					}
				}
				return approvalTask ? true : false;
			})
			for (let index = 0; index < this.creativesWithAmendsByChannel.length; index++) {
				const creatives = this.creativesWithAmendsByChannel[index];
				this.creativesWithAmends += creatives ? creatives.length : 0;				
			}
			//console.log("WAA", this.creativesWithAmendsByChannel);
			//console.log(this.creatives);
			//console.log(this.approvalTasks);
			//console.log(this.approvalStates);

			// all approval tasks are at waiting so safe to remove them all
			if (this.approvalStates?.waiting == this.approvalTasks.length) {

			}

			//no need for user to do anything if no amends at all
			if(this.creativesWithAmends == 0) this.submit();
		});
		//console.log("remove approver", this.data);

		
	}
	interacted(e:CdkStep)
	{
		//console.log("interacted", e);
		//console.log(this.creativesWithAmendsByChannel);
	}
	copyActionToOthers(creativeAction:CreativeAction, index:number)
	{
		// only for this channel
		let actions = this.creativesWithAmendsByChannel[index]
		for (let i = 0; i < actions.length; i++) {
			actions[i].action = creativeAction.action;
			if(actions[i].creative.uuid != creativeAction.creative.uuid)
			{

			}			
		}
	}
	submit()
	{
		this.snackBar.open("removing approver", null, {duration: 4000});
		// collate creative actions
		let actions = this.creativesWithAmendsByChannel.reduce((acc, val) => acc.concat(val), []);
		this.workflowService.revokeUserApproval(this.data.workflow.uuid, this.data.user_uuid, Channel.getChannelIds(this.data.channels), actions, this.data.andGroup ?? false).pipe().subscribe(res => {
			//console.log(res);
			this.snackBar.open("approver removed", null, {duration: 2000});
			this.dialogRef.close();
		});
	}
}
