<h1 mat-dialog-title>Notifications</h1>
<div mat-dialog-content>
    <p>public</p>
    <mat-list dense>
        <mat-list-item *ngFor="let message of pusher.publicMessages; index as i;">
            <span matLine>{{message}}</span>
            <button mat-icon-button (click)="deletePublic(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>
    <p>private</p>
    <mat-list dense>
        <mat-list-item *ngFor="let message of pusher.privateMessages; index as i;">
            <span matLine>{{message}}</span>
            <button mat-icon-button (click)="deletePrivate(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>
