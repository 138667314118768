let API_URL;
let WORKER_URL;
let APP_KEY = 'oceanview-key';
if(window.location.origin.indexOf("localhost") != -1) {
    // local dev
	API_URL = "/api/";
    //WORKER_URL = "http://localhost:8787/";    //"http://localhost:8787/" : "https://api.fivebyfivedev.com/workers/files/"
    WORKER_URL = API_URL + "workers/files/";
} else if(window.location.origin.indexOf("development.oceanview.pages.dev") != -1 || window.location.origin.indexOf("://fivebyfivedev.com") != -1) {
    // development first
	API_URL = "https://api.fivebyfivedev.com/";
    WORKER_URL = API_URL + "workers/files/";
} else if(window.location.origin.indexOf("oceanview.pages.dev") != -1) {
	API_URL = "https://api.oceanview5x5.com/";
    WORKER_URL = API_URL + "workers/files/";
} else {
	API_URL = window.location.origin.split("://").join("://api.") + "/";
    WORKER_URL = API_URL + "workers/files/";
}

export const Globals = Object.freeze({
    BASE_API_URL: API_URL,
    APP_KEY,
    WORKER_URL,
    //... more of your variables
});