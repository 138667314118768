// https://github.com/LeDDGroup/scroll-utility/blob/master/src/element.ts
export function scrollable(element:HTMLElement):boolean {
  if(!element) return false;
  return element.scrollHeight > element.clientHeight;
}
export function isScrolledToTop(element:HTMLElement):boolean {
  if(!element) return false;
  return element.scrollTop <= 1;
}
export function isScrolledToBottom(element:HTMLElement):boolean {
  if(!element) return false;
  return element.scrollHeight - element.scrollTop - element.clientHeight <= 1;
}