
<div *ngIf="state =='granted'" class="wrapper">
    <div class="controls">
        <div *ngIf="!preview">
            <button *ngIf="!this.recording" matTooltip="Start recording microphone..." mat-mini-fab color="warn" (click)="startRecording()">
                <mat-icon>radio_button_checked</mat-icon>
            </button>
            <button *ngIf="this.recording" matTooltip="Stop recording" mat-mini-fab color="primary" (click)="stopRecording()">
                <mat-icon>stop</mat-icon>
            </button>
        </div>
        <div *ngIf="preview">
            <button mat-mini-fab matTooltip="Delete recording"  (click)="deleteRecording()">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </div>
        <div class="time">
            {{timecode}}
        </div>

        <!--
            <button *ngIf="!this.recording" click-stop-propagation mat-button (click)="startRecording()">
                start
            </button>
            <button *ngIf="this.recording" click-stop-propagation mat-button (click)="stopRecording()">
                stop
            </button>
        -->
    </div>
    <div *ngIf="!preview"  class="vis">
        <canvas #audio_canvas id="audio_canvas" width="100px" height="20px"></canvas>
    </div>
    <div *ngIf="preview && false" class="preview">
        <audio id="audio" controls [src]="preview | safe:'url'"></audio>
        <!--

            <button click-stop-propagation mat-icon-button (click)="preview = null">
                <mat-icon>delete</mat-icon>
            </button>
        -->
    </div>
</div>
<div *ngIf="state=='prompt'" class="issue">
    <span>Please allow the app permission to record audio</span>
</div>
<div *ngIf="state=='denied'" class="issue">
    <span>Please reset your permissions for this page and reload</span>
</div>