<div class="wrapper">
    <div *ngIf="!preview && (state_capture == '' || state_capture == 'prompt')">
        <button mat-raised-button color="primary" (click)="start()" [disabled]="state_capture == 'prompt'">Enable capture</button>
    </div>
    <div class="controls">
        <div *ngIf="!captureMode">
            <div *ngIf="capturing">
                <div *ngIf="!preview">
                    <button *ngIf="!this.recording" mat-mini-fab color="warn" (click)="startRecording()" matTooltip="Start Recording">
                        <mat-icon>radio_button_checked</mat-icon>
                    </button>
                    <button *ngIf="this.recording" mat-mini-fab color="primary" (click)="stopRecording()" matTooltip="Stop Recording">
                        <mat-icon>stop</mat-icon>
                    </button>
                </div>
            </div>
           
            <button *ngIf="preview" mat-mini-fab (click)="deleteRecording()">
                <mat-icon>delete_forever</mat-icon>
            </button>
            <button *ngIf="this.preview && !this.cropResult" mat-mini-fab color="primary" matTooltip="Crop video" (click)="crop('video')">
                <mat-icon>crop</mat-icon>
            </button>
            <button *ngIf="this.preview && this.cropResult" mat-mini-fab color="primary" matTooltip="Clear crop" (click)="clearCrop()">
                <mat-icon>crop_free</mat-icon>
            </button>
            <button *ngIf="false && this.preview && !this.trimResult" mat-mini-fab color="primary" matTooltip="Trim video" (click)="trim()">
                <mat-icon style="transform: rotate(90deg);">expand</mat-icon>
            </button>
            <!--
    
                <button *ngIf="!this.recording" click-stop-propagation mat-button (click)="startRecording()">
                    start
                </button>
                <button *ngIf="this.recording" click-stop-propagation mat-button (click)="stopRecording()">
                    stop
                </button>
            -->
            <div class="time">
                {{timecode}}
            </div>
        </div>
        <div *ngIf="captureMode">
            <div *ngIf="capturing">
                <!-- take snapshot -->
                <button *ngIf="!preview" click-stop-propagation mat-icon-button (click)="snapshot()" matTooltip="Take Screenshot">
                    <mat-icon>add_a_photo</mat-icon>
                </button>
            </div>
            <button *ngIf="preview" mat-mini-fab (click)="deleteSnapshot()">
                <mat-icon>delete_forever</mat-icon>
            </button>
            <button *ngIf="preview && !this.cropResult" mat-mini-fab color="primary" matTooltip="Crop video" (click)="crop('image')">
                <mat-icon>crop</mat-icon>
            </button>
            <button *ngIf="preview && this.cropResult" mat-mini-fab color="primary" matTooltip="Clear crop" (click)="clearCrop()">
                <mat-icon>crop_free</mat-icon>
            </button>
        </div>
    </div>
    <div>

    </div>
    <div class="audio-vis-wrapper" *ngIf="state_capture == 'granted' && !captureMode && !preview">
        <div class="mic">
            <!--<mat-slide-toggle [disabled]="this.recording" (toggleChange)="changeIncludeMic($event)" [checked]="includeMic">Record mic</mat-slide-toggle>-->
            <div matTooltip="Include microphone audio">
                <button [disabled]="this.recording" class="icon-button-small" (click)="changeIncludeMic()" *ngIf="includeMic" mat-icon-button><mat-icon>mic</mat-icon></button>
            </div>
            <div matTooltip="Exclude microphone audio">
                <button [disabled]="this.recording" class="icon-button-small" (click)="changeIncludeMic()" *ngIf="!includeMic" mat-icon-button><mat-icon>mic_off</mat-icon></button>
            </div>
            <!--
                <span *ngIf="systemAudio">Recording system audio</span>
                <span [matTooltip]="'To capture system audio retry and check box in browser popup'" *ngIf="!systemAudio">Not recording system audio</span>
            -->
        </div>
        <div class="line-wrapper" *ngIf="!audioStream">
            <span class="line"></span>
        </div>
        <!-- live audio preview-->
        <div *ngIf="audioStream" class="audio-vis">
            <!--<span>{{mediaRecorder?.state}}</span>-->
            <app-audio-visualize-widget [stream]="combinedStream" [colour]="mediaRecorder?.state == 'recording' ? 'rgb(255, 0, 0)' : 'rgb(0, 0, 0)'"></app-audio-visualize-widget>
        </div>
    </div>
    <div class="vis" [class.hidden]="preview">
        <!-- <span *ngIf="!preview">Live preview:</span> -->
        <!-- live capture preview -->
        <div [class.hidden]="preview" [ngClass]="{recording:this.recording, previewing:!this.recording}">
            <video #video style="width:100%" id="video" autoplay [muted]="true"></video>
        </div>
    </div>
    <div class="preview">
        <!-- <span *ngIf="preview">Recorded preview:</span> -->
        <!-- recorded video preview -->
        <div *ngIf="!captureMode && preview" style="display:flex;align-items:center;">
            <video id="" style="width:100%" controls [src]="preview | safe:'url'"></video>
        </div>
        <!-- snappshot image preview -->
        <div *ngIf="captureMode && preview" style="width:100%">
            <!-- <span>Preview:</span> -->
            <img [src]="preview | safe:'url'" style="width:100%">
        </div>
        <div *ngIf="this.cropResult" style="pointer-events:none; position: absolute; border: 1px solid #ff0; box-shadow: 0 0 0 999px rgb(0 0 0 / 33%);"
            [style.left.%]="(this.cropResult.x1 / this.width) * 100"
            [style.right.%]="(1 - (this.cropResult.x2 / this.width)) * 100"
            [style.top.%]="(this.cropResult.y1 / this.height) * 100"
            [style.bottom.%]="(1 -(this.cropResult.y2 / this.height)) * 100"
        ></div>
    </div>

    <div style="display:none">
        <span>Snapshot:</span>
        <canvas style="width:100%" #canvas id="canvas"></canvas>
    </div>

</div>