import { Component, OnInit, Inject} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';

export interface NewCreativeData {
	name: string;
	channel: any;
	format: any;
	channels: any[];
	formats: any[];
}


@Component({
	selector: 'app-new-creative-dialog',
	templateUrl: './new-creative-dialog.component.html',
	styleUrls: ['./new-creative-dialog.component.scss']
})
export class NewCreativeDialogComponent implements OnInit {
	formControlName = new FormControl('', [Validators.required] );	/* needed this to get the name to bind ??? */
	formControlChannel = new FormControl('', [Validators.required]);
	formControlFormat = new FormControl('', [Validators.required]);
	filteredChannels: Observable<string[]>;
	filteredFormats: Observable<string[]>;

	constructor(public dialogRef: MatDialogRef<NewCreativeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: NewCreativeData, private api:ApiService) { }

	ngOnInit(): void {
		this.filteredChannels = this.formControlChannel.valueChanges
		.pipe(
		  startWith(''),
		  map(value => this._filter(value, this.data.channels))
		);
		this.filteredFormats = this.formControlFormat.valueChanges
		.pipe(
		  startWith(''),
		  map(value => this._filter(value, this.data.formats))
		);
	}
	private _filter(value: string, list:any[]): string[] {
		const filterValue = value.toLowerCase();	
		return list.filter(option => option.name.toLowerCase().includes(filterValue));
	}
	isValid()
	{
		return this.data.channel && this.data.format;
		;//return this.formControlName.valid && this.formControlChannel.valid && this.formControlFormat.valid;
	}
	private _filterChannels(value: string): string[] {
		const filterValue = value.toLowerCase();	
		return this.data.channels.filter(option => option.toLowerCase().includes(filterValue));
	}
	private _filterFormats(value: string): string[] {
		const filterValue = value.toLowerCase();	
		return this.data.formats.filter(option => option.toLowerCase().includes(filterValue));
	}
	/*
	done() : void {
		this.dialogRef.close(this.data);
	}*/
}
