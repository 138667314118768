<h2>Asset List</h2>
<table *ngIf="data?.assets" mat-table [dataSource]="data.assets">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container matColumnDef="assetname">
      <th mat-header-cell *matHeaderCellDef>
         <div class="assetname">Layer name</div>
         <div class="content">Content</div>
      </th>
      <td mat-cell *matCellDef="let assetVO" class="clickable" (click)="clickAsset(assetVO)">
          <div class="assetname">{{assetVO.asset.name}}</div>
          <div class="content" [ngClass]="{issue:!getDisplayContentURI(assetVO)}">{{getDisplayContentURI(assetVO) || 'no content'}}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef>Content</th>
        <td mat-cell *matCellDef="let assetVO" [ngClass]="{issue:!getDisplayContentURI(assetVO)}" class="clickable" (click)="clickAsset(assetVO)">
            {{getDisplayContentURI(assetVO) || 'no content'}} </td>
     </ng-container>
     <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let assetVO"> {{assetVO.asset.type}} </td>
     </ng-container>
     <ng-container matColumnDef="width">
        <th mat-header-cell *matHeaderCellDef>Width</th>
        <td mat-cell *matCellDef="let assetVO" [ngClass]="{issue:(assetVO.asset.metadata?.width && ((assetVO.asset.metadata?.width != assetVO.name_width) || (assetVO.asset.metadata?.width != assetVO.filename_width)))}">
            {{assetVO.asset.metadata?.width || "-"}}</td>
     </ng-container>
     <ng-container matColumnDef="height">
        <th mat-header-cell *matHeaderCellDef>Height</th>
        <td mat-cell *matCellDef="let assetVO" [ngClass]="{issue:(assetVO.asset.metadata?.height && ((assetVO.asset.metadata?.height != assetVO.name_height) || (assetVO.asset.metadata?.height != assetVO.filename_height)))}">
            {{assetVO.asset.metadata?.height || "-"}}</td>
     </ng-container>
     <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let assetVO"> {{formatBytes(assetVO.asset.metadata?.size) || '-'}} </td>
     </ng-container>
     <ng-container matColumnDef="visible">
        <th mat-header-cell *matHeaderCellDef>Visible</th>
        <td mat-cell *matCellDef="let assetVO" [ngClass]="{green:assetVO.asset.visible, issue:!assetVO.asset.visible}">
            {{assetVO.asset.visible ? "yes" : "no"}} </td>
     </ng-container>
     <ng-container matColumnDef="deliverable">
        <th mat-header-cell *matHeaderCellDef>Deliverable</th>
        <td mat-cell *matCellDef="let assetVO" [ngClass]="{green:assetVO.asset.deliverable, issue:!assetVO.asset.deliverable}">
            {{assetVO.asset.deliverable ? "yes" : "no"}} </td>
     </ng-container>
</table>