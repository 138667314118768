import { IAsset } from "./asset.model";
import { ITask } from "./task.model";
import { IUser } from "./user.model";


export class CreativeAction
{
	public creative_version:string;
	public user_id:string;
	public action:string;
	public created_at:string;
	public user:IUser;
}
export enum CreativeState
{
	NEW 		= "new",				// production, light grey
	BUILD 		= "build",				// production, light blue
	APPROVAL 	= "reviewing",			// approver, light green
	APPROVED 	= "reviewed",			// approver
	SUBMITTED	= "submitted",			// approver
	QUALIFYING 	= "qualifying", 		// validator			
	AMENDING 	= "amending",			// production, light red
	CONFIRMING 	= "confirming",//not used in back-end			// validator
	CHECKING 	= "checking",//not used in back-end			// approver, light orange
	DONE 		= "approved",				// n/a, green
	WARNING		= "warning",
	QUERIED		= "queried",
	AWAITING_APPROVERS = "warning",		//"¯\_(ツ)_/¯";
	VIEWONLY    = "info"
}




export interface ICreative
{
	id: number;
	uuid: string;
	
	//project_id: string;
	workflow_uuid: string;
	workflow_name?: string;
	format_uuid: string;
	channel_uuid: string;
	created_by: string;
	updated_by: string;
	name: string;
	state: string;
	stateValue: number; // numeric representation of state
	version: number;
	version_latest: number;
	created_at: string;
	updated_at: string;

	assets: IAsset[];
	amends: [];
	tasks: ITask[];
	actions: CreativeAction[];
	
	format: any;
	channel: any;
	
	label: string;
	flag: number;
	specs: string;
	public: boolean;
	
	groupInfo: any;
	meta?: {[key: string]: number};
	selected: boolean;
	package: number;
	deliveryStatus: string;
	production_task?: ITask;

	bg_color:string;
	bg_transparent:boolean;
	layout:string;
	viewonly:boolean;

	warnings: string[];
	deleting:boolean;
}
export class Creative implements ICreative
{
	public id: number;
	public uuid: string;
	//public project_id: string;
	public workflow_uuid: string;
	public format_uuid: string;
	public channel_uuid: string;
	public created_by: string;
	public updated_by: string;
	public name: string;
	public state: string;
	public stateValue: number;
	public version: number;
	public version_latest: number;
	public created_at: string;
	public updated_at: string;
	public assets: IAsset[];
	public amends;
	public tasks: ITask[];
	public actions: CreativeAction[];
	public format: any;
	public channel: any;
	public label: string;
	public flag: number;
	public specs: string;
	public public: boolean;
	public deleting: boolean = false;
	public groupInfo: any;
	public selected: boolean;
	package: number;
	deliveryStatus: string;

	bg_color:string;
	bg_transparent:boolean;
	layout:string;
	viewonly: boolean;
	warnings: string[];

	public static getIcon(state){
		switch (state)	
		{
			case CreativeState.NEW 					: return "highlight_alt"; break; 				
			case CreativeState.BUILD 				: return "settings"; break; 				
			case CreativeState.APPROVAL 			: return "visibility"; break; 			
			case CreativeState.APPROVED 			: return "done"; break; 			
			case CreativeState.SUBMITTED			: return "feedback"; break; 			
			case CreativeState.QUALIFYING 			: return "policy"; break;  					
			case CreativeState.AMENDING 			: return "build"; break; 			
			case CreativeState.CONFIRMING 			: return "gpp_good"; break; //not used in back-end			
			case CreativeState.CHECKING 			: return ""; break; //not used in back-end	
			case CreativeState.DONE 				: return "check_circle"; break; 			
			case CreativeState.WARNING				: return "warning"; break; 
			case CreativeState.QUERIED				: return "question_mark"; break; 
			case CreativeState.AWAITING_APPROVERS 	: return "warning"; break; 	
			case CreativeState.VIEWONLY				: return null;	
		}
		return '';
	}
}