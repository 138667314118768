<form method="post" action="" enctype="multipart/form-data">
	<div #droptarget class="dropwrapper" appDnd (fileDropped)="handleDrop($event)">
		<div class="dropcontent">
			<div class="message">
				<mat-icon>upload</mat-icon>
				<span>{{message || "Drag files here to create multiple assets"}}</span>
			</div>
			<input (change)="onFileSelect($event)" #input type="file" style="display: none" [multiple]="multiple"/>
			<button (click)="input.click()" mat-stroked-button color="primary" matTooltip="Manually browse for a file or DRAG & DROP a file here">Browse...</button>
		</div>
	</div>
</form>
