import Echo from "laravel-echo";
import { PusherPresenceChannel } from "laravel-echo/dist/channel";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { IMessage } from "./models/message";

export class Chat
{
    private _channelName:string;
    public get channelName():string
    {
        return this._channelName;
    }
    private _chat:PusherPresenceChannel;
    public get chat():PusherPresenceChannel
    {
        return this._chat;
    }
    public get me()
    {
        return this._chat.subscription.members.me;
    }

    private _users:any[] = [];
	//private _usersSubject:Subject<any[]> = new Subject<any[]>();//new BehaviorSubject<any[]>(null);
	public usersSubject:BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);//new BehaviorSubject<any[]>(null);
    public users = this.usersSubject.asObservable();// new Observable<any[]>();//from(this._users);//this._users.asObservable();

    private _messages : Subject<IMessage>  = new Subject<IMessage>();
	public messages:Observable<IMessage> = this._messages.asObservable();

    private _whispers : Subject<any>  = new Subject<any>();
	public whispers = this._whispers.asObservable();
    private _whisperBacklog : any[] = [];

    constructor(private onJoin:Function = null, private onHere:Function = null, private onLeave:Function = null)
    {
        
    }
    join(channelName:string)
    {
        this._whisperBacklog.length = 0;

        // leave any current channel if one exists
        if(this._channelName)
        {
            if(channelName == this._channelName) return;    // already joined so ignore
            else this.leave();
        }
        this._channelName = channelName;
        if(!this._channelName || this._channelName == '') return;
        // @ts-ignore
        this._chat = window.Echo.join(this._channelName)

        .here((users) => {
            // (I HAVE JOINED) we are in the chat and here are the current users already in...
            this._users = users || [];
            this.usersSubject.next(this._users);

            if(this.onHere) this.onHere(this);

            var limit = this._whisperBacklog.length;
            while(limit--)
            {
                let whisper = this._whisperBacklog.shift();
                this.whisper(whisper.eventName, whisper.data);
            }
            //console.log("members - here", this._channelName, this._chat.subscription.members);
            
            /*
            if(here_whisper){
                let me = this._currentProjectChat.subscription.members.me.info;
                this.chat.whisper("creative", {user:me.uuid, message:here_whisper});
            }*/
        }).joining((joiner) => {
            // (SOMEONE HAVE JOINED)
            let alreadyJoined = this._users.some((user) => { return user.uuid == joiner.uuid});
            if(true || !alreadyJoined){
                //joiner.initials = joiner.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('');
                this._users.push(joiner);
                this.usersSubject.next(this._users);
            }
            if(this.onJoin)  this.onJoin(this);
            //console.log("members - joining", this._channelName, this._chat.subscription.members);
            // can only whisper once we are in the channel.. 
            // need to message when we join and when others join incase they come later
            /*
            if(join_whisper){
                let me = this._currentProjectChat.subscription.members.me.info;
                this._currentProjectChat.whisper("creative", {user:me.uuid, message:join_whisper});
            }*/
        })
        .leaving((leaver) => {
            // someone else leaving
            //console.warn("LEAVER", this._channelName, leaver);
            let leaverIndex = this._users.findIndex(user => user.uuid == leaver.uuid && user.session == leaver.session);
           // console.warn("leaverIndex", leaverIndex);
            if(leaverIndex != -1) this._users.splice(leaverIndex, 1);
            if(this.onLeave) this.onLeave(leaver, this);
            this.usersSubject.next(this._users);
            //console.log("members - leaving", this._channelName, this._chat.subscription.members);
        }).error((error) => {
            console.error(error);
        });
        this._chat.listenToAll((event, data) => {
            //console.log("message", this._channelName, event, data);            
        });
        this._chat.listen('RealTimeMessageNow', (payload:any) =>{
            if(!payload?.message) console.warn("missing message in payload");
            const name = this.channelName.split(".")[1];
            //console.log(`${name} chat message:`, payload);
            this._messages.next(payload.message as IMessage);
        })
        this._chat.listenForWhisper('creative', (whisper:any) =>{
            this._whispers.next({event:'creative', whisper});
        })
        this._chat.listenForWhisper('ping', (whisper:any) =>{
            this._whispers.next({event:'ping', whisper});
        })
        this._chat.listenForWhisper('pong', (whisper:any) =>{
            this._whispers.next({event:'pong', whisper});
        })
        this._chat.listenForWhisper('chat', (whisper:any) =>{
            this._whispers.next({event:'chat', whisper});
        })
        /*
        this._chat.notification((notification) =>{
            console.log("notification!", notification);
        })*/
    }
    leave()
    {
        // @ts-ignore
		window.Echo.leave(this._channelName);
        this._channelName = null;
        this._chat = null;
        this._users.length = 0;
        this._whisperBacklog.length = 0;
        this.usersSubject.next(this._users);

        // clear any listenrs
        this.onHere = this.onJoin = this.onLeave = null;
    }
    whisper(eventName:string, data:any = {})
    {
        // TODO check joined to a channel first
        // TODO stack up messages if not yet joined (perhaps for up 2 some time limit)
        let me = this._chat?.subscription.members.me?.info;
        if(me)
        {
            data.user = me;
            this._chat.whisper(eventName, data);
        }else{
            this._whisperBacklog.push({eventName, data});        
        }
    }

}