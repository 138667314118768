import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { DialogService } from './../../services/dialog.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreativePropertiesComponent } from './../../panels/creative-properties/creative-properties.component';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';

declare var jquery:any;
declare var $ :any;
declare var window :any;

@Component({
	selector: 'app-creative-view',
	templateUrl: './creative-view.component.html',
	styleUrls: ['./creative-view.component.scss']
})
export class CreativeViewComponent implements OnInit, OnDestroy, CanComponentDeactivate {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	segment:any = {};
	name:any = '';
	asset_type_id:any = false;
	asset_types_obj:any;
	asset_types:any;
	
	uuid:any;
	uploads:any = [];
	
	imageSrc:any;
	base64img:any;
	
	added_asset_uuid:any;
	img_type:any;
	
	saveDataTimer:any = false;
	validateAssetMetaTimer:any = false;
	required_fields_filled:any = false;
	
	asset_save_data:any = {};
	
	public assets:any;
	public asset_width:any = 200;
	public asset_height:any = 100;
	
	controlMode:any = 'normal';
	currentPanels:any = [];
	
	@ViewChild('creativePropertiesPanel') creativePropertiesPanel:ElementRef;
	@ViewChild('creativeProperties') creativeProperties:CreativePropertiesComponent;
	

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, public ds:DialogService) { }
	
	canDeactivate(): Observable<boolean> | boolean {
		
		return window.confirm('Unsaved changes are you sure you want to leave');
	  }	
	public setUploadFiles(files:any){
		this.uploads = [];
		for(let i in files){
			if(typeof files[i] == 'object'){
				this.uploads.push(files[i]);
			}
		}
		
		var fr:FileReader = new FileReader;
		fr.onload = () => {
		    let img:any = new Image;
		    img.onload = () => {
		        this.setAssetSaveData('upload', true);
		        if(this.asset_width !== img.width && this.asset_height !== img.height){
			        this.asset_width = img.width;
			        this.asset_height = img.height;
		        }
		    };
		    img.src = fr.result;
		};
		fr.readAsDataURL(this.uploads[0]);
	}
	
	public openRemoveAssetDialog(asset:any, child:any){
		let confirm:any = {
			 title: 'Delete asset',
			 message: 'Are you sure you want to delete "'+asset.name+'"?',
			 confirmAction: () => {
				 this.api.removeasset.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					this.ds.closeDialog();
					$('[data-asset-uuid='+asset.uuid+']').remove();
					child.dismissAction();
				})
				this.api.removeAsset({uuid:asset.uuid});
			 }
		 };
		 this.ds.openConfirm(confirm);
	}
	
	_handleReaderLoaded(e) {
		let reader = e.target;
		let type = reader.result.split(',')[0]+',';
		this.imageSrc = reader.result;
		this.base64img = reader.result.replace(type, '');
		//console.log('this.base64img', this.base64img);
		//console.log('this.uploads[0]', this.uploads[0]);
		this.api.uploadAsset({file:this.uploads[0], uuid:this.added_asset_uuid});
		
/*
		if(this.imageSrc){
			this.imageFileSelected = true;
		}
*/
	}
	
	clickUploadAssetButton(){
		let _data:any = {
			uuid: this.uuid,
			asset_types: this.asset_types,
		};
		this.ds.openUploadAssetDialog(_data);
	}
	
	uploadAsset(asset:any){
		
		if(!this.asset_type_id) this.asset_type_id = 1;
		
		asset['creative_id'] = this.uuid;
		asset['x'] = 0;
		asset['y'] = 0;
		asset['z'] = 0;
		asset['visible'] = 1;
		asset['deliverable'] = 1;
		asset['type'] = this.asset_type_id;
		
		this.api.addasset.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//this.name = res.data.name;
			//console.log('addasset', res.data);
			//console.log('this.uploads[0]', this.uploads[0]);
			
			this.added_asset_uuid = res.data.uuid;
			
			var file = this.uploads[0];
			var pattern = /image-*/;
			var reader = new FileReader();
			this.img_type = file.type;
			if (!file.type.match(pattern)) {
				alert('invalid format');
				return;
			}
			reader.onload = this._handleReaderLoaded.bind(this);
			reader.readAsDataURL(file);
		});
		
		$('body').addClass('saving').addClass('loading');
		
		this.api.addAsset(asset);
		
		this.api.uploadasset.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			//this.api.getAssetsForCreative({creative_id: this.uuid});
			window.location.reload();
		});
	}
	
	showAssetDetails(uuid:any){
		this.api.gotoPage('asset/'+uuid);
		//console.log('showAssetDetails', uuid);
	}
	
	setAssetTypeId(value:any){
		this.asset_type_id = value;
		this.setAssetSaveData('asset_type_id', this.asset_type_id);
		setTimeout(() => {
			$(window).trigger('resize_dropzone');
		}, 100);
	}
	
	setAssetSaveDataDelayed(key:any, value:any){
		clearTimeout(this.saveDataTimer);
		this.saveDataTimer = setTimeout(() => {
			this.setAssetSaveData(key, value);
		}, 100);
	}
	
	public setAssetSaveData(key:any, value:any){
		this.asset_save_data[key] = value;
		if(key == 'width') this.asset_width = value;
		if(key == 'height') this.asset_height = value;
		$(window).trigger('resize_dropzone');
		this.validateAssetMeta();
	}
	
	removeCreative(){
		let confirm:any = {
			 title: 'Delete Creative',
			 message: 'Are you sure you want to delete '+this.name+'?',
			 confirmAction: () => {
				 this.api.removecreative.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(!res || res === 0) return;
					this.ds.closeDialog();
					this.api.gotoPage('project/'+this.segment.project_id);
				})
				this.api.removeCreative({uuid:this.uuid});
			 }
		 };
		 this.ds.openConfirm(confirm);
	}
	
	validateAssetMeta(){
		this.required_fields_filled = true;
		
		this.asset_save_data['width'] = $('#_asset_w').val();
		this.asset_save_data['height'] = $('#_asset_h').val();
		
		//console.log('this.asset_save_data', this.asset_save_data);
		if(typeof this.asset_save_data['name'] == 'undefined' || !this.asset_save_data['name'] || this.asset_save_data['name'] == ''){
			this.required_fields_filled = false;
		}
		if(typeof this.asset_save_data['width'] == 'undefined' || !this.asset_save_data['width'] || this.asset_save_data['width'] == ''){
			this.required_fields_filled = false;
		}
		if(typeof this.asset_save_data['height'] == 'undefined' || !this.asset_save_data['height'] || this.asset_save_data['height'] == ''){
			this.required_fields_filled = false;
		}
		if(!this.asset_type_id){
			this.required_fields_filled = false;
		}else if(this.asset_type_id === 1 || this.asset_type_id === '1'){
			if(typeof this.asset_save_data['upload'] == 'undefined' || !this.asset_save_data['upload'] || this.asset_save_data['upload'] == ''){
				this.required_fields_filled = false;
			}
		}
	}
	
	
	getPanel(panel){
		let panelIndex = this.currentPanels.indexOf(panel);
		if( panelIndex == -1){
			this.currentPanels.push(panel);
		}else{
			this.currentPanels.splice(panelIndex, 1);
		}
		//console.log(this.currentPanels);
	}


	hasPanel(panel){
		let panelIndex = this.currentPanels.indexOf(panel);
		if( panelIndex == -1){
			return false;
		}else{
			return true;
		};
	}

	getControlMode(mode){
		this.controlMode = mode;
	}

	
	ngOnInit() {
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
		this.subscription = this.route.params.subscribe(params => {
			if(params['uuid']){
				this.uuid = params['uuid'];
				this.api.getCreative({creative_id: this.uuid});
				this.api.getAssetsForCreative({creative_id: this.uuid});
			}
		});
		this.api.getcreative.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.segment = res.data.segment;
			this.name = res.data.creative.name;
			this.asset_types_obj = res.data.asset_types;
			this.asset_types = [];
			for(let i in this.asset_types_obj){
				this.asset_types.push({uuid: i, name: this.asset_types_obj[i]});
			}
			//console.log('getcreative', res.data);
		});
		
		this.api.getassetsforcreative.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			this.assets = res.data;
		});
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}