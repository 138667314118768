import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewCreativeDialogComponent } from '../new-creative-dialog/new-creative-dialog.component';


export interface NewWorkgroupData {
	name: string;
  edit: boolean;
}


@Component({
  selector: 'app-new-workgroup-dialog',
  templateUrl: './new-workgroup-dialog.component.html',
  styleUrls: ['./new-workgroup-dialog.component.scss']
})
export class NewWorkgroupDialogComponent implements OnInit {
	formControlName = new FormControl('', [Validators.required] );	/* needed this to get the name to bind ??? */
  constructor(public dialogRef: MatDialogRef<NewCreativeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NewWorkgroupData) { }

  ngOnInit(): void {
  }
  isValid()
	{
	  return this.data.name && this.data.name.length > 1;
	}

}
