import { Creative } from "./creative.model";
import { IDeliveryItem } from "./deliveryItem.model";
import { IProject } from "./project.model";
import { IWorkflow } from "./workflow.model";


export enum DeliveryFolderOptions
{
	FLAT 		= "flatten",				
	CHANNEL		= "channel",				
	FORMAT 		= "format",			
}


export interface IDeliveryPackage {
	id: number;
	uuid: string;
	project_uuid: string;
	name: string;
	type: string;
	actioned: boolean;
	partialAllowed: boolean;
	useFormatPath: boolean;
	passcode: string;
	notes: string;
	delivery_items: IDeliveryItem[];
	delivery_items_add: string[];
	delivery_items_remove: string[];
	project?: any;
	workflows?: IWorkflow[];
	folderOption?: string;
}
export class DeliveryPackage implements IDeliveryPackage{
	static getCreativeDeliveries(creative_uuid: string, deliveryPackages: IDeliveryPackage[]) : DeliveryPackage[]{

		let packages = [];
		if(!deliveryPackages) return packages;
		for (let i = 0; i < deliveryPackages.length; i++) {
			const deliveryPackage = deliveryPackages[i];
			for (let j = 0; j < deliveryPackage.delivery_items.length; j++) {
				const deliveryItem = deliveryPackage.delivery_items[j];
				if(deliveryItem.creative_uuid == creative_uuid) {
					packages.push(deliveryPackage);
					break;
				}
			}		
		}
		return packages;
	}


	public id: number;
	public uuid: string;
	public project_uuid: string;
	public name: string;
	type: string;
	actioned: boolean;
	partialAllowed: boolean;
	useFormatPath: any;
	notes: string;
	folderOption?: string;
	public passcode: string;
	public delivery_items: IDeliveryItem[];
	public delivery_items_add: string[];
	public delivery_items_remove: string[];
	public reference: IDeliveryPackage;

	public static createReferece(target:DeliveryPackage):void {
		target.reference = Object.assign({}, target);
	}
	public static isPropDifferent(target:DeliveryPackage, prop: string): boolean {
		return target.reference[prop] !== target[prop];
	}
	public static isDirty(target:DeliveryPackage): string[]
	{
		let changes: string[];
		for (let prop in target) {
			if(prop == "reference") continue;
			if (DeliveryPackage.isPropDifferent(target, prop)) { if(!changes) changes = [];changes.push(prop)};
		}
		return changes;
	}
	public static resetProp(target:DeliveryPackage, prop: string): boolean {
		if(!target.reference) return;
		return target[prop] = target.reference[prop];
	}

	public static getTempUuid(){
		const d = new Date();
		let t = d.getTime();
		return "temp"+ t;
	}

	public static isNew(target:DeliveryPackage){	
		let _new = (!target.uuid || target.uuid.indexOf('temp',0) != -1);
		return _new;
	}
	public static getStatus(item:DeliveryPackage, count:number, total:number):string
	{
		let status = "";
		if(item.actioned)// is activated for download
		{
			status += "actioned_";
			if(count == total && total != 0){
				status += "allApproved";
			} else {
				status += "notAllApproved";
			}
		} else {
			status += "unactioned_";
			if(count == total && total != 0){
				status += "allApproved";
			} else {
				status += "notAllApproved";
			}
		}
		return status;
	}
	public static getClass(status:string):string
	{
		switch (status) {
			case 'actioned_allApproved':
				return 'green';
				break;
			case 'actioned_notAllApproved':
				return 'red';
				break;
			case 'unactioned_allApproved':
				return 'green';
				break;
			case 'unactioned_notAllApproved':
				return 'grey';
				break;
			default:
				return '';
				break;
		}
	}
	public static getIcon(status:string, total:number):string
	{
		if(total == 0) return 'space_bar';

		switch (status.split("_")[0]) {
			case 'actioned':
				return 'mail';
				break;
			case 'unactioned':
				return 'mail_outline';
				break;
			default:
				return '';
				break;
		}
	}
	/*
		statusObj.icon = 'mail';
			if(count == total){
				statusObj.class = "green";
				statusObj.approvalMsg = "All assets in this package are approved.";
				statusObj.downloadMsg = "Delivery download is active.";
			} else {
				statusObj.class = "red";
				statusObj.approvalMsg = (total - count) + " of " +  total + " assets in this package still waiting for approval.";
				statusObj.downloadMsg = "Download BLOCKED - package has been activated but not all assets are approved.";
				statusObj.warning = true;
			}
		} else {
			statusObj.icon = 'mail_outline';
			if(count == total){
				statusObj.class = "green";
				statusObj.approvalMsg = "All assets in this package are approved.";
			} else {
				statusObj.class = "grey";
				statusObj.approvalMsg = (total - count) + " of " +  total + " assets still waiting for approval.";
			}
			statusObj.downloadMsg = "Download not yet activated.";
		}	
		*/
	public static getDownloadMessage(status:string, total:number, count:number, partialAllowed:boolean):string
	{
		if(status.split("_")[0]=='actioned' && total == 0) return 'Downloads activated - but there are no items in this package';
		switch (status) {
			case 'actioned_allApproved':
				return 'All items available in package download';
				break;
			case 'actioned_notAllApproved':
				if(partialAllowed){
					if(count == 0) return 'Download activated - but there are no approved items available in this package.'
					return `Download activated - ${count} of ${total} items approved and available in this package.`;
				} else {
					return 'Download BLOCKED - all items in this package must be approved to enable download.';
				}
				break;
			default:
				return 'Download not activated.';
				break;
		}
	}
	public static getApprovalMessage(status:string, total:number, count:number):string
	{
		if(total == 0){
			return `No assets have been assigned to this package.`;
		}

		switch (status.split("_")[1]) {
			case 'allApproved':
				return `All ${total} asset(s) in this package are approved.`;
				break;
			case 'notAllApproved':
				let diff = total - count;
				return `${diff} of ${total} asset(s) in this package still waiting for approval.`;
				break;
			default:
				return '';
				break;
		}
	}

	// creative
	public static getCreativeStatus(numPackages, deliverableCount, actionedCount)
	{
		let className;
		let description;

		if(!numPackages)
		{
			// in no packages - none
			className = 'none';
		}else if(numPackages == deliverableCount && numPackages == actionedCount) {
			// green filled: In at least 1 package - all  deliverable - ALL manually set to delivered
			className = 'allDeliverable allActive';
			description = [deliverableCount+' package'+(deliverableCount==1?'':'s')+' ready for delivery. ',deliverableCount+' package'+(deliverableCount==1?'':'s')+' have been activated for download.'];
		}else if(numPackages == deliverableCount && actionedCount) {
			// amber filled: In at least 1 package - all  deliverable - at least 1 manually set to delivered 
			className = 'allDeliverable someActive';
			description = [deliverableCount+' package'+(deliverableCount==1?'':'s')+' ready for delivery. ', (numPackages-actionedCount)+' waiting to be activated (for download).'];
		}else if(numPackages != deliverableCount && actionedCount) {
			// red filled: In at least 1 package - at least 1 not deliverable - at least 1 manually set to delivered 
			className = 'blocked someActive';
			description = [(numPackages-deliverableCount)+' package'+(numPackages-deliverableCount==1?'':'s')+' waiting for content approval. ','DOWNLOADS BLOCKED while package content awaits approval.'];
		}else if(numPackages == deliverableCount && actionedCount == 0) {
			//green outline: In at least 1 package - all  deliverable - none manually set to delivered 
			className = 'allDeliverable noneActive';
			description = [deliverableCount+' package'+(deliverableCount==1?'':'s')+' ready for delivery. ', numPackages+' waiting to be activated (for download).'];
		}else if(deliverableCount > 0 && numPackages != deliverableCount && actionedCount == 0) {
			// amber outline: In at least 1 package - at least 1 deliverable  - none manually set to delivered
			className = 'someDeliverable noneActive';
			description = [(numPackages-deliverableCount)+' package'+(numPackages-deliverableCount==1?'':'s')+' waiting for content approval. ', numPackages+' waiting to be activated (for download).'];
		}else if(deliverableCount == 0 && actionedCount == 0) {
			// grey outline: In at least 1 package - none deliverable - none manually set to delivered
			className = 'noneDeliverable noneActive';
			description = [numPackages+' package'+(numPackages==1?'':'s')+' waiting for content approvals. ', numPackages+' waiting to be activated (for download).'];
		}

		return {className,description};
	}
	public static getCreativeClass(status:string):string
	{
		return '';
	}
	public static getCreativeDescription(status:string):string
	{
		return '';
	}
	/*
	public isNew(){
		
		let _new = (!this.uuid || this.uuid.indexOf('temp',0) != -1);
		//console.log("_new package",_new);
		return _new;
	}
	*/
}
