import { Pipe, PipeTransform } from '@angular/core';
import { MathUtils } from 'three';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number): number|string {
    if(isNaN(value)) value = 0;
    MathUtils.clamp(value, 0, 1);
    return Math.round(value * 100);
  }

}
