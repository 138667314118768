<mat-card class="add_folder_form">
	<h3>Add Folder</h3>
	<form class="login-form">
		<div class="inputs">
			<mat-form-field>
				<input matInput id="name" class="login_input" placeholder="Name" value="">
			</mat-form-field>
		</div>
		<div class="button_wrapper">
			<button (click)="addFolder()" mat-raised-button>Add Folder</button>
		</div>
	</form>
</mat-card>
<mat-card class="all_folders" *ngIf="folders.length > 0">
	<h3>Folders</h3>
	<mat-list role="list">
		<mat-list-item *ngFor="let folder of folders; index as i" role="listitem" class="list-item">
			<div class="folder" [attr.data-row]="(i%2==0) ? 'odd' : 'even'">
				<p class="name">{{folder.name}}</p>
			</div>
		</mat-list-item>
	</mat-list>
</mat-card>