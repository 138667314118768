import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { PanelService } from './../../services/panel.service';
import { Subscription, Subject } from 'rxjs';
import { Globals } from 'src/app/global';

declare var jquery:any;
declare var $ :any;
declare var window :any;

let API_URL = Globals.BASE_API_URL;

@Component({
	selector: 'app-asset-embed',
	templateUrl: './asset-embed.component.html',
	styleUrls: ['./asset-embed.component.scss']
})
export class AssetEmbedComponent implements OnInit, OnDestroy {

	private subscription:Subscription;
	private _unsubscribe = new Subject<boolean>();
	
	@Input() creative:any;
	@Input() asset:any;
	
	uuid:any;
	asset_data_string:any;
	asset_data:any;
	
	image_src:any = false;
	image_width:any = false;
	image_height:any = false;
	

	constructor(private route: ActivatedRoute, private router: Router, public api:ApiService, public panels:PanelService) { }
	
	clickEdit(){
		this.panels.openCreativeProperties({asset:this.asset, creative:this.creative});
	}
	
	ngOnInit() {
		
		let img_src_tmp:any = API_URL+'api/asset/'+this.asset.uuid+'/image';
		
		let img:any = new Image();
		
		img.onload = () => {
			
				$('[data-asset-uuid='+this.asset.uuid+']').css({
					'position': 'absolute',
					'height': this.asset.height,
					'width': this.asset.width,
					'top': this.asset.y,
					'left': this.asset.x,
					'z-index': this.asset.z,
				});
			
			this.image_src = img_src_tmp;
			//console.log('$([data-asset-uuid=this.asset.uuid])', $('[data-asset-uuid='+this.asset.uuid+']'));
			//console.log('this.asset.uuid', this.asset.uuid);
		}
		img.src = img_src_tmp;
		
		$(window).on('update_assets', () => {
			$('[data-asset-uuid='+this.asset.uuid+']').css({
				'position': 'absolute',
				'height': parseInt(this.asset.height),
				'width': parseInt(this.asset.width),
				'top': parseInt(this.asset.y),
				'left': parseInt(this.asset.x),
				'z-index': parseInt(this.asset.z),
			});
			//console.log('this.asset', this.asset.x, $('[data-asset-uuid='+this.asset.uuid+']'));
		});
		
		
		/*
			this.api.example.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				// do something with res
			});
		*/
/*
		this.subscription = this.route.params.subscribe(params => {
			if(params['uuid']){
				this.uuid = params['uuid'];
				this.api.getAssetMeta(this.uuid);
			}
		});
		this.api.getassetmeta.pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(!res || res === 0) return;
			console.log('getassetmeta', res.data);
			this.image_width = res.data.asset.width;
			this.image_height = res.data.asset.height;
			this.image_src = API_URL+'api/asset/'+this.uuid+'/image';
			this.asset_data = res.data;
			this.asset_data_string = JSON.stringify(this.asset_data, null, 2);
		});
*/
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}